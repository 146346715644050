import React, { Component, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import "./Main.css";
import useTokenStore from "../store/TokenStore";
import MainSlide from "./MainSlide";
import { getAnnounceList, announceRecruitSearch } from "../../api/User/Jop/Job";

import Announcement from "./Announcement";
import Banner from "./Banner";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import LoginForm from "../user/login/LoginForm";
import LoginPopUP from "./Popup";

const Main = () => {
  const { alert } = useParams();
  const [announcements, setAnnouncements] = useState({
    population: [],
    imminent: [],
  });

  const getAnnounces = useCallback(() => {
    getAnnounceList(4).then((response) => {
      const data = response.data;
      data["resultMsg"] === "성공."
        ? setAnnouncements({
            population: data["resultData"]["population"],
            imminent: data["resultData"]["imminent"],
          })
        : setAnnouncements(announcements);
      if (data["resultMsg"] === "성공.") {
        const recruit_search_data = {
          population: data["resultData"]["population"].map((e, i) => e.id),
          imminent: data["resultData"]["imminent"].map((e, i) => e.id),
        };
        // announceRecruitSearch(recruit_search_data).then(
        //     response => {
        //         console.log(response)
        //         const recruit_info = response.data;
        //         recruit_info["resultMsg"] === "성공." ? localStorage.setItem("announceRecruits",JSON.stringify(recruit_info.resultData)) : localStorage.setItem("announceRecruits",JSON.stringify(null));
        //     }
        // )
      }
    });
  }, []);

  useEffect(() => {
    getAnnounces();
  }, []);

  return (
    <div>
      <div className="wrapper">
        <main>
          {!(typeof alert == "undefined" || alert == null || alert == "") && (
            <LoginPopUP text={alert} />
          )}
          <MainSlide />
          <Announcement
            title="요즘 인기있는 공고"
            subTitle="요즘 인기있는 공고"
            announceList={announcements["population"]}
            name={"population"}
            href={"/user/job/recruitInfo"}
          />
          <Announcement
            sortSubject={"imminent"}
            title="모집 임박 공고"
            subTitle="얼마 남지 않았어요!"
            announceList={announcements["imminent"]}
            name={"imminent"}
            href={"/user/job/recruitInfo"}
          />
          {/* <Banner /> */}
        </main>
      </div>
    </div>
  );
};

export default Main;
