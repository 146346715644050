import { useEffect, useState, useMemo, forwardRef, useRef } from "react";
import University from "./University";
import { useFieldArray } from "react-hook-form";
import Graduate from "./Graduate";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { registerLocale } from "react-datepicker";
import dayjs from "dayjs";
import "../autoComplete.css";
registerLocale("ko", ko);

export const AutoComplete = ({
  name,
  id,
  inputValue,
  placeholder,
  register,
  autoCompleteInfo,
  group,
  setv,
  setFocus,
  readOnly,
}) => {
  const inputRef = useRef(null);
  const autoRef = useRef(null);

  const keyDown = (e) => {
    if (e.code === "ArrowDown" && autoRef.current) {
      //
    }
  };

  const change = (e) => {
    if (!readOnly) {
      const value = e.target.value;
      setv(name, value);
      setFocus(value);
    }
  };

  const filteredAutoCompleteInfo = autoCompleteInfo.filter((info) =>
    info.major_nm.includes(inputValue)
  );

  return (
    <div className="box_input dropdown">
      <label htmlFor={id} className="asterisk">
        전공
      </label>
      <input
        type="text"
        className="placeholder_test"
        name={name}
        id={id}
        ref={inputRef}
        placeholder={placeholder}
        {...register}
        onChange={change}
        onKeyDown={keyDown}
        autoComplete="off"
        tabIndex={0}
        readOnly={readOnly}
        disabled={readOnly}
      />
      {inputValue && (
        <Option
          ref={autoRef}
          inputName={name}
          inputValue={inputValue}
          majorInfo={filteredAutoCompleteInfo}
          schoolGroup={group}
          setValue={setv}
          setFocus={setFocus}
          inputRef={inputRef}
        />
      )}
    </div>
  );
};

export const Option = forwardRef(
  (
    {
      inputName,
      inputValue,
      majorInfo,
      schoolGroup,
      setValue,
      setFocus,
      inputRef,
      className,
    },
    ref
  ) => {
    const [count, setCount] = useState(0);
    const [enter, setEnter] = useState(false);

    const keyDown2 = (e) => {
      if (e.code === "ArrowDown") {
        setCount((prevCount) => Math.min(prevCount + 1, contents.length - 1));
        setEnter(false);
      } else if (e.code === "ArrowUp") {
        setCount((prevCount) => Math.max(prevCount - 1, 0));
        setEnter(false);
      } else if (e.code === "Enter") {
        setValue(inputName, contents[count].major_nm);
        setCount(0);
        setEnter(true);
        setFocus(inputName);
        inputRef?.current?.focus();
      }

      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.preventDefault();
    };

    const click = (e) => {
      setCount(0);
      setEnter(true);
      setValue(inputName, e.target.innerText);
      inputRef?.current?.focus();

      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.preventDefault();
    };

    const contents = useMemo(() => {
      setCount(0);
      setEnter(true);
      return majorInfo.filter(
        (data) =>
          data.school_gb === (schoolGroup || "") &&
          data.major_nm.includes(inputValue)
      );
    }, [inputValue, schoolGroup, majorInfo]);

    useEffect(() => {
      if (!enter && count >= 0 && count < contents.length) {
        const optionToFocus = ref.current.querySelectorAll("span")[count];
        if (optionToFocus) {
          optionToFocus.focus();
        }
      } else {
        setEnter(false);
      }
    }, [count]);

    if ((inputValue && contents.length === 0) || contents == null) {
      return (
        <div className={className ? className : "dropdown-options"}>
          <span href="#">{"일치하는 목록 없음"}</span>
        </div>
      );
    }

    return (
      <div className={className ? className : "dropdown-options"}>
        {inputValue &&
          contents.map((e, i) => (
            <span
              key={i}
              onKeyDown={keyDown2}
              onClick={click}
              ref={count === i ? ref : null}
              tabIndex={-1}
            >
              {e.major_nm}
            </span>
          ))}
      </div>
    );
  }
);

const Education = ({
  register,
  control,
  errors,
  setValue,
  watchField,
  educationalHistory,
  autoComplete,
  setFocus,
  readOnly,
  majorIndex,
}) => {
  const [univIdx, setUnivIdx] = useState(0);
  const [gradIdx, setGradIdx] = useState(0);

  const { remove: removeUniv, append: appendUniversity } = useFieldArray({
    control,
    name: "education.university",
  });

  const { remove: removeGraduate, append: appendGraduate } = useFieldArray({
    control,
    name: "education.graduate",
  });

  const [majorAutoComplete, setMajorAutoComplete] = useState([]);

  useEffect(() => {
    setMajorAutoComplete(autoComplete);
  }, [autoComplete]);

  useEffect(() => {
    if (educationalHistory.length > 0) {
      educationalHistory.map((data, i) => {
        if (data.type === "학부") {
          setNumOfUniv((prevNum) => prevNum + 1);
          setValue(`education.university[${univIdx}].school_name`, data.title);
          setValue(
            `education.university[${univIdx}].admission_date`,
            data.period.split("~")[0].replace(" ", "").replace(".", "-")
          );
          setValue(
            `education.university[${univIdx}].graduate_year`,
            data.period.split("~")[1].replace(" ", "").replace(".", "-")
          );
          setValue(
            `education.university[${univIdx}].graduate_status`,
            data.etc
          );
          setUnivIdx((prev) => prev + 1);
        } else if (data.type === "대학원") {
          setNumOfGrad((prevNum) => prevNum + 1);
          setValue(`education.graduate[${gradIdx}].school_name`, data.title);
          setValue(
            `education.graduate[${gradIdx}].admission_date`,
            data.period.split("~")[0].replace(" ", "").replace(".", "-")
          );
          setValue(
            `education.graduate[${gradIdx}].graduate_year`,
            data.period.split("~")[1].replace(" ", "").replace(".", "-")
          );
          setValue(`education.graduate[${gradIdx}].graduate_status`, data.etc);
          setGradIdx((prev) => prev + 1);
        }
      });
      setChecked(false);
    }
  }, [educationalHistory]);

  const [numOfUniv, setNumOfUniv] = useState(0);
  const [numOfGrad, setNumOfGrad] = useState(0);

  const handleAddUniv = () => {
    setNumOfUniv((prevNum) => prevNum + 1);
  };
  const handleAddGrad = () => {
    //appendGraduate();
    setNumOfGrad((prevNum) => prevNum + 1);
  };
  const handleRemoveGraduate = (index) => {
    removeGraduate(index);
    setNumOfGrad(numOfGrad - 1);
  };
  const RemoveAllGraduate = () => {
    removeGraduate();
    setNumOfGrad(0);
  };
  const handleRemoveUniv = (index) => {
    removeUniv(index);
    if (numOfUniv > 0) setNumOfUniv(numOfUniv - 1);
  };

  const [checked, setChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      handleRemoveUniv();
      RemoveAllGraduate();
      watchField.education.high_school.admission_status = "";
      watchField.education.high_school.school_name = "";
      watchField.education.high_school.affiliation = "";
      watchField.education.high_school.major = "";
      watchField.education.high_school.admission_date = "";
      watchField.education.high_school.graduate_status = "";
      watchField.education.high_school.graduate_year = "";
      watchField.education.high_school.below = "checked";
    } else watchField.education.high_school.below = "unchecked";
  };

  useEffect(() => {
    if (watchField.education.high_school.below === "checked") {
      setChecked(true);
      // console.log("checked")
    }
    // else console.log("unchecked")
  }, [watchField.education.high_school.below]);

  useEffect(() => {
    setNumOfGrad(
      watchField?.education?.graduate?.length != undefined
        ? watchField?.education?.graduate?.length
        : 0
    );
    setNumOfUniv(
      watchField?.education?.university?.length != undefined
        ? watchField?.education?.university?.length
        : 0
    );
  }, [watchField]);

  return (
    <div className="wrap_signup_input wrap_signup_basic">
      <div className="box_sub_title chk_required_education">
        <h4 className="sub_title_signup">학력사항</h4>
        <div className="box_input_chk">
          <label htmlFor="temp05">
            고등학교 미만 졸업
            <input
              type="checkbox"
              checked={checked}
              onChange={handleCheckboxChange}
              readOnly={readOnly}
              disabled={readOnly}
              className="input_chk"
              id="temp05"
              name="group05"
            ></input>
          </label>
        </div>
      </div>
      {!checked ? (
        <div className="box_education_info history_education">
          <h5 className="history_education_detail">고등학교</h5>

          <div className="box_division">
            <div className="wrap_box_input">
              <div className="box_input">
                <label htmlFor="" className="asterisk">
                  입학상태
                </label>
                <select
                  name="education.high_school.admission_status"
                  readOnly={readOnly}
                  disabled={readOnly}
                  id=""
                  className="select"
                  {...register("education.high_school.admission_status", {
                    required: "필수 입력 항목입니다.",
                  })}
                >
                  <option value="" hidden={true}>
                    입학상태를 선택해주세요.
                  </option>
                  <option value="입학">입학</option>
                  <option value="편입">편입</option>
                  <option value="검정고시">검정고시</option>
                </select>
              </div>
              <p className="msg_err">
                {errors?.education?.high_school?.admission_status?.message}
              </p>
            </div>
            {watchField.education.high_school.admission_status !==
              "검정고시" && (
              <>
                <div className="wrap_box_input">
                  <div className="box_input">
                    <label htmlFor="" className="asterisk">
                      학교명
                    </label>
                    <input
                      type="text"
                      readOnly={readOnly}
                      disabled={readOnly}
                      className="placeholder_test"
                      name="education.high_school.school_name"
                      id="education.high_school.school_name"
                      placeholder="고등학교 이름을 입력해주세요."
                      {...register("education.high_school.school_name", {
                        required: "필수 입력 항목입니다.",
                      })}
                    ></input>
                  </div>
                  <p className="msg_err">
                    {errors?.education?.high_school?.school_name?.message}
                  </p>
                </div>
                <div className="wrap_box_input">
                  <div className="box_input">
                    <label htmlFor="" className="asterisk">
                      계열
                    </label>
                    <select
                      name="education.high_school.affiliation"
                      disabled={readOnly}
                      readOnly={readOnly}
                      id="education.high_school.affiliation"
                      className="select"
                      {...register("education.high_school.affiliation", {
                        required: "필수 입력 항목입니다.",
                      })}
                    >
                      <option value="" hidden={true}>
                        계열을 선택해주세요.
                      </option>
                      <option value="이과">이과</option>
                      <option value="문과">문과</option>
                      <option value="전문(실업)계">전문(실업)계</option>
                      <option value="예체능계">예체능계</option>
                      <option value="특성화">특성화/마이스터고</option>
                      <option value="특수목적고">특수목적고</option>
                    </select>
                  </div>
                  <p className="msg_err">
                    {errors?.education?.high_school?.affiliation?.message}
                  </p>
                </div>
                {(watchField.education.high_school.affiliation ===
                  "전문(실업)계" ||
                  watchField.education.high_school.affiliation ===
                    "특성화") && (
                  <div className="wrap_box_input">
                    {/*
                        <div className="box_input dropdown">
                            <label htmlFor="" className="asterisk">전공</label>
                            <input type="text" className="placeholder_test" name="education.high_school.major" id="education.high_school.major"
                                   placeholder="전공을 입력해주세요." {...register('education.high_school.major', {
                                required: '필수 입력 항목입니다.'
                            })}></input>
                            <AutoCompleteOption
                            inputName={'education.high_school.major'}
                            inputValue={watchField.education.high_school.major}
                            majorInfo={majorAutoComplete}
                            schoolGroup={"H"}
                            setValue={setValue}/>
                        </div>*/}
                    <AutoComplete
                      name={"education.high_school.major"}
                      id={"education.high_school.major"}
                      inputValue={watchField.education.high_school.major}
                      placeholder={"전공을 입력해주세요."}
                      register={register("education.high_school.major", {
                        required: "필수 입력 항목입니다.",
                      })}
                      autoCompleteInfo={majorAutoComplete}
                      readOnly={readOnly}
                      disabled={readOnly}
                      group={"H"}
                      setv={setValue}
                      setFocus={setFocus}
                    />
                    <p className="msg_err">
                      {errors?.education?.high_school?.major?.message}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="box_division">
            {watchField.education.high_school.admission_status !==
              "검정고시" && (
              <>
                <div className="wrap_box_input">
                  <div className="box_input wrap_datepicker">
                    <label htmlFor="" className="asterisk">
                      입학년월
                    </label>
                    <input
                      type="text"
                      readOnly={readOnly}
                      disabled={readOnly}
                      id="education.high_school.admission_date"
                      name="education.high_school.admission_date"
                      className="input_datepicker hasDatepicker"
                      placeholder="년도를 입력해주세요."
                      style={{ display: "none" }}
                      {...register("education.high_school.admission_date", {
                        required: "필수 입력 항목입니다.",
                      })}
                    ></input>
                    <DatePicker
                      onKeyDown={(e) => {
                        console.log(e);
                        if (
                          (e.keyCode < 48 || e.keyCode > 57) &&
                          e.keyCode !== 8 &&
                          e.keyCode !== 189
                        ) {
                          e.preventDefault();
                        }
                      }}
                      selected={
                        watchField.education?.high_school?.admission_date
                          ? new Date(
                              watchField.education.high_school.admission_date
                            )
                          : null
                      }
                      onChange={(date) => {
                        date &&
                          setValue(
                            "education.high_school.admission_date",
                            dayjs(date).format("YYYY-MM"),
                            { shouldValidate: true }
                          );
                      }}
                      showMonthYearPicker
                      dateFormat="yyyy-MM"
                      locale={ko}
                      readOnly={readOnly}
                      disabled={readOnly}
                      maxDate={
                        watchField.education?.high_school?.graduate_year <
                        new Date()
                          ? watchField.education?.high_school?.graduate_year
                          : new Date()
                      }
                      placeholderText="년도를 입력해주세요."
                      label
                    />
                  </div>
                  <p className="msg_err">
                    {errors?.education?.high_school?.admission_date?.message}
                  </p>
                </div>
                <div className="wrap_box_input">
                  <div className="box_input">
                    <label htmlFor="" className="asterisk">
                      졸업상태
                    </label>
                    <select
                      name=""
                      id=""
                      readOnly={readOnly}
                      disabled={readOnly}
                      className="select"
                      {...register("education.high_school.graduate_status", {
                        required: "필수 입력 항목입니다.",
                      })}
                    >
                      <option value="" hidden={true}>
                        졸업상태를 선택해주세요.
                      </option>
                      <option value="졸업">졸업</option>
                      <option value="졸업예정">졸업예정</option>
                      <option value="재학중">재학중</option>
                      <option value="중퇴">중퇴</option>
                    </select>
                  </div>
                  <p className="msg_err">
                    {errors?.education?.high_school?.graduate_status?.message}
                  </p>
                </div>
                {(watchField.education.high_school.graduate_status === "졸업" ||
                  watchField.education.high_school.graduate_status === "") && (
                  <>
                    <div className="wrap_box_input">
                      <div className="box_input wrap_datepicker">
                        <label htmlFor="" className="asterisk">
                          졸업년월
                        </label>
                        <input
                          type="text"
                          readOnly={readOnly}
                          disabled={readOnly}
                          id="education.high_school.graduate_year"
                          name="education.high_school.graduate_year"
                          className="input_datepicker hasDatepicker"
                          placeholder="년도를 입력해주세요."
                          style={{ display: "none" }}
                          {...register("education.high_school.graduate_year", {
                            required: "필수 입력 항목입니다.",
                          })}
                        ></input>
                        <DatePicker
                          onKeyDown={(e) => {
                            console.log(e);
                            if (
                              (e.keyCode < 48 || e.keyCode > 57) &&
                              e.keyCode !== 8 &&
                              e.keyCode !== 189
                            ) {
                              e.preventDefault();
                            }
                          }}
                          selected={
                            watchField.education?.high_school?.graduate_year
                              ? new Date(
                                  watchField.education.high_school.graduate_year
                                )
                              : null
                          }
                          onChange={(date) => {
                            date &&
                              setValue(
                                "education.high_school.graduate_year",
                                dayjs(date).format("YYYY-MM"),
                                { shouldValidate: true }
                              );
                          }}
                          showMonthYearPicker
                          dateFormat="yyyy-MM"
                          locale={ko}
                          readOnly={readOnly}
                          disabled={readOnly}
                          maxDate={new Date()}
                          minDate={
                            new Date(
                              watchField.education.high_school.admission_date
                            )
                          }
                          placeholderText="년도를 입력해주세요."
                        />
                      </div>
                      <p className="msg_err">
                        {errors?.education?.high_school?.graduate_year?.message}
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
            {watchField.education.high_school.admission_status ===
              "검정고시" && (
              <>
                <div className="wrap_box_input">
                  <div className="box_input wrap_datepicker">
                    <label htmlFor="" className="asterisk">
                      합격년월
                    </label>
                    <input
                      type="text"
                      id="education.high_school.graduate_year"
                      name="education.high_school.graduate_year"
                      readOnly={readOnly}
                      disabled={readOnly}
                      className="input_datepicker hasDatepicker"
                      placeholder="년도를 입력해주세요."
                      style={{ display: "none" }}
                      {...register("education.high_school.graduate_year", {
                        required: "필수 입력 항목입니다.",
                      })}
                    ></input>
                    <DatePicker
                      onKeyDown={(e) => {
                        console.log(e);
                        if (
                          (e.keyCode < 48 || e.keyCode > 57) &&
                          e.keyCode !== 8 &&
                          e.keyCode !== 189
                        ) {
                          e.preventDefault();
                        }
                      }}
                      selected={
                        watchField.education?.high_school?.graduate_year
                          ? new Date(
                              watchField.education.high_school.graduate_year
                            )
                          : null
                      }
                      onChange={(date) => {
                        date &&
                          setValue(
                            "education.high_school.graduate_year",
                            dayjs(date).format("YYYY-MM"),
                            { shouldValidate: true }
                          );
                      }}
                      showMonthYearPicker
                      dateFormat="yyyy-MM"
                      locale={ko}
                      maxDate={new Date()}
                      disabled={readOnly}
                      readOnly={readOnly}
                      placeholderText="년도를 입력해주세요."
                    />
                  </div>
                  <p className="msg_err">
                    {errors?.education?.high_school?.graduate_year?.message}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}
      <div>
        {Array.from({ length: numOfUniv }).map((_, index) => (
          <University
            key={`university-${index}`}
            register={register}
            index={index}
            majorIndex={majorIndex}
            control={control}
            removeUniv={() => handleRemoveUniv(index)}
            GraduateNum={numOfUniv}
            errors={errors}
            watchField={watchField}
            setValue={setValue}
            autoComplete={autoComplete}
            setFocus={setFocus}
            readOnly={readOnly}
          />
        ))}
        {!checked && numOfGrad === 0 && (
          <button
            type="button"
            className="btn_submit btn_add filled_green"
            onClick={handleAddUniv}
            disabled={readOnly}
          >
            <span className="btn_text">대학교 추가</span>
          </button>
        )}
      </div>
      <div style={{ marginTop: "10px" }}>
        {Array.from({ length: numOfGrad }).map((_, index) => (
          <Graduate
            key={`graduate-${index}`}
            register={register}
            index={index}
            autoComplete={autoComplete}
            setFocus={setFocus}
            removeGraduate={() => handleRemoveGraduate(index)}
            errors={errors}
            watchField={watchField}
            setValue={setValue}
            readOnly={readOnly}
          />
        ))}
        {numOfUniv !== 0 && (
          <button
            type="button"
            className="btn_submit btn_add filled_green"
            onClick={handleAddGrad}
            disabled={readOnly}
          >
            <span className="btn_text">대학원 추가</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default Education;
