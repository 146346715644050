import LeftToggleAfterUserLogin from "./LeftToggleAfterUserLogin";
import LeftToggleBeforeLogin from "./LeftToggleBeforeLogin";
import { useLayoutEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import LeftToggleAfterCompLogin from "./LeftToggleAfterCompLogin";
import ChatBot from "../chatbot/ChatBot";

const Aside = () => {
  const [role, setRole] = useState();
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  useLayoutEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      setRole(decodedToken.role);
    }
  }, []);
  return (
    <>
      <div className="area_aside">
        <div className="area_aside_left"></div>
        <div className="wrap_btn_toggle wrap_btn_toggle_left">
          <a href="#" target="_self" className="btn_toggle btn_user_toggle"></a>
        </div>
        <aside className="aside_left on">
          {role === "ROLE_USER" && (
            <LeftToggleAfterUserLogin
              setModal={setModal}
              setModalType={setModalType}
            />
          )}
          {role === "ROLE_COMPANY" && <LeftToggleAfterCompLogin />}
          {!role && <LeftToggleBeforeLogin />}
        </aside>

        <div className="wrap_btn_toggle wrap_btn_toggle_right">
          <a href="#" target="_self" className="btn_toggle_chat"></a>
        </div>

        <aside className="aside_right">
          <ChatBot />
        </aside>
      </div>

      {modal && (
        <>
          <div
            className="popup_mask"
            style={{ display: "block" }}
            onClick={(e) => {
              e.preventDefault();
              setModal(false);
            }}
          ></div>
          <div className="popup_layer popup_small">
            <div className="popup_inner">
              <div className="popup_contents">
                {modalType > 0 && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        (modalType === 1
                          ? "역량평가"
                          : modalType === 2 || modalType === 4
                            ? "이력서 작성"
                            : "이력서 작성 및 역량평가") +
                        (modalType !== 4
                          ? " 완료 시<br/>추천직무 정보를 제공해드립니다."
                          : "완료 후<br/>역량평가를 진행 할수 있습니다."),
                    }}
                  ></p>
                )}

                <div className="popup_small_btn_wrap">
                  <a
                    href="#"
                    target="_self"
                    className="btn_submit filled_black"
                    onClick={(e) => {
                      e.preventDefault();
                      setModal(false);
                    }}
                  >
                    닫기
                  </a>
                  {modalType > 0 && modalType === 1 ? (
                    <a
                      href="/user/ability/main"
                      target="_self"
                      className="btn_submit filled_green"
                    >
                      역량평가 하기
                    </a>
                  ) : (
                    <a
                      href="/user/resume/beforeResume"
                      target="_self"
                      className="btn_submit filled_green"
                    >
                      이력서작성 하기
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Aside;
