import React, {useState, useEffect} from 'react';
import {ErrorMessages} from "../../../utils/Message";
import {usePopupPost} from "../../../hooks/usePopupPost";
import {countCertification} from "../../../api/User/Signup/Signup";


const InterCrtfCertification = (props) => {
    const { methods, scenarioId } = props;
    const { register, setValue, watch, handleSubmit, clearErrors, reset, setError, formState: { errors }} = methods;
    const [regSeq, setRegSeq] = useState(false);
    const watchField = watch();

    function getPostCode(address, extraAddress) {
        setValue('disabili_certifica.address', address, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
        setValue('disabili_certifica.extraAddr', extraAddress, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
    }

    const { postCode } = usePopupPost(getPostCode);

    const duplicateCheckNum = async () => {
        const dis_reg_cer_seq = watchField.disabili_certifica.dis_reg_cer_seq;

        if (dis_reg_cer_seq === "") {
            setError("disabili_certifica.dis_reg_cer_seq",{
                message: ErrorMessages.required
            }, {shouldFocus: true});
        } else if (!/^\d{16}$/.test(dis_reg_cer_seq)) {
            setError("disabili_certifica.dis_reg_cer_seq",{
                message: "16자리 숫자만 입력 가능 합니다."
            }, {shouldFocus: true});
        } else {
            try {
                const response = await countCertification(dis_reg_cer_seq);

                if (response.data.resultData !== 0) {
                    setError("disabili_certifica.dis_reg_cer_seq", {
                        message: ErrorMessages.duplicateCrtfNum
                    }, {shouldFocus: true});
                    setValue("disabili_certifica.dis_reg_cer_seq", "", {shouldDirty: true, shouldTouch: true});
                    setRegSeq(false);
                } else {
                    clearErrors('disabili_certifica.dis_reg_cer_seq')
                    setRegSeq(true);
                }

            } catch (error) {
                console.log(error)
            }
        }

    }
    useEffect(() => {
        console.log(props.crtfStatus);
    }, [])
    return (
        <div className="chat_message_box">
            <div className="box_chat_bg">
                <div className="wrap_certificate">

                <div className="box_division">
                    <div className="wrap_box_input chat">
                        <div className="box_input">
                            <label htmlFor="" className="asterisk">이름</label>
                            <input type="text" id="member_com.member_nm" name="member_com.member_nm" placeholder="이름을 입력해 주세요." {...register("member_com.member_nm", {
                                required: ErrorMessages.required,
                            })} disabled={scenarioId === "upload_crtf" || scenarioId === "create_crtf" || scenarioId === "valid_crtf" ? false : true}/>
                        </div>
                        <p className="msg_err">{ errors.member_com?.member_nm?.message }</p>
                    </div>
                    <div className="wrap_box_input chat">
                        <div className="box_input">
                            <label htmlFor="" className="asterisk">성별</label>
                            <input type="text" id="disabili_certifica.sex_gb" name="disabili_certifica.sex_gb" placeholder="성별을 입력해 주세요." {...register("disabili_certifica.sex_gb", {
                                required: ErrorMessages.required,
                            })} disabled={scenarioId === "upload_crtf" || scenarioId === "create_crtf" || scenarioId === "valid_crtf" ? false : true}/>
                        </div>
                        <p className="msg_err">{ errors.disabili_certifica?.sex_gb?.message }</p>
                    </div>
                    <div className="wrap_box_input chat">
                        <div className="box_input">
                            <label htmlFor="" className="asterisk">생년월일</label>
                            <input type="text" id="disabili_certifica.birthday" name="disabili_certifica.birthday" placeholder="생년월일을 입력해 주세요." {...register("disabili_certifica.birthday", {
                                required: ErrorMessages.required,
                                pattern: {
                                    value: /^\d{4}-\d{2}-\d{2}$/,
                                    message: ErrorMessages.inValidBirthday
                                }
                            })} disabled={scenarioId === "upload_crtf" || scenarioId === "create_crtf" || scenarioId === "valid_crtf" ? false : true}/>
                        </div>
                        <p className="msg_err">{ errors.disabili_certifica?.birthday?.message }</p>
                    </div>
                </div>
                <div className="wrap_box_input focus_in chat">
                    <div className="box_confirm">
                        <div className="box_input">
                            <label htmlFor="" className="asterisk">주소</label>
                            <input type="text" id="disabili_certifica.address" name="disabili_certifica.address" placeholder="주소를 입력해 주세요." {...register("disabili_certifica.address", {
                                required: true,
                            })} readOnly={true}/>
                        </div>
                        <button type="button" className="btn_submit filled_green" onClick={postCode} disabled={scenarioId === "upload_crtf" || scenarioId === "create_crtf" || scenarioId === "valid_crtf" ? false : true}>우편번호 찾기</button>
                    </div>
                    <div className="box_input">
                        <input type="text" id="disabili_certifica.extraAddr" name="disabili_certifica.extraAddr" placeholder="상세주소를 입력해 주세요." {...register("disabili_certifica.extraAddr", {
                            required: true,
                        })} disabled={scenarioId === "upload_crtf" || scenarioId === "create_crtf" || scenarioId === "valid_crtf" ? false : true}/>
                    </div>
                    <p className="msg_err">{ (errors.disabili_certifica?.address || errors.disabili_certifica?.extraAddr) && '필수 입력 항목입니다.' }</p>
                </div>
                <div className="wrap_box_input chat">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">주장애 및 장애 정도</label>
                        <input type="text" id="disabili_certifica.major_disability_nm" name="disabili_certifica.major_disability_nm" placeholder="주장애 및 장애 정도를 입력해 주세요."
                               {...register("disabili_certifica.major_disability_nm", {
                            required: ErrorMessages.required,
                        })} disabled={scenarioId === "upload_crtf" || scenarioId === "create_crtf" || scenarioId === "valid_crtf" ? false : true}/>
                    </div>
                    <p className="msg_err">{ errors.disabili_certifica?.major_disability_nm?.message }</p>
                </div>
                <div className="wrap_box_input chat">
                    <div className="box_input">
                        <label htmlFor="">부장애 및 장애 정도</label>
                        <input type="text" id="disabili_certifica.minor_disability_nm" name="disabili_certifica.minor_disability_nm" placeholder="부장애 및 장애 정도를 입력해 주세요."
                               {...register("disabili_certifica.minor_disability_nm")} disabled={scenarioId === "upload_crtf" || scenarioId === "create_crtf" || scenarioId === "valid_crtf" ? false : true}/>
                    </div>
                </div>
                <div className="wrap_box_input chat">
                    <div className="box_input">
                        <label htmlFor="">종합 장애 정도</label>
                        <input type="text" id="disabili_certifica.total_disability_nm" name="disabili_certifica.total_disability_nm" placeholder="종합 장애 정도를 입력해 주세요."
                               {...register("disabili_certifica.total_disability_nm")} disabled={scenarioId === "upload_crtf" || scenarioId === "create_crtf" || scenarioId === "valid_crtf" ? false : true}/>
                    </div>
                </div>
                <div className="box_division">
                    <div className="wrap_box_input chat">
                        <div className="box_input">
                            <label htmlFor="" className="asterisk">발급일</label>
                            <input type="text" id="disabili_certifica.issuance_dt" name="disabili_certifica.issuance_dt" placeholder="발급일을 입력해 주세요."
                                   {...register("disabili_certifica.issuance_dt", {
                                required: ErrorMessages.required,
                            })} disabled={scenarioId === "upload_crtf" || scenarioId === "create_crtf" || scenarioId === "valid_crtf" ? false : true}/>
                        </div>
                        <p className="msg_err">{ errors.disabili_certifica?.issuance_dt?.message }</p>
                    </div>
                    <div className="wrap_box_input chat">
                        <div className="box_input">
                            <label htmlFor="" className="asterisk">발급기관</label>
                            <input type="text" id="disabili_certifica.issuance_agency" name="disabili_certifica.issuance_agency" placeholder="발급기관을 입력해 주세요." {...register("disabili_certifica.issuance_agency", {
                                required: ErrorMessages.required,
                            })} disabled={scenarioId === "upload_crtf" || scenarioId === "create_crtf" || scenarioId === "valid_crtf" ? false : true}/>
                        </div>
                        <p className="msg_err">{ errors.disabili_certifica?.issuance_agency?.message }</p>
                    </div>
                </div>
                <div className="box_division">
                    <div className="wrap_box_input chat">
                        <div className="box_confirm certifica">
                            <div className="box_input certifica chat">
                                <label htmlFor="" className="asterisk">등록번호</label>
                                <input type="text" id="disabili_certifica.dis_reg_cer_seq"
                                       name="disabili_certifica.dis_reg_cer_seq"
                                       placeholder="등록번호를 입력해 주세요." {...register("disabili_certifica.dis_reg_cer_seq", {
                                    required: ErrorMessages.required,
                                    pattern: {value:/^\d{16}$/,message:"16자리 숫자만 입력 가능 합니다."},
                                    validate: {
                                        duplicate: () => regSeq === true || ErrorMessages.duplicateValidation
                                    }
                                })} disabled={scenarioId === "upload_crtf" || scenarioId === "create_crtf" || scenarioId === "valid_crtf" ? false : true}/>
                                <button type="button" className="btn_submit filled_green certifica" style={{width: "80px"}}
                                        onClick={duplicateCheckNum} disabled={scenarioId === "upload_crtf" || scenarioId === "create_crtf" || scenarioId === "valid_crtf" ? false : true} >중복확인
                                </button>
                            </div>
                        </div>
                        <p className="msg_err">{errors.disabili_certifica?.dis_reg_cer_seq?.message}</p>
                        <p className="msg_id_dup">{(!errors.disabili_certifica?.dis_reg_cer_seq && regSeq === true) && '사용 가능한 등록번호입니다.'}</p>
                    </div>
                    <div className="wrap_box_input">
                        <div className="box_input">
                            <label htmlFor="">문서확인번호</label>
                            <input type="text" id="disabili_certifica.confirm_no" name="disabili_certifica.confirm_no" placeholder="문서확인번호를 입력해 주세요."
                                   {...register("disabili_certifica.confirm_no")} disabled={scenarioId === "upload_crtf" || scenarioId === "create_crtf" || scenarioId === "valid_crtf" ? false : true}/>
                            <p><font color="red">*최근 3개월 이내 서류제출</font></p>
                        </div>
                    </div>
                </div>
                {
                    props.crtfStatus && (
                        <div className="box_division">
                            <div className="wrap_box_input chat">
                                <div className="box_confirm certifica">
                                    <label htmlFor="" className="asterisk">증명서를 다시 업로드 해주세요.</label>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            </div>
        </div>
    )
}

export default InterCrtfCertification;