import React, {useRef, useState} from "react";
import {useFormContext} from "react-hook-form";
import {checkCrtfNum, checkId, countCertification, readOcr} from "../../../api/User/Signup/Signup";
import {useImageFile} from "../../../hooks/useImageFile";
import {usePopupPost} from "../../../hooks/usePopupPost";
import {ErrorMessages} from "../../../utils/Message";

const CrtfCertification = (props) => {
    const { crtf, setCrtf, formData, disabled, regSeq, setRegSeq, isUserInfo } = props;
    const methods = useFormContext();
    const { register, setValue, watch, handleSubmit, clearErrors, reset, setError, formState: { errors }} = methods;
    const crtfInput = useRef(null);
    const [changeCrtf, setChangeCrtf] = useState(false);
    const watchField = watch()

    const uploadCrtf = async (image) => {
        let social_number = '';
        let sex_gb = '';
        let extraAddr = '';

        try {
            formData.append('crtf', image);
            const response = await readOcr(formData);

            if (response.data && response.data.resultCode === 1000 && response.data.resultData && isUserInfo === false) {
                const {data} = response.data.resultData;

                data.social_security_number = data.social_security_number.replace(" ", "")
                if (data.social_security_number.slice(7, 8) % 2 === 1) {
                    sex_gb = "남성";
                } else {
                    sex_gb = "여성";
                }

                if (data.social_security_number.slice(7, 8) < 3) {
                    social_number = "19" + data.social_security_number.slice(0, 2) + "-" + data.social_security_number.slice(2, 4) + "-" + data.social_security_number.slice(4, 6);
                } else {
                    social_number = "20" + data.social_security_number.slice(0, 2) + "-" + data.social_security_number.slice(2, 4) + "-" + data.social_security_number.slice(4, 6);
                }

                var tmp = data.address.split(",");
                if ((tmp[1] === undefined) || (tmp[1] === "")) {
                    tmp = "";
                } else {
                    for (var i = 1; i < tmp.length; i++) {
                        extraAddr += tmp[i];
                        if (i != tmp.length - 1)
                            extraAddr += ","
                    }
                }

                setValue('member_com.member_nm', data.name, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                setValue('disabili_certifica.crtf', image, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                setValue('disabili_certifica.birthday', social_number, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                setValue('disabili_certifica.sex_gb', sex_gb, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                setValue('disabili_certifica.address', data.address.split(",")[0], { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                setValue('disabili_certifica.extraAddr', extraAddr, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                setValue('disabili_certifica.major_disability_nm', data.major_disability, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                setValue('disabili_certifica.minor_disability_nm', data.minor_disability, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                setValue('disabili_certifica.total_disability_nm', data.comprehensive_degree_disability, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                setValue('disabili_certifica.issuance_agency', data.issuing_agency, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                setValue('disabili_certifica.issuance_dt', data.issuing_date, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                setValue('disabili_certifica.dis_reg_cer_seq', data.registration_number, { shouldValidate: true, shouldDirty: true, shouldTouch: true});

            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteImage = () => {
        setCrtf(null);
        setValue('disabili_certifica.crtf_file_path', null);
        setValue('disabili_certifica.crtf_file_path_nm', null);
        setValue('disabili_certifica.crtf_origin_file_nm', null);
    }

    const {uploadImage} = useImageFile(crtfInput, setCrtf, methods, undefined, uploadCrtf, ['pdf']);

    function getPostCode(address, extraAddress) {
        setValue('disabili_certifica.address', address, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
        setValue('disabili_certifica.extraAddr', extraAddress, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
    }

    const { postCode } = usePopupPost(getPostCode);

    const duplicateCheckNum = async (name) => {
        const dis_reg_cer_seq = watch(name);

        if (dis_reg_cer_seq === "") {
            setError(name,{
                message: ErrorMessages.required
            }, {shouldFocus: true});
        } else if (!/^\d{16}$/.test(dis_reg_cer_seq)) {
            setError(name,{
                message: "16자리 숫자만 입력 가능 합니다."
            }, {shouldFocus: true});
        } else {
            try {
                const response = await countCertification(dis_reg_cer_seq);

                if (response.data.resultData !== 0) {
                    setError(name, {
                        message: ErrorMessages.duplicateCrtfNum
                    }, {shouldFocus: true});
                    setValue(name, "", {shouldDirty: true, shouldTouch: true});
                    setRegSeq(false);
                } else {
                    clearErrors(name)
                    setRegSeq(true);
                }

            } catch (error) {
                console.log(error)
            }
        }

    }

    return (
        <div className="wrap_signup_input wrap_signup_detail">
            <div className="box_sub_title">
                <h4 className="sub_title_signup">장애인증명서</h4>
            </div>
            <div className="wrap_box_input">
                <div className="wrap_attached_file">
                    { crtf === null  &&
                    <div className="box_attached_file">
                        <input id="attachedFileCertificate" type="file" style={{ display: "none" }}
                               ref={crtfInput} onChange={uploadImage} accept='.pdf' multiple/>
                        <button id="crtfFile" type="button" className="attached_file btn_submit filled_green" onClick={() => {crtfInput.current.click()}}> 파일첨부 </button>
                        <p className="attached_note">※ PDF 형식의 파일만 업로드 가능합니다.<br />※ 10MB 이하만 업로드 가능합니다.</p>
                    </div>
                    }
                    { crtf !== null &&
                    <div className="attached_file_viewer">
                        <ul className="attached_file_list">
                            <li className="attached_file_item">
                                <a className="attached_anchor"><span>{crtf ? crtf.name : watchField.disabili_certifica.image_file_path_nm}</span></a>
                                <button className="btn_attached_delete certificate"
                                        title="첨부파일 삭제" type="button" onClick={deleteImage}></button>
                            </li>
                        </ul>
                    </div>
                    }
                </div>
            </div>

            <div className="box_division">
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">이름</label>
                        <input type="text" id="member_com.member_nm" name="member_com.member_nm" placeholder="이름을 입력해 주세요." {...register("member_com.member_nm", {
                            required: ErrorMessages.required,
                        })}/>
                    </div>
                    <p className="msg_err">{ errors.member_com?.member_nm?.message }</p>
                </div>
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">성별</label>
                        <input type="text" id="disabili_certifica.sex_gb" name="disabili_certifica.sex_gb" placeholder="성별을 입력해 주세요." {...register("disabili_certifica.sex_gb", {
                            required: ErrorMessages.required,
                        })}/>
                    </div>
                    <p className="msg_err">{ errors.disabili_certifica?.sex_gb?.message }</p>
                </div>
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">생년월일</label>
                        <input type="text" id="disabili_certifica.birthday" name="disabili_certifica.birthday" placeholder="생년월일을 입력해 주세요." {...register("disabili_certifica.birthday", {
                            required: ErrorMessages.required,
                            pattern: {
                                value: /^\d{4}-\d{2}-\d{2}$/,
                                message: ErrorMessages.inValidBirthday
                            }
                        })}/>
                    </div>
                    <p className="msg_err">{ errors.disabili_certifica?.birthday?.message }</p>
                </div>
            </div>
            <div className="wrap_box_input focus_in">
                <div className="box_confirm">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">주소</label>
                        <input type="text" id="disabili_certifica.address" name="disabili_certifica.address" placeholder="주소를 입력해 주세요." {...register("disabili_certifica.address", {
                            required: true,
                        })}  disabled={disabled} readOnly={true}/>
                    </div>
                    <button type="button" className="btn_submit filled_green" onClick={postCode}  disabled={disabled}>우편번호 찾기</button>
                </div>
                <div className="box_input">
                    <input type="text" id="disabili_certifica.extraAddr" name="disabili_certifica.extraAddr" placeholder="상세주소를 입력해 주세요." {...register("disabili_certifica.extraAddr", {
                        required: true,
                    })}  disabled={disabled}/>
                </div>
                <p className="msg_err">{ (errors.disabili_certifica?.address || errors.disabili_certifica?.extraAddr) && '필수 입력 항목입니다.' }</p>
            </div>
            <div className="wrap_box_input">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">주장애 및 장애 정도</label>
                    <input type="text" id="disabili_certifica.major_disability_nm" name="disabili_certifica.major_disability_nm" placeholder="주장애 및 장애 정도를 입력해 주세요." {...register("disabili_certifica.major_disability_nm", {
                        required: ErrorMessages.required,
                    })}/>
                </div>
                <p className="msg_err">{ errors.disabili_certifica?.major_disability_nm?.message }</p>
            </div>
            <div className="wrap_box_input">
                <div className="box_input">
                    <label htmlFor="">부장애 및 장애 정도</label>
                    <input type="text" id="disabili_certifica.minor_disability_nm" name="disabili_certifica.minor_disability_nm" placeholder="부장애 및 장애 정도를 입력해 주세요." {...register("disabili_certifica.minor_disability_nm")}/>
                </div>
            </div>
            <div className="wrap_box_input">
                <div className="box_input">
                    <label htmlFor="">종합 장애 정도</label>
                    <input type="text" id="disabili_certifica.total_disability_nm" name="disabili_certifica.total_disability_nm" placeholder="종합 장애 정도를 입력해 주세요." {...register("disabili_certifica.total_disability_nm")}/>
                </div>
            </div>
            <div className="box_division">
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">발급일</label>
                        <input type="text" id="disabili_certifica.issuance_dt" name="disabili_certifica.issuance_dt" placeholder="발급일을 입력해 주세요." {...register("disabili_certifica.issuance_dt", {
                            required: ErrorMessages.required
                        })}/>
                    </div>
                    <p className="msg_err">{ errors.disabili_certifica?.issuance_dt?.message }</p>
                </div>
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">발급기관</label>
                        <input type="text" id="disabili_certifica.issuance_agency" name="disabili_certifica.issuance_agency" placeholder="발급기관을 입력해 주세요." {...register("disabili_certifica.issuance_agency", {
                            required: ErrorMessages.required,
                        })}/>
                    </div>
                    <p className="msg_err">{ errors.disabili_certifica?.issuance_agency?.message }</p>
                </div>
            </div>
            <div className="box_division">
                {
                    changeCrtf === false ? (
                        <div className="wrap_box_input">
                            <div className="box_confirm certifica">
                                <div className="box_input certifica">
                                    <label htmlFor="" className="asterisk">등록번호</label>
                                    <input type="text" id="disabili_certifica.dis_reg_cer_seq"
                                           name="disabili_certifica.dis_reg_cer_seq"
                                           placeholder="등록번호를 입력해 주세요." {...register("disabili_certifica.dis_reg_cer_seq", {
                                        required: ErrorMessages.required,
                                        pattern: {value:/^\d{16}$/,message:"16자리 숫자만 입력 가능 합니다."},
                                        ...( isUserInfo === false && {
                                            validate: {
                                                duplicate: () => regSeq === true || ErrorMessages.duplicateValidation
                                            }
                                        })
                                    })} readOnly={isUserInfo === true ? true : false}/>
                                    {   (changeCrtf === false && isUserInfo === false) ? (
                                            <button type="button" className="btn_submit filled_green certifica"
                                                    onClick={() => duplicateCheckNum("disabili_certifica.dis_reg_cer_seq")}>중복확인
                                            </button>
                                        ) : (
                                            <button type="button" className="btn_submit filled_green certifica"
                                                    onClick={()=> {setChangeCrtf(true); setValue("disabili_certifica.new_drcs", "")}}>변경하기
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                            <p className="msg_err">{errors.disabili_certifica?.dis_reg_cer_seq?.message}</p>
                            <p className="msg_id_dup">{(!errors.disabili_certifica?.dis_reg_cer_seq && regSeq === true) && '사용 가능한 등록번호입니다.'}</p>
                        </div>
                    ) : (
                        <div className="wrap_box_input">
                            <div className="box_confirm certifica">
                                <div className="box_input certifica">
                                    <label htmlFor="" className="asterisk">등록번호</label>
                                    <input type="text" id="disabili_certifica.new_drcs" name="disabili_certifica.new_drcs" placeholder="등록번호를 입력해 주세요."
                                        {...register("disabili_certifica.new_drcs", {
                                        required: ErrorMessages.required,
                                        pattern: {value:/^\d{16}$/,message:"16자리 숫자만 입력 가능 합니다."},
                                        validate: {
                                            duplicate: () => regSeq === true || ErrorMessages.duplicateValidation
                                        }
                                    })} defaultValue=""/>
                                    <button type="button" className="btn_submit filled_green certifica" onClick={() => duplicateCheckNum("disabili_certifica.new_drcs")}>중복확인</button>
                                </div>
                            </div>
                            <p className="msg_err">{errors.disabili_certifica?.new_drcs?.message}</p>
                            <p className="msg_id_dup">{(!errors.disabili_certifica?.new_drcs && regSeq === true) && '사용 가능한 등록번호입니다.'}</p>
                        </div>
                    )
                }
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="">문서확인번호</label>
                        <input type="text" id="disabili_certifica.confirm_no" name="disabili_certifica.confirm_no" placeholder="문서확인번호를 입력해 주세요." {...register("disabili_certifica.confirm_no")}/>
                        <p><font color="red">*최근 3개월 이내 서류제출</font></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CrtfCertification;