
import React from 'react';
import api from '../../../api/api';
const CancelWarningModal = (props) => {  
    const deleteApplicant = (e) => {
        e.preventDefault();
        api.post('/api/user/interview/updateViewYn',props.cancelWarning).then(response => response.data)
            .then((response) => {
                if(response && response.resultCode == 1000){
                } else {
                    //alert("일치하는 정보가 없습니다.");
                }
            }
        );

        window.location.href='/user/interview/InterviewMain';
    }
    const close_popup = (e) =>{
        e.preventDefault();
        props.setCancelWarningModal(false)
    }
    return (
        <>
            <div className="popup_layer popup_small" id="popWarning02">
                <div className="popup_inner">          
                    <div className="popup_contents">
                        <p>해당 내역 삭제 시 귀하의 지원현황을<br/>추후 확인할 수 없습니다.</p>
                        <div className="popup_small_btn_wrap">
                            <a href="javascript:;" target="_self" className="btn_submit filled_black" onClick={(e)=>close_popup(e)}>취소</a>
                            <a href="javascript:;" target="_self" className="btn_submit filled_green" onClick={(e)=>{deleteApplicant(e)}}>확인</a>
                        </div>
                    </div>    
                </div>
            </div>
        </>
    )
};

export default CancelWarningModal;