const CompSignupsuccess = () => {

    return (
        <div className="wrapper">
            <main>
                <div className="join page page_join page_join_complete">
                    <h2 className="page_title">기업회원 가입하기</h2>
                    <div className="page_inner">
                        <div className="area_shade">
                            <div className="wrap_txt">
                                <h3>회원가입이 완료되었습니다.</h3>
                                <p>회원이 되신 것을 환영합니다!<br/>브이드림의 다양한 서비스를 만나보세요.</p>
                            </div>
                        </div>
                        <div className="wrap_btns flex_center">
                            <a href="/" className="btn_submit filled_green">로그인</a>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default CompSignupsuccess;