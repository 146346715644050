import React, {Component, useEffect, useState,useCallback} from 'react';
import api, { decodeToken } from '../../../api/api';
import InterviewList from './InterviewList';
import ApplicantInfoModal from '../applicant/applicantInfoModal';
import ConfirmModal from '../applicant/confirmModal';
import QuestionManage from './QuestionManage';


const InterviewMain = () => {
   
    const [result, setResult] = useState(false);  
    const [detailInfo, setDetailInfo] = useState(false);  
    const [menu, setMenu] = useState(true);
    const [applicantModal, setApplicantModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [confirmModalType, setConfirmModalType] = useState(null);
    const [updateChk, setUpdateChk] = useState(false);
    const [updateParam, setUpdateParam] = useState({
        "comp_evaluation" : null,
        "recruit_seq" : null,
        "announcement_seq" : null
    });
    
    const onClickMenu = (e) => {
        if(e.target.className.indexOf("active") < 0){
            setMenu((menu) => !menu);
        }
        
    }; 
    
    const applicantDetailModal = async(annNum, recNum) => {
        let detailParam = {
            "announcement_seq" : 39,
            "recruit_seq" : 497
        }
        annNum && (detailParam.announcement_seq = annNum);
        recNum && (detailParam.recruit_seq = recNum);
        try{
            await api.post("/api/comp/applicant/getApplicantInfo", detailParam).then(response =>
                response.data
                ).then((response) => {
                    response && response.resultData && setDetailInfo(response.resultData);
                })
        } catch(e){

        } finally{
            setApplicantModal(true);
        }
    }

    return (
        <div className="wrapper">
            {/*<!-- 본문 -->*/}
            <main>
                <div className="page page_applicant_list page_layout_40">
                    <div className="page_back_title">
                        <h2 className="page_back_title border_bottom_line">
                            면접 관리               
                        </h2>
                    </div>
                    <ul className="tab_menu">
                        <li className="tab_menu_list"><a href="#" target="_self" className={"tab_page_01" + (menu ? " active" : "")} onClick={onClickMenu}>면접일정 관리</a></li>
                        <li className="tab_menu_list"><a href="#" target="_self" className={"tab_page_02" + (menu ? "" : " active")} onClick={onClickMenu}>면접질문 관리</a></li>                   
                    </ul>
                    
                    <div className="page_inner wrap_list_table applicant_list">
                        {
                            menu ? <InterviewList detailModal={applicantDetailModal} />  
                                : <QuestionManage />
                        }                        
                    </div>
                </div>    
            </main>
            {
                applicantModal && <ApplicantInfoModal result={detailInfo} setModal={setApplicantModal}  setConfirmModal={setConfirmModal} setConfirmModalType={setConfirmModalType}  setUpdateParam={setUpdateParam} />
            }
            {
                confirmModal && <ConfirmModal setModal={setConfirmModal} type={confirmModalType} setUpdateChk={setUpdateChk} updateParam={updateParam}/>
            }
        </div>

        
    );
};

export default InterviewMain;