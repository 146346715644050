import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useHistory, useLocation} from 'react-router-dom';
import CrtfCertification from "./CrtfCertification";
import AddInfo from "./AddInfo";
import SnsMemberCom from "./SnsMemberCom";
import {createUser, uploadImage} from "../../../api/User/Signup/Signup";
import {ErrorMessages} from "../../../utils/Message";

const init =  {
    // 구직자 회원 계정
    member_com : {
        member_pw: "NO_PASS",
        gubun: "USER",
    }
}

const SnsSignup = () => {
    const methods = useForm({
        mode: 'onChange',
        defaultValues:init
    });
    const { register, setValue, watch, handleSubmit, clearErrors, reset, setError, formState: { errors, isValid }} = methods
    const formData = new FormData();
    const [phone, setPhone] = useState({
        send : false,
        num : undefined,
        valid : false,
        pass : false
    });
    // const [socialType, setSocialType] = useState("");
    const [crtf, setCrtf] = useState(null);
    const [image, setImage] = useState(null);
    const [regSeq, setRegSeq] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false)
    const watchField = watch()
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if(!isSubmit) return
        Object.entries(errors).forEach(([key, value]) => {
            Object.keys(value).forEach((name) => {
                setValue(key + "." + name, "", {shouldValidate: false, shouldDirty: true})
            })
        })
        setIsSubmit(false)
        AlertError()
    }, [errors, isSubmit])

    const AlertError = () => {

        if (errors.member_info?.phone_no || errors.member_info?.validNum) {
            alert("휴대폰번호 입력정보를 확인해주세요.")
        } else if (errors.member_com?.email) {
            alert("이메일 입력정보를 확인해주세요.")
        } else if (errors.member_com?.member_nm) {
            alert("이름 입력정보를 확인해주세요.")
        } else if (errors.disabili_certifica?.sex_gb) {
            alert("성별 입력정보를 확인해주세요.")
        } else if (errors.disabili_certifica?.birthday) {
            alert("생년월일 입력정보를 확인해주세요.")
        } else if (errors.disabili_certifica?.address || errors.disabili_certifica?.extraAddr) {
            alert("주소 입력정보를 확인해주세요")
        } else if (errors.disabili_certifica?.major_disability_nm) {
            alert("주장애 및 장애 정도 입력정보를 확인해주세요")
        } else if (errors.disabili_certifica?.issuance_dt) {
            alert("발급일 입력정보를 확인해주세요.")
        } else if (errors.disabili_certifica?.issuance_agency) {
            alert("발급기관 입력정보를 확인해주세요.")
        } else if (errors.disabili_certifica?.dis_reg_cer_seq) {
            alert("장애인 등록번호를 확인해주세요.")
        } else if (errors.member_info?.image_file_path) {
            alert("이력서 사진을 첨부해주세요.")
        }
    }

    const extraValid = () => {
        if (image === null) {
            setError("member_info.image_file_path", {
                message: ErrorMessages.required
            }, {shouldFocus: true});
        }
    }

    // 가입하기
    const onSubmit = async (data) => {
        extraValid();

        if (image !== null) {
            try {
                data.member_com.member_id = location.state.id;
                data.member_com.provider_type = location.state.providerType;
                data.disabili_certifica.address =  data.disabili_certifica.address + "|" + data.disabili_certifica.extraAddr;
                data.member_info.phone_no = data.member_info.phone_no.replaceAll("-", "");

                await createUser(data).then((response) => {
                    if (response.data.resultCode === 1000) {
                        if (crtf !== null) {

                            formData.append('image', crtf);
                            formData.append('imageNm', data.disabili_certifica.dis_reg_cer_seq + "." + crtf.type.split('/')[1]);
                            formData.append('originFileNm', crtf.name);
                            formData.append('memSeq', response.data.resultData)

                            uploadImage(formData);
                        }
                    }
                });

                window.location.href="/user/signup/signupsuccess";

            } catch (error) {
                console.log(error);
            }

            setIsSubmit(true);

        }

    }

    const onError = async e => {
        extraValid();

        setIsSubmit(true);
    }

    return (
        <div>
            <div className="wrapper">
                <main>
                    <div className="join page_join page_join_vdream">
                        <h2 className="page_title">회원 가입하기</h2>
                        <div className="wrap_progressbar">
                            <ul className="progressbar_list progress_step03">
                                <li className="progressbar_item progress_item01"><p>이용약관 동의</p></li>
                                <li className="progressbar_item progress_item02"><p>계정유형 선택</p></li>
                                <li className="progressbar_item progress_item03"><p>회원정보 등록</p></li>
                            </ul>
                        </div>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit, onError)}>
                                <div className="page_inner">
                                    <div className="area_shade">
                                        <SnsMemberCom location={location} phone={phone} setPhone={setPhone} />
                                        <CrtfCertification crtf={crtf} setCrtf={setCrtf} formData={formData} disabled={false} regSeq={regSeq} setRegSeq={setRegSeq} isUserInfo={false} />
                                        <AddInfo image={image} setImage={setImage} isUserInfo={false}  />
                                    </div>
                                    <div className="wrap_btns">
                                        <a href="/user/signup/CountType" target="_self"
                                           className="btn_submit filled_black">이전으로</a>
                                        <button type="submit" className="btn_submit filled_green">가입하기</button>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default SnsSignup;