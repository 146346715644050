import {Fragment, useEffect, useState} from 'react';
import dayjs from "dayjs";
import {applyAnnouncement, getResumeDesire} from "../../../api/User/Jop/Job";

const ResumePopup = (props) => {
    const { setResumeModal, postData, setDisabled } = props;
    const [desireNames, setDesireNames] = useState([]);
    const [radioValue, setRadioValue] = useState(null);

    useEffect(() => {
        try {
            getResumeDesire().then((response) => {
                if (response.data.resultCode === 1000 && response.data.resultData) {
                    setDesireNames(response.data.resultData)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }, [])

    const disableModal = () =>{
        setTimeout(() => {
            setResumeModal(false);
        }, 300);
    }

    const handleRadio = (e) => {
        setRadioValue(parseInt(e.target.defaultValue))
    }

    const applyResume = () => {
        try {
            if (radioValue !== null) {
                applyAnnouncement(postData.enterprise.seq ,radioValue, postData.comInfo.company_seq).then((response) => {
                    if (response.data.resultCode === 1000 && response.data.resultData) {
                        alert("이력서가 지원되었습니다.")
                        setResumeModal(false)
                        setDisabled((prev) => prev + 1);
                    }
                })
            } else {
                alert("이력서를 선택해주세요.")
            }
        } catch (e) {

        }
    }

    return (
        <Fragment>
            <div className="popup_mask" style={{display: "block"}} onClick={disableModal}></div>
            <div className="popup_layer popAddResume"id="popAddResume" style={{display: "block"}}>
                <div className="popup_inner">
                    <div className="pop_title">
                        <h3>이력서 선택하기</h3>
                        <button type="button" aria-label="레이어 팝업 닫기" className="popup_close" onClick={() => setResumeModal(false)}></button>
                    </div>
                    <div className="popup_contents">
                        <div className="wrap_box_resume">
                            {
                                desireNames ? desireNames.map((data, index) => (
                                    index % 3 == 0 && (
                                        <div className="box_resume" key={data.resume_seq}>
                                            <input type="radio" id={"resume01 " + data.resume_seq} name={"select_resume " + data.resume_seq}
                                                   className="resume_chkbox" value={data.resume_seq} checked={data.resume_seq === radioValue} onChange={handleRadio} />
                                            <label htmlFor={"resume01 " + data.resume_seq} className="resume_detail">
                                                <span className="resume_title">{data.depth4_name}</span>
                                                <span className="resume_written_date">{dayjs(data.modify_dt).format("YYYY-MM-DD")}</span>
                                            </label>
                                        </div>
                                    )
                                )) : null
                            }
                        </div>
                        <div className="btn_wrap_centered">
                            <button type="button" className="btn_submit filled_black" onClick={() => setResumeModal(false)}>취소</button>
                            <button type="button" className="btn_submit filled_green" onClick={applyResume}>확인</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ResumePopup;