import React from "react";
import {Link} from "react-router-dom";

const ContractModal = (props) => {
    const { setModal } = props;

    return (
        <React.Fragment>
            <div className="popup_mask" style={{"display" : "block", zIndex : 1011}} onClick={(e) => {e.preventDefault(); setModal(false);}}></div>
            <div className="popup_layer popup_small" >
                <div className="popup_inner">
                    <div className="popup_contents">
                        <p dangerouslySetInnerHTML={{__html :  "사용자 계약완료 되어야 사용이 가능합니다.\n시스템계약서 확인 페이지에서 다음내용을 확인하세요!"}}></p>
                        <div className="popup_small_btn_wrap">
                            <a href="#" target="_self" className="btn_submit filled_black" onClick={(e) => {e.preventDefault(); setModal(false);}}>닫기</a>
                            <Link to={{pathname:"/comp/signup/compInfoManage", state: {toContract: true}}} className="btn_submit filled_green" onClick={() => setModal(false)}>이동하기</Link>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ContractModal;