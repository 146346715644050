import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ErrorMessages} from "../../../utils/Message";
import {useImageFile} from "../../../hooks/useImageFile";


const Disability = (props) => {
    const { methods, disabled: propsDisabled, postFile = null, setPostFile,errors} = props;
    const { register, watch, setValue, clearErrors } = methods;
    const watchField = watch();
    const disabled = propsDisabled !== undefined ? propsDisabled : false;
    const [isIrrelevantRadioChecked, setIsIrrelevantRadioChecked] = useState(false);
    const postFileRef = useRef(null);

    useEffect(()=>{
        setValue('disability.handicapped_type[0].disability_status', 'Y');
    },[])

    useEffect(() => {
        if (watchField.disability.handicapped_type[0].disability_status === 'Y')
            setIsIrrelevantRadioChecked(false);
        else
            setIsIrrelevantRadioChecked(true);

    }, [watchField.disability]);

    const callback = (image) => {
        clearErrors(["enterprise.enterprise_file_path", "enterprise.enterprise_file_nm"])
        setValue("enterprise.enterprise_file_nm",image.name);
    }

    const {uploadBase64} = useImageFile(postFileRef, setPostFile, methods, "enterprise.enterprise_file_path", callback, ['jpg', 'jpeg', 'png']);

    const deleteImage = () => {
        setValue("enterprise.enterprise_file_path","");
        setValue("enterprise.enterprise_file_nm","");
    }

    const handleIrrelevantTypeRadioClick = () => {
        setIsIrrelevantRadioChecked(!isIrrelevantRadioChecked);
        if (!isIrrelevantRadioChecked) {
            setValue('disability.handicapped_type[0].disability_status', 'N')
            setValue('disableNameData', [])
        }else{
            setValue('disability.handicapped_type[0].disability_status', 'Y')
        }
    }

    const validateTextarea = (value) => {
        return value.length <= 2000 ? true : "최대 2000자 까지 입력 가능합니다.";
    };

    useEffect(() => {
        const checkboxes = document.querySelectorAll('.wrap_chk_icon input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.disabled = isIrrelevantRadioChecked || disabled;
            if (isIrrelevantRadioChecked || disabled) checkbox.checked = false;
        });
    }, [isIrrelevantRadioChecked, disabled]);

    return (
        <>
            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">지원가능 장애유형(복수선택 가능)</label>
                    <p className="msg_err disability_check">{errors?.disableNameData?.message}</p>
                    <div className="box_input_chk_round">
                        <label htmlFor="temp01">
                            <input type="checkbox" 
                                   className="input_chk"
                                   id="temp01"
                                   defaultValue="-1"
                                   name=""
                                   defaultChecked={isIrrelevantRadioChecked}
                                   onClick={handleIrrelevantTypeRadioClick}
                                   disabled={disabled}
                            ></input>
                            무관
                        </label>
                    </div>

                    <div className="wrap_chk_icon">
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp01" className="btn_chk_icon btn_icon_01" defaultValue="1"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`, {
                                       required: !isIrrelevantRadioChecked ? ErrorMessages.required : undefined
                                   })}
                            />
                            <label className="" htmlFor="icon_temp01">지체장애</label>
                        </div>
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp02" className="btn_chk_icon btn_icon_02" defaultValue="2"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`)}
                            />
                            <label className="" htmlFor="icon_temp02">호흡기장애</label>
                        </div>
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp03" className="btn_chk_icon btn_icon_03" defaultValue="3"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`)} />
                            <label className="" htmlFor="icon_temp03">시각장애</label>
                        </div>
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp04" className="btn_chk_icon btn_icon_04" defaultValue="4"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`)} />
                            <label className="" htmlFor="icon_temp04">청각장애</label>
                        </div>
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp05" className="btn_chk_icon btn_icon_05" defaultValue="5"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`)} />
                            <label className="" htmlFor="icon_temp05">언어장애</label>
                        </div>
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp06" className="btn_chk_icon btn_icon_06"defaultValue="6"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`)} />
                            <label className="" htmlFor="icon_temp06">안면장애</label>
                        </div>
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp07" className="btn_chk_icon btn_icon_07" defaultValue="7"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`)} />
                            <label className="" htmlFor="icon_temp07">자폐성장애</label>
                        </div>
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp08" className="btn_chk_icon btn_icon_08" defaultValue="8"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`)} />
                            <label className="" htmlFor="icon_temp08">정신장애</label>
                        </div>
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp09" className="btn_chk_icon btn_icon_09" defaultValue="9"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`)} />
                            <label className="" htmlFor="icon_temp09">뇌병변장애</label>
                        </div>
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp10" className="btn_chk_icon btn_icon_10" defaultValue="10"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`)} />
                            <label className="" htmlFor="icon_temp10">신장장애</label>
                        </div>
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp11" className="btn_chk_icon btn_icon_11" defaultValue="11"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`)} />
                            <label className="" htmlFor="icon_temp11">심장장애</label>
                        </div>
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp12" className="btn_chk_icon btn_icon_12" defaultValue="12"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`)} />
                            <label className="" htmlFor="icon_temp12">간장애</label>
                        </div>
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp13" className="btn_chk_icon btn_icon_13" defaultValue="13"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`)} />
                            <label className="" htmlFor="icon_temp13">장루/요루장애</label>
                        </div>
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp14" className="btn_chk_icon btn_icon_14" defaultValue="14"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`)} />
                            <label className="" htmlFor="icon_temp14">뇌전증장애</label>
                        </div>
                        <div className="box_chk_icon">
                            <input type="checkbox" id="icon_temp15" className="btn_chk_icon btn_icon_15" defaultValue="15"
                                   name={`disableNameData`}
                                   {...register(`disableNameData`)} />
                            <label className="" htmlFor="icon_temp15">지적장애</label>
                        </div>

                    </div>

                </div>

            </div>


            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="">장애정도</label>
                    <div className="wrap_box_radio flex_column">
                        <div className="input_radio">
                            <label htmlFor="temp20" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp20" name="word"
                                       defaultValue="중증(장애의 정도가 심한 장애인, 기존 장애등급1~3등급)" {...register('disability.disability_type.disability_name')}
                                       disabled={disabled}
                                ></input>
                                중증 (장애의 정도가 심한 장애인, 기존 장애등급 1~3등급)</label>
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp21" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp21" name="word"
                                       defaultValue="경증(심하지 않은 장애인, 기존 장애등급 4~6등급)" {...register('disability.disability_type.disability_name')}
                                       disabled={disabled}>

                                </input>
                                경증 (심하지 않은 장애인, 기존 장애등급 4~6등급)</label>
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp22" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp22" name="word"
                                       defaultValue="무관" {...register('disability.disability_type.disability_name')}
                                       disabled={disabled}>

                                </input>
                                무관 </label>
                        </div>
                    </div>
                </div>
            </div>


            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">주요업무</label>
                    <textarea id="comInfo.main_duty" maxLength="2001" placeholder="주요업무를 입력해주세요." name="comInfo.main_duty"
                              {...register("comInfo.main_duty", {
                                  required: ErrorMessages.required,
                                  validate: validateTextarea,
                                  maxLength: {value:2000,message:"2000자 까지 입력 가능합니다."}

                              })}
                              disabled={disabled}
                    ></textarea>
                    {errors?.comInfo?.main_duty?.type === 'validate' && (
                        <p className="msg_warning in_textarea">
                            {errors?.comInfo?.main_duty?.message} <span>{watchField.comInfo?.main_duty?.length} / 2000</span>
                            
                        </p>
                    )}
                </div>
                <p className="msg_err">{errors?.comInfo?.main_duty?.message}</p>
            </div>

            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">자격요건</label>
                    <textarea id="" maxLength="2001" placeholder="자격요건을 입력해주세요."
                              name={`comInfo.qualification`}
                              {...register(`comInfo.qualification`, {
                                  required: ErrorMessages.required,
                                  validate: validateTextarea,
                                  maxLength: {value:2000,message:"2000자 까지 입력 가능합니다."}
                              })}
                              disabled={disabled}
                    ></textarea>
                    {errors?.comInfo?.qualification?.type === 'validate' && (
                        <p className="msg_warning in_textarea">
                            {errors?.comInfo.qualification.message} <span>{watchField.comInfo.qualification.length} / 2000</span>
                        </p>
                    )}
                </div>
                <p className="msg_err">{errors?.comInfo?.qualification?.message}</p>
            </div>

            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">복리후생 </label>
                    <textarea id="" maxLength="2001" placeholder="복리후생을 입력해주세요."
                              name={`comInfo.benefits`}
                              {...register(`comInfo.benefits`, {
                                  required: ErrorMessages.required,
                                  validate: validateTextarea,
                                  maxLength: {value:2000,message:"2000자 까지 입력 가능합니다."}
                              })}
                              disabled={disabled}
                    ></textarea>
                    {errors?.comInfo?.benefits?.type === 'validate' && (
                        <p className="msg_warning in_textarea">
                            {errors?.comInfo.benefits.message} <span>{watchField.comInfo.benefits.length} / 2000 </span>
                        </p>
                    )}
                </div>
                <p className="msg_err">{errors?.comInfo?.benefits?.message}</p>
            </div>

            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">기타사항</label>
                    <textarea id="" maxLength="2001" placeholder="기타사항을 입력해주세요."
                              name={`comInfo.other_info`}
                              {...register(`comInfo.other_info`, {
                                  required: false,
                                  validate: validateTextarea,
                                  maxLength: {value:2000,message:"2000자 까지 입력 가능합니다."}
                              })}
                              disabled={disabled}
                    ></textarea>
                    {errors?.comInfo?.other_info?.type === 'validate' && (
                        <p className="msg_warning in_textarea">
                            {errors?.comInfo.other_info.message} <span>{watchField.comInfo.other_info.length} / 2000 </span>
                        </p>
                    )}
                </div>
                <p className="msg_err">{errors?.comInfo?.other_info?.message}</p>
            </div>

            <div className="wrap_box_input">
                <div className="box_input">
                    <label htmlFor="">파일첨부</label>
                    <div className="wrap_attached_file">
                        {
                            watchField.enterprise.enterprise_file_path === "" ? (
                                <div className="box_attached_file">
                                    <input type="file" id="attachedFileImage" ref={postFileRef} accept=".jpg, .png" style={{display:"none"}}
                                        onChange={uploadBase64}
                                    ></input>
                                    <button type="button" className="attached_file btn_submit filled_green" disabled={disabled}
                                            onClick={(e) => {postFileRef.current.click()}}>파일선택
                                    </button>
                                    <p className="attached_note">※ JPG, PNG 형식의 파일만 업로드 가능합니다.</p>
                                </div>
                            ) : (
                                <div className="attached_file_viewer">
                                    <ul className="attached_file_list">
                                        <li className="attached_file_item">
                                            <a className="attached_anchor"><span>{watchField.enterprise.enterprise_file_nm !== "" ? watchField.enterprise.enterprise_file_nm : null}</span></a>
                                            <button className="btn_attached_delete certificate" title="첨부파일 삭제" type="button"
                                                    onClick={deleteImage} disabled={disabled}></button>
                                        </li>
                                    </ul>
                                </div>
                            )
                        }
                    </div>
                </div>
                <p className="msg_err">{errors?.comInfo?.manager_nm?.message}</p>
            </div>

        </>
    )
}
export default Disability;

