import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
const SocialLogin = () => {
    const socialSites = [
        {
            name: 'Google',
            icon: '/img/icon/login_google.png',
            url: '/api/oauth2/authorization/login/google?redirect_uri=https://matching.vdream.co.kr/auth/redirect/login'
            //url: '/api/oauth2/authorization/login/google?redirect_uri=http://localhost:3000/auth/redirect/login'
        },
        {
            name: 'Kakao',
            icon: '/img/icon/login_kakao.png',
            url: '/api/oauth2/authorization/login/kakao?redirect_uri=https://matching.vdream.co.kr/auth/redirect/login'
            //url: '/api/oauth2/authorization/login/kakao?redirect_uri=http://localhost:3000/auth/redirect/login'
        },
        {
            name: 'Naver',
            icon: '/img/icon/login_naver.png',
            url: '/api/oauth2/authorization/login/naver?redirect_uri=https://matching.vdream.co.kr/auth/redirect/login'
            //url: '/api/oauth2/authorization/login/naver?redirect_uri=http://localhost:3000/auth/redirect/login'
        },
        {
            name: 'Facebook',
            icon: '/img/icon/login_facebook.png',
            url: '/api/oauth2/authorization/login/facebook?redirect_uri=https://matching.vdream.co.kr/auth/redirect/login'
            //url: '/api/oauth2/authorization/login/facebook?redirect_uri=http://localhost:3000/auth/redirect/login'
        }
    ];


    return (
        <>
            <div className="wrap_social_login">
                <p>소셜로그인</p>
                <ul className="social_login_list">
                    {socialSites.map(site => (
                        <li className="social_login_item" key={site.name}>
                            <a
                                href={site.url} // Set the URL here
                                className="social_login_anchor"
                                title={`${site.name} 아이디로 로그인`}
                            >
                                <img src={site.icon} alt=""/>
                            </a>
                        </li>
                    ))}
                </ul>

            </div>
        </>


    );
};

export default SocialLogin;
