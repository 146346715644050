import axios from 'axios';
import {useLocation, Redirect} from 'react-router-dom';
import { useHistory } from 'react-router-dom';


const SocialSignUpRedirect = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('code');
    const history = useHistory();

    axios.post('/api/v1/auth/join', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then((response) => {
            const user = response.data.body.user;
            const { username, providerType, userId } = user;
            const userData = { username, providerType, userId };
            const USER = { providerType: userData.providerType, id: userData.userId }

            history.push("/user/signup/snssignup", USER);
        })
};

export default SocialSignUpRedirect;