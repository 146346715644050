import React from 'react';

function PaginationIndividual({ target, total, limit, page, setPage,i }) {
    const numPages = Math.ceil(total / limit);
    const visiblePageCount = 10;
    const currentPageGroup = Math.ceil(page[i] / visiblePageCount);
    const startPage = (currentPageGroup - 1) * visiblePageCount + 1;
    const endPage = Math.min(startPage + visiblePageCount - 1, numPages);
    const handlePrevClick = () => {
        let copiedItems = [...page];
        if (copiedItems[i] > 1) {
            copiedItems[i]=copiedItems[i]-1;
            setPage(copiedItems);
        }
    };

    const handleNextClick = () => {
        let copiedItems = [...page];
        if (copiedItems[i] < numPages) {
            copiedItems[i]=copiedItems[i]+1;
            setPage(copiedItems);
        }
    };
    const changePage = (pageValue) => {
        let copiedItems = [...page];
        copiedItems[i]=pageValue;
        setPage(copiedItems);
    };
    return (
      <>
        <ul className="pagination_list">
                {page[i] > 1 && (
                    <li className="pagination_item">
                        <a className="pagination_anchor prev" onClick={handlePrevClick}></a>
                    </li>
                )}
                {Array.from({ length: endPage - startPage + 1 }, (_, index) => {
                    const pageNumber = startPage + index;
                    return (
                        <li className="pagination_item" key={pageNumber}>
                            <a

                                className={`pagination_anchor ${page[i] === pageNumber ? 'currently' : ''}`}
                                onClick={() => changePage(pageNumber)}
                            >
                                {pageNumber}
                            </a>
                        </li>
                    );
                })}
                {page[i] < numPages && (
                    <li className="pagination_item">
                        <a className="pagination_anchor next" onClick={handleNextClick}></a>
                    </li>
                )}
            </ul>
      </>
    );
}

export default PaginationIndividual;



