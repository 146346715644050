import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useState, useCallback, useEffect } from "react";
import { getBannerList } from "../../api/User/Jop/Job";
import React from "react";

class BannerItemError extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <li></li>;
      }
  
      return this.props.children; 
    }
  }

const BannerItem = ({href,src,errorSrc,alt}) => {
    return (
        <li className="benefit_banner_item">
            <Link to={href||"#"} target="_self" className="benefit_banner_anchor">
                <img src={src != null ? src : errorSrc} alt={alt}/>
            </Link>
        </li>
    );
}


const Banner = () => {
    const [banners,setBanners] = useState([]);
    const [error,setError] = useState(null);

    const bannerError = [{error_image_path :"/img/main/benefit_banner01.png", alt : "장애인 고용 사업자가 알아야 할 시설장비 무상지원방법" },
                    {error_image_path :"/img/main/benefit_banner02.png", alt : "#장애인의무고용#고용장려 고용장려금제도 알고있나요?"},
                    {error_image_path :"/img/main/benefit_banner03.png", alt : "2023 장애인 신규고용장려금 함께 알아볼까요?"}];


    const getBanners = useCallback(
        () => {
            getBannerList(3).then(
                response => {
                    const data = response.data;
                    if(response.status === 200 && data["resultMsg"] === "성공."){
                        setBanners(data["resultData"]);
                        setError(null);
                    }else{
                        setError(Error("배너에러 발생함."));
                    }
                }
            ).catch((e)=>{setError(e)})
        },[]
    );

    useEffect(()=> {
        
        getBanners();
        
    },[]);

    return (
        <section className="section_benefit">
            <h3>혜택&nbsp;&nbsp;|&nbsp;&nbsp;정보</h3>
            <ul className="benefit_banner_list">
               {
                    error != null ? 
                    bannerError.map((e,i)=>{return <BannerItemError key={i}><BannerItem key={i} errorSrc={e.error_image_path} alt={e.alt}/></BannerItemError>;}) 
                    :  
                    banners.map((e,i)=>{return <BannerItemError key={i}><BannerItem key={i} href={e.href} src={e.image_path} errorSrc={e.error_image_path}/></BannerItemError>})
                }
            </ul>
        </section>

    );
}

export default Banner