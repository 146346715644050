import React, { Component } from 'react';
import "./join.css"

const SelectSignup = () => {
    return (
        <div>
            <div className="wrapper">
                <main>
                    <div className="join page_join">
                        <h2 className="page_title">회원가입 진행방식을 선택해 주세요. </h2>
                        <div className="page_inner">
                            <div className="area_box_way">
                                <div className="wrap_box_way">
                                    <div className="box_way_txt">
                                        <p className="badge badge_way">기존방식</p>
                                        <h3>작성형</h3>
                                        <p> 작성양식에 맞춰<br/>
                                            가입하는 방식입니다.</p>
                                    </div>
                                    <a href="/user/signup/termsconditions" target="_self"
                                       className="btn_submit lined_green">시작하기</a>
                                </div>
                                <div className="wrap_box_way">
                                    <div className="box_way_txt">
                                        <p className="badge badge_way">챗봇방식</p>
                                        <h3>1:1 대화형</h3>
                                        <p>챗봇과 1:1 대화형식으로<br/>
                                            회원가입을 진행합니다.</p>
                                    </div>
                                    <a href="/user/signup/intersignup" target="_self" className="btn_submit lined_green">시작하기</a>
                                </div>
                            </div>
                            <p className="join_enterprise font">기업회원 가입을 원하시면 <a href="/comp/signup/termsconditions" target="_self" className="red_color">여기</a>를 선택해 주세요.</p>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default SelectSignup;