const RecJob = (props) => {
    const { user, recJobList, setRecJobList, checkedInter, handleInterest, toDetailPage } = props

    const handleBoxClick = (e) => {
        const className = e.target.className;
        const temp = className.includes(" on") ? className.replace(" on", "") : "accodian_tit_box on";
        e.target.className = temp;
    }

    function formatDate(dateString) {
        const dateObj = new Date(dateString);
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        const weekday = dateObj.toLocaleDateString('ko-KR', { weekday: 'short' });
        return `${month}/${day} (${weekday})`;
    }

    function calculatedDays(dateString) {
        const dateObj = new Date(dateString);
        const timeDiff = dateObj.getTime() - Date.now();
        const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        return daysLeft;
    }

    const onErrorImg = (e) => {
        e.target.src = "/img/bg/aside_left_enterprise_login.png"
        e.target.style.height = "105px"
    }

    return (
        <div className="applicant_list show_a_line content_01">
            <h3 className="content_inner_title"><span>{user?.username}</span>님의 추천직무별 채용정보입니다.</h3>
            <div className="wrap_accodian">
                <ul className="accodian_list">
                    {
                        recJobList && recJobList.map((recJob, index) => (
                        <li className="wrap_accodian_item card_init area_shade" key={index}>
                            <div className={`accodian_tit_box on`} onClick={(e) => handleBoxClick(e)}>
                                <h3 style={{pointerEvents: "none"}}><span>{recJob.depthName.toString().substring(3)}</span> 직무 채용정보</h3>
                            </div>
                            {
                                recJob.recomJobList.length > 0 ? recJob.recomJobList.map((data, index) => (
                                    (index % 4 === 0 && index / 4 === 0) ? (
                                        <div className="section_job_list" key={index}>
                                            <ul className="job_list show_default">
                                                {
                                                    recJob.recomJobList.slice(index, index + 4).map((item, innerIndex) => (
                                                        <li className={`job_list_item`} key={item.enterprise.seq}>
                                                            <div href="#none" target="_self" className="job_item_box">
                                                                <div className="box_top">
                                                                    <p><span>
                                                                        {
                                                                            (
                                                                                calculatedDays(item.incruit.recruit_end_date) >= 0 && item.incruit.employment_type === "") ? ('D-' + calculatedDays(item.incruit.recruit_end_date)
                                                                            ) : (
                                                                                item.incruit.employment_type !== "" ? (item.incruit.employment_type) : ("D+" + calculatedDays(item.incruit.recruit_end_date))
                                                                            )
                                                                        }
                                                                    </span></p>
                                                                    <div className="box_chk_icon">
                                                                        <input type="checkbox" className="add_interest_list" onClick={handleInterest} value={item.enterprise.seq}
                                                                               defaultChecked={checkedInter(item.enterprise.seq) === true ? true : false}/>
                                                                    </div>
                                                                </div>
                                                                <div className="wrap_company_logo">
                                                                    <img src={item.comInfo.file_path} onError={onErrorImg}/>
                                                                </div>
                                                                <h4 className="job_list_title">{item.enterprise.enterprise_nm}</h4>
                                                                <div className="box_detail">
                                                                    <div className="wrap_detail_line">
                                                                        <p>채용인원: <span>{item.incruit.position_num}</span>{item.incruit.position_num === '미정' ? null : '명'}</p>
                                                                        <p>채용조건: <span>{item.condition.employment_condition.indexOf("|") > 0 ? item.condition.employment_condition.substring(0,2) : item.condition.employment_condition}</span></p>
                                                                    </div>
                                                                    <div className="wrap_detail_line" style={{ marginBottom: "unset", height: "32px"}}>
                                                                        <p>채용직무: <span>{item.desire.depth3_name.split(".")[1]}</span></p>
                                                                        <p>근무형태: <span>{item.condition.duty_type}</span></p>
                                                                    </div>
                                                                    <div className="wrap_detail_line">
                                                                        <p>마감일: <span>{ item.incruit.employment_type !== "상시 채용" && item.incruit.employment_type !== "채용 시 마감" ? formatDate(item.incruit.recruit_end_date) : item.incruit.employment_type}</span></p>
                                                                    </div>
                                                                </div>
                                                                <div className="box_badge" style={{ position: "relative", bottom: "8px"}}>
                                                                    <a target="_self" className="badge_btn badge_task btn"
                                                                       onClick={() => {toDetailPage(item)}}>채용정보 자세히 보기</a>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    ) : (index % 4 === 0 && index / 4 === 1) ? (
                                        <div className="accodian_cnt">
                                            <div className="section_job_list">
                                                <ul className="job_list in_accodian">
                                                    {
                                                        recJob.recomJobList.slice(index, index + 4).map((item, innerIndex) => (
                                                            <li className={`job_list_item`} key={data.enterprise.seq}>
                                                                <div href="#none" target="_self" className="job_item_box">
                                                                    <div className="box_top">
                                                                        <p><span>
                                                                            {
                                                                                (
                                                                                    calculatedDays(item.incruit.recruit_end_date) >= 0 && item.incruit.employment_type === "") ? ('D-' + calculatedDays(item.incruit.recruit_end_date)
                                                                                ) : (
                                                                                    item.incruit.employment_type !== "" ? (item.incruit.employment_type) : ("D+" + calculatedDays(item.incruit.recruit_end_date))
                                                                                )
                                                                            }
                                                                        </span></p>
                                                                        <div className="box_chk_icon">
                                                                            <input type="checkbox" className="add_interest_list" onClick={handleInterest} value={item.enterprise.seq}
                                                                                   defaultChecked={checkedInter(data.enterprise.seq) === true ? true : false} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="wrap_company_logo">
                                                                        <img src={item.comInfo.file_path} onError={onErrorImg}/>
                                                                    </div>
                                                                    <h4 className="job_list_title">{item.enterprise.enterprise_nm}</h4>
                                                                    <div className="box_detail">
                                                                        <div className="wrap_detail_line">
                                                                            <p>채용인원: <span>{item.incruit.position_num}</span>{item.incruit.position_num === '미정' ? null : '명'}</p>
                                                                            <p>채용조건: <span>{item.condition.employment_condition.indexOf("|") > 0 ? item.condition.employment_condition.substring(0,2) : item.condition.employment_condition}</span></p>
                                                                        </div>
                                                                        <div className="wrap_detail_line" style={{ marginBottom: "unset", height: "32px"}}>
                                                                            <p>채용직무: <span>{item.desire?.depth3_name.split(".")[1]}</span></p>
                                                                            <p>근무형태: <span>{item.condition.duty_type}</span></p>
                                                                        </div>
                                                                        <div className="wrap_detail_line">
                                                                            <p>마감일: { item.incruit.employment_type !== "상시 채용" && item.incruit.employment_type !== "채용 시 마감" ? formatDate(item.incruit.recruit_end_date) : item.incruit.employment_type} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="box_badge" style={{ position: "relative", bottom: "8px"}}>
                                                                        <a target="_self" className="badge_btn badge_task btn"
                                                                           onClick={() => {toDetailPage(item)}}>채용정보 자세히 보기</a>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    ) : null
                                )) : (
                                    <div className="section_job_list">
                                        <p className="no_content_notice">현재 <span>{recJob.depthName.toString().substring(3)}</span> 직무 관련 채용정보가 없습니다. </p>
                                    </div>
                                )
                            }
                        </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default RecJob;