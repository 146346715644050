import React, {useEffect, useState} from 'react';
import api from "../../../api/api";

const JobPostingModal = (props) => {
    const { setJobPostingModal, recruitSeq } = props;
    const [postData, setPostData] = useState(null);

    useEffect(() => {
        try {
            api.get(`api/jobSearching/getRecruit?recruitSeq=`+recruitSeq).then((response) => {
                if (response.data.resultCode === 1000 && response.data.resultData) {
                    setPostData(response.data.resultData)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }, [])

    function formatDate(dateString) {
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear().toString().padStart(2, '0')
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        const weekday = dateObj.toLocaleDateString('ko-KR', { weekday: 'short' });
        return `${year}.${month}.${day}(${weekday})`;
    }

    function calculatedDays(dateString) {
        const dateObj = new Date(dateString);
        const timeDiff = dateObj.getTime() - Date.now();
        const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        return daysLeft;
    }

    return (
        <React.Fragment>
            {
                postData && (
                    <>
                    <div className="popup_mask" style={{display: "block"}} onClick={() => setJobPostingModal(false)}/>
                    <div className="popup_layer pop_lg popRequestOpening" id="popRequestOpening" style={{display: "block"}}>
                        <div className="popup_inner page page_detail">
                            <div className="pop_title with_btn">
                                <h3>{postData.incruit.recruit_name}</h3>
                                <button type="button" aria-label="레이어 팝업 닫기" className="popup_close" onClick={() => setJobPostingModal(false)}/>
                            </div>
                            <div className="popup_contents profile_detail">
                                <div className="page_inner">
                                    <div className="popup_page_box">
                                        <div>
                                            <div className="icon_box_wrap">
                                                <div className="icon_box">
                                                    <div className="image_box"/>
                                                    {
                                                        postData.incruit.employment_type === "상시 채용" || postData.incruit.employment_type === "채용 시 마감" ? (
                                                            postData.incruit.employment_type
                                                        ) : (
                                                            <>
                                                                <p>마감일 D - <span>{calculatedDays(postData.incruit.recruit_end_date)}</span>
                                                                </p>
                                                                <p><span>{formatDate(postData.incruit.recruit_end_date)}</span></p>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                <div className="icon_box">
                                                    <div className="image_box"/>
                                                    {
                                                        postData.condition.remote_work === "재택근무" ? ( postData.condition.remote_work
                                                        ) : (<>
                                                                <p>{postData.condition.work_location.split(" ")[0]}</p>
                                                                <p>{postData.condition.work_location.split(" ")[1]}</p></>
                                                        )
                                                    }
                                                </div>
                                                <div className="icon_box">
                                                    <div className="image_box"/>
                                                    <p>{postData.incruit.salary_type}</p>
                                                    <p><span>{postData.incruit.salary.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>원</p>
                                                </div>
                                                <div className="icon_box">
                                                    <div className="image_box"/>
                                                    <p>{postData.condition.duty_form}</p>
                                                    <p><span>1</span>일 <span>{postData.condition.duty_hour}</span>시간</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrap_signup_input wrap_signup_basic">
                                            <div className="box_sub_title">
                                                <h4 className="sub_title_signup">지원자격</h4>
                                            </div>
                                            <div className="box_self_info">
                                                <div className="detail_box">
                                                    <p>지원가능 장애유형</p>
                                                    <span>|</span>
                                                    <div className="wrap_chk_icon">
                                                        {
                                                            postData.disability.handicapped_type.map((handi) =>
                                                                (
                                                                    handi.disability_status === "Y" ? (
                                                                        handi.add_disabled.map((add, i) => (
                                                                                <div className="box_chk_icon"  key={i}>
                                                                                    <span className={`btn_chk_icon btn_icon_${add.disability_name?.length === 1 ? '0' + add.disability_name : add.disability_name}`}></span>
                                                                                    <span className="">
                                                                        {
                                                                            add.disability_name === "1" ? (
                                                                                "지체장애"
                                                                            ) : add.disability_name === "2" ? (
                                                                                "호흡기장애"
                                                                            ) : add.disability_name === "3" ? (
                                                                                "시각장애"
                                                                            ) : add.disability_name === "4" ? (
                                                                                "청각장애"
                                                                            ) : add.disability_name === "5" ? (
                                                                                "언어장애"
                                                                            ) : add.disability_name === "6" ? (
                                                                                "안면장애"
                                                                            ) : add.disability_name === "7" ? (
                                                                                "자폐성장애"
                                                                            ) : add.disability_name === "8" ? (
                                                                                "정신장애"
                                                                            ) : add.disability_name === "9" ? (
                                                                                "뇌병변장애"
                                                                            ) : add.disability_name === "10" ? (
                                                                                "신장장애"
                                                                            ) : add.disability_name === "11" ? (
                                                                                "심장장애"
                                                                            ) : add.disability_name === "12" ? (
                                                                                "간장애"
                                                                            ) : add.disability_name === "13" ? (
                                                                                "장루/요루장애"
                                                                            ) : add.disability_name === "14" ? (
                                                                                "뇌전증장애"
                                                                            ) : (
                                                                                "지적장애"
                                                                            )
                                                                        }
                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        )
                                                                    ) : (
                                                                        <div key={handi.recruit_seq}>
                                                                            <span>무관</span>
                                                                        </div>
                                                                    )
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="detail_box">
                                                    <p>장애정도</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>{postData.disability.disability_type.disability_name}</p>
                                                    </div>
                                                </div>
                                                <div className="detail_box">
                                                    <p>직무</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>{postData.desire?.depth3_name.split(".")[1]}</p>
                                                    </div>
                                                </div>
                                                <div className="detail_box">
                                                    <p>근무형태</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>{postData.condition.duty_type}</p>
                                                    </div>
                                                </div>
                                                <div className="detail_box">
                                                    <p>학력</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>{postData.condition.education}</p>
                                                    </div>
                                                </div>
                                                <div className="detail_box">
                                                    <p>자격요건</p>
                                                    <span>|</span>
                                                    <div>
                                                        {/*<p className="black_bullet">{postData.comInfo.qualification}</p>*/}
                                                        <p>{postData.comInfo.qualification}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrap_signup_input wrap_signup_basic">
                                            <div className="box_sub_title">
                                                <h4 className="sub_title_signup">근무조건</h4>
                                            </div>
                                            <div className="box_self_info">
                                                <div className="detail_box">
                                                    <p>급여</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>{postData.incruit.salary_type + " "}{postData.incruit.salary_type === "면접 후 합의" ? "" : postData.incruit.salary.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원"} </p>
                                                    </div>
                                                </div>
                                                <div className="detail_box">
                                                    <p>직무</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>{postData.condition.duty_form} / 1일 {postData.condition.duty_hour + "시간"}</p>
                                                    </div>
                                                </div>
                                                <div className="detail_box">
                                                    <p>근무지</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>{postData.condition.remote_work === "재택근무" ?
                                                            postData.condition.remote_work : postData.condition.work_location.replace("|", " ")}</p>
                                                    </div>
                                                </div>
                                                <div className="detail_box">
                                                    <p>채용조건</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>{postData.condition.employment_condition.substring(0,2) !== "경력" ?
                                                            postData.condition.employment_condition : postData.condition.employment_condition.split("|")[0]}</p>
                                                    </div>
                                                </div>
                                                <div className="detail_box">
                                                    <p>주요업무</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>{postData.comInfo.main_duty}</p>
                                                    </div>
                                                </div>
                                                <div className="detail_box">
                                                    <p>복리후생</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>{postData.comInfo.benefits}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrap_signup_input wrap_signup_basic">
                                            <div className="box_sub_title">
                                                <h4 className="sub_title_signup">모집기간 및 인원</h4>
                                            </div>
                                            <div className="box_self_info">
                                                <div className="detail_box">
                                                    <p>공고마감일</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>
                                                            {
                                                                postData.incruit.employment_type === "상시 채용" || postData.incruit.employment_type === "채용 시 마감" ? (
                                                                    postData.incruit.employment_type
                                                                ) : (
                                                                    postData.incruit.recruit_end_date.replaceAll("-", ".") + "까지"
                                                                )
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="detail_box">
                                                    <p>모집인원</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>{postData.incruit.position_num}{postData.incruit.position_num === '미정' ? null : '명'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrap_signup_input wrap_signup_basic photo_init">
                                            <div className="box_self_info">
                                                <img src={postData.enterprise.enterprise_file_path ? postData.enterprise.enterprise_file_path : null} />
                                            </div>
                                        </div>
                                        <div className="wrap_signup_input wrap_signup_basic logo_init">
                                            <div className="box_sub_title">
                                                <h4 className="sub_title_signup">기업소개</h4>
                                            </div>
                                            <div className="box_self_info">
                                                <div className="detail_box">
                                                    <div className="company_logo_wrap">
                                                        <img src={postData.comInfo.file_path ? postData.comInfo.file_path : null} />
                                                    </div>
                                                    <div>
                                                        <p>{postData.enterprise.enterprise_info}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrap_signup_input wrap_signup_basic">
                                            <div className="box_sub_title">
                                                <h4 className="sub_title_signup">채용 담당자 정보</h4>
                                            </div>
                                            <div className="box_self_info">
                                                <div className="detail_box">
                                                    <p>회사명</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>{postData.enterprise.enterprise_nm}</p>
                                                    </div>
                                                </div>
                                                <div className="detail_box">
                                                    <p>채용 담당자 이름</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>{postData.enterprise.manager_nm}</p>
                                                    </div>
                                                </div>
                                                <div className="detail_box">
                                                    <p>채용 담당자 이메일</p>
                                                    <span>|</span>
                                                    <div>
                                                        <p>{postData.enterprise.enterprise_email}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*  <div class="wrap_btns">
                          <button href="javascript:;" target="_self" class="btn_submit lined_green" onclick="closePop('layer','popRequestOpening'); return false;">관심공고</button>
                          <button href="javascript:;" target="_self" class="btn_submit filled_green"  onclick="openPop2('layer','popAddResume'); return false;">지원하기</button>
                      </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                )
            }
        </React.Fragment>

    )
}

export default JobPostingModal;