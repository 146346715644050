import React, { useState, Component } from 'react';

const TermsConditions = () => {
    const [agree, setAgree] = useState(false);

    const message = e => {
        alert("약관에 동의하셔야 다음 단계로 진행가능합니다.")
    }
    const agreeMessage = e => {
        alert("현 버전에서는 사용자 개인정보가 기업에 제공되지 않습니다.")
    }

    return (
        <div>
            <div className="wrapper">
                <main>
                    <div className="join page_join">
                        <h2 className="page_title">회원 가입하기</h2>
                        <div className="wrap_progressbar">
                            <ul className="progressbar_list progress_step01">
                                <li className="progressbar_item progress_item01"><p>이용약관 동의</p></li>
                                <li className="progressbar_item progress_item02"><p>계정유형 선택</p></li>
                                <li className="progressbar_item progress_item03"><p>회원정보 등록</p></li>
                            </ul>
                        </div>
                        <div className="page_inner">
                            <div className="area_shade">
                                <div className="wrap_title_step">
                                    <span className="badge badge_step">STEP 1</span>
                                    <h3 className="title_step">이용약관에 동의해 주세요.</h3>
                                </div>
                                <div className="box_txt" tabIndex="0">
                                <p>장애인 직무매칭–이용약관동의서 </p><br/>
                                    <p>제 1장 총칙</p><br/> 
                                    <p>제 1조 (목적)</p>
                                    본 약관은 주식회사 와이즈넛 , 주식회사 브이드림, 사회복지법인 청십자사회복지회(이하  “ 컨소시엄 ” )가 제공하는 장애인직무매칭시스템 서비스의 이용과 관련하여 컨소시엄과 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정하는 것을 목적으로 한다.<br/><br/>
                                    장애인 직무매칭시스템은  ‘ 산업통상자원부 ’ 에서 전문기관  ‘ 한국산업기술평가관리원 ’ 을 통해 지원하는 지식서비스산업기술개발사업으로 주관기관 와이즈넛 , 참여기관 브이드림, 청십자사회복지회이 과제명  ‘ 장애인 고용 촉진을 위한 비대면 직무매칭 (역량평가) 및 직무개발 지원 시스템 개발 ’  으로 개발 중인 시스템이다.<br/><br/>
                                    <p>제  2 조 (용어의 정의) </p>
                                    이 약관에서 사용하는 용어의 정의는 다음과 같다 .<br/><br/>
                                    ①  "사이트"라 함은  “ 컨소시엄 ” 이 서비스를  “ 이용자 ” 에게 제공하기 위하여 제작 , 운영하는 사이트를 말합니다. 현재  “ 컨소시엄 ” 이 운영하는 사이트의 접속 주소는 아래와 같다 .<br/>
                                    -  https://matching.vdream.co.kr/<br/> - m.matching.vdream.co.kr/ (비활성화)<br/><br/>
                                    ②   ” 서비스 “ 라 함은 이력서 등록 , 역량평가, 직무추천의 서비스를 제공하는 플랫폼 서비스이다. 구체적으로는 컨소시엄이 구직자의 직무추천, 직무교육과 구직을 목적으로 등록하는 각종 자료들을 DB화하여 각각의 목적에 맞게 분류 가공, 집계하여 정보를 제공하는 서비스 및 기타 구직이 원활히 이루어지도록 하기 위하여 사이트 등에서 제공하는 모든 서비스를 말한다. 서비스의 자세한 내용은 제7조에서 정하는 바에 따른다.<br/><br/>
                                    ③   ” 이용자 “ 라 함은 사이트 등에 접속하여 본 약관에 따라 컨소시엄이 제공하는 서비스를 이용하는 개인회원 및 비회원을 말한다 .<br/><br/>
                                    ④   ” 개인회원 “  이라 함은 본 서비스를 이용하기 위하여 본 약관에 동의하고 컨소시엄과 서비스 이용계약을 체결하여 개인회원 ID를 부여받은 이용자를 말한다.<br/><br/>
                                    ⑤   ” 비회원 “  이라 함은 컨소시엄과 서비스 이용계약을 체결하지 않은 채 사이트 등을 통하여 컨소시업이 제공하는 서비스를 이용하는 이용자를 말한다.<br/><br/>
                                    ⑥   ” 서비스 이용계약 “  이라 함은 컨소시엄이 개인회원을 위해 제공하는 서비스를 계속적으로 이용하기 위하여 컨소시엄과 이용자 사이에 체결되는 계약을 말한다.<br/> <br/>
                                    ⑦   ” 이용자 ID “  또는  ” 개인회원 ID “  라 함은 개인회원의 식별 및 서비스 이용을 위하여 개인회원이 선정하거나 컨소시엄이 부여하는 문자와 숫자의 조합을 말한다.<br/><br/>
                                    ⑧   ” 비밀번호 “  라 함은 컨소시엄의 서비스를 이용하려는 사람이 개인회원ID를 부여 받은 자와 동일인임을 확인하고 개인회원의 권익을 보호하기 위하여 개인회원이 선정하거나 컨소시엄이 부여하는 문자와 숫자의 조합을 말한다.<br/><br/>
                                    ⑨   ” 계정 “  이라 함은 개인회원의 개인정보, 이력서 등을 등록, 관리할 수 있도록 컨소시엄이 개인회원에게 제공하는 공간을 말한다.<br/><br/>
                                    <p>제  3 조 (	약관의 명시와 개정) </p>
                                    ①   ” 컨소시엄 “ 은 이 약관의 내용을  ” 이용자 “ 가 알 수 있도록 초기 화면에 게시하거나 기타의 방법으로 이용자에게 공지한다 . <br/><br/>
                                    ②   ” 컨소시엄 “ 은  "약관의규제에관한법률", "정보통신망이용촉진및정보보호등에관한법률(	이하 "정보통신망법")" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있다.<br/><br/>
                                    ③   ” 컨소시엄 “ 은 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제 1항의 방식에 따라 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지 공지한다. 다만,  ” 개인회원 “  에게 불리한 약관의 개정의 경우에는 공지 외에 일정기간 서비스 내 전자우편 등의 전자적 수단을 통해 따로 명확히 통지하도록 한다.<br/><br/>
                                    ④   ” 컨소시엄 “ 은 전항에 따라 개정약관을 공지 또는 통지하면서  ” 개인회원 “  에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도  ” 개인회원 “  이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 본다.<br/><br/>
                                    ⑤   ” 개인회원 “  이 개정약관의 적용에 동의하지 않는 경우  ” 컨소시엄 “ 은 개정 약관의 내용을 적용할 수 없으며 , 이 경우  ” 개인회원 “  은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는  ” 컨소시엄 “  은 이용계약을 해지할 수 있다.<br/><br/>
                                    <p>제  4 조 (	약관의 해석) </p>
                                    ①  이 약관에서 정하지 아니한 사항이나 해석에 대해서는 관계법령 또는 상관례에 따른다.<br/><br/>
                                    <p>제  2장 회원가입</p><br/>
                                    <p>제  5 조 (	서비스 이용계약의 성립)</p>
                                    ①  서비스 이용계약은 개인회원 서비스를 이용하고자 하는 자(	이하  “ 이용희망자 ” 라 합니다 )의 본 약관과 개인정보 처리방침의 내용에 대한 동의 및 회원 가입 신청에 대하여  “ 컨소시엄 ” 이 승낙함으로써 성립한다 .<br/>
                                    ②  사이트 등을 통한 회원 가입시 이용희망자는 본 약관 및 개인정보 처리방침에 대한 동의 표시 및 회원 가입 신청을 하여야 하며,  “ 컨소시엄 ” 은 이용희망자가 본 약관 및 개인정보 처리방침의 내용을 읽고 동의한 것으로 본다 .<br/>
                                    ③  "컨소시엄"이 이용신청(	회원가입 신청) 작성 후에 "회사"가 웹상의 안내 및 전자메일 또는  “ 컨소시엄 ” 이 정한 수단으로  "회원"에게 통지함으로써 이용계약이 성립된다.<br/>
                                    ④  구글, 카카오, 네이버, 페이스북 등 외부 서비스와의 연동을 통해 이용계약을 신청할 경우, 본 약관과 개인정보취급방침, 서비스 제공을 위해 "컨소시엄"이 "개인회원"의 외부 서비스 계정 정보 접근 및 활용에 "동의" 또는 "확인" 버튼을 누르면 "컨소시엄"이 웹상의 안내 및 전자메일로 "회원"에게 통지함으로써 이용계약이 성립된다.<br/>
                                    ⑤   “ 개인회원 ” 은 본 약관 및  “ 컨소시엄 ” 의 개인정보 처리방침에서 정한 항목을 제공해야 한다 .<br/><br/>
                                    <p>제  6 조 (	개인회원 가입신청 및 이용계약의 승낙과 제한) </p> 
                                    ①   “ 컨소시엄 ” 은 전조의 규정에 의한 이용희망자에 대하여 다음 각 호의 사유를 모두 충족할 경우  “ 컨소시엄 ” 이 제공하는 절차에 따라 개인회원 가입을 승낙합니다 .<br/>
                                      1.  “ 컨소시엄 ” 의 업무수행 및 서비스 제공을 위한 설비의 여유 • 기술상 지장이 없는 경우<br/>
                                      2. 본 약관에서 정하는 승낙 제한 또는 거절, 보류 사유에 해당하지 않는 경우<br/><br/>
                                    ②  "컨소시엄"은 "이용자"의 신청에 대하여 "서비스" 이용을 승낙함을 원칙으로 합니다. 다만, "컨소시엄"은 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.<br/>
                                    1.  이용희망자가 만  15세 미만인 경우<br/>
                                    2. 이용자가 이 약관에 의하여 이전에 서비스 이용자격을 상실한 적이 있는 경우, 단 "컨소시엄"의 재이용 승낙을 얻은 경우에는 예외로 함.<br/>
                                    3. 실명이 아니거나 타인의 명의를 이용한 경우<br/>
                                    4. 허위의 정보를 기재하거나, "컨소시엄"이 제시하는 내용을 기재하지 않은 경우<br/>
                                    5.  “ 이용자 “ 의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우<br/><br/>
                                    <p>제  3장 서비스의 이용</p><br/>
                                    <p>제  7 조 (	서비스의 내용)</p>
                                    ①  "컨소시엄"은 제2조 2항의 서비스를 제공할 수 있으며 그 내용은 다음 각 호와 같다.<br/>
                                    1. 이력서 등록 및 인재 데이터베이스 등록 서비스<br/>
                                    2. 구인/구직과 관련된 제반 서비스<br/>
                                     2-1. 온라인 입사지원 서비스<br/>
                                     2-2. 역량평가 서비스<br/>
                                     2-3. 추천직무 서비스<br/>
                                     2-4. 커리어패스 서비스<br/>
                                     2-5. 모의면접 및 온라인 면접 서비스<br/>
                                    3. 기업정보 조회와 관련된 제반 서비스<br/>
                                    4. 추천교육과 관련된 제반 서비스 <br/>
                                    5. 기타 "컨소시엄"이 추가 개발, 편집, 재구성하거나 제휴계약 등을 통해  “ 개인회원 ” 에게 제공하는 일체의 서비스<br/>
                                    ②  "컨소시엄"은 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있다. 다만, 서비스의 내용의 추가 또는 변경으로 인하여  “ 개인회원 ” 의 권리 • 의무에 중요한 영향을 미치는 경우 ,  “ 컨소시엄 ” 은 추가 또는 변경된 서비스 내용을 그 적용일로부터  30일 전에 공지합니다.<br/><br/>
                                    <p>제  8 조 (	개인회원 정보, 이력서, 역량평가, 추천직무, 추천교육 등의 등록 및 제공)</p>
                                    ①   “ 개인회원 ” 의 이력서는  “ 개인회원 ” 이 회원가입 또는 이력서 작성 및 수정시 희망한 형태로 등록 및 제공됩니다 .<br/>
                                    ②   “ 개인회원 ” 은 이력서의 등록 , 역량평가까지 완료해야 추천직무 서비스를 제공받을 수 있다.<br/>
                                    ③  "개인회원"이 등록한 이력서는 커리어패스 서비스에 통계 자료로 활용 및 제공 될 수 있다.<br/>
                                    ④  안정적인 서비스를 제공을 위해 테스트 및 모니터링, 고객문의 응대 등의 용도로 "개인회원"의 이력서 정보가 열람될 수 있습니다.<br/>
                                    ⑤  "컨소시엄"은 "개인회원"의 선택에 의하여 등록 및 제공되는 이력서 및 역량평가 등의 정보를 기준으로 구직활동에 보다 유익한 서비스를 제공하기 위하여 이를 개발, 편집, 재구성한 통계 자료로 활용 할 수 있다.<br/><br/>
                                    <p>제  9 조 (	서비스의 요금)</p>
                                    ①  "개인회원" 가입과 이력서 등록, 역량평가는 무료입니다.<br/><br/>
                                    <p>제  10 조 (	서비스 이용시간 및 제공 중지)</p>
                                    ①  "컨소시엄"은 특별한 사유가 없는 한 연중무휴, 1일 24시간 서비스를 제공한다. 다만, "컨소시엄"은 서비스의 종류나 성질에 따라 제공하는  “ 서비스 ”  중 일부에 대해서는 별도로 이용시간을 정할 수 있으며, 이 경우 "컨소시엄"은 그 이용시간을 사전에 "개인회원"에게 공지 또는 통지하여야 한다.<br/>
                                    ②   “ 컨소시엄 ” 은 서비스 개선을 위한 시스템 업데이트 기타 유지보수 작업 등을 진행하고자 하는 경우 , 사전에 서비스 중단 시간과 작업 내용을 고지하여 일시적 서비스 중단을 시행할 수 있습니다.<br/>
                                    ③   “ 컨소시엄 ” 의 성실한 의무 이행에도 불구하고 다음 각 호의 사유로 서비스 중지 등  “ 개인회원 ” 의 손해가 발생한 경우 ,  “ 컨소시엄 ” 은 그로 인한 책임을 부담하지 않습니다 .<br/>
                                    1.  천재지변 또는 이에 준하는 불가항력으로 인한 손해<br/>
                                    2.  회원의 귀책으로 발생한 손해<br/>
                                    3.  제 3자의 고의 또는 과실로 발생한 손해<br/>
                                    4.  기타  “ 컨소시엄 ” 의 고의 또는 과실 없이 발생한 손해<br/><br/>
                                    <p> 제 11 조 (	서비스 정보의 제공)</p>
                                    ①   “ 컨소시엄 ” 은  "개인회원"에게 서비스 이용에 필요가 있다고 인정되거나 서비스 개선 및  “ 개인회원 ”  대상의 서비스 소개 등의 목적으로 하는 각종 정보에 대해서 전자우편이나 서신우편을 이용한 방법으로 제공할 수 있다.<br/><br/>
                                    <p>제  12조 (	자료내용의 책임과  “ 컨소시엄 ” 의 정보수정 권한 )</p>
                                    ①  자료내용은 "개인회원"이 등록한 회원정보 및 이력서와 수행한 역량평가 결과를 말한다.<br/>
                                    ②  "개인회원"은 자료 내용 및 게시물을 사실에 근거하여 성실하게 작성해야 하며, 만일 자료의 내용이 사실이 아니거나 부정확하게 작성되어 발생하는 모든 책임은 "개인회원"에게 있다. "컨소시엄"은 "개인회원"이 등록한 이력서, 수행한 역량평가 등의 정확성 및 진실성을 보증하지 아니하며, 일체의 책임을 부담하지 아니한다.<br/>
                                    ③  모든 자료내용의 관리와 작성은 "개인회원" 본인이 하는 것이 원칙이나 사정상 위탁 또는 대행관리를 하더라도 자료내용의 책임은 "개인회원"에게 있으며 "개인회원"은 주기적으로 자신의 자료를 확인하여 항상 정확하게 관리가 되도록 노력해야 한다.<br/>
                                    ④  "컨소시엄"은 "개인회원"이 등록한 자료 내용에 역량평가와 직무추천을 요하지 않는 광고성 문구 또는 사회적 통념에 어긋나는 문구와 내용이 있을 경우 이를 언제든지 수정할 수 있다.<br/>
                                    ⑤   “ 컨소시엄 ” 은  "개인회원"이 등록하는 서비스내용의 내용물이 다음 각 호에 해당한다고 판단하는 경우에  “ 개인회원 ” 에게 사전 통지 없이 삭제할 수 있다 .<br/>
                                    1. 다른 이용자 또는 제3자를 비방하거나 중상모략으로 명예를 손상키기는 경우<br/>
                                    2. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 유포하는 경우<br/>
                                    3. 반국가적, 반사회적, 범죄적 행위와 결부된다고 판단되는 경우<br/>
                                    4. 기타 법령에 위배된다고 판단되는 경우 <br/><br/>
                                    <p>제  13 조 (	자료 내용의 활용 및 온라인 입사 지원 정보)</p>
                                    ①  "개인회원"이  “ 사이트 ”  등에 등록한 이력서 등 정보는  ‘ 장애인 고용 촉진을 위한 비대면 직무매칭 (	역량평가) 및 직무개발 지원 시스템 개발 ’ 과제의 수행목적으로서 보고서 작성을 위한 통계 자료로 구성 , 활용 될 수 있으며 그 자료는 과제를 지원하는 전담기관에 제공될 수 있다. 단 개인을 식별할 수 있는 형태가 아니어야 합니다.<br/><br/>
                                    <p>제  14 조 ("컨소시엄"의 의무)</p>
                                    ①  "컨소시엄"은 본 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다해야 한다.<br/>
                                    ②  "컨소시엄"은 서비스와 관련한 "개인회원"의 불만사항이 접수되는 경우 이를 즉시 처리하여야 하며, 즉시 처리가 곤란한 경우에는 그 사유와 처리일정을 서비스 화면 또는 기타 방법을 통해 동 "개인회원"에게 통지하여야 한다.<br/>
                                    ③  천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가 발생하여 서비스가 중단될 경우 이에 대한 손해에 대해서는 "컨소시엄"이 책임을 지지 않는다. 다만 자료의 복구나 정상적인 서비스 지원이 되도록 최선을 다할 의무를 진다.<br/>
                                    ④  "개인회원"의 자료를 본 서비스 이외의 목적으로 제3자에게 제공하거나 열람시킬 경우 반드시 "개인회원"의 동의를 얻어야 한다.<br/><br/>
                                    <p>제  15 조 ("개인회원"의 의무)</p>
                                    ①  "개인회원"은 관계법령과 본 약관의 규정 및 기타 "컨소시엄"이 통지하는 사항을 준수하여야 하며, 기타 "컨소시엄"의 업무에 지장을 초래하는 행위를 해서는 안 된다.<br/>
                                    ②  "개인회원"은 서비스를 이용하여 얻은 정보를 "컨소시엄"의 사전동의 없이 복사, 복제, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없다.<br/>
                                    ③  "개인회원"은 본 서비스를 역량평가 및 직무추천 이외의 목적으로 사용해서는 안되며 이용 중 다음 각 호의 행위를 해서는 안 된다. 위반하는 경우, 제 12조 4항에 의해 삭제될 수 있다.<br/>
                                    1. 다른 회원의 아이디를 부정 사용하는 행위<br/>
                                    2. 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위<br/>
                                    3. 타인의 명예를 훼손하거나 모욕하는 행위<br/>
                                    4. 타인의 지적재산권 등의 권리를 침해하는 행위<br/>
                                    5. 해킹행위 또는 바이러스의 유포 행위<br/>
                                    6. 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 계속적으로 전송하는 행위<br/>
                                    7. 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는 행위<br/>
                                    8. 사이트의 정보 및 서비스를 이용한 영리 행위<br/>
                                    9. 그밖에 선량한 풍속, 기타 사회질서를 해하거나 관계법령에 위반하는 행위<br/><br/>
                                    <p>제  16 조 (	서비스 이용계약 해지/서비스중지/자료삭제)</p>
                                    ①  "개인회원"이 서비스 이용계약을 해지하고자 할 경우, 고객센터 또는 "개인회원 탈퇴" 메뉴를 이용해 회사에 대한 해지 신청을 합니다. 회사는 즉시 가입해지(	회원탈퇴)에 필요한 조치를 취합니다.<br/>
                                    ②   “ 개인회원 ” 이 서비스 이용계약을 해지한 경우 ,  “ 컨소시엄 ” 은 해지 즉시  “ 개인회원 ” 의 모든 정보를 파기합니다 . 다만, 관련 법령 및 개인정보 처리방침에 따라  “ 컨소시엄 ” 이  “ 개인회원 ”  정보를 보유할 수 있는 경우는 보유 목적에 필요한 최소한의 정보를 보관할 수 있습니다. <br/>
                                    ③  다음의 사항에 해당하는 경우 "컨소시엄"은  “ 개인회원 ” 의 사전 동의없이 가입해지나 서비스 중지 , 이력서 삭제 조치를 취할 수 있습니다.<br/>
                                    1.  “ 개인회원 ” 의 의무를 성실하게 이행하지 않았을 때<br/>
                                    2. 본 서비스 목적에 맞지 않는 분야에 정보를 활용하여 사회적 물의가 발생한 때<br/>
                                    3.  “ 개인회원 ” 이 등록한 정보의 내용이 사실과 다르거나 조작되었을 때<br/>
                                    4. 본 서비스와 관련하여  “ 컨소시엄 ”  또는 제3자의 명예를 훼손하였을 때<br/>
                                    5. 기타 위 각호에 준하는 사유가 발생하였을 때<br/><br/>
                                    <p>제  17 조 (	손해배상 및 면책)</p>
                                    ①   “ 컨소시엄 ” 이 본 약관의 제  8조, 제 14조 등의 규정에 위반한 행위로  “ 개인회원 ” 에게 손해를 입히거나 기타  “ 컨소시엄 ” 이 제공하는 모든 서비스와 관련하여  “ 컨소시엄 ” 의 책임 있는 사유로 인해  “ 개인회원 ” 에게 손해가 발생한 경우 ,  “ 컨소시엄 ” 은 그 손해를 배상하여야 합니다 .<br/>
                                    ②   “ 개인회원 ” 이 본 약관의 제  6조, 제 12조, 제 15조 등의 규정에 위반한 행위로  “ 컨소시엄 ”  및 제3자에게 손해를 입히거나  “ 개인회원 ” 의 책임 있는 사유에 의해  “ 컨소시엄 ”  및 제3자에게 손해를 입힌 경우에는  “ 개인회원 ” 은 그 손해를 배상하여야 합니다 .<br/>
                                    ③   “ 컨소시엄 ” 은  “ 개인회원 ” 의 본 서비스를 통한 구직활동에 대하여 어떠한 책임도 부담하지 않습니다 . 또한,  “ 컨소시엄 ” 은  “ 컨소시엄 ” 이 무료로 제공하는 서비스의 이용과 관련하여 개인정보 처리방침에서 정하는 내용에 위반하지 않은 한  “ 이용자 ” 에게 어떠한 손해도 책임지지 않습니다 .<br/>
                                    ④   “ 컨소시엄 ” 은 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우 서비스 제공에 관한 책임이 면제됩니다 .<br/>
                                    ⑤   “ 컨소시엄 ” 은 서비스 이용과 관련하여  “ 개인회원 ” 에게 발생한 손해 가운데  “ 개인회원 ” 의 고의 또는 과실로 인한 서비스 이용의 장애 및 손해에 대하여 어떠한 책임도 부담하지 않습니다 .<br/><br/>
                                    <p>제  18 조 (	양도 금지)</p>
                                    “ 개인회원 ” 의 서비스 받을 권리는 제 3자에게 양도, 대여, 증여 등으로 사용할 수 없다.<br/><br/>
                                    <p>제  19 조 (	회원의 개인정보보호)</p>
                                    "컨소시엄"은  “ 이용자 “ 의 개인정보를 보호하기 위하여 노력합니다 .  “ 컨소시엄 ” 은 정보통신망 이용 촉진 및 정보 보호 등에 관한 법률 , 개인정보 보호법을 준수하고, 개인정보 보호를 위하여  “ 사이트 ”  등에 개인정보 처리방침을 고지합니다.<br/><br/>
                                    <p>제  20 조 (	분쟁의 해결)</p>
                                    ①   “ 컨소시엄 ” 과  “ 개인회원 ” 은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다 .<br/>
                                    ②  전항의 노력에도 불구하고,  “ 컨소시엄 ” 과  “ 개인회원 ”  간에 발생한 분쟁에 관한 소송이 제기될 경우, 민사소송법에 따른 관할법원을 제1심 관할법원으로 지정합니다.<br/><br/>
                                    <p>부칙</p>
                                    - 이 약관은 2023년 11월 20일부터 시행한다.<br/>
                                    - 이전 회원 이용약관 보기 (2023년 11월 15일 시행)<br/>
                                </div>
                                <div className="wrap_chk_agree">
                                    <p>위 약관을 확인하였으며 이에 동의합니까?</p>
                                    <div className="box_chk_agree">
                                        <div className="box_radio">
                                            <input type="radio" className="btn_type_radio" id="agree01" name="agree01" onClick={agreeMessage} onChange={() => setAgree(true)}/>
                                                <label htmlFor="agree01" className="label_txt">동의합니다.</label>
                                        </div>
                                        <div className="box_radio">
                                            <input type="radio" className="btn_type_radio" id="agree02" name="agree01" onClick={message} onChange={() => setAgree(false)}/>
                                                <label htmlFor="agree02" className="label_txt">동의하지 않습니다.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wrap_btns">
                                <a href="/user/signup/selectsignup" target="_self" className="btn_submit filled_black">이전으로</a>
                                <button type="button" disabled={agree === false ? true : false} className="btn_submit filled_green" onClick={()=> window.location.href="/user/signup/counttype"}>다음으로</button>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default TermsConditions;