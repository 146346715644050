import React, { useRef, useEffect, useState } from "react";
import BasicInfo from "./BasicInfo";
import Education from "./education/Education";
import Career from "./Career";
import ComputerSkill from "./ComputerSkill";
import Awards from "./Awards";
import Language from "./Language";
import MilitaryService from "./MilitaryService";
import VeteransAffairs from "./VeteransAffairs";
import SelfIntroduction from "./SelfIntroduction";
import { useForm } from "react-hook-form";

import { useHistory, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import api from "../../../api/api";
import { getAutoComplete } from "../../../api/User/resume/autoComplete";
import { useImageFile } from "../../../hooks/useImageFile";
import ResumeCertificate from "./Certificate/ResumeCertificate";

const init = {
  profile: {
    member_name: "",
    member_phone: "",
    member_birthdate: "",
    member_email: "",
    member_image: "",
    member_file_path: "",
  },
  education: {
    high_school: {
      admission_status: "",
      school_name: "",
      affiliation: "",
      major: "",
      admission_date: "",
      graduate_status: "",
      graduate_year: "",
    },
  },
  military: {
    military_target: "",
    military_start_date: "",
    military_end_date: "",
    military_category: "",
    military_discharged_rank: "",
    discharge_reason: "",
  },
  veteran: {
    has_veterans_affair: "",
    veterans_affair_reason: "",
  },
  introduction_title: {
    introduction_title: "",
    introduction_content: "",
  },
};

const ResumeForm = () => {
  const { resumeNo } = useParams();
  const [isSubmit, setIsSubmit] = useState(false);
  const [agree, setAgree] = useState(null);
  const [modal, setModal] = useState(false);
  const goBackUrl = "/user/resume/desiredJobUpdate/" + resumeNo;

  const message = (e) => {
    alert("동의하셔야 다음 단계로 진행가능합니다.");
  };
  const history = useHistory();
  const [image, setImage] = useState(null);
  const [gradNum, setGradNum] = useState(0);
  const resumeRef = useRef(null);

  const [resume, setResume] = useState(null);
  const [educationalHistory, setEducationalHistory] = useState([]);
  const [careerHistory, setCareerHistory] = useState([]);
  const [certificateHistory, setCertificateHistory] = useState([]);

  const formData = new FormData();

  const methods = useForm({
    mode: "onChange",
    defaultValues: init,
  });

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    control,
    reset,
    resetField,
    trigger,
    setError,
    remove,
    formState: { errors },
    setFocus,
  } = methods;

  const [userInfo, setUserInfo] = useState({
    // 구직자 회원 계정
    member_com: {
      member_id: "",
      member_pw: "",
      email: "",
      member_nm: "",
      gubun: "USER",
      provider_type: "LOCAL",
    },
    // 구직자 회원정보
    disabili_certifica: {
      dis_reg_cer_seq: "",
      birthday: "",
      sex_gb: "",
      address: "",
      extraAddr: "",
      major_disability_nm: "",
      minor_disability_nm: "",
      total_disability_nm: "",
      issuance_dt: "",
      issuance_agency: "",
      confirm_no: "",
      certifica_no: "",
      crtf_file_path: "",
      crtf: "",
    },
    // 장애인증명서
    member_info: {
      image_file_path: "",
      image: "",
      phone_no: "",
      protector_nm: "",
      protector_relation: "",
      equipment_st: "",
      inflow: "",
    },
  });
  const watchField = watch();
  const [autoCompleteInfo, setAutoCompleteInfo] = useState({
    certifications: [],
    majors: [],
  });
  console.log(watchField);
  useEffect(() => {
    try {
      getAutoComplete()
        .then((response) => {
          const data = response.data;
          data &&
            data.resultCode === 1000 &&
            data.resultData &&
            setAutoCompleteInfo(data.resultData);
        })
        .catch((error) => {
          console.error("Error", error);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    const url = "api/resume_new/getProfile?seq=" + resumeNo;
    api
      .post(url)
      .then((response) => {
        const profileData = response.data.resultData.profile;
        const educationData = response.data.resultData.education;
        const careerData = response.data.resultData.career;
        const comSkillData = response.data.resultData.comskill;
        const certificateData = response.data.resultData.certificate;
        const prizeData = response.data.resultData.prize;
        const languageData = response.data.resultData.language;
        const militaryData = response.data.resultData.military;
        const veteranData = response.data.resultData.veteran;
        const introductionData = response.data.resultData.introduction;
        setGradNum(
          educationData.university.length + educationData.graduate.length
        );
        reset({
          ...watchField,
          profile: profileData,
          education: educationData,
          career: careerData.map((data, _) => {
            let token = data?.duty?.split(">");
            token && (data.duty_detail = token[token.length - 1]?.trim());
            data.is_removed = false;
            data.is_saved = true;
            return data;
          }),
          comskill: comSkillData,
          certificate: certificateData,
          prize: prizeData,
          language: languageData,
          military: militaryData,
          veteran: veteranData,
          introduction: introductionData,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [resumeNo]);

  useEffect(() => {
    try {
      api
        .post("/api/signup/getUserInfo", {})
        .then((response) => response.data)
        .then((response) => {
          response &&
            response.resultCode === 1000 &&
            response.resultData &&
            setUserInfo(response.resultData);
          api
            .post("api/resume_new/getProfile?seq=" + resumeNo)
            .then((response) => {
              if (
                response &&
                response.data?.resultCode == 1000 &&
                response.data?.resultData?.profile?.member_image
              ) {
                setUserInfo((preview) => {
                  const new_data = { ...preview };
                  new_data.member_info.image_file_path =
                    response.data.resultData.profile.member_image;
                  return new_data;
                });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              throw error;
            });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (!isSubmit) return;
    AlertError();
    setIsSubmit(false);
  }, [errors, isSubmit]);

  const decodedAccessToken = jwt_decode(localStorage.getItem("accessToken"));
  const userId = decodedAccessToken.sub;

  const AlertError = () => {
    if (errors.education) {
      if (errors.education.high_school) {
        alert("고등학교 입력정보를 확인해주세요.");
      } else if (errors.education.university) {
        alert("대학교 입력정보를 확인해주세요.");
      } else if (errors.education.graduate) {
        alert("대학원 입력정보를 확인해주세요.");
      }
    } else if (errors.career) {
      alert("경력사항 입력정보를 확인해주세요.");
    } else if (errors.certificate) {
      alert("자격증 입력정보를 확인해주세요.");
    } else if (errors.prize) {
      alert("수상내역 입력정보를 확인해주세요.");
    } else if (errors.language) {
      alert("어학 입력정보를 확인해주세요.");
    } else if (errors.military) {
      alert("병역사항 입력정보를 확인해주세요.");
    } else if (errors.veteran) {
      alert("보훈사항 입력정보를 확인해주세요.");
    } else if (errors.introduction) {
      alert("자기소개서 입력정보를 확인해주세요.");
    }
  };

  const resumeOcr = async (image) => {
    formData.append("attach", image);

    try {
      api.post("/api/ocr/resume", formData).then((response) => {
        if (response.data.resultCode === 1000 && response.data.resultData) {
          const { data } = response.data.resultData;
          console.log(data);
          setValue("profile.member_name", data.name, {
            shouldDirty: true,
            shouldTouch: true,
          });
          setValue("profile.member_phone", data.phone, {
            shouldDirty: true,
            shouldTouch: true,
          });
          setEducationalHistory(data.educational_history);
          setCareerHistory(data.career_history);
          setCertificateHistory(data.certificates);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const { uploadImage, deleteImage } = useImageFile(
    resumeRef,
    setResume,
    methods,
    undefined,
    resumeOcr
  );

  const uploadImageWrap = (ref) => {
    if (
      window.confirm(
        "orc 이미지를 다시 등록하면 기존 정보가 삭제 됩니다. ocr 이미지를 등록 하시겠습니까? "
      )
    ) {
      uploadImage(ref);
      alert("등록 되었습니다.");
    } else {
      alert("등록이 취소 되었습니다.");
    }
  };

  const onSubmithandler = async (data) => {
    if (agree !== true) {
      alert("허위사실 기재가 아님에 동의하셔야 내용 수정이 반영 됩니다.");
    } else {
      try {
        const resumeData = {
          profile: {
            member_name: "",
            member_birthdate: "",
            member_phone: "",
            member_email: "",
            member_file_path: "",
            member_image: "",
          },
          education: {
            high_school: {
              admission_status: "",
              school_name: "",
              affiliation: "",
              major: "",
              admission_date: "",
              graduate_status: "",
              graduate_year: "",
            },
            university: [
              {
                admission_status: "",
                gubun: "",
                school_name: "",
                admission_date: "",
                graduate_status: "",
                graduate_year: "",
                major: "",
                affiliation: "",
                grade: "",
                total_grade: "",
                add_major: [
                  {
                    major_status: "",
                    major_name: "",
                    major_affiliation: "",
                  },
                ],
              },
            ],
            graduate: [
              {
                admission_status: "",
                school_name: "",
                admission_date: "",
                graduate_status: "",
                graduate_year: "",
                major: "",
                affiliation: "",
                grade: "",
                total_grade: "",
                degree: "",
              },
            ],
          },
          career: [
            {
              company_name: "",
              department: "",
              duty: "",
              hired_date: "",
              employment_status: "",
              resignation_date: "",
              salary: 0,
            },
          ],
          comskill: {
            word_ability: "",
            excel_ability: "",
            ppt_ability: "",
          },
          certificate: [
            {
              certificate_name: "",
              certificate_authority: "",
              certificate_acquisition_date: "",
              certificate_gubun: "",
              certificate_level: "",
            },
          ],
          prize: [
            {
              award_name: "",
              award_authority: "",
              award_date: "",
            },
          ],
          language: [
            {
              language_test_name: "",
              language_name: "",
              language_score: "",
              language_acquisition_date: "",
            },
          ],
          military: {
            military_target: "",
            military_category: "",
            military_discharged_rank: "",
            discharge_reason: "",
            military_start_date: "",
            military_end_date: "",
          },
          veteran: {
            has_veterans_affair: "",
            veterans_affair_reason: "",
          },
          introduction: {
            introduction_title: "",
            introduction_content: "",
          },
          desire: [
            {
              depth1: "",
              depth2: "",
              depth3: "",
              depth4: "",
              depth1Name: "",
              depth2Name: "",
              depth3Name: "",
              depth4Name: "",
            },
          ],
        };
        resumeData.profile.member_name = userInfo.member_com.member_nm;
        resumeData.profile.member_phone = userInfo.member_info.phone_no;
        resumeData.profile.member_birthdate =
          userInfo.disabili_certifica.birthday;
        resumeData.profile.member_email = userInfo.member_com.email;

        userInfo.member_info.image_file_path_nm &&
          (resumeData.profile.member_file_path =
            userInfo.member_info.image_file_path_nm);
        data.profile.member_file_path &&
          (resumeData.profile.member_file_path = data.profile.member_file_path);

        data.profile.member_image
          ? (resumeData.profile.member_image = data.profile.member_image)
          : (resumeData.profile.member_image =
              userInfo.member_info.image_file_path);

        resumeData.education = data.education;
        resumeData.career = data.career;
        resumeData.comskill = data.comskill;
        resumeData.certificate = data.certificate;
        resumeData.prize = data.prize;
        resumeData.language = data.language;
        resumeData.military = data.military;
        resumeData.veteran = data.veteran;
        resumeData.introduction = data.introduction;
        resumeData.desire = JSON.parse(localStorage.getItem("desiredJobCode"));

        const url = "/api/resume_new/updateProfile?resumeSeq=" + resumeNo;
        await api.post(url, resumeData);
        alert("이력서 내용이 수정되었습니다");
        history.push("/user/resume/beforeResume");
      } catch (error) {
        console.log("Error:", error);
      }
    }

    setIsSubmit(true);
  };
  const handleResumeSubmit = () => {
    if (agree) {
      history.push("/user/resume/beforeResume");
    }
  };
  const onError = () => {
    setIsSubmit(true);
  };

  return (
    <div>
      <div className="wrapper">
        <main>
          <div className="page join page_resume_write page_layout_40">
            <h2 className="page_back_title">
              <a href={goBackUrl} target="_self" className="page_back">
                이력서 작성하기
              </a>
            </h2>

            <div className="wrap_progressbar">
              <ul className="progressbar_list progress_step03">
                <li className="progressbar_item progress_item01">
                  <p>희망직무 선택</p>
                </li>
                <li className="progressbar_item progress_item02">
                  <p>진행방식 선택</p>
                </li>
                <li className="progressbar_item progress_item03">
                  <p>이력서 작성</p>
                </li>
              </ul>
            </div>

            <h3 className="page_title">이력서를 작성해 보세요!</h3>

            <div className="page_inner">
              <form
                onSubmit={handleSubmit(onSubmithandler, onError)}
                onKeyDown={(e) => {
                  var slotName = e.target.id.split(".")[0];
                  console.log(e);
                  console.log(slotName);
                  if (
                    e.key === "Enter" &&
                    e.target.nodeName === "INPUT" &&
                    (slotName !== "education" || slotName !== "certificate")
                  ) {
                    e.preventDefault();
                  }
                }}
              >
                <div className="area_shade">
                  {/*
                                    <div className="wrap_title_step">
                                        <span className="badge badge_step">STEP 3</span>
                                        <h3 className="title_step">회원정보를 등록해 주세요.</h3>
                                    </div>*/}

                  <div className="wrap_signup_input wrap_signup_basic">
                    {/*<div className="box_sub_title file_upload_only">*/}
                    {/*    <h4 className="sub_title_signup">(선택사항) 이력서 첨부<span>브이드림 양식의 이력서를 수기로 작성하셨다면 첨부해 주세요.</span>*/}
                    {/*    </h4>*/}
                    {/*</div>*/}

                    {/*<div className="wrap_box_input">*/}
                    {/*    <div className="wrap_attached_file">*/}
                    {/*        {*/}
                    {/*            resume === null &&*/}
                    {/*            <div className="box_attached_file">*/}
                    {/*                <input type="file" id="attachedFileCertificate"*/}
                    {/*                       className="attached_file btn_submit filled_green"*/}
                    {/*                       ref={resumeRef} onChange={uploadImageWrap} accept='.pdf' multiple/>*/}
                    {/*                <p className="attached_note">※ PDF 형식의 파일만 업로드 가능합니다.</p>*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*        {*/}
                    {/*            resume !== null &&*/}
                    {/*            <div className="attached_file_viewer">*/}
                    {/*                <ul className="attached_file_list">*/}
                    {/*                    <li className="attached_file_item">*/}
                    {/*                        <a href="#none" target="_self" className="attached_anchor"><span>{resume.name}</span></a>*/}
                    {/*                        <button className="btn_attached_delete certificate"*/}
                    {/*                                title="첨부파일 삭제" type="button" onClick={deleteImage}></button>*/}
                    {/*                    </li>*/}
                    {/*                </ul>*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <BasicInfo
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      userInfo={userInfo}
                      watchField={watchField}
                      methods={methods}
                    />
                    <Education
                      register={register}
                      control={control}
                      errors={errors}
                      gradNum={gradNum}
                      setValue={setValue}
                      watchField={watchField}
                      educationalHistory={educationalHistory}
                      autoComplete={autoCompleteInfo.majors}
                      setFocus={setFocus}
                    />
                    <Career
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      watchField={watchField}
                      modal={modal}
                      setModal={setModal}
                      careerHistory={careerHistory}
                    />
                    <ComputerSkill
                      register={register}
                      control={control}
                      errors={errors}
                    />
                    <ResumeCertificate
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      watchField={watchField}
                      certificateHistory={certificateHistory}
                      autoComplete={autoCompleteInfo.certifications}
                      setFocus={setFocus}
                    />
                    <Awards
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      watchField={watchField}
                    />
                    <Language
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      watchField={watchField}
                    />
                    <MilitaryService
                      register={register}
                      control={control}
                      errors={errors}
                      watchField={watchField}
                      setValue={setValue}
                    />
                    <VeteransAffairs
                      register={register}
                      control={control}
                      errors={errors}
                      watchField={watchField}
                    />
                    <SelfIntroduction
                      register={register}
                      control={control}
                      errors={errors}
                    />

                    <div className="wrap_chk_agree">
                      <p>상기 내용이 허위사실이 아님을 증명합니다.</p>
                      <div className="box_chk_agree">
                        <div className="box_radio">
                          <input
                            type="radio"
                            className="btn_type_radio"
                            id="agree01"
                            name="agree01"
                            onChange={() => setAgree(true)}
                          ></input>
                          <label htmlFor="agree01" className="label_txt">
                            동의합니다.
                          </label>
                        </div>
                        <div className="box_radio">
                          <input
                            type="radio"
                            className="btn_type_radio"
                            id="agree02"
                            name="agree01"
                            onChange={() => setAgree(false)}
                            onClick={message}
                          ></input>
                          <label htmlFor="agree02" className="label_txt">
                            동의하지 않습니다.
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrap_btns">
                  <a
                    href="/user/resume/beforeResume"
                    target="_self"
                    className="btn_submit filled_black"
                  >
                    작성 취소
                  </a>

                  <button
                    type="submit"
                    disabled={modal}
                    //style={agree === true ? {pointerEvents: "auto"} : {pointerEvents: "none"}}
                    className="btn_submit filled_green"
                  >
                    이력서 수정
                  </button>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default ResumeForm;
