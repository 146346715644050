import React, {useEffect, useLayoutEffect, useState} from "react";
import {useForm} from "react-hook-form";
import api from "../../../api/api";
import {getJobRequestList} from "../../../api/Comp/JobMatching/JobMatching";
import Pagination from "./Pagination";
import ApplicantModal from "./ApplicantModal";
import { useHistory } from 'react-router-dom';
import DeleteModal from "./DeleteModal";
import ApplicantInfoModal from "../applicant/applicantInfoModal";
import ConfirmModal from "../applicant/confirmModal";

const JobApplicationList = () => {
    const methods = useForm({
        mode: 'onChange',
    });
    const {register, setValue, watch, handleSubmit, control, reset, resetField, trigger, setError, remove, formState: {errors}} = methods

    const [visiblePageCount, setVisiblePageCount] = useState(10);
    const [page, setPage] = useState(1);
    const offset = (page - 1) * visiblePageCount;
    const [applicationList, setApplicationList] = useState([]);
    const [modal, setModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [recruitSeq, setRecruitSeq] = useState(null);
    const [deleteSeq, setDeleteSeq] = useState(null);
    const [applyCnt, setApplyCnt] = useState(0);

    const [applicantInfoModal, setApplicantInfoModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [applicantInfoResult, setApplicantInfoResult] = useState({});
    const [confirmModalType, setConfirmModalType] = useState("");
    const [updateParam, setUpdateParam] = useState({
        "comp_evaluation" : null,
        "recruit_seq" : null,
        "announcement_seq" : null
    });
    const [updateChk, setUpdateChk] = useState(false);

    useLayoutEffect(() => {
        try {
            getJobRequestList().then((response) => {
                if (response.data.resultCode === 1000 && response.data.resultData) {
                    setApplicationList(response.data.resultData);
                }
            })
        } catch (e) {
            console.log(e)
        }
    }, []);

    function calculatedDays(dateString) {
        const dateObj = new Date(dateString);
        const timeDiff = dateObj.getTime() - Date.now();
        const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        return daysLeft;
    }

    const handleInsertPage = () => {
        window.localStorage.removeItem('compDesiredJobCode');
        window.location.href = `/comp/jobRequest/chooseJobRequest`;
    }

    const handleApplicationClick = (recruitSeq) => {
        //window.location.href = `/comp/jobRequest/jobRequestUpdate/${recruitSeq}`;
        window.localStorage.removeItem('compDesiredJobCode');
        window.location.href = `/comp/jobRequest/chooseJobRequest/${recruitSeq}`;
    };

    const handleDeleteClick = (recruitSeq) => {
        setDeleteModal(() => true);
        setDeleteSeq(recruitSeq);
    }

    const openModal = (recruitSeq, cnt) => {
        setRecruitSeq(recruitSeq)
        setApplyCnt(cnt)
        setModal(true);
    }

    const onSubmit = async (data) => {
        try {
            api.post('/api/comp/matching/getJobApplicationListSearch', data).then((response) => {
                if (response.data.resultCode === 1000)
                    setApplicationList(response.data.resultData)
            })
        } catch (e) {
            console.log(e)
        }
    }

    const useHis = useHistory();

    const goToApplicantInfo = (recruitSeq, nums, index) => {
        useHis.push("/comp/Applicant/main/" + recruitSeq, {"comp_evaluation" : nums, "tab" : index});
    }

    return (
        <div>
            <div className="wrapper">
                <main>
                    <div className="page page_request_list page_layout_40">
                        <div className="page_back_title">
                            <h2 className="page_back_title border_bottom_line">
                                공고⋅지원자 관리
                            </h2>
                            <a href="#" onClick={() => handleInsertPage()} target="_self" className="btn_submit filled_green">직무요청서
                                추가</a>
                        </div>

                        <div className="page_inner wrap_list_table applicant_list">
                            <div className="table_init">
                                <div className="table_above_area">
                                    <p className="case_count">총<span>{applicationList.length}</span>건</p>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="table_search_area">
                                            <select name="category" value={watch("search_condition") || "전체"} title="검색조건" className="select" {...register('search_condition')}>
                                                <option value="전체">전체</option>
                                                <option value="공고상태">공고 상태</option>
                                                <option value="공고명">공고명</option>
                                            </select>
                                            <div className="search_bar">
                                                <input type="search"  placeholder="검색어를 입력해 주세요." {...register('search_word')}></input>
                                                <button type="submit" title="검색"></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="wrap_applicant_list">
                                    <table className="table_detail">
                                        <colgroup>
                                            <col style={{width: '70px'}}></col>
                                            <col style={{width: 'auto'}}></col>
                                            <col style={{width: '90px'}}></col>
                                            <col style={{width: '90px'}}></col>
                                            <col style={{width: '90px'}}></col>
                                            <col style={{width: '90px'}}></col>
                                            <col style={{width: '90px'}}></col>
                                            <col style={{width: '100px'}}></col>
                                        </colgroup>
                                        <thead>
                                        <tr>
                                            <th scope="col">번호</th>
                                            <th scope="col">공고명</th>
                                            <th scope="col">지원자수</th>
                                            <th scope="col">미열람</th>
                                            <th scope="col">면접진행</th>
                                            <th scope="col">최종합격</th>
                                            <th scope="col">불합격</th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            applicationList.length > 0 ? (
                                            applicationList.slice(offset, offset + visiblePageCount).map((application, index) => (
                                                <tr key={application.seq}>
                                                    <td>{(page - 1) * visiblePageCount + index + 1}</td>
                                                    <td className="td_double_row">
                                                        <a target="_self" className="recruit_popup" onClick={() => openModal(application.seq, application.status[0].count)}>
                                                            <span className="table_icon">
                                                                {
                                                                    calculatedDays(application.recruitEndDate) > 0 ||
                                                                    (application.recruitStatus === "공고중" && (application.employmentType === "상시 채용" || application.employmentType === "채용 시 마감")) ? application.recruitStatus : "마감"
                                                                }
                                                            </span>
                                                            <span
                                                                className="table_date">{ application.employmentType === "상시 채용" ? application.employmentType : application.recruitStartDate.split(' ')[0]}
                                                            </span> { application.employmentType === "상시 채용" ? null : "~" } <span className="table_date">
                                                            {application.employmentType === "채용 시 마감" ? application.employmentType : (application.employmentType === "상시 채용" ?
                                                                    null : application.recruitEndDate.split(' ')[0])}</span><br/>
                                                            <span className="td_title">{application.applicationNm}</span>
                                                        </a>

                                                    </td>
                                                    {
                                                        application.status && (
                                                        application.status.map((status, i) => (
                                                            <td key={i} className="align_right text_link">
                                                                <a href="#" onClick={(e) => {e.preventDefault(); goToApplicantInfo(application.seq, status.evalNums, i+1);}} target="_self" className="recruit_link">{status.count}명</a>
                                                            </td>))
                                                        )
                                                    }
                                                    <td>
                                                        <button type="button" className="btn_resume_write"
                                                                title="직무요청서 수정"
                                                                onClick={() => handleApplicationClick(application.seq)}
                                                        ></button>
                                                        {/*<button type="button"*/}
                                                        {/*        className="btn_resume_delete" title="직무요청서 삭제"*/}
                                                        {/*        onClick={() => deleteRecruit(application.seq)}*/}
                                                        {/*></button>*/}
                                                        <button type="button"
                                                                className="btn_resume_delete" title="직무요청서 삭제"
                                                                onClick={() => handleDeleteClick(application.seq)}
                                                        ></button>
                                                    </td>
                                                </tr>))
                                            ) : (
                                                <tr>
                                                    <td colSpan="6">지원자가 없습니다.</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="wrap_pagination">
                                    <Pagination
                                        total={applicationList.length}
                                        visiblePageCount={visiblePageCount}
                                        page={page}
                                        setPage={setPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            {
                modal && <ApplicantModal setModal={setModal} setApplicantInfoModal={setApplicantInfoModal} setResult={setApplicantInfoResult} recruitSeq={recruitSeq} applyCnt={applyCnt} updateChk={updateChk} setUpdateChk={setUpdateChk}/>
            }
            {
                deleteModal && <DeleteModal setDeleteModal={setDeleteModal} applicationList={applicationList} setApplicationList={setApplicationList} deleteSeq={deleteSeq} />
            }
            {
                applicantInfoModal && <ApplicantInfoModal result={applicantInfoResult} setModal={setApplicantInfoModal} setConfirmModal={setConfirmModal} setConfirmModalType={setConfirmModalType}  setUpdateParam={setUpdateParam} />
            }
            {
                confirmModal && <ConfirmModal setModal={setConfirmModal} type={confirmModalType} setUpdateChk={setUpdateChk} updateParam={updateParam}/>
            }
        </div>
    )
}

export default JobApplicationList;



