import React, {useState, useEffect, useLayoutEffect} from 'react';
import api, { decodeToken } from '../../api/api';
import {getCompInfo} from "../../api/Comp/CompSignup/CompSignup";
import ContractModal from "../../container/user/login/ContractModal";


const LeftToggleAfterCompLogin = () => {
    const userString = localStorage.getItem('user');
    const userObject = JSON.parse(userString);
    const userName = userObject?.username;
    const dToken = decodeToken(localStorage.getItem("accessToken"));
    const [userInfo, setUserInfo] = useState(null);
    const [modal, setModal] = useState(false);

    useLayoutEffect(() => {
        try {
            getCompInfo().then((response) =>{
                if (response.data.resultCode === 1000 && response.data.resultData) {
                    setUserInfo(response.data.resultData);
                } else {
                    setUserInfo({comp_member_info: {
                            rep_file_path: '/img/bg/aside_left_enterprise_login.png',
                    }})
                }
            })
        } catch (e) {
            console.log(e)
        }
    }, []);

    return (

        <div className="after_login enterprise">
            <div className="wrap_profile">
                <h4>{ userInfo && userInfo.comp_member_info.comp_nm}</h4>
                {
                    userInfo && userInfo.comp_member_info.rep_file_path && (
                        <div className="box_profile">
                            <img src={userInfo.comp_member_info.rep_file_path} alt="프로필"/>
                        </div>
                    )
                }
            </div>
            <div className="wrap_my_regist">
                <ul className="my_regist_list">
                    <li className="my_regist_item">
                        <a href="/comp/signup/compInfoManage" target="_self" className="my_regist_anchor">기업정보 관리</a>
                        <i className="regist_status normal"></i>
                    </li>
                    <li className="my_regist_item">
                        {
                            localStorage.getItem("customer_status") === "true" ? (
                                <a href="/comp/jobRequest/jobApplicationList" target="_self" className="my_regist_anchor">공고·지원자 관리</a>
                            ) : (
                                <a target="_self" className="my_regist_anchor" onClick={() => setModal(true)}>공고·지원자 관리</a>
                            )
                        }
                        <i className="regist_status normal"></i>
                    </li>
                    <li className="my_regist_item">
                        {
                            localStorage.getItem("customer_status") === "true" ? (
                                <a href="/comp/interview/InterviewMain" target="_self" className="my_regist_anchor">면접관리</a>
                            ) : (
                                <a target="_self" className="my_regist_anchor" onClick={() => setModal(true)}>면접관리</a>
                            )
                        }
                        <i className="regist_status normal"></i>
                    </li>
                    {/*
                    <li className="my_regist_item">
                        <a href="#" target="_self" className="my_regist_anchor">관심인재 관리</a>
                        <i className="regist_status normal"></i>
                    </li>
                    */}
                </ul>
            </div>
            {
                modal && <ContractModal setModal={setModal} />
            }
        </div>

    );
};

export default LeftToggleAfterCompLogin;

