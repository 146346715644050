import React, { useEffect, useState } from "react";
import api, { decodeToken } from "../../../api/api";

const CareerPath = () => {
  const [career, setCareer] = useState("");
  const [cateSeq, setCateSeq] = useState("");
  const [result, setResult] = useState([]);
  const [dcate, setDcate] = useState([]);
  const [ecate, setEcate] = useState([]);
  const [jobWorkersData, setJobWorkersData] = useState([]);

  useEffect(() => {
    api
      .post("/api/job/JobMatching")
      .then((response) => response.data)
      .then((response) => {
        if (response.resultCode === 1000) {
          if (
            response.resultData &&
            response.resultData.data &&
            response.resultData.data.length > 0
          ) {
            setCareer(
              response.resultData.data[0].depth4 &&
                response.resultData.data[0].depth4.substring(3)
            );
            setCateSeq(
              (response.resultData.data[0].depth1 &&
                response.resultData.data[0].depth1.substring(0, 2)) +
                (response.resultData.data[0].depth2 &&
                  response.resultData.data[0].depth2.substring(0, 2)) +
                (response.resultData.data[0].depth3 &&
                  response.resultData.data[0].depth3.substring(0, 2)) +
                (response.resultData.data[0].depth4 &&
                  response.resultData.data[0].depth4.substring(0, 2))
            );
          } else {
            api
              .post("/api/job/getDesiredJob")
              .then((response) => response.data)
              .then((response) => {
                if (response.resultCode === 1000) {
                  response.resultData &&
                    response.resultData.length > 0 &&
                    setCareer(response.resultData[0].depth4_name.substring(3));
                  response.resultData &&
                    response.resultData.length > 0 &&
                    setCateSeq(
                      response.resultData[0].depth1 +
                        response.resultData[0].depth2 +
                        response.resultData[0].depth3 +
                        response.resultData[0].depth4
                    );
                } else {
                  alert(
                    "데이터를 가져오는 중에 오류가 발생하였습니다. 관리자에게 문의해주세요."
                  );
                }
              });
          }
        } else {
          api
            .post("/api/job/getDesiredJob")
            .then((response) => response.data)
            .then((response) => {
              if (response.resultCode === 1000) {
                response.resultData &&
                  response.resultData.length > 0 &&
                  setCareer(response.resultData[0].depth4_name.substring(3));
                response.resultData &&
                  response.resultData.length > 0 &&
                  setCateSeq(
                    response.resultData[0].depth1 +
                      response.resultData[0].depth2 +
                      response.resultData[0].depth3 +
                      response.resultData[0].depth4
                  );
              } else {
                alert(
                  "데이터를 가져오는 중에 오류가 발생하였습니다. 관리자에게 문의해주세요."
                );
              }
            });
        }
      });
  }, []);

  useEffect(() => {
    const cateArr = cateSeq != "" ? [{ cate_seq: cateSeq }] : [];
    api
      .post("/api/resume/getACateList", cateArr)
      .then((response) => response.data)
      .then((response) => {
        response && response.resultData && setResult(response.resultData);
      });

    cateArr.length != 0 &&
      api
        .post("/api/resume/getCareerPath", cateArr[0])
        .then((response) => response.data)
        .then((response) => {
          if (response.resultCode === 1000) {
            response.resultData &&
              response.resultData.dcate &&
              setDcate(response.resultData.dcate);
            response.resultData &&
              response.resultData.ecate &&
              setEcate(response.resultData.ecate);
          } else {
            alert(
              "데이터를 가져오는 중에 오류가 발생하였습니다. 관리자에게 문의해주세요."
            );
          }
        });
  }, [cateSeq]);

  useEffect(() => {
    api
      .post("/api/job/CareerPath")
      .then((response) => response.data)
      .then((response) => {
        if (response.resultCode === 1000) {
          response.resultData &&
            response.resultData &&
            setJobWorkersData(response.resultData);
        } else {
          alert(
            "데이터를 가져오는 중에 오류가 발생하였습니다. 관리자에게 문의해주세요."
          );
        }
      });
  }, []);

  console.log(result);
  console.log(dcate);
  console.log(ecate);

  return (
    <div className="wrapper">
      <main>
        <div className="page page_career_pass page_layout_40">
          <h2 className="page_back_title border_bottom_line">
            “<span>{career}</span>” 직무 취업자들의 스펙정보
          </h2>
          <div className="page_description">
            <p>직무정의와 요구능력에 대한 안내입니다.</p>
          </div>

          <div className="page_inner">
            <div className="area_shade">
              <div className="explanation_box">
                <h4>직무정의(세분류 정의)</h4>
                {dcate && dcate.map((item, i) => <p>{item.cate_def}</p>)}
              </div>

              <div className="explanation_box">
                <h4>관련직업 예시 </h4>
                <p>
                  {ecate &&
                    ecate.map((item, i) => (
                      <>
                        {item.cate_nm}
                        {ecate.length > i + 1 ? ", " : ""}
                      </>
                    ))}
                </p>
              </div>
            </div>
            <div className="area_shade">
              {result &&
                result.map(
                  (item, i) =>
                    item.result &&
                    item.result.map((rItem, j) => (
                      <div className="explanation_box">
                        <h5>{rItem.cate_nm}</h5>
                        <p>{rItem.cate_def}</p>
                      </div>
                    ))
                )}
            </div>
          </div>

          <div align="center" className="career_table">
            <div
              align="center"
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              <table className="picture-quiz-table career">
                <thead>
                  <tr>
                    <th colSpan="3">{"<최종학력>"}</th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>순번</th>
                    <th>학력</th>
                    <th>비율</th>
                  </tr>
                </thead>
                <tbody align="center">
                  {jobWorkersData.educationList &&
                    jobWorkersData.educationList?.map((education, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{education}</td>
                        <td>-</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div
              align="center"
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              <table className="picture-quiz-table career">
                <thead>
                  <tr>
                    <th colSpan="3">{"<전공>"}</th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>순번</th>
                    <th>전공명</th>
                    <th>비율</th>
                  </tr>
                </thead>
                <tbody align="center">
                  {jobWorkersData.majorList &&
                    jobWorkersData.majorList?.map((major, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{major}</td>
                        <td>-</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div
              align="center"
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              <table className="picture-quiz-table career">
                <thead>
                  <tr>
                    <th colSpan="3">{"<자격증>"}</th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>순번</th>
                    <th>자격증명</th>
                    <th>비율</th>
                  </tr>
                </thead>
                <tbody align="center">
                  {jobWorkersData.certificateList &&
                    jobWorkersData.certificateList?.map(
                      (certificate, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{certificate}</td>
                          <td>-</td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CareerPath;
