import React from 'react';

function Pagination({ total, limit, page, setPage }) {
    const numPages = Math.ceil(total / limit);
    const visiblePageCount = 10;
    const currentPageGroup = Math.ceil(page / visiblePageCount);
    const startPage = (currentPageGroup - 1) * visiblePageCount + 1;
    const endPage = Math.min(startPage + visiblePageCount - 1, numPages);
    const handlePrevClick = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextClick = () => {
        if (page < numPages) {
            setPage(page + 1);
        }
    };
    return (
      <>
        <ul className="pagination_list">
                {page > 1 && (
                    <li className="pagination_item">
                        <a className="pagination_anchor prev" onClick={handlePrevClick}></a>
                    </li>
                )}
                {Array.from({ length: endPage - startPage + 1 }, (_, index) => {
                    const pageNumber = startPage + index;
                    return (
                        <li className="pagination_item" key={pageNumber}>
                            <a

                                className={`pagination_anchor ${page === pageNumber ? 'currently' : ''}`}
                                onClick={() => setPage(pageNumber)}
                            >
                                {pageNumber}
                            </a>
                        </li>
                    );
                })}
                {page < numPages && (
                    <li className="pagination_item">
                        <a className="pagination_anchor next" onClick={handleNextClick}></a>
                    </li>
                )}
            </ul>
      </>
    );
}

export default Pagination;



