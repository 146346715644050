
import {useForm} from "react-hook-form";
import React, {Component, useEffect, useState,useCallback} from 'react';
import DatePicker from "react-datepicker";
import {ko} from 'date-fns/esm/locale';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import api, { decodeToken } from '../../../api/api';
const UpdateInterviewModal = (props) => {  
    const formData = new FormData();
    const init = {
        recruit_seq: "",
        resume_seq: "",
        agency_type: "",
        interview_dt1: "",
        interview_dt2: "",
        interview_dt3: "",
        direct_yn: "N"
    }    
    
    const methods = useForm({
        mode: 'onChange',
        defaultValues : init
    });
    const { handleSubmit, formState: { errors, isValid }} = methods;
    const [isSubmit, setIsSubmit] = useState(false);   
    const [selectedDate1, setSelectedDate1] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
    const [selectedHour1, setSelectedHour1] = useState('09');
    const [selectedMinute1, setSelectedMinute1] = useState('00');
    const [selectedDate2, setSelectedDate2] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
    const [selectedHour2, setSelectedHour2] = useState('09');
    const [selectedMinute2, setSelectedMinute2] = useState('00');
    const [selectedDate3, setSelectedDate3] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
    const [selectedHour3, setSelectedHour3] = useState('09');
    const [selectedMinute3, setSelectedMinute3] = useState('00');
    const [interviewPopup, setInterviewPopup ] = useState({display: 'block', top:'10%'})
    const [selectDate, setSelectDate] = useState(false);   
    const [popup, setPopup ] = useState({display: 'block'}) 
    useEffect(() => {
        if(props.interviewData.select_confirm == null || props.interviewData.select_confirm === 'N'){
            setSelectDate(true);
        }
        if(props.interviewData.interview_dt1 != null){
            setSelectedDate1(dayjs(props.interviewData.interview_dt1.substring(0,10)).format('YYYY-MM-DD'));
            setSelectedHour1(props.interviewData.interview_dt1.substring(11,13));
            setSelectedMinute1(props.interviewData.interview_dt1.substring(14,16));
        }
        if(props.interviewData.interview_dt2 != null){
            setSelectedDate2(dayjs(props.interviewData.interview_dt2.substring(0,10)).format('YYYY-MM-DD'));
            setSelectedHour2(props.interviewData.interview_dt2.substring(11,13));
            setSelectedMinute2(props.interviewData.interview_dt2.substring(14,16));

        }
        if(props.interviewData.interview_dt3 != null){
            setSelectedDate3(dayjs(props.interviewData.interview_dt3.substring(0,10)).format('YYYY-MM-DD'));
            setSelectedHour3(props.interviewData.interview_dt3.substring(11,13));
            setSelectedMinute3(props.interviewData.interview_dt3.substring(14,16));

        }
        
    }, [])
    const [interviewInfo, setInterviewInfo] = useState({      
            announcement_seq : props.interviewData.announcement_seq,
            recruit_seq: props.interviewData.recruit_seq,
            interview_seq: props.interviewData.interview_seq,
            resume_seq: props.interviewData.resume_seq,
            agency_type: props.interviewData.agency_type,
            interview_dt1: props.interviewData.interview_dt1,
            interview_dt2: props.interviewData.interview_dt2,
            interview_dt3: props.interviewData.interview_dt3,
            direct_yn: props.interviewData.direct_yn,
    });

    const setupInterviewInfo = (target,value) =>{
        setInterviewInfo(prevState => ({
            ...prevState, 
            [target]: value,
        }));
    }
    const handleDayChange = (t,value) => {
        if(t==1)    setSelectedDate1(value);
        else if(t==2)    setSelectedDate2(value);
        else if(t==3)    setSelectedDate3(value);
    };    
    const handleHourChange = (t,value) => {
        if(t==1)    setSelectedHour1(value);
        else if(t==2)    setSelectedHour2(value);
        else if(t==3)    setSelectedHour3(value);
    };    
    const handleMinuteChange = (t,value) => {
        if(t==1)    setSelectedMinute1(value);
        else if(t==2)    setSelectedMinute2(value);
        else if(t==3)    setSelectedMinute3(value);
    };
    const setupInterviewInfoDate = (target1, target2,value) =>{
        let date
        if(target1 === 1){
            if(target2 === 'D'){
                date = value + ' '+selectedHour1+':'+selectedMinute1
                handleDayChange(target1,value)
            } 
            else if(target2 === 'H'){
                date = selectedDate1 + ' '+value+':'+selectedMinute1
                handleHourChange(target1,value)
            } 
            else if(target2 === 'M'){
                date = selectedDate1 + ' '+selectedHour1+':'+value
                handleMinuteChange(target1,value)
            } 
            setInterviewInfo(prevState => ({
                ...prevState, 
                interview_dt1: date,
            }))
        }        
        else if(target1 === 2){
            if(target2 === 'D') {
                date = value + ' '+selectedHour2+':'+selectedMinute2
                handleDayChange(target1,value)
            } 
            else if(target2 === 'H'){
                date = selectedDate2 + ' '+value+':'+selectedMinute2
                handleHourChange(target1,value)
            } 
            else if(target2 === 'M'){
                date = selectedDate2 + ' '+selectedHour2+':'+value
                handleMinuteChange(target1,value)
            } 
            setInterviewInfo(prevState => ({
                ...prevState, 
                interview_dt2: date,
            }))
        }
        else if(target1 === 3){
            if(target2 === 'D') {
                date = value + ' '+selectedHour3+':'+selectedMinute3
                handleDayChange(target1,value)
            } 
            else if(target2 === 'H'){
                date = selectedDate3 + ' '+value+':'+selectedMinute3
                handleHourChange(target1,value)
            } 
            else if(target2 === 'M'){
                date = selectedDate3 + ' '+selectedHour3+':'+value
                handleMinuteChange(target1,value)
            } 
            setInterviewInfo(prevState => ({
                ...prevState, 
                interview_dt3: date,
            }))
        }
    }
    const onSubmithandler = async () => {  
        if (!interviewInfo.interview_dt1) {
            alert("면접 일정을 입력해 주세요")
        } else {
            try {
                await api.post('/api/comp/interview/updateInterviewDate', interviewInfo)
                //alert("면접 등록 성공");
                props.setInterviewModal(false)
                
            } catch (error) {
                console.log('Error:', error);
            }
        }     
    };
    const confirmInterviewDate = (e,data,date) => {
        e.preventDefault()
        let confirmDate = {
            announcement_seq : data.announcement_seq,
            interview_seq : data.interview_seq,
            date : date
        }
        api.post('/api/comp/interview/updateConfirmDate', confirmDate)
        props.setInterviewModal(false)
        window.location.href='/comp/interview/InterviewMain'
    }
    const onError = () => {
        setIsSubmit(true)
    }
    useEffect(() => {
        if(!isSubmit) return
        AlertError()
        setIsSubmit(false)
    }, [errors, isSubmit])
    const AlertError = () => {
    }

    return (
        <>
            <div className="popup_mask" style={popup}></div>
            <div className="popup_layer popup_middle" id="popInterviewApplySugg" style={interviewPopup}>
                <div className="popup_inner" style={{maxHeight:'1500px'}}>
                    <div className="pop_title">
                        <h3>면접 일정 확인</h3>
                        <button type="button" aria-label="레이어 팝업 닫기" className="popup_close" onClick={(e) => {e.preventDefault(); props.setInterviewModal(false);}}></button>
                    </div>
                    <div className="popup_contents">                        
                        <div className="selection_wrap" style={{marginBottom:"40px"}}>
                            <h4 className="selection_title">면접 방법을 선택해 주세요</h4>
                            <div className="rd_select_wrap">
                                <div className="rd_btn_wrap">     
                                    {
                                    props.interviewData.agency_type == 'Y' ? <input type="radio" name="modal_rd01" id="modal01" onClick={()=>setupInterviewInfo("agency_type","Y")} defaultChecked/>
                                    : <input type="radio" name="modal_rd01" id="modal01" onClick={()=>setupInterviewInfo("agency_type","Y")}/>
                                    }   
                                    <label htmlFor="modal01">직접면접</label>
                                </div>
                                <div className="rd_btn_wrap">                           
                                    
                                    {
                                    props.interviewData.agency_type == 'N' ? <input type="radio" name="modal_rd01" id="modal02" onClick={()=>setupInterviewInfo("agency_type","N")} defaultChecked/>
                                    : <input type="radio" name="modal_rd01" id="modal02" onClick={()=>setupInterviewInfo("agency_type","N")}/>
                                    }   
                                    <label htmlFor="modal02">대행면접</label>
                                </div>
                            </div>
                        </div>
                        <div className="selection_wrap date_selected_wrap" style={{marginBottom:"40px"}}>
                            <h4 className="selection_title">면접 일정</h4><span className="vertical_line"></span>
                            <div style={{display:"flex"}}>
                            {
                                props.interviewData.interview_dt != null ? 
                                    <p className="date_selected_text"style={{textAlign:"left"}}>{props.interviewData.interview_dt.substring(0, 10)+" "+props.interviewData.interview_dt.substring(10, 16)}</p>
                                    : props.interviewData.select_confirm != null ? (() => {
                                    switch (props.interviewData.select_confirm) {
                                      case '1':
                                        return (
                                            <>
                                                <p className="date_selected_text"style={{textAlign:"left"}}>{props.interviewData.interview_dt1.substring(0, 10)+" "+props.interviewData.interview_dt1.substring(10, 16)}</p>
                                                <p className="date_selected_text" style={{textAlign:"right", width:'50%', textDecoration:"underline",cursor:"pointer"}} 
                                                onClick={(e)=>(confirmInterviewDate(e,props.interviewData,props.interviewData.interview_dt1))}>면접일정 확정하기</p>
                                            </>
                                        );
                                      case '2':
                                        return (
                                            <>
                                                <p className="date_selected_text"style={{textAlign:"left"}}>{props.interviewData.interview_dt2.substring(0, 10)+" "+props.interviewData.interview_dt2.substring(10, 16)}</p>
                                                <p className="date_selected_text" style={{textAlign:"right", width:'50%', textDecoration:"underline",cursor:"pointer"}} 
                                                onClick={(e)=>(confirmInterviewDate(e,props.interviewData,props.interviewData.interview_dt2))}>면접일정 확정하기</p>
                                            </>
                                        );
                                      case '3':
                                        return (
                                            <>
                                                <p className="date_selected_text"style={{textAlign:"left"}}>{props.interviewData.interview_dt3.substring(0, 10)+" "+props.interviewData.interview_dt3.substring(10, 16)}</p>
                                                <p className="date_selected_text" style={{textAlign:"right", width:'50%', textDecoration:"underline",cursor:"pointer"}} 
                                                onClick={(e)=>(confirmInterviewDate(e,props.interviewData,props.interviewData.interview_dt3))}>면접일정 확정하기</p>
                                            </>
                                        );
                                      default:
                                        return <p className="date_selected_text">구직자 응답(가능한 면접일정이 없습니다.)</p>;
                                    }
                                  })(): <p className="date_selected_text">구직자 응답대기중입니다.</p>
                            }
                            </div>
                        </div>
                        {
                            selectDate && (
                            <div className="interview_tab_contents">
                                <div id="btn11" className={"tab_content_select current"}>
                                    <div className="selection_wrap">
                                        <p>면접일 1</p>
                                        <div className="line_date_select">                     
                                        <input type="text" id="" className="input_datepicker"
                                                name={`selectedDate1`} style={{display: "none"}} />
                                            <DatePicker
                                                locale={ko} selected={selectedDate1 ? new Date(selectedDate1) : null}
                                                onChange={(date) => {setupInterviewInfoDate(1,'D',dayjs(date).format('YYYY-MM-DD'))}}
                                                dateFormat="yyyy-MM-dd"
                                            /> 
                                            <select defaultValue='09' value={selectedHour1} onChange={(e)=>{setupInterviewInfoDate(1,'H',e.target.value)}}>
                                                <option key='09' value="09">09시</option>
                                                <option key='10' value="10">10시</option>
                                                <option key='11' value="11">11시</option>
                                                <option key='12' value="12">12시</option>
                                                <option key='13' value="13">13시</option>
                                                <option key='14' value="14">14시</option>
                                                <option key='15' value="15">15시</option>
                                                <option key='16' value="16">16시</option>
                                                <option key='17' value="17">17시</option>
                                                <option key='18' value="18">18시</option>
                                            </select>
                                            <select defaultValue='00' value={selectedMinute1} onChange={(e)=>{setupInterviewInfoDate(1,'M',e.target.value)}}>
                                                <option key='00' value="00">00분</option>
                                                <option key='10' value="10">10분</option>
                                                <option key='20' value="20">20분</option>
                                                <option key='30' value="30">30분</option>
                                                <option key='40' value="40">40분</option>
                                                <option key='50' value="50">50분</option>         
                                            </select>
                                        </div>
                                    </div>
                                    <div className="selection_wrap">
                                        <p>면접일 2</p>
                                        <div className="line_date_select">                    
                                        <input type="text" id="" className="input_datepicker"
                                                name={`selectedDate2`} style={{display: "none"}} />
                                            <DatePicker
                                                locale={ko} selected={selectedDate2 ? new Date(selectedDate2) : null}
                                                onChange={(date) => {setupInterviewInfoDate(2,'D',dayjs(date).format('YYYY-MM-DD'))}}
                                                dateFormat="yyyy-MM-dd"
                                            /> 
                                            <select defaultValue='09' value={selectedHour2} onChange={(e)=>{setupInterviewInfoDate(2,'H',e.target.value)}}>
                                                <option key='09' value="09">09시</option>
                                                <option key='10' value="10">10시</option>
                                                <option key='11' value="11">11시</option>
                                                <option key='12' value="12">12시</option>
                                                <option key='13' value="13">13시</option>
                                                <option key='14' value="14">14시</option>
                                                <option key='15' value="15">15시</option>
                                                <option key='16' value="16">16시</option>
                                                <option key='17' value="17">17시</option>
                                                <option key='18' value="18">18시</option>
                                            </select>
                                            <select defaultValue='00' value={selectedMinute2} onChange={(e)=>{setupInterviewInfoDate(2,'M',e.target.value)}}>
                                                <option key='00' value="00">00분</option>
                                                <option key='10' value="10">10분</option>
                                                <option key='20' value="20">20분</option>
                                                <option key='30' value="30">30분</option>
                                                <option key='40' value="40">40분</option>
                                                <option key='50' value="50">50분</option>         
                                            </select>
                                        </div>
                                    </div>
                                    <div className="selection_wrap">
                                        <p>면접일 3</p>
                                        <div className="line_date_select">                 
                                        <input type="text" id="" className="input_datepicker"
                                                name={`selectedDate3`} style={{display: "none"}} />
                                            <DatePicker
                                                locale={ko} selected={selectedDate3 ? new Date(selectedDate3) : null}
                                                onChange={(date) => {setupInterviewInfoDate(3,'D',dayjs(date).format('YYYY-MM-DD'))}}
                                                dateFormat="yyyy-MM-dd"
                                            /> 
                                            <select defaultValue='09' value={selectedHour3} onChange={(e)=>{setupInterviewInfoDate(3,'H',e.target.value)}}>
                                                <option key='09' value="09">09시</option>
                                                <option key='10' value="10">10시</option>
                                                <option key='11' value="11">11시</option>
                                                <option key='12' value="12">12시</option>
                                                <option key='13' value="13">13시</option>
                                                <option key='14' value="14">14시</option>
                                                <option key='15' value="15">15시</option>
                                                <option key='16' value="16">16시</option>
                                                <option key='17' value="17">17시</option>
                                                <option key='18' value="18">18시</option>
                                            </select>
                                            <select defaultValue='00' value={selectedMinute3} onChange={(e)=>{setupInterviewInfoDate(3,'M',e.target.value)}}>
                                                <option key='00' value="00">00분</option>
                                                <option key='10' value="10">10분</option>
                                                <option key='20' value="20">20분</option>
                                                <option key='30' value="30">30분</option>
                                                <option key='40' value="40">40분</option>
                                                <option key='50' value="50">50분</option>         
                                            </select>
                                        </div>
                                    </div>
                                </div>                  
                            </div>     
                            )
                        }
                        {
                            selectDate ? 
                            <div className="popup_middle_btn_wrap">
                                <a href="#" target="_self" className="btn_submit filled_black"  onClick={(e) => {e.preventDefault(); props.setInterviewModal(false);}}>취소</a>
                                <a href="#" target="_self" className="btn_submit filled_green" onClick={handleSubmit(onSubmithandler, onError)}>확인</a>
                            </div>
                            : <div className="popup_middle_btn_wrap">
                            <a href="#" target="_self" className="btn_submit filled_black"  onClick={(e) => {e.preventDefault(); props.setInterviewModal(false);}}>확인</a>
                                <a href="#" target="_self" className="btn_submit filled_green" onClick={(e) => {e.preventDefault(); setSelectDate(true);}}>변경하기</a>
                            </div>
                        }        
                    </div>
                </div>
            </div>
        </>
    )
};

export default UpdateInterviewModal;