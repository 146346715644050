import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "./Header";
import ChatMessage from "./ChatMessage";
import MessageInput from "./MessageInput";
import GuideComponent from "./Guide";
import "./chatbot.css";
import STTAndTTS from "./STTAndTTS";
import { ReactComponent as ArrowRightIcon } from "../../assets/icon/icon_arrow_right.svg";

const ROUTES_MAP = {
  채용정보검색: "/user/job/recruitInfo#search",
  추천채용정보: "/user/job/recruitInfo#recommended",
  관심채용정보: "/user/job/recruitInfo#desired",
  추천교육: "/user/lms/recommendLecture",
  커리어패스: "/user/job/CareerPath",
  내정보관리: "/user/signup/UserInfo",
  이력서관리: "/user/resume/beforeResume",
  역량평가: "/user/ability/main",
  직무추천: "/user/job/main",
  지원및면접현황: "/user/interview/InterviewMain",
  내강의실: "/user/lms/myLecture",
  로그인: "/user/login/LoginForm",
  회원가입: "/user/signup/selectsignup",
};

const ChatBot = () => {
  const history = useHistory();
  const isLoggedIn = Boolean(localStorage.getItem("user"));

  const MESSAGES = {
    메인으로: {
      response: [
        "안녕하세요!✋",
        "직무매칭 시스템 이용을 돕는 챗봇 드리미예요🌼",
        "질문을 선택하면 드리미가 친절하게 답변해드릴게요😊",
        "\n",
        "마우스 클릭이나 음성답변을 통해 아래 주제별 버튼 선택가능하니",
        "직무매칭 시스템 사용안내를 확인하시고 직무추천 서비스를 받아보세요!",
        "\n",
        "언제나, 당신의 구직활동을 응원하겠습니다 ^^",
        <div
          className="text-button"
          onClick={() => handleSendMessage("직무매칭 안내")}
        >
          1. 직무매칭 안내
        </div>,
        <div
          className="text-button"
          onClick={() => handleSendMessage("채용정보")}
        >
          2. 채용정보
        </div>,
        <div
          className="text-button"
          onClick={() => handleSendMessage("추천교육")}
        >
          3. 추천교육
        </div>,
        <div
          className="text-button"
          onClick={() => handleSendMessage("커리어 패스")}
        >
          4. 커리어 패스
        </div>,
        <div
          className="text-button"
          onClick={() => handleSendMessage("나의구직활동")}
        >
          5. 나의 구직활동
        </div>,
        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button"
            onClick={() => handleSendMessage("회원가입")}
          >
            6. 회원가입 하러가기
          </div>
          <div
            className="text-button"
            onClick={() => handleSendMessage("로그인")}
          >
            7. 로그인 하기
          </div>
        </ShowIf>,
      ],
      ttsText:
        "안녕하세요! 직무매칭 시스템 이용을 돕는 챗봇 드리미예요. 질문을 선택하면 드리미가 친절하게 답변해드릴게요. 마우스 클릭이나 음성답변을 통해 아래 주제별 버튼 선택가능하니 직무매칭 시스템 사용안내를 확인하시고 직무추천 서비스를 받아보세요! 언제나, 당신의 구직활동을 응원하겠습니다",
      callback: null,
    },
    직무매칭안내: {
      response: [
        <h3 className="text-align-center">직무매칭 안내</h3>,
        <div className="horizontal-divider" />,
        "직무매칭 시스템은 장애인 구직자가 등록한 이력서와 NCS 기반 역량평가 결과에 따라 사용자에게 추천직무와 추천채용 정보를 제공합니다.",
        "\n",
        "이에 따른 추천교육과 커리어패스 제공정보를 통해 직무개발을 위한 교육정보 수강도 가능하며, 채용지원 및 면접 일정까지 채용 토탈 프로세스를 한번에 관리하실 수 있습니다.",
        "\n",
        "다음 아래의 주기능을 살펴보시겠습니까?",
        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("채용정보")}
        >
          채용정보
        </div>,
        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("추천교육")}
        >
          추천교육
        </div>,
        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("커리어패스")}
        >
          커리어패스
        </div>,
        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("나의구직활동")}
        >
          나의 구직활동
        </div>,
        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("회원가입")}
          >
            회원가입
          </div>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("로그인")}
          >
            로그인 하기
          </div>
        </ShowIf>,
        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,
      ],
      ttsText:
        "직무매칭 시스템은 장애인 구직자가 등록한 이력서와 NCS 기반 역량평가 결과에 따라 사용자에게 추천직무와 추천채용 정보를 제공합니다. 이에 따른 추천교육과 커리어패스 제공정보를 통해 직무개발을 위한 교육정보 수강도 가능하며, 채용지원 및 면접 일정까지 채용 토탈 프로세스를 한번에 관리하실 수 있습니다.  다음 아래의 주기능을 살펴보시겠습니까?",
      callback: null,
    },
    채용정보: {
      response: [
        <h3 className="text-align-center">채용정보</h3>,
        <div className="horizontal-divider" />,

        <div
          className="text-button"
          onClick={() => handleSendMessage("채용정보 검색")}
        >
          1. 채용정보 검색
        </div>,
        "기업이 올린 전체 채용공고를 확인하는 페이지로 선택조건에 맞는 채용정보 조회가 가능합니다.",

        <div
          className="text-button"
          onClick={() => handleSendMessage("추천 채용정보")}
        >
          2. 추천 채용정보
        </div>,
        "회원가입, 이력서등록, 역량평가까지 완료한 경우 구직자에 제공되는 추천직무에 해당하는 채용정보들을 제공하는 서비스입니다.",

        <div
          className="text-button"
          onClick={() => handleSendMessage("관심 채용정보")}
        >
          3. 관심 채용정보
        </div>,
        "전체 채용정보 검색 페이지에서 관심가는 채용정보를 즐겨찾기(★ 표시)후 따로 한번에 확인하실 수 있습니다.",

        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("회원가입")}
          >
            회원가입
          </div>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("로그인")}
          >
            로그인 하기
          </div>
        </ShowIf>,
        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,
      ],
      ttsText:
        "1. [채용정보 검색] : 기업이 올린 전체 채용공고를 확인하는 페이지로 선택조건에 맞는 채용정보 조회가 가능합니다. 2. [추천 채용정보] : 회원가입, 이력서등록, 역량평가까지 완료한 경우 구직자에 제공되는 추천직무에 해당하는 추천 채용정보들을 제공하는 서비스 입니다. 3. [관심 채용정보] : 전체 채용정보 검색 페이지에서 관심가는 채용정보를 즐겨찾기(별 표시) 후 따로 한번에 확인하실 수 있습니다.",
      callback: null,
    },
    채용정보검색: {
      response: [
        <h3 className="text-align-center">채용정보 검색</h3>,
        <div className="horizontal-divider" />,
        "1. 전체 채용정보들을 확인할 수 있는 페이지로 현재 채용중이거나 마감된 채용정보들 확인이 가능합니다.",
        "2. 재택근무, 고용형태, 장애유형별 조건검색하여 관련 채용정보들을 조회할 수 있습니다.",
        "3. 추천 채용공고의 경우 리스트 상단에 고정 노출됩니다.",

        <div
          className="text-button bg-blue"
          onClick={() => {
            history.push(ROUTES_MAP.채용정보검색);
          }}
        >
          해당화면으로 이동하기
          <ArrowIcon />
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,

        <div
          className="text-button text-align-center bg-gray"
          onClick={handleGoBack}
        >
          이전으로
        </div>,

        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("회원가입")}
          >
            회원가입
          </div>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("로그인")}
          >
            로그인 하기
          </div>
        </ShowIf>,
      ],
      ttsText:
        "1. 전체 채용정보들을 확인할 수 있는 페이지로 현재 채용중이거나 마감된 채용정보들 확인이 가능합니다. 2. 재택근무, 고용형태, 장애유형별 조건검색하여 관련 채용정보들을 조회할 수 있습니다. 3. 추천 채용공고의 경우 리스트 상단에 고정 노출됩니다.",
      callback: null,
    },
    추천채용정보: {
      response: [
        <h3 className="text-align-center">추천 채용정보</h3>,
        <div className="horizontal-divider" />,
        "1. 추천직무에 해당하는 채용공고 정보들을 요약한 채용공고별 카드 리스트가 노출되며, 카드 하단에 위치한 [채용정보 자세히보기] 버튼 클릭시 채용공고 상세보기 페이지로 이동합니다.",
        "2. 추천직무 관련 채용정보가 없을 경우 채용정보가 노출되지 않습니다.",
        "3. 카드 오른쪽 상단의 즐겨찾기(★ 표시)선택시 관심 채용정보에서 확인이 가능합니다.",

        <div
          className="text-button bg-blue"
          onClick={() => {
            history.push(ROUTES_MAP.추천채용정보);
          }}
        >
          해당화면으로 이동하기
          <ArrowIcon />
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,

        <div
          className="text-button text-align-center bg-gray"
          onClick={handleGoBack}
        >
          이전으로
        </div>,

        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("회원가입")}
          >
            회원가입
          </div>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("로그인")}
          >
            로그인 하기
          </div>
        </ShowIf>,
      ],
      ttsText:
        "1. 추천직무에 해당하는 채용공고 정보들을 요약한 채용공고별 카드 리스트가 노출되며, 카드 하단에 위치한 [채용정보 자세히보기] 버튼 클릭시 채용공고 상세보기 페이지로 이동합니다. 2. 추천직무 관련 채용정보가 없을 경우 채용정보가 노출되지 않습니다. 3. 카드 오른쪽 상단의 즐겨찾기(별 표시)선택시 관심 채용정보에서 확인이 가능합니다.",
      callback: null,
    },
    관심채용정보: {
      response: [
        <h3 className="text-align-center">관심 채용정보</h3>,
        <div className="horizontal-divider" />,
        "- 사용자가 즐겨찾기(★ 표시)한 관심 채용 공고 카드가 리스트로 조회됩니다.",

        <div
          className="text-button bg-blue"
          onClick={() => {
            history.push(ROUTES_MAP.관심채용정보);
          }}
        >
          해당화면으로 이동하기
          <ArrowIcon />
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,

        <div
          className="text-button text-align-center bg-gray"
          onClick={handleGoBack}
        >
          이전으로
        </div>,

        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("회원가입")}
          >
            회원가입
          </div>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("로그인")}
          >
            로그인 하기
          </div>
        </ShowIf>,
      ],
      ttsText:
        "사용자가 즐겨찾기(별 표시)한 관심 채용공고 카드가 리스트로 조회됩니다.",
      callback: null,
    },
    추천교육: {
      response: [
        <h3 className="text-align-center">추천교육</h3>,
        <div className="horizontal-divider" />,
        "1. 추천직무별 추천교육 정보 카드 리스트로 제공합니다.",
        "2. 추천교육 무료수강을 희망할 경우, 추천교육 카드 클릭 후 별도 회원가입을 통해 계정연동이 완료되야 수강이 가능합니다.",
        "3. 수강내역 현황은 화면 왼쪽 [내 강의실] 클릭 후 확인이 가능합니다.",
        "4. 추천직무에 해당하는 추천교육이 없는 경우 준비중으로 교육정보 리스트가 보이지 않습니다.",

        <div
          className="text-button bg-blue"
          onClick={() => {
            history.push(ROUTES_MAP.추천교육);
          }}
        >
          해당화면으로 이동하기
          <ArrowIcon />
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,

        <div
          className="text-button text-align-center bg-gray"
          onClick={handleGoBack}
        >
          이전으로
        </div>,

        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("회원가입")}
          >
            회원가입
          </div>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("로그인")}
          >
            로그인 하기
          </div>
        </ShowIf>,
      ],
      ttsText:
        "1. 추천직무별 추천교육 정보 카드 리스트로 제공합니다. 2. 추천교육 무료수강을 희망할 경우, 추천교육 카드 클릭후 별도 회원가입을 통해 계정연동이 완료되야 수강이 가능합니다.  3. 수강내역 현황은 화면 왼쪽 [내 강의실] 클릭 후 확인이 가능합니다.  4. 추천직무에 해당하는 추천교육이 없는 경우 준비중으로 교육정보 리스트가 보이지 않습니다.",
      callback: null,
    },
    커리어패스: {
      response: [
        <h3 className="text-align-center">커리어패스</h3>,
        <div className="horizontal-divider" />,
        "1. 1순위 추천직무 정의와 관련직업 예시, 직무에 필요한 요구능력 정보들이 제공됩니다.",
        "2. 화면 하단의 테이블에서는 1순위 추천직무에 재직한 선배 장애인 재직자들의 최종학력, 전공, 자격증 정보들이 제공됩니다.",
        "3. 1순위 추천직무 관련 재직자들의 싀펙정보가 없을 경우 NCS 국가직무능력 표준개요정보(전공, 자격증)를 제공합니다.",

        <div
          className="text-button bg-blue"
          onClick={() => {
            history.push(ROUTES_MAP.커리어패스);
          }}
        >
          해당화면으로 이동하기
          <ArrowIcon />
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,

        <div
          className="text-button text-align-center bg-gray"
          onClick={handleGoBack}
        >
          이전으로
        </div>,

        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("회원가입")}
          >
            회원가입
          </div>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("로그인")}
          >
            로그인 하기
          </div>
        </ShowIf>,
      ],
      ttsText:
        "1. 1순위 추천직무 정의와 관련직업 예시, 직무에 필요한 요구능력 정보들이 제공됩니다. 2. 화면 하단의 테이블에서는 1순위 추천직무에 재직한 선배 장애인 재직자들의 최종학력, 전공, 자격증 정보들이 제공됩니다. 3. 1순위 추천직무 관련 재직자들의 스펙정보가 없을 경우 NCS 국가직무능력 표준개요정보(전공, 자격증)를 제공합니다.",
      callback: null,
    },
    나의구직활동: {
      response: [
        <h3 className="text-align-center">나의 구직활동</h3>,
        <div className="horizontal-divider" />,
        "1. 직무매칭 시스템 활용을 통한 나의 구직활동을 확인할 수 있는 영역입니다.",
        <br />,
        "2. 아래 3단계가 순서대로 진행되어야 추천직무를 제공받고 채용지원이 가능합니다.",
        <br />,
        "①단계.  회원가입완료",
        "②단계.  이력서 등록 ",
        "③단계. 역량평가",
        <br />,
        "3. 진행완료된 경우 V 체크표시되며, 미완료시 ! 표시 됩니다.",

        <img
          src="/img/chatbot/example.webp"
          alt="example"
          style={{ padding: "10px 0" }}
        />,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("내정보관리")}
        >
          내정보 관리
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("이력서관리")}
        >
          이력서 관리
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("역량평가")}
        >
          역량평가
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("직무추천")}
        >
          직무추천
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("채용정보")}
        >
          채용정보
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("지원및면접현황")}
        >
          지원 및 면접현황
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("내강의실")}
        >
          내 강의실
        </div>,

        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("회원가입")}
          >
            회원가입
          </div>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("로그인")}
          >
            로그인 하기
          </div>
        </ShowIf>,
      ],
      ttsText:
        "1. 직무매칭 시스템 활용을 통한 나의 구직활동을 확인 할 수 있는 영역입니다. 2. 아래 3단계가 순서대로 진행되어야 추천직무를 제공받고 채용지원이 가능합니다.  1단계.  회원가입완료 2단계.  이력서 등록  3단계. 3. 진행완료된 경우 V 체크표시되며, 미완료시 느낌표 표시 됩니다.  역량평가 [메인으로] [내정보 관리] [이력서관리] [역량평가]  [직무추천] [채용정보] [지원 및 면접현황] [내 강의실]",
      callback: null,
    },
    내정보관리: {
      response: [
        <h3 className="text-align-center">내정보 관리</h3>,
        <div className="horizontal-divider" />,
        "회원가입한 내정보 확인 및 수정이 가능합니다. (ex 비밀번호, 이력서사진 수정)",

        <div
          className="text-button bg-blue"
          onClick={() => {
            history.push(ROUTES_MAP.내정보관리);
          }}
        >
          해당화면으로 이동하기
          <ArrowIcon />
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,

        <div
          className="text-button text-align-center bg-gray"
          onClick={handleGoBack}
        >
          이전으로
        </div>,

        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("회원가입")}
          >
            회원가입
          </div>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("로그인")}
          >
            로그인 하기
          </div>
        </ShowIf>,
      ],
      ttsText:
        "회원가입한 내정보 확인 및 수정이 가능합니다 (ex 비밀번호, 이력서사진 수정)",
      callback: null,
    },
    이력서관리: {
      response: [
        <h3 className="text-align-center">이력서 관리</h3>,
        <div className="horizontal-divider" />,
        "1. 최대 3개까지 이력서 등록 및 수정이 가능합니다.",
        "2. 가장 최신 등록 및 수정된 이력서를 기준으로 추천직무를 제공받습니다.",
        "3. 희망직무 3가지 필수 선택후 학력 및 경력사항 정보 등 이력정보를 입력합니다.",
        "4. 이력서 등록 진행방식 선택이 가능합니다. (작성형, 대화형)",

        <div
          className="text-button bg-blue"
          onClick={() => {
            history.push(ROUTES_MAP.이력서관리);
          }}
        >
          해당화면으로 이동하기
          <ArrowIcon />
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,

        <div
          className="text-button text-align-center bg-gray"
          onClick={handleGoBack}
        >
          이전으로
        </div>,

        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("회원가입")}
          >
            회원가입
          </div>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("로그인")}
          >
            로그인 하기
          </div>
        </ShowIf>,
      ],
      ttsText:
        "1. 최대 3개까지 이력서 등록 및 수정이 가능합니다. 2. 가장 최신 등록 및 수정된 이력서를 기준으로 추천직무를 제공받습니다. 3. 희망직무 3가지 필수 선택후 학력 및 경력사항 정보 등 이력정보를 입력합니다. 4.  이력서 등록 진행방식 선택이 가능합니다. (작성형, 대화형)",
      callback: null,
    },
    역량평가: {
      response: [
        <h3 className="text-align-center">역량평가</h3>,
        <div className="horizontal-divider" />,
        "1. 게임형 4가지, 문항형 19가지 유형으로 역량평가가 진행됩니다.",
        "2. 역량평가 진행방식 선택이 가능합니다. (작성형, 대화형)",
        "3. 역량평가 완료 후 선택한 답변에 따른 역량평가 결과 상세보기가 가능합니다.",

        <div
          className="text-button bg-blue"
          onClick={() => {
            history.push(ROUTES_MAP.역량평가);
          }}
        >
          해당화면으로 이동하기
          <ArrowIcon />
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,

        <div
          className="text-button text-align-center bg-gray"
          onClick={handleGoBack}
        >
          이전으로
        </div>,

        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("회원가입")}
          >
            회원가입
          </div>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("로그인")}
          >
            로그인 하기
          </div>
        </ShowIf>,
      ],
      ttsText:
        "1. 게임형 4가지, 문항형 19가지 유형으로 역량평가가 진행됩니다. 2. 역량평가 진행방식 선택이 가능합니다. (작성형, 대화형)  3. 역량평가 완료 후 선택한 답변에 따른 역량평가 결과 상세보기가 가능합니다.",
      callback: null,
    },
    직무추천: {
      response: [
        <h3 className="text-align-center">직무추천</h3>,
        <div className="horizontal-divider" />,
        "사용자의 이력서정보와 역량평가 응답에 따른 추천직무 5순위까지 정보가 제공됩니다.",

        <div
          className="text-button bg-blue"
          onClick={() => {
            history.push(ROUTES_MAP.직무추천);
          }}
        >
          해당화면으로 이동하기
          <ArrowIcon />
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,

        <div
          className="text-button text-align-center bg-gray"
          onClick={handleGoBack}
        >
          이전으로
        </div>,

        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("회원가입")}
          >
            회원가입
          </div>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("로그인")}
          >
            로그인 하기
          </div>
        </ShowIf>,
      ],
      ttsText:
        "사용자의 이력서정보와 역량평가 응답에 따른 추천직무 5순위까지 정보가 제공됩니다.",
      callback: null,
    },
    지원및면접현황: {
      response: [
        <h3 className="text-align-center">지원 및 면접현황</h3>,
        <div className="horizontal-divider" />,
        "면접 및 지원내역을 관리하는 페이지로 조회기간, 지원상태별 지원현황 정보 조회가 가능합니다.",

        <div
          className="text-button bg-blue"
          onClick={() => {
            history.push(ROUTES_MAP.지원및면접현황);
          }}
        >
          해당화면으로 이동하기
          <ArrowIcon />
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,

        <div
          className="text-button text-align-center bg-gray"
          onClick={handleGoBack}
        >
          이전으로
        </div>,

        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("회원가입")}
          >
            회원가입
          </div>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("로그인")}
          >
            로그인 하기
          </div>
        </ShowIf>,
      ],
      ttsText:
        "면접 및 지원내역을 관리하는 페이지로 조회기간, 지원상태별 지원현황 정보 조회가 가능합니다.",
      callback: null,
    },
    내강의실: {
      response: [
        <h3 className="text-align-center">내 강의실</h3>,
        <div className="horizontal-divider" />,
        "1. 추천교육을 수강한 이력이 있을경우 수강현황 정보가 다음아래와 같이 조회가능합니다.",
        "2. 현재 수강중인 class: 현재 수강중인 클래스 정보가 노출되며, 최근까지 수강한 영역부터 이어서 듣기가 가능합니다.",
        "3. 지난 수강완료 class: 지난 수강완료 추천교육 정보가 노출됩니다. 수강기한이 마감되지 않아 다시듣기가 가능합니다.",
        "4. 수강기한 마감 class: 수강기한이 마감된 추천교육 정보가 노출되며, 다시듣기가 불가합니다.",

        <div
          className="text-button bg-blue"
          onClick={() => {
            history.push(ROUTES_MAP.내강의실);
          }}
        >
          해당화면으로 이동하기
          <ArrowIcon />
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,

        <div
          className="text-button text-align-center bg-gray"
          onClick={handleGoBack}
        >
          이전으로
        </div>,

        <ShowIf condition={!isLoggedIn}>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("회원가입")}
          >
            회원가입
          </div>
          <div
            className="text-button text-align-center"
            onClick={() => handleSendMessage("로그인")}
          >
            로그인 하기
          </div>
        </ShowIf>,
      ],
      ttsText:
        "1. 추천교육을 수강한 이력이 있을경우 수강현황 정보가 다음아래와 같이 조회가능합니다. 2. 현재 수강중인 class : 현재 수강중인 클래스 정보가 노출되며, 최근까지 수강한 영역부터 이어서 듣기가 가능합니다.  3. 지난 수강완료  class : 지난 수강완료 추천교육 정보가 노출됩니다. 수강기한이 마감되지 않아 다시듣기가 가능합니다.   4. 수강기한 마감 class : 수강기한이 마감된 추천교육 정보가 노출되며, 다시듣기가 불가합니다.",
      callback: null,
    },
    로그인: {
      response: [
        <h3 className="text-align-center">로그인 하기</h3>,
        <div className="horizontal-divider" />,
        "회원가입 완료한 계정정보 아이디와 비밀번호 입력 후 로그인이 완료되어야 직무매칭 시스템의 제공서비스 활용이 가능합니다.",

        <div
          className="text-button text-align-center"
          onClick={() => history.push(ROUTES_MAP.로그인)}
        >
          로그인하기
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,

        <div
          className="text-button text-align-center bg-gray"
          onClick={handleGoBack}
        >
          이전으로
        </div>,
      ],
      ttsText:
        "회원가입 완료한 계정정보 아이디와 비밀번호 입력 후 로그인이 완료되어야 직무매칭 시스템의 제공서비스 활용이 가능합니다.",
      callback: null,
    },
    회원가입: {
      response: [
        <h3 className="text-align-center">회원가입</h3>,
        <div className="horizontal-divider" />,
        "1. 이용약관 동의후 회원가입 계정유형 선택하여 회원가입 절차를 진행합니다.",
        "2. 계정유형은 일반 계정만들기와 소셜계정으로 간편 회원가입이 가능합니다.",
        "3. 소셜계정은 구글, 카카오, 네이버, 페이스북으로 회원가입이 가능합니다.",

        <div
          className="text-button text-align-center"
          onClick={() => history.push(ROUTES_MAP.회원가입)}
        >
          회원가입하러 가기
        </div>,

        <div
          className="text-button text-align-center"
          onClick={() => handleSendMessage("메인으로")}
        >
          메인으로
        </div>,

        <div
          className="text-button text-align-center bg-gray"
          onClick={handleGoBack}
        >
          이전으로
        </div>,
      ],
      ttsText:
        "1. 이용약관 동의후 회원가입 계정유형 선택하여 회원가입 절차를 진행합니다.  2. 계정유형은 일반 계정만들기와 소셜계정으로 간편 회원가입이 가능합니다.  3. 소셜계정은 구글, 카카오, 네이버, 페이스북으로 회원가입이 가능합니다.  [회원가입하러 가기] ",
      callback: null,
    },
  };

  const [messages, setMessages] = useState([
    {
      sender: "bot",
      text: MESSAGES.메인으로.response,
      ttsText: MESSAGES.메인으로.ttsText,
      date: new Date(),
      showDreame: true,
    },
  ]);

  const [pendingUserMessage, setPendingUserMessage] = useState(null);

  const [showGuide, setShowGuide] = useState(() => {
    if (localStorage.getItem("neverShowGuide") === "true") return false;
    return true;
  });

  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);

  useEffect(() => {
    if (pendingUserMessage) {
      const text = pendingUserMessage;
      setPendingUserMessage(null);

      setTimeout(() => {
        let showDreame = true;
        let botResponse;
        let ttsText;

        const matchedKey = findMatchingKey(text, Object.keys(MESSAGES));

        if (matchedKey) {
          const messageObj = MESSAGES[matchedKey];
          botResponse = messageObj.response;
          ttsText = messageObj?.ttsText || "";
          messageObj.callback && messageObj.callback();
        } else {
          showDreame = false;
          botResponse = [
            "입력하신 내용을 이해하지 못했어요.",
            "아래 항목 중에서 다시 선택해 주세요.",
          ];

          const lastBotMessage = messages.findLast(
            (message) => message.sender === "bot" && message.showDreame === true
          );
          setTimeout(() => {
            setMessages((prev) => [...prev, lastBotMessage]);
          }, 800);
        }

        setMessages((prevMessages) => [
          ...prevMessages,
          {
            key: matchedKey,
            sender: "bot",
            text: botResponse,
            ttsText,
            date: new Date(),
            showDreame,
          },
        ]);
      }, 300);
    }
  }, [pendingUserMessage, messages]);

  const handleSendMessage = (text) => {
    if (text.includes("이전")) return handleGoBack();
    if (text.includes("해당")) {
      const lastBotMessageKey = messages[messages.length - 1].key;

      if (ROUTES_MAP[lastBotMessageKey]) {
        history.push(ROUTES_MAP[lastBotMessageKey]);
        return;
      } else {
        console.log("No matching route found for the last bot message.");
      }
    }

    if (!text.trim()) return;

    setMessages((prevMessages) => [
      ...prevMessages,
      { key: null, sender: "user", text: [text], date: new Date() },
    ]);
    setPendingUserMessage(text);
  };

  // 이전 메시지로 돌아가는 함수
  function handleGoBack() {
    if (messages.length > 1) {
      setMessages((prevMessages) => prevMessages.slice(0, -2)); // 마지막 메시지 삭제
    }
  }

  return (
    <div style={styles.appContainer}>
      {showGuide && <GuideComponent setShowGuide={setShowGuide} />}
      <Header setShowGuide={setShowGuide} />
      <ChatMessage
        messages={messages}
        goToMain={() => handleSendMessage("메인으로")}
      />
      <STTAndTTS
        messages={messages}
        handleSendMessage={handleSendMessage}
        isListening={isListening}
        setIsListening={setIsListening}
        isSpeaking={isSpeaking}
        setIsSpeaking={setIsSpeaking}
      />
      <MessageInput
        handleSendMessage={handleSendMessage}
        isListening={isListening}
      />
    </div>
  );
};

const styles = {
  appContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    maxHeight: "calc(100vh - 335px)",
    minHeight: "calc(100vh - 335px)",

    backgroundColor: "#fff",
  },
};

export default ChatBot;

const findMatchingKey = (input, keys) => {
  const normalizedInput = input.replace(/\s+/g, "");

  if (normalizedInput.length < 2) return null; // Minimum 2 characters required for matching

  // 1. 완전 일치하는 키가 있는지 먼저 확인
  const exactMatch = keys.find((key) => key === normalizedInput);
  if (exactMatch) {
    return exactMatch;
  }

  // 2. 부분 일치 확인 (키에 입력이 포함된 경우)
  const partialMatches = keys.filter(
    (key) => key.includes(normalizedInput) || normalizedInput.includes(key)
  );

  // 부분 일치된 키 중 가장 길이가 긴 것을 반환 (근접한 의미로)
  if (partialMatches.length > 0) {
    // 입력과 가장 길게 일치하는 키를 반환
    return partialMatches.reduce((longest, current) => {
      return current.length > longest.length ? current : longest;
    });
  }

  return null; // No match found
};

function ShowIf({ condition, children }) {
  if (!condition) return null;
  return <> {children}</>;
}

const ArrowIcon = () => {
  return (
    <ArrowRightIcon
      className="arrow-icon"
      style={{ height: 16, position: "absolute", right: 4, top: 7.5 }}
    />
  );
};
