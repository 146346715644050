import axios from "axios";

export async function findUserId(phoneNo, name) {
    const num = phoneNo.replaceAll("-", "");

    const response = await axios.get('/api/login/findUserId', {
        params: {
            phoneNo: num,
            name: name
        }
    });

    return response;
}

export async function findCompId(managerNm, bizSeq) {
    const response = await axios.get('/api/login/findCompId', {
        params: {
            managerNm: managerNm,
            bizSeq: bizSeq
        }
    });

    return response;
}

export async function findCompPw(managerNm, managerTel) {
    const num = managerTel.replaceAll("-", "");

    const response = await axios.get('/api/login/findCompPw', {
        params: {
            managerNm: managerNm,
            managerTel: num
        }
    });

    return response;
}

export async function matchingUserPw(phoneNo, memberPw) {
    const response = await axios.get('/api/login/matchingUserPw', {
        params: {
            phoneNo: phoneNo,
            memberPw: memberPw,
        }
    });

    return response;
}

export async function matchingCompPw(managerTel, memberPw) {
    const response = await axios.get('/api/login/matchingCompPw', {
        params: {
            managerTel: managerTel,
            memberPw: memberPw,
        }
    });

    return response;
}

export async function updateUserPw(memberId, memberPw) {
    const response = await axios.put('/api/login/updateUserPw', null, {
        params: {
            memberId: memberId,
            memberPw: memberPw,
        }
    });

    return response;
}

export async function updateCompPw(memberId, memberPw) {
    const response = await axios.put('/api/login/updateCompPw', null, {
        params: {
            memberId: memberId,
            memberPw: memberPw,
        }
    });

    return response;
}