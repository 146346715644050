import React, { useState, Component } from 'react';

const TermsConditions = () => {
    const [agree, setAgree] = useState(false);

    const message = e => {
        alert("약관에 동의하셔야 다음 단계로 진행가능합니다.")
    }
    const agreeMessage = e => {
        alert("현 버전에서는 사용자 개인정보가 기업에 제공되지 않습니다.")
    }

    return (
        <div>
            <div className="wrapper">
                <main>
                    <div className="page join page_join enterprise">
                        <h2 className="page_title">기업회원 가입하기</h2>
                        <div className="wrap_progressbar">
                            <ul className="progressbar_list progress_step01">
                                <li className="progressbar_item progress_item01"><p>이용약관 동의</p></li>
                                <li className="progressbar_item progress_item02"><p>회원정보 등록</p></li>
                            </ul>
                        </div>
                        <div className="page_inner">
                            <div className="area_shade">
                                <div className="wrap_title_step">
                                    <span className="badge badge_step">STEP 1</span>
                                    <h3 className="title_step">이용약관에 동의해 주세요.</h3>
                                </div>
                                <div className="box_txt" tabIndex="0">
                                    <p>장애인 직무매칭 – 이용약관동의서 (기업용)</p><br />

                                    <p>제 1장 총칙</p><br />

                                    <p>제 1 조 (목적)</p>
                                    본 약관은 주식회사 와이즈넛, 주식회사 브이드림, 사단법인 청십자사회복지회(이하 “컨소시엄”)가 제공하는 장애인직무매칭시스템 서비스의 이용과 관련하여 컨소시엄과 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정하는 것을 목적으로 한다.<br /><br />

                                    장애인 직무매칭 시스템은 ‘산업통상자원부’에서 전문기관 ‘한국산업기술평가관리원’을 통해 지원하는 지식서비스산업 기술개발 사업으로 주관기관 와이즈넛, 참여기관 브이드림, 청십자사회복지회이 과제명 ‘장애인 고용 촉진을 위한 비대면 직무매칭(역량평가) 및 직무개발 지원 시스템 개발’로 개발 중인 시스템이다.<br /><br />
                                    <p>제 2 조 (용어의 정의)</p>
                                        이 약관에서 사용하는 용어의 정의는 아래와 같다.<br /><br />
                                        ① "사이트"라 함은 컨소시엄이 서비스를 “ 이용자에게 제공하기 위하여 제작, 운영하는 사이트를 말합니다. 현재 컨소시엄이 운영하는 사이트의 접속 주소는 아래와 같다.<br />
                                        - https://matching.vdream.co.kr/<br />
                                        - m.matching.vdream.co.kr/ (비활성화)<br /><br />

                                        ② ”서비스“라 함은 채용정보, 이력서 및 기업정보 제공 기타의 서비스를 통하여 구직자와 기업의 연결을 돕는 플랫폼 서비스이다. 구체적으로는 ”컨소시엄“이 구직자의 직무추천, 직무교육과 구직을 목적으로 등록하는 각종 자료들을 DB화하여 각각의 목적에 맞게 분류 가공, 집계하여 정보를 제공하는 서비스 및 기타 구직이 원활히 이루어지도록 하기 위하여 사이트 등에서 제공하는 모든 서비스를 말한다. 서비스의 자세한 내용은 제8조에서 정하는 바에 따른다.<br /><br />
                                        ③ ”이용자“라 함은 사이트 등에 접속하여 본 약관에 따라 컨소시엄이 제공하는 서비스를 이용하는 회원(개인회원 및 기업회원을 포함) 및 비회원을 말한다.<br /><br />
                                        ④ ”기업회원“ 이라 함은 채용을 희망하는 기업으로서 본 서비스를 이용하기 위하여 본 약관에 동의하고 컨소시엄과 서비스 이용계약을 체결하여 기업회원 ID를 부여받은 이용자를 말한다.<br /><br />
                                        ⑤ ”서비스 이용계약“ 이라 함은 컨소시엄이 기업회원을 위해 제공하는 서비스를 계속적으로 이용하기 위하여 컨소시엄과 이용자 사이에 체결되는 계약을 말한다.<br /><br />
                                        ⑥ ”ID“ 또는 ”기업회원ID“ 라 함은 기업회원의 식별 및 서비스 이용을 위하여 개인회원이 선정하거나 컨소시엄이 부여하는 문자와 숫자의 조합을 말한다.<br /><br />
                                        ⑦ ”비밀번호“ 라 함은 컨소시엄의 서비스를 이용하려는 사람이 기업회원ID를 부여 받은 자와 동일인임을 확인하고 개인회원의 권익을 보호하기 위하여 기업회원이 선정하거나 컨소시엄이 부여하는 문자와 숫자의 조합을 말한다.<br /><br />

                                    <p>
                                        제 3 조 (약관의 명시와 개정)</p>
                                        ① ”컨소시엄“은 이 약관의 내용을 ”이용자“가 알 수 있도록 초기 화면에 게시하거나 기타의 방법으로 이용자에게 공지한다.<br /><br />
                                        ② ”컨소시엄“은 "약관의 규제에 관한 법률", "정보통신망 이용 촉진 및 정보보호등에 관한 법률(이하 "정보통신망법")"등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있다.<br /><br />
                                        ③ ”컨소시엄“은 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지 공지한다. 다만, ”기업회원“ 에게 불리한 약관의 개정의 경우에는 공지 외에 일정기간 서비스 내 전자우편, 전자쪽지 등의 전자적 수단을 통해 따로 명확히 통지하도록 한다.<br /><br />
                                        ④ ”컨소시엄“은 전항에 따라 개정약관을 공지 또는 통지하면서 ”기업회원“ 에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 ”기업회원“ 이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 본다.<br /><br />
                                        ⑤ ”기업회원“ 이 개정약관의 적용에 동의하지 않는 경우 ”컨소시엄“은 개정 약관의 내용을 적용할 수 없으며, 이 경우 ”기업회원“ 은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 ”컨소시엄“ 은 이용계약을 해지할 수 있다.<br /><br />

                                    <p>제 4 조 (약관의 해석)</p> <br /><br />
                                        ① 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 관계법령 또는 상관례에 따른다.<br /><br />

                                    <p>제 2장 회원가입</p><br />
                                    <p>제 5 조 (서비스 이용계약의 성립)</p>
                                    ① 서비스 이용계약은 기업회원 서비스를 이용하고자 하는 자(이하 “이용희망자”라 한다)의 본 약관과 개인정보 처리방침의 내용에 대한 동의 및 회원 가입 신청에 대하여 “컨소시엄”이 승낙함으로써 성립한다.<br /><br />
                                    ② 이용희망자가 기업회원 가입 신청시 사이트 등의 기업회원 가입 화면 중 "동의함" 버튼을 누르면 본 약관 및 개인정보 처리방침을 충분히 읽고 동의한 것으로 간주한다.<br /><br />
                                    ③ "컨소시엄"이 이용신청(회원가입 신청) 작성 후에 "컨소시엄"이 웹상의 안내 및 전자메일 또는 “컨소시엄”이 정한 수단으로 "기업회원"에게 통지함으로써 이용계약이 성립된다.<br /><br />
                                    ④ “기업회원”은 본 약관 및 “컨소시엄”이 개인정보 처리방침에서 정한 항목을 제공해야 한다.<br /><br />

                                    <p>제 6 조 (기업회원 가입신청 및 이용계약의 승낙과 제한)</p>
                                        ① “컨소시엄”은 전조의 규정에 의한 이용희망자에 대하여 다음 각 호의 사유를 모두 충족할 경우 “컨소시엄”이 제공하는 절차에 따라 기업회원 가입을 승낙한다.<br /><br />
                                        1. “컨소시엄”의 업무수행 및 서비스 제공을 위한 설비의 여유•기술상 지장이 없는 경우<br /><br />
                                        2. 본 약관에서 정하는 승낙 제한 또는 거절, 보류 사유에 해당하지 않는 경우<br /><br />

                                        ② "컨소시엄"은 "이용자"의 신청에 대하여 "서비스" 이용을 승낙함을 원칙으로 합니다. 다만, "컨소시엄"은 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있다.<br /><br />
                                        1. “이용자”가 이 약관에 의하여 이전에 서비스 이용자격을 상실한 적이 있는 경우, 단 "컨소시엄"의 재이용 승낙을 얻은 경우에는 예외로 함.<br /><br />
                                        2. 실명이 아니거나 타인의 명의를 이용한 경우<br /><br />
                                        3. 허위의 정보를 기재하거나, "컨소시엄"이 제시하는 내용을 기재하지 않은 경우<br /><br />
                                        4. “이용자“의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우<br /><br />
                                        5. 파견 및 도급 ”기업회원“ 및 헤드헌팅(서치펌) 기업회원<br /><br />
                                        6. ”기업회원“으로 가입한 이후, 회원이 영위하는 사업 목적 또는 형태의 변화로 회원 가입 신청시와 다른 분류의 ”기업회원“에 해당하게 되었을 경우(예를 들어 일반 기업회원이 파견 및 도급 기업회원 또는 헤드헌팅(서치펌) 기업회원으로 변경된 경우 또는 그 반대의 경우 등)<br /><br />

                                    <p>제 7 조 (증빙자료의 제출)</p>
                                        ① ”컨소시엄“은 기업회원 자격 보유 여부를 확인하기 위하여 ”기업회원“에게 사업자등록증 등의 제출을 요청할 수 있으며, ”기업회원“은 이에 응하여야 한다.<br /><br />
                                        ② ”기업회원“은 기업회원으로 가입한 이후, 회원이 영위하는 사업 목적 또는 형태의 변화로 회원 가입 신청시와 다른 분류의 ”기업회원“에 해당하게 되었을 경우(예를 들어 일반 기업회원이 파견 및 도급 기업회원 또는 헤드헌팅(서치펌) 기업회원으로 변경된 경우 또는 그 반대의 경우 등) 즉시 ”컨소시엄“에게 이러한 내용을 통지하여야 한다. 만약, ”기업회원“이 본 조항에 따른 의무를 이행하지 않아 ”컨소시엄“에게 손해가 발생한 경우, ”기업회원“은 ”컨소시엄“에 대하여 제20조에 따른 손해배상책임을 부담하게 된다.<br /><br />
                                        ③ ”컨소시엄“의 증빙 자료 제출 요청에 상당기간 동안 응하지 않는 경우, 제18조에 따른 서비스 이용중지, 강제탈퇴, 재가입 제한 조치 등의 제재조치를 받게 된다.<br /><br />
                                        ④ ”컨소시엄“은 본 조에 따라 ”기업회원“으로부터 제출 받은 자료를 통하여 ”기업회원“의 자격 보유 여부를 확인하며, 기업회원의 자격 보유 사실이 확인되면 당해 자료를 파기한다.<br /><br />

                                    <p>제 3장 서비스의 이용</p><br />
                                    <p>제 8 조 (서비스의 내용)</p>
                                        ① "컨소시엄"은 제2조 2항의 서비스를 제공할 수 있으며 그 내용은 다음 각 호와 같다.<br /><br />
                                        1. 채용정보 등록서비스(직무요청서) <br /><br />
                                        2. 온라인 채용관리 서비스<br /><br />
                                        3. 구인/구직과 관련된 제반 서비스<br /><br />
                                        3-1. 이력서 열람 서비스<br /><br />
                                        3-2. 역량평가 결과 제공 서비스<br /><br />
                                        3-3. 직무교육 이수 정보 제공 서비스<br /><br />
                                        3-4. 온라인 면접 서비스<br /><br />
                                        4. 기업정보 제공 서비스<br /><br />
                                        5. 기타 "컨소시엄"이 추가 개발하거나 제휴계약 등을 통해 "기업회원"에게 제공하는 일체의 서비스<br /><br />
                                        ② "컨소시엄"은 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있다. 다만, 서비스의 내용의 추가 또는 변경으로 인하여 “기업회원”의 권리•의무에 중요한 영향을 미치는 경우, “컨소시엄”은 추가 또는 변경된 서비스 내용을 그 적용일로부터 30일 전에 공지한다.<br /><br />

                                    <p>
                                        제 9 조 (기업정보 및 채용공고의 등록, 지원자 및 면접관리)</p>
                                        ① “기업회원”은 구직을 원하는 이용자가 채용공고를 열람한 후 해당 기업에 대하여 정확히 판단할 수 있도록 기업정보를 사실과 다름없이 정확하게 기재하여야 합니다. 기업정보를 사실과 다르게 기재한 “기업회원”은 이로 인하여 발생한 모든 문제에 대하여 전적인 책임을 부담하여야 한다.<br /><br />
                                        ② “기업회원”은 채용절차 공정화에 관한 법률 제4조, 직업안정법 제34조 및 동법 시행령 제34조에 의하여 금지되는 거짓 구인광고 또는 거짓 채용공고(이하 “거짓 채용공고”라 합니다)를 등록하여서는 안된다. 거짓 채용공고를 등록하거나 거짓 구인 조건을 제시한 기업회원은 채용절차의 공정화에 관한 법률 제16조에 의해 5년 이하의 징역 또는 2천만원 이하의 벌금형을 받거나 직업안정법 제47조에 의해 5년 이하의 징역 또는 5천만 원 이하의 벌금형을 받을 수 있다. “컨소시엄”은 거짓 채용공고를 등록한 “기업회원”을 직권으로 고발할 수 있다.<br /><br />
                                        ③ 제2항에 따라 금지되는 거짓 채용공고는 다음 각 호와 같다.<br /><br />
                                        1. 구인 또는 채용을 가장하여 아이디어 수집, 사업장 홍보, 물품 판매, 유학 알선, 수강생 모집, 직업소개, 부업알선, 자금 모집 등을 행하는 공고<br /><br />
                                        2. 작성자가 제시한 직종, 업무내용, 근로조건 등이 실제와 현저히 다른 공고<br /><br />
                                        3. 회사명, 담당자 성명, 사무실 연락처 등 구인업체의 중요 정보를 정확하게 기입하지 않았을 경우<br /><br />
                                        4. 기타 채용공고의 중요 내용이 사실과 다른 공고<br /><br />
                                        5. 제1호 내지 제4호의 거짓 채용공고를 목적으로 구인자의 신원(업체명 또는 성명)을 표시하지 아니하는 공고<br /><br />
                                        ④ “기업회원”은 남녀 고용 평등과 일, 가정 양립 지원에 관한 법률 제7조에 의거하여 근로자의 모집 및 채용에 있어서 남녀를 차별하여서는 아니된다.<br /><br />
                                        ⑤ “기업회원”이 등록한 기업정보 및 채용공고가 제2항 내지 제5항을 위반한 것으로 인정될 경우, 회사는 제16조 따라 해당 공고의 게시를 중단하거나 삭제하는 등의 조치를 취할 수 있다. 또한, “기업회원”은 “컨소시엄” 및 이용자에 대한 관계에서 제2항의 위반으로 발생할 수 있는 민·형사상 책임을 전적으로 부담한다.<br /><br />
                                        ⑥ "컨소시엄"은 "기업회원"이 등록한 기업정보 및 채용공고를 "컨소시엄"이 정한 방법에 의해 노출할 수 있다.<br /><br />
                                        ⑦ "기업회원"은 등록된 채용정보에 지원한 “개인회원”의 회원정보, 이력서, 역량평가 결과, 직무교육 이수 사항 등의 정보를 열람할 수 있으며, 지원자의 서류통과 여부를 지정할 수 있고, 온라인 면접 서비스를 이용할 수 있다.<br /><br />
                                        ⑧ “기업회원”은 “개인회원”에게 연락을 취할 수 있다. 이 때, 이력서상 기재된 “개인회원”의 연락처의 열람 및 연락의 목적은 채용활동에 국한되어야 하며, 기업의 영업·마케팅을 위하여 활용하거나 제3자에 대한 개인정보 제공 시에는 정보통신망 이용 촉진 및 정보 보호 등 관련 법률, 개인정보 보호법, 직업안정법 위반에 따른 법적 책임을 전적으로 부담한다.<br /><br />
                                        ⑨ “컨소시엄”은 “기업회원”이 “개인회원”에게 연락할 수 있는 권한에 적정한 제한을 둘 수 있으며, “기업회원”이 적절하지 않은 방법이나 채용활동 이외의 목적으로 “개인회원”에게 연락하거나 서비스 이용에 불편을 끼친 경우, “기업회원”의 채용공고 및 기업정보를 임의로 수정/삭제 등의 조치를 취할 수 있다.<br /><br />

                                    <p>제 10 조 (서비스의 요금)</p>
                                        ① “컨소시엄”이 제공하는 서비스 이용을 위한 기업회원 가입과 기업정보 및 직무요청서 등록은 무료이다.<br /><br />

                                    <p>제 11 조 (서비스 이용시간 및 제공 중지)</p>
                                        ① "컨소시엄"은 특별한 사유가 없는 한 연중무휴, 1일 24시간 서비스를 제공한다. 다만, "컨소시엄"은 서비스의 종류나 성질에 따라 제공하는 “서비스” 중 일부에 대해서는 별도로 이용시간을 정할 수 있으며, 이 경우 "컨소시엄"은 그 이용시간을 사전에 "기업회원"에게 공지 또는 통지하여야 한다.<br /><br />
                                        ② “컨소시엄”은 서비스 개선을 위한 시스템 업데이트 기타 유지보수 작업 등을 진행하고자 하는 경우, 사전에 서비스 중단 시간과 작업 내용을 고지하여 일시적 서비스 중단을 시행할 수 있다.<br /><br />
                                        ③ “컨소시엄”의 성실한 의무 이행에도 불구하고 다음 각 호의 사유로 서비스 중지 등 “기업회원”의 손해가 발생한 경우, “컨소시엄”은 그로 인한 책임을 부담하지 않는다.<br /><br />
                                        천재지변 또는 이에 준하는 불가항력으로 인한 손해<br /><br />
                                        회원의 귀책으로 발생한 손해<br /><br />
                                        제3자의 고의 또는 과실로 발생한 손해<br /><br />
                                        기타 “컨소시엄”의 고의 또는 과실 없이 발생한 손해<br /><br />

                                    <p>제 12 조 (서비스 정보의 제공)</p>
                                        ① “컨소시엄”은 "개인회원"에게 서비스 이용에 필요가 있다고 인정되거나 서비스 개선 및 “기업회원” 대상의 서비스 소개 등의 목적으로 하는 각종 정보에 대해서 전자우편이나 서신우편을 이용한 방법으로 제공할 수 있다.<br /><br />

                                    <p> 제 13 조 (게시물 작성과 "컨소시엄"의 정보 수정 권한)</p>
                                        ① 게시물이란 “기업회원”이 등록한 기업정보 및 직무요청서를 게시한 일체의 게시물을 의미한다.<br /><br />
                                        ② “기업회원”은 게시물 작성시 서비스 제공 목적에 부합하게 정확한 사실에 근거하여 성실하게 그 내용을 작성하여야 하며, 작성된 게시물의 내용이 사실이 아니거나 부정확한 경우 발생하는 모든 문제에 대하여 전적인 책임을 부담한다.<br /><br />
                                        ③ 모든 게시물의 작성 및 관리는 “기업회원” 본인이 하는 것이 원칙이며, 제3자를 통해 위탁 또는 대행 관리를 하더라도 게시물 작성 및 관리에 관련된 일체의 책임은 “기업회원”에게 귀속된다. “기업회원”은 주기적으로 작성된 게시물을 확인하여 정확한 내용이 포함될 수 있도록 수정·변경하는 등 일체의 노력을 다하여야 한다.<br /><br />
                                        ④ "컨소시엄"은 "기업회원"이 등록한 게시물에 오자, 탈자 또는 사회적 통념에 어긋나는 문구와 내용이 있을 경우 이를 언제든지 수정할 수 있다.<br /><br />
                                        ⑤ “컨소시엄”은 "기업회원"이 등록하는 게시물이 다음 각 호에 해당한다고 판단하는 경우에 “기업회원”에게 사전 통지 없이 삭제할 수 있다.<br /><br />
                                        1. 다른 이용자 또는 제3자를 비방하거나 중상모략으로 명예를 손상키기는 경우<br /><br />
                                        2. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 유포하는 경우<br /><br />
                                        3. 반국가적, 반사회적, 범죄적 행위와 결부된다고 판단되는 경우<br /><br />
                                        4. 기타 법령에 위배된다고 판단되는 경우<br /><br />

                                    <p>제 14 조 (자료 내용의 활용 및 취급)</p>
                                        ① "기업회원"이 입력한 정보는 ‘장애인 고용 촉진을 위한 비대면 직무매칭(역량평가) 및 직무개발 지원 시스템 개발’과제의 수행목적으로서 보고서 작성을 위한 통계 자료로 구성, 활용 될 수 있으며 그 자료는 과제를 지원하는 전담기관에 제공될 수 있다. 단 개인을 식별할 수 있는 형태가 아니어야 한다.<br /><br />
                                        ② "사이트"에서 정당한 절차를 거쳐 열람한 “개인회원”의 이력서 등 정보는 해당 "기업회원"의 인사자료이며 이에 대한 관리 권한은 해당 "기업회원"의 정책에 의한다.<br /><br />
                                        ③ "컨소시엄"은 "사이트"의 온라인 입사지원 시스템을 통해 지원한 "개인회원"의 이력서 열람 여부 및 “기업회원”이 제공한 채용정보에 입사지원한 구직자들의 각종 통계데이터를 "개인회원"에게 제공할 수 있다. 단 개인을 식별할 수 있는 형태가 아니어야 한다.<br /><br />

                                    <p>제 15 조 (게시물과 지식재산권 등)</p>
                                        ① “컨소시엄”이 작성한 게시물 또는 저작물에 대한 저작권 및 기타 지식재산권은 “컨소시엄”에 귀속한다.<br /><br />
                                        ② “기업회원”이 사이트 등에 게시한 게시물에 대한 권리와 책임은 해당 게시물을 게시한 “기업회원“에게 있다. ”컨소시엄“은 ”기업회원“의 동의 없이 해당 게시물을 서비스 제공 이외의 영리적 목적으로 사용할 수 없다. 다만, 비영리적 목적으로 사용하는 경우에는 ”기업회원“의 동의를 요하지 않는다.<br /><br />

                                    <p>제 16 조 ("컨소시엄"의 의무)</p>
                                        ① "컨소시엄"은 본 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다해야 한다.<br /><br />
                                        ② "컨소시엄"은 서비스와 관련한 "기업회원"의 불만사항이 접수되는 경우 이를 즉시 처리하여야 하며, 즉시 처리가 곤란한 경우에는 그 사유와 처리일정을 서비스 화면 또는 기타 방법을 통해 동 "기업회원"에게 통지하여야 한다.<br /><br />
                                        ③ 천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가 발생하여 서비스가 중단될 경우 이에 대한 손해에 대해서는 "컨소시엄"이 책임을 지지 않는다. 다만 자료의 복구나 정상적인 서비스 지원이 되도록 최선을 다할 의무를 진다.<br /><br />
                                        ④ ”기업회원“이 사이트에 등록한 기업정보 및 채용공고 내용은 ”사이트“ 등을 방문하는 모든 ”이용자“에게 공개함을 원칙으로 하며, 공개 기간은 ”기업회원“이 지정할 수 있다. <br /><br />
                                        ⑤ ”컨소시엄“은 ”기업회원“ 가입과 관련하여 취득한 ”기업회원“의 정보를 타인에게 제공하거나 활용하고자 할 때에는 사전에 그 사유 및 제3자 제공 기관 또는 업체명 등을 밝히고 해당 ”기업회원“의 동의를 얻어야 한다.<br /><br />

                                    <p>제 17 조 ("기업회원"의 의무)</p>
                                        ① "기업회원"은 관계법령과 본 약관의 규정 및 기타 "컨소시엄"이 통지하는 사항을 준수하여야 하며, 기타 "컨소시엄"의 업무에 지장을 초래하는 행위를 해서는 안 된다.<br /><br />
                                        ② "기업회원"은 서비스를 이용하여 얻은 정보를 "컨소시엄"의 사전동의 없이 복사, 복제, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없다.<br /><br />
                                        ③ "기업회원"은 본 서비스를 직원채용 이외의 목적으로 사용해서는 안되며 이용 중 다음 각 호의 행위를 해서는 안 된다.<br /><br />
                                        1. 다른 회원의 아이디를 부정 사용하는 행위<br /><br />
                                        2. 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위<br /><br />
                                        3. 타인의 명예를 훼손하거나 모욕하는 행위<br /><br />
                                        4. 타인의 지적재산권 등의 권리를 침해하는 행위<br /><br />
                                        5. 해킹행위 또는 바이러스의 유포 행위<br /><br />
                                        6. 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 계속적으로 전송하는 행위<br /><br />
                                        7. 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는 행위<br /><br />
                                        8. 사이트의 정보 및 서비스를 이용한 영리 행위<br /><br />
                                        9. 그밖에 선량한 풍속, 기타 사회질서를 해하거나 관계법령에 위반하는 행위<br /><br />
                                        ④ ”기업회원“은 ”사이트“ 등을 통해 열람한 이력서 정보를 ”컨소시엄“ 및 당사자의 허락 없이 재배포할 수 없으며, 본 정보에 대한 출력 및 복사 등의 관리 책임은 전적으로 ”기업회원“에게 있다.<br /><br />

                                    <p>
                                        제 18 조 (서비스 이용계약 해지/서비스중지/자료삭제)</p>
                                        ① "기업회원"이 서비스 이용계약을 해지하고자 할 경우, 고객센터 또는 ‘기업회원 탈퇴’ 메뉴를 이용해 “컨소시엄”에 대한 해지 신청을 한다. “컨소시엄”은 즉시 가입해지(회원탈퇴)에 필요한 조치를 취한다.<br /><br />
                                        ② “기업회원”이 서비스 이용계약을 해지한 경우, “컨소시엄”은 해지 즉시 “기업회원”의 모든 정보를 파기한다. 다만, 관련 법령 및 개인정보 처리방침에 따라 “컨소시엄”이 “기업회원” 정보를 보유할 수 있는 경우는 보유 목적에 필요한 최소한의 정보를 보관할 수 있다. <br /><br />
                                        ③ 다음의 사항에 해당하는 경우 "컨소시엄"은 “기업회원”의 사전 동의없이 가입해지나 서비스 중지, 게시물 삭제 조치를 취할 수 있다.<br /><br />
                                        1. 회원의 의무를 성실하게 이행하지 않았을 때 <br /><br />
                                        2. 본 서비스 목적에 맞지 않는 분야에 정보를 활용하여 사회적 물의가 발생한 때<br /><br />
                                        3. “기업회원”이 등록한 정보의 내용이 사실과 다르거나 조작되었을 때<br /><br />
                                        4. 사업자등록증에 나타난 정보와 “기업회원” 가입시의 정보가 일치하지 않을 때<br /><br />
                                        5. 타 기업의 사업자등록번호나 상호를 도용하여 허위로 기업정보를 등록하거나 휴·폐업 사업자등록번호로 기업정보를 등록했을 때<br /><br />
                                        6. 지사, 지점, 영업소 등의 경우 구체적인 지사, 지점, 영업소 명칭을 사용하지 않고 기업명을 등록했거나 지사, 지점, 영업소의 사업자등록번호가 별도 있음에도 불구하고 본사의 사업자등록번호로 기업회원 가입을 했을 때<br /><br />
                                        7. 회사명, 담당자 성명, 사무실 연락처 등 구인업체의 중요 정보를 정확하게 기입하지 않았을 때<br /><br />
                                        8. “기업회원” 가입 또는 채용공고 등록시 본 서비스에서 안내하는 방법으로 가입하지 않았거나 등록하지 않았을 때<br /><br />
                                        9. 동일 회원ID로 마감일이 지나지 않은 사실상의 동일 내용의 채용공고를 중복 등록했을 때<br /><br />
                                        10. 허위 구인공고를 등록했을 때<br /><br />
                                        11. 작성자가 제시한 직종, 업무내용, 근로조건 등이 실제와 현저히 다를 때<br /><br />
                                        12. 채용공고의 업무에 대한 내용이 명확하지 않을 때<br /><br />
                                        13. 채용공고를 등록한 “기업회원”의 정보와 실제 채용공고 상 모집하는 기업의 정보가 다른 경우<br /><br />
                                        14. 다단계 식의 모집 내용을 등록했을 때<br /><br />
                                        15. 구인을 가장하여 유학알선, 물품판매, 자금 모금 등을 행하는 내용을 등록했을 때<br /><br />
                                        16. 회원모집 형태의 광고 또는 카드회원 모집(수당제 광고) 내용을 등록했을 때<br /><br />
                                        17. 추천인 모집 광고, 재택 쇼핑몰 분양 광고, 직업소개, 부업알선 광고를 등록했을 때<br /><br />
                                        18. 취업수수료 등 취업 관련 비용을 필요로 하는 채용공고를 등록했을 때 (운송, 물류, 지입, 인력용역 등)<br /><br />
                                        19. 불건전한 내용의 유흥업소 채용공고를 등록했을 때<br /><br />
                                        20. 구직자의 피해사례 (채용공고를 통해 구직자가 입사 과정에서 피해가 발생한 경우 또는 재직중 "기업회원"이 관계법령을 위반하여 피해가 발생한 경우 등)가 접수된 채용공고<br /><br />
                                        21. 임금체불로 근로감독원에 진정서가 접수되거나, 관계 당국에 고소·고발되어 있는 기업 (단, 기업의 임금체불이 해소가 입증되면 확인 후에 서비스 이용 가능)<br /><br />
                                        22. 본 서비스와 관련하여 “컨소시엄” 또는 제3자의 명예를 훼손하였을 때<br /><br />
                                        23. 동업자 모집, 프랜차이즈 모집, 점포개설, 창업, 사이트 홍보 등 직원 채용이 아닌 공고를 등록했을 때<br /><br />
                                        24. 기타 관계법규에 위배되는 행위를 한 경우<br /><br />

                                    <p>제 19 조 (허위 구인광고 경고)</p>
                                        허위 구인광고로 인해 발생된 문제에 따른 법적인 책임은 모두 작성자에게 있으며, 허위 구인광고 또는 허위 구인 조건을 제시한 자는 직업 안정법 제 47조에 의해 5년 이하의 징역 또는 2천만원 이하의 벌금형을 받게 된다.<br /><br />

                                    <p>제 20 조 (손해배상 및 면책)</p>
                                        ① “컨소시엄”이 본 약관의 제 8조, 제 16조 등의 규정에 위반한 행위로 “기업회원”에게 손해를 입히거나 기타 “컨소시엄”이 제공하는 모든 서비스와 관련하여 “컨소시엄”의 책임 있는 사유로 인해 “기업회원”에게 손해가 발생한 경우, “컨소시엄”은 그 손해를 배상하여야 한다.<br /><br />
                                        ② “기업회원”이 본 약관의 제 7조, 제 9조, 제 17조 등의 규정에 위반한 행위로 “컨소시엄” 및 제3자에게 손해를 입히거나 “기업회원”의 책임 있는 사유에 의해 “컨소시엄” 및 제3자에게 손해를 입힌 경우에는 “기업회원”은 그 손해를 배상하여야 한다.<br /><br />
                                        ③ “컨소시엄”은 “기업회원”이 서비스를 이용하여 기대하는 효과를 얻지 못한 것에 대하여 책임을 지지 않는다. 또한, “컨소시엄”은 “컨소시엄”이 제공하는 무료 서비스의 이용과 관련하여 개인정보 처리방침에서 정하는 내용을 위반하지 않은 한 “기업회원”에게 어떠한 책임도 부담하지 않는다.<br /><br />
                                        ④ “컨소시엄”은 “기업회원”에게 구직자 정보의 정확성 및 진실성을 보증하지 않으며, “기업회원”은 구직자로부터 수령한 정보의 정확성 및 진실성을 직접 확인하여야 합니다. “컨소시엄”은 “기업회원”에게 구직자 정보의 정확성 및 진실성에 관련한 일체의 책임을 부담하지 않는다. <br /><br />
                                        ⑤ “컨소시엄”은 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우 서비스 제공에 관한 책임이 면제된다.<br /><br />
                                        ⑥ “컨소시엄”은 서비스 이용과 관련하여 “기업회원”에게 발생한 손해 가운데 “기업회원”의 고의 또는 과실로 인한 서비스 이용의 장애 및 손해에 대하여 어떠한 책임도 부담하지 않는다.<br /><br />

                                    <p>
                                        제 21 조 (양도 금지)</p>
                                        "기업회원"의 서비스 받을 권리는 제3자에게 양도, 대여, 증여 등으로 사용할 수 없다.<br /><br />

                                    <p>제 22 조 (회원의 개인정보보호)</p>
                                        "컨소시엄"은 “이용자“의 개인정보를 보호하기 위하여 노력한다. “컨소시엄”은 정보통신망 이용 촉진 및 정보 보호 등에 관한 법률, 개인정보 보호법을 준수하고, 개인정보 보호를 위하여 “사이트” 등에 개인정보 처리방침을 고지한다.<br /><br />

                                    <p>제 23 조 (분쟁의 해결)</p>
                                        ① “컨소시엄”과 “개인회원”은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 한다.<br /><br />
                                        ② 전항의 노력에도 불구하고, “컨소시엄”과 “기업회원” 간에 발생한 분쟁에 관한 소송이 제기될 경우, 민사소송법에 따른 관할법원을 제1심 관할법원으로 지정한다.<br /><br />

                                    <p>부칙</p>
                                        - 이 약관은 2023년 ○월 ○일부터 시행한다.<br /><br />
                                        - 이전 회원 이용약관 보기 (202○년 ○월 ○일 시행)<br /><br />
                                </div>

                                <div className="wrap_chk_agree">
                                    <p>위 약관을 확인하였으며 이에 동의합니까?</p>
                                    <div className="box_chk_agree">
                                        <div className="box_radio">
                                            <input type="radio" className="btn_type_radio" id="agree01" name="agree01" onClick={agreeMessage} onChange={() => setAgree(true)}/>
                                            <label htmlFor="agree01" className="label_txt">동의합니다.</label>
                                        </div>
                                        <div className="box_radio">
                                            <input type="radio" className="btn_type_radio" id="agree02" name="agree01" onClick={message} onChange={() => setAgree(false)}/>
                                            <label htmlFor="agree02" className="label_txt">동의하지 않습니다.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wrap_btns">
                                <a href="/user/signup/selectsignup" target="_self" className="btn_submit filled_black">이전으로</a>
                                <button className="btn_submit filled_green" disabled={agree === true ? false : true} onClick={() => window.location.href="/comp/signup/compSignup"}>다음으로</button>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default TermsConditions;