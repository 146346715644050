import ReactDaumPost from "react-daumpost-hook";

export const usePopupPost = (callback) => {
    const postConfig = {
        // ref : ref,
        onComplete : (data) => {
            let Addr = data.address;
            let extraAddr = '';

            // 도로명 주소를 입력했을 경우 : R or 지번주소
            if (data.userSelectedType === 'R') {
                Addr = data.roadAddress;
            } else {
                Addr = data.jibunAddress;
            }

            // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합
            if (data.userSelectedType === 'R') {
                // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                    extraAddr += data.bname;
                }

                // 건물명이 있고, 공동주택일 경우 추가
                if (data.buildingName !== '' && data.apartment === 'Y') {
                    extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                }

                // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 생성
                if (extraAddr !== ''){
                    extraAddr = ' (' + extraAddr + ')';
                }


            } else {
                extraAddr = '';
            }

            if (callback) {
                callback(Addr, extraAddr)
            }

            // 검색후 해당 컴포넌트를 다시 안보이게 하는 부분
            // ref.current.style.display= "none";
        }
    };

    const postCode = ReactDaumPost(postConfig);

    return {
        postCode
    }
}