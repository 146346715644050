import React, { useEffect, useMemo, useState } from "react";
import { getImageUrl } from "../../../utils/common";

const ApplicantAbilityItemScore = ({ resultData }) => {
  const {
    abilityItemScore,
    userNm,
    situationProblemList,
    situationProblemScore,
    thinkingProblemList,
    thinkingProblemScore,
    emotionProblemList,
    emotionProblemScore,
    blockProblemList,
    blockProblemScore,
  } = resultData || {};

  const [skillCategory, setSkillCategory] = useState([]);
  const [currCate, setCurrCate] = useState("default");
  const [physicalRes, setPhysicalRes] = useState([]);
  const [skillRes, setSkillRes] = useState([]);

  const [skillResTotalCnt, setSkillResTotalCnt] = useState(0);
  const [skillResCnt, setSkillResCnt] = useState(0);

  useEffect(() => {
    let category = [];
    let totalCnt = 0;
    let cnt = 0;
    abilityItemScore &&
      abilityItemScore.map((item) => {
        item.l_cate === "S" && (category = [...category, item.m_cate]);
        item.l_cate === "S" && totalCnt++;
        item.l_cate === "S" && item.score && item.score > 0 && cnt++;
      });
    setSkillCategory(category.filter((v, i) => category.indexOf(v) === i));
    abilityItemScore &&
      setPhysicalRes(abilityItemScore.filter((v, i) => v.l_cate === "P"));

    setSkillResTotalCnt(totalCnt);
    setSkillResCnt(cnt);
  }, []);

  useEffect(() => {
    abilityItemScore &&
      setSkillRes(abilityItemScore.filter((v, i) => v.m_cate === currCate));
  }, [currCate]);

  const eCategoryScores = abilityItemScore?.filter((v, i) => v.l_cate === "E");
  const oCategoryScores = abilityItemScore?.filter((v, i) => v.l_cate === "O");

  const getPhysicAnswer = (element) => {
    switch (element.score) {
      case 1:
        return element.m_cate === "시각 기능"
          ? "두 눈의 시력이 각각 0.02 이하"
          : element.m_cate === "청각 기능"
            ? "심도난청 90~115dB"
            : "전혀 할 수 없음";
      case 2:
        return element.m_cate === "시각 기능"
          ? "두 눈의 시력이 각각 0.06 이하 또는 한 눈의 시력이 0.02 이하, 다른 눈의 시력이 0.1 이하"
          : element.m_cate === "청각 기능"
            ? "고도난청 70~80dB"
            : "많은 도움 필요";
      case 3:
        return element.m_cate === "시각 기능"
          ? "두 눈의 시력이 각각 0.1 이하 또는 한 눈의 시력이 0.02 이하, 다른 눈의 시력이 0.15 이하"
          : element.m_cate === "청각 기능"
            ? "중고난청 50~60dB"
            : "중간정도 도움 필요";
      case 4:
        return element.m_cate === "시각 기능"
          ? "두 눈의 시력이 각각 0.3 이하 또는 한 눈의 시력이 0.02 이하"
          : element.m_cate === "청각 기능"
            ? "경도난청 30~40dB"
            : "경미한 도움 필요";
      case 5:
        return element.m_cate === "시각 기능"
          ? "눈으로 보는 것에 지장이 없음"
          : element.m_cate === "청각 기능"
            ? "모든 소리를 들을 수 있음"
            : "완전히 독립적 수행";
      default:
        return "응답하지 않음";
    }
  };

  const cateSelect = (e) => {
    setCurrCate(e.target.value);
  };

  return (
    <div className="profile_tab_con profile_tab_con_3" id="btn3">
      <h2 style={{ marginTop: 30 }}>역량평가 답변보기</h2>
      <div className="pop_title line_under">
        <h2>
          <span dangerouslySetInnerHTML={{ __html: userNm }}></span>님의
          정신장애정도 답변
        </h2>
      </div>
      <Collapsible label="주의력기능" totalScore={situationProblemScore}>
        {situationProblemList?.map((situationProblem, index) => (
          <SituationProblem key={index} situationProblem={situationProblem} />
        ))}
      </Collapsible>
      <Collapsible label="기억기능" totalScore={blockProblemScore}>
        {blockProblemList?.map((blockProblem, index) => (
          <BlockProblem key={index} blockProblem={blockProblem} />
        ))}
      </Collapsible>
      <Collapsible label="감정기능" totalScore={emotionProblemScore}>
        {emotionProblemList?.map((emotionProblem, index) => (
          <EmotionProblem key={index} emotionProblem={emotionProblem} />
        ))}
      </Collapsible>
      <Collapsible label="사고력기능" totalScore={thinkingProblemScore}>
        {thinkingProblemList?.map((thinkingProblem, index) => (
          <ThinkingProblem key={index} thinkingProblem={thinkingProblem} />
        ))}
      </Collapsible>

      <div className="flex align-items-center" style={{ gap: 40 }}>
        <h1 className="flex justify-content-center" style={{ width: 250 }}>
          에너지와
          <br />
          욕동기능
        </h1>
        <div>
          {eCategoryScores?.map((item, i) => (
            <QuestionWithRadioOptions
              key={i}
              item={item}
              i={i}
              category="eCategory"
            />
          ))}
        </div>
      </div>

      <div className="divider" />

      <div className="flex align-items-center" style={{ gap: 40 }}>
        <h1 className="flex justify-content-center" style={{ width: 250 }}>
          전반적
          <br />
          발달평가
          <br />
          GAS
        </h1>
        <div>
          {oCategoryScores?.map((item, i) => (
            <QuestionWithRadioOptions
              key={i}
              item={item}
              i={i}
              category="oCategory"
            />
          ))}
        </div>
      </div>

      <div className="pop_title line_under" style={{ marginTop: 40 }}>
        <h2>
          <span dangerouslySetInnerHTML={{ __html: userNm }}></span>님의
          신체장애정도 답변
        </h2>
      </div>
      <div className="popup_contents">
        <div className="table_wrap padding-10">
          <table>
            {physicalRes &&
              physicalRes.map(
                (item, i) =>
                  item.l_cate === "P" && (
                    <tr key={i}>
                      <th>{"Q" + (i + 1) + ". " + item.question}</th>
                      <td>
                        {item.member_seq == null || item.score == null
                          ? ""
                          : item["score_" + item.score]}
                      </td>
                    </tr>
                  )
              )}
          </table>
        </div>
      </div>
      <div className="pop_title line_under selectbox_init">
        <h3>
          <span dangerouslySetInnerHTML={{ __html: userNm }}></span>님의
          직업기초 능력평가 답변
        </h3>

        <select id="selectTab" defaultValue={currCate} onChange={cateSelect}>
          <option value="default">
            직업기초 능력평가 항목을 선택해 주세요.
          </option>
          {skillCategory &&
            skillCategory.map((item, i) => (
              <option
                key={i}
                value={item}
                dangerouslySetInnerHTML={{ __html: item }}
              />
            ))}
        </select>
      </div>
      <div className="popup_contents select_tab_con tabcon1">
        {currCate !== "default" ? (
          <>
            <article className="wrap_progress">
              <h4
                dangerouslySetInnerHTML={{
                  __html:
                    "진행률 " +
                    (skillResTotalCnt > 0 &&
                      skillResCnt > 0 &&
                      "(<span>" +
                        skillResCnt +
                        "</span> / " +
                        skillResCnt +
                        ")"),
                }}
              ></h4>
              <div className="box_progress">
                <progress
                  value={
                    skillResTotalCnt > 0
                      ? (skillResCnt / skillResTotalCnt) * 100
                      : 0
                  }
                  max="100"
                ></progress>
                <p className="progress_txt">
                  <span>
                    {skillResTotalCnt > 0
                      ? parseInt((skillResCnt / skillResTotalCnt) * 100)
                      : 0}
                  </span>
                  %
                </p>
              </div>
            </article>
            <div className="wrap_qna_box">
              {skillRes &&
                skillRes.map((item, i) => (
                  <div key={i} className="box_radio_qna">
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: "Q" + (i + 1) + ". " + item.question,
                      }}
                    ></h4>
                    <div className="radio_wrap">
                      <div className="box_input_radio">
                        <label htmlFor={"temp" + item.test_seq}>
                          <input
                            type="radio"
                            className="input_radio"
                            id={"temp" + item.test_seq}
                            checked={item.score === 1 ? true : false}
                            disabled
                          />
                          {item.score_1}
                        </label>
                      </div>
                      <div className="box_input_radio">
                        <label htmlFor={"temp" + item.test_seq}>
                          <input
                            type="radio"
                            className="input_radio"
                            id={"temp" + item.test_seq}
                            checked={item.score === 2 ? true : false}
                            disabled
                          />
                          {item.score_2}
                        </label>
                      </div>
                      <div className="box_input_radio">
                        <label htmlFor={"temp" + item.test_seq}>
                          <input
                            type="radio"
                            className="input_radio"
                            id={"temp" + item.test_seq}
                            checked={item.score === 3 ? true : false}
                            disabled
                          />
                          {item.score_3}
                        </label>
                      </div>
                      <div className="box_input_radio">
                        <label htmlFor={"temp" + item.test_seq}>
                          <input
                            type="radio"
                            className="input_radio"
                            id={"temp" + item.test_seq}
                            checked={item.score === 4 ? true : false}
                            disabled
                          />
                          {item.score_4}
                        </label>
                      </div>
                      <div className="box_input_radio">
                        <label htmlFor={"temp" + item.test_seq}>
                          <input
                            type="radio"
                            className="input_radio"
                            id={"temp" + item.test_seq}
                            checked={item.score === 5 ? true : false}
                            disabled
                          />
                          {item.score_5}
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ApplicantAbilityItemScore;

const Collapsible = ({ children, label, totalScore }) => {
  return (
    <div className="collapsible">
      <input type="checkbox" id={label} className="collapsible-toggle-input" />
      <label htmlFor={label} className="collapsible-toggle-label">
        <span className="collapsible-label-text">{label}</span>
        <span className="collapsible-right">
          <span className="collapsible-score">
            ( 확보점수 : {totalScore} 점 )
          </span>
          <span className="collapsible-arrow"></span>
        </span>
      </label>

      <div className="collapsible-content">
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            gap: 40,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

const SituationProblem = ({ situationProblem }) => {
  const {
    arrayScore,
    problemSituationAnswer1,
    problemSituationAnswer2,
    problemSituationAnswer3,
    problemSituationAnswer4,
    problemSituationImage,
    problemSituationText1,
    problemSituationText2,
    problemSituationText3,
    problemSituationText4,
  } = situationProblem;

  const questionList = [
    problemSituationText1,
    problemSituationText2,
    problemSituationText3,
    problemSituationText4,
  ];

  const correctAnswerList = [
    problemSituationAnswer1,
    problemSituationAnswer2,
    problemSituationAnswer3,
    problemSituationAnswer4,
  ];

  const isCorrect = (userAnswer, correctAnswer) => {
    return (
      userAnswer?.trim().toLowerCase() === correctAnswer?.trim().toLowerCase()
    );
  };

  return (
    <div className="quiz-wrapper">
      <div className="quiz flex h-full">
        <div className="quiz-assets">
          <img
            className="picture-quiz-image"
            src={getImageUrl(problemSituationImage)}
            alt="quiz"
          />
        </div>
        <div className="quiz-answer flex">
          <h3>
            Q. 주어진 그림을 확인하여 다음 문제 4가지에 대한 답변을 작성하시오.
          </h3>

          <table className="picture-quiz-table">
            <thead>
              <tr>
                <th align="start">문제</th>
                <th align="start">정답</th>
              </tr>
            </thead>

            <tbody>
              {questionList.map((question, index) => (
                <tr key={index}>
                  <td align="start">{question}</td>
                  <td
                    style={{
                      backgroundColor: !isCorrect(
                        arrayScore.split(",")[index],
                        correctAnswerList[index]
                      )
                        ? "#ff1e1ee6"
                        : "",
                    }}
                  >
                    <span>{arrayScore.split(",")[index] || ""}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const BlockProblem = ({ blockProblem }) => {
  const { arrayScore, problemBlocks } = blockProblem;

  return (
    <div className="quiz flex h-full">
      <div className="quiz-wrapper">
        <div className="quiz flex h-full">
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <h3>Q. 블록의 위치를 마우스로 클릭하세요. (순서무관)</h3>
            <div className="quiz-assets flex">
              <Grid blocks={problemBlocks} isPreview />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <h3>사용자 입력답변</h3>
            <div className="quiz-assets flex">
              <Grid
                blocks={arrayScore}
                correctBlocks={problemBlocks}
                isPreview={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Grid = ({ blocks, correctBlocks, isPreview }) => {
  const size = 4;
  const castingTypeBlocks = blocks.map((v) => Number(v));
  return (
    <div
      className={`block-grid ${isPreview ? "preview" : "answer"}`}
      style={{ pointerEvents: "none" }}
    >
      {Array.from({ length: Math.pow(size, 2) }).map((_, index) => (
        <div
          key={index}
          style={{
            backgroundColor:
              correctBlocks?.includes(index) &&
              !castingTypeBlocks.includes(index)
                ? "#ff1e1ee6"
                : "",
          }}
          className={`block-card ${castingTypeBlocks.includes(index) ? "selected" : ""}`}
        />
      ))}
    </div>
  );
};

const EmotionProblem = ({ emotionProblem }) => {
  const { arrayScore, problemAnswer, problemEmotionImage } = emotionProblem;

  const list = ["기쁨", "놀람", "슬픔", "분노"];

  const getButtonClass = (emotion) => {
    if (emotion === arrayScore) {
      return arrayScore === problemAnswer ? "filled_green" : "filled_red";
    }
    if (arrayScore !== "-") {
      return emotion === problemAnswer ? "filled_green" : "";
    }
    return "";
  };

  return (
    <div className="quiz flex">
      <div className="quiz-wrapper">
        <div className="quiz flex h-full">
          <div className="quiz-assets">
            <img src={getImageUrl(problemEmotionImage)} alt="quiz" />
          </div>

          <div className="quiz-answer">
            <h3>
              Q. 주어진 그림을 확인하여 아래의 올바른 감정카드 버튼을
              클릭하세요.
            </h3>
            <div
              className={`emotion-buttons ${arrayScore === "-" ? "no-selection" : ""}`}
            >
              {list.map((emotion) => (
                <button
                  className={`btn_submit ${getButtonClass(emotion)}`}
                  key={emotion}
                >
                  {emotion}
                </button>
              ))}
            </div>
            {arrayScore === "-" && (
              <p className="no-selection-message">
                지원자가 답안을 입력하지 않았습니다.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ThinkingProblem = ({ thinkingProblem }) => {
  const { arrayIds, arrayScore, problems } = thinkingProblem;

  const groupedScores = [
    arrayScore.slice(0, 3),
    arrayScore.slice(3, 6),
    arrayScore.slice(6),
  ];

  const { sortedImages, categories, correctAnswers } = useMemo(() => {
    const idImageMap = problems.reduce((acc, problem) => {
      problem.problemThinkingIds.forEach((id, index) => {
        acc[id] = {
          id,
          imgUrl: getImageUrl(
            problem.problemThinkingImages[index].replace("/images", "/game")
          ),
          category: problem.secondCategory,
        };
      });
      return acc;
    }, {});

    // arrayIds의 순서대로 이미지 정렬
    const sortedImages = arrayIds.map((id) => idImageMap[id]);

    // 카테고리 정보 생성 및 정답 추출
    const categories = problems.map((problem) => ({
      name: problem.secondCategory,
      problemBlockId: problem.problemThinkingIds,
    }));

    // 각 문제의 정답 정보 (정답 ID 배열)
    const correctAnswers = [
      problems[0].problemThinkingIds,
      problems[1].problemThinkingIds,
      problems[2].problemThinkingIds,
    ];

    return { sortedImages, categories, correctAnswers };
  }, []);

  const isWrongAnswer = (id, index) => {
    return !correctAnswers[index].includes(Number(id));
  };

  return (
    <div className="quiz">
      <div className="quiz-wrapper">
        <div className="quiz">
          <div className="quiz-assets card-quiz-grid">
            {sortedImages.map(({ id, imgUrl: image }) => (
              <button key={image} className={`card-quiz-grid-item`}>
                {!arrayScore.includes(String(id)) && (
                  <img src={image} alt={image} />
                )}
              </button>
            ))}
          </div>

          <div className="quiz-answer">
            <h3>
              Q. 주어진 3x3 그림카드들을 주제에 맞게 분류하여, 선택한 그림카드를
              각각 아래 빈칸영역에 마우스 클릭으로 배치하세요.
            </h3>

            <div className="card-quiz-box">
              {categories.map((category, i) => (
                <div className="card-quiz-row" key={`${category.name}-${i}`}>
                  <p>{category.name}</p>
                  <div>
                    {Array(3)
                      .fill()
                      .map((_, j) => (
                        <button
                          className={`card-quiz-button ${isWrongAnswer(groupedScores[i][j], i) ? "wrong" : "correct"}`}
                          key={`${category.name}-${j}`}
                        >
                          {sortedImages.find(
                            (v) => v.id === Number(groupedScores[i][j])
                          )?.imgUrl && (
                            <img
                              src={
                                sortedImages.find(
                                  (v) => v.id === Number(groupedScores[i][j])
                                ).imgUrl
                              }
                              alt={`selected-${i}-${j}`}
                            />
                          )}
                        </button>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const QuestionWithRadioOptions = ({ item, i, category }) => {
  return (
    <div key={item.value} className={`box_radio_qna`}>
      <h4>
        <span
          dangerouslySetInnerHTML={{
            __html: "Q" + (i + 1) + ".",
          }}
        ></span>
        <span dangerouslySetInnerHTML={{ __html: item.question }}></span>
      </h4>
      <div className="box_input_radio" style={{ width: "100%" }}>
        <label htmlFor={category + item.question + i}>
          <input
            type="radio"
            className="input_radio"
            id={category + item.question + i}
            name={`${category}-group0${i}`}
            value="1"
            checked={item.score === 1}
          />
          {item.score_1}
        </label>
      </div>
      <div className="box_input_radio" style={{ width: "100%" }}>
        <label htmlFor={category + item.question + i}>
          <input
            type="radio"
            className="input_radio"
            id={category + item.question + i}
            name={`${category}-group0${i}`}
            value="2"
            checked={item.score === 2}
          />
          {item.score_2}
        </label>
      </div>
      <div className="box_input_radio" style={{ width: "100%" }}>
        <label htmlFor={category + item.question + i}>
          <input
            type="radio"
            className="input_radio"
            id={category + item.question + i}
            name={`${category}-group0${i}`}
            value="3"
            checked={item.score === 3}
          />
          {item.score_3}
        </label>
      </div>
      <div className="box_input_radio" style={{ width: "100%" }}>
        <label htmlFor={category + item.question + i}>
          <input
            type="radio"
            className="input_radio"
            id={category + item.question + i}
            name={`${category}-group0${i}`}
            value="4"
            checked={item.score === 4}
          />
          {item.score_4}
        </label>
      </div>
      <div className="box_input_radio" style={{ width: "100%" }}>
        <label htmlFor={category + item.question + i}>
          <input
            type="radio"
            className="input_radio"
            id={category + item.question + i}
            name={`${category}-group0${i}`}
            value="5"
            checked={item.score === 5}
          />
          {item.score_5}
        </label>
      </div>
    </div>
  );
};
