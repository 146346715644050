import React, {useState} from "react";
import InsertInterviewModal from './InsertInterviewModal';

const MainTabA = (props) => {
    const [isActive, setIsActive] = useState({});
    const [interviewModal, setInterviewModal] = useState(false);
    const [interviewData, setInterviewData] = useState(false);

    const toggleSortBtn = (e) => {
        let tmp = {...isActive};
        (tmp[e.target.id] === undefined || tmp[e.target.id] === "ASC") ? (tmp[e.target.id] = "DESC") : (tmp[e.target.id] = "ASC");
        props.sorting((e.target.id === "comp_evaluation2" ? "comp_evaluation" : e.target.id) , tmp[e.target.id]);
        setIsActive(tmp);
    }

    const calcAge = (birthday) => {
        let date = new Date();
        let year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        let monthDay = month + day;
        
        let birth = birthday.replace('-', '').replace('-', '');
        let birthY = birth.substr(0,4);
        let birthMd = birth.substr(4,4);

        let age = monthDay < birthMd ? year - birthY -1 : year - birthY;
        return age;
    }

    const calcCareer = (career) => {
        let ret_month = 0; 
        let date = new Date();
        let year = date.getFullYear();
        var month = date.getMonth() + 1;
        
        career && career.map((item, i) => {
            let hired_date = item.hired_date;
            hired_date  = hired_date.replace('-', '').replace('-', '').replace('/', '').replace('/', '').replace('.', '').replace('.', '');
            let resignation_date = item.resignation_date;
            if(resignation_date != null) resignation_date = resignation_date.replace('-', '').replace('-', '').replace('/', '').replace('/', '').replace('.', '').replace('.', '');
            
            ret_month += item.employment_status == "재직중" ? (year - Number(hired_date.substr(0,4)))*12 + (month - Number(hired_date.substr(4,2)))  : item.employment_status == "퇴사" ? (Number(resignation_date.substr(0,4)) - Number(hired_date.substr(0,4))) * 12 + (Number(resignation_date.substr(4,2)) - Number(hired_date.substr(4,2))) : 0;
            
        });
        ret_month > 0 && ret_month++;

        return ret_month > 0 ? (Math.floor(ret_month/12))+ "년 " + (ret_month%12) + "개월" : "";
    }

    const handleChange = (e, annNum, recNum, resNum) => {
        if(e.target.value !== "3"){
             let param = {
                 "comp_evaluation" : e.target.value,
                 "recruit_seq" : recNum,
                 "announcement_seq" :annNum
             }
             props.setUpdateParam(param);
             props.setConfirmModalType("confirm");
             props.setConfirmModal(true);
        }
        else if(e.target.value == 3){
            
            props.setResumeSeq(resNum)
            props.setRecruitSeq(recNum)
            props.setInterviewModal(true)
        }
     }


    const updateInterviewDate = (e,item) =>{
        e.preventDefault()
        if(item.interview_dt != null){
            const currentDate = new Date();
            const interviewDate = new Date(item.interview_dt.substring(0,10));
            if (interviewDate > currentDate ) {
                setInterviewData(item);
                setInterviewModal(true);
            }
        }
        else{
            setInterviewData(item);
            setInterviewModal(true);
        }
        
    }
    return (
        <div className="table_init content_01">
            <div>
                <p className="case_count" dangerouslySetInnerHTML={{__html : props?.result?.totalCnt  ? ("총<span>") + props.result.totalCnt + "</span>건" : ""}}></p>                          
            </div>
            <div className="wrap_applicant_list">
                <table className="table_detail sort_column">
                    <colgroup>
                        <col style={{"width" : "95px"}} />                                   
                        <col style={{"width" : "auto"}} /> 
                        <col style={{"width" : "130px"}} />
                        <col style={{"width" : "185px"}} />
                        <col style={{"width" : "144px"}} />
                        <col style={{"width" : "130px"}} />                            
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope="col" className={"header_sort01 " + (isActive.rownum == "DESC" ? "sort_up" : "sort_down")} id="rownum" onClick={toggleSortBtn}>번호</th>
                            <th scope="col" className="no_sort">지원자</th>
                            <th scope="col" className={"header_sort01 " + (isActive.apply_dt == "ASC" ? "sort_up" : "sort_down")} id="apply_dt" onClick={toggleSortBtn}>지원일</th>
                            <th scope="col" className={"header_sort01 " + (isActive.interview_dt == "ASC" ? "sort_up" : "sort_down")} id="interview_dt" onClick={toggleSortBtn}>면접일시</th>
                            <th scope="col" className={"header_sort01 " + (isActive.comp_evaluation == "ASC" ? "sort_up" : "sort_down")} id="comp_evaluation" onClick={toggleSortBtn}>평가하기</th>
                            <th scope="col" className={"header_sort01 " + (isActive.comp_evaluation2 == "ASC" ? "sort_up" : "sort_down")} id="comp_evaluation2" onClick={toggleSortBtn}>평가상태</th>                               
                        </tr>
                    </thead>
                    <tbody>
                    {props.result?.applicantInfos?.map((item, i)=> (
                        <tr key={i}>
                            <td dangerouslySetInnerHTML={{__html : item.rownum}}></td>
                            <td className="td_pic_init">                                            
                                <a href="#" onClick={(e) => {props.modal(item.announcement_seq, item.recruit_seq)} } className="resume_popup" >
                                    <div className="prof_pic_wrap">
                                        <span className="profile_pic">
                                            <img src={item.image_file_path} onError={props.setDefaultImg} alt="프로필 이미지" />
                                        </span>
                                    </div>
                                    <div className="prof_text_wrap">
                                        <div>
                                            <span className="name" dangerouslySetInnerHTML={{__html : item.member_nm}}></span>|
                                            <span className="gender" dangerouslySetInnerHTML={{__html : item.sex_gb}}></span>
                                            <span className="age" dangerouslySetInnerHTML={{__html : calcAge(item.birthday)}}></span>세| 
                                            <span className="p_history" dangerouslySetInnerHTML={{__html : calcCareer(item.career)}}></span>
                                        </div>
                                        <div>
                                            <span className="body_status" dangerouslySetInnerHTML={{__html : item.major_disability_nm}}></span>
                                        </div>                                           
                                    </div>                                                                                           
                                </a>                                          
                            </td>
                            <td dangerouslySetInnerHTML={{__html : item.apply_dt}}></td>
                            <td>
                            {item.comp_evaluation == 3 || item.comp_evaluation == 4  || item.comp_evaluation == 6 ? (
                                    <button 
                                    type="button" className="btn_table_text" 
                                    dangerouslySetInnerHTML={
                                        {
                                            __html : item.interview_dt != null ? 
                                                item.interview_dt.substring(0, 10)+"<br/>"+item.interview_dt.substring(10, 16)
                                                : item.select_confirm != null ?
                                                (() => {
                                                    switch (item.select_confirm) {
                                                      case '1':
                                                        return item.interview_dt1.substring(0, 10)+"<br/>"+item.interview_dt1.substring(10, 16);
                                                      case '2':
                                                        return item.interview_dt2.substring(0, 10)+"<br/>"+item.interview_dt2.substring(10, 16);
                                                      case '3':
                                                        return item.interview_dt3.substring(0, 10)+"<br/>"+item.interview_dt3.substring(10, 16);
                                                      default:
                                                        return "가능한 일정이<br/>없습니다";
                                                    }
                                                  })(): item.comp_evaluation == 3 ? "구직자<br/>응답대기" : "-"
                                        }                                        
                                    }
                                    onClick={(e) => {updateInterviewDate(e,item)}}
                                    >
                                </button>                             
                                ) : (
                                    "-"
                                )
                            }    
                            </td>   
                            <td className="table_select_init">
                                <select name="category" onChange={(e) => {e.preventDefault(); handleChange(e, item.announcement_seq, item.recruit_seq, item.resume_seq )}} title="평가하기" value={item.comp_evaluation} className={"table_select popSelectStatus" + (item.comp_evaluation === "7" ? " readonly" : "")}>
                                    <option value={1}>서류검토</option>
                                    <option value={2}>서류탈락</option>
                                    <option value={3}>면접진행</option>
                                    <option value={4}>면접탈락</option>
                                    <option value={5}>비면접합격</option>
                                    <option value={6} >최종합격</option>
                                    {item.comp_evaluation === "7" && <option value={7} >지원취소</option>}
                                </select>
                            </td>  
                            <td dangerouslySetInnerHTML={{__html : item.comp_evaluation_name && item.comp_evaluation != "5" ? item.comp_evaluation_name : "최종합격"}}></td>                
                        </tr>
                    ))

                    }
                    {props.result?.applicantInfos?.length == 0 && (
                        <tr>
                            <td colspan="6">지원자가 없습니다.</td>
                        </tr>
                    )}
                    
                              
                    </tbody>
                </table>
            </div>
            <div className="wrap_pagination">
                {Object.keys(props.paginationInfo).length > 0 && (
                <ul className="pagination_list">
                    {props.paginationInfo?.minLimit == 1 ? ("") : (<li className="pagination_item"><a href="#" className="pagination_anchor prev" onClick={(e) => {e.preventDefault(); props.setCurrPage(props.paginationInfo?.minLimit - 1);}} ></a></li>)}
                    {props.paginationInfo?.minLimit > props.paginationInfo?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((props.paginationInfo?.minLimit == props.paginationInfo?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); props.setCurrPage(props.paginationInfo?.minLimit);}} dangerouslySetInnerHTML={{__html : props.paginationInfo?.minLimit}}></a></li>)}
                    {props.paginationInfo?.minLimit +1 > props.paginationInfo?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((props.paginationInfo?.minLimit +1 == props.paginationInfo?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); props.setCurrPage(props.paginationInfo?.minLimit +1);}} dangerouslySetInnerHTML={{__html : props.paginationInfo?.minLimit+1}}></a></li>)}
                    {props.paginationInfo?.minLimit +2 > props.paginationInfo?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((props.paginationInfo?.minLimit +2 == props.paginationInfo?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); props.setCurrPage(props.paginationInfo?.minLimit +2);}} dangerouslySetInnerHTML={{__html : props.paginationInfo?.minLimit+2}}></a></li>)}
                    {props.paginationInfo?.minLimit +3 > props.paginationInfo?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((props.paginationInfo?.minLimit +3 == props.paginationInfo?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); props.setCurrPage(props.paginationInfo?.minLimit +3);}} dangerouslySetInnerHTML={{__html : props.paginationInfo?.minLimit+3}}></a></li>)}
                    {props.paginationInfo?.minLimit +4 > props.paginationInfo?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((props.paginationInfo?.minLimit +4 == props.paginationInfo?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); props.setCurrPage(props.paginationInfo?.minLimit +4);}} dangerouslySetInnerHTML={{__html : props.paginationInfo?.minLimit+4}}></a></li>)}
                    {props.paginationInfo?.maxLimit == props.paginationInfo?.totalPage ? ("") : (<li className="pagination_item"><a href="#" className="pagination_anchor next" onClick={(e) => {e.preventDefault(); props.setCurrPage(props.paginationInfo?.maxLimit + 1);}} ></a></li>)}
                </ul>
                )}
            </div>
            {
                interviewModal && <InsertInterviewModal setInterviewModal={setInterviewModal} interviewData={interviewData}/>
            }
        </div>       
    )
}

export default MainTabA;