import {ErrorMessages} from "../../../utils/Message";
import React from "react";
import {useFormContext} from "react-hook-form";

const HolidayReg = () => {
    const { register, setValue, setError, clearErrors, reset, watch, formState: { errors } } = useFormContext();

    return (
        <div className="wrap_signup_input wrap_signup_basic">
            <div className="box_sub_title">
                <h4 className="sub_title_signup">휴일규정</h4>
            </div>
            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">연차사용기준</label>
                    <input type="text" id="comp_member_info.al_guidelines" name="comp_member_info.al_guidelines" placeholder="연차사용기준을 입력해 주세요." {...register('comp_member_info.al_guidelines',{
                        required: ErrorMessages.required
                    })}/>
                </div>
                <p className="msg_err">{errors.comp_member_info?.al_guidelines?.message}</p>
            </div>
            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">대체공휴일 휴무</label>
                    <input type="text" id="comp_member_info.rh_policy" name="comp_member_info.rh_policy" placeholder="대체공휴일 휴무규정을 입력해 주세요." {...register('comp_member_info.rh_policy',{
                        required: ErrorMessages.required
                    })}/>
                </div>
                <p className="msg_err">{errors.comp_member_info?.rh_policy?.message}</p>
            </div>
        </div>
    )
}

export default HolidayReg;