import React, { useEffect, useRef } from "react";

const ChatMessage = ({ messages, goToMain }) => {
  const chatContainerRef = useRef(null);

  useEffect(() => {
    // 메시지가 변경될 때마다 스크롤을 최하단으로 내림
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div
      ref={chatContainerRef}
      style={{ flex: 1, overflowY: "scroll", padding: "10px" }}
    >
      {messages.map((message, index) => (
        <div
          key={index}
          style={{
            ...styles.wrapper,
            justifyContent:
              message.sender === "user" ? "flex-end" : "flex-start",
          }}
        >
          {message.sender === "bot" && (
            <img
              src="/img/chatbot/UI_img_botProfile.png"
              alt="avatar"
              style={styles.avatar}
              onClick={goToMain}
            />
          )}
          <div style={{ marginTop: "5px", width: "100%" }}>
            {message.sender !== "user" && (
              <span style={styles.name}>드리미</span>
            )}
            <div
              style={{
                ...styles.textContainer,
                backgroundColor: message.sender === "user" ? "#00bf81" : "#fff",
                color: message.sender === "user" ? "#ffffff" : "#333",
                borderRadius:
                  message.sender === "user"
                    ? "50px 0 50px 50px"
                    : "0px 15px 15px 15px",
                boxShadow: "1px 3px 6px 0 rgba(0, 0, 0, 0.16)",
                float: message.sender === "user" ? "right" : "left",
                maxWidth: message.sender === "user" ? "90%" : "unset", // 유저일 때만 maxWidth
                width: message.sender !== "user" ? "90%" : "unset", // 유저가 아닐 때 width
                marginBottom: "8px", // 메시지와 시간 사이의 간격
              }}
            >
              {message.sender !== "user" && message.showDreame && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "12px",
                  }}
                >
                  <img
                    alt="send"
                    src="/img/chatbot/img_greeting_00.png"
                    style={{ width: 150 }}
                  />
                </div>
              )}
              {message.text.map((msg, i) => (
                <div key={i} style={styles.message}>
                  {msg}
                </div>
              ))}
            </div>
            <p
              style={{
                fontSize: "12px",
                color: "#aaa",
                textAlign: message.sender === "user" ? "right" : "left",
                clear: "both",
              }}
            >
              {message.date.toLocaleTimeString()}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "20px",
    maxWidth: "100%",
  },
  avatar: {
    height: "40px",
    width: "40px",
    marginRight: "5px",
    cursor: "pointer",
  },
  textContainer: {
    padding: "10px 15px",
    position: "relative",
    lineHeight: "1.5",
    wordWrap: "break-word",
    marginTop: "5px",
  },
  name: {
    font: "normal 14px Noto Sans KR",
    fontWeight: "bold",
    fontSize: "14px",
    color: "#012646",
    margin: 0,
  },
  message: {
    fontSize: "14px",
    margin: 0,
  },
};

export default ChatMessage;
