import React, { Component } from 'react';

const CountType = () => {
    return (
        <div>
            <div className="wrapper">
                <main>
                    <div className="join page_join ">
                        <h2 className="page_title">회원 가입하기</h2>
                        <div className="wrap_progressbar">
                            <ul className="progressbar_list progress_step02">
                                <li className="progressbar_item progress_item01 "><p>이용약관 동의</p></li>
                                <li className="progressbar_item progress_item02"><p>계정유형 선택</p></li>
                                <li className="progressbar_item progress_item03"><p>회원정보 등록</p></li>
                            </ul>
                        </div>
                        <div className="page_inner">
                            <div className="area_shade">
                                <div className="wrap_title_step">
                                    <span className="badge badge_step">STEP 2</span>
                                    <h3 className="title_step">가입 방식을 선택해 주세요.</h3>
                                </div>
                                <div className="wrap_btn_signup">
                                    <div className="box_signup">
                                        {
                                        //<a href="/user/signup/signup" target="_self" className="btn_submit lined_green">브이드림 계정 만들기</a>
                                        }
                                        <a href="/user/signup/signup" target="_self" className="btn_submit lined_green">계정 만들기</a>
                                    </div>
                                    <h4>소셜 계정으로 간편 가입하기</h4>
                                    <div className="box_join_social">
                                        {
                                    /* 로컬
                                        <a href="/api/oauth2/authorization/join/google?redirect_uri=http://localhost:3000/auth/redirect/join" target="_self" className="btn_submit btn_join btn_join_google"><i className="btn_join_icon"></i>Google로 시작하기</a>
                                        <a href="/api/oauth2/authorization/join/kakao?redirect_uri=http://localhost:3000/auth/redirect/join" target="_self" className="btn_submit btn_join btn_join_kakao"><i className="btn_join_icon"></i>카카오로 시작하기</a>
                                        <a href="/api/oauth2/authorization/join/naver?redirect_uri=http://localhost:3000/auth/redirect/join" target="_self" className="btn_submit btn_join btn_join_naver"><i className="btn_join_icon"></i>네이버로 시작하기</a>
                                        <a href="/api/oauth2/authorization/login/facebook?redirect_uri=http://localhost:3000/auth/redirect/join" target="_self" className="btn_submit btn_join btn_join_facebook"><i className="btn_join_icon"></i>페이스북으로 시작하기</a>
                                    */
                                        }
                                        <a href="/api/oauth2/authorization/join/google?redirect_uri=https://matching.vdream.co.kr/auth/redirect/join" target="_self" className="btn_submit btn_join btn_join_google"><i className="btn_join_icon"></i>Google로 시작하기</a>
                                        <a href="/api/oauth2/authorization/join/kakao?redirect_uri=https://matching.vdream.co.kr/auth/redirect/join" target="_self" className="btn_submit btn_join btn_join_kakao"><i className="btn_join_icon"></i>카카오로 시작하기</a>
                                        <a href="/api/oauth2/authorization/join/naver?redirect_uri=https://matching.vdream.co.kr/auth/redirect/join" target="_self" className="btn_submit btn_join btn_join_naver"><i className="btn_join_icon"></i>네이버로 시작하기</a>
                                        <a href="/api/oauth2/authorization/join/facebook?redirect_uri=https://matching.vdream.co.kr/auth/redirect/join" target="_self" className="btn_submit btn_join btn_join_facebook"><i className="btn_join_icon"></i>페이스북으로 시작하기</a>
                                    </div>
                                </div>
                            </div>
                            <div className="wrap_btns flex_center">
                                <a href="/user/signup/termsconditions" target="_self" className="btn_submit filled_black ">이전으로</a>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default CountType;