import React, {useState, useRef, useEffect} from "react";
import ReactDaumPost from 'react-daumpost-hook';
import {usePopupPost} from "../../../hooks/usePopupPost";

const WorkPostCode = (props) => {
    const {watchField, setValue, reset, register, errors, address, disabled: propsDisabled} = props;
    const disabled = propsDisabled !== undefined ? propsDisabled : false;

    const ref = useRef(null);

    const [isRemoteRadioChecked, setIsRemoteRadioChecked] = useState(false);
    const [isSameAddrRadioChecked, setIsSameAddrRadioChecked] = useState(false);

    useEffect(() => {
        if (watchField.condition.remote_work === '재택근무') {
            setIsRemoteRadioChecked(true);
        } else if (watchField.condition.remote_work === '회사주소와 동일') {
            setIsSameAddrRadioChecked(true);
        }
    }, [watchField.condition.remote_work]);


    const handleRemoteWorkRadioClick = (e) => {
        if (e.target.value === '재택근무') {
            setValue('condition.detail_work_location', '');
            setValue('condition.work_location', '');
            setIsRemoteRadioChecked(!isRemoteRadioChecked);
            if (isSameAddrRadioChecked) setIsSameAddrRadioChecked(false)
        } else {
            setValue('condition.work_location', address[0]);
            setValue('condition.detail_work_location', address[1]);
            setIsSameAddrRadioChecked(!isSameAddrRadioChecked);
            if (setIsRemoteRadioChecked) setIsRemoteRadioChecked(false)
        }
    };

    function getPostCode(address, extraAddress) {
        reset({
            ...watchField,
            condition: {
                ...watchField.condition,
                work_location: address,
                detail_work_location: extraAddress
            }
        })
    }

    const {postCode} = usePopupPost(getPostCode);

    useEffect(() => {

    }, [watchField.condition.remote_work])

    return (
        <div className="wrap_signup_input wrap_box_address">
            <div className="wrap_box_input focus_in">
                <div className="box_confirm">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">근무지</label>
                        <input type="text" id="" placeholder="주소를 입력해 주세요." name={`condition.work_location`}
                               {...register(`condition.work_location`, {required: !(isSameAddrRadioChecked || isRemoteRadioChecked) ? '필수 입력 항목입니다.' : undefined})}
                               readOnly disabled></input>
                    </div>
                    <button type="button" className="btn_submit filled_green" onClick={() => {
                        postCode()
                    }} disabled={props.disabled}>우편번호 찾기
                    </button>
                </div>
                <div className="box_input">
                    <input type="text" id=""
                           placeholder="상세주소를 입력해 주세요."
                           name={`condition.detail_work_location`}
                           {...register(`condition.detail_work_location`, {required: !(isSameAddrRadioChecked || isRemoteRadioChecked) ? '필수 입력 항목입니다.' : undefined})}
                           readOnly={isSameAddrRadioChecked || isRemoteRadioChecked}
                           disabled={isSameAddrRadioChecked || isRemoteRadioChecked || disabled}

                    ></input>
                </div>
                <p className="msg_err">{errors?.condition?.work_location?.message||errors?.condition?.detail_work_location?.message}</p>
            </div>
            <div className="wrap_box_input focus_in" style={{marginBottom: '30px'}}>
                <div className="wrap_box_radio box_education">
                    <div className="input_radio">
                        <label htmlFor="temp18" className="label_txt">
                            <input type="radio" className="btn_type_radio" id="temp18" name="remote_work"
                                   value="재택근무" {...register('condition.remote_work')}
                                   checked={isRemoteRadioChecked}
                                   onClick={handleRemoteWorkRadioClick}
                                   disabled={disabled}
                            ></input>
                            재택근무</label>
                    </div>
                    <div className="input_radio">
                        <label htmlFor="temp19" className="label_txt">
                            <input type="radio" className="btn_type_radio" id="temp19" name="remote_work"
                                   value="회사주소와 동일" {...register('condition.remote_work')}
                                   checked={isSameAddrRadioChecked}
                                   onClick={handleRemoteWorkRadioClick}
                                   disabled={disabled}
                            ></input>
                            회사주소와 동일</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkPostCode;


