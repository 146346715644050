import React, { useEffect, useState } from 'react';
import {useHistory,useParams } from "react-router-dom";
import axios from 'axios';
import api from "../../../api/api";
import ChooseJobModal from "./ChooseJobModal";

const DesiredJob = () => {
    const goBackUrl = '/user/resume/beforeResume';
    let history = useHistory();
    const { resumeNo } = useParams();
    const[responseData,setResponseData] = useState(null);
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState('');
    const [FirstDesired, setFirstDesired] = useState({
        l_cate_cdnm : '',
        m_cate_cdnm : '',
        s_cate_cdnm : '',
        d_cate_cdnm : ''
    });
    const [SecondDesired, setSecondDesired] = useState({
        l_cate_cdnm : '',
        m_cate_cdnm : '',
        s_cate_cdnm : '',
        d_cate_cdnm : ''
    });
    const [ThirdDesired, setThirdDesired] = useState({
        l_cate_cdnm : '',
        m_cate_cdnm : '',
        s_cate_cdnm : '',
        d_cate_cdnm : ''
    });

    useEffect(() => {
        const url = 'api/resume_new/getProfile?seq='+resumeNo
        api.post(url)
            .then(response => response.data.resultData.desire)
            .then(responseData => {
            if (Array.isArray(responseData) && responseData.length >= 1) {
                setFirstDesired({
                    l_cate_cdnm: responseData[0].depth1_name,
                    m_cate_cdnm: responseData[0].depth2_name,
                    s_cate_cdnm: responseData[0].depth3_name,
                    d_cate_cdnm: responseData[0].depth4_name
                });
            }
            if (Array.isArray(responseData) && responseData.length >= 2) {
                setSecondDesired({
                    l_cate_cdnm: responseData[1].depth1_name,
                    m_cate_cdnm: responseData[1].depth2_name,
                    s_cate_cdnm: responseData[1].depth3_name,
                    d_cate_cdnm: responseData[1].depth4_name
                });
            }
            if (Array.isArray(responseData) && responseData.length >= 3) {
                setThirdDesired({
                    l_cate_cdnm: responseData[2].depth1_name,
                    m_cate_cdnm: responseData[2].depth2_name,
                    s_cate_cdnm: responseData[2].depth3_name,
                    d_cate_cdnm: responseData[2].depth4_name
                });
            }
        })
            .catch(error => {
                console.error(error);
            });
    }, [resumeNo]);



    async function alertMessage () {
        if(FirstDesired.d_cate_cdnm != '' && SecondDesired.d_cate_cdnm != '' && ThirdDesired.d_cate_cdnm != '') {                 
            window.localStorage.removeItem('desiredJobCode');
            let Desired = [
                {
                    depth1 : FirstDesired.l_cate_cdnm.split(".")[0],
                    depth2 : FirstDesired.m_cate_cdnm.split(".")[0],
                    depth3 : FirstDesired.s_cate_cdnm.split(".")[0],
                    depth4 : FirstDesired.d_cate_cdnm.split(".")[0],
                    depth1_name : FirstDesired.l_cate_cdnm,
                    depth2_name : FirstDesired.m_cate_cdnm,
                    depth3_name : FirstDesired.s_cate_cdnm,
                    depth4_name : FirstDesired.d_cate_cdnm
                },
                {
                    depth1 : SecondDesired.l_cate_cdnm.split(".")[0],
                    depth2 : SecondDesired.m_cate_cdnm.split(".")[0],
                    depth3 : SecondDesired.s_cate_cdnm.split(".")[0],
                    depth4 : SecondDesired.d_cate_cdnm.split(".")[0],
                    depth1_name : SecondDesired.l_cate_cdnm,
                    depth2_name : SecondDesired.m_cate_cdnm,
                    depth3_name : SecondDesired.s_cate_cdnm,
                    depth4_name : SecondDesired.d_cate_cdnm
                },
                {
                    depth1 : ThirdDesired.l_cate_cdnm.split(".")[0],
                    depth2 : ThirdDesired.m_cate_cdnm.split(".")[0],
                    depth3 : ThirdDesired.s_cate_cdnm.split(".")[0],
                    depth4 : ThirdDesired.d_cate_cdnm.split(".")[0],
                    depth1_name : ThirdDesired.l_cate_cdnm,
                    depth2_name : ThirdDesired.m_cate_cdnm,
                    depth3_name : ThirdDesired.s_cate_cdnm,
                    depth4_name : ThirdDesired.d_cate_cdnm
                }
            ];
            window.localStorage.setItem('desiredJobCode',JSON.stringify(Desired));            
            window.location.href="/user/resume/resumeProcess/"+resumeNo;
        }
        else{
            alert("희망직무 3개 선택은 필수입니다.");
        } 
    }
    const handleOpenModal = () => {
        setModal1(true);
        setModal2(true);
        setModal3(true);
      };
    
      const handleCloseModal = () => {
        setModal1(false);
        setModal2(false);
        setModal3(false);
      };
      const handleModalOutsideClick = () => {
        
    };
      const handleCheckboxChange1 = (checkboxValue) => {  
        setSelectedCheckbox(checkboxValue);
        setFirstDesired(selectedCheckbox);
      };
      const handleCheckboxChange2 = (checkboxValue) => {    
        setSelectedCheckbox(checkboxValue);
        setSecondDesired(selectedCheckbox);
      };
      const handleCheckboxChange3 = (checkboxValue) => {     
        setSelectedCheckbox(checkboxValue);
        setThirdDesired(selectedCheckbox);
      };
    useEffect(() => {
     
    }, []);

    const colWidth120={
        width:"120px"
    }
    const colWidth140={
        width:"140px"
    }
    const colWidth200={
        width:"200px"
    }
    return (
        <div>            
            <div className="wrapper">
                <main>
                    <div className="page page_job_select page_layout_40">
                        <h2 className="page_back_title">
                            <a href={goBackUrl} className="page_back">희망직무 선택</a>
                        </h2>
                        <div className="wrap_progressbar">
                            <ul className="progressbar_list progress_step01">
                                <li className="progressbar_item progress_item01"><p>희망직무 선택</p></li>
                                <li className="progressbar_item progress_item02"><p>진행방식 선택</p></li>
                                <li className="progressbar_item progress_item03"><p>이력서 작성</p></li>
                            </ul>
                        </div>
                        <div className="wrap_title_step">
                            <h3 className="page_title">희망직무를 선택해 주세요.</h3>
                        </div>
                        <div className="page_inner">
                            <div className="wrap_box_search_result">
                                <div className="box_search_result">
                                    <table className="table_detail">
                                        <caption>희망직무 선택</caption>
                                        <colgroup>
                                            <col style={colWidth140}/>
                                            <col style={colWidth120}/>
                                            <col style={colWidth200}/>
                                            <col style={colWidth200}/>
                                            <col style={colWidth200}/>
                                            <col style={colWidth200}/>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">선택하기</th>
                                                <th scope="col">구분</th>
                                                <th scope="col">대분류</th>
                                                <th scope="col">중분류</th>
                                                <th scope="col">소분류</th>
                                                <th scope="col">세분류</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <a href="#" onClick={(e) => {e.preventDefault(); setModal1(true);}} className="btn_submit filled_green">선택하기</a>
                                                </td>
                                                <td>1순위</td>
                                                <td>{FirstDesired?.l_cate_cdnm}</td>
                                                <td>{FirstDesired?.m_cate_cdnm}</td>
                                                <td>{FirstDesired?.s_cate_cdnm}</td>
                                                <td>{FirstDesired?.d_cate_cdnm}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" onClick={(e) => {e.preventDefault(); setModal2(true);}} className="btn_submit filled_green">선택하기</a>
                                                </td>
                                                <td>2순위</td>
                                                <td>{SecondDesired?.l_cate_cdnm}</td>
                                                <td>{SecondDesired?.m_cate_cdnm}</td>
                                                <td>{SecondDesired?.s_cate_cdnm}</td>
                                                <td>{SecondDesired?.d_cate_cdnm}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" onClick={(e) => {e.preventDefault(); setModal3(true);}} className="btn_submit filled_green">선택하기</a>
                                                </td>
                                                <td>3순위</td>
                                                <td>{ThirdDesired?.l_cate_cdnm}</td>
                                                <td>{ThirdDesired?.m_cate_cdnm}</td>
                                                <td>{ThirdDesired?.s_cate_cdnm}</td>
                                                <td>{ThirdDesired?.d_cate_cdnm}</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                            <div className="wrap_btns flex_ed">
                                <a href="#"  onClick={(e) => {e.preventDefault(); alertMessage();}} target="_self" className="btn_submit filled_green">다음으로</a>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
    {
        
        modal1 ? <div onClick={handleModalOutsideClick}>
            <ChooseJobModal selectedCheckbox={selectedCheckbox}
            onClose={handleCloseModal}
            onCheckboxChange={handleCheckboxChange1}
            setModal={setModal1}
            category={"desired"}
            FirstDesired={FirstDesired} 
            SecondDesired={SecondDesired} 
            ThirdDesired={ThirdDesired} /></div> : null
    }
    
    {
        modal2 ? <div onClick={handleModalOutsideClick}>
            <ChooseJobModal selectedCheckbox={selectedCheckbox}
            onClose={handleCloseModal}
            onCheckboxChange={handleCheckboxChange2}
            setModal={setModal2}
            category={"desired"}
            FirstDesired={FirstDesired} 
            SecondDesired={SecondDesired} 
            ThirdDesired={ThirdDesired}  /></div> : null
    }
    
    {
        modal3 ? <div onClick={handleModalOutsideClick}>
                <ChooseJobModal selectedCheckbox={selectedCheckbox}
            onClose={handleCloseModal}
            onCheckboxChange={handleCheckboxChange3}
            setModal={setModal3}
            category={"desired"}
            FirstDesired={FirstDesired} 
            SecondDesired={SecondDesired} 
            ThirdDesired={ThirdDesired}  /></div> : null
    }
        </div>
        
    )
}

export default DesiredJob;