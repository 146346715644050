import React from "react";

const Header = ({ setShowGuide }) => {
  return (
    <div style={styles.header}>
      <img src="/img/logo/logo_circle.png" alt="" style={styles.icon} />
      브이드림 챗봇 드리미
      <img
        src="/img/icon/btn_guide_open.png"
        style={styles.helpIcon}
        onClick={() => setShowGuide(true)}
        alt="가이드"
      />
    </div>
  );
};

const styles = {
  header: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#00bf81",
    padding: "10px 15px",
    borderBottom: "1px solid #ccc",
    lineHeight: "26px",
    fontSize: "1em",
    fontWeight: "bold",
    color: "#fff",
  },
  icon: {
    height: "30px",
    width: "30px",
    paddingBottom: "3px",
    marginRight: 5,
  },
  helpIcon: {
    position: "absolute",
    width: "23px",
    height: "23px",
    fontSize: "20px",
    cursor: "pointer",
    bottom: "15px",
    right: "10px",
  },
};

export default Header;
