import { useHistory, useLocation } from "react-router-dom";

const AbilityProcced = () => {
  const location = useLocation();

  const myparam = location.state && location.state.checked;

  const useHis = useHistory();
  const goToProgress = (param) => {
    window.scrollTo(0, 0);
    useHis.push("/user/ability/progress", { params: param });
  };

  const goToMain = (e) => {
    e.preventDefault();
    useHis.push("/user/ability/main");
  };

  return (
    myparam && (
      <>
        {/* <Aside /> */}
        <div className="wrapper">
          <main>
            <div className="page page_job_select page_layout_40">
              <h2 className="page_back_title">
                <a
                  href="#"
                  onClick={goToMain}
                  target="_self"
                  className="page_back"
                >
                  역량평가 진행하기
                </a>
              </h2>
              <h2 className="page_title">역량평가 진행방식을 선택해 주세요.</h2>

              <div className="page_inner">
                <div className="area_box_way">
                  <div className="wrap_box_way">
                    <div className="box_way_txt">
                      <p className="badge badge_way">기존방식</p>
                      <h3>작성형</h3>
                      <p>
                        {" "}
                        작성양식에 맞춰
                        <br />
                        가입하는 방식입니다.
                      </p>
                    </div>
                    <a
                      href="#"
                      target="_self"
                      onClick={(e) => {
                        e.preventDefault();
                        goToProgress({ type: "write" });
                      }}
                      className="btn_submit lined_green"
                    >
                      시작하기
                    </a>
                  </div>

                  <div className="wrap_box_way">
                    <div className="box_way_txt">
                      <p className="badge badge_way">챗봇방식</p>
                      <h3>1:1 대화형</h3>
                      <p>
                        챗봇과 1:1 대화형식으로
                        <br />
                        회원가입을 진행합니다.
                      </p>
                    </div>
                    <a
                      href="#"
                      target="_self"
                      onClick={(e) => {
                        e.preventDefault();
                        goToProgress({ type: "chat" });
                      }}
                      className="btn_submit lined_green"
                    >
                      시작하기
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    )
  );
};

export default AbilityProcced;
