import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import api, { decodeToken } from "../../../api/api";
import moment from "moment/moment";
import "moment/locale/ko";
import { useModal } from "../../../hooks/useModal";
import Games from "../../../components/quiz/games";
import usePrevious from "../../../hooks/usePrevious";

function isGamesCateType(cateType) {
  return /^(Situation|Block|Emotion|Thinking)/.test(cateType);
}

const AbilityProgress = () => {
  const history = useHistory();
  const dToken = decodeToken(localStorage.getItem("accessToken"));
  const userId = dToken.sub;
  const location = useLocation();
  const myParams = location.state && location.state.params;
  const { isModalOpen, openModal, closeModal, ModalComponent } = useModal();

  const [abilityResult, setAbilityResult] = useState([]);
  const [type, setType] = useState(null);
  const [cate, setCate] = useState(null);
  const [cateType, setCateType] = useState(null);
  const [values, setValues] = useState([]);
  const [cateIdx, setCateIdx] = useState(0);

  const [conversation, setConversation] = useState([]);
  const [valueIdx, setValueIdx] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const scrollRef = useRef();
  const [shouldProceedToNextPage, setShouldProceedToNextPage] = useState(false);

  //질문 갯수
  const [progressTotalCnt, setProgressTotalCnt] = useState(0);
  //체크된 질문 갯수
  const [progressCnt, setProgressCnt] = useState(0);

  const [prevPageButtonProps, setPrevPageButtonProps] = useState({
    disabled: false,
    onClick: () => {},
  });
  const [nextPageButtonProps, setNextPageButtonProps] = useState({
    disabled: false,
    onClick: () => {},
  });

  useEffect(() => {
    let abr = localStorage.getItem("abilityResult_" + userId);
    let cateTmp = localStorage.getItem("category_" + userId);
    let cateIdxTmp = localStorage.getItem("categoryIdx_" + userId);
    let typeTmp = myParams && myParams.type;
    setType(typeTmp);
    if (abr == null || cateTmp == null) {
      console.log("역량평가 받아오기");
      api
        .post("/api/ability/getAbilityItem")
        .then((response) => response.data)
        .then((response) => {
          setAbilityResult(response && response.resultData);
          localStorage.setItem(
            "abilityResult_" + userId,
            JSON.stringify(abilityResult)
          );

          function getLastAnsweredIndex(data) {
            let lastIndex = -1;

            const hasScore = (problem) =>
              problem &&
              problem.arrayScore !== null &&
              !problem.arrayScore.some((score) => score === null);

            const hasQuestionScore = (questions) =>
              questions && questions.some((q) => q.score !== 0);

            for (let i = 0; i < data.length; i++) {
              const item = data[i];

              if (item.type.includes("Preview")) continue;

              const problem = item[item.type.toLowerCase() + "Problem"];
              if (
                (item.question && hasQuestionScore(item.question)) ||
                (problem && hasScore(problem))
              ) {
                lastIndex = i;
              }
            }

            return lastIndex;
          }

          const startIndex = getLastAnsweredIndex(response.resultData);
          console.log({ startIndex });
          setCateIdx(startIndex + 1);

          console.log({
            cateTmp:
              response &&
              response.resultData &&
              response.resultData[startIndex + 1]?.category,
            values:
              (response?.resultData &&
                (response.resultData[startIndex + 1]?.question ||
                  [
                    response.resultData[startIndex + 1]?.situationProblem,
                  ].filter(Boolean) ||
                  [response.resultData[startIndex + 1]?.emotionProblem].filter(
                    Boolean
                  ) ||
                  response.resultData[startIndex + 1]?.thinkingProblem
                    .problem ||
                  [response.resultData[startIndex + 1]?.blockProblem].filter(
                    Boolean
                  ))) ||
              [],
            cateType:
              response &&
              response.resultData &&
              response.resultData[startIndex + 1]?.type,
          });

          cateTmp == null &&
            setCate(
              response &&
                response.resultData &&
                response.resultData[startIndex + 1]?.category
            );
          setValues(
            response?.resultData?.[startIndex + 1] &&
              (() => {
                const result =
                  response.resultData[startIndex + 1].question ||
                  response.resultData[startIndex + 1].situationProblem ||
                  response.resultData[startIndex + 1].emotionProblem ||
                  response.resultData[startIndex + 1].thinkingProblem ||
                  response.resultData[startIndex + 1].blockProblem;

                return result &&
                  typeof result === "object" &&
                  !Array.isArray(result)
                  ? [result]
                  : result;
              })()
          );

          cateType == null &&
            setCateType(
              response &&
                response.resultData &&
                response.resultData[startIndex + 1]?.type
            );
        });
    } else {
      console.log("저장되어 있는 거 사용하기");
      abr = JSON.parse(abr);
      setAbilityResult(abr);
      setCate(cateTmp);
      cateTmp == null
        ? setCate(abr && abr[0].category)
        : abr &&
          abr.map((item, i) => {
            cateTmp === item.category && cateIdxTmp == i && setCateIdx(i);
            cateTmp === item.category && setCateType(abr && abr[i].type);
          });
      let questions = [];
      abr &&
        abr.map(
          (item, i) =>
            cateTmp === item.category &&
            cateIdxTmp == i &&
            (questions =
              item.question ||
              (item.situationProblem && [item.situationProblem]) ||
              (item.emotionProblem && [item.emotionProblem]) ||
              (item.thinkingProblem && [item.thinkingProblem]) ||
              (item.blockProblem && [item.blockProblem]))
        );
      if (type === "chat") {
        questions &&
          questions.map((item, i) => {
            item.score = 0;
          });
      }

      setValues(questions);
    }
  }, [cate]);

  useEffect(() => {
    if ((cate === "청각 기능" || cate === "시각 기능") && type === "write") {
      let scoreChk = false;
      values &&
        values.map((item) => {
          scoreChk = scoreChk || item.score === 0;
        });

      if (scoreChk) {
        try {
          api
            .post("/api/signup/getUserInfo", {})
            .then((response) => response.data)
            .then((response) => {
              let majorDisable =
                response &&
                response.resultCode === 1000 &&
                response.resultData &&
                response.resultData.disabili_certifica &&
                response.resultData.disabili_certifica.major_disability_nm;
              let minorDisable =
                response &&
                response.resultCode === 1000 &&
                response.resultData &&
                response.resultData.disabili_certifica &&
                response.resultData.disabili_certifica.minor_disability_nm;
              let valueTmp = [...values];

              if (cate === "청각 기능") {
                if (
                  majorDisable &&
                  (minorDisable || minorDisable === "") &&
                  majorDisable.indexOf("청각") < 0 &&
                  minorDisable.indexOf("청각") < 0
                ) {
                  if (valueTmp) {
                    const updatedValues = valueTmp.map((item) => ({
                      ...item,
                      score: item.score === 0 ? 5 : item.score,
                    }));
                    setValues(updatedValues);
                  }
                }
              } else if (cate === "시각 기능") {
                if (
                  majorDisable &&
                  (minorDisable || minorDisable === "") &&
                  majorDisable.indexOf("시각") < 0 &&
                  minorDisable.indexOf("시각") < 0
                ) {
                  if (valueTmp) {
                    const updatedValues = valueTmp.map((item) => ({
                      ...item,
                      score: item.score === 0 ? 5 : item.score,
                    }));
                    setValues(updatedValues);
                  }
                }
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } catch (e) {
          console.log(e);
        }
      }
    }

    if (type === "chat") {
      if (isGamesCateType(cate)) {
        let greet = wrapMessage(
          "game",
          null,
          values && "Q" + (valueIdx + 1) + ". " + values[0].value,
          cate,
          valueIdx
        );

        return;
      }

      if (conversation && conversation.length === 0) {
        let greet = wrapMessage(
          "bot",
          null,
          values && "Q" + (valueIdx + 1) + ". " + values[0].value,
          cate,
          valueIdx
        );
        greet.content = [
          { type: "text", message: cate + " 문항 질문드릴게요 :)" },
          ...greet.content,
        ];
        setConversation([...conversation, greet]);
      }
    }

    if (abilityResult.length !== 0) {
      localStorage.setItem(
        "abilityResult_" + userId,
        JSON.stringify(abilityResult)
      );
    }

    // 진행률
    let tmpTotalCnt = 0;
    let tmpCnt = 0;

    if (type === "write") {
      abilityResult &&
        abilityResult.forEach((item) => {
          if (item.type.includes("Preview")) return;

          if (item.question) {
            tmpTotalCnt += item.question.length;
            item.question.forEach((qItem) => {
              tmpCnt +=
                qItem.score > 0 ||
                (qItem.arrayScore && qItem.arrayScore.length > 0)
                  ? 1
                  : 0;
            });
            return;
          }

          const countNonEmptyAnswers = ({ arrayScore }) => {
            const validArrayScore = arrayScore ?? [];
            return validArrayScore.filter(
              (answer) => ![undefined, null].includes(answer)
            ).length;
          };
          const problem = item[item.type.toLowerCase() + "Problem"];
          switch (item.type) {
            case "Thinking": {
              tmpTotalCnt += 9;
              tmpCnt += countNonEmptyAnswers(problem);
              break;
            }
            case "Emotion": {
              tmpTotalCnt++;
              if (countNonEmptyAnswers(problem) > 0) tmpCnt++;
              break;
            }
            case "Situation": {
              const countTextNumberKeys = Object.keys(problem).filter((key) =>
                /Text\d+$/.test(key)
              ).length;
              tmpTotalCnt += countTextNumberKeys > 0 ? countTextNumberKeys : 1;
              tmpCnt += countNonEmptyAnswers(problem);
              break;
            }
            case "Block": {
              tmpTotalCnt++;
              if (countNonEmptyAnswers(problem) > 0) tmpCnt++;
              break;
            }
            default:
              break;
          }
        });
    } else if (type === "chat") {
      //채팅형의 경우 대화를 임시저장하지 않아 현재카테고리 기준 이전 값 + 현재 카테고리 값의 체크된 밸류 갯수로 함

      abilityResult &&
        abilityResult.map((item, i) => {
          tmpTotalCnt += item.question != undefined ? item.question.length : 0;
          i <= cateIdx &&
            item.question &&
            item.question.map((qItem, j) => {
              tmpCnt += qItem.score > 0 || qItem.arrayScore?.length > 0 ? 1 : 0;
            });
        });
    }

    setProgressCnt(tmpCnt);
    setProgressTotalCnt(tmpTotalCnt);
  }, [values]);

  const prevCate = usePrevious(cate);
  useEffect(() => {
    scrollToBottom();

    // 렌더링이 꼬여있음. conversation이 가장 나중에 업데이트 되므로
    // 아래처럼 prevCate와 cate를 비교하여 다를 경우에만 호출 해야함
    // 만약 아래 if문이 없으면 청각기능 다음 문제에서 '이전으로'버튼 클릭 시 무한재귀 발생함
    if (prevCate !== cate) return;
    if ((cate === "청각 기능" || cate === "시각 기능") && type === "chat") {
      let scoreChk = false;
      values &&
        values.map((item) => {
          scoreChk = scoreChk || item.score === 0;
        });

      if (scoreChk) {
        try {
          api
            .post("/api/signup/getUserInfo", {})
            .then((response) => response.data)
            .then((response) => {
              let majorDisable =
                response &&
                response.resultCode === 1000 &&
                response.resultData &&
                response.resultData.disabili_certifica &&
                response.resultData.disabili_certifica.major_disability_nm;
              let minorDisable =
                response &&
                response.resultCode === 1000 &&
                response.resultData &&
                response.resultData.disabili_certifica &&
                response.resultData.disabili_certifica.minor_disability_nm;
              let valueTmp = [...values];

              if (cate === "청각 기능") {
                if (
                  majorDisable &&
                  (minorDisable || minorDisable === "") &&
                  majorDisable.indexOf("청각") < 0 &&
                  minorDisable.indexOf("청각") < 0
                ) {
                  if (valueTmp) {
                    const message = values[0].score_5;
                    const fakeEvent = {
                      target: {
                        attributes: {
                          idx: { value: "0" },
                          type: { value: "radio" },
                          data: { value: "0" },
                        },
                        value: 5,
                      },
                    };

                    doQuestion(fakeEvent, message);
                  }
                }
              } else if (cate === "시각 기능") {
                if (
                  majorDisable &&
                  (minorDisable || minorDisable === "") &&
                  majorDisable.indexOf("시각") < 0 &&
                  minorDisable.indexOf("시각") < 0
                ) {
                  if (valueTmp) {
                    const message = values[0].score_5;
                    const fakeEvent = {
                      target: {
                        attributes: {
                          idx: { value: "0" },
                          type: { value: "radio" },
                          data: { value: "0" },
                        },
                        value: 5,
                      },
                    };

                    doQuestion(fakeEvent, message);
                  }
                }
              }
            });
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [conversation]);

  // useEffect(()=>{

  //     if(cate === "청각 기능" && (userInfo.major_disability_nm.indexOf("시각")>=0 || userInfo.minor_disability_nm.indexOf("시각")>=0)){
  //         setAbsoluteResult(true);
  //     }
  //     else if((cate == null || cate === "시각 기능") && (userInfo.major_disability_nm.indexOf("청각")>=0 || userInfo.minor_disability_nm.indexOf("청각")>=0)){
  //         setAbsoluteResult(true);
  //     }
  //     else{
  //         setAbsoluteResult(false);
  //     }

  //     console.log(absoluteResult);
  // })

  const handleChange = (e) => {
    let tmpValues = [...values];
    tmpValues[e.target.attributes.idx.value].score = Number(e.target.value);
    setValues(tmpValues);
  };

  const handleGamesValueChange = (val, _shouldProceedToNextPage) => {
    setValues((prev) => [{ ...prev[0], ...val }]);
    setAbilityResult((prev) => {
      const updatedResult = [...prev];
      updatedResult[cateIdx] = {
        ...updatedResult[cateIdx],
        [updatedResult[cateIdx].type.toLowerCase() + "Problem"]: {
          ...updatedResult[cateIdx][
            updatedResult[cateIdx].type.toLowerCase() + "Problem"
          ],
          arrayScore: val.arrayScore,
        },
      };
      return updatedResult;
    });

    setShouldProceedToNextPage(_shouldProceedToNextPage);
  };

  useEffect(() => {
    if (shouldProceedToNextPage) {
      nextPage();
      setShouldProceedToNextPage(false);
    }
  }, [shouldProceedToNextPage]);

  const handleChangeInput = (e) => {
    setInputValue(e.target.value);
  };

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      doQuestion(e, inputValue);
    }
  };

  const updateConversation = (e, type, message) => {
    let cTmp = [...conversation];
    let valueTmp = [...values];
    if (type === "btn") {
      let idx = e.target.attributes.data.value;
      let score = e.target.value;
      cTmp &&
        cTmp[idx] &&
        cTmp[idx].content &&
        cTmp[idx].content.map((item, i) => {
          item.type === "button" &&
            cTmp[idx].content[i].buttons.map((bItem, j) => {
              j == score - 1 ? (bItem.action = true) : (bItem.action = false);
            });
        });
      valueTmp[e.target.attributes.idx.value].score = Number(score);
      setValues(valueTmp);
    } else {
      let score = 0;
      cTmp &&
        cTmp.map((item, i) => {
          item.idx === valueIdx &&
            item.content &&
            item.content.map((cItem, j) => {
              cItem.type === "button" &&
                cItem.buttons.map((bItem, k) => {
                  bItem.message.indexOf(message) == 0
                    ? (bItem.action = true)
                    : (bItem.action = false);
                  bItem.message.indexOf(message) == 0
                    ? (score = k + 1)
                    : (score = score);
                });
            });
        });
      if (score != 0) {
        valueTmp[valueIdx].score = score;
        setValues(valueTmp);
      }
    }
  };

  const doQuestion = (e, message) => {
    if (message === "") return;
    let tmpConv = [...conversation];
    tmpConv = [...tmpConv, wrapMessage("user", message, null, null, null)];

    setConversation(tmpConv);
    if (
      e.target.attributes.type &&
      e.target.attributes.type.value === "radio"
    ) {
      //wrapMessage = (role, text, value, category, valueIdx)
      //1.
      updateConversation(e, "btn", null);
      if (valueIdx === Number(e.target.attributes.idx.value)) {
        if (values.length === Number(e.target.attributes.idx.value) + 1) {
          tmpConv = [
            ...tmpConv,
            {
              role: "bot",
              content: [
                {
                  type: "text",
                  message: "다음으로 버튼을 눌러 역량평가를 진행해주세요.",
                },
              ],
            },
          ];
          setConversation(tmpConv);
        } else {
          tmpConv = [
            ...tmpConv,
            wrapMessage(
              "bot",
              null,
              values &&
                "Q" + (valueIdx + 2) + ". " + values[valueIdx + 1].value,
              cate,
              valueIdx + 1
            ),
          ];
          setValueIdx(valueIdx + 1);
          setConversation(tmpConv);
        }
      }
      setInputValue("");
    } else {
      updateConversation(e, "chat", message);
      let chk = true;
      tmpConv.map((item) => {
        item.role === "bot" &&
          item.idx === valueIdx &&
          item.content &&
          item.content.length &&
          item.content[item.content.length - 1] &&
          item.content[item.content.length - 1].buttons &&
          item.content[item.content.length - 1].buttons.map((bItem) => {
            chk = chk && !(bItem.message.indexOf(message) > -1);
          });
      });
      if (!chk) {
        if (valueIdx === values.length - 1) {
          tmpConv = [
            ...tmpConv,
            {
              role: "bot",
              content: [
                {
                  type: "text",
                  message: "다음으로 버튼을 눌러 역량평가를 진행해주세요.",
                },
              ],
            },
          ];
          setConversation(tmpConv);
        } else {
          tmpConv = [
            ...tmpConv,
            wrapMessage(
              "bot",
              null,
              values &&
                "Q" + (valueIdx + 2) + ". " + values[valueIdx + 1].value,
              cate,
              valueIdx + 1
            ),
          ];
          setValueIdx(valueIdx + 1);
          setConversation(tmpConv);
        }
      }
      setInputValue("");
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const prevPage = (e) => {
    localStorage.removeItem("gameRemainingTime");
    localStorage.removeItem("gameStartTime");

    if (cateIdx === 0) {
      return history.push("/user/ability/main");
    }

    localStorage?.setItem(
      "category_" + userId,
      abilityResult && abilityResult[cateIdx - 1].category
    );
    localStorage.setItem("categoryIdx_" + userId, abilityResult && cateIdx - 1);
    setCate(abilityResult && abilityResult[cateIdx - 1].category);
    setConversation([]);
    setValueIdx(0);
    window.scrollTo(0, 0);
  };

  const useHis = useHistory();
  const goToAbilityMainPage = () => {
    useHis.push("/user/ability/main");
  };

  const goToProceed = () => {
    useHis.push("/user/ability/procced", { checked: true });
  };

  const nextPage = () => {
    localStorage.removeItem("gameRemainingTime");
    localStorage.removeItem("gameStartTime");

    const isEndOfGameCategories =
      !isGamesCateType(abilityResult[cateIdx + 1]?.category) &&
      isGamesCateType(abilityResult[cateIdx]?.category);

    if (isEndOfGameCategories) {
      setTimeout(() => {
        setPrevPageButtonProps({ disabled: false, onClick: () => {} });
        openModal({
          title: "알람",
          content: (
            <div>
              <p>게임형 평가가 종료되었으며</p>
              <p>문항형 평가가 시작됩니다.</p>
            </div>
          ),
          buttons: [
            {
              label: "확인",
              className: "confirm-button",
            },
          ],
        });
      }, 500);
    }

    let tmpAbilityResult = [...abilityResult];
    let scoreChk = true;

    if (cateType === "Situation") {
      // console.log(values);
      // if (values[0].score.length === 4) {
      //   scoreChk = true;
      // } else {
      //   scoreChk = false;
      // }
    } else if (cateType === "Emotion") {
      // if (values[0].score) {
      //   scoreChk = true;
      // } else {
      //   scoreChk = false;
      // }
    } else if (cateType === "Thinking") {
      // if (values[0].score.every((item) => item.list.length === 3)) {
      //   scoreChk = true;
      // } else {
      //   scoreChk = false;
      // }
    } else if (cateType === "Block") {
      // if (values[0].score) {
      //   scoreChk = values[0].score.length === 4;
      // } else {
      //   scoreChk = false;
      // }
    } else if (cateType.endsWith("Preview")) {
      //
    } else {
      values &&
        values.map((item, i) => (scoreChk = scoreChk && item.score > 0));
    }

    if (scoreChk > 0 && !cateType.endsWith("Preview")) {
      api
        .post("/api/ability/insertAbilityTmpScore", values)
        .then((response) => response.data)
        .then((response) => {
          if (response) {
            if (tmpAbilityResult && tmpAbilityResult[cateIdx]) {
              if (tmpAbilityResult[cateIdx].question) {
                tmpAbilityResult[cateIdx].question = values;
              }

              if (tmpAbilityResult[cateIdx].situationProblem) {
                tmpAbilityResult[cateIdx].situationProblem = values[0];
              }

              if (tmpAbilityResult[cateIdx].blockProblem) {
                tmpAbilityResult[cateIdx].blockProblem = values[0];
              }

              if (tmpAbilityResult[cateIdx].emotionProblem) {
                tmpAbilityResult[cateIdx].emotionProblem = values[0];
              }

              if (tmpAbilityResult[cateIdx].thinkingProblem) {
                tmpAbilityResult[cateIdx].thinkingProblem = values[0];
              }
            }

            localStorage.setItem(
              "abilityResult_" + userId,
              JSON.stringify(tmpAbilityResult)
            );
            localStorage.setItem(
              "category_" + userId,
              abilityResult && abilityResult[cateIdx + 1].category
            );
            localStorage.setItem(
              "categoryIdx_" + userId,
              abilityResult && cateIdx + 1
            );

            setCate(abilityResult && abilityResult[cateIdx + 1].category);
            setConversation([]);
            setValueIdx(0);
            window.scrollTo(0, 0);
          } else {
            alert("오류 발생");
          }
        });
    } else {
      if (isGamesCateType(cateType)) {
        // 게임형 프리뷰문제인 경우 api 호출하지않고 다음페이지로 넘김
        localStorage.setItem(
          "category_" + userId,
          abilityResult && abilityResult[cateIdx + 1].category
        );
        localStorage.setItem(
          "categoryIdx_" + userId,
          abilityResult && cateIdx + 1
        );
        setCate(abilityResult && abilityResult[cateIdx + 1].category);
        setConversation([]);
        setValueIdx(0);
        window.scrollTo(0, 0);
      }
      if (!isGamesCateType(cateType)) {
        openModal({
          title: "알람",
          content: (
            <div>
              <p>완료되지 않은 항목이 있습니다.</p>
            </div>
          ),
          buttons: [
            {
              label: "확인",
              className: "confirm-button",
            },
          ],
        });
      }
    }
  };

  const modalPopup = (type) => {
    if (type === "prev" || cateType?.endsWith("Preview")) {
      openModal({
        title: "알람",
        content: (
          <p>
            이전 페이지로 이동할 경우
            <br />
            지금까지 작성된 내용은 저장되지 않습니다.
          </p>
        ),
        buttons: [
          { label: "확인", onClick: prevPage, className: "confirm-button" },
          { label: "취소", onClick: closeModal, className: "cancel-button" },
        ],
      });
    } else if (type === "main") {
      openModal({
        title: "알람",
        content: (
          <p>
            현재 진행중인 역량평가를 종료하시겠습니까?
            <br />
            종료시 지금까지 작성된 내용은 저장되지 않습니다.
          </p>
        ),
        buttons: [
          {
            label: "확인",
            onClick: goToAbilityMainPage,
            className: "confirm-button",
          },
          { label: "취소", onClick: closeModal, className: "cancel-button" },
        ],
      });
    } else if (type === "insert") {
      openModal({
        title: "알람",
        content: <p>역량평가를 최종 완료하시겠습니까?</p>,
        buttons: [
          { label: "확인", onClick: resultSubmit, className: "confirm-button" },
          { label: "취소", onClick: closeModal, className: "cancel-button" },
        ],
      });
    } else if (type === "proceed") {
      openModal({
        title: "알람",
        content: <p>이전 페이지로 이동하시겠습니까?</p>,
        buttons: [
          { label: "확인", onClick: goToProceed, className: "confirm-button" },
          { label: "취소", onClick: closeModal, className: "cancel-button" },
        ],
      });
    } else if (type === "goToPreviousPage") {
      openModal({
        title: "알람",
        content: <p>제한시간 종료후 버튼클릭이 가능합니다.</p>,
        buttons: [
          {
            label: "확인",
            onClick: closeModal,
            className: "confirm-button",
          },
        ],
      });
    }
  };

  const resultSubmit = () => {
    let scoreChk = true;
    values && values.map((item, i) => (scoreChk = scoreChk && item.score > 0));
    if (scoreChk > 0) {
      api
        .post("/api/ability/insertAbility", [...abilityResult])
        .then((response) => response.data)
        .then((response) => {
          localStorage.removeItem("abilityResult_" + userId);
          localStorage.removeItem("category_" + userId);
          goToAbilityMainPage();
        });
    } else {
      alert("완료되지 않은 항목이 있습니다.");
    }
  };

  const wrapMessage = (role, text, value, category, valueIdx) => {
    if (role === "user") {
      return {
        role: "user",
        date: moment().format("LT"),
        content: [
          {
            type: "text",
            message: text,
          },
        ],
      };
    } else if (role === "bot") {
      return {
        role: "bot",
        idx: valueIdx,
        date: moment().format("LT"),
        content: [
          {
            type: "text",
            message: value,
          },
          {
            type: "button",
            buttons: [
              {
                message: values[valueIdx].score_1,
                action: false,
              },
              {
                message: values[valueIdx].score_2,
                action: false,
              },
              {
                message: values[valueIdx].score_3,
                action: false,
              },
              {
                message: values[valueIdx].score_4,
                action: false,
              },
              {
                message: values[valueIdx].score_5,
                action: false,
              },
            ],
          },
        ],
      };
    } else if (role == "game") {
      const message = "";
      return {
        role: "game",
        idx: valueIdx,
        date: moment().format("LT"),
        content: [{}],
      };
    }
  };

  return (
    <>
      <div className="wrapper">
        <main>
          <div className="page ability_check page_layout_40">
            <h2 className="page_back_title page_inner_above">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  modalPopup("proceed");
                }}
                target="_self"
                className="page_back"
              >
                역량평가 진행하기
              </a>
            </h2>
            <article className="wrap_progress">
              <h4>
                진행률 (<span>{progressCnt}</span> / {progressTotalCnt})
              </h4>
              <div className="box_progress">
                <progress
                  value={
                    progressTotalCnt > 0
                      ? (progressCnt / progressTotalCnt) * 100
                      : 0
                  }
                  max="100"
                ></progress>
                <p className="progress_txt">
                  <span>
                    {progressTotalCnt > 0
                      ? parseInt((progressCnt / progressTotalCnt) * 100)
                      : 0}
                  </span>
                  %
                </p>
              </div>
            </article>

            <div className="page_inner">
              {type && type === "write" ? (
                <div className="area_shade">
                  <Games
                    cateType={cateType}
                    cateIdx={cateIdx}
                    nextPage={nextPage}
                    values={values}
                    isModalOpen={isModalOpen}
                    openModal={openModal}
                    setPrevPageButtonProps={setPrevPageButtonProps}
                    setNextPageButtonProps={setNextPageButtonProps}
                    handleGamesValueChange={handleGamesValueChange}
                  />

                  {values &&
                    (cateType === "P" ||
                      cateType === "S" ||
                      cateType === "E" ||
                      cateType === "O") &&
                    values.map((item, i) => (
                      <div
                        key={item.value}
                        className={`box_radio_qna ${cateType}`}
                      >
                        <h4
                          style={
                            cateType === "P"
                              ? { width: "calc(50% - 300px)" }
                              : { width: "100%" }
                          }
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: "Q" + (i + 1) + ".",
                            }}
                          ></span>{" "}
                          <span
                            dangerouslySetInnerHTML={{ __html: item.value }}
                          ></span>
                        </h4>
                        <div
                          className="box_input_radio"
                          style={
                            cate === "시각 기능" || cate === "청각 기능"
                              ? { width: "100%" }
                              : {}
                          }
                        >
                          <label htmlFor={"temp01" + i}>
                            <input
                              type="radio"
                              idx={i}
                              className="input_radio"
                              id={"temp01" + i}
                              name={"group0" + i}
                              value="1"
                              checked={item.score === 1 ? true : false}
                              onChange={handleChange}
                            />
                            {item.score_1}
                          </label>
                        </div>
                        <div
                          className="box_input_radio"
                          style={
                            cate === "시각 기능" || cate === "청각 기능"
                              ? { width: "100%" }
                              : {}
                          }
                        >
                          <label htmlFor={"temp02" + i}>
                            <input
                              type="radio"
                              idx={i}
                              className="input_radio"
                              id={"temp02" + i}
                              name={"group0" + i}
                              value="2"
                              checked={item.score === 2 ? true : false}
                              onChange={handleChange}
                            />
                            {item.score_2}
                          </label>
                        </div>
                        <div
                          className="box_input_radio"
                          style={
                            cate === "시각 기능" || cate === "청각 기능"
                              ? { width: "100%" }
                              : {}
                          }
                        >
                          <label htmlFor={"temp03" + i}>
                            <input
                              type="radio"
                              idx={i}
                              className="input_radio"
                              id={"temp03" + i}
                              name={"group0" + i}
                              value="3"
                              checked={item.score === 3 ? true : false}
                              onChange={handleChange}
                            />
                            {item.score_3}
                          </label>
                        </div>
                        <div
                          className="box_input_radio"
                          style={
                            cate === "시각 기능" || cate === "청각 기능"
                              ? { width: "100%" }
                              : {}
                          }
                        >
                          <label htmlFor={"temp04" + i}>
                            <input
                              type="radio"
                              idx={i}
                              className="input_radio"
                              id={"temp04" + i}
                              name={"group0" + i}
                              value="4"
                              checked={item.score === 4 ? true : false}
                              onChange={handleChange}
                            />
                            {item.score_4}
                          </label>
                        </div>
                        <div
                          className="box_input_radio"
                          style={
                            cate === "시각 기능" || cate === "청각 기능"
                              ? { width: "100%" }
                              : {}
                          }
                        >
                          <label htmlFor={"temp05" + i}>
                            <input
                              type="radio"
                              idx={i}
                              className="input_radio"
                              id={"temp05" + i}
                              name={"group0" + i}
                              value="5"
                              checked={item.score === 5 ? true : false}
                              onChange={handleChange}
                            />
                            {item.score_5}
                          </label>
                        </div>
                      </div>
                    ))}
                  {cateType === "O" && (
                    <div className="text_box">
                      ※ 자조기술이란?
                      <br /> 독립적인 일상생활을 하는데 필요한 기본적인 기술을
                      의미하며 식사하기, 대소변처리하기, 옷입고 벗기, 목욕,
                      몸단장하기 등의 기술을 포함한다.
                    </div>
                  )}
                </div>
              ) : (
                <div className="area_shade">
                  <div className="message_container">
                    <div
                      className="area_chat"
                      style={{ height: "588px", overflowY: "auto" }}
                      ref={scrollRef}
                    >
                      {conversation.length > 0 ? (
                        <>
                          {conversation &&
                            conversation.map((cItem, i) => {
                              return cItem.role === "bot" ? (
                                <div key={i} className="chat_message_area">
                                  <div
                                    className="chat_message_wrap"
                                    style={{ paddingLeft: 0 }}
                                  >
                                    <div className="chat_message_profile">
                                      <div className="chatbot_photo"></div>
                                      <div className="chatbot_name">
                                        드리미 챗봇
                                      </div>
                                    </div>
                                    {cItem.content &&
                                      cItem.content.map((content, j) => {
                                        return content.type === "text" ? (
                                          <div
                                            key={content.type + i + j}
                                            className="chat_message_box"
                                          >
                                            <div
                                              className="chat_message_text"
                                              dangerouslySetInnerHTML={{
                                                __html: content.message,
                                              }}
                                            />
                                            <div
                                              className="chat_message_date"
                                              dangerouslySetInnerHTML={{
                                                __html: cItem.date,
                                              }}
                                            />
                                          </div>
                                        ) : content.type === "button" ? (
                                          <div
                                            key={content.type + i + j}
                                            className="chat_message_box"
                                            style={{
                                              flexDirection: "row",
                                              flexWrap: "wrap",
                                              width: "80%",
                                            }}
                                          >
                                            {content.buttons &&
                                              content.buttons.map(
                                                (button, k) => {
                                                  return (
                                                    <div
                                                      key={k}
                                                      className="box_inline_radio"
                                                    >
                                                      <input
                                                        type="radio"
                                                        idx={cItem.idx}
                                                        data={i}
                                                        id={"temp0" + k + i}
                                                        name={"group0" + i}
                                                        value={k + 1}
                                                        checked={button.action}
                                                        onChange={(e) => {
                                                          doQuestion(
                                                            e,
                                                            button.message
                                                          );
                                                        }}
                                                      />
                                                      <label
                                                        className="btn_inline_radio"
                                                        htmlFor={
                                                          "temp0" + k + i
                                                        }
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            button.message,
                                                        }}
                                                      ></label>
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                        ) : null;
                                      })}
                                  </div>
                                </div>
                              ) : (
                                <div key={i} className="send_message_area">
                                  <div className="send_message_wrap">
                                    <div className="send_message_box">
                                      <div
                                        className="send_message_text"
                                        dangerouslySetInnerHTML={{
                                          __html: cItem.content[0].message,
                                        }}
                                      />
                                      <div
                                        className="send_message_date"
                                        dangerouslySetInnerHTML={{
                                          __html: cItem.date,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      ) : (
                        <Games
                          cateType={cateType}
                          cateIdx={cateIdx}
                          nextPage={nextPage}
                          values={values}
                          isModalOpen={isModalOpen}
                          openModal={openModal}
                          setNextPageButtonProps={setNextPageButtonProps}
                          setPrevPageButtonProps={setPrevPageButtonProps}
                          handleGamesValueChange={handleGamesValueChange}
                        />
                      )}
                    </div>
                    <div className="area_send">
                      <div className="wrap_send_message">
                        <input
                          type="text"
                          id="msgSendInput"
                          className=""
                          placeholder="도움이 필요하신가요?"
                          value={inputValue}
                          onChange={handleChangeInput}
                          onKeyDown={handleOnKeyPress}
                        />
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            doQuestion(e, inputValue);
                          }}
                          target="_self"
                          className="btn_submit filled_green"
                        >
                          전송
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="wrap_btns">
                <a
                  href="#"
                  target="_self"
                  onClick={(e) => {
                    e.preventDefault();
                    modalPopup("main");
                  }}
                  className="btn_submit filled_black"
                >
                  종료하기
                </a>
                <div className="back_or_next">
                  {cateIdx > 0 && (
                    <button
                      onClick={(evt) => {
                        evt.preventDefault();

                        if (
                          prevPageButtonProps.disabled ||
                          cateType?.endsWith("Preview")
                        ) {
                          modalPopup("goToPreviousPage");
                          return;
                        }

                        if (isGamesCateType(cateType)) {
                          prevPageButtonProps.onClick(modalPopup("prev"));
                          return;
                        }

                        modalPopup("prev");
                      }}
                      className={`btn_submit lined_green ${prevPageButtonProps.disabled ? "custom_disabled" : ""}`}
                    >
                      이전으로
                    </button>
                  )}
                  {abilityResult && abilityResult.length === cateIdx + 1 ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        modalPopup("insert");
                      }}
                      className="btn_submit filled_green"
                    >
                      역량평가 종료
                    </button>
                  ) : (
                    <button
                      disabled={
                        nextPageButtonProps.disabled ||
                        cateType?.endsWith("Preview")
                      }
                      onClick={(evt) => {
                        if (isGamesCateType(cateType)) {
                          nextPageButtonProps.onClick(nextPage);
                          return;
                        }

                        nextPage(evt);
                      }}
                      className="btn_submit filled_green"
                    >
                      다음으로
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <ModalComponent />
    </>
  );
};

export default AbilityProgress;
