import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import dayjs from "dayjs";

const Language = ({
  register,
  control,
  errors,
  setValue,
  watchField,
  readOnly,
  langHistory,
}) => {
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "language", // Use the same name used in the form registration.
  });
  useEffect(() => {
    if (langHistory?.length > 0) {
      replace(
        langHistory.map((data) => ({
          language_name: data.language_name,
          language_score: data.language_score,
          language_test_name: data.language_test_name,
          language_acquisition_date: data.language_acquisition_date,
        }))
      );
    }
  }, [langHistory?.length]);
  return (
    <div className="wrap_signup_input wrap_signup_basic">
      <div className="box_sub_title">
        <h4 className="sub_title_signup">어학</h4>
      </div>

      {fields.map((item, index) => (
        <div key={item.id} className="box_language_info history_language">
          <div className="box_division">
            {!readOnly && (
              <button
                className="btn_square_black"
                type="button"
                onClick={() => remove(index)}
              ></button>
            )}

            <div className="wrap_box_input">
              <div className="box_input">
                <label htmlFor="" className="asterisk">
                  어학시험명
                </label>
                <input
                  type="text"
                  name={`language[${index}].language_test_name`}
                  placeholder="어학시험명을 입력해주세요."
                  {...register(`language[${index}].language_test_name`, {
                    required: "필수 입력 항목입니다.",
                  })}
                  readOnly={readOnly}
                  disabled={readOnly}
                ></input>
              </div>
              <p className="msg_err">
                {errors?.language?.[index]?.language_test_name?.message}
              </p>
            </div>
            <div className="wrap_box_input">
              <div className="box_input">
                <label htmlFor="" className="asterisk">
                  외국어명
                </label>
                <input
                  type="text"
                  id=""
                  name={`language[${index}].language_name`}
                  {...register(`language[${index}].language_name`, {
                    required: "필수 입력 항목입니다.",
                  })}
                  readOnly={readOnly}
                  disabled={readOnly}
                  placeholder="외국어명을 입력해주세요."
                ></input>
              </div>
              <p className="msg_err">
                {errors?.language?.[index]?.language_name?.message}
              </p>
            </div>
            <div className="wrap_box_input">
              <div className="box_input">
                <label htmlFor="" className="asterisk">
                  급수/점수
                </label>
                <input
                  type="text"
                  id=""
                  name={`language[${index}].language_score`}
                  {...register(`language[${index}].language_score`, {
                    required: "필수 입력 항목입니다.",
                  })}
                  placeholder="급수/점수를 입력해주세요."
                  readOnly={readOnly}
                  disabled={readOnly}
                ></input>
              </div>
              <p className="msg_err">
                {errors?.language?.[index]?.language_score?.message}
              </p>
            </div>
            <div className="wrap_box_input">
              <div className="box_input wrap_datepicker">
                <label htmlFor="">취득일</label>
                <input
                  type="text"
                  id="dp1690885806933"
                  className="input_datepicker hasDatepicker"
                  placeholder="년도를 입력해주세요."
                  name={`language[${index}].language_acquisition_date`}
                  {...register(`language[${index}].language_acquisition_date`)}
                  style={{ display: "none" }}
                ></input>
                <DatePicker
                  readOnly={readOnly}
                  disabled={readOnly}
                  onKeyDown={(e) => {
                    console.log(e);
                    if (
                      (e.keyCode < 48 || e.keyCode > 57) &&
                      e.keyCode !== 8 &&
                      e.keyCode !== 189
                    ) {
                      e.preventDefault();
                    }
                  }}
                  selected={
                    watchField?.language?.[index]?.language_acquisition_date
                      ? new Date(
                          watchField.language[index].language_acquisition_date
                        )
                      : null
                  }
                  onChange={(date) => {
                    date &&
                      setValue(
                        "language[" + index + "].language_acquisition_date",
                        dayjs(date).format("YYYY-MM"),
                        { shouldValidate: true }
                      );
                  }}
                  showMonthYearPicker
                  dateFormat="yyyy-MM"
                  locale={ko}
                  maxDate={new Date()}
                  placeholderText="년도를 입력해주세요."
                />
              </div>
              <p className="msg_err">
                {errors?.language?.[index]?.language_acquisition_date?.message}
              </p>
            </div>
          </div>
        </div>
      ))}

      <button
        type="button"
        readOnly={readOnly}
        disabled={readOnly}
        onClick={() => {
          append();
        }}
        className="btn_submit btn_add filled_green"
      >
        <span className="btn_text">어학 추가</span>
      </button>
    </div>
  );
};
export default Language;
