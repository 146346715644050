import { useState, useRef, useCallback, useEffect } from "react";
import { useImageFile } from "../../../hooks/useImageFile";
import { use } from "echarts";

const BasicInfo = ({ register, errors, setValue, userInfo, methods }) => {
  const [profileImageUrl, setProfileImageUrl] = useState(
    userInfo.member_info.image_file_path
  );
  const [img, setImg] = useState(null);
  const resumeRef = useRef(null);

  const setName = (image) => {
    setImg(URL.createObjectURL(image));
    setValue("profile.member_file_path", image.name);
  };

  const resizeImage = (settings) => {
    const file = settings.file;
    const onlyUrl = settings.onlyUrl;
    const maxWidth = settings.maxWidth;
    const maxHeight = settings.maxHeight;
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement("canvas");

    const dataURItoBlob = (dataURI) => {
      const bytes =
        dataURI.split(",")[0].indexOf("base64") >= 0
          ? atob(dataURI.split(",")[1])
          : unescape(dataURI.split(",")[1]);
      const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
    };

    const resize = () => {
      canvas.width = maxWidth;
      canvas.height = maxHeight;
      canvas.getContext("2d").drawImage(image, 0, 0, maxWidth, maxHeight);
      const dataUrl = canvas.toDataURL("image/jpeg");
      return dataURItoBlob(dataUrl);
    };

    return new Promise((ok, no) => {
      if (!file) {
        return;
      }
      if (!file.type.match(/image.*/)) {
        no(new Error("Not an image"));
        return;
      }

      reader.onload = (readerEvent) => {
        image.onload = () => {
          let blob = resize();
          return onlyUrl ? ok(window.URL.createObjectURL(blob)) : ok(blob);
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const uploadBase64Proxy = (e) => {
    const image = e.target.files[0];
    setValue("profile.member_file_path", image.name);
    resizeImage({ file: image, maxWidth: 150, maxHeight: 200, onlyUrl: false })
      .then((blob) => {
        uploadBase64({ target: { files: [blob] } });
      })
      .catch((err) => {
        console.log(err);
        console.log("error catched");
      });
  };

  const callback = (image) => {
    resizeImage({
      file: image,
      maxWidth: 150,
      maxHeight: 200,
      onlyUrl: true,
    }).then((url) => {
      setImg(url);
    });
  };

  const { uploadImage, deleteImage, uploadBase64 } = useImageFile(
    resumeRef,
    setImg,
    methods,
    "profile.member_image",
    callback,
    ["png", "jpg", "jpeg"]
  );

  useEffect(() => {
    setProfileImageUrl(userInfo.member_info.image_file_path);
  }, [userInfo.member_info.image_file_path]);

  /*
    const imageUpload = e => {
        e.preventDefault();

        if (e.target.files) {
            const reader = new FileReader();
            const uploadImage = e.target.files[0];
            setValue("image", uploadImage, {shouldValidate: true, shouldDirty: true})

            reader.readAsDataURL(uploadImage)
            reader.onloadend = () => {
                setImg(reader.result)
            }
        }
    } */

  const onErrorImage = (e) => {
    e.target.src = "../../../../public/img/icon/photo_thumbnail.png";
  };
  return (
    <div className="box_basic_info">
      <div className="wrap_basic_photo">
        {img !== null && (
          <div
            style={{ width: "150px", height: "200px" }}
            id="image_container"
            className="wrap_thumbnail"
          >
            <div className="box_thumbnail">
              <img
                style={{ objectFit: "cover" }}
                src={img}
                alt=""
                onError={onErrorImage}
              ></img>
            </div>
          </div>
        )}
        {img === null &&
          (profileImageUrl === "string" ? (
            <div
              style={{ width: "150px", height: "200px" }}
              id="image_container"
              className="wrap_thumbnail thumnail_null"
            >
              <div className="box_thumbnail">
                <img
                  style={{ objectFit: "cover" }}
                  src={"/img/icon/photo_thumbnail.png"}
                  alt="defaultImage"
                />
              </div>
            </div>
          ) : (
            <div
              style={{ width: "150px", height: "200px" }}
              id="image_container"
              className="wrap_thumbnail thumnail_null"
            >
              <div className="box_thumbnail">
                <img
                  style={{ objectFit: "cover" }}
                  src={profileImageUrl}
                  alt="profileImageUrl"
                />
              </div>
            </div>
          ))}
        <input
          ref={resumeRef}
          type="file"
          id="attachedFileImage"
          accept="image/*"
          onChange={uploadBase64Proxy}
        ></input>
      </div>
      <div className="wrap_basic_text">
        <div className="box_division">
          <div className="wrap_box_input">
            <div className="box_input">
              <label htmlFor="" className="asterisk">
                이름
              </label>
              <input
                type="text"
                id="profile.member_name"
                name="profile.member_name"
                value={userInfo.member_com.member_nm}
                placeholder="이름을 입력해주세요."
                {...register("profile.member_name")}
                readOnly
              ></input>
            </div>
            <p className="msg_err">{errors?.profile?.member_name?.message}</p>
          </div>
          <div className="wrap_box_input">
            <div className="box_input">
              <label htmlFor="" className="asterisk">
                전화번호
              </label>
              <input
                type="tel"
                id="profile.member_phone"
                name="profile.member_phone"
                placeholder="전화번호를 입력해 주세요."
                value={userInfo.member_info.phone_no}
                {...register("profile.member_phone")}
                readOnly
              ></input>
            </div>
            <p className="msg_err">{errors?.profile?.member_phone?.message}</p>
          </div>
        </div>
        <div className="box_division">
          <div className="wrap_box_input">
            <div className="box_input">
              <label htmlFor="" className="asterisk">
                생년월일
              </label>
              <input
                type="text"
                id="profile.member_birthdate"
                name="profile.member_birthdate"
                value={userInfo.disabili_certifica.birthday}
                placeholder="생년월일을 입력해 주세요. (예시: 1991-08-03)"
                {...register("profile.member_birthdate")}
                readOnly
              ></input>
            </div>
            <p className="msg_err">
              {errors?.profile?.member_birthdate?.message}
            </p>
          </div>
          <div className="wrap_box_input">
            <div className="box_input">
              <label htmlFor="" className="asterisk">
                E-mail
              </label>
              <input
                type="text"
                id="profile.member_email"
                name="profile.member_email"
                placeholder="E-mail을 입력해 주세요."
                value={userInfo.member_com.email}
                {...register("profile.member_email")}
                readOnly
              ></input>
            </div>
            <p className="msg_err">{errors?.profile?.member_email?.message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
