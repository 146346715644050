import { useMemo } from "react";
import "../quiz.css";
import NoticeTemplate from "../common/NoticeTemplate";
import Tip from "../common/Tip";
import { useGameTimer } from "../../../hooks/useGameTimer";
import { INITIAL_COUNTDOWN_SECONDS } from "../games";

export default function BlockQuizPreview({ cateIdx, nextPage }) {
  const { GameTimerComponent } = useGameTimer({
    initialDurationInSeconds: INITIAL_COUNTDOWN_SECONDS,
  });

  const previewGrid = useMemo(
    () =>
      Array.from({ length: Math.pow(4, 2) }).map((i, j) => (
        <div
          key={j}
          className={`block-card ${
            [2, 5, 8, 13].includes(j) ? "selected" : undefined
          }`}
        />
      )),
    []
  );

  return (
    <div className="quiz-preview">
      <NoticeTemplate
        title="게임형 평가 시작전 안내사항"
        list={[
          "1. 문항형 평가와 달리 게임형 평가는 시간제한이 있으며 멈춤없이 진행됩니다.",
          "2. 문항형 평가와 달리 게임형 평가는 해당문제의 제한시간 종료후 [다음으로] 기능이 활성화되며, 답변수정 불가하니 신중한 답변을 바랍니다.",
        ]}
      />

      <GameTimerComponent />

      <div className="quiz flex h-full">
        <div className="quiz-assets">
          <div className="block-grid result">{previewGrid}</div>
        </div>
        <div className="flex_column">
          <h3>Q. 블록의 위치를 마우스로 클릭하세요. (순서무관)</h3>
          <div className="wrap_btns">
            <button onClick={nextPage} className="btn_submit filled_black">
              게임시작
            </button>
          </div>
        </div>
      </div>

      <Tip top={400} right={200} arrowDirection="left-bottom">
        1. 5초 동안 점등 On된 블록들의 위치를 기억하세요.
        <br />
        2. 점등 OFF되면 기억나는 대로 블록의 위치를 클릭하세요.
        <br />
        3. 제한시간 전까지 재클릭을 통해 블록 선택 수정이 가능합니다.
        <br />
        4. 점등된 블록은 총 4개이며, 5번째 클릭 시 알람 노출되며
        <br />
        점등 해제 후 새로운 블록을 클릭하세요.
      </Tip>
      <Tip top={215} right={110} arrowDirection="left-top">
        게임 규칙을 이해 완료하였으면
        <br />
        버튼 클릭 후 게임을 시작하세요.
      </Tip>
      {Boolean(cateIdx) && (
        <Tip bottom={-65} right={200} arrowDirection="bottom-right">
          제한시간 이후 버튼 클릭 가능하며,
          <br />
          클릭완료한 블록 확인은 가능하나
          <br />
          답변 수정은 불가합니다.
        </Tip>
      )}
      <Tip bottom={-65} right={-100} arrowDirection="bottom-left">
        버튼 클릭 전까지 입력값 수정
        <br />
        가능하며, 제한시간이 종료된
        <br />
        경우 정답 입력 및 수정이
        <br />
        불가능합니다.
      </Tip>
    </div>
  );
}
