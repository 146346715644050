import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {conditionedSearch} from "../../../api/User/Jop/Job";
import Pagination from "./Pagination";
import SearchCondition from "./SearchCondition";

const init = {
    disability: {
        handicapped_type: [
            {
                add_disabled: [
                    {
                        disability_name: ""
                    }
                ]
            }
        ]
    },
    disableNameData: []
}

const JobSearch = (props) => {
    const { jobList, setJobList, checkedInter, handleInterest, calculatedDays, toDetailPage, checkedDepth1th } = props;
    const methods = useForm({
        mode: 'onChange',
        defaultValues: init
    })
    const { register, watch, setValue, setError, handleSubmit, formState: { errors, isValid } } = methods;

    // pagination
    const [visiblePageCount, setVisiblePageCount] = useState(10);
    const [page, setPage] = useState(1);
    const offset = (page - 1) * visiblePageCount;
    //
    const [sortEnterNm, setSortEnterNm] = useState(true);
    const [sortRecruitNm, setSortRecruitNm] = useState(true);
    const [sortEndDt, setSortEndDt] = useState(1);
    const watchField = watch();

    function formatDate(dateString) {
        const dateObj = new Date(dateString);
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        const weekday = dateObj.toLocaleDateString('ko-KR', { weekday: 'short' });
        return `~ ${month}/${day} (${weekday})`;
    }

    const sortEnterName = () => {
        let temp = [...jobList]
        temp.sort((a, b) =>
            sortEnterNm === true ? a.enterprise.enterprise_nm.localeCompare(b.enterprise.enterprise_nm) : b.enterprise.enterprise_nm.localeCompare(a.enterprise.enterprise_nm)
        );
        setSortEnterNm(!sortEnterNm)
        setJobList(temp);
    }

    const sortRecruitName = () => {
        let temp = [...jobList]
        temp.sort((a, b) =>
            sortRecruitNm === true ? a.incruit.recruit_name.localeCompare(b.incruit.recruit_name) : b.incruit.recruit_name.localeCompare(a.incruit.recruit_name)
        );
        setSortRecruitNm(!sortRecruitNm)
        setJobList(temp);
    }

    const sortEndDate = () => {
        let temp = [...jobList]
        if (sortEndDt === 1) {
            temp.sort((a, b) => {
                if (a.incruit.employment_type === "상시 채용" && b.incruit.employment_type !== "상시 채용") return -1;
                if (a.incruit.employment_type === "채용 시 마감" && b.incruit.employment_type === "") return -1;
                if (a.incruit.employment_type === "" && b.incruit.employment_type !== "") return 1;
                if (a.incruit.employment_type === "" && b.incruit.employment_type === "") {
                    return -(calculatedDays(a.incruit.recruit_end_date) - calculatedDays(b.incruit.recruit_end_date));
                }
                return 0;
            });
        } else {
            temp.sort((a, b) => {
                if(a.incruit.employment_type === "" && b.incruit.employment_type !== "") return -1;
                if (a.incruit.employment_type === "" && b.incruit.employment_type === "") {
                    return (calculatedDays(a.incruit.recruit_end_date) - calculatedDays(b.incruit.recruit_end_date));
                }
                if (a.incruit.employment_type === "채용 시 마감" && b.incruit.employment_type === "상시 채용") return -1;
                if (a.incruit.employment_type === "상시 채용" && b.incruit.employment_type !== "상시 채용") return 1;
                return 0;
            })
        }
        setSortEndDt(-sortEndDt)
        setJobList(temp);
    }

    const onSubmit = async (data) => {
        try {
            data.disability.handicapped_type[0].add_disabled = data.disableNameData.map((disabled_name) => ({disabled_name}))

            conditionedSearch(data).then((response) => {
                if(response.data.resultCode === 1000 && response.data.resultData) {
                    const temp = [...response.data.resultData]
                    temp.sort((a, b) => {
                        if(a.incruit.employment_type === "" && b.incruit.employment_type !== "") return -1;
                        if(a.incruit.employment_type === "채용 시 마감" && b.incruit.employment_type === "상시 채용") return -1 ;
                        if(a.incruit.employment_type === "상시 채용" && b.incruit.employment_type === "채용 시 마감") return 1;
                        return 0;
                    });
                    setJobList(temp)
                }
            })
        } catch(e) {
            console.log(e);
        }
    }
    
    return (
        <div className=" search_detail content_03">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SearchCondition register={register} watchFeild={watchField} setValue={setValue}/>
                </form>
            </FormProvider>
            <div className="area_shade">
                <div className="section_title">
                    <h3>채용정보</h3>
                </div>
                <div className="wrap_search_detail table_init">
                    <table className="table_detail sort_column">
                        <colgroup>
                            <col style={{"width":"180px"}}/>
                            <col style={{"width":"auto"}}/>
                            <col style={{"width":"240px"}}/>
                            <col style={{"width":"115px"}}/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th scope="col" className={`header_sort01 sort_down ${sortEnterNm === false ? 'rotate' : ''}`} onClick={sortEnterName}>기업명</th>
                            <th scope="col" className={`header_sort02 sort_down ${sortRecruitNm === false ? 'rotate' : ''}`} onClick={sortRecruitName}>공고명</th>
                            <th scope="col" className="no_sort">지원가능 장애유형</th>
                            <th scope="col" className={`header_sort04 sort_down ${sortEndDt === 1 ? '' : 'rotate'}`} onClick={sortEndDate}>마감일순</th>
                        </tr>
                        </thead>
                            {
                                jobList.length > 0 && (
                                    <tbody>
                                        { jobList.slice(offset, offset+visiblePageCount).map((data, i) => (
                                                <tr key={data.enterprise.seq}>
                                                    <td className="align_left">
                                                        <div className="td_cont_wrap">
                                                            <div>
                                                                <input type="checkbox" className="add_interest_list" onClick={handleInterest} value={data.enterprise.seq}
                                                                       defaultChecked={checkedInter(data.enterprise.seq) === true ? true : false}/>
                                                                <a href="#none" target="_self">{data.enterprise.enterprise_nm}</a>
                                                            </div>
                                                            {
                                                                checkedDepth1th(data.enterprise.seq) === true &&
                                                                <span className="txt_icon txt_icon_red">추천 채용정보</span>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="align_left">
                                                        <div className="td_cont_wrap">
                                                            <a onClick={() => {toDetailPage(data)}}>
                                                                {data.incruit.recruit_name}
                                                            </a>
                                                            <p>직무: <span>{data.desire?.depth3_name}</span></p>
                                                            <div className="wrap_txt_icon ellipsis">
                                                                {
                                                                    data.condition.employment_condition !== "" &&
                                                                    <span className="txt_icon txt_icon_purple">{data.condition.employment_condition.includes("경력") ? data.condition.employment_condition.split("|")[0] : data.condition.employment_condition}</span>
                                                                }
                                                                {
                                                                    data.desire ?
                                                                    <span className="txt_icon txt_icon_blue">{data.desire?.depth1_name.split(".")[1]}</span> : null
                                                                }
                                                                {
                                                                    data.condition.education !== "" &&
                                                                    <span className="txt_icon txt_icon_green">{data.condition.education}</span>
                                                                }
                                                                {
                                                                    data.condition.duty_type !== "" &&
                                                                    <span className="txt_icon txt_icon_orange">{data.condition.duty_type}</span>
                                                                }
                                                                <span className="txt_icon txt_icon_pink">
                                                                    {   data.condition.remote_work === "재택근무" ? (
                                                                            data.condition.remote_work
                                                                        ) : (
                                                                            data.condition.work_location?.split(" ")[0] + " " + data.condition.work_location?.split(" ")[1]
                                                                        )
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align_left">
                                                        <div className="td_cont_wrap">
                                                            <input type="checkbox" className="toggle_btn"></input>
                                                            <div>
                                                                <p>
                                                                    {
                                                                        data.disability.handicapped_type.map((handi) =>
                                                                            (
                                                                                handi.disability_status === "Y" ? (
                                                                                    handi.add_disabled.map((add, i) => (
                                                                                            `${i !== 0 ? ", " : ""}` + add.disabled_name
                                                                                        )
                                                                                    )
                                                                                ) : null
                                                                            )
                                                                        )
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            { data.incruit.employment_type !== "상시 채용" && data.incruit.employment_type !== "채용 시 마감" ? (
                                                                    <p className="txt_icon txt_icon_scarlet">{"D - " + calculatedDays(data.incruit.recruit_end_date)}</p>
                                                                ) : (
                                                                    <p className="txt_icon txt_icon_scarlet" style={{fontSize: "13px"}}>{data.incruit.employment_type}</p>
                                                                )
                                                            }
                                                            {
                                                                data.incruit.employment_type !== "상시 채용" && data.incruit.employment_type !== "채용 시 마감" ? (
                                                                <p>
                                                                    {formatDate(data.incruit.recruit_end_date)}
                                                                </p>
                                                                ) : null
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                )
                            }
                    </table>
                    <div className="wrap_pagination">
                        <Pagination
                            total={jobList.length}
                            visiblePageCount={visiblePageCount}
                            page={page}
                            setPage={setPage}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default JobSearch;
