import React, { useEffect, useState } from "react";
import ApplicantProfile from "./applicantProfile";
import ApplicantAbilityResult from "./applicantAbilityResult";
import ApplicantAbilityItemScore from "./applicantAbilityItemScore";
import InsertInterviewModal from "../interview/InsertInterviewModal";
import ApplicantEdu from "./applicantEdu";

const ApplicantInfoModal = (props) => {
  const [interviewModal, setInterviewModal] = useState(false);
  const [recruitSeq, setRecruitSeq] = useState(false);
  const [resumeSeq, setResumeSeq] = useState(false);
  const [currTab, setCurrTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
    tab4: false,
  });

  const [evalVal, setEvalVal] = useState(
    props.result?.announce?.comp_evaluation
      ? props.result?.announce?.comp_evaluation
      : "1"
  );
  const [changeVal, setChangeVal] = useState(null);

  const changeTab = (tab) => {
    let tabState = {
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: false,
    };
    tabState[tab] = true;
    setCurrTab(tabState);
  };

  const calcAge = (birthday) => {
    let date = new Date();
    let year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    let monthDay = month + day;

    let birth = birthday.replace("-", "").replace("-", "");
    let birthY = birth.substr(0, 4);
    let birthMd = birth.substr(4, 4);

    let age = monthDay < birthMd ? year - birthY - 1 : year - birthY;
    return age;
  };

  const disableModal = () => {
    props.setModal(false);
  };

  const handleChange = (e, annNum, recNum, resNum) => {
    if (e.target.value !== "3") {
      let param = {
        comp_evaluation: e.target.value,
        recruit_seq: recNum,
        announcement_seq: annNum,
      };
      setChangeVal(e.target.value);
      props.setUpdateParam(param);
      props.setConfirmModalType("confirm");
      props.setConfirmModal(true);
    } else if (e.target.value == 3) {
      setResumeSeq(resNum);
      setRecruitSeq(recNum);
      setInterviewModal(true);
    }
  };

  useEffect(() => {
    props.updateChk && setEvalVal(changeVal);
  }, [props.updateChk]);

  const setDefaultImg = (e) => {
    if (
      e.target.src != null &&
      e.target.src.indexOf("/img/icon/icon_profile.png") == -1
    ) {
      e.target.src = "/img/icon/icon_profile.png";
    }
  };

  return (
    <>
      <div
        className="popup_mask"
        style={{ display: "block" }}
        onClick={disableModal}
      ></div>
      <div
        className="popup_layer pop_lg popLResultEvaluation"
        id="popLApplicantInfo"
        style={{ display: "block", width: 1700, maxWidth: 1700 }}
      >
        <div className="popup_inner">
          <div className="pop_title">
            <h3>지원자 정보 상세보기</h3>
            <button
              type="button"
              aria-label="레이어 팝업 닫기"
              onClick={disableModal}
              className="popup_close"
            ></button>
          </div>
          <div className="popup_contents profile_detail">
            <div className="profile_top">
              <div className="profile_top_img">
                <img
                  src={props?.result?.profile?.profile?.member_image}
                  onError={setDefaultImg}
                  alt="회원 이미지"
                />
              </div>
              <div className="profile_top_txt">
                <div>
                  <div className="profile_name">
                    <h4>
                      {props.result &&
                        props.result.user &&
                        props.result.user.member_com &&
                        props.result.user.member_com.member_nm}
                    </h4>
                    <p>
                      <span
                        className="gender"
                        dangerouslySetInnerHTML={{
                          __html:
                            props.result &&
                            props.result.user &&
                            props.result.user.disabili_certifica &&
                            props.result.user.disabili_certifica.sex_gb,
                        }}
                      ></span>
                      <span
                        className="birth"
                        dangerouslySetInnerHTML={{
                          __html:
                            props.result &&
                            props.result.user &&
                            props.result.user.disabili_certifica &&
                            props.result.user.disabili_certifica.birthday,
                        }}
                      ></span>
                      (만{" "}
                      <span
                        className="age"
                        dangerouslySetInnerHTML={{
                          __html:
                            props.result &&
                            props.result.user &&
                            props.result.user.disabili_certifica &&
                            props.result.user.disabili_certifica.birthday &&
                            calcAge(
                              props.result.user.disabili_certifica.birthday
                            ),
                        }}
                      ></span>
                      세)
                    </p>
                  </div>
                  <div className="profile_btn">
                    <select
                      onChange={(e) => {
                        e.preventDefault();
                        handleChange(
                          e,
                          props.result?.announce?.announcement_seq,
                          props.result?.announce?.recruit_seq,
                          props.result?.announce?.resume_seq
                        );
                      }}
                      value={evalVal}
                      className={
                        props.result?.announce?.comp_evaluation === "7"
                          ? "readonly"
                          : ""
                      }
                    >
                      <option value={1}>서류검토</option>
                      <option value={2}>서류탈락</option>
                      <option value={3}>면접진행</option>
                      <option value={4}>면접탈락</option>
                      <option value={5}>비면접합격</option>
                      <option value={6}>최종합격</option>
                      {props.result?.announce?.comp_evaluation === "7" && (
                        <option value={7}>지원취소</option>
                      )}
                    </select>
                  </div>
                </div>
                <div className="profile_info">
                  <table>
                    <tr>
                      <th>전화번호</th>
                      <td>
                        {props.result &&
                          props.result.user &&
                          props.result.user.member_info &&
                          props.result.user.member_info.phone_no}
                      </td>
                    </tr>
                    <tr>
                      <th>주소</th>
                      <td className="address-cell">
                        {props.result &&
                          props.result.user &&
                          props.result.user.disabili_certifica &&
                          props.result.user.disabili_certifica.address && (
                            <>
                              {props.result.user.disabili_certifica.address
                                .split("|")
                                .map((addressPart, index) => (
                                  <div key={index}>{addressPart.trim()}</div>
                                ))}
                            </>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th>이메일</th>
                      <td>
                        {props.result &&
                          props.result.user &&
                          props.result.user.member_com &&
                          props.result.user.member_com.email}
                      </td>
                    </tr>
                    <tr>
                      <th>주장애 및 장애정도</th>
                      <td>
                        {props.result &&
                          props.result.user &&
                          props.result.user.disabili_certifica &&
                          props.result.user.disabili_certifica
                            .major_disability_nm}
                      </td>
                    </tr>
                  </table>
                  <div className="recommended-job-role">
                    <h3>추천직무</h3>
                    <table>
                      <tr>
                        <th>1순위</th>
                        <td>
                          {props.result &&
                            props.result.jobMatching &&
                            props.result.jobMatching.job1}
                        </td>
                      </tr>
                      <tr>
                        <th>2순위</th>
                        <td>
                          {props.result &&
                            props.result.jobMatching &&
                            props.result.jobMatching.job2}
                        </td>
                      </tr>
                      <tr>
                        <th>3순위</th>
                        <td>
                          {props.result &&
                            props.result.jobMatching &&
                            props.result.jobMatching.job3}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile_detail_tab">
              <div className="btn_tab">
                <ul>
                  <li>
                    <button
                      data-tag="btn1"
                      className={currTab.tab1 ? "current" : ""}
                      onClick={(e) => {
                        changeTab("tab1");
                      }}
                    >
                      이력서
                    </button>
                  </li>
                  <li>
                    <button
                      data-tag="btn2"
                      className={currTab.tab2 ? "current" : ""}
                      onClick={(e) => {
                        changeTab("tab2");
                      }}
                    >
                      역량평가 결과
                    </button>
                  </li>
                  <li>
                    <button
                      data-tag="btn3"
                      className={currTab.tab3 ? "current" : ""}
                      onClick={(e) => {
                        changeTab("tab3");
                      }}
                    >
                      역량평가 답변보기
                    </button>
                  </li>
                  <li>
                    <button
                      data-tag="btn4"
                      className={currTab.tab4 ? "current" : ""}
                      onClick={(e) => {
                        changeTab("tab4");
                      }}
                    >
                      추천교육
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            {currTab.tab1 && (
              <ApplicantProfile
                profile={props.result && props.result.profile}
                disabili_certifica={
                  props.result &&
                  props.result.user &&
                  props.result.user.disabili_certifica
                }
                equipment_st={
                  props.result &&
                  props.result.user &&
                  props.result.user.member_info &&
                  props.result.user.member_info.equipment_st
                }
              />
            )}
            {currTab.tab2 && (
              <ApplicantAbilityResult
                abilityResult={
                  props.result &&
                  props.result.abilityResult &&
                  props.result.abilityResult.result
                }
                abilityComment={
                  props.result &&
                  props.result.abilityResult &&
                  props.result.abilityResult.comment
                }
                userNm={
                  props.result &&
                  props.result.user &&
                  props.result.user.member_com &&
                  props.result.user.member_com.member_nm
                }
              />
            )}
            {currTab.tab3 && (
              <ApplicantAbilityItemScore resultData={props.result} />
            )}
            {currTab.tab4 && (
              <ApplicantEdu
                userNm={
                  props.result &&
                  props.result.user &&
                  props.result.user.member_com &&
                  props.result.user.member_com.member_nm
                }
                userClass={props?.result?.userClass}
              />
            )}
          </div>
        </div>
      </div>

      {interviewModal && (
        <InsertInterviewModal
          setInterviewModal={setInterviewModal}
          recruitSeq={recruitSeq}
          resumeSeq={resumeSeq}
        />
      )}
    </>
  );
};

export default ApplicantInfoModal;
