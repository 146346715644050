import {useState} from "react";

const DesiredJob = (props) => {
    const { allInterJobList, setAllInterJobList, user, checkedInter, handleInterest, calculatedDays, toDetailPage} = props;

    function formatDate(dateString) {
        const dateObj = new Date(dateString);
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        const weekday = dateObj.toLocaleDateString('ko-KR', { weekday: 'short' });
        return `${month}/${day} (${weekday})`;
    }

    const onErrorImg = (e) => {
        e.target.src = "/img/bg/aside_left_enterprise_login.png"
        e.target.style.height = "105px"
    }

    return (
        <div className="applicant_list content_02">
            <h3 className="content_inner_title"><span>{user?.username}</span>님의 관심 채용정보입니다.</h3>
            <div className="area_shade">
                <div className="section_job_list">
                    {
                        allInterJobList.length > 0 ? allInterJobList.map((data, index) => (
                            index % 4 === 0 ? (
                                <ul className="job_list" key={index}>
                                    {allInterJobList.slice(index, index + 4).map((item, innerIndex) => (
                                        <li className={`job_list_item${item.incruit.recruit_status === "공고중" && (calculatedDays(item.incruit.recruit_end_date) >= 0 || item.incruit.employment_type !== "") ? '' : ' item_closure'}`} key={item.enterprise.seq}>
                                            <div href="#none" target="_self" className="job_item_box">
                                                <div className="box_top">
                                                    <p><span>
                                                        {
                                                            (
                                                                calculatedDays(item.incruit.recruit_end_date) >= 0 && item.incruit.employment_type === "") ? ('D-' + calculatedDays(item.incruit.recruit_end_date)
                                                            ) : (
                                                                item.incruit.employment_type !== "" ? (item.incruit.employment_type) : ("D+" + calculatedDays(item.incruit.recruit_end_date).toString().substring(1))
                                                            )
                                                        }
                                                    </span></p>
                                                    <div className="box_chk_icon">
                                                        <input type="checkbox" className="add_interest_list" onClick={handleInterest} value={item.enterprise.seq}
                                                               defaultChecked={checkedInter(item.enterprise.seq) === true ? true : false}/>
                                                    </div>
                                                </div>
                                                <div className="wrap_company_logo">
                                                    <img src={item.comInfo.file_path} alt="" onError={onErrorImg}/>
                                                </div>
                                                <h4 className="job_list_title">{item.enterprise.enterprise_nm}</h4>
                                                <div className="box_detail">
                                                    <div className="wrap_detail_line">
                                                        <p>채용인원: <span>{item.incruit.position_num}</span>{item.incruit.position_num === '미정' ? null : '명'}</p>
                                                        <p>채용조건: <span>{item.condition.employment_condition}</span></p>
                                                    </div>
                                                    <div className="wrap_detail_line" style={{ marginBottom: "unset", height: "32px"}}>
                                                        <p>채용직무: <span>{item.desire?.depth3_name.split(".")[1]}</span></p>
                                                        <p>근무형태: <span>{item.condition.duty_type}</span></p>
                                                    </div>
                                                    <div className="wrap_detail_line">
                                                        <p>마감일: { (item.incruit.employment_type !== "상시 채용" && item.incruit.employment_type !== "채용 시 마감") ? formatDate(item.incruit.recruit_end_date) : item.incruit.employment_type} </p>
                                                    </div>
                                                </div>
                                                <div className="box_badge" style={{ position: "relative", bottom: "8px"}}>
                                                    <a target="_self" className="badge_btn badge_task btn"
                                                       onClick={() => {toDetailPage(item)}}>채용정보 자세히 보기</a>

                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : null
                        )) : (
                            <p className="no_content_notice" style={{marginTop: "unset"}}>현재 <span>관심 채용정보</span>가 없습니다. </p>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default DesiredJob;