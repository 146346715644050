import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { registerLocale } from "react-datepicker";
import React from "react";
import dayjs from "dayjs";
import { AutoComplete } from "./Education";
registerLocale("ko", ko);

const Graduate = ({
  register,
  index,
  removeGraduate,
  errors,
  autoComplete,
  setFocus,
  watchField,
  setValue,
  readOnly,
}) => {
  return (
    <div key={index} className="box_education_info">
      <h5 className="history_education_detail">대학원</h5>
      {!readOnly && (
        <button
          className="btn_square_black"
          type="button"
          onClick={removeGraduate}
        ></button>
      )}

      <div className="box_division">
        <div className="wrap_box_input">
          <div className="box_input">
            <label htmlFor="" className="asterisk">
              입학상태
            </label>
            <select
              name={`education.graduate[${index}].admission_status`}
              id={`education.graduate[${index}].admission_status`}
              {...register(`education.graduate[${index}].admission_status`, {
                required: "필수 입력 항목입니다.",
              })}
              className="select"
              disabled={readOnly}
              readOnly={readOnly}
            >
              <option value="" hidden={true}>
                입학상태를 선택해주세요.
              </option>
              <option value="입학">입학</option>
              <option value="편입">편입</option>
            </select>
          </div>
          {errors?.education?.graduate &&
            index < errors?.education?.graduate.length && (
              <p className="msg_err">
                {errors?.education?.graduate[index]?.admission_status?.message}
              </p>
            )}
        </div>
        <div className="wrap_box_input">
          <div className="box_input">
            <label htmlFor="" className="asterisk">
              학교명
            </label>
            <input
              type="text"
              name={`education.graduate[${index}].school_name`}
              id={`education.graduate[${index}].school_name`}
              placeholder="대학교 이름을 입력해주세요."
              disabled={readOnly}
              readOnly={readOnly}
              {...register(`education.graduate[${index}].school_name`, {
                required: "필수 입력 항목입니다.",
              })}
            ></input>
          </div>
          {errors?.education?.graduate &&
            index < errors?.education?.graduate.length && (
              <p className="msg_err">
                {errors?.education?.graduate[index]?.school_name?.message}
              </p>
            )}
        </div>
        <div className="wrap_box_input">
          <AutoComplete
            name={`education.graduate[${index}].major`}
            id={`education.graduate[${index}].major`}
            inputValue={watchField.education.graduate[index].major}
            placeholder="전공을 입력해 주세요."
            register={register(`education.graduate[${index}].major`, {
              required: "필수 입력 항목입니다.",
            })}
            autoCompleteInfo={autoComplete}
            group={"G"}
            setv={setValue}
            setFocus={setFocus}
            readOnly={readOnly}
          />
          {errors?.education?.graduate &&
            index < errors?.education?.graduate.length && (
              <p className="msg_err">
                {errors?.education?.graduate[index]?.major?.message}
              </p>
            )}
        </div>
        <div className="wrap_box_input">
          <div className="box_input">
            <label htmlFor="" className="asterisk">
              계열
            </label>
            <select
              name={`education.graduate[${index}].affiliation`}
              id={`education.graduate[${index}].affiliation`}
              className="select"
              disabled={readOnly}
              readOnly={readOnly}
              {...register(`education.graduate[${index}].affiliation`, {
                required: "필수 입력 항목입니다.",
              })}
            >
              <option value="" hidden={true}>
                계열을 선택해주세요.
              </option>
              <option value="공과">공과</option>
              <option value="인문대학">인문대학</option>
              <option value="사회과학">사회과학</option>
              <option value="자연과학">자연과학</option>
              <option value="생활과학">생활과학</option>
              <option value="경영">경영</option>
              <option value="경제">경제</option>
              <option value="금융">금융</option>
              <option value="예술">예술</option>
              <option value="체육">체육</option>
              <option value="사범">사범</option>
              <option value="국제">국제</option>
              <option value="의과">의과</option>
              <option value="간호학">간호학</option>
              <option value="기타">기타</option>
            </select>
          </div>
          {errors?.education?.graduate &&
            index < errors?.education?.graduate.length && (
              <p className="msg_err">
                {errors?.education?.graduate[index]?.affiliation?.message}
              </p>
            )}
        </div>
      </div>
      <div className="box_division">
        <div className="wrap_box_input">
          <div className="box_input wrap_datepicker">
            <label htmlFor="" className="asterisk">
              입학년월
            </label>
            <input
              type="text"
              name={`education.graduate[${index}].admission_date`}
              id={`education.graduate[${index}].admission_date`}
              className="input_datepicker hasDatepicker"
              placeholder="년도를 입력해주세요."
              {...register(`education.graduate[${index}].admission_date`, {
                required: "필수 입력 항목입니다.",
              })}
              style={{ display: "none" }}
            ></input>
            <DatePicker
              disabled={readOnly}
              readOnly={readOnly}
              onKeyDown={(e) => {
                console.log(e);
                if (
                  (e.keyCode < 48 || e.keyCode > 57) &&
                  e.keyCode !== 8 &&
                  e.keyCode !== 189
                ) {
                  e.preventDefault();
                }
              }}
              selected={
                watchField?.education?.graduate[index]?.admission_date
                  ? new Date(
                      watchField.education.graduate[index].admission_date
                    )
                  : null
              }
              onChange={(date) => {
                date &&
                  setValue(
                    "education.graduate[" + index + "].admission_date",
                    dayjs(date).format("YYYY-MM"),
                    { shouldValidate: true }
                  );
              }}
              showMonthYearPicker
              dateFormat="yyyy-MM"
              locale={ko}
              maxDate={
                watchField?.education?.graduate[index]?.graduate_year <
                new Date()
                  ? watchField?.education?.graduate[index]?.graduate_year
                  : new Date()
              }
              placeholderText="년도를 입력해주세요."
            />
          </div>
          {errors?.education?.graduate &&
            index < errors?.education?.graduate.length && (
              <p className="msg_err">
                {errors?.education?.graduate[index]?.admission_date?.message}
              </p>
            )}
        </div>
        <div className="wrap_box_input">
          <div className="box_input">
            <label htmlFor="" className="asterisk">
              학위
            </label>
            <select
              name={`education.graduate[${index}].degree`}
              id={`education.graduate[${index}].degree`}
              className="select"
              disabled={readOnly}
              readOnly={readOnly}
              {...register(`education.graduate[${index}].degree`, {
                required: "필수 입력 항목입니다.",
              })}
            >
              <option value="" hidden={true}>
                학위를 선택해주세요.
              </option>
              <option value="석사">석사</option>
              <option value="박사">박사</option>
              <option value="석박사">석박사</option>
            </select>
          </div>
          {errors?.education?.graduate &&
            index < errors?.education?.graduate.length && (
              <p className="msg_err">
                {errors?.education?.graduate[index]?.degree?.message}
              </p>
            )}
        </div>
        <div className="wrap_box_input">
          <div className="box_input">
            <label htmlFor="" className="asterisk">
              졸업상태
            </label>
            <select
              name={`education.graduate[${index}].graduate_status`}
              id={`education.graduate[${index}].graduate_status`}
              className="select"
              disabled={readOnly}
              readOnly={readOnly}
              {...register(`education.graduate[${index}].graduate_status`, {
                required: "필수 입력 항목입니다.",
              })}
            >
              <option value="" hidden={true}>
                졸업상태를 선택해주세요.
              </option>
              <option value="졸업">졸업</option>
              <option value="졸업예정">졸업예정</option>
              <option value="재학중">재학중</option>
              <option value="휴학중">휴학중</option>
              <option value="수료">수료</option>
              <option value="중퇴">중퇴</option>
            </select>
          </div>
          {errors?.education?.graduate &&
            index < errors?.education?.graduate.length && (
              <p className="msg_err">
                {errors?.education?.graduate[index]?.graduate_status?.message}
              </p>
            )}
        </div>
        {(watchField.education.graduate[index].graduate_status === "" ||
          watchField.education.graduate[index].graduate_status === "졸업" ||
          watchField.education.graduate[index].graduate_status === "수료") && (
          <>
            <div className="wrap_box_input">
              <div className="box_input wrap_datepicker">
                <label htmlFor="" className="asterisk">
                  졸업년월
                </label>
                <input
                  type="text"
                  name={`education.graduate[${index}].graduate_year`}
                  id={`education.graduate[${index}].graduate_year`}
                  className="input_datepicker hasDatepicker"
                  placeholder="년도를 입력해주세요."
                  {...register(`education.graduate[${index}].graduate_year`, {
                    required: "필수 입력 항목입니다.",
                  })}
                  style={{ display: "none" }}
                ></input>
                <DatePicker
                  disabled={readOnly}
                  readOnly={readOnly}
                  onKeyDown={(e) => {
                    console.log(e);
                    if (
                      (e.keyCode < 48 || e.keyCode > 57) &&
                      e.keyCode !== 8 &&
                      e.keyCode !== 189
                    ) {
                      e.preventDefault();
                    }
                  }}
                  selected={
                    watchField?.education?.graduate[index]?.graduate_year
                      ? new Date(
                          watchField.education.graduate[index].graduate_year
                        )
                      : null
                  }
                  onChange={(date) => {
                    date &&
                      setValue(
                        "education.graduate[" + index + "].graduate_year",
                        dayjs(date).format("YYYY-MM"),
                        { shouldValidate: true }
                      );
                  }}
                  showMonthYearPicker
                  dateFormat="yyyy-MM"
                  locale={ko}
                  maxDate={new Date()}
                  minDate={
                    new Date(
                      watchField.education.graduate[index].admission_date
                    )
                  }
                  placeholderText="년도를 입력해주세요."
                />
              </div>
              {errors?.education?.graduate &&
                index < errors?.education?.graduate.length && (
                  <p className="msg_err">
                    {errors?.education?.graduate[index]?.graduate_year?.message}
                  </p>
                )}
            </div>
          </>
        )}
      </div>
      <div className="box_division">
        <div className="wrap_box_input">
          <div className="box_input">
            <label htmlFor="" className="asterisk">
              학점
            </label>
            <input
              type="text"
              name={`education.graduate[${index}].grade`}
              id={`education.graduate[${index}].grade`}
              placeholder="학점을 입력해 주세요."
              disabled={readOnly}
              readOnly={readOnly}
              {...register(`education.graduate[${index}].grade`, {
                required: "필수 입력 항목입니다.",
                min: 0,
                max: parseFloat(
                  watchField.education.graduate[index].total_grade
                ),
                validate: (value) =>
                  !isNaN(parseFloat(value)) || "올바른 숫자를 입력해주세요.",
              })}
            ></input>
          </div>
          {errors?.education?.graduate &&
            index < errors?.education?.graduate.length && (
              <p className="msg_err">
                {errors?.education?.graduate[index]?.grade?.message}
              </p>
            )}
        </div>
        <div className="wrap_box_input">
          <div className="box_input">
            <label htmlFor="" className="asterisk">
              총점
            </label>
            <select
              name={`education.graduate[${index}].total_grade`}
              id={`education.graduate[${index}].total_grade`}
              className="select"
              defaultValue={4.5}
              placeholder="총점을 입력해 주세요."
              disabled={readOnly}
              readOnly={readOnly}
              {...register(`education.graduate[${index}].total_grade`, {
                required: "필수 입력 항목입니다.",
              })}
            >
              <option value="" hidden={true}>
                총점을 선택해주세요.
              </option>
              <option value="4.5">4.5</option>
              <option value="4.3">4.3</option>
              <option value="4.0">4.0</option>
              <option value="100">100</option>
            </select>
          </div>
          {errors?.education?.graduate &&
            index < errors?.education?.graduate.length && (
              <p className="msg_err">
                {errors?.education?.graduate[index]?.total_grade?.message}
              </p>
            )}
        </div>
      </div>
    </div>
  );
};
export default Graduate;
