import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import {ko} from 'date-fns/esm/locale';
import { registerLocale } from 'react-datepicker';
import dayjs from "dayjs";
registerLocale('ko', ko);


const MilitaryService = ({register,errors, watchField, setValue, readOnly}) => {

    return (
        <div className="wrap_signup_input wrap_signup_basic">
            <div className="box_sub_title">
                <h4 className="sub_title_signup">병역사항</h4>
            </div>
            <div className="box_service_info history_service">
                <div className="box_division">
                    <div className="wrap_box_input">
                        <div className="box_input">
                            <label htmlFor="" className="asterisk">병역대상</label>
                            <select id="military.military_target" name="military.military_target" className="select" {...register('military.military_target', {
                                required: '필수 입력 항목입니다.'
                            })} readOnly={readOnly} disabled={readOnly}>
                                <option value="" hidden={true}>병역대상을 선택해주세요.</option>
                                <option value="대상아님">대상아님</option>
                                <option value="군필">군필</option>
                                <option value="미필">미필</option>
                                <option value="면제">면제</option>
                                <option value="복무중">복무중</option>
                            </select>
                        </div>
                        <p className="msg_err">{errors?.military?.military_target?.message}</p>
                    </div>

                    {(watchField.military.military_target !== '대상아님' && watchField.military.military_target !== '미필' && watchField.military.military_target !== '면제') && (
                        <>
                    <div className="wrap_box_input">
                        <div className="box_input wrap_datepicker">
                            <label htmlFor="" className="asterisk">입대일</label>
                            <input type="text" id="military.military_start_date" className="input_datepicker hasDatepicker" readOnly={readOnly} disabled={readOnly}
                                   name ="military.military_start_date" placeholder="년도를 입력해주세요." style={{display: "none"}}
                                   {...register('military.military_start_date', {
                                required: '필수 입력 항목입니다.'
                            })}></input>
                            <DatePicker
                                onKeyDown={(e) => {console.log(e); if((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 8 && e.keyCode !== 189) {e.preventDefault();}}}
                                selected={watchField.military?.military_start_date ? new Date(watchField.military.military_start_date) : null} readOnly={readOnly} disabled={readOnly}
                                onChange={(date) => {date && setValue('military.military_start_date', dayjs(date).format("YYYY-MM"), {shouldValidate: true})}}
                                showMonthYearPicker dateFormat="yyyy-MM" locale={ko}
                                maxDate={watchField.military?.military_end_date < new Date() ? watchField.military?.military_end_date : new Date()}
                                placeholderText="년도를 입력해주세요." label/>
                        </div>
                        <p className="msg_err">{errors?.military?.military_start_date?.message}</p>
                    </div>
                    {watchField.military.military_target !== '복무중' && (
                    <div className="wrap_box_input">
                        <div className="box_input wrap_datepicker">
                            <label htmlFor="" className="asterisk">제대일</label>
                            <input type="text" id="military.military_end_date" className="input_datepicker hasDatepicker" readOnly={readOnly} disabled={readOnly}
                                   name ="military.military_end_date" placeholder="년도를 입력해주세요." style={{display: "none"}}
                                   {...register('military.military_end_date', {
                                required: '필수 입력 항목입니다.'
                            })}></input>
                            <DatePicker
                                onKeyDown={(e) => {console.log(e); if((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 8 && e.keyCode !== 189) {e.preventDefault();}}}
                                selected={watchField.military?.military_end_date ? new Date(watchField.military.military_end_date) : null} readOnly={readOnly} disabled={readOnly}
                                onChange={(date) => {date && setValue('military.military_end_date', dayjs(date).format("YYYY-MM"), {shouldValidate: true})}}
                                showMonthYearPicker dateFormat="yyyy-MM" locale={ko}
                                maxDate={new Date()} minDate={new Date(watchField.military?.military_start_date)}
                                placeholderText="년도를 입력해주세요." label/>

                        </div>
                        <p className="msg_err">{errors?.military?.military_end_date?.message}</p>
                    </div>
                    )}
                        </>
                        )}
                </div>
                {(watchField.military.military_target !== '대상아님' && watchField.military.military_target !== '미필' && watchField.military.military_target !== '면제') && (
                    <>
                <div className="box_division">
                    <div className="wrap_box_input">
                        <div className="box_input">
                            <label htmlFor="" className="asterisk">군별</label>
                            <select name="military.military_category" id="military.military_category" className="select" {...register('military.military_category', {
                                required: '필수 입력 항목입니다.'
                            })} readOnly={readOnly} disabled={readOnly}>
                                <option value="" hidden={true}>군별을 선택해주세요.</option>
                                <option value="육군">육군</option>
                                <option value="해군">해군</option>
                                <option value="공군">공군</option>
                                <option value="해병">해병</option>
                                <option value="의경">의경</option>
                                <option value="전경">전경</option>
                                <option value="기타">기타</option>
                                <option value="사회복무요원">사회복무요원</option>
                                <option value="의무소방">의무소방</option>
                            </select>
                        </div>
                        <p className="msg_err">{errors?.military?.military_category?.message}</p>
                    </div>

                    {watchField.military.military_target !== '복무중' && (
                        <>
                    <div className="wrap_box_input">
                        <div className="box_input">
                            <label htmlFor="" className="asterisk">제대 계급</label>
                            <select name="military.military_discharged_rank" id="military.military_discharged_rank" className="select" {...register('military.military_discharged_rank', {
                                required: '필수 입력 항목입니다.'
                            })} readOnly={readOnly} disabled={readOnly}>
                                <option value="" hidden={true}>제대 계급을 선택해주세요.</option>
                                <option value="이병">이병</option>
                                <option value="일병">일병</option>
                                <option value="상병">상병</option>
                                <option value="병장">병장</option>
                                <option value="하사">하사</option>
                                <option value="중사">중사</option>
                                <option value="상사">상사</option>
                                <option value="원사">원사</option>
                                <option value="준위">준위</option>
                                <option value="소위">소위</option>
                                <option value="중위">중위</option>
                                <option value="대위">대위</option>
                                <option value="소령">소령</option>
                                <option value="중령">중령</option>
                                <option value="대령">대령</option>
                                <option value="소장">소장</option>
                                <option value="중장">중장</option>
                                <option value="대장">대장</option>
                                <option value="기타">기타</option>
                            </select>
                        </div>
                        <p className="msg_err">{errors?.military?.military_discharged_rank?.message}</p>
                    </div>
                    <div className="wrap_box_input">
                        <div className="box_input">
                            <label htmlFor="" className="asterisk">전역 사유</label>
                            <select name="military.discharge_reason" id="military.discharge_reason" className="select" {...register('military.discharge_reason', {
                                required: '필수 입력 항목입니다.'
                            })} readOnly={readOnly} disabled={readOnly}>
                                <option value="" hidden={true}>전역 사유를 선택해주세요.</option>
                                <option value="만기제대">만기제대</option>
                                <option value="의가사제대">의가사제대</option>
                                <option value="의병제대">의병제대</option>
                                <option value="소집해제">소집해제</option>
                                <option value="불명예제대">불명예제대</option>
                                <option value="상이제대">상이제대</option>
                                <option value="기타">기타</option>
                            </select>
                        </div>
                        <p className="msg_err">{errors?.military?.discharge_reason?.message}</p>
                    </div>
                    </>
                    )}
                </div>
                    </>
                )}

            </div>
        </div>
    )

}
export default MilitaryService;