import React, { useState } from "react";
import api from "../../../api/api";
import jwtDecode from "jwt-decode";

const loginUser = async (formData, role) => {
  try {
    const loginResponse = await api.post("/api/v1/auth/login", formData);
    if (loginResponse.data.resultCode != 9000) {
      const token = loginResponse.data.resultData;
      const decodedToken = jwtDecode(token);
      const tokenRole = decodedToken.role;

      if (tokenRole !== role) {
        alert("로그인 실패. 아이디와 비밀번호를 확인해주세요.");
      } else {
        localStorage.setItem("accessToken", token);

        const headers = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const [userResponse, seqNoResponse] = await Promise.all([
          api.get("/api/v1/users", headers),
          api.get("/api/v1/getSeqNo", headers),
        ]);

        const user = userResponse.data.resultData;
        const { username, providerType, userId, roleType } = user;
        const userData = { username, providerType, userId, roleType };
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("userId", userData.userId);
        const mem_seq = seqNoResponse.data.resultData;
        localStorage.setItem("mem_seq", mem_seq);

        if (roleType === "COMPANY") {
          await api
            .get(`/api/login/getCustomerstatusById?memberId=${userId}`)
            .then((response) => {
              localStorage.setItem("customer_status", "true");
              if (
                response.data.resultCode === 1000 &&
                response.data.resultData > 0
              ) {
                // localStorage.setItem('customer_status', "true");
              }
            });
        }

        window.location.href = "/";
      }
    } else {
      alert("로그인 실패. 아이디와 비밀번호를 확인해주세요.");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

const SelfLogin = (props) => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const role = props.role;
  const handleUserIdChange = (e) => {
    setId(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const activeEnter = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = () => {
    const formData = {
      id: id,
      password: password,
    };
    loginUser(formData, role);
  };

  return (
    <>
      <ul className="login_input_list">
        <li className="login_input_item">
          <input
            type="text"
            placeholder="아이디"
            value={id}
            onChange={handleUserIdChange}
            onKeyDown={(e) => activeEnter(e)}
          />
        </li>
        <li className="login_input_item">
          <input
            type="password"
            placeholder="비밀번호"
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={(e) => activeEnter(e)}
          />
        </li>
      </ul>
      <button
        target="_self"
        className="btn_submit filled_green"
        onClick={handleLogin}
      >
        로그인
      </button>
    </>
  );
};

export default SelfLogin;
