import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { scenario, scenarioType } from "./Scenario";
import uuid from "react-uuid";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import api from "../../../api/api";
import { useImageFile } from "../../../hooks/useImageFile";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useModal } from "../../../hooks/useModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

dayjs.locale("ko");

const init = {
  profile: {
    member_name: "",
    member_phone: "",
    member_birthdate: "",
    member_email: "",
  },
  education: {
    high_school: {
      admission_status: "",
      school_name: "",
      affiliation: "",
      major: "",
      admission_date: "",
      graduate_status: "",
      graduate_year: "",
    },
  },
  military: {
    military_target: "",
    military_start_date: "",
    military_end_date: "",
    military_category: "",
    military_discharged_rank: "",
    discharge_reason: "",
  },
  veteran: {
    has_veterans_affair: "",
    veterans_affair_reason: "",
  },
  introduction_title: {
    introduction_title: "",
    introduction_content: "",
  },
};
const InterResume = () => {
  const { resumeNo } = useParams();
  const history = useHistory();

  // ocr
  const [resume, setResume] = useState(null);
  const resumeRef = useRef(null);
  const [educationalHistory, setEducationalHistory] = useState([]);
  const [careerHistory, setCareerHistory] = useState([]);
  const [certificateHistory, setCertificateHistory] = useState([]);
  const goBackUrl = "/user/resume/resumeProcess/" + resumeNo;
  const [messages, setMessages] = useState([]);
  const [scenarioId, setScenarioId] = useState(scenarioType.GREETING);
  const [inputValue, setInputValue] = useState("");
  const _scenarioId = scenarioId.split("||");
  const methods = useForm({
    mode: "onChange",
    defaultValues: init,
  });

  const resumeOcr = async (image) => {
    formData.append("attach", image);

    try {
      const userChat = createUserChat(image.name, image, "image");
      appendMessage(userChat);
      const scenario = scenarioType.NO_RESUME + "||" + uuid();
      setScenarioId(scenario);

      api.post("/api/ocr/resume", formData).then((response) => {
        if (response.data.resultCode === 1000 && response.data.resultData) {
          const { data } = response.data.resultData;
          localStorage.setItem(
            "resumeData",
            JSON.stringify(response.data.resultData)
          );
          setValue("profile.member_name", data.name, {
            shouldDirty: true,
            shouldTouch: true,
          });
          setValue("profile.member_phone", data.phone, {
            shouldDirty: true,
            shouldTouch: true,
          });
          setEducationalHistory(data.educational_history);
          setCareerHistory(data.career_history);
          setCertificateHistory(data.certificates);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    control,
    reset,
    resetField,
    trigger,
    setError,
    remove,
    formState: { errors },
    setFocus,
  } = methods;
  const formData = new FormData();
  const { uploadImage, deleteImage } = useImageFile(
    resumeRef,
    setResume,
    methods,
    undefined,
    resumeOcr,
    ["pdf"]
  );

  const deleteResume = () => {
    setResume(null);
    setScenarioId(scenarioType.BEFORE_RESUME + "||" + uuid());
  };

  useEffect(() => {
    const currentScenario = scenario[_scenarioId[0]];
    if (!currentScenario) return;
    deleteType(["button", "file", "resume"]);
    appendMessage(currentScenario);
  }, [scenarioId]);

  const appendMessage = (message) => {
    setMessages((prev) => {
      const nextMessages = [...prev, message];
      return nextMessages;
    });
  };

  const handleButton = async (nextScenario, message) => {
    const userChat = createUserChat(message, "", "text");
    appendMessage(userChat);
    const scenario = nextScenario + "||" + uuid();
    setScenarioId(scenario);
  };

  const handleChangeInput = (e) => {
    setInputValue(e.target.value);
  };
  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendBtn();
    }
  };
  const resetInputValue = () => {
    setInputValue("");
  };
  const handleSendBtn = () => {
    const userChat = createUserChat(inputValue, "", "text");
    appendMessage(userChat);
    vaildateUserInput(inputValue);
    resetInputValue();
  };

  const deleteType = (type) => {
    let del = false;

    if (_scenarioId[0] === "before_resume") {
      del = true;
    }

    const updatedData = messages.reduce((acc, item) => {
      if (item.role === "user" && del === true) {
        item.content.map((chat) => {
          if (chat.type === "image_input") {
            chat.type = "deleted";
          }
        });
      }
      const filteredContent = item.content.filter(
        (contentItem) => !type.includes(contentItem.type)
      );
      acc.push({ ...item, content: filteredContent });
      return acc;
    }, []);
    setMessages(updatedData);
  };

  const createUserChat = (text, file, type) => {
    if (type === "text")
      return {
        role: "user",
        content: [
          {
            type: "text",
            message: text,
          },
        ],
      };
    else if (type === "image")
      return {
        role: "user",
        content: [
          {
            type: "text",
            message: text,
          },
          {
            type: "image_input",
            message: text,
            file: file,
          },
        ],
      };
  };

  const vaildateUserInput = (value) => {
    const _uuid = "||" + uuid();

    if (
      _scenarioId[0] === scenarioType.GREETING ||
      _scenarioId[0] === scenarioType.BEFORE_RESUME
    ) {
      if (
        "이력서가 없습니다.".includes(value) ||
        "이력서가 없어요.".includes(value) ||
        "이력서 없는데?".includes(value) ||
        "이력서 없음".includes(value)
      ) {
        setScenarioId(scenarioType.NO_RESUME + _uuid);
      } else {
        setScenarioId(scenarioType.GREETING + _uuid);
      }
    } else if (_scenarioId[0] === scenarioType.NO_RESUME) {
    }
  };

  const { openModal, closeModal, ModalComponent } = useModal();

  const handleCancel = () => {
    openModal({
      title: "이력서 작성 취소",
      content: <p>정말로 이력서 작성을 취소하시겠습니까?</p>,
      buttons: [
        {
          label: "확인",
          className: "confirm-button",
          onClick: () => {
            closeModal();
            history.replace("/user/resume/beforeResume");
          },
        },
        {
          label: "취소",
          className: "cancel-button",
          onClick: closeModal,
        },
      ],
    });
  };

  const handleGoBack = () => {
    openModal({
      title: "이력서 작성 취소",
      content: (
        <p>
          이력서 작성을 다시 시작하시겠습니까?
          <br />
          작성된 이력서는 저장되지 않습니다.
        </p>
      ),
      buttons: [
        {
          label: "확인",
          className: "confirm-button",
          onClick: () => {
            closeModal();
            window.location.reload();
          },
        },
        {
          label: "취소",
          className: "cancel-button",
          onClick: closeModal,
        },
      ],
    });
  };

  return (
    <div>
      <div className="wrapper">
        <main>
          <div className="page join page_join_chat page_layout_40">
            <h2 className="page_back_title">
              <a href={goBackUrl} target="_self" className="page_back">
                이력서 작성하기
              </a>
            </h2>

            <div className="wrap_progressbar">
              <ul className="progressbar_list progress_step03">
                <li className="progressbar_item progress_item01">
                  <p>희망직무 선택</p>
                </li>
                <li className="progressbar_item progress_item02">
                  <p>진행방식 선택</p>
                </li>
                <li className="progressbar_item progress_item03">
                  <p>이력서 작성</p>
                </li>
              </ul>
            </div>

            <h3 className="page_title">이력서를 작성해 보세요!</h3>

            <div className="page_inner">
              <div className="area_shade">
                <div className="message_container">
                  <div className="area_chat">
                    {messages.map((message) => {
                      if (message.role === "user")
                        return (
                          <div className="send_message_area">
                            <div className="send_message_wrap">
                              {message.content.map((item) => {
                                if (item.type === "text")
                                  return (
                                    <div className="send_message_box">
                                      <div className="send_message_text">
                                        {item.message}
                                      </div>
                                      <div className="send_message_date">
                                        {dayjs(new Date()).format("A hh:mm")}
                                      </div>
                                    </div>
                                  );
                                if (item.type === "image_input")
                                  return (
                                    <div className="send_message_box">
                                      <div className="box_chat_bg">
                                        <div className="wrap_attached_file">
                                          <div className="box_thumbnail">
                                            <Document file={item.file}>
                                              <Page
                                                pageNumber={1}
                                                width={318}
                                                height={422}
                                              />
                                            </Document>
                                          </div>
                                          <div className="box_attached_info">
                                            <p className="attached_file_name">
                                              {item.message}
                                            </p>
                                            <button
                                              className="btn_attached_delete photo"
                                              type="button"
                                              title="첨부파일 삭제"
                                              onClick={deleteResume}
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                              })}
                            </div>
                          </div>
                        );
                      else
                        return (
                          <div className="chat_message_area">
                            <div className="chat_message_wrap">
                              <div className="chat_message_profile">
                                <div className="chatbot_photo"></div>
                                <div className="chatbot_name">드리미 챗봇</div>
                              </div>
                              {message.content.map((item) => {
                                if (item.type === "text")
                                  return (
                                    <div className="chat_message_box">
                                      <div className="chat_message_text">
                                        {item.message}
                                      </div>
                                      <div className="chat_message_date">
                                        {dayjs(new Date()).format("A hh:mm")}
                                      </div>
                                    </div>
                                  );
                                if (item.type === "file")
                                  return (
                                    <div className="chat_message_box">
                                      <div className="box_chat_bg">
                                        <div className="wrap_attached_file">
                                          <div className="box_attached_file">
                                            <input
                                              type="file"
                                              id="attachedFileCertificate"
                                              className="attached_file btn_submit filled_green"
                                              ref={resumeRef}
                                              onChange={uploadImage}
                                              accept=".pdf"
                                              multiple
                                            />
                                            <p className="attached_note">
                                              ※ PDF 형식의 파일만 업로드
                                              가능합니다.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                if (item.type === "resume")
                                  return (
                                    <div className="chat_message_box">
                                      <div className="box_chat_bg">
                                        <div className="box_resume">
                                          <div className="resume_write_delete">
                                            <Link
                                              to="/user/resume/interRegistedResume"
                                              className="btn_resume_write"
                                              title="이력서 수정"
                                            ></Link>
                                          </div>
                                          <Link
                                            to={{
                                              pathname:
                                                "/user/resume/interRegistedResume",
                                              state: { resume: resume },
                                            }}
                                            onClick={() =>
                                              window.scrollTo(0, 0)
                                            }
                                            className="resume_detail"
                                          >
                                            <span className="resume_title">
                                              {resume
                                                ? resume.name
                                                : "새 이력서"}
                                            </span>
                                            <span className="resume_written_date">
                                              {dayjs(new Date()).format(
                                                "YYYY-MM-DD"
                                              )}
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                if (item.type === "button")
                                  return (
                                    <div className="chat_message_box">
                                      <div className="wrap_btn">
                                        {item.buttons.map((button) => (
                                          <button
                                            className="btn_inline"
                                            type="button"
                                            onClick={() =>
                                              handleButton(
                                                button.action,
                                                button.message
                                              )
                                            }
                                          >
                                            {button.message}
                                          </button>
                                        ))}
                                      </div>
                                    </div>
                                  );
                              })}
                            </div>
                          </div>
                        );
                    })}
                  </div>
                  <div className="area_send">
                    <div className="wrap_send_message">
                      <input
                        type="text"
                        id=""
                        className=""
                        placeholder="도움이 필요하신가요?"
                        value={inputValue}
                        onChange={handleChangeInput}
                        onKeyPress={handleOnKeyPress}
                      />
                      <button
                        type="button"
                        className="btn_submit filled_green"
                        onClick={handleSendBtn}
                      >
                        전송
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrap_btns">
                <button
                  type="button"
                  className="btn_submit filled_black"
                  onClick={handleGoBack}
                >
                  처음부터
                </button>
                <button
                  type="button"
                  className="btn_submit filled_green"
                  onClick={handleCancel}
                >
                  대화종료
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ModalComponent />
    </div>
  );
};

export default InterResume;
