import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {ErrorMessages} from "../../../utils/Message";
import {updatePw} from "../../../api/Comp/CompSignup/CompSignup";

const ChangePwModal =(props) => {
    const { setChangeModal } = props;
    const { register, setValue, setError, reset, handleSubmit, watch, formState: { errors, isValid } } = useForm({
        mode: 'onChange'
    });
    const watchField = watch();

    const disableModal = () =>{
        setTimeout(() => {
            setChangeModal(false);
        }, 300);
    }

    const onSubmit = async (data) => {
        try {
            const response = await updatePw(data.currentPw, data.changePw);
            console.log(response)
            if (response.data.resultCode===1000 && response.data.resultData === true) {
                alert("비밀번호가 변경되었습니다.")
                setChangeModal(false);
            } else {
                alert("현재 비밀번호가 일치하지 않습니다.")
            }

        } catch(error) {
            console.log(error)
        }
    }

    return (
        <div>
            <div className="popup_mask" style={{display: "block"}} onClick={disableModal}></div>
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e)=>{if (e.key === 'Enter' && e.target.nodeName === "INPUT"){e.preventDefault();}}}>
                <div className="popup_layer popup_middle popResetIDPW changePW" id="popResetIDPW" style={{display: "block"}}>
                    <div className="popup_inner">
                        <div className="pop_title">
                            <h3>비밀번호 변경하기</h3>
                            <button type="button" aria-label="레이어 팝업 닫기" className="popup_close" onClick={()=>{setChangeModal(false)}}></button>
                        </div>
                        <div className="popup_contents">
                            <div className="wrap_contents content_01">
                                <div>
                                    <div className="wrap_input">
                                        <div className="wrap_box_input">
                                            <div className="box_input">
                                                <label htmlFor="" className="asterisk">비밀번호 확인</label>
                                                <input type="password" id="currentPw" name="currentPw" placeholder="현재 비밀번호를 입력해 주세요." {...register('currentPw',{
                                                    required: ErrorMessages.required
                                                })}/>
                                            </div>
                                            <p className="msg_err">{ errors.currentPw?.message }</p>
                                        </div>
                                        <div className="wrap_box_input">
                                            <div className="box_input">
                                                <label htmlFor="" className="asterisk">새 비밀번호 입력</label>
                                                <input type="password" id="changePw" name="changePw" placeholder="새 비밀번호를 입력해 주세요." {...register('changePw',{
                                                    required: ErrorMessages.required,
                                                    pattern: {
                                                        value: /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,32}$/,
                                                        message: ErrorMessages.inValidPw
                                                    },
                                                    validate: {
                                                        current: (value) => value !== watchField.currentPw || ErrorMessages.currentPw
                                                    }
                                                })}/>
                                            </div>
                                            <p className="msg_err">{ errors.changePw?.message }</p>
                                        </div>
                                        <div className="wrap_box_input">
                                            <div className="box_input">
                                                <label htmlFor="" className="asterisk">새 비밀번호 확인</label>
                                                <input type="password" id="validPw" name="validPw" placeholder="새 비밀번호를 다시 입력해 주세요." {...register('validPw',{
                                                    required: ErrorMessages.required,
                                                    validate: {
                                                        valid: (value) => value === watchField.changePw || ErrorMessages.unMatchPw,
                                                    }
                                                })} />
                                            </div>
                                            <p className="msg_err">{ errors.validPw?.message }</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="popup_middle_btn_wrap popup_pw_btn">
                                    <button type="button" className="btn_submit filled_black" onClick={()=>{setChangeModal(false)}}>닫기</button>
                                    <button type="submit" className="btn_submit filled_green" >확인</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ChangePwModal;