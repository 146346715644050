import { useState } from "react";
import { Modal } from "./Modal";

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [buttons, setButtons] = useState(null);

  const openModal = ({ title, content, buttons }) => {
    setTitle(title);
    setContent(content);
    setButtons(buttons);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return {
    isModalOpen: isOpen,
    openModal,
    closeModal,
    ModalComponent: () => (
      <Modal
        isOpen={isOpen}
        title={title}
        content={content}
        buttons={buttons}
        onClose={closeModal}
      />
    ),
  };
}
