import React, {useEffect, useState} from "react";
import {usePopupPost} from "../../../hooks/usePopupPost";
import {useFormContext} from "react-hook-form";
import {countManagerTel, sendPhone} from "../../../api/Comp/CompSignup/CompSignup";
import {ErrorMessages} from "../../../utils/Message";
import {countPhoneNo} from "../../../api/User/Signup/Signup";

const ManagerInfo = (props) => {
    const { phone, setPhone, isCompInfo, phoneChange,setPhoneChange } = props;
    const { register, setValue, setError, clearErrors, reset, watch, formState: { errors } } = useFormContext();
    const [isSame, setIsSame] = useState(false);
    const [time ,setTime] = useState(179);
    const watchField = watch();

    useEffect(() => {
        let Count

        if (time > 0) {
            Count = setInterval(() => {
                setTime((prev) => prev - 1);
            }, 1000);
        }
        else {
            clearInterval(Count);
            if (phone.pass !== true)
                setPhone({...phone, num: undefined, valid: false})
        }
        return () => clearInterval(Count)
    }, [time])

    const timeFormat = (time) => {
        const m = Math.floor(time / 60).toString()
        let s = (time % 60).toString()
        if (s.length === 1) s = `0${s}`
        return `${m}:${s}`
    }

    const sendMessage = async () => {
        const num = watchField.comp_member_info.manager_tel

        if (num === "" || num === undefined)
            setError("comp_member_info.manager_tel", {
                message: ErrorMessages.required
            }, {shouldFocus: true})
        else {
            try {
                // const response = await countManagerTel(num);
                //
                // if (response.data.resultData === 0) {
                    const valid = await sendPhone(num);

                    clearErrors("comp_member_info.manager_tel")
                    setPhone({...phone, send: true, num : valid})
                    setTime(179)
                // } else {
                //     setError("comp_member_info.manager_tel", {
                //         message: ErrorMessages.duplicatePhoneNo
                //     }, {shouldFocus: true})
                // }

            } catch (error) {
                console.log(error)
            }
        }

    }

    const validPhoneCheck = () => {
        const validNum = watchField.comp_member_info.validNum;
        if (phone.num !== undefined) {
            if (validNum === phone.num.data.resultData.toString()) {
                setPhone({...phone, valid: true, pass: true})
                clearErrors("comp_member_info.manager_tel")
                clearErrors("comp_member_info.validNum")
            }
            else {
                setError("comp_member_info.validNum", {
                    message : ErrorMessages.inValidPhone
                }, {shouldFocus: true})
                setPhone({...phone, valid : false})
            }
        }
        else {
            setError("comp_member_info.validNum", {
                message : ErrorMessages.reValidPhone
            }, {shouldFocus: true})
            setPhone({...phone, valid : false})
        }
    }

    function getPostCode(address, extraAddress) {
        setValue('comp_member_info.manager_address', address, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
        setValue('comp_member_info.manager_extraAddress', extraAddress, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
    }

    const {postCode} = usePopupPost(getPostCode)

    const sameAddress = (e) =>{
        if(e.target.checked === true && watchField.comp_member_info !== undefined && watchField.comp_member_info.address !== undefined && watchField.comp_member_info.extraAddress !== undefined) {
            setValue('comp_member_info.manager_address', watchField.comp_member_info.address, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
            setValue('comp_member_info.manager_extraAddress', watchField.comp_member_info.extraAddress, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
            setIsSame(true);
        } else {
            setValue('comp_member_info.manager_address', "", {shouldValidate: true, shouldDirty: true, shouldTouch: true});
            setValue('comp_member_info.manager_extraAddress', "", {shouldValidate: true, shouldDirty: true, shouldTouch: true});
            setIsSame(false);
        }
    }

    const changePhone = () => {
        setPhoneChange(true);
        setValue("comp_member_info.manager_tel", "", {shouldDirty: true, shouldTouch: true})
    }

    return (
        <>
        <div className="wrap_signup_input wrap_signup_basic">
            <div className="box_sub_title">
                <h4 className="sub_title_signup">담당자 정보</h4>
            </div>
            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label className="asterisk">담당자 이름</label>
                    <input type="text" id="comp_member_info.manager_nm" name="comp_member_info.manager_nm" placeholder="담당자 이름을 입력해 주세요." {...register('comp_member_info.manager_nm',{
                        required: ErrorMessages.required,
                        onChange : (e) => { e.target.value = e.target.value.replace(/[^가-힣ㄱ-ㅎㅏ-ㅣ]/gi,'')}
                    })}/>
                </div>
                <p className="msg_err">{errors.comp_member_info?.manager_nm?.message}</p>
            </div>
            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label className="asterisk">담당자 직급</label>
                    <input type="text" id="comp_member_info.manager_pos" name="comp_member_info.manager_pos" placeholder="담당자 직급을 입력해 주세요." {...register('comp_member_info.manager_pos',{
                        required: ErrorMessages.required,
                        onChange : (e) => { e.target.value = e.target.value.replace(/[^가-힣ㄱ-ㅎㅏ-ㅣ]/gi,'')}
                    })}/>
                </div>
                <p className="msg_err">{errors.comp_member_info?.manager_pos?.message}</p>
            </div>
            { phoneChange === false &&
            <div className="wrap_box_input focus_in">
                <div className="box_confirm">
                    <div className="box_input">
                        <label className="asterisk">담당자 연락처</label>
                        <input type="text" id="comp_member_info.manager_tel" name="comp_member_info.manager_tel"  placeholder="휴대폰 번호를 입력해 주세요."
                               {...register('comp_member_info.manager_tel',{
                                    pattern: {value: /^(d{3}-\d{3,4}-\d{4}|010\d{7,8})$/},
                                    onChange : (e) => { e.target.value = e.target.value.replace(/[^0-9]/gi,'')}
                                })} disabled={true}/>
                    </div>
                    <button type="button" className="btn_submit filled_green" onClick={changePhone}>변경하기</button>
                </div>
            </div>
            }
            { (phone.send !== true && phoneChange !== false) &&
            <div className="wrap_box_input focus_in">
                <div className="box_confirm">
                    <div className="box_input">
                        <label className="asterisk">담당자 연락처</label>
                        <input type="text" id="comp_member_info.manager_tel" name="comp_member_info.manager_tel"  placeholder="휴대폰 번호를 입력해 주세요." {...register('comp_member_info.manager_tel', {
                                   required: ErrorMessages.required,
                                   validate: {
                                       validPhone: () => phone.send === true || ErrorMessages.validPhone
                                   },
                                   onChange : (e) => { e.target.value = e.target.value.replace(/[^0-9]/g,'')}
                               })}/>
                    </div>
                    <button type="button" className="btn_submit filled_green" onClick={sendMessage}>인증번호 요청</button>
                </div>
                <p className="msg_err">{errors.comp_member_info?.manager_tel?.message}</p>
            </div>
            }
            { phone.send === true &&
            <div className="wrap_box_input focus_in">
                <div className="box_confirm">
                    <div className="box_input">
                        <label className="asterisk">담당자 연락처</label>
                        <input type="text" id="comp_member_info.manager_tel" name="comp_member_info.manager_tel"  placeholder="휴대폰 번호를 입력해 주세요." {...register('comp_member_info.manager_tel',{
                                   required: ErrorMessages.required,
                                   validate: {
                                       validPhone: () => phone.send === true || ErrorMessages.validPhone
                                   },
                                   onChange: (e) => {
                                       setPhone({...phone, valid: false, pass: false, num: undefined});
                                       e.target.value = e.target.value.replace(/[^0-9]/g,'');
                                   }
                               })} disabled={phone.pass === true ? true : false} />
                        <p className="msg_time">{phone.valid !== true && timeFormat(time)}</p>
                    </div>
                    {phone.valid !== true &&
                    <button type="button" className="btn_submit filled_green" onClick={sendMessage}>재전송</button>
                    }
                    {phone.pass === true &&
                    <button type="button" className="btn_submit filled_green" disabled>인증완료</button>
                    }
                </div>
                { phone.valid !== true &&
                <div className="box_confirm">
                    <div className="box_input">
                        <input type="number" id="comp_member_info.validNum" name="comp_member_info.validNum" placeholder="인증번호를 입력해 주세요." {...register('comp_member_info.validNum',{
                            required: ErrorMessages.required,
                            validate: {
                                validNum: () => (phone.valid === true && phone.pass === true) || ErrorMessages.validPhone
                            },
                            onChange: () => setPhone({...phone, valid: false})
                        })}/>
                    </div>
                    <button type="button" className="btn_submit filled_green" onClick={validPhoneCheck}>인증번호 확인</button>
                </div>
                }
                <p className="msg_err">{errors.comp_member_info?.validNum?.message || errors.comp_member_info?.manager_tel?.message}</p>
            </div>
            }
            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label className="asterisk">회사 연락처</label>
                    <input type="text" id="comp_member_info.comp_tel" name="comp_member_info.comp_tel" placeholder="회사 연락처를 입력해 주세요." {...register('comp_member_info.comp_tel', {
                        required: ErrorMessages.required,
                        onChange : (e) => { e.target.value = e.target.value.replace(/[^0-9]/g,'')}
                    })}/>
                </div>
                <p className="msg_err">{errors.comp_member_info?.comp_tel?.message}</p>
            </div>

            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">E-mail</label>
                    <input type="text" id="member_com.email" name="member_com.email" placeholder="E-mail 주소를 입력해 주세요." {...register('member_com.email', {
                        required: ErrorMessages.required,
                        pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: ErrorMessages.inValidEmail
                        }
                    })}/>
                </div>
                <p className="msg_err">{errors.member_com?.email?.message}</p>
            </div>
        </div>
        <div className="wrap_signup_input wrap_box_address">
            <div className="wrap_box_input focus_in">
                <div className="box_confirm">
                    <div className="box_input">
                        <label className="asterisk">담당자 근무지</label>
                        { isSame !== true &&
                        <>
                        <input type="text" id="comp_member_info.manager_address" name="comp_member_info.manager_address" placeholder="주소를 입력해 주세요." {...register('comp_member_info.manager_address', {
                            required: true
                        })} readOnly/>
                        <button type="button" className="btn_submit filled_green" onClick={postCode}> 우편번호 찾기 </button>
                        </>
                        }
                    </div>
                </div>
                <div className="box_input">
                    {isSame !== true &&
                    <input type="text" id="comp_member_info.manager_extraAddress"
                           name="comp_member_info.manager_extraAddress"
                           placeholder="상세주소를 입력해 주세요." {...register('comp_member_info.manager_extraAddress', {
                        required: true
                    })}/>
                    }
                </div>
                <div className="box_input_chk_round">
                    <label>
                        <input type="checkbox" className="input_chk" id="same01" name="same01" onChange={sameAddress}/>
                            회사 주소와 동일
                    </label>
                    </div>
                    <p className="msg_err">{ (errors.comp_member_info?.manager_address || errors.comp_member_info?.manager_extraAddress) && ErrorMessages.required }</p>
                </div>
            </div>
            </>
        )
    }

export default ManagerInfo;