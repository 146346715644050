import api from "../../api";

export async function getJobRequestList() {
    const response = await api.get('/api/comp/matching/getJobApplicationList')

    return response;
}

export async function getRecruit(recruitSeq) {
    const response = await api.post('/api/comp/matching/getRecruit', null,{
        params: {
            seq: recruitSeq
        }
    })

    return response;
}

export async function updateRecruit(vo, recruitSeq) {
    const response = await api.post('/api/comp/matching/updateRecruit', vo, {
        params: {
            recruitSeq: recruitSeq
        }
    })

    return response;
}