import api from "../../api";

export async function countId(id) {
  const response = await api.get("/api/comp/signup/countId", {
    params: {
      id: id,
    },
  });

  return response;
}

export async function countBizSeq(bizSeq) {
  const response = await api.get("/api/comp/signup/countBizSeq", {
    params: {
      bizSeq: bizSeq,
    },
  });

  return response;
}

export async function countManagerTel(managerTel) {
  const tel = managerTel.replaceAll("-", "");

  const response = await api.get("/api/comp/signup/countManagerTel", {
    params: {
      managerTel: tel,
    },
  });

  return response;
}

export async function sendPhone(num) {
  const phoneNum = num.replaceAll("-", "");

  const response = await api.get("/api/sms/send", {
    params: {
      phoneNum: phoneNum,
    },
  });

  return response;
}

export async function createCompUser(data) {
  const response = await api.post("/api/comp/signup/createCompUser", data);

  return response;
}

export async function uploadImage(data) {
  const response = await api.post("/api/comp/signup/uploadImage", data);

  return response;
}

export async function getCompInfo() {
  const response = await api.get("/api/comp/signup/getCompInfo");

  return response;
}

export async function updatePw(memberPw, changePw) {
  const response = await api.put("/api/comp/signup/updatePw", null, {
    params: {
      memberPw: memberPw,
      changePw: changePw,
    },
  });

  return response;
}

export async function updateCompInfo(data) {
  const response = await api.put("/api/comp/signup/updateCompInfo", data);

  return response;
}

export async function updateCompWithdrawal() {
  const response = await api.put("api/comp/signup/updateCompWithdrawal");

  return response;
}
