import { useState,useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import jwt_decode from "jwt-decode";
import api from "../../api/api";
import ContractModal from "../../container/user/login/ContractModal";

const Header = () => {
        const user = JSON.parse(localStorage.getItem('user'));

        const [role, setRole] = useState();
        const [contractModal, setContractModal] = useState(false);
        const [modal, setModal] = useState(false);
        //0 : 비로그인, 1 : 역량평가x, 2 : 이력서x, 3: 역량평가,이력서x
        const [modalType, setModalType] = useState(-1);
        const [checkResume, setCheckResume] = useState(false);
        const [checkAbility, setCheckAbility] = useState(0);

        const [ment, setMent] = useState("");

        const handleLogout = () => {
                localStorage.clear();
                window.location.href="/";
        };

        const [userId,setUserId] = useState(null);

        const loginCheck = () => {
                try{
                        const decodedAccessToken = jwt_decode(localStorage.getItem("accessToken"))
                        setUserId(decodedAccessToken.sub)
                        setRole(decodedAccessToken.role);

                        api.post('/api/resume_new/getProfileList',{}).then(response => 
                                response.data
                        ).then((response) => {
                                if(response.resultData?.length > 0) {
                                        setCheckResume(true)
                                } 
                        })
                        .catch(error => {
                        console.error('Error:', error);
                        });

                        api.post("/api/ability/abilityChk").then(response => 
                        response.data
                        ).then((response) => {
                                if(response.resultCode===1000) {
                                        setCheckAbility(1);
                                }
                                else if(localStorage.getItem('abilityResult_'+decodedAccessToken.sub)!=undefined) {
                                        setCheckAbility(-1);
                                }
                        })
                        .catch(error => {
                        console.error('Error:', error);
                        });
                }catch(e){
                        setModalType(0);
                        console.log("is not logined");
                }
        }

        useEffect(loginCheck,[]);

        const checkAbilityResume = () => {
        if(modalType === 0){
                setModal(true);
                setMent("회원가입 후 이력서 작성 및 역량평가");
        }
        else if(!checkResume || checkAbility <= 0){
                setModal(true);
                if(!checkResume && checkAbility <=0){
                        setModalType(3);
                        setMent("이력서 작성 및 역량평가");
                } else if(!checkResume) {
                        setModalType(2);
                        setMent("이력서 작성");
                } else if(checkAbility <= 0){
                        setModalType(1);
                        setMent("역량평가");
                }
        }
        else{
                window.location.href="/user/job/CareerPath"
        }}
        
        return (
                <>
                <header>
                        <div className="wrap_header">
                                <div className="area_logo">
                                        <div className="box_logo">
                                                <a href="/" target="_self" className="logo_anchor">
                                                        <img src="/img/logo/logo_vdream.png" alt=""/>
                                                </a>
                                        </div>
                                </div>
                                <div className="inner_wrpper">
                                        <div className="area_gnb">
                                                <ul className="gnb_list">
                                                        <li className="gnb_item">
                                                                {
                                                                    user && user.roleType === "COMPANY" && localStorage.getItem("customer_status") !== "true" ? (
                                                                        <a target="_self" className="gnb_anchor" onClick={() => setContractModal(true)}>채용정보</a>
                                                                    ) : (
                                                                        <Link to={"/user/job/recruitInfo"} target="_self" className="gnb_anchor">채용정보</Link>
                                                                    )
                                                                }
                                                        </li>
                                                        {
                                                                user && user.roleType === "COMPANY" ? (
                                                                   null
                                                                ) : (
                                                                    <li className="gnb_item">
                                                                            <a href="/user/lms/recommendLecture"
                                                                               target="_self"
                                                                               className="gnb_anchor">추천교육</a>
                                                                    </li>
                                                                )
                                                        }
                                                        {
                                                                user && user.roleType === "COMPANY" ? (
                                                                    null
                                                                ) : (
                                                                    <li className="gnb_item">
                                                                            <a href="#" target="_self"
                                                                               className="gnb_anchor" onClick={(e) => {e.preventDefault(); checkAbilityResume();}}>커리어패스</a>
                                                                    </li>
                                                                )
                                                        }
                                                </ul>
                                        </div>
                                        <div className="area_button">
                                                <ul>
                                                {localStorage.getItem('accessToken')==null ? (
                                                        <><li>
                                                                <a href="/user/login/LoginForm" target="_self" className="">로그인</a>
                                                        </li>
                                                        <li>
                                                                <a href="/user/signup/selectsignup" target="_self" className="">회원가입</a>
                                                        </li></>
                                                ) : (
                                                        <li>
                                                        <button onClick={handleLogout}>로그아웃</button>
                                                        </li>
                                                )}
                                                </ul>
                                        </div>
                                </div>
                        </div>
                </header>
        {modal && (
           <>
            <div className="popup_mask" style={{"display" : "block", zIndex : 1011}} onClick={(e) => {e.preventDefault(); setModal(false);}}></div>
            <div className="popup_layer popup_small" >
                <div className="popup_inner">          
                    <div className="popup_contents">
                        {modalType > -1 && (
                             <p dangerouslySetInnerHTML={{__html :  ment  + " 완료 시<br/>커리어패스 정보를 제공해드립니다."}}></p>
                        )}

                        <div className="popup_small_btn_wrap">
                            <a href="#" target="_self" className="btn_submit filled_black" onClick={(e) => {e.preventDefault(); setModal(false);}}>닫기</a>
                            {modalType > -1 && modalType == 0 ? (<a href="/user/signup/selectsignup" target="_self" className="btn_submit filled_green">회원가입 하기</a>) : modalType == 1 ? 
                            (
                                <a href="/user/ability/main" target="_self" className="btn_submit filled_green">역량평가 하기</a>
                            ) : (
                                <a href="/user/resume/beforeResume" target="_self" className="btn_submit filled_green">이력서작성 하기</a>
                            )}
                        </div>
                    </div>    
                </div>
            </div>
            </>
        )}
        {
            contractModal && <ContractModal setModal={setContractModal} />
        }
        </>
        )
}

export default Header;