import axios from "axios";
import jwt_decode from "jwt-decode";
// const apiUrl = process.env.REACT_APP_API_URL;
const api = axios.create({
  // baseURL: "/",
});

const apiUrl =
  process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : "/";
api.defaults.baseURL = apiUrl;

const refreshToken = async () => {
  try {
    const expiredToken = localStorage.getItem("accessToken");
    const response = await axios.get("/api/v1/auth/refresh", {
      headers: {
        Authorization: `Bearer ${expiredToken}`,
      },
    });
    const refreshedToken = response.data.resultData;
    console.log(response);
    localStorage.setItem("accessToken", refreshedToken);
    return refreshedToken;
  } catch (error) {
    console.error("토큰 재발급 실패:", error);
    throw error;
  }
};

api.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const decodedToken = jwt_decode(accessToken);
      const expirationDate = new Date(decodedToken.exp * 1000);
      const now = new Date();
      if (now >= expirationDate) {
        try {
          const refreshedToken = await refreshToken();
          config.headers["Authorization"] = `Bearer ${refreshedToken}`;
        } catch (error) {
          console.error("토큰 재발급 실패:", error);
          throw error;
        }
      } else {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
export const decodeToken = (token) => {
  if (token) {
    return jwt_decode(token);
  } else {
    return null;
  }
};
