import React, { createContext, useEffect, useRef, useState } from "react";
import { useForm, FormProvider, appendErrors } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { scenario, scenarioType } from "./Scenario";
import uuid from "react-uuid";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import BasicInfo from "./BasicInfo";
import Education, { Option } from "./education/Education";
import Career from "./Career";
import ComputerSkill from "./ComputerSkill";
import Language from "./Language";
import MilitaryService from "./MilitaryService";
import VeteransAffairs from "./VeteransAffairs";
import SelfIntroduction from "./SelfIntroduction";
import Awards from "./Awards";
import api from "../../../api/api";
import { useImageFile } from "../../../hooks/useImageFile";
import { getAutoComplete } from "../../../api/User/resume/autoComplete";
import { useModal } from "../../../hooks/useModal";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { validateInput } from "../../../utils/interResume/vaildateResume";
import _ from "lodash";
import ResumeCertificate, {
  AutoCompleteOption,
} from "./Certificate/ResumeCertificate";

dayjs.locale("ko");

const init = {
  profile: {
    member_name: "",
    member_phone: "",
    member_birthdate: "",
    member_email: "",
    member_image: "",
    member_file_path: "",
  },
  education: {
    high_school: {
      admission_status: "",
      school_name: "",
      affiliation: "",
      major: "",
      admission_date: "",
      graduate_status: "",
      graduate_year: "",
    },
  },
  military: {
    military_target: "",
    military_start_date: "",
    military_end_date: "",
    military_category: "",
    military_discharged_rank: "",
    discharge_reason: "",
  },
  veteran: {
    has_veterans_affair: "",
    veterans_affair_reason: "",
  },
  introduction_title: {
    introduction_title: "",
    introduction_content: "",
  },
};

const InterRegistedResume = () => {
  const { resumeNo } = useParams();

  const [agree, setAgree] = useState(false);
  const [modal, setModal] = useState(false);
  const goBackUrl = "/user/resume/resumeProcess/" + resumeNo;
  const location = useLocation();
  const message = (e) => {
    alert("동의하셔야 이력서 등록이 가능합니다.");
  };
  const history = useHistory();
  const [isSubmit, setIsSubmit] = useState(false);
  const resumeRef = useRef(null);
  // ocr
  const [resume, setResume] = useState(null);
  const [educationalHistory, setEducationalHistory] = useState([]);
  const [careerHistory, setCareerHistory] = useState([]);
  const [certificateHistory, setCertificateHistory] = useState([]);
  const [awardsHistory, setAwardsHistory] = useState([]);
  const [langHistory, setLangHistory] = useState([]);
  const [universityIndex, setUniversityIndex] = useState(0);
  const [graduateIndex, setGraduateIndex] = useState(0);

  const formData = new FormData();
  const FormContext = createContext();
  const methods = useForm({
    mode: "onChange",
    defaultValues: init,
  });

  const targetRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [scenarioId, setScenarioId] = useState(
    scenarioType.REGISTED_RESUEM_GREETING
  );
  const [inputValue, setInputValue] = useState("");
  const _scenarioId = scenarioId.split("||");
  // 각 항목 인덱스
  const [indices, setIndices] = useState({
    majorIndex: null,
    graduateIndex: 0,
    careerIndex: 0,
    certIndex: 0,
    awardIndex: 0,
    langIndex: 0,
  });
  const updateIndex = (key, value) => {
    setIndices((prevIndices) => ({
      ...prevIndices,
      [key]: value,
    }));
  };

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    control,
    reset,
    resetField,
    trigger,
    setError,
    remove,
    formState: { errors },
    setFocus,
  } = methods;

  const [userInfo, setUserInfo] = useState({
    // 구직자 회원 계정
    member_com: {
      member_id: "",
      member_pw: "",
      email: "",
      member_nm: "",
      gubun: "USER",
      provider_type: "LOCAL",
    },
    // 구직자 회원정보
    disabili_certifica: {
      dis_reg_cer_seq: "",
      birthday: "",
      sex_gb: "",
      address: "",
      extraAddr: "",
      major_disability_nm: "",
      minor_disability_nm: "",
      total_disability_nm: "",
      issuance_dt: "",
      issuance_agency: "",
      confirm_no: "",
      certifica_no: "",
      crtf_file_path: "",
      crtf: "",
    },
    // 장애인증명서
    member_info: {
      image_file_path: "",
      image: "",
      phone_no: "",
      protector_nm: "",
      protector_relation: "",
      equipment_st: "",
      inflow: "",
    },
  });
  const [autoCompleteInfo, setAutoCompleteInfo] = useState({
    certifications: [],
    majors: [],
  });
  const watchField = watch();

  useEffect(() => {
    return localStorage.removeItem("newcomer");
  }, []);

  useEffect(() => {
    const currentScenario = scenario[_scenarioId[0]];
    console.log(currentScenario);
    if (!currentScenario) return;
    deleteType(["button", "file"]);
    appendMessage(currentScenario);
    if (_scenarioId[0] === "registed_resume_greeting") unFulledInput();
  }, [scenarioId]);

  useEffect(() => {
    // let localData = JSON.parse(localStorage.getItem("resumeData"));
    // 이력서 히스토리 남겼었던 로직..?
    // if (data != null) {
    //   setValue("profile.member_name", data.data.name, {
    //     shouldDirty: true,
    //     shouldTouch: true,
    //   });
    //   setValue("profile.member_phone", data.data.phone, {
    //     shouldDirty: true,
    //     shouldTouch: true,
    //   });
    //   setEducationalHistory(data.data.educational_history);
    //   setCareerHistory(data.data.career_history);
    //   setCertificateHistory(data.data.certificates);
    // }
    // if (location.state !== undefined) {
    //   setResume(location.state.resume);
    // }
  }, []);

  const resumeOcr = async (image) => {
    formData.append("attach", image);

    try {
      api.post("/api/ocr/resume", formData).then((response) => {
        if (response.data.resultCode === 1000 && response.data.resultData) {
          const { data } = response.data.resultData;
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    try {
      getAutoComplete()
        .then((response) => {
          const data = response.data;
          data &&
            data.resultCode === 1000 &&
            data.resultData &&
            setAutoCompleteInfo(data.resultData);
        })
        .catch((error) => {
          console.error("Error", error);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    try {
      api
        .post("/api/signup/getUserInfo", {})
        .then((response) => response.data)
        .then((response) => {
          response &&
            response.resultCode === 1000 &&
            response.resultData &&
            setUserInfo(response.resultData);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    targetRef.current.scrollTop = targetRef.current.scrollHeight;
  });

  useEffect(() => {
    if (!isSubmit) return;
    AlertError();
    setIsSubmit(false);
  }, [errors, isSubmit]);

  const appendMessage = (message) => {
    setMessages((prev) => {
      const nextMessages = [...prev, message];
      return nextMessages;
    });
  };

  const _uuid = "||" + uuid();
  const openDutyModal = JSON.parse(localStorage.getItem("openDutyModal"));
  useEffect(() => {
    if (
      _scenarioId[0] === "career.department" &&
      openDutyModal === "complete"
    ) {
      setScenarioId(scenarioType.INSERT_HIRED_DATE + _uuid);
    }
  }, [openDutyModal]);

  const unFulledInput = (message, arrayPath) => {
    if (message) return;

    const currentScenario = [_scenarioId[0]];
    const currentValue = _.get(
      watchField,
      arrayPath ? arrayPath : currentScenario[0]
    );

    // 다음 문항 가져옴
    const getNext = (currentKey) => {
      for (let key in scenario) {
        if (scenario[key].index === scenario[currentKey].index + 1) {
          return scenario[key].id;
        }
      }
    };
    const nextItem = getNext(_scenarioId[0]);
    // console.log("*************************************");

    // console.log("현재 시나리오", _scenarioId[0]);
    // console.log(currentValue, nextItem);
    // console.log(_.get(watchField, currentScnario[0]));

    // 처음 진입시 고등학교 정보 입력 받기
    if (scenarioId === "registed_resume_greeting") {
      setScenarioId(scenarioType.INSERT_HIGH + _uuid);
    } else if (scenarioId === "add_univ") {
      setScenarioId(scenarioType.INSERT_UNIV_ADDMISION_STATUS + _uuid);
    } else if (scenarioId === "add_career") {
      setScenarioId(scenarioType.INSERT_MAJOR_STATUS + _uuid);
    }
    // 검정고시 선택
    else if (_.get(watchField, currentScenario[0]) === "검정고시") {
      setScenarioId(scenarioType.INSERT_HIGH_PASS_DATE + _uuid);
    } else if (
      _scenarioId[0] === "education.high_school.graduate_status" &&
      currentValue !== "졸업"
    ) {
      setScenarioId(scenarioType.EDIT_HIGH + _uuid);
    }
    // 졸업날짜, 검정고시 패스 날짜 선택시 수정 항목으로
    else if (
      currentValue &&
      _scenarioId[0] === "education.high_school.graduate_year"
    ) {
      setScenarioId(scenarioType.EDIT_HIGH + _uuid);
    } else if (
      watchField.education.high_school.graduate_year &&
      _scenarioId[0] === "insert.high_pass.date"
    ) {
      setScenarioId(scenarioType.ADD_UNIV_SCHOOL + _uuid);
    } else if (
      _scenarioId[0] === `education.university.graduate_status` &&
      !["졸업", "수료"].includes(currentValue)
    ) {
      setScenarioId(scenarioType.INSERT_UNIV_MAJOR + _uuid);
    }

    // 학력 추가 입력
    else if (
      currentValue &&
      _scenarioId[0] === "education.graduate.total_grade"
    ) {
      setScenarioId(scenarioType.EDIT_GRAD + _uuid);
    } else if (
      _scenarioId[0] === "education.graduate.graduate_status" &&
      !["졸업", "수료"].includes(currentValue)
    ) {
      setScenarioId(scenarioType.INSERT_GRAD_GRADE + _uuid);
    } else if (currentValue && nextItem === "career.duty") {
      localStorage.setItem("openDutyModal", true);
    } else if (
      _scenarioId[0] === "career.employment_status" &&
      !["퇴사"].includes(currentValue)
    ) {
      setScenarioId(scenarioType.INSERT_SALARY + _uuid);
    }
    //병역사항 - 복무중
    else if (
      watchField.military.military_target === "복무중" &&
      _scenarioId[0] === "military.military_start_date"
    ) {
      setScenarioId(scenarioType.ADD_MILITARY_TYPE + _uuid);
    } else if (
      watchField.military.military_target === "복무중" &&
      _scenarioId[0] === "military.military_category"
    ) {
      setScenarioId(scenarioType.EDIT_MILITARY + _uuid);
    }

    // 값을 입력받았고, 다음 문항이 있을경우 시나리오 추가
    else if (currentValue && nextItem) {
      setScenarioId(nextItem + _uuid);
    }
  };

  const handleChangeInput = (e) => {
    setInputValue(e.target.value);
  };
  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendBtn();
    }
  };
  const resetInputValue = () => {
    setInputValue("");
  };

  // 고등학교 전공 자동완성 클릭 후
  useEffect(() => {
    const univMajor = watchField.education?.high_school?.major;

    if (!univMajor) return;
    const userChat = createUserChat(univMajor, "", "text");
    appendMessage(userChat);

    setScenarioId("education.high_school.admission_date" + "||" + uuid());
    resetInputValue();
  }, [watchField.education?.high_school?.major]);

  // 대학교 자동완성 클릭 후
  useEffect(() => {
    const univMajor =
      watchField.education?.university?.[universityIndex]?.major;

    if (!univMajor) return;
    const userChat = createUserChat(univMajor, "", "text");
    appendMessage(userChat);

    setScenarioId(`education.university.affiliation` + "||" + uuid());
    resetInputValue();
  }, [watchField.education?.university?.[universityIndex]?.major]);

  console.log(watchField);
  // 부전공/복수전공/이중전공 자동완성 클릭 후
  useEffect(() => {
    const univMajor =
      watchField.education?.university?.[universityIndex]?.add_major?.[
        indices.majorIndex
      ]?.major_name;

    if (!univMajor) return;
    const userChat = createUserChat(univMajor, "", "text");
    appendMessage(userChat);

    setScenarioId(
      `education.university.add_major.major_affiliation` + "||" + uuid()
    );
    resetInputValue();
  }, [
    watchField.education?.university?.[universityIndex]?.add_major?.[
      indices.majorIndex
    ]?.major_name,
  ]);

  // 자격증명 자동완성 클릭 후
  useEffect(() => {
    const certName =
      watchField.certificate?.[indices.certIndex]?.certificate_name;

    if (!certName) return;
    const userChat = createUserChat(certName, "", "text");
    appendMessage(userChat);

    setScenarioId("certificate.certificate_authority" + "||" + uuid());
    resetInputValue();
  }, [watchField.certificate?.[indices.certIndex]?.certificate_name]);

  // 질문 네/아니오 버튼
  const handleButton = async (nextScenario, message, status) => {
    const userChat = createUserChat(message, "", "text");
    appendMessage(userChat);

    const scenario = nextScenario + "||" + uuid();

    if (nextScenario === "add_more_univ_school") {
      setUniversityIndex((prev) => prev + 1);
    }
    if (nextScenario === "add_more_grad") {
      setGraduateIndex((prev) => prev + 1);
    }

    if (status === "add") {
      if (nextScenario === "education.university.add_major.major_status") {
        if (indices.majorIndex === null) {
          updateIndex("majorIndex", 0);
        } else {
          updateIndex("majorIndex", Number(indices.majorIndex) + 1);
        }
      } else if (nextScenario === "education.graduate.admission_status") {
        updateIndex("graduateIndex", Number(indices.graduateIndex) + 1);
      } else if (nextScenario === "career.company_name") {
        updateIndex("careerIndex", Number(indices.careerIndex) + 1);
      } else if (nextScenario === "certificate.certificate_gubun") {
        updateIndex("certIndex", Number(indices.certIndex) + 1);
      } else if (nextScenario === "prize.award_name") {
        updateIndex("awardIndex", Number(indices.awardIndex) + 1);
      } else if (nextScenario === "language.language_test_name") {
        updateIndex("langIndex", Number(indices.langIndex) + 1);
      }
    }

    if (status === "initialCareer") {
      if (message === "신입") {
        localStorage.setItem("newcomer", true);
      }
    }

    setScenarioId(scenario);
    saveInputValue(message);
    resetInputValue();
  };

  const handleSendBtn = () => {
    const userChat = createUserChat(inputValue, "", "text");
    appendMessage(userChat);

    const isValidate = validateInput({ inputValue, scenarioId });
    // 유효성 검사
    if (isValidate) {
      saveInputValue();
    } else {
      appendMessage({
        role: "bot",
        content: [
          {
            type: "text",
            message: "입력값 형태를 확인후 다시 작성해주세요",
          },
        ],
      });
    }
    resetInputValue();
  };

  const saveInputValue = (propsMessage) => {
    let message = propsMessage;

    if (propsMessage === "아니오") return;
    if (propsMessage === "특성화/마이스터고") {
      message = "특성화";
    }

    const field = _scenarioId[0].split(".");
    const path = field[field.length - 1];
    const _inputValue = message || inputValue;
    const settingValue = (path, value) => {
      const substrings = [
        "add_univ",
        "add_major",
        "add_career",
        "add_cert",
        "add_award",
        "add_lang",
      ];
      const allIncluded = substrings.every(
        (substring) => _scenarioId[0] === substring
      );

      if (allIncluded) return;

      setValue(path, value);
    };

    if (_scenarioId[0] === "insert.high_pass.date") {
      settingValue("education.high_school.graduate_year", _inputValue);
      unFulledInput(message);
    } else if (_scenarioId[0].includes("comskill.")) {
      function getNumericLevel(_inputValue) {
        switch (_inputValue) {
          case "상":
            return "3";
          case "중":
            return "2";
          case "하":
            return "1";
          default:
            return null;
        }
      }

      settingValue(_scenarioId[0], getNumericLevel(_inputValue));
      unFulledInput(message);
    } else if (_scenarioId[0].includes(`education.university.gubun`)) {
      const fullPath = `education.university[${universityIndex}].${path}`;
      function getNumericLevel(_inputValue) {
        switch (_inputValue) {
          case "일반(4년제)":
            return "4";
          case "전문대(3년제)":
            return "3";
          case "전문대(2년제)":
            return "2";
          default:
            return null;
        }
      }

      settingValue(fullPath, getNumericLevel(_inputValue));
      unFulledInput(message);
    }
    // 전공
    else if (_scenarioId[0].includes("_major")) {
      const fullPath = `education.university[${universityIndex}].add_major[${indices.majorIndex}].${path}`;
      settingValue(fullPath, _inputValue);
      unFulledInput(message, fullPath);
    }
    // 대학교
    else if (_scenarioId[0].includes("education.university")) {
      const fullPath = `education.university[${universityIndex}].${path}`;
      settingValue(fullPath, _inputValue);
      unFulledInput(message, fullPath);
    }
    // 대학원
    else if (_scenarioId[0].includes("education.graduate")) {
      const fullPath = `education.graduate[${graduateIndex}].${path}`;
      settingValue(fullPath, _inputValue);
      unFulledInput(message, fullPath);
    }
    // 경력
    else if (_scenarioId[0].includes("career")) {
      const fullPath = `career[${indices.careerIndex}].${path}`;
      settingValue(fullPath, _inputValue);
      unFulledInput(message, fullPath);
    }
    // 자격증
    else if (_scenarioId[0].includes("cert")) {
      const fullPath = `certificate[${indices.certIndex}].${path}`;
      settingValue(fullPath, _inputValue);
      unFulledInput(message, fullPath);
    }
    // 수상내역
    else if (_scenarioId[0].includes("award")) {
      const fullPath = `prize[${indices.awardIndex}].${path}`;
      settingValue(fullPath, _inputValue);
      unFulledInput(message, fullPath);
    }
    // 어학
    else if (_scenarioId[0].includes("lang")) {
      const fullPath = `language[${indices.langIndex}].${path}`;
      settingValue(fullPath, _inputValue);
      unFulledInput(message, fullPath);
    } else {
      settingValue(_scenarioId[0], _inputValue);
      unFulledInput(message);
    }

    setCareerHistory(watchField.career);
    setCertificateHistory(watchField.certificate);
    setAwardsHistory(watchField.prize);
    setLangHistory(watchField.language);
  };

  const deleteType = (type) => {
    let del = false;

    const updatedData = messages.reduce((acc, item) => {
      const filteredContent = item.content.filter(
        (contentItem) => !type.includes(contentItem.type)
      );
      acc.push({ ...item, content: filteredContent });
      return acc;
    }, []);
    setMessages(updatedData);
  };

  const createUserChat = (text, file, type) => {
    if (type === "text")
      return {
        role: "user",
        content: [
          {
            type: "text",
            message: text,
          },
        ],
      };
  };

  const AlertError = () => {
    if (errors.education) {
      if (errors.education.high_school) {
        alert("고등학교 입력정보를 확인해주세요.");
      } else if (errors.education.university) {
        alert("대학교 입력정보를 확인해주세요.");
      } else if (errors.education.graduate) {
        alert("대학원 입력정보를 확인해주세요.");
      }
    } else if (errors.career) {
      alert("경력사항 입력정보를 확인해주세요.");
    } else if (errors.certificate) {
      alert("자격증 입력정보를 확인해주세요.");
    } else if (errors.prize) {
      alert("수상내역 입력정보를 확인해주세요.");
    } else if (errors.language) {
      alert("어학 입력정보를 확인해주세요.");
    } else if (errors.military) {
      alert("병역사항 입력정보를 확인해주세요.");
    } else if (errors.veteran) {
      alert("보훈사항 입력정보를 확인해주세요.");
    } else if (errors.introduction) {
      alert("자기소개서 입력정보를 확인해주세요.");
    }
  };

  const { uploadImage, deleteImage } = useImageFile(
    resumeRef,
    setResume,
    methods,
    undefined,
    resumeOcr,
    ["pdf"]
  );

  const onSubmit = async (data) => {
    if (agree !== true) {
      openModal({
        title: "알람",
        content: <p>허위사실 기재가 아님에 동의하셔야 이력서 등록이 됩니다.</p>,
        buttons: [
          {
            label: "확인",
            className: "confirm-button",
            onClick: closeModal,
          },
        ],
      });
    } else {
      try {
        const resumeData = {
          profile: {
            member_name: "",
            member_birthdate: "",
            member_phone: "",
            member_email: "",
            member_file_path: "",
            member_image: "",
          },
          education: {
            high_school: {
              admission_status: "",
              school_name: "",
              affiliation: "",
              major: "",
              admission_date: "",
              graduate_status: "",
              graduate_year: "",
            },
            university: [
              {
                admission_status: "",
                gubun: "",
                school_name: "",
                admission_date: "",
                graduate_status: "",
                graduate_year: "",
                major: "",
                affiliation: "",
                grade: "",
                total_grade: "",
                add_major: [
                  {
                    major_status: "",
                    major_name: "",
                    major_affiliation: "",
                  },
                ],
              },
            ],
            graduate: [
              {
                admission_status: "",
                school_name: "",
                admission_date: "",
                graduate_status: "",
                graduate_year: "",
                major: "",
                affiliation: "",
                grade: "",
                total_grade: "",
                degree: "",
              },
            ],
          },
          career: [
            {
              company_name: "",
              department: "",
              duty: "",
              hired_date: "",
              employment_status: "",
              resignation_date: "",
              salary: 0,
            },
          ],
          comskill: {
            word_ability: "",
            excel_ability: "",
            ppt_ability: "",
          },
          certificate: [
            {
              certificate_name: "",
              certificate_authority: "",
              certificate_acquisition_date: "",
              certificate_gubun: "",
              certificate_level: "",
            },
          ],
          prize: [
            {
              award_name: "",
              award_authority: "",
              award_date: "",
            },
          ],
          language: [
            {
              language_test_name: "",
              language_name: "",
              language_score: "",
              language_acquisition_date: "",
            },
          ],
          military: {
            military_target: "",
            military_category: "",
            military_discharged_rank: "",
            discharge_reason: "",
            military_start_date: "",
            military_end_date: "",
          },
          veteran: {
            has_veterans_affair: "",
            veterans_affair_reason: "",
          },
          introduction: {
            introduction_title: "",
            introduction_content: "",
          },
          desire: [
            {
              depth1: "",
              depth2: "",
              depth3: "",
              depth4: "",
              depth1Name: "",
              depth2Name: "",
              depth3Name: "",
              depth4Name: "",
            },
          ],
        };
        resumeData.profile.member_name = userInfo.member_com.member_nm;
        resumeData.profile.member_phone = userInfo.member_info.phone_no;
        resumeData.profile.member_birthdate =
          userInfo.disabili_certifica.birthday;
        resumeData.profile.member_email = userInfo.member_com.email;
        resumeData.profile.member_file_path =
          userInfo.disabili_certifica.crtf_file_path;
        resumeData.profile.member_image =
          userInfo.disabili_certifica.crtf_file_path_nm;
        resumeData.education = data.education;
        resumeData.career = data.career;
        resumeData.comskill = data.comskill;
        resumeData.certificate = data.certificate;
        resumeData.prize = data.prize;
        resumeData.language = data.language;
        resumeData.military = data.military;
        resumeData.veteran = data.veteran;
        resumeData.introduction = data.introduction;

        resumeData.desire = JSON.parse(localStorage.getItem("desiredJobCode"));
        /*if (data.education.university === undefined) {
                    resumeData.education.university = null;
                }*/
        await api.post("/api/resume_new/createProfile", resumeData);
        localStorage.removeItem("resumeData");
        alert("이력서 등록 성공");
        history.push("/user/resume/beforeResume");
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }

    setIsSubmit(true);
  };

  const onError = () => {
    setIsSubmit(true);
  };

  const { openModal, closeModal, ModalComponent } = useModal();

  const handleCancel = () => {
    openModal({
      title: "이력서 작성 취소",
      content: <p>정말로 이력서 작성을 취소하시겠습니까?</p>,
      buttons: [
        {
          label: "확인",
          className: "confirm-button",
          onClick: () => {
            closeModal();
            history.push(goBackUrl);
          },
        },
        {
          label: "취소",
          className: "cancel-button",
          onClick: closeModal,
        },
      ],
    });
  };

  const handleGoBack = () => {
    openModal({
      title: "이력서 작성 취소",
      content: (
        <p>
          이력서 작성을 다시 시작하시겠습니까?
          <br />
          작성된 이력서는 저장되지 않습니다.
        </p>
      ),
      buttons: [
        {
          label: "확인",
          className: "confirm-button",
          onClick: () => {
            closeModal();
            history.push("/user/resume/interResume");
          },
        },
        {
          label: "취소",
          className: "cancel-button",
          onClick: closeModal,
        },
      ],
    });
  };

  return (
    <FormProvider value={methods}>
      <div className="wrapper">
        <main>
          <div className="page join page_resume_write page_join_chat page_layout_divided">
            <h2 className="page_back_title">
              <a href={goBackUrl} target="_self" className="page_back">
                이력서 작성하기
              </a>
            </h2>

            <div className="wrap_progressbar">
              <ul className="progressbar_list progress_step03">
                <li className="progressbar_item progress_item01">
                  <p>희망직무 선택</p>
                </li>
                <li className="progressbar_item progress_item02">
                  <p>진행방식 선택</p>
                </li>
                <li className="progressbar_item progress_item03">
                  <p>이력서 작성</p>
                </li>
              </ul>
            </div>

            <h3 className="page_title">이력서를 작성해 보세요!</h3>

            <div className="page_inner conversation">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="conversaiton_write">
                  <div className="area_shade">
                    <button
                      type="button"
                      className="btn_conversation_close"
                      title="입력폼 닫기"
                    ></button>
                    <div className="wrap_signup_input wrap_signup_basic">
                      <BasicInfo
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        userInfo={userInfo}
                        watchField={watchField}
                        methods={methods}
                      />
                      <Education
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        watchField={watchField}
                        educationalHistory={educationalHistory}
                        autoComplete={autoCompleteInfo.majors}
                        setFocus={setFocus}
                        readOnly={true}
                        majorIndex={indices.majorIndex}
                      />
                      <Career
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        watchField={watchField}
                        modal={modal}
                        setModal={setModal}
                        careerHistory={careerHistory}
                        readOnly={true}
                        careerIndexAtChat={indices.careerIndex}
                      />
                      <ComputerSkill
                        register={register}
                        control={control}
                        errors={errors}
                        readOnly={true}
                      />
                      <ResumeCertificate
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        watchField={watchField}
                        autoComplete={autoCompleteInfo.certifications}
                        setFocus={setFocus}
                        readOnly={true}
                        certificateHistory={certificateHistory}
                      />
                      <Awards
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        watchField={watchField}
                        readOnly={true}
                        awardsHistory={awardsHistory}
                      />
                      <Language
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        watchField={watchField}
                        readOnly={true}
                        langHistory={langHistory}
                      />
                      <MilitaryService
                        register={register}
                        control={control}
                        errors={errors}
                        watchField={watchField}
                        setValue={setValue}
                        readOnly={true}
                      />
                      <VeteransAffairs
                        register={register}
                        control={control}
                        errors={errors}
                        watchField={watchField}
                        readOnly={true}
                      />
                      <SelfIntroduction
                        register={register}
                        control={control}
                        errors={errors}
                        readOnly={true}
                      />
                      <div className="wrap_chk_agree">
                        <p>상기 내용이 허위사실이 아님을 증명합니다.</p>
                        <div className="box_chk_agree">
                          <div className="box_radio">
                            <input
                              type="radio"
                              className="btn_type_radio"
                              id="agree01"
                              name="agree01"
                              onChange={() => setAgree(true)}
                            ></input>
                            <label htmlFor="agree01" className="label_txt">
                              동의합니다.
                            </label>
                          </div>
                          <div className="box_radio">
                            <input
                              type="radio"
                              className="btn_type_radio"
                              id="agree02"
                              name="agree01"
                              onChange={() => setAgree(false)}
                              onClick={message}
                            ></input>
                            <label htmlFor="agree02" className="label_txt">
                              동의하지 않습니다.
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<div className="wrap_btns">
                                            <a href="/user/resume/beforeResume" target="_self" className="btn_submit filled_black">작성 취소</a>
                                            <button style={ agree === false ? {pointerEvents: "none"} : {pointerEvents: "auto"}}
                                                    type={ agree === false ? "button" : "submit" } className="btn_submit filled_green">이력서 등록</button>
                                        </div>*/}
                  <div className="wrap_btns">
                    <button
                      type="button"
                      className="btn_submit filled_black"
                      onClick={handleCancel}
                    >
                      작성 취소
                    </button>
                    <button
                      type="submit"
                      disabled={modal}
                      //style={agree === true ? {pointerEvents: "auto"} : {pointerEvents: "none"}}
                      className="btn_submit filled_green"
                    >
                      이력서 등록
                    </button>
                  </div>
                </div>
              </form>
              <div className="conversation_chat">
                <div className="area_shade">
                  <div className="message_container">
                    <div className="area_chat" ref={targetRef}>
                      {messages.map((message, index) => {
                        const isLastItem = index === messages?.length - 1;
                        if (message.role === "user")
                          return (
                            <div className="send_message_area">
                              <div className="send_message_wrap">
                                {message.content.map((item) => {
                                  if (item.type === "text")
                                    return (
                                      <div className="send_message_box">
                                        <div className="send_message_text">
                                          {item.message}
                                        </div>
                                        <div className="send_message_date">
                                          {dayjs(new Date()).format("A hh:mm")}
                                        </div>
                                      </div>
                                    );
                                  if (item.type === "deleted")
                                    return (
                                      <div className="send_message_box">
                                        <div className="box_chat_bg">
                                          <p className="attached_note">
                                            {" "}
                                            파일이 삭제되었습니다.
                                          </p>
                                        </div>
                                      </div>
                                    );
                                })}
                              </div>
                            </div>
                          );
                        else
                          return (
                            <div className="chat_message_area">
                              <div className="chat_message_wrap">
                                <div className="chat_message_profile">
                                  <div className="chatbot_photo"></div>
                                  <div className="chatbot_name">
                                    드리미 챗봇
                                  </div>
                                </div>
                                {message.content.map((item) => {
                                  if (item.type === "text")
                                    return (
                                      <div
                                        className="chat_message_box"
                                        tabIndex={0}
                                      >
                                        <div className="chat_message_text">
                                          {item.message}
                                        </div>
                                        <div className="chat_message_date">
                                          {dayjs(new Date()).format("A hh:mm")}
                                        </div>
                                      </div>
                                    );
                                  if (item.type === "autoComplete") {
                                    return (
                                      <>
                                        <div
                                          className="chat_message_box"
                                          tabIndex={0}
                                        >
                                          <div className="chat_message_text">
                                            {item.message}
                                          </div>
                                          <div className="chat_message_date">
                                            {dayjs(new Date()).format(
                                              "A hh:mm"
                                            )}
                                          </div>
                                        </div>
                                        {scenarioId.includes(
                                          "education.high_school.major"
                                        ) &&
                                          isLastItem && (
                                            <Option
                                              className="chat-dropdown-options"
                                              inputName={`education.high_school.major`}
                                              inputValue={inputValue}
                                              majorInfo={
                                                autoCompleteInfo.majors
                                              }
                                              schoolGroup="H"
                                              setValue={setValue}
                                            />
                                          )}
                                        {scenarioId.includes(
                                          `education.university.major`
                                        ) &&
                                          isLastItem && (
                                            <Option
                                              className="chat-dropdown-options"
                                              inputName={`education.university[${universityIndex}].major`}
                                              inputValue={inputValue}
                                              majorInfo={
                                                autoCompleteInfo.majors
                                              }
                                              schoolGroup="U"
                                              setValue={setValue}
                                            />
                                          )}
                                        {scenarioId.includes(
                                          `education.university.add_major.major_name`
                                        ) &&
                                          isLastItem && (
                                            <Option
                                              className="chat-dropdown-options"
                                              inputName={`education.university[${universityIndex}].add_major[${indices.majorIndex}].major_name`}
                                              inputValue={inputValue}
                                              majorInfo={
                                                autoCompleteInfo.majors
                                              }
                                              schoolGroup="U"
                                              setValue={setValue}
                                            />
                                          )}
                                        {scenarioId.includes(
                                          "certificate.certificate_name"
                                        ) &&
                                          isLastItem && (
                                            <AutoCompleteOption
                                              inputName={`certificate[${indices.certIndex}].certificate_name`}
                                              inputValue={inputValue}
                                              certificateType={
                                                watchField.certificate[
                                                  indices.certIndex
                                                ].certificate_gubun
                                              }
                                              certificationInfo={
                                                autoCompleteInfo.certifications
                                              }
                                              setValue={setValue}
                                            />
                                          )}
                                      </>
                                    );
                                  }

                                  if (item.type === "file")
                                    return (
                                      <div className="chat_message_box">
                                        <div className="box_chat_bg">
                                          <div className="wrap_attached_file">
                                            {resume === null && (
                                              <div className="box_attached_file">
                                                <input
                                                  type="file"
                                                  id="attachedFileCertificate"
                                                  className="attached_file btn_submit filled_green"
                                                  ref={resumeRef}
                                                  onChange={uploadImage}
                                                  accept=".pdf"
                                                  multiple
                                                />
                                                <p className="attached_note">
                                                  ※ PDF 형식의 파일만 업로드
                                                  가능합니다.
                                                </p>
                                              </div>
                                            )}
                                            {resume !== null && (
                                              <div className="attached_file_viewer">
                                                <ul className="attached_file_list">
                                                  <li className="attached_file_item">
                                                    <a
                                                      href="#none"
                                                      target="_self"
                                                      className="attached_anchor"
                                                    >
                                                      <span>{resume.name}</span>
                                                    </a>
                                                    <button
                                                      className="btn_attached_delete certificate"
                                                      title="첨부파일 삭제"
                                                      type="button"
                                                      onClick={deleteImage}
                                                    ></button>
                                                  </li>
                                                </ul>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  if (item.type === "resume")
                                    return (
                                      <div className="chat_message_box">
                                        <div className="box_chat_bg">
                                          <div className="box_resume">
                                            <div className="resume_write_delete">
                                              <button
                                                type="button"
                                                className="btn_resume_write"
                                                title="이력서 수정"
                                              ></button>
                                            </div>
                                            <a
                                              href="#"
                                              className="resume_detail"
                                            >
                                              <span className="resume_title">
                                                {resume
                                                  ? resume.name
                                                  : "새 이력서"}
                                              </span>
                                              <span className="resume_written_date">
                                                {dayjs(new Date()).format(
                                                  "YYYY-MM-DD"
                                                )}
                                              </span>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  if (item.type === "button")
                                    return (
                                      <div className="chat_message_box">
                                        <div className="wrap_btn">
                                          {item.buttons.map((button) => (
                                            <button
                                              className="btn_inline"
                                              type="button"
                                              onClick={() =>
                                                handleButton(
                                                  button.action,
                                                  button.message,
                                                  button.status
                                                )
                                              }
                                            >
                                              {button.message}
                                            </button>
                                          ))}
                                        </div>
                                      </div>
                                    );
                                })}
                              </div>
                            </div>
                          );
                      })}
                    </div>
                    <div className="area_send">
                      <div className="wrap_send_message">
                        <input
                          type="text"
                          id=""
                          className=""
                          placeholder="도움이 필요하신가요?"
                          value={inputValue}
                          onChange={handleChangeInput}
                          onKeyPress={handleOnKeyPress}
                        />
                        <button
                          type="button"
                          className="btn_submit filled_green"
                          onClick={handleSendBtn}
                        >
                          전송
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="wrap_btns">
                    <button
                      type="button"
                      className="btn_submit filled_black"
                      onClick={handleGoBack}
                    >
                      처음부터
                    </button>
                    <button
                      type="button"
                      className="btn_submit filled_green"
                      onClick={handleCancel}
                    >
                      대화종료
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ModalComponent />
    </FormProvider>
  );
};

export default InterRegistedResume;
