import React, { Component, useState } from 'react';


const SignupSuccess = () => {
    return (
        <div>
            <div className="wrapper">
                <main>
                    <div className="join page_join page_join_complete">
                        <h2 className="page_title">회원 가입하기</h2>
                        <div className="page_inner">
                            <div className="area_shade">
                                <div className="wrap_txt">
                                    <h3>회원가입이 완료되었습니다.</h3>
                                    <p>회원이 되신 것을 환영합니다!<br/>
                                    {
                                        //브이드림의 다양한 서비스를 만나보세요.</p>
                                    }
                                        다양한 서비스를 만나보세요.</p>
                                </div>
                            </div>
                            <div className="wrap_btns flex_center">
                                <a href="/user/login/LoginForm" target="_self" className="btn_submit filled_green ">로그인</a>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default SignupSuccess;