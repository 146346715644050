import React, {useEffect, useState, useRef} from 'react';
import api from "../../../api/api";
import Pagination from "../../user/resume/Pagination";
import {useParams, useHistory } from "react-router-dom";
import {getCompInfo, getJobRequestList} from "../../../api/Comp/CompSignup/CompSignup";


const ChooseJobRequestUpdate = () => {
    const {recruitSeq} = useParams();
    const beforeUrl= "/comp/jobRequest/JobApplicationList"
    const[defaultCheck, setDefaultCheck] = useState(true);
    const [selectLCate, setSelectLCate] = useState([]);
    const [selectMCate, setSelectMCate] = useState([]);
    const [selectSCate, setSelectSCate] = useState([]);
    const [selectDCate, setSelectDCate] = useState([]);
    const [selectedCheckbox, setSelectedCheckbox] = useState('');
    const [visiblePageCount, setVisiblePageCount] = useState(10);
    const [inputText, setInputText] = useState("");
    const [inputCateCode, setinputCateCode] = useState("");
    const [checkboxValue, setCheckboxValue] = useState(selectedCheckbox);
    const [selectedDCateData, setSelectedDCateData] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const offset = (page - 1) * limit;
    const [Desired, setDesired] = useState({
        l_cate_cdnm: '',
        m_cate_cdnm: '',
        s_cate_cdnm: '',
        d_cate_cdnm: ''
    });
    const [updateDesired, setUpdateDesired] = useState({
        l_cate_cdnm: '',
        m_cate_cdnm: '',
        s_cate_cdnm: '',
        d_cate_cdnm: ''
    });
    const[desireCode, setDesireCode]= useState(null);

    const mCateRef = useRef(null);
    const sCateRef = useRef(null);
    const history = useHistory();

    const activeEnter = (e) => {
        if (e.key === "Enter") {
            searchButton();
        }
    }

    useEffect(() => {
        try {
            const url = 'api/comp/matching/getRecruit?seq=' + recruitSeq;
            api.post(url)
                .then(response => response.data.resultData.desire)
                .then(responseData => {
                    setUpdateDesired({
                        l_cate_cdnm: responseData.depth1_name,
                        m_cate_cdnm: responseData.depth2_name,
                        s_cate_cdnm: responseData.depth3_name,
                        d_cate_cdnm: responseData.depth4_name
                    });
                    setDesireCode(`${responseData.depth1}${responseData.depth2}${responseData.depth3}${responseData.depth4}`);
                    setSelectedDCateData(responseData.depth4_name);
                })
                .catch(error => {
                    console.log("Error fetching data:", error);
                });
        } catch (e) {
            console.log(e)
        }
    }, []);


    useEffect(()=>{
        try{
            api.post("/api/resume/getLMSDCateList", {
                cate_nm: "",
                cate_seq: desireCode
            }).then(response =>
                response.data
            ).then((response) => {
                setSelectDCate(response.resultData);
            })
        }catch (e){
            console.log(e);
        }
    },[updateDesired])

    const deleteCheck = () => {
        const checkboxes = document.getElementsByName('group')
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false
        }
    };

    const isChecked= ()=> {
        const checkboxes = document.getElementsByName('group')
        let foundChecked = false;
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked === true) {
                foundChecked = true;
                break;
            }
        }
        return foundChecked;
    }


    const onCheckboxChange = (checkboxValue) => {
        //setSelectedCheckbox(checkboxValue);
        setDesired(checkboxValue);
    };
    const handleCheckboxToggle = (e, value) => {
        setCheckboxValue(value);
        const checkboxes = document.getElementsByName('group')
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i] !== e.target) {
                checkboxes[i].checked = false
            }
        }
        onCheckboxChange(value);
    };
    useEffect(() => {
        handleCATE();
    }, []);
    const handleCATE = (e) => {
        api.post("/api/resume/getLCateList", {})
            .then(response => response.data)
            .then((response) => {
                setSelectLCate(response.resultData);
            })
    };

    const handleLCATE = (e) => {
        api.post("/api/resume/getMCateList", {
            cate_seq: e.target.value
        }).then(response =>
            response.data
        ).then((response) => {
            setSelectMCate(response.resultData);
            if (mCateRef.current) {
                mCateRef.current.value = '';
                mCateRef.current.selectedIndex = 0;
            }
            setSelectSCate([]);
            setinputCateCode(e.target.value);
            api.post("/api/resume/getLMSDCateList", {
                cate_nm: inputText,
                cate_seq: e.target.value
            })
                .then(response => response.data)
                .then((response) => {
                    setSelectDCate(response.resultData);
                })
        })
    };
    const handleMCATE = (e) => {
        api.post("/api/resume/getSCateList", {
            cate_seq: e.target.value
        }).then(response =>
            response.data
        ).then((response) => {
            setSelectSCate(response.resultData);
            if (sCateRef.current) {
                sCateRef.current.value = ''; // Reset the value
                sCateRef.current.selectedIndex = 0; // Set the first option as selected
            }
            setinputCateCode(e.target.value);
            api.post("/api/resume/getLMSDCateList", {
                cate_nm: inputText,
                cate_seq: e.target.value
            })
                .then(response => response.data)
                .then((response) => {
                    setSelectDCate(response.resultData);
                })
        })
    };

    const handleSCATE = (e) => {
        api.post("/api/resume/getDCateList", {
            cate_seq: e.target.value
        }).then(response =>
            response.data
        ).then((response) => {
            setSelectDCate(response.resultData);
            setinputCateCode(e.target.value);
            api.post("/api/resume/getLMSDCateList", {
                cate_nm: inputText,
                cate_seq: e.target.value
            })
                .then(response => response.data)
                .then((response) => {
                    setSelectDCate(response.resultData);
                })
        })
    };

    const searchButton = (e) => {
        if (e)
            e.preventDefault();
        api.post("/api/resume/getLMSDCateList", {
            cate_nm: inputText,
            cate_seq: inputCateCode
        }).then(response =>
            response.data
        ).then((response) => {
            setSelectDCate(response.resultData);
            console.log(response.resultData);
        })
        deleteCheck();
    };

    async function alertMessage() {
        const desiredToUse = Desired.d_cate_cdnm!=='' ? Desired : updateDesired;
        console.log(isChecked())
        if (desiredToUse.d_cate_cdnm !== '' && isChecked()) {
            window.localStorage.removeItem('compDesiredJobCode');
            const desiredArr = {
                depth1: desiredToUse.l_cate_cdnm.split(".")[0],
                depth2: desiredToUse.m_cate_cdnm.split(".")[0],
                depth3: desiredToUse.s_cate_cdnm.split(".")[0],
                depth4: desiredToUse.d_cate_cdnm.split(".")[0],
                depth1_name: desiredToUse.l_cate_cdnm,
                depth2_name: desiredToUse.m_cate_cdnm,
                depth3_name: desiredToUse.s_cate_cdnm,
                depth4_name: desiredToUse.d_cate_cdnm
            };
            window.localStorage.setItem('compDesiredJobCode', JSON.stringify(desiredArr));
            window.location.href = `/comp/jobRequest/jobRequestUpdate/${recruitSeq}`;
            history.push(`/comp/jobRequest/jobRequestUpdate/${recruitSeq}`)
        } else {
            alert("희망직무 선택은 필수입니다.");
        }
    }


    return (
        <div className="wrapper">
            <main>
                <div className="page_job_request page_layout_40">
                    <div className="page_back_title">
                        <a href={beforeUrl} target="_self" className="page_back">요청 채용직무 선택</a>
                    </div>
                    <div className="wrap_progressbar">
                        <ul className="progressbar_list progress_step01">
                            <li className="progressbar_item progress_item01"><p>요청직무 선택</p></li>
                            <li className="progressbar_item progress_item02"><p>직무요청서 작성</p></li>
                        </ul>
                    </div>
                    <h3 className="page_title">요청직무를 선택해 주세요.</h3>
                    <div className="page_inner">
                        <div className="area_shade">
                            <div className="wrap_box_search">
                                <div className="box_division">
                                    <div className="wrap_box_input">
                                        <div className="box_input">
                                            <label htmlFor="" className="">대분류 </label>
                                            <select onChange={handleLCATE} defaultValue=''>
                                                <option value=''>대분류를 선택해주세요.</option>
                                                {selectLCate.map(item => (
                                                    <option key={item.cate_cd}
                                                            value={item.cate_seq}>{item.cate_cdnm}</option>
                                                ))}

                                            </select>
                                        </div>
                                    </div>
                                    <div className="wrap_box_input">
                                        <div className="box_input">
                                            <label htmlFor="" className="">중분류</label>
                                            <select ref={mCateRef} onChange={handleMCATE} defaultValue=''>
                                                <option value=''>중분류를 선택해주세요.</option>
                                                {selectMCate.map(item => (
                                                    <option key={item.cate_cd}
                                                            value={item.cate_seq}>{item.cate_cdnm}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="wrap_box_input">
                                        <div className="box_input">
                                            <label htmlFor="" className="">소분류</label>
                                            <select ref={sCateRef} onChange={handleSCATE} defaultValue=''>
                                                <option value=''>소분류를 선택해주세요.</option>
                                                {selectSCate.map(item => (
                                                    <option key={item.cate_cd}
                                                            value={item.cate_seq}>{item.cate_cdnm}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="wrap_box_input wrap_box_input_text">
                                        <div className="box_input">
                                            <label htmlFor="" className=""></label>
                                            <input type="text" id="dCateNm" className="" name="dCateNm"
                                                   onChange={(e) => setInputText(e.target.value)}
                                                   onKeyDown={(e) => activeEnter(e)}
                                                   placeholder="검색어 예시: 사무, 디자인, 마케팅"/>
                                        </div>
                                    </div>
                                </div>
                                <a href="#" target="_self" className="btn_submit filled_green"
                                   onClick={searchButton}> 검색하기</a>
                            </div>

                            <div className="guide_exam">
                                <p>※장애예술인 채용을 희망하신다면 반드시 아래 예시를 포함해 선택해 주시길 바랍니다.</p>
                                <p>(예시:
                                    음악 > 08.문화·예술·방송·디자인 > 01.문화·예술 > 03.공연예술<span>/</span>미술 > 08.문화·예술·방송·디자인 > 02.디자인
                                    > 01.디자인)
                                </p>
                                <br></br>
                                <br></br>
                            </div>

                            <div className="wrap_box_search_result">
                                <div className="box_search_result">
                                    <table className="table_detail">
                                        <colgroup>
                                            <col style={{width: '64px'}}/>
                                            <col style={{width: '64px'}}/>
                                            <col style={{width: 'auto'}}/>
                                            <col style={{width: 'auto'}}/>
                                            <col style={{width: 'auto'}}/>
                                            {/*<col style={{width: 'auto'}}/>*/}
                                        </colgroup>
                                        <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">번호</th>
                                            <th scope="col">대분류</th>
                                            <th scope="col">중분류</th>
                                            <th scope="col">소분류</th>
                                            {/*<th scope="col">세분류</th>*/}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            selectDCate.slice(offset, offset + limit).map((item, i) => {
                                                return (
                                                    <tr key={item}>
                                                        <td>
                                                            <div className="box_input_chk">
                                                                <label htmlFor={"group" + i}
                                                                       className="input_chk_label">
                                                                    <input type="checkbox" className="input_chk"
                                                                           id={"group" + i} name="group"
                                                                           onChange={(e) => handleCheckboxToggle(e, item)}
                                                                           defaultChecked={defaultCheck && i === 0}
                                                                    />
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>{(page - 1) * limit + i + 1}</td>
                                                        <td>{item.l_cate_cdnm}</td>
                                                        <td>{item.m_cate_cdnm}</td>
                                                        <td>{item.s_cate_cdnm}</td>
                                                        {/*<td>{item.d_cate_cdnm}</td>*/}
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>

                                    </table>
                                </div>
                            </div>

                            <div className="wrap_pagination">
                                <Pagination
                                    total={selectDCate.length}
                                    limit={limit}
                                    page={page}
                                    setPage={setPage}
                                    visiblePageCount={visiblePageCount}
                                />
                            </div>

                        </div>

                        <div className="wrap_btns flex_ed">
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                alertMessage();
                            }} target="_self" className="btn_submit filled_green">다음으로</a>

                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
export default ChooseJobRequestUpdate;





