import React, { useState,useEffect } from 'react';
import api, { decodeToken } from '../../api/api';


const LeftToggleAfterUserLogin = (props) => {
    const userString = localStorage.getItem('user');
    const userObject = JSON.parse(userString);
    const userName = userObject?.username;
    const dToken = decodeToken(localStorage.getItem("accessToken"));
    const [checkResume, setCheckResume] = useState(false);
    const [checkAbility, setCheckAbility] = useState(0);
    const [profileImg, setProfileImg] = useState(false);
    useEffect(() => {
        api.post("/api/signup/getUserInfo").then(response => 
            response.data
        ).then((response) => {
            if(response.resultCode===1000) {
                setProfileImg(response.resultData.member_info.image_file_path);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }, []);
    useEffect(() => {
        try {
            api.post('/api/resume_new/getProfileList',{}).then(response => 
                response.data
            ).then((response) => {
                if(response.resultData.length>0) {
                    setCheckResume(true)
                    localStorage.setItem("checkResume", true)
                } else {
                    localStorage.setItem("checkResume", false)
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        } catch (e) {
            console.log(e);
        }

        api.post("/api/ability/abilityChk").then(response => 
            response.data
        ).then((response) => {
            if(response.resultCode===1000) {
                setCheckAbility(1);
                localStorage.setItem("checkAbility", true)
            }
            else if(localStorage.getItem('abilityResult_'+dToken.sub)!==undefined) {
                setCheckAbility(-1);
                localStorage.setItem("checkAbility", false)
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }, []);

    const checkAbilityResume = () => {
        if(!checkResume || checkAbility <= 0){
            props.setModal(true);
            if(!checkResume && checkAbility <=0){
                props.setModalType(3);
            } else if(!checkResume) {
                props.setModalType(2);
            } else if(checkAbility <= 0){
                props.setModalType(1);
            }
        }
        else{
            window.location.href="/user/job/main"
        }
    }

    const chkResume = () => {
        if(!checkResume) {
            props.setModal(true);
            props.setModalType(4);
        }
        else{
            window.location.href="/user/ability/main"
        }
    }
  return (
    <>
            <div className="after_login">
                <div className="wrap_profile">
                    <div className="box_profile">
                        {
                            profileImg!=false ? (<img src={profileImg} alt="프로필"></img>) : (<img src="/img/icon/icon_profile.png" alt="프로필"></img>)
                        }
                        
                    </div>
                    <h4>안녕하세요. <span className="userinfo_name">{userName}</span>님.</h4>
                </div>
                <div className="wrap_my_regist">
                    <h3>나의 구직 활동</h3>
                    <ul className="my_regist_list">
                        <li className="my_regist_item">
                            <a href="/user/signup/UserInfo" target="_self" className="my_regist_anchor">내 정보 관리</a>
                            <i className="regist_status success"></i>
                        </li>
                        <li className="my_regist_item">
                            <a href="/user/resume/beforeResume" target="_self" className="my_regist_anchor">이력서 관리</a>                           
                            {
                                checkResume ?
                                <i className="regist_status success"></i>
                                : <i className="regist_status wraning"></i>
                            }
                        </li>
                        <li className="my_regist_item">
                            <a href="#" target="_self" className="my_regist_anchor" onClick={(e) => {e.preventDefault(); chkResume();}}>역량평가</a>
                            {
                                checkAbility === 1 ?
                                <i className="regist_status success"></i>
                                : <i className="regist_status wraning"></i>
                            }
                        </li>
                        <li className="my_regist_item">
                            <a href="#" target="_self" className="my_regist_anchor" onClick={(e) => {e.preventDefault(); checkAbilityResume();}}>직무추천</a>
                            <i className="regist_status normal"></i>
                        </li>
                        <li className="my_regist_item">
                            <a href="/user/job/recruitInfo" target="_self" className="my_regist_anchor">채용정보</a>
                            <i className="regist_status normal"></i>
                        </li>
                        <li className="my_regist_item">
                            <a href="/user/interview/InterviewMain" target="_self" className="my_regist_anchor">지원 및 면접현황</a>
                            <i className="regist_status normal"></i>
                        </li>
                        <li className="my_regist_item">
                            <a href="/user/lms/myLecture" target="_self" className="my_regist_anchor">내 강의실</a>   
                            <i className="regist_status normal"></i>
                        </li>
                    </ul>
                </div>
            </div>
    </>
        
      
  );
};

export default LeftToggleAfterUserLogin;












