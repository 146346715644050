import React, {useRef} from "react";
import {useFormContext} from "react-hook-form";
import {useImageFile} from "../../../hooks/useImageFile";

const AddInfo = (props) => {
    const { image, setImage, isUserInfo } = props;
    const methods = useFormContext();
    const { register, setValue, watch, handleSubmit, clearErrors, reset, setError, formState: { errors }} = methods;
    const imageInput = useRef(null);
    const watchField = watch();

    const setImageFile = (image) => {
        clearErrors(["member_info.image_file_path_nm", "member_info.image_file_path"])
        setValue("member_info.image_file_path_nm", image.name, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
    }

    const deleteImage = () => {
        setValue("member_info.image_file_path_nm", "", {shouldValidate: true, shouldDirty: true, shouldTouch: true});
        setValue("member_info.image_file_path", "", {shouldValidate: true, shouldDirty: true, shouldTouch: true});
        setImage(null);
    }

    const { uploadBase64 } = useImageFile(imageInput, setImage, methods, "member_info.image_file_path", setImageFile, ['jpg', 'jpeg', 'png']);

    const onErrorImg = (e) => {
        e.target.src = "/img/icon/photo_thumbnail.png"
    }

    return (
        <div className="wrap_signup_input wrap_signup_add">
            <div className="box_sub_title">
                <h4 className="sub_title_signup">부가정보 등록</h4>
            </div>
            <div className="box_division">
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="">보호자 이름</label>
                        <input type="text" id="member_info.protector_nm" name="member_info.protector_nm" placeholder="보호자 이름을 입력해 주세요."  {...register("member_info.protector_nm")}/>
                    </div>
                </div>
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="">관계</label>
                        <select name="member_info.protector_relation" id="member_info.protector_relation" className="select" {...register("member_info.protector_relation")}>
                            <option value="부">부</option>
                            <option value="모">모</option>
                            <option value="형제/자매">형제/자매</option>
                            <option value="조부모">조부모</option>
                            <option value="복지사">복지사</option>
                            <option value="기타">기타</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="box_division">
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="">보호자 연락처</label>
                        <input type="text" id="member_info.protector_phone" name="member_info.protector_phone" placeholder="휴대폰 번호를 입력해 주세요."
                               {...register("member_info.protector_phone",{
                                   onChange : (e) => { e.target.value = e.target.value.replace(/[^0-9]/g,'')}
                               })}/>
                    </div>
                </div>
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="">보호장구</label>
                        <select name="member_info.equipment_st" id="member_info.equipment_st" className="select" {...register("member_info.equipment_st")}>
                            <option value="휠체어">휠체어</option>
                            <option value="보조기">보조기</option>
                            <option value="목발">목발</option>
                            <option value="의수">의수</option>
                            <option value="의족">의족</option>
                            <option value="스틱">스틱</option>
                            <option value="보청기">보청기</option>
                            <option value="흰지팡이">흰지팡이</option>
                            <option value="안내견">안내견</option>
                            <option value="기타">기타</option>
                            <option value="사용안함">사용안함</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="box_division">
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="" className="">유입경로</label>
                        <select name="member_info.inflow" id="member_info.inflow" className="select" {...register("member_info.inflow")}>
                            <option value="해당없음">해당없음</option>
                            <option value="삼장사 카페">삼장사 카페</option>
                            <option value="기사">기사</option>
                            <option value="소개">소개</option>
                            <option value="블로그">블로그</option>
                            <option value="네이버카페">네이버카페</option>
                            <option value="인스타그램">인스타그램</option>
                            <option value="페이스북">페이스북</option>
                            <option value="유튜브">유튜브</option>
                            <option value="복지관">복지관</option>
                            <option value="검색">검색</option>
                        </select>
                    </div>
                </div>
                <div className="wrap_box_input">
                    <div className="box_input">
                    </div>
                </div>
            </div>
            <div className="wrap_box_input">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">이력서 사진</label>
                </div>
                <div className="wrap_attached_file">
                    {(image === null && isUserInfo === false) &&
                    <div className="box_attached_file">
                        <input type="file"
                               style={{ display: "none" }} ref={imageInput} onChange={uploadBase64} accept='.jpg, .png'/>
                        <button id="member_info.image"  type="button" className="attached_file btn_submit filled_green" onClick={() => {imageInput.current.click()}}> 파일첨부 </button>
                        <p className="attached_note">※ PNG, JPG 형식의 파일만 업로드 가능합니다.<br />※ 10MB 이하만 업로드 가능합니다.</p>
                    </div>
                    }
                    {(image !== null && isUserInfo === false)  &&
                    <div className="attached_file_viewer">
                        <ul className="attached_file_list">
                            <li className="attached_file_item">
                                <a className="attached_anchor"><span>{image ? image.name : null}</span></a>
                                <button id='imageDelete' className="btn_attached_delete photo"
                                        title="첨부파일 삭제" type="button" onClick={deleteImage}></button>
                            </li>
                        </ul>
                    </div>
                    }
                    {isUserInfo === true &&
                    <div className="wrap_basic_photo">
                        <div id="image_container" className="wrap_thumbnail">
                            <div className="box_thumbnail">
                                <img src={watchField.member_info.image_file_path} alt="" onError={onErrorImg} />
                            </div>
                        </div>
                        <input type="file" id="attachedFileImage" ref={imageInput}
                               onChange={uploadBase64} accept='.jpg, .png'/>
                    </div>
                    }
                </div>
                <p className="msg_err">{errors.member_info?.image_file_path?.message}</p>
            </div>
        </div>
    )
}

export default AddInfo;