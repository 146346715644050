import { useGameTimer } from "../../../hooks/useGameTimer";
import { getImageUrl } from "../../../utils/common";
import NoticeTemplate from "../common/NoticeTemplate";
import Tip from "../common/Tip";
import { INITIAL_COUNTDOWN_SECONDS } from "../games";

export default function CardQuizPreview({ cateIdx, nextPage }) {
  const { GameTimerComponent } = useGameTimer({
    initialDurationInSeconds: INITIAL_COUNTDOWN_SECONDS,
  });

  return (
    <div className="quiz-preview">
      <NoticeTemplate
        title="게임형 평가 시작전 안내사항"
        list={[
          "1. 문항형 평가와 달리 게임형 평가는 시간제한이 있으며 멈춤없이 진행됩니다.",
          "2. 문항형 평가와 달리 게임형 평가는 해당문제의 제한시간 종료후 [다음으로] 기능이 활성화되며, 답변수정 불가하니 신중한 답변을 바랍니다.",
        ]}
      />

      <GameTimerComponent />

      <div className="quiz">
        <div className="quiz-assets card-quiz-grid">
          {[
            "/game/thinking/E/E_preview-5.webp",
            "/game/thinking/E/E_preview-2.webp",
            "/game/thinking/E/E_preview-1.webp",
            "/game/thinking/E/E_preview-3.webp",
            "/game/thinking/E/E_preview-7.webp",
            "/game/thinking/E/E_preview-8.webp",
            "/game/thinking/E/E_preview-4.webp",
            "/game/thinking/E/E_preview-6.webp",
            "/game/thinking/E/E_preview-9.webp",
          ].map((src, index) => (
            <button key={index} className="card-quiz-grid-item">
              <img src={getImageUrl(src)} alt={`card-${index}`} />
            </button>
          ))}
        </div>

        <div className="quiz-answer">
          <h3>
            Q. 주어진 3x3 그림카드들을 주제에 맞게 분류하여, 선택한 그림카드를
            각각 아래 빈칸영역에 마우스 클릭으로 배치하세요.
          </h3>

          <div className="card-quiz-box">
            {["초록", "빨강", "검정"].map((category, index) => (
              <div key={index} className="card-quiz-row">
                <p className="card-quiz-category-text">{category}</p>
                <div>
                  <button className="card-quiz-button"></button>
                  <button className="card-quiz-button"></button>
                  <button className="card-quiz-button"></button>
                </div>
              </div>
            ))}
          </div>
          <div className="wrap_btns" style={{ marginTop: 10 }}>
            <button onClick={nextPage} className="btn_submit filled_black">
              게임시작
            </button>
          </div>
        </div>
      </div>

      <Tip top={140} left={50} arrowDirection="bottom-left">
        마우스 클릭으로 선택한 그림
        <br />
        카드는 빈칸 표시되며, 주제별
        <br />
        분류칸에 이동되어있다.
      </Tip>

      <Tip top={230} right={-80} arrowDirection="left-top">
        그림카드 배치는 최대 3개까지며,
        <br />
        수정을 원할 경우 기존 그림카드 재클릭
        <br />
        하여 분류 해제 후 재배치하세요.
      </Tip>

      <Tip top={480} right={110} arrowDirection="left-top">
        게임 규칙을 이해 완료하였으면
        <br />
        버튼 클릭 후 게임을 시작하세요.
      </Tip>

      {Boolean(cateIdx) && (
        <Tip bottom={-65} right={200} arrowDirection="bottom-right">
          제한시간 이후 버튼 클릭 가능하며,
          <br />
          입력한 답변 확인은 가능하나 답변
          <br />
          수정은 불가합니다.
        </Tip>
      )}

      <Tip bottom={-65} right={-100} arrowDirection="bottom-left">
        버튼 클릭 전까지 입력값 수정
        <br />
        가능하며, 제한시간이 종료된
        <br />
        경우 정답 입력 및 수정이
        <br />
        불가능합니다.
      </Tip>
    </div>
  );
}
