import { useFieldArray } from "react-hook-form";
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { registerLocale } from "react-datepicker";
import dayjs from "dayjs";
import { AutoComplete } from "./Education";
import "../autoComplete.css";
registerLocale("ko", ko);

const University = ({
  register,
  removeUniv,
  control,
  index,
  majorIndex,
  GraduateNum,
  errors,
  watchField,
  setValue,
  autoComplete,
  setFocus,
  readOnly,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `education.university[${index}].add_major`,
  });

  const isUniversitySelected =
    watchField.education.university[index]?.gubun === "4";

  useEffect(() => {
    if (majorIndex !== null) {
      append({
        major_affiliation:
          watchField.education.university[`${index}`].add_major[majorIndex]
            ?.major_affiliation,
        major_name:
          watchField.education.university[`${index}`].add_major[majorIndex]
            ?.major_name,
        major_status:
          watchField.education.university[`${index}`].add_major[majorIndex]
            ?.major_status,
      });
    }
  }, [majorIndex]);

  return (
    <div className="box_education_info">
      <h5 className="history_education_detail">대학교</h5>
      {GraduateNum !== 0 && !readOnly && (
        <button
          className="btn_square_black"
          type="button"
          onClick={() => removeUniv(index)}
        ></button>
      )}
      <div className="box_division">
        <div className="wrap_box_input">
          <div className="box_input">
            <label htmlFor="" className="asterisk">
              입학상태
            </label>
            <select
              name={`education.university[${index}].admission_status`}
              id={`education.university[${index}].admission_status`}
              className="select"
              {...register(`education.university[${index}].admission_status`, {
                required: "필수 입력 항목입니다.",
              })}
              readOnly={readOnly}
              disabled={readOnly}
            >
              <option value="" hidden={true}>
                입학상태를 선택해주세요.
              </option>
              <option value="입학">입학</option>
              <option value="편입">편입</option>
            </select>
          </div>
          {errors?.education?.university &&
            index < errors?.education?.university.length && (
              <p className="msg_err">
                {
                  errors?.education?.university[index]?.admission_status
                    ?.message
                }
              </p>
            )}
        </div>
        <div className="wrap_box_input">
          <div className="box_input">
            <label htmlFor="" className="asterisk">
              학교구분
            </label>
            <select
              name={`education.university[${index}].gubun`}
              id={`education.university[${index}].gubun`}
              className="select"
              {...register(`education.university[${index}].gubun`, {
                required: "필수 입력 항목입니다.",
              })}
              readOnly={readOnly}
              disabled={readOnly}
            >
              <option value="" hidden={true}>
                학교구분을 선택해주세요.
              </option>
              <option value="4">일반(4년제)</option>
              <option value="3">전문대(3년제)</option>
              <option value="2">전문대(2년제)</option>
            </select>
          </div>
          {errors?.education?.university &&
            index < errors?.education?.university.length && (
              <p className="msg_err">
                {errors?.education?.university[index]?.gubun?.message}
              </p>
            )}
        </div>
        <div className="wrap_box_input">
          <div className="box_input">
            <label htmlFor="" className="asterisk">
              학교명
            </label>
            <input
              type="text"
              name={`education.university[${index}].school_name`}
              id={`education.university[${index}].school_name`}
              placeholder="대학교 이름을 입력해주세요."
              {...register(`education.university[${index}].school_name`, {
                required: "필수 입력 항목입니다.",
              })}
              readOnly={readOnly}
              disabled={readOnly}
            ></input>
          </div>
          {errors?.education?.university &&
            index < errors?.education?.university.length && (
              <p className="msg_err">
                {errors?.education?.university[index]?.school_name?.message}
              </p>
            )}
        </div>
      </div>
      <div className="box_division">
        <div className="wrap_box_input">
          <div className="box_input wrap_datepicker">
            <label htmlFor="" className="asterisk">
              입학년월
            </label>
            <input
              type="text"
              name={`education.university[${index}].admission_date`}
              id={`education.university[${index}].admission_date`}
              className="input_datepicker hasDatepicker"
              style={{ display: "none" }}
              placeholder="년도를 입력해주세요."
              {...register(`education.university[${index}].admission_date`, {
                required: "필수 입력 항목입니다.",
              })}
            ></input>
            <DatePicker
              onKeyDown={(e) => {
                console.log(e);
                if (
                  (e.keyCode < 48 || e.keyCode > 57) &&
                  e.keyCode !== 8 &&
                  e.keyCode !== 189
                ) {
                  e.preventDefault();
                }
              }}
              selected={
                watchField.education?.university[index]?.admission_date
                  ? new Date(
                      watchField.education.university[index].admission_date
                    )
                  : null
              }
              onChange={(date) => {
                date &&
                  setValue(
                    `education.university[${index}].admission_date`,
                    dayjs(date).format("YYYY-MM"),
                    { shouldValidate: true }
                  );
              }}
              showMonthYearPicker
              dateFormat="yyyy-MM"
              locale={ko}
              maxDate={
                watchField.education?.university[index]?.graduate_year <
                new Date()
                  ? watchField.education?.university[index]?.graduate_year
                  : new Date()
              }
              placeholderText="년도를 입력해주세요."
              label
              readOnly={readOnly}
              disabled={readOnly}
            />
          </div>
          {errors?.education?.university &&
            index < errors?.education?.university.length && (
              <p className="msg_err">
                {errors?.education?.university[index]?.admission_date?.message}
              </p>
            )}
        </div>
        <div className="wrap_box_input">
          <div className="box_input">
            <label htmlFor="" className="asterisk">
              졸업상태
            </label>
            <select
              name={`education.university[${index}].graduate_status`}
              id={`education.university[${index}].graduate_status`}
              className="select"
              {...register(`education.university[${index}].graduate_status`, {
                required: "필수 입력 항목입니다.",
              })}
              readOnly={readOnly}
              disabled={readOnly}
            >
              <option value="" hidden={true}>
                졸업상태를 선택해주세요.
              </option>
              <option value="졸업">졸업</option>
              <option value="졸업예정">졸업예정</option>
              <option value="재학중">재학중</option>
              <option value="휴학중">휴학중</option>
              <option value="수료">수료</option>
              <option value="중퇴">중퇴</option>
            </select>
          </div>
          {errors?.education?.university &&
            index < errors?.education?.university.length && (
              <p className="msg_err">
                {errors?.education?.university[index]?.graduate_status?.message}
              </p>
            )}
        </div>
        {(watchField.education.university[index].graduate_status === "" ||
          watchField.education.university[index].graduate_status === "졸업" ||
          watchField.education.university[index].graduate_status ===
            "수료") && (
          <>
            <div className="wrap_box_input">
              <div className="box_input wrap_datepicker">
                <label htmlFor="" className="asterisk">
                  졸업년월
                </label>
                <input
                  type="text"
                  name={`education.university[${index}].graduate_year`}
                  id={`education.university[${index}].graduate_year`}
                  className="input_datepicker hasDatepicker"
                  style={{ display: "none" }}
                  placeholder="년도를 입력해주세요."
                  {...register(`education.university[${index}].graduate_year`, {
                    required: "필수 입력 항목입니다.",
                  })}
                ></input>
                <DatePicker
                  onKeyDown={(e) => {
                    console.log(e);
                    if (
                      (e.keyCode < 48 || e.keyCode > 57) &&
                      e.keyCode !== 8 &&
                      e.keyCode !== 189
                    ) {
                      e.preventDefault();
                    }
                  }}
                  selected={
                    watchField.education?.university[index]?.graduate_year
                      ? new Date(
                          watchField.education.university[index].graduate_year
                        )
                      : null
                  }
                  onChange={(date) => {
                    date &&
                      setValue(
                        `education.university[${index}].graduate_year`,
                        dayjs(date).format("YYYY-MM"),
                        { shouldValidate: true }
                      );
                  }}
                  showMonthYearPicker
                  dateFormat="yyyy-MM"
                  locale={ko}
                  maxDate={new Date()}
                  minDate={
                    new Date(
                      watchField.education.university[index].admission_date
                    )
                  }
                  placeholderText="년도를 입력해주세요."
                  label
                  readOnly={readOnly}
                  disabled={readOnly}
                />
              </div>
              {errors?.education?.university &&
                index < errors?.education?.university.length && (
                  <p className="msg_err">
                    {
                      errors?.education?.university[index]?.graduate_year
                        ?.message
                    }
                  </p>
                )}
            </div>
          </>
        )}
      </div>

      <div className="box_division">
        <div className="wrap_box_input">
          {/*
                    <div className="box_input dropdown">
                        <label htmlFor="" className="asterisk">전공명</label>
                        <input type="text"                               
                            name={`education.university[${index}].major`} 
                            id={`education.university[${index}].major`}
                            placeholder="전공을 입력해주세요."
                            {...register(`education.university[${index}].major`, {
                                required: '필수 입력 항목입니다.'
                            })}></input>
                            <AutoCompleteOption 
                            inputName={`education.university[${index}].major`} 
                            inputValue={watchField.education.university[index].major} 
                            majorInfo={autoComplete} 
                            schoolGroup={"U"}
                            setValue={setValue}/>
                        </div>*/}
          <AutoComplete
            name={`education.university[${index}].major`}
            id={`education.university[${index}].major`}
            inputValue={watchField.education.university[index].major}
            placeholder={"전공을 입력해주세요."}
            register={register(`education.university[${index}].major`, {
              required: "필수 입력 항목입니다.",
            })}
            autoCompleteInfo={autoComplete}
            group={isUniversitySelected ? "U" : "C"}
            setv={setValue}
            setFocus={setFocus}
            readOnly={readOnly}
          />
          {errors?.education?.university &&
            index < errors?.education?.university.length && (
              <p className="msg_err">
                {errors?.education?.university[index]?.major?.message}
              </p>
            )}
        </div>
        <div className="wrap_box_input">
          <div className="box_input">
            <label htmlFor="" className="asterisk">
              계열
            </label>
            <select
              name={`education.university[${index}].affiliation`}
              id={`education.university[${index}].affiliation`}
              className="select"
              {...register(`education.university[${index}].affiliation`, {
                required: "필수 입력 항목입니다.",
              })}
              readOnly={readOnly}
              disabled={readOnly}
            >
              <option value="" hidden={true}>
                계열을 선택해주세요.
              </option>
              <option value="공과">공과</option>
              <option value="인문대학">인문대학</option>
              <option value="사회과학">사회과학</option>
              <option value="자연과학">자연과학</option>
              <option value="생활과학">생활과학</option>
              <option value="경영">경영</option>
              <option value="경제">경제</option>
              <option value="금융">금융</option>
              <option value="예술">예술</option>
              <option value="체육">체육</option>
              <option value="사범">사범</option>
              <option value="국제">국제</option>
              <option value="의과">의과</option>
              <option value="간호학">간호학</option>
              <option value="기타">기타</option>
            </select>
          </div>
          {errors?.education?.university &&
            index < errors?.education?.university.length && (
              <p className="msg_err">
                {errors?.education?.university[index]?.affiliation?.message}
              </p>
            )}
        </div>
        <div className="wrap_box_input">
          <div className="box_input">
            <label htmlFor="" className="asterisk">
              학점
            </label>
            <input
              type="text"
              name={`education.university[${index}].grade`}
              id={`education.university[${index}].grade`}
              placeholder="학점을 입력해 주세요."
              {...register(`education.university[${index}].grade`, {
                required: "필수 입력 항목입니다.",
                min: 0,
                max: parseFloat(
                  watchField.education.university[index].total_grade
                ),
                validate: (value) =>
                  !isNaN(parseFloat(value)) || "올바른 숫자를 입력해주세요.",
              })}
              readOnly={readOnly}
              disabled={readOnly}
            ></input>
          </div>
          {errors?.education?.university &&
            index < errors?.education?.university.length && (
              <p className="msg_err">
                {errors?.education?.university[index]?.grade?.message}
              </p>
            )}
        </div>
        <div className="wrap_box_input">
          <div className="box_input">
            <label htmlFor="" className="asterisk">
              총점
            </label>
            <select
              name={`education.university[${index}].total_grade`}
              id={`education.university[${index}].total_grade`}
              className="select"
              defaultValue={4.5}
              placeholder="총점을 입력해 주세요."
              {...register(`education.university[${index}].total_grade`, {
                required: "필수 입력 항목입니다.",
              })}
              readOnly={readOnly}
              disabled={readOnly}
            >
              <option value="" hidden={true}>
                총점을 선택해주세요.
              </option>
              <option value="4.5">4.5</option>
              <option value="4.3">4.3</option>
              <option value="4.0">4.0</option>
              <option value="100">100</option>
            </select>
          </div>
          {errors?.education?.university &&
            index < errors?.education?.university.length && (
              <p className="msg_err">
                {errors?.education?.university[index]?.total_grade?.message}
              </p>
            )}
        </div>
      </div>

      {fields.map((item, index2) => (
        <div key={index2} className="box_division box_major_added">
          {!readOnly && (
            <button
              className="btn_square_black"
              type="button"
              onClick={() => remove(index2)}
            ></button>
          )}

          <div className="wrap_box_input">
            <div className="box_input">
              <label htmlFor="" className="asterisk">
                전공상태
              </label>
              <select
                name={`education.university[${index}].add_major[${index2}].major_status`}
                id={`education.university[${index}].add_major[${index2}].major_status`}
                className="select"
                {...register(
                  `education.university[${index}].add_major[${index2}].major_status`,
                  {
                    required: "필수 입력 항목입니다.",
                  }
                )}
                placeholder="전공상태를 선택해 주세요."
                readOnly={readOnly}
                disabled={readOnly}
              >
                <option value="" hidden={true}>
                  전공상태를 선택해주세요.
                </option>
                <option value="부전공">부전공</option>
                <option value="복수전공">복수전공</option>
                <option value="이중전공">이중전공</option>
              </select>
            </div>
            {errors?.education?.university &&
              index < errors?.education?.university.length &&
              index2 < errors?.education?.university[index]?.length && (
                <p className="msg_err">
                  {
                    errors?.education?.university[index]?.add_major[index2]
                      ?.major_status?.message
                  }
                </p>
              )}
          </div>
          <div className="wrap_box_input">
            {/*
                        <div className="box_input dropdown">
                            <label htmlFor="" className="asterisk">전공명</label>
                            <input type="text"
                                id={`education.university[${index}].add_major[${index2}].major_name`}
                                name={`education.university[${index}].add_major[${index2}].major_name`}
                                {...register(`education.university[${index}].add_major[${index2}].major_name`, {
                                    required: '필수 입력 항목입니다.'
                                })}
                                placeholder="전공을 입력해주세요."
                            ></input>
                            <AutoCompleteOption 
                            inputName={`education.university[${index}].add_major[${index2}].major_name`} 
                            inputValue={watchField.education.university[index].add_major[index2].major_name} 
                            majorInfo={autoComplete} 
                            schoolGroup={"U"}
                            setValue={setValue}/>
                            </div>*/}
            <AutoComplete
              name={`education.university[${index}].add_major[${index2}].major_name`}
              id={`education.university[${index}].add_major[${index2}].major_name`}
              inputValue={
                watchField.education.university[index].add_major[index2]
                  .major_name
              }
              placeholder={"전공을 입력해주세요."}
              register={register(
                `education.university[${index}].add_major[${index2}].major_name`,
                {
                  required: "필수 입력 항목입니다.",
                }
              )}
              autoCompleteInfo={autoComplete}
              group={isUniversitySelected ? "U" : "C"}
              setv={setValue}
              setFocus={setFocus}
              readOnly={readOnly}
            />
            {errors?.education?.university &&
              index < errors?.education?.university.length &&
              index2 < errors?.education?.university[index]?.length && (
                <p className="msg_err">
                  {
                    errors?.education?.university[index]?.add_major[index2]
                      ?.major_name?.message
                  }
                </p>
              )}
          </div>
          <div className="wrap_box_input">
            <div className="box_input">
              <label htmlFor="" className="select">
                계열
              </label>
              <select
                name={`education.university[${index}].add_major[${index2}].major_affiliation`}
                id={`education.university[${index}].add_major[${index2}].major_affiliation`}
                {...register(
                  `education.university[${index}].add_major[${index2}].major_affiliation`,
                  {
                    required: "필수 입력 항목입니다.",
                  }
                )}
                className="select"
                readOnly={readOnly}
                disabled={readOnly}
              >
                <option value="">계열을 선택해주세요.</option>
                <option value="공과">공과</option>
                <option value="인문대학">인문대학</option>
                <option value="사회과학">사회과학</option>
                <option value="자연과학">자연과학</option>
                <option value="생활과학">생활과학</option>
                <option value="경영">경영</option>
                <option value="경제">경제</option>
                <option value="금융">금융</option>
                <option value="예술">예술</option>
                <option value="체육">체육</option>
                <option value="사범">사범</option>
                <option value="국제">국제</option>
                <option value="의과">의과</option>
                <option value="간호학">간호학</option>
                <option value="기타">기타</option>
              </select>
            </div>
            {errors?.education?.university &&
              index < errors?.education?.university.length &&
              index2 < errors?.education?.university[index]?.length && (
                <p className="msg_err">
                  {
                    errors?.education?.university[index]?.add_major[index2]
                      ?.major_affiliation?.message
                  }
                </p>
              )}
          </div>
        </div>
      ))}
      <button
        type="button"
        readOnly={readOnly}
        disabled={readOnly}
        onClick={() => {
          append({
            major_affiliation: "",
            major_name: "",
            major_status: "",
          });
        }}
        className="btn_submit btn_add filled_green"
      >
        <span className="btn_text">전공 추가</span>
      </button>
    </div>
  );
};

export default University;
