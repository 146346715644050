import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import api from "../../../api/api";
import ConfirmModal from "./confirmModal";
import AddInfo from "./AddInfo";
import CrtfCertification from "./CrtfCertification";
import {ErrorMessages} from "../../../utils/Message";
import {countPhoneNo, sendPhone, updateUser, uploadImage} from "../../../api/User/Signup/Signup";
import ChangePwModal from "./ChangePwModal";
import * as PropTypes from "prop-types";
import BackModal from "./BackModal";

const init = {
    // 구직자 회원 계정
    member_com : {
        gubun: "USER",
        provider_type: "LOCAL"
    }
};

const UserInfo = () => {
    const methods = useForm({
        mode: 'onChange',
        defaultValues:init
    });
    const { register, setValue, watch, handleSubmit, clearErrors, reset, setError, formState: { errors }} = methods;
    const [time ,setTime] = useState(1799);
    const [phone, setPhone] = useState({
        send : false,
        num : undefined,
        valid : false,
        pass : false
    });
    const [isPhoneChange, setIsPhoneChange] = useState(false);
    const [changeModal, setChangeModal] = useState(false);
    const [backModal, setBackModal] = useState(false);
    const [link, setLink] = useState('/');
    const [backModalMent, setBackModalMent] = useState("이전 페이지로 이동할 경우 지금까지 작성된 내용은 저장되지 않습니다.");
    const [regSeq, setRegSeq] = useState(false);
    const [crtf, setCrtf] = useState(null);
    const [image, setImage] = useState(null);
    const [modal, setModal] = useState(false);
    const [ment, setMent] = useState(null);
    const formData = new FormData();
    const watchField = watch()
    const history = useHistory();

    useLayoutEffect(() => {
        try {
            api.post('/api/signup/getUserInfo',{}).then(response => 
                response.data
            ).then((response) => {
                if (response && response.resultCode === 1000 && response.resultData)
                    reset({
                    ...response.resultData,
                    disabili_certifica : {
                        ...response.resultData.disabili_certifica,
                        address: response.resultData.disabili_certifica.address.split("|",2)[0],
                        extraAddr: response.resultData.disabili_certifica.address.split("|",2)[1]
                    }
                })
                if (response.resultData.disabili_certifica.crtf_origin_file_nm !== null)
                    setCrtf({name: response.resultData.disabili_certifica.crtf_origin_file_nm})
            })
            .catch(error => {
                console.error('Error:', error);
            });
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        let Count

        if (time > 0) {
            Count = setInterval(() => {
                setTime((prev) => prev - 1);
            }, 1000);
        }
        else {
            clearInterval(Count);
            if (phone.pass !== true)
                setPhone({...phone, num: undefined, valid: false})
        }
        return () => clearInterval(Count)
    }, [time])

    const timeFormat = (time) => {
        const m = Math.floor(time / 60).toString()
        let s = (time % 60).toString()
        if (s.length === 1) s = `0${s}`
        return `${m}:${s}`
    }

    const sendMessage = async () => {
        const num = watchField.member_info.phone_no;

        if (num === "" || num === undefined)
            setError("member_info.phone_no", {
                message: ErrorMessages.required
            }, {shouldFocus: true})
        else {
            try {
                const response = await countPhoneNo(num);
                clearErrors("member_info.phone_no")

                if (response.data.resultData === 0) {
                    const valid = await sendPhone(num);

                    setPhone({...phone, send: true, num : valid})
                    setTime(1799)
                } else {
                    setError("member_info.phone_no", {
                        message: ErrorMessages.duplicatePhoneNo
                    }, {shouldFocus: true})
                }

            } catch (error) {
                console.log(error)
            }
        }
    }

    const validPhoneCheck = () => {
        if (phone.num !== undefined) {
            if (watchField.member_info.validNum === phone.num.data.resultData.toString()) {
                setPhone({...phone, valid: true, pass: true})
                clearErrors("member_info.phone_no")
                clearErrors("member_info.validNum")
            }
            else {
                setError("member_info.validNum", {
                    message : "인증번호가 일치하지 않습니다."
                }, {shouldFocus: true})
                setPhone({...phone, valid : false})
            }
        }
        else {
            setError("member_info.validNum", {
                message : "휴대폰 인증을 다시 진행해주세요."
            }, {shouldFocus: true})
            setPhone({...phone, valid : false})
        }
    }

    const changePhone = () => {
        setIsPhoneChange(true);
        setValue("member_info.phone_no", "", {shouldDirty: true, shouldTouch: true})
    }

    const modalPopup = () => {
            setModal(true);
            setMent("수정한 정보를 저장하시겠습니까?");
    }

    const AlertError = () => {

        if (errors.member_com?.member_id) {
            alert("아이디 입력정보를 확인해주세요.")
        } else if (errors.member_com?.member_pw || errors.member_com?.pwcheck) {
            alert("비밀번호 입력정보를 확인해주세요.")
        } else if (errors.member_info?.phone_no || errors.member_info?.validNum) {
            alert("휴대폰번호 입력정보를 확인해주세요.")
        } else if (errors.member_com?.email) {
            alert("이메일 입력정보를 확인해주세요.")
        } else if (errors.member_com?.member_nm) {
            alert("이름 입력정보를 확인해주세요.")
        } else if (errors.disabili_certifica?.sex_gb) {
            alert("성별 입력정보를 확인해주세요.")
        } else if (errors.disabili_certifica?.birthday) {
            alert("생년월일 입력정보를 확인해주세요.")
        } else if (errors.disabili_certifica?.address || errors.disabili_certifica?.extraAddr) {
            alert("주소 입력정보를 확인해주세요")
        } else if (errors.disabili_certifica?.major_disability_nm) {
            alert("주장애 및 장애 정도 입력정보를 확인해주세요")
        } else if (errors.disabili_certifica?.issuance_dt) {
            alert("발급일 입력정보를 확인해주세요.")
        } else if (errors.disabili_certifica?.issuance_agency) {
            alert("발급기관 입력정보를 확인해주세요.")
        } else if (errors.disabili_certifica?.dis_reg_cer_seq) {
            alert("장애인 등록번호를 확인해주세요.")
        } else if (errors.member_info?.image_file_path) {
            alert("이력서 사진을 첨부해주세요.")
        }
    }
    
    const onSubmit = async (data) => {

            try {
                data.disabili_certifica.address = data.disabili_certifica.address + "|" + data.disabili_certifica.extraAddr;

                await updateUser(data).then((response) => {
                    if (response.data.resultCode === 1000) {
                        const user = JSON.parse(localStorage.getItem('user'));
                        user.username = data.member_com.member_nm;
                        localStorage.setItem('user', JSON.stringify(user));

                        if (crtf && crtf.type !== undefined) {
                            formData.append('image', crtf);
                            formData.append('imageNm', data.disabili_certifica.dis_reg_cer_seq + "." + crtf.type.split('/')[1]);
                            formData.append('originFileNm', crtf.name);
                            formData.append('memSeq', parseInt(data.member_info.member_seq));

                            uploadImage(formData);
                        }


                        alert("수정이 완료되었습니다.");
                        window.location.href="/";
                    }
                });

            } catch (error) {
                console.log(error);
            }

    }

    const onError = () => {
        AlertError();

    }

    return (
        <div>
            <div className="wrapper">
                <main>
                    <div className="page join page_join page_mypage">
                        <h2 className="page_back_title">내 정보 관리</h2>
                        <div className="page_inner_above"></div>

                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit,onError)}>
                                { watchField.member_com && watchField.member_info && watchField.disabili_certifica && (
                                    <div className="page_inner">
                                        <div className="area_shade">
                                            <div className="wrap_signup_input wrap_signup_basic">
                                                <div className="box_sub_title">
                                                    <h4 className="sub_title_signup">기본정보</h4>
                                                </div>
                                                    { watchField.member_com.provider_type === 'LOCAL' &&
                                                    <>
                                                    <div className="wrap_box_input">
                                                        <div className="box_input">
                                                            <label htmlFor="" className="asterisk">아이디</label>
                                                            <input type="text" id="member_com.member_id" name="member_com.member_id"
                                                                   placeholder="아이디를 입력해 주세요." {...register("member_com.member_id")} readOnly/>
                                                        </div>
                                                    </div>
                                                    <div className="wrap_box_input focus_in">
                                                        <div className="box_confirm">
                                                            <div className="box_input">
                                                                <label htmlFor="" className="asterisk"
                                                                       style={{height: '62px'}}>비밀번호</label>
                                                                <button type="button"
                                                                        className="btn_submit filled_green"
                                                                        style={{left: '0px'}} onClick={() => setChangeModal(true)}>변경하기</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </>
                                                    }
                                                    { watchField.member_com.provider_type !== 'LOCAL' &&
                                                    <div className="wrap_box_input">
                                                        <div className="box_input">
                                                            <label htmlFor="" className="asterisk">SNS 계정</label>
                                                        </div>
                                                        <div className="box_social_viewer">
                                                            <ul className="social_viewer_list">
                                                            { watchField.member_com.provider_type === 'GOOGLE' &&
                                                            <li className="social_viewer_item join_link_google">
                                                                <i className="join_link_icon"></i>구글 계정 연결
                                                            </li>
                                                            }
                                                            { watchField.member_com.provider_type === 'KAKAO' &&
                                                            <li className="social_viewer_item join_link_kakao">
                                                                <i className="join_link_icon"></i>카카오톡 계정 연결
                                                            </li>
                                                            }
                                                            { watchField.member_com.provider_type === 'NAVER' &&
                                                            <li className="social_viewer_item join_link_naver">
                                                                <i className="join_link_icon"></i>네이버 계정 연결
                                                            </li>
                                                            }
                                                            { watchField.member_com.provider_type === 'FACEBOOK' &&
                                                            <li className="social_viewer_item join_link_facebook">
                                                                <i className="join_link_icon"></i>페이스북 계정 연결
                                                            </li>
                                                            }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    }
                                                {isPhoneChange !== true &&
                                                <div className="wrap_box_input focus_in">
                                                    <div className="box_confirm">
                                                        <div className="box_input">
                                                            <label htmlFor="" className="asterisk">휴대폰 인증</label>
                                                            <input type="tel" id="member_info.phone_no" name="member_info.phone_no" placeholder="휴대폰 번호를 입력해 주세요." {...register("member_info.phone_no")} readOnly/>
                                                        </div>
                                                        <button type="button" className="btn_submit filled_green" onClick={changePhone}>변경하기</button>
                                                    </div>
                                                    <p className="msg_err">{errors.member_info?.phone_no?.message}</p>
                                                </div>
                                                }
                                                {isPhoneChange === true && phone.send !== true  &&
                                                <div className="wrap_box_input focus_in">
                                                    <div className="box_confirm">
                                                        <div className="box_input">
                                                            <label htmlFor="" className="asterisk">휴대폰 인증</label>
                                                            <input type="tel" id="member_info.phone_no" name="member_info.phone_no" placeholder="휴대폰 번호를 입력해 주세요." {...register("member_info.phone_no",{
                                                                required: '필수 입력 항목입니다.',
                                                                validate: {
                                                                    validPhone: () => phone.send === true || ErrorMessages.validPhone
                                                                },
                                                                onChange: (e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')
                                                            })}/>
                                                        </div>
                                                        <button type="button" className="btn_submit filled_green" onClick={sendMessage}>인증번호 요청</button>
                                                    </div>
                                                    <p className="msg_err">{errors.member_info?.phone_no?.message}</p>
                                                </div>
                                                }
                                                {phone.send === true &&
                                                <div className="wrap_box_input">
                                                    <div className="box_confirm">
                                                        <div className="box_input">
                                                            <label htmlFor="" className="asterisk">휴대폰 인증</label>
                                                            <input type="tel" id="member_info.phone_no" name="member_info.phone_no" placeholder="휴대폰 번호를 입력해 주세요." {...register("member_info.phone_no",{
                                                                required: '필수 입력 항목입니다.',
                                                                validate: {
                                                                    validPhone: () => phone.send === true || ErrorMessages.validPhone
                                                                },
                                                                onChange: (e) => {
                                                                    setPhone({...phone, valid: false, pass: false, num: undefined});
                                                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                }
                                                            })}/>
                                                            <p className="msg_time">{phone.valid !== true && timeFormat(time)}</p>
                                                        </div>
                                                        {phone.pass !== true &&
                                                        <button type="button" className="btn_submit filled_green" onClick={sendMessage}>재전송</button>
                                                        }
                                                        {phone.pass === true &&
                                                        <button type="button" className="btn_submit filled_green" disabled>인증완료</button>
                                                        }
                                                    </div>
                                                    { phone.valid !== true &&
                                                    <div className="box_confirm">
                                                        <div className="box_input">
                                                            <input type="tel" id="member_info.validNum" name="member_info.validNum" placeholder="인증번호를 입력해 주세요." {...register("member_info.validNum",{
                                                                required: '필수 입력 항목입니다.',
                                                                validate: {
                                                                    validNum: () => (phone.valid === true && phone.pass === true) || ErrorMessages.validPhone
                                                                },
                                                                onChange: () => setPhone({...phone, valid: false})
                                                            })}/>
                                                        </div>
                                                        <button type="button" className="btn_submit filled_green" onClick={validPhoneCheck}>인증번호 확인</button>
                                                    </div>
                                                    }
                                                    <p className="msg_err">{errors.member_info?.validNum?.message || errors.member_info?.phone_no?.message}</p>
                                                </div>
                                                }
                                                <div className="wrap_box_input">
                                                    <div className="box_input">
                                                        <label htmlFor="" className="asterisk">E-mail</label>
                                                        <input type="email" id="member_com.email" name="member_com.email" placeholder="E-mail 주소를 입력해 주세요." {...register("member_com.email", {
                                                            required: ErrorMessages.required,
                                                            pattern: {
                                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                                message: '잘못된 E-mail 형식입니다.'
                                                            }
                                                        })}/>
                                                    </div>
                                                    <p className="msg_err">{ errors.member_com?.email?.message }</p>
                                                </div>
                                            </div>

                                            <CrtfCertification crtf={crtf} setCrtf={setCrtf} formData={formData} disabled={false} regSeq={regSeq} setRegSeq={setRegSeq} isUserInfo={true} />
                                            <AddInfo image={image} setImage={setImage} isUserInfo={true} />

                                            <div className="wrap_box_input" style={{marginTop: "30px"}}>
                                                <div className="box_confirm">
                                                    <div className="box_input">
                                                        <label htmlFor="" style={{height: '62px'}}>회원탈퇴</label>
                                                        <a href="/user/signup/withdrawal" target="_self" className="btn_submit filled_green" style={{left: '0px'}}
                                                           onClick={() => {localStorage.setItem('disCerRegSeq', watchField.member_info.dis_reg_cer_seq)}}>회원 탈퇴하기</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="wrap_btns">
                                            <button type="button" className="btn_submit filled_black" onClick={() => setBackModal(true)}>이전으로</button>
                                            <button type="button" className="btn_submit filled_green" onClick={modalPopup}>저장하기</button>
                                        </div>
                                    </div>
                                )}
                                {
                                    modal ?
                                        <ConfirmModal setModal={setModal}
                                                      width={"500px"} height={"300px"}
                                                      ment={ment}/>
                                        : null
                                }
                            </form>
                        </FormProvider>
                    </div>
                </main>
            </div>
            {
                changeModal ? <ChangePwModal setChangeModal={setChangeModal}/> : null
            }
            {
                backModal ? <BackModal setBackModal={setBackModal} link={link} ment={backModalMent} /> : null
            }
        </div>

    )
}

export default UserInfo;