import React from "react";

const PopupPolicy =(props) => {

    const disableModal = () =>{
        setTimeout(() => {
            props.setModal(false);
        }, 300);
    }

    return (
        <div>
            <div className="popup_mask" style={{display: "block"}} onClick={disableModal}></div>
            <div className="popup_layer enterprise pop_lg popRequstDetail" id="popRequestDetail" style={{display: "block"}}>
                <div className="popup_inner">
                    <div className="pop_title with_btn">
                        <h3>개인정보 처리방침</h3>
                        <button type="button" aria-label="레이어 팝업 닫기" className="popup_close" onClick={() => props.setModal(false)}/>
                    </div>
                    <div className="popup_contents profile_detail">
                        <div className="page_inner recruit_status_page">                                                            
                            <div className="box_txt_policy" tabIndex="0">
                                <p>개인정보처리방침</p><br/>  
                                주식회사 와이즈넛, 주식회사 브이드림, 사단법인 청십자사회복지회(이하 “컨소시엄”)는 「개인정보보호법」의 관련 법령상의 개인정보 보호규정을 준수하며, 개인정보보호법에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. 본 개인정보처리방침은 컨소시엄이 제공하는 장애인직무매칭시스템 서비스에 적용되며 다음과 같은 내용을 포함하고 있습니다.<br/><br/>
                                장애인 직무매칭시스템은은 ‘산업통상자원부’에서 전문기관 ‘한국산업기술평가관리원’을 통해 지원하는 지식서비스산업기술개발사업으로 주관기관 와이즈넛, 참여기관 브이드림, 청십자사회복지회이 과제명 ‘장애인 고용 촉진을 위한 비대면 직무매칭(역량평가) 및 직무개발 지원 시스템 개발’ 으로 개발 중인 시스템 입니다. <br/><br/>
                                1. 개인정보 수집 및 이용 목적<br/>
                                2. 개인정보 제 3자 제공<br/>
                                3. 개인정보 처리위탁<br/>
                                4. 개인정보 보유 및 이용기간<br/>
                                5. 개인정보 파기절차 및 방법<br/>
                                6. 이용자 권리 및 행사방법<br/>
                                7. 자동 수집되는 개인정보 및 거부에 관한 사항<br/>
                                8. 개인정보의 보호조치에 관한 사항<br/>
                                9. 개인정보 보호책임자 연락처 및 이용자 고충처리 <br/>
                                10. 기타<br/><br/>
                                <p>1. 개인정보 수집 및 이용 목적</p>
                                “컨소시엄”은 서비스 제공을 위한 최소한의 범위 내에서 이용자의 동의 하에 개인정보를 수집하며, 수집한 모든 개인정보는 고지한 목적 범위 내에서만 사용됩니다. “컨소시엄”에서 제공하는 서비스 유형에 따라 다음과 같이 개인정보를 수집, 이용, 보유 및 파기하고 있습니다.<br/><br/>
                                &lt;개인회원&gt;<br/>
                                <table style={{ borderCollapse: 'collapse', border: '1px solid black' }}>
                                    <thead>
                                        <tr style={{ height: '2.8pt' }}>
                                            <td width={68} style={{ width: '151pt', border: '1px solid black', background: '#FAF3DB', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>수집방법</p>
                                            </td>
                                            <td width={302} style={{ width: '326pt', border: '1px solid black', borderLeft: 'none', background: '#FAF3DB', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>수집항목</p>
                                            </td>
                                            <td width={219} style={{ width: '264pt', border: '1px solid black', borderLeft: 'none', background: '#FAF3DB', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>수집 및 이용목적</p>
                                            </td>
                                            <td width={42} style={{ width: '131pt', border: '1px solid black', borderLeft: 'none', background: '#FAF3DB', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>보유기간</p>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ height: '2.8pt' }}>
                                            <td width={68} style={{ width: '151pt', border: '1px solid black', borderTop: 'none', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>회원가입</td>
                                            <td width={302} style={{ width: '326pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>(필수)<br />아이디, 비밀번호, 휴대폰번호, 이메일주소, 사진, 장애인증명서(이름, 성별, 생년월일, 주소, 주장애 및 장애정도, 발급일, 발급기관, 등록번호)</p>
                                            </td>
                                            <td width={219} style={{ width: '264pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>본인 확인 및 가입의사 확인, 장애인 해당 여부 확인(장애인증명서)</p>
                                            </td>
                                            <td width={42} rowSpan={6} style={{ width: '131pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>과제 종료시까지 (~2024.12.31)</td>
                                        </tr>
                                        <tr style={{ height: '2.8pt' }}>
                                            <td width={68} style={{ width: '151pt', border: '1px solid black', borderTop: 'none', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}></td>
                                            <td width={302} style={{ width: '326pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>(선택)<br/>부장애 및 장애정도, 종합장애정도, 장애인 증명서 문서확인번호, 보호자 이름, 보호자와의 관계, 보호장구,   유입경로 </p>
                                            </td>
                                            <td width={219} style={{ width: '264pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>장애 정보에 맞는 직무 추천</p>
                                            </td>
                                        </tr>
                                        <tr style={{ height: '2.8pt' }}>
                                            <td width={68} style={{ width: '151pt', border: '1px solid black', borderTop: 'none', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>간편가입 및 로그인</td>
                                            <td width={302} style={{ width: '326pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>(필수)<br/>- 구글, 페이스북 : ID, 이름,   이메일<br/>- 카카오, 네이버 : ID, 이름,   이메일, 휴대폰번호 </p>
                                            </td>
                                            <td width={219} style={{ width: '264pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>본인 확인 및 가입의사 확인<br/>※ 본   컨소시엄은 고객의 편의를 위해 간편 로그인(네이버, 카카오,   페이스북, 구글, Apple) 방식을 제공하고 있습니다. 이와 같은 로그인시 당사가 고객의 개인정보를 추가   수집하지 않으며, 본인확인값만 비교하고 있습니다.  </p>
                                            </td>
                                        </tr>
                                        <tr style={{ height: '2.8pt' }}>
                                            <td width={68} style={{ width: '151pt', border: '1px solid black', borderTop: 'none', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>이력서 등록</td>
                                            <td width={302} style={{ width: '326pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p> (선택)<br/>학력사항(대학교학력(입학상태,   학교구분, 학교명, 계열,   입학년월, 졸업상태, 졸업년월,   전공명, 학점, 총점),   
                                                대학원학력(입학상태, 학교명,   전공명, 계열, 입학년월,   학위, 졸업상태, 학점,   총점)), 
                                                경력사항(회사명,   부서/직급/직책,   직무, 입사년월, 재직여부,   퇴사년월, 연봉), 
                                                컴퓨터활용능력(워드, 엑셀, 파워포인트),   
                                                자격증(자격증명, 발행처,   취득일, 자격증구분, 급수),   
                                                수상내역(수상명, 수여기관,   수상일), 
                                                어학(어학시험명,   외국어명, 급수/점수,   취득일)  </p>
                                            </td>
                                            <td width={219} style={{ width: '264pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p></p>
                                            </td>
                                        </tr>
                                        <tr style={{ height: '2.8pt' }}>
                                            <td width={68} style={{ width: '151pt', border: '1px solid black', borderTop: 'none', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>역량평가</td>
                                            <td width={302} style={{ width: '326pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>  (필수) <br/>역량평가 결과</p>
                                            </td>
                                            <td width={219} style={{ width: '264pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>구직자의 개인 역량 분석 및 역량평가 결과를 직무, 커리어패스,   교육 추천에 활용</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br/>
                                &lt;기업회원&gt;                                    
                                <br/>   
                                <table style={{ borderCollapse: 'collapse', border: '1px solid black' }}>
                                    <thead>
                                        <tr style={{ height: '2.8pt' }}>
                                            <td width={68} style={{ width: '151pt', border: '1px solid black', background: '#FAF3DB', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>수집방법</p>
                                            </td>
                                            <td width={302} style={{ width: '326pt', border: '1px solid black', borderLeft: 'none', background: '#FAF3DB', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>수집항목</p>
                                            </td>
                                            <td width={219} style={{ width: '264pt', border: '1px solid black', borderLeft: 'none', background: '#FAF3DB', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>수집 및 이용목적</p>
                                            </td>
                                            <td width={42} style={{ width: '131pt', border: '1px solid black', borderLeft: 'none', background: '#FAF3DB', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>보유기간</p>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ height: '2.8pt' }}>
                                            <td width={68} style={{ width: '151pt', border: '1px solid black', borderTop: 'none', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>회원가입</td>
                                            <td width={302} style={{ width: '326pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>(필수)<br/>아이디, 비밀번호, 대표이미지,   사업자등록증 또는 고유번호증, 회사명, 대표자명, 사업자등록번호, 주소,   기업업종, 매출, 종업원수,   담당자 이름 및 직급, 담당자 연락처, 담당자 이메일, 연차사용기준, 대체   공휴일 휴무</p>
                                            </td>
                                            <td width={219} style={{ width: '264pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>기업 및 담당자 확인 및 가입의사 확인</p>
                                            </td>
                                            <td width={42} rowSpan={6} style={{ width: '131pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}></td>
                                        </tr>
                                        <tr style={{ height: '2.8pt' }}>
                                            <td width={68} style={{ width: '151pt', border: '1px solid black', borderTop: 'none', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}></td>
                                            <td width={302} style={{ width: '326pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>(선택)<br/>담당자 근무지</p>
                                            </td>  
                                            <td width={219} style={{ width: '264pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}></td>
                                        </tr>
                                        <tr style={{ height: '53.05pt' }}>
                                            <td width={68} style={{ width: '151pt', border: '1px solid black', borderTop: 'none', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>직무요청서 등록</td>
                                            <td width={302} style={{ width: '326pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>(필수)<br/>회사명, 담당자 이름, 담당자 연락처,   담당자 이메일, 기업소개, 공고명, 공고시작일, 공고마감일,   모집인원, 급여, 근무조건,   근무지, 지원가능 장애유형, 주요업무, 자격요건, 복리후생 </p>
                                            </td>
                                            <td width={219} style={{ width: '264pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>직무요청서 등록을 통한 추천 채용정보 및 채용공고에 활용</p>
                                            </td>
                                        </tr>
                                        <tr style={{ height: '53.05pt' }}>
                                            <td width={68} style={{ width: '151pt', border: '1px solid black', borderTop: 'none', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}></td>                                                
                                            <td width={302} style={{ width: '326pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}>
                                                <p>(선택)<br/>공고상태, 근무형태, 채용조건,   요청 학력, 지원가능 장애정도, 기타사항, 파일첨부(대부분의 확장자)</p>
                                            </td>                                                
                                            <td width={219} style={{ width: '264pt', border: '1px solid black', borderTop: 'none', borderLeft: 'none', borderBottom: '1px solid black', borderRight: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '2.8pt' }}></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br/>
                                &lt;서비스 이용에 따른 자동 수집 및 생성정보&gt;
                                <br/>
                                <table style={{ borderCollapse: 'collapse', border: 'none' }}>
                                    <thead>
                                        <tr style={{ height: '21.3pt' }}>
                                            <td width={258} style={{ width: '300pt', border: '1px solid black', background: '#FAF3DB', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '21.3pt' }}>
                                                <p>수집항목</p>
                                            </td>
                                            <td width={308} style={{ width: '400pt', border: '1px solid black', borderLeft: 'none', background: '#FAF3DB', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '21.3pt' }}>
                                                <p>수집 및 이용목적</p>
                                            </td>                                                
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ height: '37.3pt' }}>
                                            <td width={258} style={{ width: '300pt', border: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '37.3pt' }}>
                                                <p>쿠키, 서비스이용기록(방문일시,   IP, 불량이용기록) </p>
                                            </td>
                                            <td width={308} style={{ width: '400pt', border: '1px solid black', borderLeft: 'none', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '37.3pt' }}>
                                                <p>접속관리, 활동정보 파악, 맞춤형   채용정보 제공</p>
                                            </td>  
                                        </tr>                                           
                                    </tbody>
                                </table>
                                <br/>
                                귀하께서는 “컨소시엄”이 위와 같이 수집하는 개인정보에 대해 동의하지 않거나 개인정보를 기재하지 않음으로써 거부할 수 있습니다. 다만, 이때 회원에게 제공되는 서비스가 제한될 수 있습니다.<br/><br/>
                                <p>2. 개인정보 제3자 제공</p><br/>
                                “컨소시엄”은「1. 개인정보 수집 및 이용 현황」에서 고지한 범위 내에서만 개인정보를 이용하며, 원칙적으로 이용자의 개인정보를 제3자에게 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br/><br/>
                                가. 이용자가 서비스 이용중 제3자 제공에 동의(수락)한 경우 <br/>장애인직무매칭시스템에서 제공하는 입사지원서비스를 이용하기 위하여 개인정보를 제공하는 목록입니다.<br/>
                                즉, 아래 서비스를 이용하지 않는 이용자는 개인정보 제공이 발생하지 않습니다.<br/><br/>
                                [입사지원 기업]<br/><br/>
                                나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br/>
                                귀하께서는 개인정보의 제3자 제공에 대해, 동의하지 않을 수 있고 언제든지 제3자 제공 동의를 철회할 수 있습니다. 다만, 제3자 제공에 기반한 관련된 일부 서비스의 이용이 제한될 수 있습니다. (회원가입 서비스는 이용하실 수 있습니다.)<br/><br/>
                                <p>3. 개인정보 보유 및 이용기간</p>
                                “컨소시엄”은 이용자의 개인정보를 고지 및 동의 받은 사항에 따라 과제 수행을 위한 수집∙이용 목적이 달성되기 전까지 해당 정보를 보유합니다. 다만, 아래의 사유로 인하여 보관이 필요한 경우 외부와 차단된 별도 DB 또는 테이블에 분리 보관 됩니다.<br/><br/>
                                가. 서비스 제공을 위해 수집한 정보 : 과제 종료시까지 (~2024.12.31) <br/><br/>
                                나. 관련 법령에 의한 개인정보 보유<br/>
                                1) 통신비밀보호법<br/>
                                · 서비스 이용기록, 접속로그, 접속IP정보 : 3개월<br/><br/>
                                <p>4. 개인정보 파기절차 및 방법</p>
                                이용자의 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성되면 지체없이 파기 합니다. 다만, 다른 법령에 의해 보관해야 하는 정보는 법령이 정한 기간 동안 별도 분리보관 후 파기합니다.<br/><br/>
                                가. 파기절차 및 기한<br/>
                                · 수집·이용목적이 달성된 개인정보는 지체없이 파기되며, 관련 법령에 따라 보관되어야 할 경우 별도의 DB에 옮겨져 내부 규정 및 관련 법령을 준수하여 일정기간동안 안전하게 보관된 후 지체없이 파기됩니다. 이때, DB로 옮겨진 개인정보는 법률에 의한 경우를 제외하고 다른 목적으로 이용하지 않습니다.<br/><br/>
                                나. 파기방법<br/>
                                · 전자적 파일 형태의 정보는 복구 및 재생할 수 없는 기술적 방법을 사용하여 완전하게 삭제합니다.<br/>
                                · 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br/><br/>
                                <p>5. 이용자 권리 및 행사방법</p>
                                이용자는 정보주체로서 홈페이지(https://matching.vdream.co.kr/)통해 언제든지 아래의 권리를 행사할 수 있습니다.<br/><br/>
                                가. 조회 및 수정<br/>
                                홈페이지의 왼쪽 메뉴에서 [나의 구직활동 &gt; 내 정보 등록] 및 [나의 구직 활동 &gt; 이력서 등록]기능을 통해 자신의 개인정보를 조회 · 수정할 수 있습니다.<br/><br/>
                                나. 회원탈퇴<br/>
                                홈페이지의 [고객센터 &gt; 개인회원 탈퇴]기능을 통해 개인정보를 삭제할 수 있으며, 회원탈퇴 즉시 보유 중인 이력서도 삭제처리 됩니다.<br/><br/>
                                <p>6. 자동 수집되는 개인정보 및 거부에 관한 사항</p>
                                컨소시엄은 이용자 맞춤서비스를 제공하기 위하여 쿠키(cookie)를 설치 및 운영합니다. 쿠키의 사용 목적과 거부에 관한 사항은 아래와 같습니다.<br/><br/>
                                가. 쿠키란?<br/>
                                쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터에 저장되어 운영됩니다.<br/><br/>
                                나. 쿠키의 사용 목적<br/>
                                이용자들의 접속관리, 이용자별 사용 환경 제공, 이용자 활동정보를 파악하여 최적화된 맞춤형 서비스를 제공하기 위해 사용합니다.<br/><br/>
                                다. 쿠키의 설치·운영 및 거부<br/>
                                서비스를 이용함에 있어 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용 또는 거부 하거나, 쿠키가 저장될 때마다 확인을 거치도록 할 수 있습니다. 웹 브라우저 상단의 도구 &gt; 인터넷옵션 &gt; 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있으나, 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생 할 수 있습니다.<br/><br/>
                                <p>7. 개인정보의 보호조치에 관한 사항</p>
                                “컨소시엄”은 이용자의 개인정보가 분실, 도난, 유출, 위∙변조 또는 훼손되지 않도록 안전성 확보를 위하여 정보통신망법, 개인정보보호법 등 정보통신서비스 제공자가 준수하여야 할 관련 법령에 따라 기술적∙관리적 보호조치를 적정하게 취하고 있습니다.<br/><br/>
                                가. 기술적 대책<br/>
                                - 고객의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.<br/>
                                - “컨소시엄”은 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.<br/>
                                - 컨소시엄은 네트워크 상의 개인정보를 안전하게 전송할 수 있는 전송구간 암호화(SSL)를 통해 전송하고 있습니다.<br/>
                                - 해킹 등 외부침입에 대비하여 침입차단시스템 등을 이용하여 보안에 만전을 기하고 있습니다.<br/><br/>
                                나. 관리적 대책<br/>
                                - “컨소시엄”은 개인정보 취급자를 최소한의 인원으로 제한하며, 개인정보를 처리하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무 등에 관해 정기적인 교육을 실시하고 있습니다.<br/>
                                - 입사 시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보처리방침에 대한 이행사항 및 직원의 준수여부를 감시하기 위한 내부절차를 마련하고 있습니다.<br/>
                                - 개인정보취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확히 하고 있습니다.<br/>
                                - 전산실 및 자료 보관실 등을 특별 보호구역으로 설정하여 출입을 통제하고 있습니다.<br/>
                                - 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 분실, 도난, 유출, 위∙변조 또는 훼손될 경우 컨소시엄은 즉각 이용자에게 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.<br/><br/>
                                <p>8. 개인정보 보호책임자 연락처 및 이용자 고충 처리</p>
                                “컨소시엄”의 서비스를 이용하시면서 발생한 모든 개인정보보호 관련 민원, 불만처리 등에 관한 사항을 개인정보 보호책임자 및 고객센터로 문의하실 수 있고, 컨소시엄은 이용자의 문의에 신속하고 성실하게 답변하겠습니다.<br/>
                                <table style={{ borderCollapse: 'collapse', border: 'none' }}>
                                    <thead>
                                        <tr style={{ height: '21.3pt' }}>
                                            <td width={642} style={{ width: '481.8pt', border: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '21.3pt' }}>
                                                <p>개인정보 보호책임자</p>
                                            </td>                                                                                               
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ height: '21.3pt' }}>
                                            <td width={642} style={{ width: '481.8pt', border: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '21.3pt' }}>
                                                성장기술연구소 / 노상록 / 02-3404-6197 / sanrok@wisenut.co.kr
                                            </td>                                                                                               
                                        </tr>   
                                        <tr style={{ height: '21.3pt' }}>
                                            <td width={642} style={{ width: '481.8pt', border: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '21.3pt' }}>
                                            기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에   문의 가능합니다
                                            </td>                                                                                               
                                        </tr>  
                                        <tr style={{ height: '77.9pt' }}>
                                            <td width={642} style={{ width: '481.8pt', border: '1px solid black', padding: '1.4pt 5.1pt 1.4pt 5.1pt', height: '77.9pt' }}>
                                            개인정보침해신고센터 (국번없이) 118 / privacy.kisa.or.kr<br/>
                                            개인정보 분쟁조정위원회 1833-6972 / www.kopico.go.kr<br/>
                                            대검찰청 사이버수사과 (국번없이) 1301 / www.spo.go.kr<br/>
                                            경찰청 사이버수사국 (국번없이) 182 / ecrm.cyber.go.kr<br/>
                                            </td>                                                                                               
                                        </tr>                                       
                                    </tbody>
                                </table>
                                <br/>
                                <p>9. 기타</p>
                                이 개인정보 처리방침은 시행일로부터 적용되며, 관련 법령 및 컨소시엄 기획변경 등에 따른 변경 내용의 추가, 삭제 및 정정사항이 있는 경우에는 홈페이지를 통해 사전 공지하겠습니다.<br/><br/>
                                - 개인정보 처리방침 버전 번호 : Ver 0.6<br/>
                                - 개인정보 처리방침 시행일자 : 2023년 11월 10일<br/>

                            </div>             
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopupPolicy;