import React from 'react';

const PageNotFound = () => (
    <div>
        <div className="wrapper">
            <main>
                <div className="page page_recommend page_layout_40">
                    <h2 className="page_back_title border_bottom_line">                     
                        죄송합니다, 요청하신 페이지를 찾을 수 없습니다.
                    </h2>
                    <div className="page_inner">
                        <div className="applicant_list">     
                            <p>잘못된 경로로 접근하셨습니다</p>                           
                        </div>                  
                    </div>
                </div>
            </main>
        </div>        
    </div>
);
export default PageNotFound;