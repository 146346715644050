import React, {Component, useEffect, useState} from 'react';
import WorkPostCode from "./WorkPostCode";
import {ErrorMessages} from "../../../utils/Message";
import {getCompInfo} from "../../../api/Comp/CompSignup/CompSignup";


const WorkCondition = (props) => {
    const { methods, address = [], disabled: propsDisabled,errors} = props;
    const { watch, setValue, reset, register} = methods;
    const watchField = watch();
    const disabled = propsDisabled !== undefined ? propsDisabled : false;

    const [employedYears, setEmployedYears] = useState(true);

    const recruit_condition = (e) => {
        switch(e.target.value){
            case "경력" : 
                setEmployedYears(false);
                break;
            default:
                setEmployedYears(true);
                setValue('condition.employed_years',0);
        }

    }

    return (
        <>
            <div className="box_division">
                <div className="wrap_box_input focus_in">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">근무조건</label>
                        <select value={watchField.condition.duty_form || '근무조건을 선택해주세요.'} name="condition.duty_form" id="condition.duty_form" className="select"
                                {...register('condition.duty_form', {
                                    required: ErrorMessages.required,
                                    min : 0
                                })}
                                disabled={disabled}
                        >
                            <option value="" hidden={true}>근무조건을 선택해주세요.</option>
                            <option value="주 4일">주 4일</option>
                            <option value="주 5일">주 5일</option>
                        </select>
                    </div>
                    <p className="msg_err">{errors?.condition?.duty_form?.message}</p>
                </div>
                <div className="wrap_box_input focus_in">
                    <div className="wrap_box_input_minor">
                        <div className="box_input exception_wage working_time">
                            <label htmlFor="" className=""></label>
                            <div className="exception_wage">
                                <span>1일</span>
                                <input type="number" id="" name={`condition.duty_hour`}
                                       {...register(`condition.duty_hour`, {required: ErrorMessages.required, min:0})}
                                       placeholder="시간을 숫자로 입력해주세요."
                                       disabled={disabled}

                                ></input>
                                <span>시간</span>
                            </div>
                        </div>
                        <p className="msg_err">{errors?.condition?.duty_hour?.message}</p>
                    </div>
                </div>
            </div>

            <div className="wrap_box_input">
                <div className="box_input">
                    <label htmlFor="" className="">근무형태</label>
                    <div className="wrap_box_radio box_education">
                        <div className="input_radio">
                            <label htmlFor="temp7" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp7" name="duty_type"
                                       defaultValue="정규직" {...register('condition.duty_type')}
                                       disabled={disabled}

                                ></input>
                                정규직</label>
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp8" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp8" name="duty_type"
                                       defaultValue="계약직" {...register('condition.duty_type')}
                                       disabled={disabled}
                                ></input>
                                계약직
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrap_box_input">
                <div className="box_input">
                    <label htmlFor="" className="">채용조건</label>
                    <div className="wrap_box_radio box_education">
                        <div className="input_radio">
                            <label htmlFor="temp9" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp9" name="employment_condition"
                                       defaultValue="신입" {...register('condition.employment_condition')}
                                       disabled={disabled}
                                       onClick={recruit_condition}
                                ></input>
                                신입</label>
                        </div>
                        <div className="input_radio with_input">
                            <label htmlFor="temp10" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp10" name="employment_condition"
                                       defaultValue="경력" {...register('condition.employment_condition')}
                                       disabled={disabled}
                                       onClick={recruit_condition}
                                ></input>
                                경력
                            </label>
                            <input type="number" className="input_number_select" min="0" max="99"
                                   placeholder="0" {...register('condition.employed_years')}
                                   disabled={employedYears}
                            ></input>년 이상
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp11" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp11" name="employment_condition"
                                       defaultValue="경력무관" {...register('condition.employment_condition')}
                                       disabled={disabled}
                                       onClick={recruit_condition}
                                ></input>
                                경력무관</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrap_box_input">
                <div className="box_input">
                    <label htmlFor="" className="">학력</label>
                    <div className="wrap_box_radio box_education">
                        <div className="input_radio">
                            <label htmlFor="temp12" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp12" name="education"
                                       defaultValue="고등학교 졸업이상" {...register('condition.education')}
                                       disabled={disabled}
                                ></input>
                                고등학교 졸업이상</label>
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp13" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp13" name="education"
                                       defaultValue="대학교(2~3년) 졸업 이상"{...register('condition.education')}
                                       disabled={disabled}
                                ></input>
                                대학교(2~3년) 졸업 이상</label>
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp14" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp14" name="education"
                                       defaultValue="대학교(4년) 졸업 이상"{...register('condition.education')}
                                       disabled={disabled}
                                ></input>
                                대학교(4년) 졸업 이상</label>
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp15" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp15" name="education"
                                       defaultValue="석사 이상"{...register('condition.education')}
                                       disabled={disabled}
                                ></input>
                                석사 이상</label>
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp16" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp16" name="education"
                                       defaultValue="박사 이상" {...register('condition.education')}
                                       disabled={disabled}
                                ></input>
                                박사 이상</label>
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp17" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp17" name="education"
                                       defaultValue="무관" {...register('condition.education')}
                                       disabled={disabled}
                                ></input>
                                무관</label>
                        </div>
                    </div>
                </div>
            </div>

            <WorkPostCode register={register} errors={errors} reset={reset} setValue={setValue} watchField={watchField}
                          address={address} disabled={disabled}/>
        </>
    )
}
export default WorkCondition;





