import { useState, useEffect,useCallback } from 'react';
import { getBannerList } from '../../api/User/Jop/Job';



const SlideItem = ({children,src,alt,href}) => {

    return (
        <swiper-slide className="slide_main_item swiper-slide">
            <div className='slide_main_list'>
                <img src={src} alt={alt}/>
                {
                    children
                }
            </div>
        </swiper-slide>
    );
}

const SlideItem2 = ({children,src,alt,href}) => {
    return (
        <swiper-slide className="slide_main_item swiper-slide">
            <div className='slide_main_list'>
                <a href={href} target="_self" className="slide_main_anchor">
                    <img src={src} alt={alt}/>
                    {
                        children
                    }
                </a>
            </div>
        </swiper-slide>
    );
}


const MainSlide = () => {

    const [banners,setBanners] = useState([]);
    const [error,setError] = useState(null);

    const getBanners = useCallback(
        () => {
            getBannerList(3).then(
                response => {
                    const data = response.data;
                    if(response.status === 200 && data["resultMsg"] === "성공."){
                        setBanners(data["resultData"]);
                        setError(null);
                    }else{
                        setError(Error("배너에러 발생함."));
                    }
                }
            ).catch((e)=>{setError(e)})
        },[]
    );

    useEffect(()=> {
        
        getBanners();
        
    },[]);

    return (
        <section className="section_main_visual">
                <h4 className="sr_only">메인 슬라이드</h4>
                <div className="wrap_slide_main">
                    <div className="slide_main">
                        <swiper-container navigation="true" pagination={{ clickable: true }} speed="500" loop="true" css-mode="true" autoplay={{ delay: 2000}}>
                            {
                                banners.map((e,i)=><SlideItem key={i} src={e.image_url}> </SlideItem>)
                            }
                        </swiper-container>
                    </div>
                </div>
        </section>
    );    
}



/*
            <section className="section_main_visual">
                <h4 className="sr_only">메인 슬라이드</h4>
                <div className="wrap_slide_main">
                    <div className="slide_main swiper">
                        <ul className="slide_main_list swiper-wrapper">
                            <SlideItem src="/img/main/visual.png" alt="슬라이드1">
                                <h2>여러분을<br /> 언제나 함께<br /> 응원합니다</h2>
                            </SlideItem>
                            <SlideItem src="/img/main/visual.png" alt="슬라이드2">
                                <h2><span>2</span>이<br /> 언제나 함께<br /> 응원합니다</h2>
                            </SlideItem>
                            <SlideItem src="/img/main/visual.png" alt="슬라이드2">
                                <h2><span>3</span>이<br /> 언제나 함께<br /> 응원합니다</h2>
                            </SlideItem>
                        </ul>
                        <div className="wrap_swiper_buttons">
                            <div className="swiper-button-prev"></div>
                            <div className="swiper-button-next"></div>
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
        </section>


        <section className="section_main_visual">
            <h4 className="sr_only">메인 슬라이드</h4>
            <div className="wrap_slide_main">
                <swiper-container className="slide_main"  navigation="true" pagination="true">
                    {
                        banners.map((e,i)=><SlideItem2 key={i} src={e.image_url}> <h2>여러분을<br /> 언제나 함께<br /> 응원합니다</h2> </SlideItem2>)
                    }
                </swiper-container>
            </div>
        </section>
        */

export default MainSlide