import { useGameTimer } from "../../../hooks/useGameTimer";
import { getImageUrl } from "../../../utils/common";
import NoticeTemplate from "../common/NoticeTemplate";
import Tip from "../common/Tip";
import { INITIAL_COUNTDOWN_SECONDS } from "../games";

export default function PictureQuizPreview({ cateIdx, nextPage }) {
  const { GameTimerComponent } = useGameTimer({
    initialDurationInSeconds: INITIAL_COUNTDOWN_SECONDS,
  });

  return (
    <div className="quiz-preview">
      <NoticeTemplate
        title="게임형 평가 시작전 안내사항"
        list={[
          "1. 문항형 평가와 달리 게임형 평가는 시간제한이 있으며 멈춤없이 진행됩니다.",
          "2. 문항형 평가와 달리 게임형 평가는 해당문제의 제한시간 종료후 [다음으로] 기능이 활성화되며, 답변수정 불가하니 신중한 답변을 바랍니다.",
        ]}
      />

      <GameTimerComponent />

      <div className="quiz flex">
        <div className="quiz-assets">
          <img
            className="picture-quiz-image"
            src={getImageUrl("/game/situation/A/A_preview.webp")}
            alt="/game/situation/A/A_preview.webp"
          />
        </div>

        <div className="quiz-answer flex">
          <h3>
            Q. 주어진 그림을 확인하여 다음 문제 4가지에 대한 답변을 작성하시오.
          </h3>

          <table className="picture-quiz-table">
            <thead>
              <tr>
                <th align="start">문제</th>
                <th align="start">정답</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td align="start">노트위에 적힌 숫자는 몇 입니까?</td>
                <td></td>
              </tr>
              <tr>
                <td align="start">색연필은 몇 자루입니까?</td>
                <td></td>
              </tr>
              <tr>
                <td align="start">가위는 몇 개입니까?</td>
                <td></td>
              </tr>
              <tr>
                <td align="start">계산기 맨 위에 적혀있는 숫자는 몇 입니까?</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div className="wrap_btns" style={{ marginTop: 0 }}>
            <button onClick={nextPage} className="btn_submit filled_black">
              게임시작
            </button>
          </div>
        </div>
      </div>

      <Tip top={230} right={-180} arrowDirection="left-top">
        그림 속 이미지와
        <br />
        문제 확인 후
        <br />
        답변을 숫자만 입력하세요.
      </Tip>

      <Tip top={385} right={110} arrowDirection="left-top">
        게임 규칙을 이해 완료하였으면
        <br />
        버튼 클릭 후 게임을 시작하세요.
      </Tip>

      {Boolean(cateIdx) && (
        <Tip bottom={-65} right={200} arrowDirection="bottom-right">
          제한시간 이후 버튼 클릭 가능하며,
          <br />
          입력한 답변 확인은 가능하나 답변
          <br />
          수정은 불가합니다.
        </Tip>
      )}

      <Tip bottom={-65} right={-100} arrowDirection="bottom-left">
        버튼 클릭 전까지 입력값 수정
        <br />
        가능하며, 제한시간이 종료된
        <br />
        경우 정답 입력 및 수정이
        <br />
        불가능합니다.
      </Tip>
    </div>
  );
}
