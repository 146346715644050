import React from "react";
import "./guide.css";

const GuideComponent = ({ setShowGuide }) => {
  const guideNever = () => {
    localStorage.setItem("neverShowGuide", "true");
    setShowGuide(false);
  };

  const guideClose = () => {
    setShowGuide(false);
  };

  return (
    <div style={styles.container}>
      <div style={styles.guideImage} />
      <div style={styles.btnGuide}>
        <button
          className="guide_button"
          alt="neverShowBtn"
          onClick={() => guideNever()}
        >
          다시 보지 않기
        </button>
        <button
          className="guide_button"
          alt="neverShowBtn"
          onClick={() => guideClose()}
        >
          닫기
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 1,
  },
  guideImage: {
    position: "absolute",
    width: "100%",
    height: "76%",
    backgroundImage: 'Url("/img/chatbot/chatbot-example.png")',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    margin: "90px 80px 70px 0px",
  },
  btnGuide: {
    display: "flex",
    justifyContent: "space-between",
    margin: "6rem auto",
    minWidth: "300px",
    maxWidth: "400px",
    width: "33%",
    height: "auto",
  },
  neverShowBtn: {
    width: "85px",
    height: "20px",
    marginLeft: "25px",
    cursor: "pointer",
  },
  closeBtn: {
    width: "38px",
    height: "20px",
    marginRight: "20px",
    cursor: "pointer",
  },
};

export default GuideComponent;
