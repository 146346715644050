import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Aside from './components/lnb/Aside';

import Main from "./container/main/Main";

import SelectSignup from "./container/user/signup/SelectSignup";
import LoginForm from "./container/user/login/LoginForm";
import TermsConditions from "./container/user/signup/TermsConditions";
import CountType from "./container/user/signup/CountType";
import Signup from "./container/user/signup/Signup";
import SnsSignup from "./container/user/signup/SnsSignup";
import InterSignup from "./container/user/signup/InterSignup";
import SignupSuccess from "./container/user/signup/SignupSuccess";
import UserInfo from "./container/user/signup/UserInfo";
import AbilityMain from "./container/user/ability/main"
import AbilityProcced from "./container/user/ability/proceed"
import AbilityProgress from "./container/user/ability/progress"
import SocialSignUpRedirect from "./container/user/signup/SocialSignUpRedirect"
import SocialLoginRedirect from "./container/user/login/SocialLoginRedirect"
import ResumeForm from "./container/user/resume/ResumeForm";
import DesiredJob from "./container/user/resume/DesiredJob";
import ResumeProcess from "./container/user/resume/ResumeProcess"
import BeforeResumeForm from './container/user/resume/BeforeResumeForm'
import InterResume from "./container/user/resume/InterResume";
import InterRegistedResume from "./container/user/resume/InterRegistedResume";
import JobMain from './container/user/job/main';
import SocialIntersignupRedirect from "./container/user/signup/SocialIntersignupRedirect";
import ResumeUpdate from "./container/user/resume/ResumeUpdate";
import DesiredJobUpdate from "./container/user/resume/DesiredJobUpdate";
import JobInfo from "./container/user/job/JobInfo";
import DetailPage from "./container/user/job/DetailPage"
import RecommendLecture from "./container/user/lms/RecommendLecture";
import MyLecture from "./container/user/lms/MyLecture";
import LmsError from "./container/user/lms/LmsError";
import CareerPath from "./container/user/job/CareerPath"
import UserInterviewMain from "./container/user/interview/InterviewMain";

import CompTermsConditions from "./container/comp/signup/TermsConditions";
import CompSignup from "./container/comp/signup/CompSignup";
import CompSignupsuccess from "./container/comp/signup/CompSignupsuccess";
import JobRequestForm from "./container/comp/JobRequest/JobRequestForm";
import JobRequestUpdate from "./container/comp/JobRequest/JobRequestUpdate";
import ChooseJobRequest from "./container/comp/JobRequest/ChooseJobRequest";
import CompInfoManage from "./container/comp/signup/CompInfoManage";
import JobApplicationList from "./container/comp/JobRequest/JobApplicationList";
import ChooseJobRequestUpdate from "./container/comp/JobRequest/ChooseJobRequestUpdate";
import ApplicantMain from "./container/comp/applicant/main";
import CompInterviewMain from "./container/comp/interview/InterviewMain";

import HealthChk from "./container/test/HealthChk"
import calTest from "./container/test/calTest"
import WithdrawalPage from "./container/user/signup/WithdrawalPage";
import CompWithdrawalPage from "./container/comp/signup/CompWithdrawalPage";
import PageNotFound from './components/error/PageNotFound';
class App extends Component {
  render(){
    return (
        <Router>
            <div>
                <Header />
                <Aside />
                <Switch>
                <Route exact path="/" component={Main} />
                <Route path="/home/:alert" component={Main} />
                <Route path="/auth/redirect" component={SocialLoginRedirect} />
                <Route path="/auth/redirect/join" component={SocialSignUpRedirect} />
                <Route path="/auth/signup/redirect" component={SocialIntersignupRedirect} />

                <Route path="/user/login/LoginForm" component={LoginForm} />
                <Route path="/user/signup/selectsignup" component={SelectSignup} />
                <Route path="/user/signup/termsconditions" component={TermsConditions} />
                <Route path="/user/signup/counttype" component={CountType} />
                <Route path="/user/signup/signup" component={Signup} />
                <Route path="/user/signup/snssignup" component={SnsSignup} />
                <Route path="/user/signup/intersignup" component={InterSignup} />
                <Route path="/user/signup/signupsuccess" component={SignupSuccess} />
                <Route path="/user/signup/UserInfo" component={UserInfo} />
                <Route path="/user/signup/withdrawal" component={WithdrawalPage} />
                <Route path="/user/ability/main" component={AbilityMain} />
                <Route path="/user/ability/procced" component={AbilityProcced} />
                <Route path="/user/ability/progress" component={AbilityProgress} />
                <Route path="/user/resume/resumeForm/:resumeNo" component={ResumeForm} />
                <Route path="/user/resume/resumeUpdate/:resumeNo" component={ResumeUpdate} />
                <Route path="/user/resume/desiredJob/:resumeNo" component={DesiredJob} />
                <Route path="/user/resume/desiredJobUpdate/:resumeNo" component={DesiredJobUpdate} />
                <Route path="/user/resume/resumeProcess/:resumeNo" component={ResumeProcess} />
                <Route path="/user/resume/beforeResume" component ={BeforeResumeForm}/>
                <Route path="/user/resume/interResume" component ={InterResume}/>
                <Route path="/user/resume/interRegistedResume" component ={InterRegistedResume}/>
                <Route path="/user/job/recruitInfo" component={JobInfo} />
                <Route path="/user/job/main" component={JobMain} />
                <Route path="/user/job/detailPage/:recruitSeq" component={DetailPage} />
                <Route path="/user/job/CareerPath" component={CareerPath} />
                <Route path="/user/interview/InterviewMain" component={UserInterviewMain} />

                <Route path="/user/lms/recommendLecture" component ={RecommendLecture}/>
                <Route path="/user/lms/myLecture" component ={MyLecture}/>
                <Route path="/user/lms/error" component={LmsError} />

                <Route path="/comp/signup/termsconditions" component={CompTermsConditions} />
                <Route path="/comp/signup/compSignup" component={CompSignup} />
                <Route path="/comp/signup/compSignupsuccess" component={CompSignupsuccess} />
                <Route path="/comp/signup/compInfoManage" component={CompInfoManage} />
                <Route path="/comp/signup/compWithdrawal" component={CompWithdrawalPage} />
                <Route path="/comp/jobRequest/jobRequestForm" component={JobRequestForm} />
                <Route path="/comp/jobRequest/jobRequestUpdate/:recruitSeq" component={JobRequestUpdate} />
                <Route path="/comp/jobRequest/chooseJobRequest/:recruitSeq" component={ChooseJobRequest} />
                <Route path="/comp/jobRequest/chooseJobRequest" component={ChooseJobRequest} />
                <Route path="/comp/jobRequest/chooseJobRequestUpdate/:recruitSeq" component={ChooseJobRequestUpdate} />
                <Route path="/comp/jobRequest/jobApplicationList" component={JobApplicationList} />
                <Route path="/comp/Applicant/main/:recruit_seq" component={ApplicantMain} />
                <Route path="/comp/interview/InterviewMain" component={CompInterviewMain} />

                <Route path="/HealthChk" component={HealthChk} />
                <Route component={PageNotFound} />
                </Switch>
                <Footer />

            </div>
        </Router>
    )
}
}
export default App;
