
import React, { useState} from 'react';
import ConfirmDateModal from "./ConfirmDateModal";

const SelectDateModal = (props) => {  
    const [confirmDateModal,setConfirmDateModal] = useState(false); 
    const [confirmDate,setConfirmDate] = useState(false);     
    const [interviewSeq,setInterviewSeq] = useState(false);     
    const handleDate = (e,date) => { 
        //e.preventDefault();
        setConfirmDate(date);
        setInterviewSeq(props.selectDate.interview_seq);
    };    
    const isDateBeforeToday = (dateString) => {
        const selectedDate = new Date(dateString);
        const today = new Date();
        today.setHours(0, 0, 0, 0); // 오늘 날짜의 시간을 00:00:00으로 설정하여 날짜만 비교
        return selectedDate < today;
    };
    return (
        <>
            <div className="popup_layer popup_middle popSelectInterviewDate" id="popSelectInterviewDate">
                <div className="popup_inner">
                    <div className="pop_title">
                        <h3>면접일정 선택</h3>
                        <button type="button" aria-label="레이어 팝업 닫기" className="popup_close" onClick={(e)=>{e.preventDefault();props.setSelectDateModal(false)}}></button>
                    </div>
                    <div className="popup_contents">
                        <div className="selection_wrap">                      
                            <div className="wrap_btn_small select_btn_wrap">
                                {
                                    props.selectDate.interview_dt1 && (
                                        <div className="input_wrap">                                                
                                            <input type="radio" id="interview_dt1" name="select_date" onChange={(e) => handleDate(e,'1')} checked={confirmDate === '1'} disabled={isDateBeforeToday(props.selectDate.interview_dt1)}/>                                     
                                            <label htmlFor="interview_dt1">{props.selectDate.interview_dt1.substring(0,16)}</label>
                                        </div>
                                    )
                                }
                                {
                                    props.selectDate.interview_dt2 && (
                                        <div className="input_wrap">                                                
                                            <input type="radio" id="interview_dt2" name="select_date" onChange={(e) => handleDate(e,'2')} checked={confirmDate === '2'} disabled={isDateBeforeToday(props.selectDate.interview_dt2)}/>    
                                            <label htmlFor="interview_dt2">{props.selectDate.interview_dt2.substring(0,16)}</label>
                                        </div>
                                    )
                                }
                                {
                                    props.selectDate.interview_dt3 && (
                                        <div className="input_wrap">                                                
                                            <input type="radio" id="interview_dt3" name="select_date" onChange={(e) => handleDate(e,'3')} checked={confirmDate === '3'} disabled={isDateBeforeToday(props.selectDate.interview_dt3)}/>  
                                            <label htmlFor="interview_dt3">{props.selectDate.interview_dt3.substring(0,16)}</label>
                                        </div>
                                    )
                                }
                                <div className="input_wrap">                                                
                                    <input type="radio" id="no_date" name="select_date" onChange={(e) => handleDate(e,'N')} checked={confirmDate === 'N'} />
                                    <label htmlFor="no_date">가능한 면접일정이 없습니다.</label>
                                </div>                       
                            </div>                    
                        </div>                       
                        <div className="popup_middle_btn_wrap">
                            <a href="#" target="_self" className="btn_submit filled_black" onClick={(e)=>{e.preventDefault();props.setSelectDateModal(false)}}>취소</a>
                            <a href="#" target="_self" className="btn_submit filled_green" onClick={(e)=>{e.preventDefault();setConfirmDateModal(true)}}>확인</a>
                        </div>              
                    </div>
                </div>
            </div>
            { confirmDateModal && <ConfirmDateModal setConfirmDateModal={setConfirmDateModal} setSelectDateModal={props.setSelectDateModal} confirmDate={confirmDate} interviewSeq={interviewSeq}/> }
        </>
    )
};

export default SelectDateModal;