import React, { useState } from "react";
import api from "../../../api/api";

const LoginForm = () => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");

  const handleUserIdChange = (e) => {
    setId(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const activeEnter = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };
  const handleLogin = () => {
    const formData = {
      id: id,
      password: password,
    };
    loginUser(formData);
  };
  const loginUser = async (formData) => {
    try {
      const loginResponse = await api.post("/api/v1/auth/login", formData);
      if (loginResponse.data.resultCode != 9000) {
        const token = loginResponse.data.resultData;
        localStorage.setItem("accessToken", token);

        const headers = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const [userResponse, seqNoResponse] = await Promise.all([
          api.get("/api/v1/users", headers),
          api.get("/api/v1/getSeqNo", headers),
        ]);

        const user = userResponse.data.resultData;
        const { username, providerType, userId, roleType } = user;
        const userData = { username, providerType, userId, roleType };
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("userId", userData.userId);
        const mem_seq = seqNoResponse.data.resultData;
        localStorage.setItem("mem_seq", mem_seq);

        window.location.href = "/";
      } else {
        alert("회원가입 계정이 아닙니다. 회원가입을 완료해주세요.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <div className="wrapper">
        <main>
          <div className="page login page_login">
            <h2 className="page_title">로그인</h2>
            <div className="area_shade area_shade_login">
              <div className="wrap_login_input tab_contents on login_page">
                <ul className="login_input_list login_page_input_list">
                  <li className="login_input_item">
                    <p>아이디</p>
                    <input
                      type="text"
                      placeholder="아이디를 입력해주세요"
                      value={id}
                      onChange={handleUserIdChange}
                      onKeyDown={(e) => activeEnter(e)}
                    />
                  </li>
                  <li className="login_input_item">
                    <p>비밀번호</p>
                    <input
                      type="password"
                      placeholder="비밀번호를 입력해주세요"
                      value={password}
                      onChange={handlePasswordChange}
                      onKeyDown={(e) => activeEnter(e)}
                    />
                  </li>
                </ul>
                <a
                  href="#"
                  target="_self"
                  className="btn_submit filled_green btn_submit filled_green_btn"
                  onClick={handleLogin}
                >
                  로그인
                </a>
                <div className="wrap_info login_btns">
                  <div className="box_input_chk">
                    <input
                      type="checkbox"
                      className="input_chk"
                      id="loginmaintain01"
                      name="loginmaintain01"
                    />
                    <label
                      htmlFor="loginmaintain01"
                      className="input_chk_label"
                    >
                      로그인 상태 유지
                    </label>
                  </div>
                  <div className="wrap_btns">
                    <a href="#" target="_self" className="btn_">
                      ID/PW 찾기
                    </a>
                  </div>
                  <div className="wrap_btns">
                    <a
                      href="/user/signup/selectsignup"
                      target="_self"
                      className="btn_"
                    >
                      회원가입
                    </a>
                  </div>
                </div>
                <div className="wrap_social_login">
                  <p>소셜로그인</p>
                  <ul className="social_login_list">
                    <li className="social_login_item">
                      <a
                        href="/api/oauth2/authorization/login/google?redirect_uri=https://matching.vdream.co.kr/auth/redirect/login"
                        target="_self"
                        className="social_login_anchor"
                        title="구글 아이디로 로그인"
                      >
                        <img src="/img/icon/login_google.png" alt="" />
                      </a>
                    </li>
                    <li className="social_login_item">
                      <a
                        href="/api/oauth2/authorization/login/kakao?redirect_uri=https://matching.vdream.co.kr/auth/redirect/login"
                        target="_self"
                        className="social_login_anchor"
                        title="카카오 아이디로 로그인"
                      >
                        <img src="/img/icon/login_kakao.png" alt="" />
                      </a>
                    </li>
                    <li className="social_login_item">
                      <a
                        href="/api/oauth2/authorization/login/naver?redirect_uri=https://matching.vdream.co.kr/auth/redirect/login"
                        target="_self"
                        className="social_login_anchor"
                        title="네이버 아이디로 로그인"
                      >
                        <img src="/img/icon/login_naver.png" alt="" />
                      </a>
                    </li>
                    <li className="social_login_item">
                      <a
                        href="/api/oauth2/authorization/login/facebook?redirect_uri=https://matching.vdream.co.kr/auth/redirect/login"
                        target="_self"
                        className="social_login_anchor"
                        title="페이스북 아이디로 로그인"
                      >
                        <img src="/img/icon/login_facebook.png" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <a href="/" className="btn_submit filled_black">
              이전으로
            </a>
          </div>
        </main>
      </div>
    </div>
  );
};

export default LoginForm;
