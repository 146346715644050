import React, {useEffect, useState} from 'react';
import api from '../../../api/api';
import Pagination from "../../../api/Pagination";
import DatePicker from "react-datepicker";
import SelectDateModal from "./SelectDateModal";
import ApplicantCancelModal from "./ApplicantCancelModal";
import CancelWarningModal from "./CancelWarningModal";
import {ko} from 'date-fns/esm/locale';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import JobPostingModal from "./JobPostingModal";

const InterviewList = () => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [visiblePageCount, setVisiblePageCount] = useState(10);
    const [result, setResult] = useState(false);  
    const [search, setSearch] = useState(false);  
    const [searchTerm, setSearchTerm] = useState('');  
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [startDt, setStartDt] = useState('');  
    const [endDt, setEndDt] = useState('');  
    const [userEvaluation, setUserEvaluation] = useState('');  
    const offset = (page - 1) * limit;
    const [applicantCancelModal,setApplicantCancelModal] = useState(false);  
    const [cancelWarningModal,setCancelWarningModal] = useState(false);  
    const [selectDateModal,setSelectDateModal] = useState(false);
    const [selectDate,setSelectDate] = useState(false); 
    const [applicant,setApplicant] = useState(false); 
    const [cancelWarning,setCancelWarning] = useState(false);
    const [jobPostingModal, setJobPostingModal] = useState(false);
    const [recruitSeq, setRecruitSeq] = useState(null);
    
    const [sortColumn, setSortColmn] = useState('interview_dt');
    const [orderType, setOrderType] = useState('DESC');
    const [orderTypeApplyDt, setOrderTypeNumApplyDt] = useState('DESC');
    const [orderTypeNm, setOrderTypeNm] = useState('DESC');
    const [orderTypeType, setOrderTypeType] = useState('DESC');
    const [orderTypeInterviewDt, setOrderTypeInterviewDt] = useState('DESC');

    useEffect(() => {
        api.post('/api/user/interview/getInterviewList').then(response => response.data)
            .then((response) => {
                if(response && response.resultCode == 1000){
                    setResult(response.resultData);
                } else {
                    //alert("일치하는 정보가 없습니다.");
                }
            }
        );
    }, []);
    const searchList = (e) => {
        e.preventDefault()
        let data ={            
            start_dt : startDt,
            end_dt : endDt,
            user_evaluation : userEvaluation,
            searchTerm : searchTerm,
            sort_column : sortColumn,
            order_type : orderType
        }
        setSearch(true);
        api.post('/api/user/interview/searchInterviewList',data).then(response => response.data)
            .then((response) => {
                if(response && response.resultCode == 1000){
                    setResult(response.resultData);
                } else {
                    //alert("일치하는 정보가 없습니다.");
                }
            }
        );
    }       
    const changeOrder = (e,column, type) =>{
        setSortColmn(column);
        setOrderTypeNumApplyDt('DESC');
        setOrderTypeNm('DESC');
        setOrderTypeType('DESC');
        setOrderTypeInterviewDt('DESC');
        if(column ==='apply_dt'){
            setOrderTypeNumApplyDt(type);
            setOrderType(orderTypeApplyDt);    
        }
        else if(column === 'enterprise_nm'){
            setOrderTypeNm(type);
            setOrderType(orderTypeNm);    
        }
        else if(column ==='evaluation'){
            setOrderTypeType(type);
            setOrderType(orderTypeType);    
        }
        else if(column === 'interview_dt'){
            setOrderTypeInterviewDt(type);
            setOrderType(orderTypeInterviewDt);    
        }
        searchList(e);
    }
    const handleSelectDate = (e, value) => {
        setSelectDate(value)
        setSelectDateModal(true);
    }
    const handleCancelWarning = (e, value) =>{
        setCancelWarning(value)
        setCancelWarningModal(true);

    }
    const handleApplicantCancelModal = (e, value) =>{
        setApplicant(value)
        setApplicantCancelModal(true);

    }
    const moveToInterviewRoom = (e,link) =>{
        e.preventDefault()
        window.open(link,'interviewRoom');
    }
    const handlePeriodChange = (period) => {
        // 선택된 기간에 따라 시작 날짜와 종료 날짜를 계산하여 설정
        const currentDate = new Date();
        let newStartDate = new Date();    
        switch (period) {
          case '1주일':
            newStartDate.setDate(currentDate.getDate() - 7);
            break;
          case '1개월':
            newStartDate.setMonth(currentDate.getMonth() - 1);
            break;
          case '3개월':
            newStartDate.setMonth(currentDate.getMonth() - 3);
            break;
          case '6개월':
            newStartDate.setMonth(currentDate.getMonth() - 6);
            break;
          default:
            // 기본값 설정
            break;
        }    
        setSelectedPeriod(period);
        setStartDt(newStartDate.toISOString().split('T')[0]);
        setEndDt(currentDate.toISOString().split('T')[0]);
    };
    const handleJobPostingModal = (recruitSeq) => {
        setJobPostingModal(true);
        setRecruitSeq(recruitSeq);
    }

    return (
        <div className="table_init status_table">
            <div className="search_form_area">    
                <div className="wrap_search_area">
                    <div className="search_from_wrap">                               
                        <form className="wrap_input">
                            <span className="search_label">조회기간</span>
                            <div className="wrap_btn_small select_btn_wrap">
                            <div className="input_wrap">                                 
                                    <input type="radio" id="resume11" name="select_period"
                                        onChange={() => handlePeriodChange('1주일')} checked={selectedPeriod === '1주일'} />
                                    <label htmlFor="resume11">1주일</label>
                                </div>
                                <div className="input_wrap">                                                
                                    <input type="radio" id="resume12" name="select_period"
                                        onChange={() => handlePeriodChange('1개월')} checked={selectedPeriod === '1개월'} />
                                    <label htmlFor="resume12">1개월</label>
                                </div>
                                <div className="input_wrap">                                                
                                    <input type="radio" id="resume13" name="select_period"
                                        onChange={() => handlePeriodChange('3개월')} checked={selectedPeriod === '3개월'} />
                                    <label htmlFor="resume13">3개월</label>
                                </div>
                                <div className="input_wrap">                                                
                                    <input type="radio" id="resume14" name="select_period"
                                        onChange={() => handlePeriodChange('6개월')} checked={selectedPeriod === '6개월'} />
                                    <label htmlFor="resume14">6개월</label>
                                </div>                           
                            </div>
                        </form>
                        <form className="wrap_input">
                            <span className="search_label">날짜선택</span>
                            <div className="wrap_date_input">
                                <input type="text" id="" className="input_datepicker input_mid"
                                    name={`startDt`} style={{display: "none"}} />
                                <DatePicker
                                    onKeyDown={(e) => {console.log(e); if((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 8 && e.keyCode !== 189) {e.preventDefault();}}}
                                    locale={ko} selected={startDt ? new Date(startDt) : null}
                                    onChange={(date) => { date && setStartDt(dayjs(date).format('YYYY-MM-DD'))}}
                                    dateFormat="yyyy-MM-dd"
                                />   
                                <span className="tilde">~</span>
                                <input type="text" id="" className="input_datepicker input_mid"
                                    name={`endDt`} style={{display: "none"}} />
                                <DatePicker
                                    onKeyDown={(e) => {console.log(e); if((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 8 && e.keyCode !== 189) {e.preventDefault();}}}
                                    locale={ko} selected={endDt ? new Date(endDt) : null}
                                    onChange={(date) => { date && setEndDt(dayjs(date).format('YYYY-MM-DD'))}}
                                    dateFormat="yyyy-MM-dd"
                                /> 
                            </div>
                        </form>
                    </div>    
                    <div className="search_from_wrap">     
                        <form className="wrap_input">
                            <span className="search_label">지원상태</span>
                            <select onChange={(e)=>setUserEvaluation(e.target.value)} name="category" title="검색조건" className="select" defaultValue=''>
                                <option key='A' value=''>전체</option>
                                <option key='1' value="1">지원완료</option>
                                <option key='2' value="2">서류탈락</option>   
                                <option key='3' value="3">면접진행</option>   
                                <option key='4' value="4">면접탈락</option>   
                                <option key='5' value="5">최종합격</option>  
                                <option key='7' value="7">지원취소</option>        
                            </select>
                            <input type="search" placeholder="검색어를 입력해 주세요." value={searchTerm} onChange={(e)=>setSearchTerm(e.target.value)}/>
                            <button type="submit" className="filled_green btn_with_search" title="검색" onClick={(e)=>searchList(e)}>검색</button>                                    
                        </form>
                    </div>                           
                </div> 
            </div>
            <h3 className="border_bottom_line">지원현황</h3>
            {
                (
                    <>
                        <div className="wrap_applicant_list">
                            <table className="table_detail sort_column">
                                <colgroup>
                                    <col style={{width:"110px"}} />     
                                    <col style={{width:"auto"}} />   
                                    <col style={{width:"115px"}} />   
                                    <col style={{width:"170px"}} />   
                                    <col style={{width:"120px"}} />   
                                    <col style={{width:"110px"}} />                                            
                                </colgroup>
                                <thead>
                                    <tr>
                                        {
                                            (orderTypeApplyDt !== 'ASC' && orderTypeApplyDt != null) ?
                                                <th scope="col" className="header_sort01 sort_down" onClick={(e)=>{changeOrder(e,'apply_dt','ASC')}}>지원일</th> 
                                                : <th scope="col" className="header_sort01 sort_down rotate" onClick={(e)=>{changeOrder(e,'apply_dt','DESC')}}>지원일</th> 
                                        }
                                        {
                                        (orderTypeNm !== 'ASC' && orderTypeNm != null) ?
                                            <th scope="col" className="header_sort02 sort_down" onClick={(e)=>{changeOrder(e,'enterprise_nm','ASC')}}>기업명</th>    
                                            : <th scope="col" className="header_sort02 sort_down rotate" onClick={(e)=>{changeOrder(e,'enterprise_nm','DESC')}}>기업명</th>    
                                        }                                                                
                                        {
                                        (orderTypeType !== 'ASC' && orderTypeType != null) ?
                                            <th scope="col" className="header_sort03 sort_down" onClick={(e)=>{changeOrder(e,'evaluation','ASC')}}>지원상태</th>
                                            : <th scope="col" className="header_sort03 sort_down rotate" onClick={(e)=>{changeOrder(e,'evaluation','DESC')}}>지원상태</th>
                                        }
                                        {
                                            (orderTypeInterviewDt !== 'ASC' && orderTypeInterviewDt != null) ?
                                                <th scope="col" className="header_sort04 sort_down" onClick={(e)=>{changeOrder(e,'interview_dt','ASC')}}>면접일정</th>
                                                : <th scope="col" className="header_sort04 sort_down rotate" onClick={(e)=>{changeOrder(e,'interview_dt','DESC')}}>면접일정</th>
                                        }                        
                                        <th scope="col" className="no_sort">면접실</th>
                                        <th scope="col" className="no_sort">취소/삭제</th>                                                 
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        result ? (result.slice(offset,offset+limit).map((value, i)=>{
                                            let applicant_status;
                                            let cancel_button;
                                            let display_date;
                                            let display_interviewDt;
                                            if(value.user_evaluation_name=='지원완료'){
                                                applicant_status = 'txt_icon txt_icon_orange';
                                                cancel_button = 1;
                                                display_interviewDt = 0;
                                            }  
                                            else if(value.user_evaluation_name=='서류탈락'){
                                                applicant_status = 'txt_icon txt_icon_scarlet';
                                                cancel_button = 2;
                                                display_interviewDt = 0;
                                            }  
                                            else if(value.user_evaluation_name=='면접탈락'){
                                                applicant_status = 'txt_icon txt_icon_pink';
                                                cancel_button = 2;
                                                display_interviewDt = 1;
                                            }  
                                            else if(value.user_evaluation_name=='최종합격') {
                                                applicant_status = 'txt_icon txt_icon_blue';
                                                cancel_button = 2;
                                                display_interviewDt = 1;
                                            } 
                                            else if(value.user_evaluation_name=='면접진행') {                         
                                                switch (value.select_confirm) {
                                                    case "1":
                                                        if(value.interview_dt1 != null)
                                                            display_date = value.interview_dt1.substring(0, 16);
                                                        else
                                                            display_date = "가능한 일정이 없습니다";
                                                        break;
                                                    case "2":
                                                        if(value.interview_dt2 != null)
                                                            display_date = value.interview_dt2.substring(0, 16);
                                                        else
                                                            display_date = "가능한 일정이 없습니다";
                                                            break;
                                                    case "3":
                                                        if(value.interview_dt3 != null)
                                                            display_date = value.interview_dt3.substring(0, 16);
                                                        else
                                                            display_date = "가능한 일정이 없습니다";
                                                            break;
                                                    default:
                                                        display_date = "가능한 일정이 없습니다";
                                                        break;
                                                }
                                                applicant_status = 'txt_icon txt_icon_purple';
                                                cancel_button = 1;
                                                display_interviewDt = 1;
                                            } 
                                            else if(value.user_evaluation_name=='지원취소') {
                                                applicant_status = 'txt_icon txt_icon_blueblack';
                                                cancel_button = 2;
                                                display_interviewDt = 0;
                                            } 
                                            else if(value.user_evaluation_name=='모집취소') {
                                                applicant_status = 'txt_icon txt_icon_blueblack';
                                                cancel_button = 2;
                                                display_interviewDt = 0;
                                            } 
                                            return (
                                                <tr key={i}>
                                                    <td>{value.apply_dt}</td>                                                                    
                                                    <td className="align_left">                                            
                                                        <a href="#none" className="resume_popup" onClick={() => handleJobPostingModal(value.recruit_seq)}>
                                                            <p>{value.enterprise_nm}</p>
                                                            <p className="font_gray">{value.recruit_name}</p>
                                                        </a>
                                                    </td>                                    
                                                    <td className="no_ellipsis"><span className={applicant_status}>{value.user_evaluation_name}</span></td>   
                                                    {
                                                            display_interviewDt == 1  ?
                                                                value.interview_dt != null ?  
                                                                    <td className="font_red">{value.interview_dt.substring(0,16)}</td> 
                                                                    : value.select_confirm != null ? 
                                                                    <td className="font_gray">{display_date}</td>
                                                                        : value.interview_dt1 != null ? 
                                                                            (<td><button type="button" className="btn_table_small lined_green"  onClick={(e) => {e.preventDefault(); handleSelectDate(e, value )}}>면접일정 선택</button></td>) 
                                                                            : <td></td> 
                                                                : <td></td>
                                                    }
                                                    <td>
                                                        {
                                                            value.interview_dt != null && cancel_button == 1 ? (
                                                                new Date(value.interview_dt).toDateString() === new Date().toDateString() ? (
                                                                    value.zoom_link !== null ? (
                                                                        <button type="button" className="btn_table_small lined_green" onClick={(e)=>(moveToInterviewRoom(e,value.zoom_link))}>면접실 입장</button>
                                                                    ) : (
                                                                        <button type="button" className="btn_table_small" disabled>면접실 입장</button>
                                                                    )
                                                                ) : (
                                                                    <button type="button" className="btn_table_small" disabled>면접실 입장</button>
                                                                )
                                                            ) : null                                                                
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            cancel_button === 1 ?
                                                            <button type="button" className="btn_table_small lined_gray" onClick={(e) => {e.preventDefault(); handleApplicantCancelModal(e, value )}}>지원취소</button>   
                                                            :<button type="button" className="btn_table_small lined_gray" onClick={(e) => {e.preventDefault(); handleCancelWarning(e, value )}}>내역삭제</button>   
                                                    }                                    
                                                    </td>                                                                                                                
                                                </tr>
                                            )
                                        })) : (
                                            <tr>
                                                <td colSpan="6">지원 공고가 없습니다.</td>
                                            </tr>
                                        )
                                    }                                      
                                </tbody>
                            </table>
                        </div>
                        {
                            result.size > limit && (
                                <div className="wrap_pagination">
                                    <Pagination
                                        total={result.size}
                                        limit={limit}
                                        page={page}
                                        setPage={setPage}
                                        visiblePageCount={visiblePageCount}
                                    />
                                </div>
                            )
                        } 
                    </> 
                )
            }
            { applicantCancelModal && <ApplicantCancelModal setApplicantCancelModal={setApplicantCancelModal} applicant={applicant}/> }
            { cancelWarningModal && <CancelWarningModal setCancelWarningModal={setCancelWarningModal}  cancelWarning={cancelWarning}/> }
            { selectDateModal && <SelectDateModal setSelectDateModal={setSelectDateModal} selectDate={selectDate} /> }
            { jobPostingModal && <JobPostingModal setJobPostingModal={setJobPostingModal} recruitSeq={recruitSeq} /> }
        </div>
    )
};

export default InterviewList;