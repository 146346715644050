import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import {useHistory,useParams,Link } from "react-router-dom";

const Process = () => {
    const { resumeNo } = useParams();
    const goBackUrl = '/user/resume/beforeResume';
    
    let history = useHistory();
    return (
        <div>
            <div className="wrapper">
                <main>
                    <div className="page join page_join page_layout_40">
                        <h2 className="page_back_title">
                            <a href={goBackUrl} className="page_back">진행방식 선택</a>
                        </h2>
                        <div className="wrap_progressbar">
                            <ul className="progressbar_list progress_step02">
                                <li className="progressbar_item progress_item01"><p>희망직무 선택</p></li>
                                <li className="progressbar_item progress_item02"><p>진행방식 선택</p></li>
                                <li className="progressbar_item progress_item03"><p>이력서 작성</p></li>
                            </ul>
                        </div>
                        <h2 className="page_title">이력서 등록 진행방식을 선택해 주세요.</h2>

                        <div className="page_inner">

                            <div className="area_box_way">

                                <div className="wrap_box_way">
                                    <div className="box_way_txt">
                                        <p className="badge badge_way">기존방식</p>
                                        <h3>작성형</h3>
                                        <p> 작성양식에 맞춰<br />
                                            가입하는 방식입니다.</p>
                                    </div>
                                    <Link to={`/user/resume/ResumeForm/${resumeNo}`} target="_self" className="btn_submit lined_green">시작하기</Link>
                                </div>

                                <div className="wrap_box_way">
                                    <div className="box_way_txt">
                                        <p className="badge badge_way">챗봇방식</p>
                                        <h3>1:1 대화형</h3>
                                        <p>챗봇과 1:1 대화형식으로<br />
                                            이력서작성을 진행합니다.</p>
                                    </div>
                                    <Link to={`/user/resume/InterResume/${resumeNo}`} target="_self" className="btn_submit lined_green">시작하기</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Process;