import api from "../../api";

export async function countId(id) {
  const response = await api.get("/api/signup/countId", {
    params: {
      id: id,
    },
  });

  return response;
}

export async function countPhoneNo(num) {
  const phoneNo = num.replaceAll("-", "");

  const response = await api.get("/api/signup/countPhoneNo", {
    params: {
      phoneNo: phoneNo,
    },
  });

  return response;
}

export async function sendPhone(num) {
  const phoneNum = num.replaceAll("-", "");

  const response = await api.get("/api/sms/send", {
    params: {
      phoneNum: phoneNum,
    },
  });

  return response;
}

export async function readOcr(image) {
  const response = await api.post("/api/ocr/upload", image);

  return response;
}

export async function countCertification(dis_reg_cer_seq) {
  const response = await api.get("/api/signup/countCertification", {
    params: {
      disRegCerSeq: dis_reg_cer_seq,
    },
  });

  return response;
}

export async function createUser(data) {
  const response = await api.post("/api/signup/createUser", data);

  return response;
}

export async function uploadImage(formData) {
  const response = await api.post(`/api/signup/uploadImage`, formData);

  return response;
}

export async function updatePw(memberPw, changePw) {
  const response = await api.put("/api/signup/updatePw", null, {
    params: {
      memberPw: memberPw,
      changePw: changePw,
    },
  });

  return response;
}

export async function updateUser(data) {
  const response = await api.put("/api/signup/updateUser", data);

  return response;
}

export async function updateWithdrawal(disRegCerSeq) {
  const response = await api.put("/api/signup/updateWithdrawal", null, {
    params: {
      disRegCerSeq: disRegCerSeq,
    },
  });

  return response;
}

export async function deleteId(id, disRegCerSeq) {
  const response = await api.delete("/api/signup/deleteId", {
    params: {
      id: id,
      disRegCerSeq: disRegCerSeq,
    },
  });

  return response;
}

export async function synonym(input) {
  const response = api.get("/api/signup/synonym", {
    params: {
      input: input,
    },
  });

  return response;
}
