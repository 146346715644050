import React, {useState} from "react";

const VeteransAffairs = ({register,errors,watchField, readOnly}) => {

    return (
        <div className="wrap_signup_input wrap_signup_basic">
            <div className="box_sub_title">
                <h4 className="sub_title_signup">보훈사항</h4>
            </div>
            <div className="box_privilege_info history_privilege">
                <div className="box_division">
                    <div className="wrap_box_input">
                        <div className="box_input">
                            <label htmlFor="" className="asterisk">보훈대상</label>
                            <select readOnly={readOnly} disabled={readOnly} name="veteran.has_veterans_affair" id="veteran.has_veterans_affair" className="select"
                                    {...register('veteran.has_veterans_affair', {
                                required: '필수 입력 항목입니다.'
                            })}>
                                <option value="" hidden={true}>보훈대상을 선택해주세요.</option>
                                <option value="대상">대상</option>
                                <option value="비대상">비대상</option>
                            </select>
                        </div>
                        <p className="msg_err">{errors?.veteran?.has_veterans_affair?.message}</p>
                    </div>
                    {watchField.veteran.has_veterans_affair === '대상' && (
                        <>
                    <div className="wrap_box_input">
                        <div className="box_input">
                            <label htmlFor="" className="asterisk">대상 사유</label>
                            <input type="text" readOnly={readOnly} disabled={readOnly} id="veteran.veterans_affair_reason" name="veteran.veterans_affair_reason" placeholder="보훈 사유를 입력해주세요."
                                   {...register('veteran.veterans_affair_reason', {
                                       required: '필수 입력 항목입니다.'
                                   })}></input>
                        </div>
                        <p className="msg_err">{ errors?.veteran?.veterans_affair_reason?.message}</p>
                    </div>
                            </>

                        )}
                    {watchField.veteran.has_veterans_affair !== '대상' && (
                        <>
                            <div className="wrap_box_input">

                            </div>
                        </>

                    )}
                </div>
            </div>
        </div>
    )

}
export default VeteransAffairs;