export const scenarioType = {
  GREETING: "greeting",
  BEFORE_RESUME: "before_resume",
  NO_RESUME: "no_resume",
  REGISTED_RESUEM_GREETING: "registed_resume_greeting",
  INSERT_NAME: "insert_name",
  INSERT_PHONENUM: "insert_phonenum",
  INSERT_BIRTHDAY: "insert_birthday",
  INSERT_EMAIL: "insert_email",
  ERROR_EMAIL: "error_email",

  // 고등학교 입력
  INSERT_HIGH: "education.high_school.admission_status",
  INSERT_HIGH_NAME: "education.high_school.school_name",
  INSERT_HIGH_AFF: "education.high_school.affiliation",
  INSERT_HIGH_MAJOR: "education.high_school.major",
  INSERT_HIGH_ADMMISION_DATE: "education.high_school.admission_date",
  INSERT_HIGH_GRADUATE_STATUS: "education.high_school.graduate_status",
  INSERT_HIGH_GRADUATE_DATE: "education.high_school.graduate_year",
  INSERT_HIGH_PASS_DATE: "insert.high_pass.date", // 검정고시
  EDIT_HIGH: "edit_high",
  EDIT_HIGH_DETAIL: "edit_high_detail",

  // 대학(10문항))
  ADD_UNIV_SCHOOL: "add_univ",
  ADD_MORE_UNIV_SCHOOL: "add_more_univ_school",
  INSERT_UNIV_ADDMISION_STATUS: "education.university.admission_status",
  INSERT_UNIV_GUBUN: "education.university.gubun",
  INSERT_UNIV_NAME: "education.university.school_name",
  INSERT_UNIV_ADDMISION_DATE: "education.university.admission_date",
  INSERT_UNIV_GRADUATE_STATUS: "education.university.graduate_status",
  INSERT_UNIV_GRADUATE_YEAR: "education.university.graduate_year",
  INSERT_UNIV_MAJOR: "education.university.major",
  INSERT_UNIV_AFFILIATION: "education.university.affiliation",
  INSERT_UNIV_GRADE: "education.university.grade",
  INSERT_UNIV_TOTAL_GRADE: "education.university.total_grade",

  // 전공
  ADD_MAJOR: "add_major",
  INSERT_MAJOR_STATUS: "education.university.add_major.major_status",
  INSERT_MAJOR_NAME: "education.university.add_major.major_name",
  INSERT_MAJOR_AFFILIATION: "education.university.add_major.major_affiliation",

  EDIT_UNIV: "edit_univ",
  EDIT_UNIV_DETAIL: "edit_univ_detail",

  // 대학원
  ADD_GRAD: "add_grad",
  ADD_MORE_GRAD: "add_more_grad",
  INSERT_GRAD_STATUS: "education.graduate.admission_status",
  INSERT_GRAD_NAME: "education.graduate.school_name",
  INSERT_GRAD_MAJOR: "education.graduate.major",
  INSERT_GRAD_AFFILIATION: "education.graduate.affiliation",
  INSERT_GRAD_ADMISSION_DATE: "education.graduate.admission_date",
  INSERT_GRAD_DEGREE: "education.graduate.degree",
  INSERT_GRAD_GRADUATE_STATUS: "education.graduate.graduate_status",
  INSERT_GRAD_GRADUATE_YEAR: "education.graduate.graduate_year",
  INSERT_GRAD_GRADE: "education.graduate.grade",
  INSERT_GRAD_TOTAL_GRADE: "education.graduate.total_grade",
  EDIT_GRAD: "edit_grad",
  EDIT_GRAD_DETAIL: "edit_grad_detail",
  APPEND_GRAD: "append_grad",

  // 경력
  ADD_CAREER: "add_career",
  INSERT_COMPANY_NAME: "career.company_name",
  INSERT_DEPARTMENT: "career.department",
  INSERT_DUTY: "career.duty",
  INSERT_HIRED_DATE: "career.hired_date",
  INSERT_EMPLOYMENT_STATUS: "career.employment_status",
  INSERT_RESIGNATION_DATE: "career.resignation_date",
  INSERT_SALARY: "career.salary",
  EDIT_CAREER: "edit_career",
  EDIT_CAREER_DETAIL: "edit_career_detail",
  APPEND_CAREER: "append_career",

  // 컴활
  ADD_COMSKILL: "comskill",
  INSERT_WORD: "comskill.word_ability",
  INSERT_EXCEL: "comskill.excel_ability",
  INSERT_PPT: "comskill.ppt_ability",
  EDIT_COMSKILL: "edit_comskill",
  EDIT_COMSKILL_DETAIL: "edit_comskill_detail",

  // 자격증
  ADD_CERT: "add_cert",
  INSERT_CERTIFICATE_GUBUN: "certificate.certificate_gubun",
  INSERT_CERTIFICATE_LEVEL: "certificate.certificate_level",
  INSERT_CERTIFICATE_NAME: "certificate.certificate_name",
  INSERT_CERTIFICATE_AUTHORITY: "certificate.certificate_authority",
  INSERT_CERTIFICATE_DATE: "certificate.certificate_acquisition_date",
  EDIT_CERT: "edit_cert",
  EDIT_CERT_DETAIL: "edit_cert_detail",
  APPEND_CERT: "append_cert",

  // 수상내역
  ADD_AWARD: "add_award",
  INSERT_PRIZE_NAME: "prize.award_name",
  INSERT_PRIZE_AUTHORITY: "prize.award_authority",
  INSERT_PRIZE_CREATE_AT: "prize.award_date",
  EDIT_AWARD: "edit_award",
  EDIT_AWARD_DETAIL: "edit_award_detail",
  APPEND_AWARD: "append_award",

  // 어학
  ADD_LANG: "add_lang",
  INSERT_LANG_TEST_NAME: "language.language_test_name",
  INSERT_LANGUAGE_NAME: "language.language_name",
  INSERT_LANGUAGE_SCORE: "language.language_score",
  INSERT_LANG_DATE: "language.language_acquisition_date",
  EDIT_LANG: "edit_lang",
  EDIT_LANG_DETAIL: "edit_lang_detail",
  APPEND_LANG: "append_lang",

  // 병역 사항
  ADD_MILITARY_DUTY: "military.military_target",
  ADD_MILITARY_ENDATE: "military.military_start_date",
  ADD_MILITARY_DISDATE: "military.military_end_date",
  ADD_MILITARY_TYPE: "military.military_category",
  ADD_MILITARY_RANK: "military.military_discharged_rank",
  ADD_MILITARY_REASON: "military.discharge_reason",
  EDIT_MILITARY: "edit_military",
  EDIT_MILITARY_DETAIL: "edit_military_detail",

  //보훈사항
  ADD_VETERAN: "veteran.has_veterans_affair",
  ADD_VETERAN_REASON: "veteran.veterans_affair_reason",
  EDIT_VETERAN: "edit_veteran",
  EDIT_VETERAN_DETAIL: "edit_veteran_detail",

  //자기소개서
  ADD_SELF_TITLE: "introduction.introduction_title",
  ADD_SELF_CONTENT: "introduction.introduction_content",
  //
  END: "end",
};

function createButtonsWithAction(scenarioType) {
  return {
    type: "button",
    buttons: [
      {
        message: "공과",
        action: scenarioType,
      },
      {
        message: "인문대학",
        action: scenarioType,
      },
      {
        message: "사회과학",
        action: scenarioType,
      },
      {
        message: "자연과학",
        action: scenarioType,
      },
      {
        message: "생활과학",
        action: scenarioType,
      },
      {
        message: "경영",
        action: scenarioType,
      },
      {
        message: "경제",
        action: scenarioType,
      },
      {
        message: "금융",
        action: scenarioType,
      },
      {
        message: "예술",
        action: scenarioType,
      },
      {
        message: "체육",
        action: scenarioType,
      },
      {
        message: "사범",
        action: scenarioType,
      },
      {
        message: "국제",
        action: scenarioType,
      },
      {
        message: "의과",
        action: scenarioType,
      },
      {
        message: "간호학",
        action: scenarioType,
      },
      {
        message: "기타",
        action: scenarioType,
      },
    ],
  };
}

const scenarioGreeting = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "안녕하세요 드리미에요,\n" + "이력서 작성을 도와드릴게요.",
    },
    // {
    //     "type": "text",
    //     //"message": "먼저, 이미 작성하신 브이드림 이력서가 있으시다면 첨부해주세요.\n"
    //     "message": "먼저, 이미 작성하신 이력서가 있으시다면 첨부해주세요.\n"
    //     //이력서 첨부버튼 추가
    // },
    // {
    //     "type": "file"
    // },
    {
      type: "button",
      buttons: [
        {
          message: "이력서 작성하기",
          action: scenarioType.NO_RESUME,
        },
      ],
    },
  ],
};

const scenariobeforeResume = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "이력서 작성을 도와드릴게요.",
    },
    {
      type: "text",
      //"message": "먼저, 이미 작성하신 브이드림 이력서가 있으시다면 첨부해주세요.\n"
      message: "먼저, 이미 작성하신 이력서가 있으시다면 첨부해주세요.\n",
      //이력서 첨부버튼 추가
    },
    {
      type: "file",
    },
    {
      type: "button",
      buttons: [
        {
          message: "이력서가 없습니다.",
          action: scenarioType.NO_RESUME,
        },
      ],
    },
  ],
};

const scenarioResume = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "이력서 초안이 생성되었습니다.\n" +
        "이력서를 클릭해서 내용을 확인해보세요.",
    },
    {
      type: "resume",
    },
  ],
};

const scenarioRegistedResumeGreeting = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "이력서 초안이 생성되었습니다.\n" +
        "이력서를 클릭해서 내용을 확인해보세요.",
    },
    {
      type: "resume",
    },
  ],
};

//===============================기본정보
const scenarioInsertName = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "이름을 말씀해주세요.",
    },
  ],
};

const scenarioInsertPhoneNum = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "전화번호를 말씀해주세요.",
    },
  ],
};

const scenarioInsertBirthDate = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "생년월일을 말씀해주세요.",
    },
  ],
};

const scenarioInsertEmail = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "E-mail을 말씀해주세요.",
    },
  ],
};

const scenarioErrorEmail = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "E-mail 형식을 갖춰서 말씀해주세요.",
    },
  ],
};

//===============================학력사항

const scenarioInsertHigh = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "고등학교 정보 입력 받겠습니다.",
    },
    {
      type: "text",
      message: "고등학교 입학 상태를 말씀해주세요.\n" + "입학/편입/검정고시",
    },
    {
      type: "button",
      buttons: [
        {
          message: "입학",
          action: scenarioType.INSERT_HIGH_NAME,
        },
        {
          message: "편입",
          action: scenarioType.INSERT_HIGH_NAME,
        },
        {
          message: "검정고시",
          action: scenarioType.INSERT_HIGH_PASS_DATE,
        },
      ],
    },
  ],
};

const scenarioInsertHighSchoolName = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "고등학교 이름을 말씀해주세요.",
    },
  ],
};
const scenarioInsertHighAffiliation = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "고등학교 계열을 말씀해주세요.\n" +
        "이과/문과/전문(실업)계/예체능계/특성화/마이스터고/특수목적고",
    },
    {
      type: "button",
      buttons: [
        {
          message: "이과",
          action: scenarioType.INSERT_HIGH_ADMMISION_DATE,
        },
        {
          message: "문과",
          action: scenarioType.INSERT_HIGH_ADMMISION_DATE,
        },
        {
          message: "전문(실업)계",
          action: scenarioType.INSERT_HIGH_ADMMISION_DATE,
        },
        {
          message: "예체능계",
          action: scenarioType.INSERT_HIGH_ADMMISION_DATE,
        },
        {
          message: "특성화/마이스터고",
          action: scenarioType.INSERT_HIGH_MAJOR,
        },
        {
          message: "특수목적고",
          action: scenarioType.INSERT_HIGH_ADMMISION_DATE,
        },
      ],
    },
  ],
};

// 특성화/마이스터고만 입력받음
const scenarioInsertHighMajor = {
  role: "bot",
  content: [
    {
      type: "autoComplete",
      message: "고등학교 전공명을 말씀해주세요.",
    },
  ],
};

const scenarioInsertHighAdmissionDate = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "고등학교 입학 날짜를 말씀해주세요.\n" + "YYYY-MM",
    },
  ],
};

const scenarioInsertHighGraduateStatus = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "고등학교 졸업상태를 말씀해주세요.\n" + "졸업/졸업예정/재학중/중퇴",
    },
    {
      type: "button",
      buttons: [
        {
          message: "졸업",
          action: scenarioType.INSERT_HIGH_GRADUATE_DATE,
        },
        {
          message: "졸업예정",
          action: scenarioType.EDIT_HIGH,
        },
        {
          message: "재학중",
          action: scenarioType.EDIT_HIGH,
        },
        {
          message: "중퇴",
          action: scenarioType.EDIT_HIGH,
        },
      ],
    },
  ],
};

const scenarioInsertHighGraduateDate = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "고등학교 졸업 날짜를 말씀해주세요.\n" + "YYYY-MM",
    },
  ],
};

const scenarioInsertHighPassDate = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "검정고시 합격 날짜를 말씀해주세요.\n" + "YYYY-MM",
    },
  ],
};

const scenarioEditHigh = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "학력정보를 수정하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.EDIT_HIGH_DETAIL,
          status: "edit_high",
        },
        {
          message: "아니오",
          action: scenarioType.ADD_UNIV_SCHOOL,
        },
      ],
    },
  ],
};

const scenarioEditHighDetail = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수정할 정보를 상세히 말씀해주세요",
    },
    {
      type: "button",
      buttons: [
        {
          message: "입학상태",
          action: scenarioType.INSERT_HIGH,
        },
        {
          message: "학교명",
          action: scenarioType.INSERT_HIGH_NAME,
        },
        {
          message: "계열",
          action: scenarioType.INSERT_HIGH_AFF,
        },
        {
          message: "입학년월",
          action: scenarioType.INSERT_HIGH_ADMMISION_DATE,
        },
        {
          message: "졸업상태",
          action: scenarioType.INSERT_HIGH_GRADUATE_STATUS,
        },
        {
          message: "졸업년월",
          action: scenarioType.INSERT_UNIV_ADDMISION_STATUS,
        },
      ],
    },
  ],
};

//=====================================대학
const scenarioAddUnivSchool = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "고등학교 입력이 마무리되었습니다\n" +
        "학력사항에 대학교를 추가하시겠습니까",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.INSERT_UNIV_ADDMISION_STATUS,
        },
        {
          message: "아니오",
          action: scenarioType.ADD_CAREER,
        },
      ],
    },
  ],
};

const scenarioInsertUnivAdmissionStatus = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학교 입학정보를 말씀해주세요. 입학/편입",
    },
    {
      type: "button",
      buttons: [
        {
          message: "입학",
          action: scenarioType.INSERT_UNIV_GUBUN,
        },
        {
          message: "편입",
          action: scenarioType.INSERT_UNIV_GUBUN,
        },
      ],
    },
  ],
};

const scenarioInsertUnivGubun = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "대학교 구분을 말씀해주세요.\n" +
        "일반(4년제)/전문대(3년제)/전문대(2년제)",
    },
    {
      type: "button",
      buttons: [
        {
          message: "일반(4년제)",
          action: scenarioType.INSERT_UNIV_NAME,
        },
        {
          message: "전문대(3년제)",
          action: scenarioType.INSERT_UNIV_NAME,
        },
        {
          message: "전문대(2년제)",
          action: scenarioType.INSERT_UNIV_NAME,
        },
      ],
    },
  ],
};
const scenarioInsertUnivName = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학교 이름을 말씀해주세요.",
    },
  ],
};

const scenarioInsertUnivAdmissionDate = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학교 입학 날짜를 말씀해주세요.\n" + "YYYY-MM",
    },
  ],
};

const scenarioInsertUnivGraduateStatus = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "대학교 졸업 상태를 말씀해주세요.\n" +
        "졸업/졸업예정/재학중/휴학중/수료/중퇴",
    },
    {
      type: "button",
      buttons: [
        {
          message: "졸업",
          action: scenarioType.INSERT_UNIV_GRADUATE_YEAR,
        },
        {
          message: "졸업예정",
          action: scenarioType.INSERT_UNIV_MAJOR,
        },
        {
          message: "재학중",
          action: scenarioType.INSERT_UNIV_MAJOR,
        },
        {
          message: "휴학중",
          action: scenarioType.INSERT_UNIV_MAJOR,
        },
        {
          message: "수료",
          action: scenarioType.INSERT_UNIV_GRADUATE_YEAR,
        },
        {
          message: "중퇴",
          action: scenarioType.INSERT_UNIV_MAJOR,
        },
      ],
    },
  ],
};

const scenarioInsertUnivGraduateYear = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학교 졸업 날짜를 말씀해주세요.\n" + "YYYY-MM",
    },
  ],
};

const scenarioInsertUnivMajor = {
  role: "bot",
  content: [
    {
      type: "autoComplete",
      message: "대학교 전공명을 말씀해주세요.",
    },
  ],
};

const scenarioInsertUnivAffiliation = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "대학교 전공계열을 말씀해주세요.\n" +
        "공과/인문대학/사회과학/자연과학/생활과학/경영/경제/금융/예술/체육/사범/국제/의과/간호학/기타",
    },
    createButtonsWithAction(scenarioType.INSERT_UNIV_GRADE),
  ],
};

const scenarioInsertUnivGrade = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학교 학점을 말씀해주세요",
    },
  ],
};

const scenarioInsertUnivTotalGrade = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학교 학점 총점을 말씀해주세요.\n" + "4.5/4.3/4.0/100",
    },
    {
      type: "button",
      buttons: [
        {
          message: "4.5",
          action: scenarioType.ADD_MAJOR,
        },
        {
          message: "4.3",
          action: scenarioType.ADD_MAJOR,
        },
        {
          message: "4.0",
          action: scenarioType.ADD_MAJOR,
        },
        {
          message: "100",
          action: scenarioType.ADD_MAJOR,
        },
      ],
    },
  ],
};

//=====================================전공정보
const scenarioAddMajor = {
  role: "bot",
  status: "add",
  content: [
    {
      type: "text",
      message: "전공정보를 추가하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          status: "add",
          message: "네",
          action: scenarioType.INSERT_MAJOR_STATUS,
        },
        { status: "add", message: "아니오", action: scenarioType.EDIT_UNIV },
      ],
    },
  ],
};
const scenarioInsertMajorStatus = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "전공상태를 말씀해주세요. \n" + "부전공/복수전공/이중전공",
    },
    {
      type: "button",
      buttons: [
        {
          message: "부전공",
          action: scenarioType.INSERT_MAJOR_NAME,
        },
        {
          message: "복수전공",
          action: scenarioType.INSERT_MAJOR_NAME,
        },
        {
          message: "이중전공",
          action: scenarioType.INSERT_MAJOR_NAME,
        },
      ],
    },
  ],
};
const scenarioInsertMajorName = {
  role: "bot",
  content: [
    {
      type: "autoComplete",
      message: "대학교 전공명을 말씀해주세요",
    },
  ],
};

const scenarioInsertMajorAffiliation = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "대학교 전공계열을 말씀해주세요 \n" +
        "공과/인문대학/사회과학/자연과학/생활과학/경영/경제/금융/예술/체육/사범/국제/의과/간호학/기타",
    },
    createButtonsWithAction(scenarioType.ADD_MAJOR),
  ],
};

const scenarioEditUniv = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "학력정보를 수정하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.EDIT_UNIV_DETAIL,
        },
        {
          message: "아니오",
          action: scenarioType.ADD_MORE_UNIV_SCHOOL,
        },
      ],
    },
  ],
};

const scenarioAddMoreUnivSchool = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "대학교 입력이 마무리되었습니다\n" +
        "학력사항에 대학교를 추가로 입력하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.INSERT_UNIV_ADDMISION_STATUS,
        },
        {
          message: "아니오",
          action: scenarioType.ADD_GRAD,
        },
      ],
    },
  ],
};

const scenarioEditUnivDetail = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수정할 정보를 상세히 말씀해주세요",
    },
    {
      type: "button",
      buttons: [
        {
          message: "입학상태",
          action: scenarioType.INSERT_UNIV_ADDMISION_STATUS,
        },
        {
          message: "학교구분",
          action: scenarioType.INSERT_UNIV_GUBUN,
        },
        {
          message: "학교명",
          action: scenarioType.INSERT_UNIV_NAME,
        },
        {
          message: "입학년월",
          action: scenarioType.INSERT_UNIV_ADDMISION_DATE,
        },
        {
          message: "졸업상태",
          action: scenarioType.INSERT_UNIV_GRADUATE_STATUS,
        },
        {
          message: "졸업년월",
          action: scenarioType.INSERT_UNIV_GRADUATE_YEAR,
        },
        {
          message: "전공",
          action: scenarioType.INSERT_UNIV_MAJOR,
        },
        {
          message: "계열",
          action: scenarioType.INSERT_UNIV_AFFILIATION,
        },
        {
          message: "학점",
          action: scenarioType.INSERT_UNIV_GRADE,
        },
        {
          message: "총점",
          action: scenarioType.INSERT_UNIV_TOTAL_GRADE,
        },
      ],
    },
  ],
};

//=====================================대학원
const scenarioAddGrad = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학원 정보를 추가하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.INSERT_GRAD_STATUS,
        },
        {
          message: "아니오",
          action: scenarioType.ADD_CAREER,
        },
      ],
    },
  ],
};

const scenarioInsertGradstatus = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학원 정보 입력 받겠습니다. 입학/편입",
    },
    {
      type: "button",
      buttons: [
        {
          message: "입학",
          action: scenarioType.INSERT_GRAD_NAME,
        },
        {
          message: "편입",
          action: scenarioType.INSERT_GRAD_NAME,
        },
      ],
    },
  ],
};

const scenarioInsertGradName = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학원 이름을 말씀해주세요",
    },
  ],
};
const scenarioInsertGradMajor = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학원 전공명을 말씀해주세요.",
    },
  ],
};

const scenarioInsertGradAffiliation = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "대학원 전공계열을 말씀해주세요.\n" +
        "공과/인문대학/사회과학/자연과학/생활과학/경영/경제/금융/예술/체육/사범/국제/의과/간호학/기타",
    },
    createButtonsWithAction(scenarioType.INSERT_GRAD_ADMISSION_DATE),
  ],
};

const scenarioInsertGradAdmissionDate = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학원 입학 날짜를 말씀해주세요.\n" + "YYYY-MM",
    },
  ],
};
const scenarioInsertGradDegree = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학원 학위를 말씀해주세요\n" + "석사/박사/석박사",
    },
    {
      type: "button",
      buttons: [
        {
          message: "석사",
          action: scenarioType.INSERT_GRAD_GRADUATE_STATUS,
        },
        {
          message: "박사",
          action: scenarioType.INSERT_GRAD_GRADUATE_STATUS,
        },
        {
          message: "석박사",
          action: scenarioType.INSERT_GRAD_GRADUATE_STATUS,
        },
      ],
    },
  ],
};

const scenarioInsertGradGraduateStatus = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "대학원 졸업 상태를 말씀해주세요.\n" +
        "졸업/졸업예정/재학중/휴학중/수료/중퇴",
    },
    {
      type: "button",
      buttons: [
        {
          message: "졸업",
          action: scenarioType.INSERT_GRAD_GRADUATE_YEAR,
        },
        {
          message: "졸업예정",
          action: scenarioType.INSERT_GRAD_GRADE,
        },
        {
          message: "재학중",
          action: scenarioType.INSERT_GRAD_GRADE,
        },
        {
          message: "휴학중",
          action: scenarioType.INSERT_GRAD_GRADE,
        },
        {
          message: "수료",
          action: scenarioType.INSERT_GRAD_GRADUATE_YEAR,
        },
        {
          message: "중퇴",
          action: scenarioType.INSERT_GRAD_GRADE,
        },
      ],
    },
  ],
};
const scenarioInsertGradGraduateYear = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학원 졸업 날짜를 말씀해주세요.\n" + "YYYY-MM",
    },
  ],
};

const scenarioInsertGradGrade = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학원 학점을 말씀해주세요.",
    },
  ],
};

const scenarioInsertGradTotalGrade = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대학원 학점 총점을 말씀해주세요.\n" + "4.5/4.3/4.0/100",
    },
    {
      type: "button",
      buttons: [
        {
          message: "4.5",
          action: scenarioType.EDIT_GRAD,
        },
        {
          message: "4.3",
          action: scenarioType.EDIT_GRAD,
        },
        {
          message: "4.0",
          action: scenarioType.EDIT_GRAD,
        },
        {
          message: "100",
          action: scenarioType.EDIT_GRAD,
        },
      ],
    },
  ],
};

const scenarioEditGrad = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "학력정보를 수정하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.EDIT_GRAD_DETAIL,
        },
        {
          message: "아니오",
          action: scenarioType.ADD_MORE_GRAD,
        },
      ],
    },
  ],
};

const scenarioAddMoreGrad = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "대학원 입력이 마무리되었습니다\n" +
        "학력사항에 대학원을 추가로 입력하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.INSERT_GRAD_STATUS,
        },
        {
          message: "아니오",
          action: scenarioType.APPEND_GRAD,
        },
      ],
    },
  ],
};

const scenarioEditGradDetail = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수정할 정보를 상세히 말씀해주세요",
    },
    {
      type: "button",
      buttons: [
        {
          message: "입학상태",
          action: scenarioType.INSERT_GRAD_STATUS,
        },
        {
          message: "학교명",
          action: scenarioType.INSERT_GRAD_NAME,
        },
        {
          message: "전공명",
          action: scenarioType.INSERT_GRAD_MAJOR,
        },
        {
          message: "계열",
          action: scenarioType.INSERT_GRAD_AFFILIATION,
        },
        {
          message: "입학년월",
          action: scenarioType.INSERT_GRAD_ADMISSION_DATE,
        },
        {
          message: "학위",
          action: scenarioType.INSERT_GRAD_DEGREE,
        },
        {
          message: "졸업상태",
          action: scenarioType.INSERT_GRAD_GRADUATE_STATUS,
        },
        {
          message: "졸업년월",
          action: scenarioType.INSERT_GRAD_GRADUATE_YEAR,
        },
        {
          message: "학점",
          action: scenarioType.INSERT_GRAD_GRADE,
        },
        {
          message: "총점",
          action: scenarioType.INSERT_GRAD_TOTAL_GRADE,
        },
      ],
    },
  ],
};

const scenarioAppendGrad = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "학력정보를 추가하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          status: "add",
          message: "네",
          action: scenarioType.INSERT_GRAD_STATUS,
        },
        {
          status: "add",
          message: "아니오",
          action: scenarioType.ADD_CAREER,
        },
      ],
    },
  ],
};

//=====================================경력
const scenarioAddCareer = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "경력사항 정보 입력받겠습니다.",
    },
    {
      type: "button",
      buttons: [
        {
          status: "initialCareer",
          message: "신입",
          action: scenarioType.ADD_COMSKILL,
        },
        {
          message: "경력",
          action: scenarioType.INSERT_COMPANY_NAME,
        },
      ],
    },
  ],
};
const scenarioInsertCompanyName = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "회사 이름을 말씀해주세요",
    },
  ],
};
const scenarioInsertDepartment = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "부서/직급/직책 정보를 말씀해주세요",
    },
  ],
};
const scenarioInsertDuty = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "경력 직무명(세분류)을 말씀주세요",
    },
  ],
};
const scenarioCheckDuty = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "경력 직무명을 확정하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.INSERT_HIRED_DATE,
        },
        {
          message: "아니오",
          action: scenarioType.ADD_CAREER,
        },
      ],
    },
  ],
};
const scenarioInsertHiredDate = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "입사 날짜를 말씀해주세요.\n" + "YYYY-MM",
    },
  ],
};
const scenarioInsertEmploymentStatus = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "재직여부를 말씀해주세요.\n" + "재직중/퇴사",
    },
    {
      type: "button",
      buttons: [
        {
          message: "재직중",
          action: scenarioType.INSERT_SALARY,
        },
        {
          message: "퇴사",
          action: scenarioType.INSERT_RESIGNATION_DATE,
        },
      ],
    },
  ],
};
const scenarioInsertResignationDate = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "퇴사 날짜를 말씀해주세요.\n" + "YYYY-MM",
    },
  ],
};
const scenarioInsertSalary = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "연봉 정보를 말씀해주세요",
    },
  ],
};

const scenarioEditCareer = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "경력정보를 수정하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.EDIT_CAREER_DETAIL,
        },
        {
          message: "아니오",
          action: scenarioType.APPEND_CAREER,
        },
      ],
    },
  ],
};

const scenarioEditCareerDetail = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수정할 정보를 상세히 말씀해주세요",
    },
    {
      type: "button",
      buttons: [
        {
          message: "회사명",
          action: scenarioType.INSERT_COMPANY_NAME,
        },
        {
          message: "부서/직급/직책",
          action: scenarioType.INSERT_DEPARTMENT,
        },
        {
          message: "직무",
          action: scenarioType.INSERT_DUTY,
        },
        {
          message: "입사년월",
          action: scenarioType.INSERT_HIRED_DATE,
        },
        {
          message: "재직여부",
          action: scenarioType.INSERT_EMPLOYMENT_STATUS,
        },
        {
          message: "퇴사년월",
          action: scenarioType.INSERT_RESIGNATION_DATE,
        },
        {
          message: "연봉",
          action: scenarioType.INSERT_SALARY,
        },
      ],
    },
  ],
};

const scenarioAppendCareer = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "경력정보를 추가하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          status: "add",
          message: "네",
          action: scenarioType.INSERT_COMPANY_NAME,
        },
        {
          status: "add",
          message: "아니오",
          action: scenarioType.ADD_COMSKILL,
        },
      ],
    },
  ],
};

//=====================================컴활
const scenarioAddComskill = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "컴퓨터활용능력 입력 하시겠습니까?",
    },
    {
      type: "button",
      status: "add",
      buttons: [
        {
          message: "네",
          action: scenarioType.INSERT_WORD,
        },
        {
          message: "아니오",
          action: scenarioType.ADD_CERT,
        },
      ],
    },
  ],
};
const scenarioInsertWord = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "워드 정보 말씀해주세요\n" + "상/중/하",
    },
    {
      type: "button",
      buttons: [
        {
          message: "상",
          action: scenarioType.INSERT_EXCEL,
        },
        {
          message: "중",
          action: scenarioType.INSERT_EXCEL,
        },
        {
          message: "하",
          action: scenarioType.INSERT_EXCEL,
        },
      ],
    },
  ],
};
const scenarioInsertExcel = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "엑셀 정보 말씀해주세요\n" + "상/중/하",
    },
    {
      type: "button",
      buttons: [
        {
          message: "상",
          action: scenarioType.INSERT_PPT,
        },
        {
          message: "중",
          action: scenarioType.INSERT_PPT,
        },
        {
          message: "하",
          action: scenarioType.INSERT_PPT,
        },
      ],
    },
  ],
};
const scenarioInsertPPT = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "파워포인트 정보 말씀해주세요\n" + "상/중/하",
    },
    {
      type: "button",
      buttons: [
        {
          message: "상",
          action: scenarioType.EDIT_COMSKILL,
        },
        {
          message: "중",
          action: scenarioType.EDIT_COMSKILL,
        },
        {
          message: "하",
          action: scenarioType.EDIT_COMSKILL,
        },
      ],
    },
  ],
};

const scenarioEditComskill = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "컴퓨터활용능력 정보를 수정하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.EDIT_COMSKILL_DETAIL,
        },
        {
          message: "아니오",
          action: scenarioType.ADD_CERT,
        },
      ],
    },
  ],
};

const scenarioEditComskillDetail = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수정할 정보를 상세히 말씀해주세요",
    },
    {
      type: "button",
      buttons: [
        {
          message: "워드",
          action: scenarioType.INSERT_WORD,
        },
        {
          message: "엑셀",
          action: scenarioType.INSERT_EXCEL,
        },
        {
          message: "파워포인트",
          action: scenarioType.INSERT_PPT,
        },
      ],
    },
  ],
};

//=====================================자격증
const scenarioAddCertificate = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "자격증 정보를 입력하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.INSERT_CERTIFICATE_GUBUN,
        },
        {
          message: "아니오",
          action: scenarioType.ADD_AWARD,
        },
      ],
    },
  ],
};
const scenarioInsertCertificateGubun = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "자격증 구분 정보 말씀해주세요\n" +
        "국가기술자격증-기술사/국가기술자격증-기능장/국가기술자격증-기사/국가기술자격증-산업기사/국가기술자격증-기능사/국가자격증/공인민간자격증",
    },
    {
      type: "button",
      buttons: [
        {
          message: "국가기술자격증-기술사",
          action: scenarioType.INSERT_CERTIFICATE_LEVEL,
        },
        {
          message: "국가기술자격증-기능장",
          action: scenarioType.INSERT_CERTIFICATE_LEVEL,
        },
        {
          message: "국가기술자격증-기사",
          action: scenarioType.INSERT_CERTIFICATE_LEVEL,
        },
        {
          message: "국가기술자격증-산업기사",
          action: scenarioType.INSERT_CERTIFICATE_LEVEL,
        },
        {
          message: "국가기술자격증-기능사",
          action: scenarioType.INSERT_CERTIFICATE_LEVEL,
        },
        {
          message: "국가자격증",
          action: scenarioType.INSERT_CERTIFICATE_LEVEL,
        },
        {
          message: "공인민간자격증",
          action: scenarioType.INSERT_CERTIFICATE_LEVEL,
        },
      ],
    },
  ],
};
const scenarioInsertCertificateLevel = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "급수정보를 말씀해주세요",
    },
  ],
};
const scenarioInsertCertificateName = {
  role: "bot",
  content: [
    {
      type: "autoComplete",
      message: "자격증명을 말씀해주세요",
    },
  ],
};
const scenarioInsertCertificateAuthority = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "자격증 발행처 정보를 말씀해주세요",
    },
  ],
};
const scenarioInsertCertificateDate = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "자격증 취득 날짜를 말씀해주세요\n" + "YYYY-MM",
    },
  ],
};

const scenarioEditCert = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "자격증 정보를 수정하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.EDIT_CERT_DETAIL,
        },
        {
          message: "아니오",
          action: scenarioType.APPEND_CERT,
        },
      ],
    },
  ],
};

const scenarioEditCertDetail = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수정할 정보를 상세히 말씀해주세요",
    },
    {
      type: "button",
      buttons: [
        {
          message: "자격증 구분",
          action: scenarioType.INSERT_CERTIFICATE_GUBUN,
        },
        {
          message: "급수",
          action: scenarioType.INSERT_CERTIFICATE_LEVEL,
        },
        {
          message: "자격증명",
          action: scenarioType.INSERT_CERTIFICATE_NAME,
        },
        {
          message: "발행처",
          action: scenarioType.INSERT_CERTIFICATE_AUTHORITY,
        },
        {
          message: "취득일",
          action: scenarioType.INSERT_CERTIFICATE_DATE,
        },
      ],
    },
  ],
};
const scenarioAppendCertificate = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "자격증 정보를 추가하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          status: "add",
          message: "네",
          action: scenarioType.INSERT_CERTIFICATE_GUBUN,
        },
        {
          status: "add",
          message: "아니오",
          action: scenarioType.ADD_AWARD,
        },
      ],
    },
  ],
};

//=====================================수상내역
const scenarioAddPrize = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수상내역을 입력하시겠습니까",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.INSERT_PRIZE_NAME,
        },
        {
          message: "아니오",
          action: scenarioType.ADD_LANG,
        },
      ],
    },
  ],
};
const scenarioInsertPrizeName = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수상명을 말씀해주세요",
    },
  ],
};
const scenarioInsertPrizeAuthority = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수여기관명을 말씀해주세요",
    },
  ],
};
const scenarioInsertPrizeCreateAt = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수상 날짜를 말씀해주세요\n" + "YYYY-MM",
    },
  ],
};
const scenarioEditAward = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수상내역 정보를 수정하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.EDIT_AWARD_DETAIL,
        },
        {
          message: "아니오",
          action: scenarioType.APPEND_AWARD,
        },
      ],
    },
  ],
};

const scenarioEditAwardDetail = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수정할 정보를 상세히 말씀해주세요",
    },
    {
      type: "button",
      buttons: [
        {
          message: "수상명",
          action: scenarioType.INSERT_PRIZE_NAME,
        },
        {
          message: "수여기관",
          action: scenarioType.INSERT_PRIZE_AUTHORITY,
        },
        {
          message: "수상일",
          action: scenarioType.INSERT_PRIZE_CREATE_AT,
        },
      ],
    },
  ],
};
const scenarioAppendPrize = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수상내역을 추가하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          status: "add",
          message: "네",
          action: scenarioType.INSERT_PRIZE_NAME,
        },
        {
          status: "add",
          message: "아니오",
          action: scenarioType.ADD_LANG,
        },
      ],
    },
  ],
};

//=====================================어학정보
const scenarioAddLang = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "어학 정보를 입력하시겠습니까",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.INSERT_LANG_TEST_NAME,
        },
        {
          message: "아니오",
          action: scenarioType.ADD_MILITARY_DUTY,
        },
      ],
    },
  ],
};
const scenarioInsertLangTestName = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "어학시험명을 말씀해주세요",
    },
  ],
};

const scenarioInsertLanguageName = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "외국어명을 말씀해주세요",
    },
  ],
};
const scenarioInsertLanguageScore = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "급수/점수를 말씀해주세요",
    },
  ],
};
const scenarioInsertLangDate = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "취득일 날짜를 말씀해주세요 \n" + "YYYY-MM",
    },
  ],
};
const scenarioEditLang = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "어학 정보를 수정하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.EDIT_LANG_DETAIL,
        },
        {
          message: "아니오",
          action: scenarioType.APPEND_LANG,
        },
      ],
    },
  ],
};

const scenarioEditLangDetail = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수정할 정보를 상세히 말씀해주세요",
    },
    {
      type: "button",
      buttons: [
        {
          message: "어학시험명",
          action: scenarioType.INSERT_LANG_TEST_NAME,
        },
        {
          message: "외국어명",
          action: scenarioType.INSERT_LANGUAGE_NAME,
        },
        {
          message: "급수/점수",
          action: scenarioType.INSERT_LANGUAGE_SCORE,
        },
        {
          message: "취득일",
          action: scenarioType.INSERT_LANG_DATE,
        },
      ],
    },
  ],
};
const scenarioAppendLang = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "어학 정보를 추가하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          status: "add",
          message: "네",
          action: scenarioType.INSERT_LANG_TEST_NAME,
        },
        {
          status: "add",
          message: "아니오",
          action: scenarioType.ADD_MILITARY_DUTY,
        },
      ],
    },
  ],
};

//=====================================병역
const scenarioAddMilitaryDuty = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "병역사항을 입력받겠습니다.\n" + "대상아님/군필/미필/면제/복무중",
    },
    {
      type: "button",
      buttons: [
        {
          message: "대상아님",
          action: scenarioType.EDIT_MILITARY,
        },
        {
          message: "군필",
          action: scenarioType.ADD_MILITARY_ENDATE,
        },
        {
          message: "미필",
          action: scenarioType.EDIT_MILITARY,
        },
        {
          message: "면제",
          action: scenarioType.EDIT_MILITARY,
        },
        {
          message: "복무중",
          action: scenarioType.ADD_MILITARY_ENDATE,
        },
      ],
    },
  ],
};

const scenarioAddMilitaryEnDate = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "입대일을 말씀해주세요.\n" + "YYYY-MM",
    },
  ],
};

const scenarioAddMilitaryDisDate = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "제대일을 말씀해주세요.\n" + "YYYY-MM",
    },
  ],
};

const scenarioAddMilitaryType = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "군별을 입력받겠습니다.\n" +
        "육군/해군/공군/해병/의경/전경/기타/사회복무요원/의무소방",
    },
    // {
    //   type: "button",
    //   buttons: [
    //     {
    //       message: "육군",
    //       action: scenarioType.ADD_MILITARY_RANK,
    //     },
    //     {
    //       message: "해군",
    //       action: scenarioType.ADD_MILITARY_RANK,
    //     },
    //     {
    //       message: "공군",
    //       action: scenarioType.ADD_MILITARY_RANK,
    //     },
    //     {
    //       message: "해병",
    //       action: scenarioType.ADD_MILITARY_RANK,
    //     },
    //     {
    //       message: "의경",
    //       action: scenarioType.ADD_MILITARY_RANK,
    //     },
    //     {
    //       message: "전경",
    //       action: scenarioType.ADD_MILITARY_RANK,
    //     },
    //     {
    //       message: "기타",
    //       action: scenarioType.ADD_MILITARY_RANK,
    //     },
    //     {
    //       message: "사회복무요원",
    //       action: scenarioType.ADD_MILITARY_RANK,
    //     },
    //     {
    //       message: "의무소방",
    //       action: scenarioType.ADD_MILITARY_RANK,
    //     },
    //   ],
    // },
  ],
};

const scenarioAddMilitaryRank = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "제대 계급을 말씀해주세요.\n" +
        "이병/일병/상병/병장/하사/중사/상사/원사/준위/소위/중위/대위/소령/중령/대령/소장/중장/대장/기타",
    },
  ],
};

const scenarioAddMilitaryReason = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "전역 사유를 입력받겠습니다.\n" +
        "만기제대/의가사제대/의병제대/소집해제/불명예제대/상이제대",
    },
    {
      type: "button",
      buttons: [
        {
          message: "만기제대",
          action: scenarioType.EDIT_MILITARY,
        },
        {
          message: "의가사제대",
          action: scenarioType.EDIT_MILITARY,
        },
        {
          message: "의병제대",
          action: scenarioType.EDIT_MILITARY,
        },
        {
          message: "소집해제",
          action: scenarioType.EDIT_MILITARY,
        },
        {
          message: "불명예제대",
          action: scenarioType.EDIT_MILITARY,
        },
        {
          message: "상이제대",
          action: scenarioType.EDIT_MILITARY,
        },
      ],
    },
  ],
};

const scenarioEditMilitary = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "병역사항 정보를 수정하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.EDIT_MILITARY_DETAIL,
        },
        {
          message: "아니오",
          action: scenarioType.ADD_VETERAN,
        },
      ],
    },
  ],
};

const scenarioEditMilitaryDetail = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수정할 정보를 상세히 말씀해주세요",
    },
    {
      type: "button",
      buttons: [
        {
          message: "병역대상",
          action: scenarioType.ADD_MILITARY_DUTY,
        },
        {
          message: "입대일",
          action: scenarioType.ADD_MILITARY_ENDATE,
        },
        {
          message: "제대일",
          action: scenarioType.ADD_MILITARY_DISDATE,
        },
        {
          message: "군별",
          action: scenarioType.ADD_MILITARY_TYPE,
        },
        {
          message: "제대 계급",
          action: scenarioType.ADD_MILITARY_RANK,
        },
        {
          message: "전역 사유",
          action: scenarioType.ADD_MILITARY_REASON,
        },
      ],
    },
  ],
};

//=====================================보훈
const scenarioAddVeteran = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "보훈 대상이신가요?\n" + "대상/비대상",
    },
    {
      type: "button",
      buttons: [
        {
          message: "대상",
          action: scenarioType.ADD_VETERAN_REASON,
        },
        {
          message: "비대상",
          action: scenarioType.EDIT_VETERAN,
        },
      ],
    },
  ],
};

const scenarioAddVeteranReason = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "대상 사유를 말씀해주세요.",
    },
  ],
};

const scenarioEditVeteran = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "보훈사항 정보를 수정하시겠습니까?",
    },
    {
      type: "button",
      buttons: [
        {
          message: "네",
          action: scenarioType.EDIT_VETERAN_DETAIL,
        },
        {
          message: "아니오",
          action: scenarioType.ADD_SELF_TITLE,
        },
      ],
    },
  ],
};

const scenarioEditVeteranDetail = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "수정할 정보를 상세히 말씀해주세요",
    },
    {
      type: "button",
      buttons: [
        {
          message: "보훈대상",
          action: scenarioType.ADD_VETERAN,
        },
        {
          message: "대상사유",
          action: scenarioType.ADD_VETERAN_REASON,
        },
      ],
    },
  ],
};

//자기소개서

const scenarioAddSelfTitle = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "자기소개서 제목을 말씀해주세요.",
    },
  ],
};

const scenarioAddSelfContent = {
  role: "bot",
  content: [
    {
      type: "text",
      message: "자기소개서 내용을 말씀해주세요.",
    },
  ],
};

const scenarioEnd = {
  role: "bot",
  content: [
    {
      type: "text",
      message:
        "이력서 작성을 완료했습니다!\n" +
        "이력서 등록을 누르고\n" +
        "작성을 마무리해주세요 :)",
    },
  ],
};

const scenarioObject = {
  [scenarioType.GREETING]: scenarioGreeting,
  [scenarioType.BEFORE_RESUME]: scenariobeforeResume,
  [scenarioType.NO_RESUME]: scenarioResume,
  [scenarioType.REGISTED_RESUEM_GREETING]: scenarioRegistedResumeGreeting,
  [scenarioType.INSERT_NAME]: scenarioInsertName,
  [scenarioType.INSERT_PHONENUM]: scenarioInsertPhoneNum,
  [scenarioType.INSERT_BIRTHDAY]: scenarioInsertBirthDate,
  [scenarioType.INSERT_EMAIL]: scenarioInsertEmail,
  [scenarioType.ERROR_EMAIL]: scenarioErrorEmail,
  [scenarioType.INSERT_HIGH]: scenarioInsertHigh,
  [scenarioType.INSERT_HIGH_NAME]: scenarioInsertHighSchoolName,
  [scenarioType.INSERT_HIGH_AFF]: scenarioInsertHighAffiliation,
  [scenarioType.INSERT_HIGH_MAJOR]: scenarioInsertHighMajor,
  [scenarioType.INSERT_HIGH_ADMMISION_DATE]: scenarioInsertHighAdmissionDate,
  [scenarioType.INSERT_HIGH_GRADUATE_STATUS]: scenarioInsertHighGraduateStatus,
  [scenarioType.INSERT_HIGH_GRADUATE_DATE]: scenarioInsertHighGraduateDate,
  [scenarioType.INSERT_HIGH_PASS_DATE]: scenarioInsertHighPassDate,
  [scenarioType.EDIT_HIGH]: scenarioEditHigh,
  [scenarioType.EDIT_HIGH_DETAIL]: scenarioEditHighDetail,

  //대학
  [scenarioType.ADD_UNIV_SCHOOL]: scenarioAddUnivSchool,
  [scenarioType.ADD_MORE_UNIV_SCHOOL]: scenarioAddMoreUnivSchool,
  [scenarioType.INSERT_UNIV_ADDMISION_STATUS]:
    scenarioInsertUnivAdmissionStatus,
  [scenarioType.INSERT_UNIV_GUBUN]: scenarioInsertUnivGubun,
  [scenarioType.INSERT_UNIV_NAME]: scenarioInsertUnivName,
  [scenarioType.INSERT_UNIV_ADDMISION_DATE]: scenarioInsertUnivAdmissionDate,
  [scenarioType.INSERT_UNIV_GRADUATE_STATUS]: scenarioInsertUnivGraduateStatus,
  [scenarioType.INSERT_UNIV_GRADUATE_YEAR]: scenarioInsertUnivGraduateYear,
  [scenarioType.INSERT_UNIV_MAJOR]: scenarioInsertUnivMajor,
  [scenarioType.INSERT_UNIV_AFFILIATION]: scenarioInsertUnivAffiliation,
  [scenarioType.INSERT_UNIV_GRADE]: scenarioInsertUnivGrade,
  [scenarioType.INSERT_UNIV_TOTAL_GRADE]: scenarioInsertUnivTotalGrade,

  // 전공
  [scenarioType.ADD_MAJOR]: scenarioAddMajor,
  [scenarioType.INSERT_MAJOR_STATUS]: scenarioInsertMajorStatus,
  [scenarioType.INSERT_MAJOR_NAME]: scenarioInsertMajorName,
  [scenarioType.INSERT_MAJOR_AFFILIATION]: scenarioInsertMajorAffiliation,

  [scenarioType.EDIT_UNIV]: scenarioEditUniv,
  [scenarioType.EDIT_UNIV_DETAIL]: scenarioEditUnivDetail,

  //  대학원
  [scenarioType.ADD_GRAD]: scenarioAddGrad,
  [scenarioType.ADD_MORE_GRAD]: scenarioAddMoreGrad,
  [scenarioType.INSERT_GRAD_STATUS]: scenarioInsertGradstatus,
  [scenarioType.INSERT_GRAD_NAME]: scenarioInsertGradName,
  [scenarioType.INSERT_GRAD_MAJOR]: scenarioInsertGradMajor,
  [scenarioType.INSERT_GRAD_AFFILIATION]: scenarioInsertGradAffiliation,
  [scenarioType.INSERT_GRAD_ADMISSION_DATE]: scenarioInsertGradAdmissionDate,
  [scenarioType.INSERT_GRAD_DEGREE]: scenarioInsertGradDegree,
  [scenarioType.INSERT_GRAD_GRADUATE_STATUS]: scenarioInsertGradGraduateStatus,
  [scenarioType.INSERT_GRAD_GRADUATE_YEAR]: scenarioInsertGradGraduateYear,
  [scenarioType.INSERT_GRAD_GRADE]: scenarioInsertGradGrade,
  [scenarioType.INSERT_GRAD_TOTAL_GRADE]: scenarioInsertGradTotalGrade,
  [scenarioType.EDIT_GRAD]: scenarioEditGrad,
  [scenarioType.EDIT_GRAD_DETAIL]: scenarioEditGradDetail,
  [scenarioType.APPEND_GRAD]: scenarioAppendGrad,

  //경력
  [scenarioType.ADD_CAREER]: scenarioAddCareer,
  [scenarioType.INSERT_COMPANY_NAME]: scenarioInsertCompanyName,
  [scenarioType.INSERT_DEPARTMENT]: scenarioInsertDepartment,
  [scenarioType.INSERT_DUTY]: scenarioInsertDuty,
  [scenarioType.CHECK_DUTY]: scenarioCheckDuty,
  [scenarioType.INSERT_HIRED_DATE]: scenarioInsertHiredDate,
  [scenarioType.INSERT_EMPLOYMENT_STATUS]: scenarioInsertEmploymentStatus,
  [scenarioType.INSERT_RESIGNATION_DATE]: scenarioInsertResignationDate,
  [scenarioType.INSERT_SALARY]: scenarioInsertSalary,
  [scenarioType.EDIT_CAREER]: scenarioEditCareer,
  [scenarioType.EDIT_CAREER_DETAIL]: scenarioEditCareerDetail,
  [scenarioType.APPEND_CAREER]: scenarioAppendCareer,

  // 컴활
  [scenarioType.ADD_COMSKILL]: scenarioAddComskill,
  [scenarioType.INSERT_WORD]: scenarioInsertWord,
  [scenarioType.INSERT_EXCEL]: scenarioInsertExcel,
  [scenarioType.INSERT_PPT]: scenarioInsertPPT,
  [scenarioType.EDIT_COMSKILL]: scenarioEditComskill,
  [scenarioType.EDIT_COMSKILL_DETAIL]: scenarioEditComskillDetail,

  // 자격증
  [scenarioType.ADD_CERT]: scenarioAddCertificate,
  [scenarioType.INSERT_CERTIFICATE_GUBUN]: scenarioInsertCertificateGubun,
  [scenarioType.INSERT_CERTIFICATE_LEVEL]: scenarioInsertCertificateLevel,
  [scenarioType.INSERT_CERTIFICATE_NAME]: scenarioInsertCertificateName,
  [scenarioType.INSERT_CERTIFICATE_AUTHORITY]:
    scenarioInsertCertificateAuthority,
  [scenarioType.INSERT_CERTIFICATE_DATE]: scenarioInsertCertificateDate,
  [scenarioType.EDIT_CERT]: scenarioEditCert,
  [scenarioType.EDIT_CERT_DETAIL]: scenarioEditCertDetail,
  [scenarioType.APPEND_CERT]: scenarioAppendCertificate,

  // 수상내역
  [scenarioType.ADD_AWARD]: scenarioAddPrize,
  [scenarioType.INSERT_PRIZE_NAME]: scenarioInsertPrizeName,
  [scenarioType.INSERT_PRIZE_AUTHORITY]: scenarioInsertPrizeAuthority,
  [scenarioType.INSERT_PRIZE_CREATE_AT]: scenarioInsertPrizeCreateAt,
  [scenarioType.EDIT_AWARD]: scenarioEditAward,
  [scenarioType.EDIT_AWARD_DETAIL]: scenarioEditAwardDetail,
  [scenarioType.APPEND_AWARD]: scenarioAppendPrize,

  // 어학
  [scenarioType.ADD_LANG]: scenarioAddLang,
  [scenarioType.INSERT_LANG_TEST_NAME]: scenarioInsertLangTestName,
  [scenarioType.INSERT_LANGUAGE_NAME]: scenarioInsertLanguageName,
  [scenarioType.INSERT_LANGUAGE_SCORE]: scenarioInsertLanguageScore,
  [scenarioType.INSERT_LANG_DATE]: scenarioInsertLangDate,
  [scenarioType.EDIT_LANG]: scenarioEditLang,
  [scenarioType.EDIT_LANG_DETAIL]: scenarioEditLangDetail,
  [scenarioType.APPEND_LANG]: scenarioAppendLang,

  //병역 사항
  [scenarioType.ADD_MILITARY_DUTY]: scenarioAddMilitaryDuty,
  [scenarioType.ADD_MILITARY_ENDATE]: scenarioAddMilitaryEnDate,
  [scenarioType.ADD_MILITARY_DISDATE]: scenarioAddMilitaryDisDate,
  [scenarioType.ADD_MILITARY_TYPE]: scenarioAddMilitaryType,
  [scenarioType.ADD_MILITARY_RANK]: scenarioAddMilitaryRank,
  [scenarioType.ADD_MILITARY_REASON]: scenarioAddMilitaryReason,
  [scenarioType.EDIT_MILITARY]: scenarioEditMilitary,
  [scenarioType.EDIT_MILITARY_DETAIL]: scenarioEditMilitaryDetail,

  //보훈 사항
  [scenarioType.ADD_VETERAN]: scenarioAddVeteran,
  [scenarioType.ADD_VETERAN_REASON]: scenarioAddVeteranReason,
  [scenarioType.EDIT_VETERAN]: scenarioEditVeteran,
  [scenarioType.EDIT_VETERAN_DETAIL]: scenarioEditVeteranDetail,

  //자기소개서
  [scenarioType.ADD_SELF_TITLE]: scenarioAddSelfTitle,
  [scenarioType.ADD_SELF_CONTENT]: scenarioAddSelfContent,

  [scenarioType.END]: scenarioEnd,
};

export const scenario = Object.entries(scenarioObject).reduce(
  (acc, [key, value], index) => {
    return {
      ...acc,
      [key]: {
        ...value,
        id: key,
        index: index,
      },
    };
  },
  {}
);
