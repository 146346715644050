import React from 'react';
import api from '../../../api/api';
const ConfirmDateModal = (props) => {  
    const confirmDate = (e) => {
        e.preventDefault();
        let data ={            
            interview_seq : props.interviewSeq,
            select_confirm : props.confirmDate
        }
        api.post('/api/user/interview/updateSelectDate',data).then(response => response.data)
            .then((response) => {
                if(response && response.resultCode == 1000){
                } else {
                    //alert("일치하는 정보가 없습니다.");
                }
            }
        );

        window.location.href='/user/interview/InterviewMain';
    }
    const close_popup = (e) =>{
        e.preventDefault();
        props.setSelectDateModal(false)
        props.setConfirmDateModal(false)
    }
    return (
        <>
            <div className="popup_layer popup_small" id="popWarning03">
                <div className="popup_inner">          
                    <div className="popup_contents">
                        <p>희망 면접일정으로 최종 확정하겠습니까?</p>
                        <div className="popup_small_btn_wrap">
                            <a href="#" target="_self" className="btn_submit filled_black" onClick={close_popup}>취소</a>
                            <a href="#" target="_self" className="btn_submit filled_green" onClick={confirmDate}>확인</a>
                        </div>
                    </div>    
                </div>
            </div>
        </>
    )
};

export default ConfirmDateModal;