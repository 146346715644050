import { useEffect, useMemo, useState } from "react";
import Loading from "../common/Loading";
import { useGameTimer } from "../../../hooks/useGameTimer";
import { adjustArraySize } from "../../../utils/common";
import { INITIAL_COUNTDOWN_SECONDS } from "../games";

export default function PictureQuiz({
  image,
  defaultValue,
  list,
  onChange,
  isModalOpen,
  openModal,
  setPrevPageButtonProps,
  setNextPageButtonProps,
}) {
  const {
    pauseTimer,
    resumeTimer,
    isTimerFinished,
    startTimer,
    GameTimerComponent,
  } = useGameTimer({
    initialDurationInSeconds: INITIAL_COUNTDOWN_SECONDS,
    onTimerComplete: () => {
      setPrevPageButtonProps(() => {
        return { disabled: false, onClick: () => {} };
      });
      setNextPageButtonProps(() => {
        return {
          disabled: false,
          onClick: (nextPage) => nextPage(),
        };
      });
    },
  });

  const [answers, setAnswers] = useState(defaultValue || []);
  const [isLoading, setIsLoading] = useState(true);
  const isAnswered = useMemo(() => defaultValue !== null, []);

  useEffect(() => {
    if (isAnswered) {
      setPrevPageButtonProps(() => ({
        disabled: false,
        onClick: () => {},
      }));
      setNextPageButtonProps(() => ({
        disabled: false,
        onClick: (nextPage) => nextPage(),
      }));

      return;
    }

    startTimer();
    setPrevPageButtonProps(() => ({
      disabled: true,
      onClick: () => {},
    }));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    isModalOpen ? pauseTimer() : resumeTimer();
  }, [isModalOpen]);

  useEffect(() => {
    if (isTimerFinished) {
      onChange({ arrayScore: adjustArraySize(answers, list.length) });
    }
  }, [isTimerFinished]);

  useEffect(() => {
    if (answers.filter((answer) => answer).length === list.length) {
      setNextPageButtonProps(() => ({
        disabled: false,
        onClick: () =>
          onChange(
            {
              arrayScore: adjustArraySize(answers, list.length),
            },
            true
          ),
      }));
    } else {
      setNextPageButtonProps(() => ({
        disabled: false,
        onClick: () => {
          openModal({
            title: "알람",
            content: (
              <div>
                <p>미입력 답안이 있습니다.</p>
                <p>다음문제로 넘어가시겠습니까?</p>
              </div>
            ),
            buttons: [
              {
                label: "네",
                onClick: () => {
                  onChange(
                    {
                      arrayScore: adjustArraySize(answers, list.length),
                    },
                    true
                  );
                },
                className: "confirm-button",
              },
              {
                label: "아니오",
                className: "cancel-button",
              },
            ],
          });

          return false;
        },
      }));
    }
  }, [answers]);

  const handleAnswerChange = (evt, questionIndex) => {
    if (isAnswered) return;

    const inputValue = evt.currentTarget.value;

    if (inputValue === "") {
      setAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[questionIndex] = undefined;
        return updatedAnswers;
      });
      return;
    }

    const sanitizedValue = Math.max(0, parseInt(inputValue) || 0);
    const regex = /^[0-9]+$/;

    if (!regex.test(inputValue)) {
      evt.target.value = inputValue.replace(/[^0-9]/g, "");
      openModal({
        title: "알람",
        content: (
          <div>
            <p>
              오직 숫자만 입력 가능합니다.
              <br /> (한글, 영문, 특수문자 입력 불가)
            </p>
          </div>
        ),
        buttons: [
          {
            label: "확인",
            className: "confirm-button",
          },
        ],
      });
    } else {
      setAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[questionIndex] = sanitizedValue;
        return updatedAnswers;
      });
    }
  };

  return (
    <div className="quiz flex h-full">
      <Loading loading={isLoading} />
      <div className="quiz-wrapper">
        <GameTimerComponent isFinished={isTimerFinished || !!isAnswered} />
        <div className="quiz flex h-full">
          <div className="quiz-assets">
            <img className="picture-quiz-image" src={image} alt="quiz" />
          </div>
          <div className="quiz-answer flex">
            <h3>
              Q. 주어진 그림을 확인하여 다음 문제 4가지에 대한 답변을
              작성하시오.
            </h3>

            <table className="picture-quiz-table">
              <thead>
                <tr>
                  <th align="start">문제</th>
                  <th align="start">정답</th>
                </tr>
              </thead>

              <tbody>
                {list.map((question, index) => (
                  <tr key={index}>
                    <td align="start">{question}</td>
                    <td>
                      <input
                        disabled={isTimerFinished || !!isAnswered}
                        value={answers[index] || ""}
                        min="0"
                        onChange={(evt) => handleAnswerChange(evt, index)}
                        onKeyDown={(evt) => {
                          if (
                            [
                              "e",
                              "E",
                              "+",
                              "-",
                              ".",
                              "ArrowUp",
                              "ArrowDown",
                            ].includes(evt.key)
                          ) {
                            evt.preventDefault();
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
