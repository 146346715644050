import CompInfo from "./CompInfo";
import ManagerInfo from "./ManagerInfo";
import HolidayReg from "./HolidayReg";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useHistory, useLocation} from "react-router-dom";
import {getCompInfo, updateCompInfo, uploadImage} from "../../../api/Comp/CompSignup/CompSignup";
import ChangePwModal from "./ChangePwModal";
import ConfirmModal from "../../user/signup/confirmModal";
import BackModal from "../../user/signup/BackModal";
import ContractModal from "../../user/login/ContractModal";

const CompInfoManage = () => {
    const methods = useForm({
        mode: 'onChange'
    });
    const [modal, setModal] = useState(false);
    const [ment, setMent] = useState("수정한 정보를 저장하시겠습니까?");
    const [backModal, setBackModal] = useState(false);
    const [backModalMent, setBackModalMent] = useState("이전 페이지로 이동할 경우 지금까지 작성된 내용은 저장되지 않습니다.");
    const [link, setLink] = useState('/');
    const [isInfo, setIsInfo] = useState(true);
    const [changeModal, setChangeModal] = useState(false);
    const [compImage, setCompImage] = useState(null);
    const [bizImage, setBizImage] = useState(null);
    const [phoneChange,setPhoneChange] = useState(false);
    const [bizSeqCheck, setBizSeqCheck] = useState(null);
    const [isContract, setIsContract] = useState(false);
    const location = useLocation();
    const history = useHistory();
    let formData = new FormData();
    const [phone, setPhone] = useState({
        send : false,
        num : undefined,
        valid : false,
        pass : false
    })
    const [contractData, setContractData] = useState({
        contract_dt : "",
        contract_path : "",
        concent_path : ""
    })
    const { register, setValue, setError, reset, handleSubmit, formState: { errors, isValid } } = methods;

    const watchField = methods.watch();

    const comma_seperate = (value) => {
        let input = value.replaceAll(',',"");
        if(input != null && input != "" && !isNaN(input)){
            let l = input.length;

            let remain = l%3 == 0 ? 3 : l%3;
            let result = input.slice(0,remain);
            for(let i=remain; i< l; i+=3){
                result += "," + input.slice(i,i+3);
            }
            return result;
        }
        else{
            return null;
        }
    }

    useLayoutEffect(() => {
        if (location.state !== undefined && location.state.toContract === true) {
            setIsInfo(false);
        }
    },[])
    
    const AlertError = () => {

        if (errors.member_com?.member_id) {
            alert("아이디 입력정보를 확인해주세요.")
        } else if (errors.member_com?.member_pw || errors.member_com?.validPw) {
            alert("비밀번호 입력정보를 확인해주세요.")
        // } else if (compImage === null || compImage === undefined) {
        //     alert("대표 이미지를 첨부해주세요.")
        } else if (bizImage === null || bizImage === undefined) {
            alert("사업자등록증 또는 고유번호증을 첨부해주세요.")
        } else if (errors.comp_member_info?.comp_nm) {
            alert("회사명 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.rep_nm) {
            alert("대표자명 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.biz_seq) {
            alert("사업자등록번호 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.address || errors.comp_member_info?.extraAddress) {
            alert("주소 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.biz_sector) {
            alert("기업업종 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.sales) {
            alert("매출 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.employee_cnt) {
            alert("종업원 수 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.manager_nm) {
            alert("담당자 이름 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.manager_pos) {
            alert("담당자 이름 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.manager_tel || errors.comp_member_info?.validNum) {
            alert("담당자 연락처 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.comp_tel) {
            alert("회사 연락처 입력정보를 확인해주세요.")
        } else if (errors.member_com?.email) {
            alert("이메일 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.manager_address || errors.comp_member_info?.manager_extraAddress) {
            alert("담당자 근무지 입력정보를 확인해주세요.")
        } else if (errors.member_com?.al_guidelines) {
            alert("연차사용기준 입력정보를 확인해주세요.")
        } else if (errors.member_com?.rh_policy) {
            alert("대체공휴일 휴무규정 입력정보를 확인해주세요.")
        }
    }

    useEffect(() => {
        try {
            getCompInfo().then((response) =>{
                if (response.data.resultCode === 1000 && response.data.resultData) {
                    reset({
                        ...response.data.resultData,
                        comp_member_info : {
                            ...response.data.resultData.comp_member_info,
                            address: response.data.resultData.comp_member_info.address.split("|",2)[0],
                            extraAddress: response.data.resultData.comp_member_info.address.split("|",2)[1],
                            manager_address: response.data.resultData.comp_member_info.manager_address.split("|",2)[0],
                            manager_extraAddress: response.data.resultData.comp_member_info.manager_address.split("|",2)[1],
                            sales: comma_seperate(response.data.resultData.comp_member_info.sales),
                            employee_cnt: comma_seperate(response.data.resultData.comp_member_info.employee_cnt)
                        }
                    })
                }
                if (response.data.resultData.comp_member_info.biz_file_nm !== null)
                    setBizImage({name: response.data.resultData.comp_member_info.biz_origin_file_nm})
                if (response.data.resultData.comp_member_info.contract_dt !== null){
                    setIsContract(true);
                    setContractData({
                        contract_dt:response.data.resultData.comp_member_info.contract_dt,
                        contract_path:response.data.resultData.comp_member_info.contract_path,
                        concent_path:response.data.resultData.comp_member_info.concent_path
                    })
                }
            })

        } catch (e) {
            console.log(e)
        }
    }, []);

    const onSubmit = async (data) => {
        try {
            data.comp_member_info.address = data.comp_member_info.address + "|" + data.comp_member_info.extraAddress;
            data.comp_member_info.manager_address = data.comp_member_info.manager_address + "|" + data.comp_member_info.manager_extraAddress;

            await updateCompInfo(data).then((response) => {
                if (response.data.resultCode === 1000) {

                    if (bizImage && bizImage.type !== undefined) {

                        formData.append('image', bizImage);
                        formData.append('imageNm', data.comp_member_info.biz_seq + "." + bizImage.type.split('/')[1]);
                        formData.append('originFileNm', bizImage.name);
                        formData.append('compSeq', parseInt(data.comp_member_info.comp_seq));

                        uploadImage(formData);
                    }

                    const user = JSON.parse(localStorage.getItem('user'));
                    user.username = data.comp_member_info.comp_nm;
                    localStorage.setItem('user', JSON.stringify(user));

                    alert("수정이 완료되었습니다.");
                    history.push("/");
                }
            });

        } catch(error) {
            console.log(error);
        }
    }

    const onError = () => {
        AlertError()
    }

    const handleDownload = (url) => {
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file_name');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          })
          .catch(error => {
            console.error('파일을 다운로드하는 동안 오류가 발생했습니다.', error);
          });
      };

    return (
        <div className="wrapper">
            <main>
                <div className="page page_mypage enterprise page_applicant_list page_join_complete">
                    <h2 className="page_back_title">기업정보 관리</h2>
                    <div className="page_inner_above tab_menu_above"></div>
                    <div className="profile_detail_tab">
                        <ul className="tab_menu">
                            <li className="tab_menu_list">
                                <a href="#" target="_self" id="info" className={`tab_page_01${isInfo === true ? " active" : ""}`} onClick={() => setIsInfo(true)}>기업정보 관리</a>
                            </li>
                            <li className="tab_menu_list">
                                <a href="#" target="_self" id="contract" className={`tab_page_02${isInfo === false ? " active" : ""}`} onClick={() => setIsInfo(false)}>시스템 계약서 확인</a>
                            </li>
                        </ul>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit, onError)}>
                                <div className="page_inner">
                                    {isInfo === true && watchField.comp_member_info && watchField.member_com &&
                                    <div className="shadow_box_wrap">
                                        <div className="area_shade">
                                            <div className="wrap_signup_input wrap_signup_basic">
                                                <div className="box_sub_title box_sub_title_first">
                                                    <h4 className="sub_title_signup">계정정보</h4>
                                                </div>
                                                <div className="wrap_box_input focus_in">
                                                    <div className="box_confirm">
                                                        <div className="box_input">
                                                            <label htmlFor="" className="asterisk">아이디</label>
                                                            <input type="text" id="member_com.member_id"
                                                                   name="member_com.member_id" {...register('member_com.member_id')}
                                                                   disabled/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="wrap_box_input focus_in">
                                                    <div className="box_confirm">
                                                        <div className="box_input">
                                                            <label htmlFor="" className="asterisk"
                                                                   style={{height: '62px'}}>비밀번호</label>
                                                            <button type="button"
                                                                    className="btn_submit filled_green"
                                                                    style={{left: '0px'}} onClick={()=>setChangeModal(true)}>변경하기
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <CompInfo compImage={compImage} setCompImage={setCompImage}
                                                          bizImage={bizImage} setBizImage={setBizImage}
                                                          bizSeqCheck={bizSeqCheck} setBizSeqCheck={setBizSeqCheck}
                                                          isCompInfo={true} />
                                                <ManagerInfo phone={phone} setPhone={setPhone} isCompInfo={true} phoneChange={phoneChange} setPhoneChange={setPhoneChange}/>
                                                <HolidayReg/>
                                                <div className="wrap_box_input" style={{marginTop: "30px"}}>
                                                    <div className="box_confirm">
                                                        <div className="box_input">
                                                            <label htmlFor="" style={{height: '62px'}}>회원탈퇴</label>
                                                            <a href="/comp/signup/compWithdrawal" target="_self" className="btn_submit filled_green" style={{left: '0px'}}>
                                                                회원 탈퇴하기
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="wrap_btns">
                                            <button type="button" className="btn_submit filled_black" onClick={() => setBackModal(true)}>취소</button>
                                            <button type="button" className="btn_submit filled_green" onClick={() => {setModal(true)}}>저장하기</button>
                                        </div>
                                    </div>
                                    }
                                    {isInfo === false &&
                                    <div className="shadow_box_wrap">
                                        {isContract ? <>
                                        <div className="area_shade">
                                            <div className="wrap_txt">
                                                <h3>시스템 사용계약이 완료되었습니다.</h3>
                                                <p>시스템 사용계약과 관련하여 문의사항이 있으실 경우 아래 연락처로 연락 부탁드립니다.<br />
                                                    시스템 사용계약 문의 연락처: 1644-8619</p>
                                            </div>
                                        </div>
                                        <div className="area_shade">
                                            <div className="wrap_btn_download">
                                                <div className="line_btn_download">
                                                    <h5>승인완료</h5>
                                                    <span className="label_line"></span>
                                                    <p>
                                                        <span>{contractData.contract_dt.substring(0,10)}</span>
                                                        <span>{contractData.contract_dt.substring(11)}</span>
                                                    </p>
                                                </div>
                                                <div className="line_btn_download">
                                                    <h5>계약서</h5>
                                                    <span className="label_line"></span>
                                                    <a href={contractData.contract_path} target="_blank"><button type="button" className="btn_download">계약서 다운로드</button></a>
                                                </div>
                                                <div className="line_btn_download">
                                                    <h5>동의서</h5>
                                                    <span className="label_line"></span>
                                                    <a href={contractData.concent_path} target="_blank"><button type="button" className="btn_download">계약서 다운로드</button></a>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                        :<>
                                        <div className="area_shade">
                                            <div className="wrap_txt">
                                                <h3>시스템 사용계약 대기중입니다. <br/>
                                                    시스템 사용계약 완료 후 서비스를 이용하실 수 있습니다.
                                                </h3>
                                                <p>시스템 사용계약과 관련하여 문의사항이 있으실 경우 아래 연락처로 연락 부탁드립니다.<br/>
                                                    시스템 사용계약 문의 연락처: 1644-8619</p>
                                            </div>
                                        </div>
                                        <div className="area_shade">
                                            <div className="wrap_btn_download">
                                                <div className="line_btn_download">
                                                    <h5>승인완료</h5>
                                                    <span className="label_line"></span>
                                                    <p>
                                                        <span>-</span>
                                                    </p>
                                                </div>
                                                <div className="line_btn_download">
                                                    <h5>계약서</h5>
                                                    <span className="label_line"></span>
                                                    <p>
                                                        <span>-</span>
                                                    </p>
                                                </div>
                                                <div className="line_btn_download">
                                                    <h5>동의서</h5>
                                                    <span className="label_line"></span>
                                                    <p>
                                                        <span>-</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        </>}
                                    </div>
                                    }
                                </div>
                                {
                                    modal ?
                                        <ConfirmModal setModal={setModal}
                                                      width={"500px"} height={"300px"}
                                                      ment={ment}/>
                                        : null
                                }
                            </form>
                        </FormProvider>
                    </div>
                </div>
            </main>
            {
                changeModal ? <ChangePwModal setChangeModal={setChangeModal}/> : null
            }
            {
                backModal ? <BackModal setBackModal={setBackModal} link={link} ment={backModalMent} /> : null
            }
        </div>

    )
}

export default CompInfoManage;