export const CompanyRequestInit = {
    enterprise: {
        enterprise_nm: "",
        manager_nm: "",
        tel_no: "",
        enterprise_email: "",
        homepage_url: "",
        enterprise_file_path: "",
        enterprise_image: "",
        enterprise_info: ""
    },
    incruit: {
        recruit_status: "공고중",
        recruit_name: "",
        recruit_start_date: "",
        recruit_end_date: "",
        employment_type: "",
        position_num: "",
        salary_type: "",
        salary: ""
    },
    condition: {
        duty_form: "",
        duty_hour: "",
        duty_type: "정규직",
        employment_condition: "신입",
        education: "고등학교 졸업이상",
        work_location: "",
        remote_work:""
    },
    comInfo: {
        benefits: "",
        main_duty:"",
        other_info: "",
        qualification:"",
        file_path:"",
        file_nm:""
    },
    disability: {
        disability_type: {
            disability_name: "중증(장애의 정도가 심한 장애인, 기존 장애등급1~3등급)"
        },
        handicapped_type: [
            {
                disability_status: "",
                add_disabled: [
                    {
                        disability_name: ""
                    }
                ]
            }
        ]
    },
    disableNameData :{

    }

}