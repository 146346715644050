import { createPortal } from "react-dom";
import "./Modal.css";

export function Modal({ isOpen, title, content, buttons, onClose }) {
  if (!isOpen) return null;

  return createPortal(
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-body" onClick={(evt) => evt?.stopPropagation()}>
        {title && <div className="modal-title">{title}</div>}
        <div className="modal-content">{content}</div>
        <div className="modal-buttons">
          {buttons &&
            buttons.map((button, index) => (
              <button
                key={index}
                onClick={(evt) => {
                  if (button.onClick) button.onClick(evt);
                  if (!button.preserveModal) onClose(evt);
                }}
                className={`modal-button ${button.className || ""}`}
              >
                {button.label}
              </button>
            ))}
        </div>
      </div>
    </div>,
    document.body
  );
}
