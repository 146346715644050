import React, {useState} from 'react';
import SelfLogin from '../../container/user/login/SelfLogin';
import SocialLogin from '../../container/user/login/SocialLogin';
import FindIdPw from "../../container/user/login/FindIdPw";


const LeftToggleBeforeLogin = () => {
  const [findModal, setFindModal] = useState(false);
  const [role, setRole] = useState("ROLE_USER");
  const [display, setDisplay] = useState(true);

  const handleCloseModal = e => {
      setTimeout(() => {
          setFindModal(false);
          document.getElementsByClassName("wrap_btn_toggle_right")[0].style.zIndex = "1010";
      }, 300);
  }

  const handleOnModal = e => {
      setTimeout(() => {
          setFindModal(true);
          document.getElementsByClassName("wrap_btn_toggle_right")[0].style.zIndex = "1009";
      }, 300);
  }

    const handleRoleTabClick = (selectedRole,showSNS) => {
        console.log(showSNS);
        setRole(selectedRole);
        if(showSNS){
            console.log("!!!!!!!!!!!!!!")
            setDisplay(true);
        }
        else{
            console.log("@@@@@@@@@@@@@@")
            
            setDisplay(false);
        }   
        
    };

  return (
    <>
    <div className="behtmlFore_login">
        <div className="wrap_tabs">
            <ul className="tab_btn_list" role="tablist">
                <li className="tab_btn_item on" role="user" aria-controls="boardItemNotice">
                    <button className="tab_btn_anchor on" aria-label="개인회원 탭보기" onClick={() => handleRoleTabClick('ROLE_USER',true)}>
                        {/*<!-- <span className="sr_only">선택된 탭</span> -->*/}
                        개인회원</button>
                </li>
                <li className="tab_btn_item archive" role="company" aria-controls="boardItemArchive">
                    <button className="tab_btn_anchor" aria-label="기업회원 탭보기" onClick={() => handleRoleTabClick('ROLE_COMPANY',false)}>
                        {/*<!-- <span className="sr_only">비활성화 탭</span> -->*/}
                        기업회원</button>
                </li>
            </ul>
            <h3>로그인이 필요해요</h3>

            <div className="wrap_login_input tab_contents on">
                <SelfLogin role={role}></SelfLogin>
                <div className="wrap_info">
                    <div className="box_input_chk">
                        <input type="checkbox" className="input_chk" id="maintain01" name="maintain01"/>
                        <label htmlFor="maintain01" className="input_chk_label">로그인 상태 유지</label>
                    </div>
                    <div className="wrap_btns">
                        <a href="#" onClick={handleOnModal} className="btn_">ID/PW 찾기</a>
                    </div>
                    <div className="wrap_btns">
                        <a href="/user/signup/selectsignup" target="_self" className="btn_">회원가입</a>
                    </div>

                </div>
                <SocialLogin/>

            </div>

            <div className="wrap_login_input tab_contents">

                <SelfLogin role={role}/>
                <div className="wrap_info">
                    <div className="box_input_chk">
                        <input type="checkbox" className="input_chk" id="maintain02" name="maintain02"/>
                        <label htmlFor="maintain02" className="input_chk_label">로그인 상태 유지</label>
                    </div>
                    <div className="wrap_btns">
                        <a href="#" onClick={handleOnModal} className="btn_">ID/PW 찾기</a>
                    </div>
                    <div className="wrap_btns">
                        <a href="/user/signup/selectsignup" target="_self" className="btn_">회원가입</a>
                    </div>
                </div>
{
    display ? (
        <div className="wrap_social_login" >
        <p>소셜로그인</p>
        <ul className="social_login_list">
            <li className="social_login_item">
                <a href="#" target="_self" className="social_login_anchor" title="구글 아이디로 로그인">
                    <img src="/img/icon/login_google.png" alt=""/>
                </a>
            </li>
            <li className="social_login_item">
                <a href="#" target="_self" className="social_login_anchor" title="카카오 아이디로 로그인">
                    <img src="/img/icon/login_kakao.png" alt=""/>
                </a>
            </li>
            <li className="social_login_item">
                <a href="#" target="_self" className="social_login_anchor" title="네이버 아이디로 로그인">
                    <img src="/img/icon/login_naver.png" alt=""/>
                </a>
            </li>
            <li className="social_login_item">
                <a href="#" target="_self" className="social_login_anchor" title="페이스북 아이디로 로그인">
                    <img src="/img/icon/login_facebook.png" alt=""/>
                </a>
            </li>
        </ul>
    </div>
    ) : null
}
               
            </div>
        </div>
        {
            findModal ?
                <FindIdPw
                    onClose={handleCloseModal}
                    setModal={setFindModal}/> : null
        }
    </div>
    </>
  );
};

export default LeftToggleBeforeLogin;












