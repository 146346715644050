
import { useState,useEffect} from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";


const LoginPopUP = ({text}) => {
    const history = useHistory();


    const close_popup_layer = () => {
        history.push("/");
    }

    return (
        <>
            <div className="popup_mask" style={{display: 'block'}}></div>
            <div className="popup_layer popup_small" style={{display: 'block'}}>
                <div className="popup_inner">          
                    <div className="popup_contents">
                        <p>회원가입 후 이력서 작성 및 역량평가 완료 시<br/>{text} 정보를 제공해드립니다.</p>
                        <div className="popup_small_btn_wrap">
                            <Link to="#" target="_self" className="btn_submit filled_black" onClick={close_popup_layer}>닫기</Link>
                            <Link to="/user/signup/selectsignup" target="_self" className="btn_submit filled_green">회원가입 하기</Link>
                        </div>
                    </div>    
                </div>
            </div>
        </>
    );
}

export default LoginPopUP;