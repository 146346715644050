import { useState, useEffect, useRef, useCallback } from "react";
import { GameTimer } from "./GameTimer";

export function useGameTimer({ initialDurationInSeconds, onTimerComplete }) {
  const timerRef = useRef(null);
  const startTimeRef = useRef(null);
  const elapsedWhenPausedRef = useRef(0);

  const [remainingTime, setRemainingTime] = useState(initialDurationInSeconds);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [isTimerFinished, setIsTimerFinished] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const savedTime = localStorage.getItem("gameRemainingTime");
    const savedStartTime = localStorage.getItem("gameStartTime");

    if (!savedStartTime || !savedStartTime) return;

    const elapsedSinceLastSave = Math.floor(
      (Date.now() - parseInt(savedStartTime)) / 1000
    );
    const newRemainingTime = Math.max(
      parseInt(savedTime) - elapsedSinceLastSave,
      0
    );

    setRemainingTime(newRemainingTime);
    startTimeRef.current =
      Date.now() - (remainingTime - newRemainingTime) * 1000;

    if (newRemainingTime > 0) {
      setIsTimerRunning(true);
    } else {
      setIsTimerFinished(true);
    }

    return () => {
      localStorage.removeItem("gameRemainingTime");
      localStorage.removeItem("gameStartTime");
    };
  }, []);

  useEffect(() => {
    if (!isTimerRunning) return;

    const updateRemainingTime = () => {
      const elapsedTime = Math.floor(
        (Date.now() - startTimeRef.current) / 1000
      );
      const newRemainingTime = remainingTime - elapsedTime;

      if (newRemainingTime <= 0) {
        clearInterval(timerRef.current);
        setRemainingTime(0);
        setIsTimerFinished(true);
        setIsTimerRunning(false);
        if (onTimerComplete) {
          onTimerComplete();
        }
      } else {
        setRemainingTime(newRemainingTime);
        localStorage.setItem("gameRemainingTime", newRemainingTime.toString());
        localStorage.setItem("gameStartTime", Date.now().toString());
      }
    };

    timerRef.current = setInterval(updateRemainingTime, 1000);
    return () => clearInterval(timerRef.current);
  }, [isTimerRunning, initialDurationInSeconds]);

  const startTimer = useCallback(() => {
    if (!isTimerRunning) {
      startTimeRef.current = Date.now() - elapsedWhenPausedRef.current * 1000;
      setIsTimerRunning(true);
      setIsTimerFinished(false);
      setIsPaused(false);
    }
  }, [isTimerRunning]);

  const pauseTimer = useCallback(() => {
    if (isTimerRunning && !isPaused) {
      clearInterval(timerRef.current);
      elapsedWhenPausedRef.current = remainingTime - remainingTime;
      setIsPaused(true);
      setIsTimerRunning(false);
    }
  }, [isTimerRunning, isPaused, remainingTime]);

  const resumeTimer = useCallback(() => {
    if (!isTimerRunning && isPaused) {
      startTimeRef.current = Date.now() - elapsedWhenPausedRef.current * 1000;
      setIsTimerRunning(true);
      setIsPaused(false);
    }
  }, [isTimerRunning, isPaused]);

  return {
    remainingTime,
    isTimerRunning,
    isTimerFinished,
    isPaused,
    startTimer,
    pauseTimer,
    resumeTimer,
    GameTimerComponent: ({ isFinished }) => (
      <GameTimer isFinished={isFinished} remainingTime={remainingTime} />
    ),
  };
}
