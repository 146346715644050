import React from 'react';
import {useImageFile} from "../../../hooks/useImageFile";


const InterAddInfo = (props) => {
    const { methods, imageInput, scenarioId, setImage} = props;
    const { register, setValue, watch, handleSubmit, clearErrors, reset, setError, formState: { errors }} = methods;
    const watchField = watch();

    const setImageFile = (image) => {
        setValue("member_info.image_file_path_nm", image.name, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
    }

    const {uploadBase64} = useImageFile(imageInput, setImage, methods, "member_info.image_file_path", setImageFile, ['jpg', 'jpeg', 'png']);

    return (
        <div className="chat_message_box">
            <div className="box_chat_bg">
                <div className="wrap_certificate">
                    <div className="box_division">
                        <div className="wrap_box_input focus_in">
                            <div className="box_input">
                                <label>보호자 이름</label>
                                <input type="text" id="member_info.protector_nm" name="member_info.protector_nm" placeholder="보호자 이름을 입력해 주세요."
                                       {...register("member_info.protector_nm")} disabled={scenarioId === "create_addinfo" || scenarioId === "valid_addinfo" ? false : true}/>
                            </div>
                        </div>
                        <div className="wrap_box_input focus_in">
                            <div className="box_input">
                                <label>관계</label>
                                <select name="member_info.protector_relation" id="member_info.protector_relation" className="select" {...register("member_info.protector_relation")}
                                        disabled={scenarioId === "create_addinfo" || scenarioId === "valid_addinfo" ? false : true}>
                                    <option value="부">부</option>
                                    <option value="모">모</option>
                                    <option value="형제/자매">형제/자매</option>
                                    <option value="조부모">조부모</option>
                                    <option value="복지사">복지사</option>
                                    <option value="기타">기타</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="box_division">
                        <div className="wrap_box_input">
                            <div className="box_input">
                                <label htmlFor="">보호자 연락처</label>
                                <input type="text" id="member_info.protector_phone" name="member_info.protector_phone" placeholder="휴대폰 번호를 입력해 주세요."
                                       {...register("member_info.protector_phone")} disabled={scenarioId === "create_addinfo" || scenarioId === "valid_addinfo" ? false : true}/>
                            </div>
                        </div>
                        <div className="wrap_box_input">
                            <div className="box_input">
                                <label htmlFor="">보호장구</label>
                                <select name="member_info.equipment_st" id="member_info.equipment_st" className="select" {...register("member_info.equipment_st")}
                                        disabled={scenarioId === "create_addinfo" || scenarioId === "valid_addinfo" ? false : true}>
                                    <option value="휠체어">휠체어</option>
                                    <option value="보조기">보조기</option>
                                    <option value="목발">목발</option>
                                    <option value="의수">의수</option>
                                    <option value="의족">의족</option>
                                    <option value="스틱">스틱</option>
                                    <option value="보청기">보청기</option>
                                    <option value="흰지팡이">흰지팡이</option>
                                    <option value="안내견">안내견</option>
                                    <option value="기타">기타</option>
                                    <option value="사용안함">사용안함</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="box_division">
                        <div className="wrap_box_input">
                            <div className="box_input">
                                <label htmlFor="" className="">유입경로</label>
                                <select name="member_info.inflow" id="member_info.inflow" className="select" {...register("member_info.inflow")}
                                        disabled={scenarioId === "create_addinfo" || scenarioId === "valid_addinfo" ? false : true}>
                                    <option value="해당없음">해당없음</option>
                                    <option value="삼장사 카페">삼장사 카페</option>
                                    <option value="기사">기사</option>
                                    <option value="소개">소개</option>
                                    <option value="블로그">블로그</option>
                                    <option value="네이버카페">네이버카페</option>
                                    <option value="인스타그램">인스타그램</option>
                                    <option value="페이스북">페이스북</option>
                                    <option value="유튜브">유튜브</option>
                                    <option value="복지관">복지관</option>
                                    <option value="검색">검색</option>
                                </select>
                            </div>
                        </div>
                        <div className="wrap_box_input">
                            <div className="box_input">
                            </div>
                        </div>
                    </div>
                    <div className="wrap_box_input focus_in">
                        <div className="box_input">
                            <label className="asterisk">이력서 사진</label>
                            <div className="wrap_attached_photo">
                                <div className="attached_photo_name">
                                    <p>{ watchField.member_info.image_file_path !== "" ? watchField.member_info.image_file_path_nm : 'PNG, JPG 형식의 파일만 업로드 가능합니다.' }</p>
                                </div>
                                <input type="file" id="member_info.image" name="member_info.image"  onChange={uploadBase64} ref={imageInput} accept='.jpg, .png' disabled={scenarioId === "create_addinfo" || scenarioId === "valid_addinfo" ? false : true}/>
                                <button type="button" className="attached_photo_button" onClick={() => imageInput.current.click()} disabled={scenarioId === "create_addinfo" || scenarioId === "valid_addinfo" ? false : true}>파일선택</button>
                            </div>
                            <p className="attached_note">※ 10MB 이하만 업로드 가능합니다.</p>
                        </div>
                        <p className="msg_err">{ errors.member_info?.image_file_path?.message }</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InterAddInfo;