import uuid from "react-uuid";
import { scenarioType } from "../../container/user/resume/Scenario";
import { useFormContext } from "react-hook-form";
const validScenarios = ["입학", "편입", "검정고시"];
// 유효성 검사 진행하여 다음 문항 불러옴
export const validateInput = ({ inputValue, scenarioId }) => {
  const _uuid = "||" + uuid();
  const validationEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const validDate = /^\d{4}-(0[1-9]|1[0-2])$/;
  const isNumber = /^\d+$/;
  const affiliation = [
    "공과",
    "인문대학",
    "사회과학",
    "자연과학",
    "생활과학",
    "경영",
    "경제",
    "금융",
    "예술",
    "체육",
    "사범",
    "국제",
    "의과",
    "간호학",
    "기타",
  ];
  const skillOption = ["상", "중", "하"];
function isValidNumber(inputValue: string): boolean {
    const numberValue = Number(inputValue);
    
    return !isNaN(numberValue) &&  numberValue <= 100;
}

  const scenarioTypeMap = {
    "education.high_school.admission_status": [
      "입학",
      "편입",
      "검정고시",
    ].includes(inputValue),
    INSERT_HIGH_ADMISSION_DATE: validDate.test(inputValue),
    "education.high_school.affiliation": [
      "이과",
      "문과",
      "실업계",
      "예체능계",
      "특성화고",
      "특수목적고",
    ].includes(inputValue),
    "education.high_school.admission_date": validDate.test(inputValue),

    "education.high_school.graduate_status": [
      "졸업",
      "졸업예정",
      "재학중",
      "중퇴",
    ].includes(inputValue),
    "education.high_school.graduate_year": validDate.test(inputValue),
    "insert.high_pass.date": validDate.test(inputValue),
    "education.university.admission_status": ["입학", "편입"].includes(
      inputValue
    ),
    "education.university.gubun": [
      "일반(4년제)",
      "전문대(3년제)",
      "전문대(2년제)",
    ].includes(inputValue),
    "education.university.admission_date": validDate.test(inputValue),
    "education.university.graduate_status": [
      "졸업",
      "졸업예정",
      "재학중",
      "휴학중",
      "수료",
      "중퇴",
    ].includes(inputValue),
    "education.university.graduate_year": validDate.test(inputValue),
    "education.university.affiliation": affiliation.includes(inputValue),
    // "education.university.grade": isNumber.test(inputValue),
    "education.university.grade":isValidNumber(inputValue),
    "education.university.total_grade": [
      "4.5",
      "4.3",
      "4.0",
      "100",
    ].includes(inputValue),
    "education.university.add_major.major_status": [
      "부전공",
      "복수전공",
      "이중전공",
    ].includes(inputValue),
    "education.university.add_major.major_affiliation":
      affiliation.includes(inputValue),
    "education.graduate.admission_status": ["입학", "편입"].includes(
      inputValue
    ),
    "education.graduate.affiliation": affiliation.includes(inputValue),
    "education.graduate.admission_date": validDate.test(inputValue),
    "education.graduate.degree": ["석사", "박사", "석박사"].includes(
      inputValue
    ),
    "education.graduate.graduate_status": [
      "졸업",
      "졸업예정",
      "재학중",
      "휴학중",
      "수료",
      "중퇴",
    ].includes(inputValue),
    "education.graduate.graduate_year": validDate.test(inputValue),
    "education.graduate.grade" : isValidNumber(inputValue),
    "education.graduate.total_grade": ["4.5", "4.3", "4.0", "100"].includes(
      inputValue
    ),
    "career.employment_status": ["재직중", "퇴사"].includes(inputValue),
    "career.hired_date": validDate.test(inputValue),
    // 컴활
    "comskill.word_ability": skillOption.includes(inputValue),
    "comskill.excel_ability": skillOption.includes(inputValue),
    "comskill.ppt_ability": skillOption.includes(inputValue),
    // 자격증
    "certificate.certificate_gubun": [
      "국가기술자격증-기술사",
      "국가기술자격증-기능장",
      "국가기술자격증-기사",
      "국가기술자격증-산업기사",
      "국가기술자격증-기능사",
      "국가자격증",
      "공인민간자격증",
    ].includes(inputValue),
    "certificate.certificate_acquisition_date": validDate.test(inputValue),
    "prize.award_date": validDate.test(inputValue),
    "language.language_acquisition_date": validDate.test(inputValue),

    // 병역 사항
    "military.military_target": [
      "대상아님",
      "군필",
      "미필",
      "면제",
      "복무중",
    ].includes(inputValue),
    "military.military_start_date": validDate.test(inputValue),
    "military.military_end_date": validDate.test(inputValue),
    "military.military_category": [
      "육군",
      "해군",
      "공군",
      "해병",
      "의경",
      "전경",
      "기타",
      "사회복무요원",
      "의무소방",
    ].includes(inputValue),
    "military.military_discharged_rank": [
      "이병",
      "일병",
      "상병",
      "병장",
      "하사",
      "중사",
      "상사",
      "원사",
      "준위",
      "소위",
      "중위",
      "대위",
      "소령",
      "중령",
      "대령",
      "소장",
      "중장",
      "대장",
      "기타",
    ].includes(inputValue),
    "military.discharge_reason": [
      "만기제대",
      "의가사제대",
      "의병제대",
      "소집해제",
      "불명예제대",
      "상이제대",
    ].includes(inputValue),
    "veteran.has_veterans_affair": ["대상", "비대상"].includes(inputValue),
  };

  const scenario = scenarioId.split("||")[0];

  // 유효성 검사 결과 반환
  return scenarioTypeMap[scenario] !== undefined
    ? scenarioTypeMap[scenario]
    : true;
};
