import React, { useState, useRef, Component, useEffect } from "react";
import { Link } from "react-router-dom";
import api, { decodeToken } from "../../../api/api";
import dayjs from "dayjs";

const BeforeResumeForm = () => {
  const memSeq = localStorage.getItem("mem_seq");
  const dToken = decodeToken(localStorage.getItem("accessToken"));
  const [resumeData, setResumeData] = useState([]);
  const [desireNames, setDesireNames] = useState({});

  useEffect(() => {
    try {
      api
        .post("/api/resume_new/getProfileList", {})
        .then((response) => response.data)
        .then((response) => {
          response &&
            response.resultCode === 1000 &&
            response.resultData &&
            setResumeData(response.resultData);
          if (response.resultData.length === 0) {
            localStorage.setItem("checkResume", false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const deleteResume = (seq) => {
    const userConfirmed = window.confirm("이력서를 삭제하시겠습니까?");
    if (userConfirmed) {
      try {
        api
          .post(`/api/resume_new/deleteProfile?seq=${seq}`)
          .then((response) => response.data)
          .then((response) => {
            if (
              response &&
              response.resultCode === 1000 &&
              response.resultData
            ) {
              // Filter out the deleted item from the resumeData array
              window.location.reload();
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    const fetchDesireNames = async () => {
      const names = {};
      for (const item of resumeData) {
        try {
          const response = await api.post(
            `/api/resume_new/getDesireName?resumeSeq=${item.seq}`
          );
          names[item.seq] = response.data.resultData;
        } catch (error) {
          console.error(
            `Error fetching desire name for resume ${item.seq}:`,
            error
          );
        }
      }
      setDesireNames(names);
    };

    fetchDesireNames();
  }, [resumeData]);

  const timeFormatter = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  useEffect(() => {
    console.error(
      "테스트 개발 끝나면 꼭 이부분 제거해야함. 매번 이력서 등록하기 힘들어서 만든 코드임"
    );
    if (localStorage.getItem("test") === "true") {
      api.post(
        "/api/resume_new/createProfile",
        JSON.parse(
          '{"profile":{"member_name":"string","member_birthdate":"1990-06-13","member_phone":"string","member_email":"gametest60@gamil.com","member_file_path":"string","member_image":"string"},"education":{"high_school":{"admission_status":"검정고시","school_name":"","affiliation":"","major":"","admission_date":"","graduate_status":"","graduate_year":"2024-04"},"university":[],"graduate":[]},"career":[],"comskill":{"word_ability":null,"excel_ability":null,"ppt_ability":null},"certificate":[],"prize":[],"language":[],"military":{"military_target":"미필","military_start_date":"","military_end_date":"","military_category":"","military_discharged_rank":"","discharge_reason":""},"veteran":{"has_veterans_affair":"비대상","veterans_affair_reason":""},"introduction":{"introduction_title":"1","introduction_content":"2"},"desire":[{"depth1":"04","depth2":"02","depth3":"02","depth4":"01","depth1_name":"04.교육·자연·사회과학","depth2_name":"02.평생교육","depth3_name":"02.평생교육운영","depth4_name":"01.평생교육프로그램 기획·개발·평가"},{"depth1":"04","depth2":"02","depth3":"02","depth4":"02","depth1_name":"04.교육·자연·사회과학","depth2_name":"02.평생교육","depth3_name":"02.평생교육운영","depth4_name":"02.평생교육프로그램 운영·상담·교수"},{"depth1":"04","depth2":"03","depth3":"01","depth4":"01","depth1_name":"04.교육·자연·사회과학","depth2_name":"03.직업교육","depth3_name":"01.직업교육","depth4_name":"01.경력지도"}]}'
        )
      );
    }
  }, []);

  return (
    <div>
      <div className="wrapper">
        <main>
          <div className="page join page_resume_management page_layout_40">
            <h2 className="page_back_title">이력서 관리하기</h2>
            <div className="page_inner_above">
              <p className="resume_count">
                총 <span>{resumeData && resumeData.length}</span> 건
              </p>
              <p className="attached_note">
                이력서는 최대 3개까지 등록 가능합니다.
              </p>
            </div>
            <div className="page_inner">
              <div className="area_shade">
                <div className="wrap_signup_input wrap_signup_basic">
                  {resumeData && resumeData.length === 0 ? (
                    <div className="wrap_box_resume resume_null">
                      <p className="resume_add_note">
                        등록된 이력서가 없습니다.
                        <br />
                        이력서 작성을 완료해주세요.
                      </p>
                      <Link
                        to={`/user/resume/desiredJob/${1}`}
                        className="btn_submit btn_add filled_green"
                      >
                        <span className="btn_text">이력서 추가하기</span>
                      </Link>
                    </div>
                  ) : (
                    <div className="wrap_box_resume">
                      {resumeData &&
                        resumeData.map((item, i) => (
                          <div className="box_resume" key={i}>
                            <div className="resume_write_delete">
                              <Link
                                to={`/user/resume/desiredJobUpdate/${item.seq}`}
                              >
                                <button
                                  type="button"
                                  className="btn_resume_write"
                                  title="이력서 수정"
                                ></button>
                              </Link>
                              <button
                                type="button"
                                className="btn_resume_delete"
                                title="이력서 삭제"
                                onClick={() => deleteResume(item.seq)}
                              ></button>
                            </div>
                            <a href="#" className="resume_detail">
                              <span className="resume_title">
                                {desireNames[item.seq]}
                              </span>
                              <span className="resume_written_date">
                                {timeFormatter(item.modify_dt)}
                              </span>
                            </a>
                          </div>
                        ))}
                      {resumeData && resumeData.length < 3 && (
                        <Link
                          to={`/user/resume/desiredJob/${1}`}
                          className="btn_submit btn_resume_add"
                        >
                          <label htmlFor="file01">
                            <span className="input_resume_add">
                              이력서 추가하기
                            </span>
                            <span className="attached_note">
                              PDF형식의 파일만 업로드 가능합니다
                            </span>
                          </label>
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default BeforeResumeForm;
