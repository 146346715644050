import React, {useEffect, useLayoutEffect, useState} from 'react';
import EnterpriseInfo from "./EnterpriseInfo";
import IncruitInfo from "./IncruitInfo";
import WorkCondition from "./WorkCondition";
import Disability from "./Disability";
import {useForm} from "react-hook-form";
import {getCompInfo, uploadImage} from "../../../api/Comp/CompSignup/CompSignup";
import {useHistory, useParams} from "react-router-dom";
import {CompanyRequestInit} from "./Init/CompanyInit";
import {getRecruit, updateRecruit} from "../../../api/Comp/JobMatching/JobMatching";

const JobRequestUpdate = () => {
    const methods = useForm({
        mode: 'onChange',
        defaultValues: CompanyRequestInit
    });
    const {register, setValue, watch, handleSubmit, control, reset, resetField, trigger, setError, remove, formState: {errors}} = methods;
    const [imagePreview, setImagePreview] = useState(null);
    const [compImage, setCompImage] = useState(null);
    const [postFile, setPostFile] = useState(null);
    const [isRecruit, setIsRecruit] = useState(false);
    const [compAddress, setCompAddress] = useState(null);
    const {recruitSeq} = useParams();
    const history = useHistory();

    const comma_seperate = (value) => {
        let input = value.replaceAll(',',"");
        if(input != null && input != "" && !isNaN(input)){
            let l = input.length;

            let remain = l%3 == 0 ? 3 : l%3;
            let result = input.slice(0,remain);
            for(let i=remain; i< l; i+=3){
                result += "," + input.slice(i,i+3);
            }
            return result;
        }
        else{
            return null;
        }
    }

    useLayoutEffect(() => {
        try {
            getRecruit(recruitSeq).then((response) => {
                if (response.data.resultCode === 1000 && response.data.resultData) {
                    reset({
                        ...response.data.resultData,
                        incruit:{...response.data.resultData.incruit,salary: comma_seperate(response.data.resultData.incruit.salary)},
                        condition: {
                            ...response.data.resultData.condition,
                            work_location: response.data.resultData.condition.work_location.split("|")[0],
                            detail_work_location: response.data.resultData.condition.work_location.split("|")[1]
                        }
                    })
                    if (response.data.resultData.disability.handicapped_type[0].disability_status === 'Y') {
                        setValue("disableNameData", response.data.resultData.disability.handicapped_type[0].add_disabled.map((data) => data.disabled_name))
                    }
                    if (response.data.resultData.condition.employment_condition.split("|")[0] === "경력") {
                        setValue("condition.employment_condition", response.data.resultData.condition.employment_condition.split("|")[0])
                        setValue("condition.employed_years", response.data.resultData.condition.employment_condition.split("|")[1])
                    }
                    setCompAddress(response.data.resultData.incruit.address.split('|'));
                    setIsRecruit(true);
                }
            })
        } catch (e) {
            console.log(e)
        }
    }, []);

    const onError = (errors) => {
        if(errors?.enterprise?.enterprise_nm){
            alert("기업이름을 확인 하세요");
        }else if(errors?.enterprise?.manager_nm){
            alert("관리자이름을 확인 하세요");
        }else if(errors?.enterprise?.tel_no){
            alert("대표번호을 확인 하세요");
        }else if(errors?.enterprise?.enterprise_email){
            alert("기업 이메일을 확인 하세요");
        }else if(errors?.enterprise?.homepage_url){
            alert("기업 홈페이지 주소를 확인 하세요");
        }else if(errors?.enterprise?.enterprise_info){
            alert("기업정보를 확인 하세요");
        }else if(errors?.incruit?.recruit_name){
            alert("공고명을 확인 하세요");
        }else if(errors?.incruit?.recruit_start_date){
            alert("공고 시작일을 확인 하세요");
            document.getElementsByName("incruit.recruit_start_date")[0].focus();
        }else if(errors?.incruit?.recruit_end_date){
            alert("공고 종료일을 확인 하세요");
            document.getElementsByName("incruit.recruit_end_date")[0].focus();
        }else if(errors?.incruit?.position_num){
            alert("모집 인원수를 확인 하세요");
        }else if(errors?.incruit?.salary_type){
            alert("임금 지급 단위시간을 확인 하세요");
        }else if(errors?.incruit?.salary){
            alert("임금 정보를 확인 하세요");
        }else if(errors?.condition?.duty_form){
            alert("직무 조건을 확인 하세요");
        }else if(errors?.condition?.duty_hour){
            alert("직무 조건을 확인 하세요");
        }else if(errors?.disableNameData){
            alert("장애 조건을 확인 하세요");
        }else if(errors?.comInfo?.main_duty){
            alert("주요직무를 확인 하세요");
        }else if(errors?.comInfo?.qualification){
            alert("자격요건을 확인 하세요");
        }else if(errors?.comInfo?.benefits){
            alert("복리후생을 확인 하세요");
        }else if(errors?.comInfo?.other_info){
            alert("기타사항을 확인 하세요");
        }
    }

    const onSubmit = (data) => {
        try {
            const {condition: {work_location, detail_work_location, employed_years}} = data;
            const address = detail_work_location ? `${work_location}|${detail_work_location}` : work_location;
            data.condition.work_location = address;

            if (data.condition.employment_condition === '경력') {
                data.condition.employment_condition += `|${employed_years}`;
            }
            if (data.disability.handicapped_type[0].disability_status === 'Y') {
                data.disability.handicapped_type[0].add_disabled = data.disableNameData.map((disabled_name) => ({
                    disabled_name
                }));
            }
            if (data?.desire && localStorage.getItem('compDesiredJobCode')) {
                data.desire = JSON.parse(localStorage.getItem('compDesiredJobCode'));
            }

            updateRecruit(data, data.enterprise.seq).then((response) => {
                if (response.data.resultCode === 1000 && response.data.resultData) {
                    window.localStorage.removeItem('compDesiredJobCode');
                    alert("직무요청서가 성공적으로 수정되었습니다.");
                    window.location.href = "/comp/jobRequest/jobApplicationList";
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="wrapper">
            <main>
                {
                    isRecruit === true && (
                        <form onSubmit={handleSubmit(onSubmit, onError)} onKeyDown={(e)=>{if (e.key === 'Enter' && e.target.nodeName === "INPUT"){e.preventDefault();}}}>
                            <div className="page join page_join page_mypage enterprise page_job_request">
                                <div className="page_back_title with_btn">
                                    <a href="/comp/jobRequest/jobApplicationList" target="_self" className="page_back">직무요청서
                                        등록</a>
                                    <button type="submit"
                                            className="btn_submit filled_green">수정하기
                                    </button>
                                </div>
                                <div className="page_inner_above"></div>

                                <div className="wrap_progressbar">
                                    <ul className="progressbar_list progress_step02">
                                        <li className="progressbar_item progress_item01"><p>요청직무 선택</p></li>
                                        <li className="progressbar_item progress_item02"><p>직무요청서 작성</p></li>
                                    </ul>
                                </div>

                                <h3 className="page_title">직무요청서를 작성해보세요!</h3>

                                <div className="page_inner">
                                    <div className="area_shade">

                                        <EnterpriseInfo methods={methods} disabled={false} imagePreview={imagePreview}
                                                        setImagePreview={setImagePreview}
                                                        compImage={compImage} setCompImage={setCompImage} errors={errors}/>
                                        <IncruitInfo methods={methods} disabled={false} errors={errors}/>
                                        <WorkCondition methods={methods} address={compAddress} disabled={false} errors={errors}/>
                                        <Disability methods={methods} disabled={false} postFile={postFile}
                                                    setPostFile={setPostFile} errors={errors}/>

                                    </div>
                                    <div className="wrap_btns">
                                        <a href={`/comp/jobRequest/chooseJobRequest/${recruitSeq}`} target="_self"
                                           className="btn_submit filled_black">이전으로</a>
                                        <button type="submit" className="btn_submit filled_green">수정하기</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }
            </main>
        </div>
    )
}
export default JobRequestUpdate;





