import "./tip.css";

/**
 * @param {string} [top]
 * @param {string} [left]
 * @param {string} [bottom]
 * @param {string} [right]
 * @param {string} [arrowDirection="bottom-center"] - The direction of the arrow.
 *   Possible values are:
 *   - "top-left"
 *   - "top-center"
 *   - "top-right"
 *   - "bottom-left"
 *   - "bottom-center"
 *   - "bottom-right"
 *   - "left-top"
 *   - "left-center"
 *   - "left-bottom"
 *   - "right-top"
 *   - "right-center"
 *   - "right-bottom"
 */
export default function Tip({
  children,
  top,
  left,
  bottom,
  right,
  arrowDirection = "bottom-center",
}) {
  return (
    <div
      className={`tip tip-${arrowDirection}`}
      style={{ top, left, bottom, right }}
    >
      <div className="tip-arrow"></div>
      {children}
    </div>
  );
}
