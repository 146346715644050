import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {ErrorMessages} from "../../../utils/Message";
import {sendPhone} from "../../../api/User/Signup/Signup";
import {findCompId, findCompPw, findUserId, updateCompPw, updateUserPw} from "../../../api/User/Login/Login";
import axios from "axios";

const init={
    userType: "USER",
    name: "",
    id: "",
    phoneNum: "",
    validNum: ""
}

const FindIdPw = (props) => {
    const methods = useForm({
        mode: 'onChange',
        defaultValues: init
    });
    const { register, setValue, watch, handleSubmit, clearErrors, reset, setError, formState: { errors, isValid }} = methods;
    const { onClose, setModal } = props;
    const [findType, setFindType] = useState(true);
    const [userId, setUserId] = useState(null);
    const [userPw, setUserPw] = useState(false);
    const [phone, setPhone] = useState({
        send : false,
        num : undefined,
        pass : false
    });
    const [time ,setTime] = useState(179);
    const watchField = watch();

    useEffect(() => {
        let Count

        if (time > 0) {
            Count = setInterval(() => {
                setTime((prev) => prev - 1);
            }, 1000);
        }
        else {
            clearInterval(Count);
            if (phone.pass !== true)
                setPhone({...phone, num: undefined, pass: false})
        }
        return () => clearInterval(Count)
    }, [time])


    const timeFormat = (time) => {
        const m = Math.floor(time / 60).toString()
        let s = (time % 60).toString()
        if (s.length === 1) s = `0${s}`
        return `${m}:${s}`
    }

    const sendMessage = async () => {
        const num = watchField.phoneNum;

        if (num === "" || num === undefined)
            setError("phoneNum", {
                message: ErrorMessages.required
            }, {shouldFocus: true})
        else {
            try {
                const send = await sendPhone(num);

                setPhone({...phone, send: true, num : send})
                setTime(179)

            } catch (error) {
                console.log(error)
            }
        }
    }

    const validPhoneCheck = () => {
        const validNum = watchField.validNum;
        if (phone.num !== undefined) {
            if (validNum === phone.num.data.resultData.toString()) {
                setPhone({...phone, pass: true})
                clearErrors("phoneNum")
                clearErrors("validNum")
            }
            else {
                setError("validNum", {
                    message : ErrorMessages.inValidPhone
                }, {shouldFocus: true})
                setPhone({...phone, pass : false})
            }
        }
        else {
            setError("validNum", {
                message : ErrorMessages.reValidPhone
            }, {shouldFocus: true})
            setPhone({...phone, pass : false})
        }
    }

    const resetData = () => {
        reset({...init})
        setUserId(null);
        setUserPw(false);
        setPhone({...phone, send: false, num: undefined, pass: false})
    }

    const autoHyphen = (e) => {
        let num = e.target.value.replace(/[^0-9]/g,'');
        if(num.length < 4) {
            e.target.value = num.slice(0,3)
        } else if (num.length < 6) {
            e.target.value =  num.slice(0,3) + "-" + num.slice(3);
        } else {
            e.target.value =  num.slice(0,3) + "-" + num.slice(3,5) + "-" + num.slice(5);
        }
    }

    const onFindId = () => {
        if (findType === false) {
            setFindType(() => true)

            resetData();
        }
    }

    const onFindPw = () => {
        if (findType === true){
            setFindType(() => false)

            resetData();
        }
    }

    const onSubmit = async (data) => {
        if (watchField.userType === "USER" && findType === true) {
            const response = await findUserId(data.phoneNum, data.name);

            if (response.data.resultCode === 1000) {
                setUserId(response.data.resultData.slice(0, -3) + "***");
            } else {
                alert("이름 또는 휴대폰번호가 일치하지 않거나 가입된 이력이 없습니다. 확인 후 다시 입력해주세요.")
                resetData();
            }

        } else if (watchField.userType === "COMPANY" && findType === true) {
            const response = await findCompId(data.name, data.bizSeq);

            if (response.data.resultCode === 1000) {
                setUserId(response.data.resultData.slice(0, -3) + "***");
            } else {
                alert("기업담당자명 또는 사업자등록번호가 일치하지 않거나 가입된 이력이 없습니다. 확인 후 다시 입력해주세요.")
                resetData();
            }
        } else if (watchField.userType === "USER" && findType === false) {
            const response = await findUserId(data.phoneNum, data.name);
            if (response.data.resultCode === 1000 && response.data.resultData === watchField.id) {
                setUserPw(true);
            } else {
                alert("입력하신 정보와 일치하는 내역이 없습니다. 확인 후 다시 입력해주세요.")
                resetData();
            }
        } else if (watchField.userType === "COMPANY" && findType === false) {
            const response = await findCompPw(data.name, data.phoneNum);
            if (response.data.resultCode === 1000 && response.data.resultData === watchField.id) {
                setUserPw(true);
            } else {
                alert("입력하신 정보와 일치하는 내역이 없습니다. 확인 후 다시 입력해주세요..")
                resetData();
            }
        }
    }

    const changePw = async () => {
        if (isValid === true) {
            if (watchField.userType === "USER") {
                const response = await updateUserPw(watchField.id, watchField.pw)

                if (response.data.resultCode===1000 && response.data.resultData===1) {
                    alert("비밀번호가 변경되었습니다.")
                    onClose();
                } else {
                    console.log("error")
                }
            } else {
                const response = await updateCompPw(watchField.id, watchField.pw);

                if (response.data.resultCode===1000 && response.data.resultData===1) {
                    alert("비밀번호가 변경되었습니다.")
                    onClose();
                } else {
                    console.log("error")
                }
            }
        }
    }

    const displayBlock={
        display: "block",
    }

    return (
        <div>
            <div className="popup_mask" style={displayBlock} onClick={onClose}></div>
            <div className="popup_layer popup_middle popResetIDPW" id="popResetIDPW" style={displayBlock}>
                <div className="popup_inner">
                    <div className="pop_title">
                        <h3>ID/PW 찾기</h3>
                        <button type="button" aria-label="레이어 팝업 닫기" className="popup_close" onClick={onClose}></button>
                    </div>
                    <ul className="wrap_text_guide">
                        {findType === true &&
                        <li>
                            <p className="text_guide">회원가입 시 입력하신 이름과 휴대폰 번호로<br/> 아이디를 확인하실 수 있습니다.</p>
                        </li>
                        }
                        {findType === false &&
                        <li>
                            <p className="text_guide">회원가입 시 입력하신 이름, 아이디, 휴대폰 번호로<br/> 비밀번호를 재설정 하실 수 있습니다.</p>
                        </li>
                        }
                    </ul>
                    <ul className="tab_menu">
                        <li className="tab_menu_list">
                            <a id="findId" className={`tab_page_01${findType === true ? " active" : ""}`} onClick={onFindId}>ID 찾기</a>
                        </li>
                        <li className="tab_menu_list">
                            <a id="findPw" className={`tab_page_02${findType === false ? " active" : ""}`} onClick={onFindPw}>PW 찾기</a>
                        </li>
                    </ul>
                    <div className="popup_contents">
                        <div id="wrap_contents_type" className={`wrap_contents${findType === true ? " content_01" : " content_02"}`}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <div className="wrap_input">
                                        { (userId === null && userPw === false) && (
                                            <>
                                            <div className="wrap_box_input radio_init">
                                                <div className="box_input">
                                                    <div className="wrap_box_radio">
                                                        <div className="input_radio">
                                                            <label htmlFor="temp11" className="label_txt">
                                                                <input type="radio" className="btn_type_radio" id="temp11" name="radio10"
                                                                       value="USER" checked={(watchField.userType==="USER") ? true : false}
                                                                       {...register('userType')}/>개인회원</label>
                                                        </div>
                                                        <div className="input_radio">
                                                            <label htmlFor="temp12" className="label_txt">
                                                                <input type="radio" className="btn_type_radio" id="temp12" name="radio10"
                                                                       value="COMPANY" checked={(watchField.userType==="COMPANY") ? true : false}
                                                                       {...register('userType')}/>기업회원</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wrap_box_input">
                                                <div className="box_input">
                                                    <label htmlFor="" className="asterisk">{ watchField.userType === "USER" ? "이름" : "기업담당자명"}</label>
                                                    <input type="text"  id="name" name="name" placeholder="이름을 입력해 주세요." {...register('name',{
                                                        required: ErrorMessages.required
                                                    })}/>
                                                </div>
                                                <p className="msg_err">{ errors.name?.message }</p>
                                            </div>
                                            {
                                                findType === false &&
                                                    <div className="wrap_box_input">
                                                        <div className="box_input">
                                                            <label htmlFor="" className="asterisk">아이디</label>
                                                            <input type="id" id="id" name="id" placeholder="아이디를 입력해 주세요." {...register('id',{
                                                                required: ErrorMessages.required
                                                            })}/>
                                                        </div>
                                                        <p className="msg_err">{ errors.id?.message }</p>
                                                    </div>
                                            }
                                            {
                                                (watchField.userType === "COMPANY" && findType === true) && (
                                                    <div className="wrap_box_input">
                                                        <div className="box_input">
                                                            <label htmlFor="" className="asterisk">사업자등록번호</label>
                                                            <input type="text" id="bizSeq" name="bizSeq" placeholder="사업자등록번호를 입력해 주세요." {...register('bizSeq',{
                                                                // required: ErrorMessages.required,
                                                                required: "필수 입력 항목입니다. (숫자만 사용 가능)",
                                                                onChange: (e) => {
                                                                    autoHyphen(e)
                                                                }
                                                            })}/>
                                                        </div>
                                                        <p className="msg_err">{ errors.bizSeq?.message }</p>
                                                    </div>
                                                )
                                            }
                                            {
                                                !(watchField.userType === "COMPANY" && findType === true) && (
                                                    <div className="wrap_box_input">
                                                        <div className="box_confirm">
                                                            <div className="box_input">
                                                                <label htmlFor="" className="asterisk">휴대폰 인증</label>
                                                                <input type="tel" id="phoneNum" name="phoneNum"
                                                                       placeholder="휴대폰 번호를 입력해 주세요." {...register('phoneNum', {
                                                                    required: ErrorMessages.required,
                                                                    validate: {
                                                                        validPhone: () => phone.send === true || ErrorMessages.validPhone
                                                                    },
                                                                    onChange: (e) => {
                                                                        setPhone({...phone, pass: false, num: undefined});
                                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');}
                                                                })} disabled={phone.pass === true ? true : false}/>
                                                                <p className="msg_time">{(phone.pass !== true && phone.send === true) && timeFormat(time)}</p>
                                                            </div>
                                                            {phone.pass !== true &&
                                                            <button type="button" className="btn_submit filled_green"
                                                                    onClick={sendMessage}>{phone.send === false ? "인증번호 요청" : "재전송"}</button>
                                                            }
                                                            {phone.pass === true &&
                                                            <button type="button" className="btn_submit filled_green"
                                                                    disabled>인증완료</button>
                                                            }
                                                        </div>
                                                        {phone.send !== true &&
                                                        <p className="msg_err">{errors.phoneNum?.message}</p>
                                                        }
                                                        {(phone.send === true && phone.pass !== true) && <>
                                                            <div className="box_confirm">
                                                                <div className="box_input">
                                                                    <input type="tel" id="validNum" name="validNum"
                                                                           placeholder="인증번호를 입력해 주세요." {...register('validNum', {
                                                                        required: ErrorMessages.required,
                                                                        validate: {
                                                                            validNum: () => phone.pass === true || ErrorMessages.validPhone
                                                                        }
                                                                    })}/>
                                                                </div>
                                                                <button type="button"
                                                                        className="btn_submit filled_green"
                                                                        onClick={validPhoneCheck}>인증번호 확인
                                                                </button>
                                                            </div>
                                                            <p className="msg_err">{errors.phoneNum?.message || errors.validNum?.message}</p></>
                                                        }
                                                    </div>
                                                )
                                            }
                                            </>
                                        )}
                                        {userId !== null &&
                                        <div className="wrap_text">
                                            <p>입력하신 정보와 일치하는 아이디입니다.</p>
                                            <p>아이디: <span className="user_id_info">{userId}</span></p>
                                        </div>
                                        }
                                        {userPw !== false &&
                                        <div className="wrap_input">
                                            <div className="wrap_text">
                                                <p>재설정하실 비밀번호를 입력해 주세요.</p>
                                            </div>
                                            <div className="wrap_box_input">
                                                <div className="box_input">
                                                    <label htmlFor="" className="asterisk">새 비밀번호 입력</label>
                                                    <input type="password" id="pw" name="pw"
                                                           placeholder="아이디를 입력해 주세요." {...register('pw',{
                                                        required: ErrorMessages.required,
                                                        pattern: {
                                                            value: /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,32}$/,
                                                            message: ErrorMessages.inValidPw
                                                        }
                                                    })}/>
                                                </div>
                                                <p className="msg_err" >{ errors.pw?.message }</p>
                                            </div>
                                            <div className="wrap_box_input">
                                                <div className="box_input">
                                                    <label htmlFor="" className="asterisk">새 비밀번호 확인</label>
                                                    <input type="password" id="validPw" name="validPw"
                                                           placeholder="아이디를 입력해 주세요." {...register('validPw',{
                                                        required: ErrorMessages.required,
                                                        validate: {
                                                            valid: (value) => value === watchField.pw || ErrorMessages.unMatchPw
                                                        }
                                                    })}/>
                                                </div>
                                                <p className="msg_err" >{ errors.validPw?.message }</p>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="popup_middle_btn_wrap">
                                    <button type="button" aria-label="레이어 팝업 닫기" className="btn_submit filled_black" onClick={onClose}>닫기</button>
                                    { (userId === null && userPw === false) &&
                                    <button type="submit" className="btn_submit filled_green">확인</button>
                                    }
                                    { userId !== null &&
                                    <a href="/user/login/LoginForm" className="btn_submit filled_green" >로그인</a>
                                    }
                                    { userPw !== false &&
                                    <button type="button" className="btn_submit filled_green" onClick={changePw}>비밀번호 재설정하기</button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FindIdPw;