import React, {useState, useEffect} from "react";
import api from '../../../api/api';

const initValue1 = {
    "agency_seq" : null,
    "recruit_seq" : null,
    "insert_questions" : [],
    "delete_questions" : []
}

const initValue2 = {
    "question_seq" : null,
    "agency_seq" : null,
    "interview_answer" : null,
    "answer_order" : null,
    "checked" : false
}

const initParam = {
    "agency_seq" : null
}

const QuestionModal = (props) => {
    // 1. type 확인(insert, update)
    // 2. 공고 조회 및 select 박스에 넣기
    // 3-1. type == insert 인 경우 insert 후에 key값 가져오기
    // 3-2. type == update 인 경우 select 후에 initvalue에 값 추가
    // 4. 모달팝업 끌시에 insert 인경우 key값으로 삭제 / update 인 경우 아무것도 x
    // 5. 확인 및 수정버튼 클릭시 insert 인경우 insert만 update인 경우 삭제 후 완료 시 insert
    const [agency, setAgency] = useState({});
    const [agencyQuestions, setAgencyQuestions] = useState([]);
    const [deleteQuestions, setDeleteQuestions] = useState([]);
    const [orderCnt, setOrderCnt] = useState(1);
    const [inputValue, setInputValue] = useState("");
    const [isActive, setIsActive] = useState({
        "target" : "number",
        "number" : "ASC",
        "answer" : "DESC"
    });
    const [checkboxAll, setCheckboxAll] = useState(false);
    const [recruitList, setRecruitList] = useState([]);
    const [pagination, setPagination] = useState({});

    useEffect( ()=> {
        let paramTmp = {...initParam};
        if(props.type === "insert"){
            setAgency({...initValue1});
        } else if(props.type === "update"){
            let tmp = {...initValue1};
            tmp.agency_seq = props.agcNum;
            tmp.recruit_seq = props.recNum;
            setAgency(tmp);

            paramTmp.agency_seq = props.agcNum;
            api.post("/api/comp/interview/selectAgencyQuestions", paramTmp)
            .then(response => response.data)
            .then((response) => {
                if(response && response.resultCode == 1000){
                    let questionsTmp = response.resultData;
                    questionsTmp = sorting(isActive, questionsTmp);
                    setAgencyQuestions(questionsTmp);
                    setOrderCnt(response.resultData.length + 1);
                } else {
                    alert("조회 오류");
                }
            })
        }
        api.post("/api/comp/interview/getRecruitListForAgency", paramTmp)
        .then(response => response.data)
        .then((response) => {
            if(response && response.resultCode == 1000){
                setRecruitList(response.resultData)
            } else {
                alert("조회 오류");
            }
        })
    }, []);

    useEffect(() => {
        if(agencyQuestions.length > 0){
            let totalPage = Math.ceil(agencyQuestions.length / 10);
            let currentPage = pagination.currPage != undefined && pagination.currPage != null ? pagination.currPage : 1;
            let minLimit = (5 * (Math.ceil(currentPage / 5) - 1) + 1);
            let maxLimit = (5 * (Math.ceil(currentPage / 5) - 1) + 5);
            maxLimit = maxLimit > totalPage ? totalPage : maxLimit; 
            currentPage = currentPage > maxLimit ? maxLimit : currentPage;
            let pageTmp = {
                "currPage" : currentPage,
                "totalPage" : totalPage,
                "minLimit" : minLimit,
                "maxLimit" : maxLimit
            }
            setPagination(pageTmp);
        }
    }, [agencyQuestions]);
    
    const setCurrPage = (pageNum) => {
        if(pagination.currPage != pageNum){
            let totalPage = Math.ceil(agencyQuestions.length / 10);
            let minLimit = (5 * (Math.ceil(pageNum / 5) - 1) + 1);
            let maxLimit = (5 * (Math.ceil(pageNum / 5) - 1) + 5);
            maxLimit = maxLimit > totalPage ? totalPage : maxLimit; 
            let pageTmp = {
                "currPage" : pageNum,
                "totalPage" : totalPage,
                "minLimit" : minLimit,
                "maxLimit" : maxLimit
            }
            setPagination(pageTmp);    

            let tmp = [...agencyQuestions];
            tmp.map((item, i) => {
                item.checked = false;
            })
            setAgencyQuestions(tmp);
            setCheckboxAll(false);
        }
        
    }
    
    const handleChangeInput = (e) => {
        setInputValue(e.target.value);
    }

    const handleOnKeyPress = (e) =>{
        if(e.key === "Enter" && inputValue != "" && e.nativeEvent.isComposing == false){
            let tmp = {...initValue2};
            tmp.interview_answer = inputValue;
            tmp.answer_order = orderCnt;
            tmp.agency_seq = agency?.agency_seq;
            setOrderCnt(orderCnt + 1);
            let questionsTmp = [...agencyQuestions, tmp];
            questionsTmp = sorting(isActive, questionsTmp);
            setAgencyQuestions(questionsTmp);
            setInputValue("");
        }
    }

    const deleteElement = (deleteCnt) => {
        let questionsTmp = [...agencyQuestions];
        let deleteTmp = [...deleteQuestions];
        questionsTmp.map((item, i) => {
            item.answer_order == deleteCnt && item.question_seq != null && (deleteTmp = [...deleteTmp, item]);
        });
        questionsTmp = questionsTmp.filter(e => e.answer_order != deleteCnt);
        questionsTmp.map((item, i) => {
            item.answer_order >= deleteCnt && item.answer_order--;
        })
        questionsTmp = sorting(isActive, questionsTmp);
        
        setOrderCnt(questionsTmp.length + 1);
        setDeleteQuestions(deleteTmp);
        setAgencyQuestions(questionsTmp);
    }

    const deleteElements = () => {
        let questionsTmp = [...agencyQuestions];
        let deleteTmp = [...deleteQuestions];
        let checkedTmp = [...agencyQuestions].filter(e => e.checked);

        checkedTmp.sort((a, b) => {
            return b.answer_order - a.answer_order
        });

        checkedTmp.map((item, i) => {
            item.question_seq != null && (deleteTmp = [...deleteTmp, item]);
            questionsTmp = questionsTmp.filter(e => e.answer_order != item.answer_order);
            questionsTmp.map((qItem, j) => {
                qItem.answer_order >= item.answer_order && qItem.answer_order--;
            })
        });
    
        questionsTmp = sorting(isActive, questionsTmp);

        setOrderCnt(questionsTmp.length + 1);
        setDeleteQuestions(deleteTmp);
        setAgencyQuestions(questionsTmp);

    }
    
    const toggleSortBtn = (e) => {
        let tmp = {...isActive};
        let tmpQ = [...agencyQuestions];
        tmp.target = e;
        (tmp[e] === undefined || tmp[e] === "DESC") ? (tmp[e] = "ASC") : (tmp[e] = "DESC");
        tmpQ = sorting(tmp, tmpQ);
        setIsActive(tmp);
        setAgencyQuestions(tmpQ);
    }

    // 질문 추가 후, 항목 삭제 후, 토글버튼 
    const sorting = (e, questions) => {
        let activeTmp ={...e};
        let questionsTmp = [...questions];
        //sortEnterNm === true ? a.enterprise.enterprise_nm.localeCompare(b.enterprise.enterprise_nm) : b.enterprise.enterprise_nm.localeCompare(a.enterprise.enterprise_nm)
        if(activeTmp.target === "number"){
            questionsTmp.sort((a, b) => {
                if(activeTmp[activeTmp.target] == "ASC") {
                    return a.answer_order - b.answer_order;
                } else {
                    return b.answer_order - a.answer_order;
                }
            });
        } else if(activeTmp.target === "answer"){
            questionsTmp.sort((a,b) => {
                if(activeTmp[activeTmp.target] == "DESC"){
                    return b.interview_answer.localeCompare(a.interview_answer);
                } else {
                    return a.interview_answer.localeCompare(b.interview_answer);
                }
            })
            //questionsTmp.sort((a,b) => activeTmp[activeTmp.target] == "DESC" ? a.interview_answer.localCompare(b.interview_answer) : b.interview_answer.localCompare(a.interview_answer))
        }
        return questionsTmp;
    }
    
    const handleInputChkbox = (isChecked, i) => {
        let tmp = [...agencyQuestions];
        tmp[i].checked = isChecked;
        setAgencyQuestions(tmp);
    }
    
    const handleSelectbox = (e) => {
        let tmp = {...agency};
        if(e.target.value != -1) {
            tmp.recruit_seq = e.target.value;
        } else {
            tmp.recruit_seq = null;
        }
        setAgency(tmp);
    }

    const closeModal = () => {
        props.setModal(false);
    }

    const saveAgency = () => {
      
        agency.insert_questions = agencyQuestions;
        agency.delete_questions = deleteQuestions;

        if(agency.recruit_seq == null){
            alert("공고명을 선택해주세요.");
            return false;
        } 
        if(agencyQuestions.length == 0){
            alert("등록된 질문이 없습니다. 질문을 등록해 주세요.");
            return false;
        }
        try{
            api.post("/api/comp/interview/saveQuestion", agency)
            .then(response => response.data)
            .then((response) => {
                if(response && response.resultCode == 1000){
                    alert("저장완료")
                } else {
                    alert("에러발생");
                }
            })
        } catch(e){
            alert("에러발생");
        } finally {
            closeModal();
        }
    }

    const allCheck = (isChecked) => {
        let tmp = [...agencyQuestions];
        if(isChecked){
            tmp.map((item, i) => {
                pagination?.currPage && (pagination.currPage * 10 > i) && ( (pagination.currPage -1) * 10 <= i) && (item.checked = true);
            })
        } else {
            tmp.map((item, i) => {
                pagination?.currPage && (pagination.currPage * 10 > i) && ( (pagination.currPage -1) * 10 <= i) && (item.checked = false);
            })
        }
        setAgencyQuestions(tmp);
        setCheckboxAll(isChecked);
    }

    return (
        <>
        <div className="popup_mask" style={{"display" : "block"}} ></div>
        <div className="popup_layer enterprise pop_lg popRequstDetail popInterviewMng" id="popInterviewMng">
            <div className="popup_inner">
                <div className="pop_title with_btn">
                    <h3>대행면접 질문관리</h3>
                    <button type="button" aria-label="레이어 팝업 닫기" className="popup_close" onClick={(e) => {e.preventDefault(); closeModal();}}></button>
                </div>
                <div className="page_inner">
                    <div className="popup_section_title line_under popup_btn_init">
                        <h3>기본정보</h3>
                        <div className="btn_wrap">
                            <button className="btn_submit filled_black" onClick={(e) => {e.preventDefault(); closeModal();}}>취소</button>
                            <button className="btn_submit filled_green" onClick={(e) => {e.preventDefault(); saveAgency();}}>저장하기</button>
                        </div>                 
                    </div>               
                    <div className="popup_contents">
                        <div className="wrap_signup_input">
                            <div className="wrap_box_input">
                                <div className="box_input">
                                    <label className="asterisk">공고명</label>
                                    {props.type == "insert" && (
                                        <select onChange={(e) => {e.preventDefault(); handleSelectbox(e);}} title="공고명 선택" defaultValue={-1}>
                                            <option value={-1}>대행면접을 진행할 공고명을 선택해 주세요.</option>
                                            {recruitList.map((item, i) => (
                                                <option value={item.recruit_seq} dangerouslySetInnerHTML={{__html : item.recruit_name}}></option>
                                            ))}
                                        </select>
                                    )}
                                    {props.type == "update" && (
                                        <input type="text" readOnly value={recruitList?.[0]?.recruit_name} ></input>
                                    )}
                                </div>
                               {props.type == "insert" && (<p className="msg_err" >필수 선택 항목입니다.</p>)}
                            </div>
                        </div>
                    </div>
                    <div className="popup_section_title line_under">
                        <h3>대행면접 질문리스트</h3>                  
                    </div>
                    <div className="popup_contents">
                        <div className="wrap_signup_input">
                            <div className="wrap_box_input">
                                <div className="box_input">
                                    <label >질문추가</label>
                                    <input type="text" placeholder="대행면접 질문 입력 후 ‘Enter’ 키를 눌러주세요." value={inputValue} onChange={handleChangeInput} onKeyDown={handleOnKeyPress}/>
                                </div>
                                <p className="msg_err" >필수 입력 항목입니다.</p>
                            </div>
                        </div>
                        <div className="table_init wrap_box_search_result">                  
                            <div className="box_search_result">
                                <table className="table_detail sort_column">
                                    <colgroup>     
                                        <col style={{"width" : "64px"}}/>  
                                        <col style={{"width" : "100px"}}/>
                                        <col style={{"width" : "auto"}}/>
                                        <col style={{"width" : "130px"}}/>                                                      
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="no_sort">
                                                <div className="box_input_chk">
                                                    <label htmlFor={"allChk"} className="input_chk_label">
                                                        <input type="checkbox"  className="input_chk" id={"allChk"} checked={checkboxAll} name="question01" onClick={(e) => {allCheck(e.target.checked);}}/>
                                                    </label>
                                                </div>
                                            </th>
                                            <th scope="col" className={"header_sort01 " + (isActive.number == "ASC" ? "sort_down" : "sort_up")} onClick={(e) => {e.preventDefault(); toggleSortBtn("number"); }}>번호</th>                                      
                                            <th scope="col" className={"header_sort02 " + (isActive.answer == "DESC" ? "sort_down" : "sort_up")} onClick={(e) => {e.preventDefault(); toggleSortBtn("answer"); }}>전체 대행면접 질문리스트</th>
                                            <th scope="col" className="no_sort" style={{"textIndent" : 0, "textAlign" : "center"}}><button type="button" className="btn_table_init lined_black" onClick={(e) => {e.preventDefault(); deleteElements();}}>선택삭제</button></th>                                                                                     
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {agencyQuestions.length == 0 ? (
                                        <tr>
                                            <td colspan="4">등록된 대행면접 질문이 없습니다.</td>
                                        </tr>
                                        ) : (
                                            agencyQuestions.map((item, i) => (
                                                pagination?.currPage && (pagination.currPage * 10 > i) && ( (pagination.currPage -1) * 10 <= i) && ( <tr key={i}>
                                            <td>
                                                <div className="box_input_chk">
                                                    <label htmlFor={"temp0" + i} className="input_chk_label">
                                                        <input type="checkbox" value={item.answer_order} className="input_chk" id={"temp0" + i} name="question01" checked={item.checked} onChange={e => {handleInputChkbox(e.currentTarget.checked, i)}}/>
                                                    </label>
                                                </div>
                                            </td>
                                            <td dangerouslySetInnerHTML={{__html : item.answer_order}}></td>
                                            <td className="align_left" dangerouslySetInnerHTML={{__html : item.interview_answer}}></td>                                                          
                                            <td>
                                                <button type="button" className="btn_table_init lined_black" onClick={(e) => {e.preventDefault(); deleteElement(item.answer_order);}}>삭제하기</button>
                                            </td>
                                        </tr>)
                                        ))
                                        )}
                                        
                                       
                                    </tbody>
                                </table>
                            </div>
                            <div className="wrap_pagination no_result">
                                {agencyQuestions.length > 0 && (
                                    <ul className="pagination_list">
                                        {pagination?.minLimit == 1 ? ("") : (<li className="pagination_item"><a href="#" className="pagination_anchor prev" onClick={(e) => {e.preventDefault(); setCurrPage(pagination?.minLimit - 1);}} ></a></li>)}
                                        {pagination?.minLimit > pagination?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((pagination?.minLimit == pagination?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); setCurrPage(pagination?.minLimit);}} dangerouslySetInnerHTML={{__html : pagination?.minLimit}}></a></li>)}
                                        {pagination?.minLimit +1 > pagination?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((pagination?.minLimit +1 == pagination?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); setCurrPage(pagination?.minLimit +1);}} dangerouslySetInnerHTML={{__html : pagination?.minLimit+1}}></a></li>)}
                                        {pagination?.minLimit +2 > pagination?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((pagination?.minLimit +2 == pagination?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); setCurrPage(pagination?.minLimit +2);}} dangerouslySetInnerHTML={{__html : pagination?.minLimit+2}}></a></li>)}
                                        {pagination?.minLimit +3 > pagination?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((pagination?.minLimit +3 == pagination?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); setCurrPage(pagination?.minLimit +3);}} dangerouslySetInnerHTML={{__html : pagination?.minLimit+3}}></a></li>)}
                                        {pagination?.minLimit +4 > pagination?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((pagination?.minLimit +4 == pagination?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); setCurrPage(pagination?.minLimit +4);}} dangerouslySetInnerHTML={{__html : pagination?.minLimit+4}}></a></li>)}
                                        {pagination?.maxLimit == pagination?.totalPage ? ("") : (<li className="pagination_item"><a href="#" className="pagination_anchor next" onClick={(e) => {e.preventDefault(); setCurrPage(pagination?.maxLimit + 1);}} ></a></li>)}
                                    </ul>
                                )}
                                
                            </div>
                        </div>
                    </div>
                </div>    
            </div>    
        </div>
        </>
    )
}

export default QuestionModal;