import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "../quiz.css";
import { useGameTimer } from "../../../hooks/useGameTimer";
import { adjustArraySize } from "../../../utils/common";
import { INITIAL_COUNTDOWN_SECONDS } from "../games";

export default function BlockQuiz({
  defaultValue,
  nodes = [],
  size = 4,
  onChange,
  isModalOpen,
  openModal,
  setPrevPageButtonProps,
  setNextPageButtonProps,
}) {
  const ref = useRef(null);
  const {
    pauseTimer,
    resumeTimer,
    isTimerFinished,
    startTimer,
    GameTimerComponent,
  } = useGameTimer({
    initialDurationInSeconds: INITIAL_COUNTDOWN_SECONDS,
    onTimerComplete: () => {
      setPrevPageButtonProps(() => {
        return { disabled: false, onClick: () => {} };
      });
      setNextPageButtonProps(() => {
        return {
          disabled: false,
          onClick: (nextPage) => nextPage(),
        };
      });
    },
  });

  const [showState, setShowState] = useState(true);
  const [selectedState, setSelectedState] = useState(defaultValue || []);
  const isAnswered = useMemo(() => defaultValue?.filter(Boolean).length, []);

  useEffect(() => {
    if (isAnswered) {
      setPrevPageButtonProps(() => ({
        disabled: false,
        onClick: () => {},
      }));
      setNextPageButtonProps(() => ({
        disabled: false,
        onClick: (nextPage) => nextPage(),
      }));

      return;
    }

    startTimer();
    setPrevPageButtonProps(() => ({
      disabled: true,
      onClick: () => {},
    }));
  }, []);

  useEffect(() => {
    if (isAnswered) return setShowState(false);

    setShowState(true);
    ref.current?.classList.add("block-grid-timer");

    const timer = setTimeout(() => {
      setShowState(false);
      ref.current?.classList.remove("block-grid-timer");
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [ref.current]);

  useEffect(() => {
    if (isTimerFinished) {
      onChange({ arrayScore: adjustArraySize(selectedState, size) });
    }
  }, [isTimerFinished]);

  useEffect(() => {
    if (selectedState.length >= 5) {
      openModal({
        title: "알람",
        content: (
          <div>
            <p>블록은 최대 4개까지 선택가능합니다.</p>
            <p>수정을 원할 경우, 기존 블록을 재클</p>
            <p>릭하여 점등 해제 후 새로운 블록을</p>
            <p>클릭하세요</p>
          </div>
        ),
        buttons: [
          {
            label: "확인",
            onClick: () => {
              setSelectedState([]);
            },
            className: "confirm-button",
          },
        ],
      });

      return;
    }

    if (selectedState.length === size) {
      setNextPageButtonProps(() => {
        return {
          disabled: false,
          onClick: () =>
            onChange(
              {
                arrayScore: adjustArraySize(selectedState, size),
              },
              true
            ),
        };
      });
    } else {
      setNextPageButtonProps(() => {
        return {
          disabled: false,
          onClick: () => {
            openModal({
              title: "알람",
              content: (
                <div>
                  <p>미클릭 블록이 있습니다.</p>
                  <p>다음문제로 넘어가시겠습니까?</p>
                </div>
              ),
              buttons: [
                {
                  label: "네",
                  onClick: () =>
                    onChange(
                      {
                        arrayScore: adjustArraySize(selectedState, size),
                      },
                      true
                    ),
                  className: "confirm-button",
                },
                {
                  label: "아니오",
                  className: "cancel-button",
                },
              ],
            });

            return false;
          },
        };
      });
    }
  }, [selectedState]);

  useEffect(() => {
    isModalOpen ? pauseTimer() : resumeTimer();
  }, [isModalOpen]);

  const previewGrid = useMemo(
    () =>
      Array.from({ length: Math.pow(size, 2) }).map((i, j) => (
        <div
          key={j}
          className={`block-card ${nodes.includes(j) ? "selected" : undefined}`}
        />
      )),
    [nodes, size]
  );

  const handleClick = useCallback(
    (num) => {
      if (isAnswered || isTimerFinished || showState) return;
      setSelectedState((state) => {
        let post = [...state];
        if (state.includes(num)) {
          post = post.filter((i) => i !== num);
        } else {
          post = [...post, num];
        }

        return post;
      });
    },
    [showState, isTimerFinished]
  );

  const selectedGrid = useMemo(
    () =>
      Array.from({ length: Math.pow(size, 2) }).map((i, j) => (
        <div
          key={j}
          className={`block-card ${selectedState.includes(j) ? "selected" : undefined}`}
          onClick={() => handleClick(j)}
        />
      )),
    [selectedState, size, handleClick]
  );

  return (
    <div className="quiz flex h-full">
      <div className="quiz-wrapper">
        <GameTimerComponent isFinished={isTimerFinished || !!isAnswered} />

        <div className="quiz flex h-full">
          <div className="quiz-assets">
            <div
              className={`block-grid preview ${showState ? undefined : "under"}`}
            >
              {previewGrid}
            </div>

            <div className="block-grid answer">{selectedGrid}</div>

            <div ref={ref}></div>
          </div>

          <h3>Q. 블록의 위치를 마우스로 클릭하세요. (순서무관)</h3>
        </div>
      </div>
    </div>
  );
}
