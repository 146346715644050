import React, {useRef} from 'react';
import {useImageFile} from "../../../hooks/useImageFile";
import {ErrorMessages} from "../../../utils/Message";

const EnterpriseInfo = (props) => {
    const { methods, compImage, setCompImage, setImagePreview, disabled: propsDisabled,errors} = props;
    const { register, watch, setValue} = methods
    const disabled = propsDisabled !== undefined ? propsDisabled : false;
    const compImageRef = useRef(null);
    const thumbnailRef = useRef(null);
    const watchField = watch();

    const resizeImage = (settings) => {
        const file = settings.file;
        const onlyUrl = settings.onlyUrl;
        const maxWidth = settings.maxWidth;
        const maxHeight = settings.maxHeight;
        const reader = new FileReader();
        const image = new Image();
        const canvas = document.createElement("canvas");
      
        const dataURItoBlob = (dataURI) => {
          const bytes =
            dataURI.split(",")[0].indexOf("base64") >= 0
              ? atob(dataURI.split(",")[1])
              : unescape(dataURI.split(",")[1]);
          const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
          const max = bytes.length;
          const ia = new Uint8Array(max);
          for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
          return new Blob([ia], { type: mime });
        };
      
        const resize = () => {
            canvas.width = maxWidth;
            canvas.height = maxHeight;
            canvas.getContext("2d").drawImage(image, 0, 0, maxWidth, maxHeight);
            const dataUrl = canvas.toDataURL("image/jpeg");
            return dataURItoBlob(dataUrl);
        };
        
        return new Promise((ok, no) => {
          if (!file) {  
            return;
          }
          if (!file.type.match(/image.*/)) {
            no(new Error("Not an image"));
            return;
          }
          
          reader.onload = (readerEvent) => {
            image.onload = () => {
              let blob = resize();
              return onlyUrl ? ok(window.URL.createObjectURL(blob)) : ok(blob);
            };
            image.src = readerEvent.target.result;
          };
          reader.readAsDataURL(file);
        });
      }

    const uploadCompImageProxy = (e) => {
        const image = e.target.files[0];
        resizeImage({file: image, maxWidth:297, maxHeight:137, onlyUrl: false}).then((blob)=>{
            uploadCompImage({target:{files:[blob]}});
            setValue("comInfo.file_nm", image.name, {shouldValidate: true, shouldDirty: true, shouldTouch: true})
        }).catch((err) => {console.log(err); console.log("error catched");});
    }

    const callback = (image) => {
        resizeImage({file: image, maxWidth:297, maxHeight:137,onlyUrl: true}).then((url)=>{
            setImagePreview(url);
        });
    }

    const {uploadBase64: uploadCompImage} = useImageFile(compImageRef, setCompImage, methods, "comInfo.file_path", callback, ['jpg', 'jpeg', 'png']);

    const onErrorImg = (e) => {
        e.target.src = "/img/logo/vlogo.png"
        e.target.style.height = "80%"
    }

    return (
        <>
            <div className="wrap_signup_input wrap_signup_basic">
                <div className="box_sub_title">
                    <h4 className="sub_title_signup">기업정보 입력</h4>
                </div>
                <div className="wrap_box_input focus_in">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">회사명</label>
                        <input type="text" id="" name={`enterprise.enterprise_nm`}
                               {...register(`enterprise.enterprise_nm`,
                                   {required: ErrorMessages.required})}
                               placeholder="회사명을 입력해 주세요."
                               disabled={disabled}
                        ></input>
                    </div>
                    <p className="msg_err">{errors?.enterprise?.enterprise_nm ? "회사명을 입력해 주세요." : ""}</p>
                </div>

                <div className="wrap_box_input focus_in">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">담당자 이름</label>
                        <input type="text" id="" name={`enterprise.manager_nm`}
                               {...register(`enterprise.manager_nm`, {required: ErrorMessages.required})}
                               placeholder="담당자 이름을 입력해 주세요."
                               disabled={disabled}
                        ></input>
                    </div>
                    <p className="msg_err">{errors?.enterprise?.manager_nm?.message}</p>
                </div>

                <div className="wrap_box_input focus_in">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">연락처</label>
                        <input type="text" id="" name={`enterprise.tel_no`}
                               {...register(`enterprise.tel_no`, {required: ErrorMessages.required, pattern : /^\d{3}-?\d{4}-?\d{4}$/i})}
                               placeholder="연락처를 입력해 주세요."
                               disabled={disabled}
                        ></input>
                    </div>
                    <p className="msg_err">{errors?.enterprise?.tel_no?.message}</p>
                </div>

                <div className="wrap_box_input focus_in">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">이메일</label>
                        <input type="text" id="" name={`enterprise.enterprise_email`}
                               {...register(`enterprise.enterprise_email`, {required: ErrorMessages.required, pattern : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i})}
                               placeholder="이메일 주소를 입력해 주세요."
                               disabled={disabled}
                        ></input>
                    </div>
                    <p className="msg_err">{errors?.enterprise?.enterprise_email?.message}</p>
                </div>

                <div className="wrap_box_input focus_in">
                    <div className="box_input">
                        <label htmlFor="" className="">홈페이지</label>
                        <input type="text" id="" name={`enterprise.homepage_url`}
                               {...register(`enterprise.homepage_url`,
                                   {
                                       required: false
                                       // ,pattern : /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?$/i
                                   })
                                }
                               placeholder="홈페이지 주소를 입력해 주세요."
                               disabled={disabled}
                        ></input>
                    </div>
                    <p className="msg_err">{errors?.enterprise?.homepage_url?.message}</p>

                </div>
            </div>

            <div className="wrap_signup_input">
                <div className="wrap_box_input focus_in">
                    <div className="box_input">
                        <label htmlFor="" className="">대표 이미지</label>
                        <div className={`wrap_basic_photo${disabled === true ? " null" : ""}`}>
                            <div id="image_container" className="wrap_thumbnail">
                                <div className="box_thumbnail" ref={thumbnailRef}>
                                    <img src={watchField.comInfo.file_path} alt="" disabled={disabled} onError={onErrorImg}/>
                                </div>
                            </div>
                            <input type="file" id="attachedFileImage" ref={compImageRef} onChange={uploadCompImageProxy} accept=".jpg, .png" disabled={disabled}/>
                        </div>
                        <p className="attached_note company_img">※ JPG, PNG 형식의 파일만 업로드 가능합니다. </p>
                    </div>
                </div>

                <div className="wrap_box_input focus_in">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">기업소개</label>
                        <textarea name={`enterprise.enterprise_info`} maxLength={100}
                                  {...register(`enterprise.enterprise_info`, {required: ErrorMessages.required
                                    ,maxLength:{
                                    value: 1000,
                                    message: "입력 가능 글자수는 1000자 입니다."
                                  }})}
                                  id="" placeholder="기업소개 내용을 1000자 이내로 입력해주세요."
                                  disabled={disabled}
                        ></textarea>
                    </div>
                    <p className="msg_err">{errors?.enterprise?.enterprise_info?.message}</p>
                </div>
            </div>
        </>
    )
}
export default EnterpriseInfo;





