export function getImageUrl(path) {
  const host =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL || "http://localhost:8080"
      : "https://matching.vdream.co.kr";

  return `${host}${path || ""}`;
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function adjustArraySize(array, desiredSize = array.length) {
  const adjustedArray = array.map((item) =>
    item === "" || item === null || item === undefined ? "-" : item
  );
  const shortfall = desiredSize - adjustedArray.length;
  const fillArray = new Array(shortfall > 0 ? shortfall : 0).fill("-");

  return [...adjustedArray, ...fillArray];
}
