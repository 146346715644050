import React, { useState, useEffect} from 'react';
import QuestionModal from './QuestionModal';
import ConfirmModal from './ConfirmModal';
import api from '../../../api/api';

const QuestionManage = () => {
    //150글자 제한
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(4);
    const [visiblePageCount, setVisiblePageCount] = useState(10);
    const offset = (page - 1) * limit;

    const [questionModal, setQuestionModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [qType, setQType] = useState(null);
    const [recruitList, setRecruitList] = useState([]);
    const [recNum, setRecNum] = useState(null);
    const [agcNum, setAgcNum] = useState(null);
    const modalPopup = (type) => {
        setQType(type);
        switch(type) {
            case "insert":
            case "update":
                setQuestionModal(true);
                break;
            case "confirm":
                setConfirmModal(true);
                break;
        }
    }
    const [pagination, setPagination] = useState({});
    const [isActive, setIsActive] = useState({
        "target" : "name",
        "name" : "DESC",
        "count" : "DESC"
    });

    useEffect(() => {
        api.post("/api/comp/interview/getAgencyQuestionList")
        .then(response => response.data)
        .then((response) => {
            if(response && response.resultCode == 1000){
                let tmp = response.resultData;
                tmp = sorting(isActive, tmp);
                setRecruitList(tmp)
            } else {

            }
        })
    }, [confirmModal, questionModal])

    useEffect(() => {
        if(recruitList.length > 0) {
            let totalPage = Math.ceil(recruitList.length / 10);
            let currentPage = pagination.currentPage != undefined && pagination.currentPage != null ? pagination.currentPage : 1;
            let minLimit = (5 * (Math.ceil(currentPage / 5) - 1) + 1);
            let maxLimit = (5 * (Math.ceil(currentPage / 5) - 1) + 5);
            maxLimit = maxLimit > totalPage ? totalPage : maxLimit; 
            let pageTmp = {
                "currPage" : currentPage,
                "totalPage" : totalPage,
                "minLimit" : minLimit,
                "maxLimit" : maxLimit
            }
            setPagination(pageTmp);
        }
    }, [recruitList]);

    const setCurrPage = (pageNum) => {
        let tmp = {...pagination};
        tmp.currPage = pageNum;
        setPagination(tmp);
    }

    const sorting = (e, list) => {
        let activeTmp ={...e};
        let listTmp = [...list];
        if(activeTmp.target === "count"){
            listTmp.sort((a, b) => {
                if(activeTmp[activeTmp.target] == "ASC") {
                    return a.question_cnt - b.question_cnt;
                } else {
                    return b.question_cnt - a.question_cnt;
                }
            });
        } else if(activeTmp.target === "name"){
            listTmp.sort((a,b) => {
                if(activeTmp[activeTmp.target] == "DESC"){
                    return a.recruit_name.localeCompare(b.recruit_name);
                } else {
                    return b.recruit_name.localeCompare(a.recruit_name);
                }
            })
        }
        return listTmp;
    }

    const toggleSortBtn = (e) => {
        let tmp = {...isActive};
        let tmpQ = [...recruitList];
        tmp.target = e;
        (tmp[e] === undefined || tmp[e] === "ASC") ? (tmp[e] = "DESC") : (tmp[e] = "ASC");
        tmpQ = sorting(tmp, tmpQ);
        setIsActive(tmp);
        setRecruitList(tmpQ);
    }

    return (
        <>
        <div className="table_init content_02">
            <div>
                <h3 className="table_title">대행면접 질문관리</h3>
                <button type="button" className="btn_submit filled_green" onClick={(e) => {e.preventDefault(); modalPopup("insert");}} style={{"width" : "180px" }}>대행면접 질문 생성</button>
                {/*<!-- table 시작 -->*/}
                <div className="wrap_applicant_list">
                    <table className="table_detail sort_column">
                        <colgroup>                                                             
                            <col style={{width:"auto"}}/>
                            <col style={{width:"120px"}}/>                                
                            <col style={{width:"120px"}}/>
                            <col style={{width:"120px"}}/>                           
                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col" className={"header_sort01 " + (isActive.name == "DESC" ? "sort_down" : "sort_up")} onClick={(e) => {e.preventDefault(); toggleSortBtn("name"); }}>공고명</th>                                      
                                <th scope="col" className={"header_sort02 " + (isActive.count == "DESC" ? "sort_down" : "sort_up")} onClick={(e) => {e.preventDefault(); toggleSortBtn("count"); }}>질문 수</th>
                                <th scope="col" className="no_sort"></th>                                    
                                <th scope="col" className="no_sort"></th>                                                                  
                            </tr>
                        </thead>
                        <tbody>
                            {recruitList?.map((item, i) => (
                                pagination?.currPage && (pagination.currPage * 10 > i) && ( (pagination.currPage -1) * 10 <= i) && (<tr>
                                    <td className="align_left" dangerouslySetInnerHTML={{__html : item.recruit_name}}></td>
                                    <td className="align_right" dangerouslySetInnerHTML={{__html : item.question_cnt + "건"}}></td>
                                    <td>
                                        <button type="button" className="btn_table_init lined_black" onClick={(e) => {e.preventDefault(); setRecNum(item.recruit_seq);setAgcNum(item.agency_seq); modalPopup("update");}}>수정하기</button>
                                    </td>
                                    <td>
                                        <button type="button" className="btn_table_init lined_black" onClick={(e) => {e.preventDefault(); setRecNum(item.recruit_seq);setAgcNum(item.agency_seq); modalPopup("confirm");}}>삭제하기</button>
                                    </td>
                                </tr>)
                            ))}
                            
                            {recruitList.length == 0 && (
                                <tr>
                                    <td colSpan={4}>등록된 대행면접 질문이 없습니다.</td>
                                </tr>
                            )}
                            
                        </tbody>
                    </table>
                </div>
                {/*<!-- table 끝 -->*/}
                {/*<!-- pagination -->*/}
                <div className="wrap_pagination no_result">
                {recruitList.length > 0 && (
                    <ul className="pagination_list">
                        {pagination?.minLimit == 1 ? ("") : (<li className="pagination_item"><a href="#" className="pagination_anchor prev" onClick={(e) => {e.preventDefault(); setCurrPage(pagination?.minLimit - 1);}} ></a></li>)}
                        {pagination?.minLimit > pagination?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((pagination?.minLimit == pagination?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); setCurrPage(pagination?.minLimit);}} dangerouslySetInnerHTML={{__html : pagination?.minLimit}}></a></li>)}
                        {pagination?.minLimit +1 > pagination?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((pagination?.minLimit +1 == pagination?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); setCurrPage(pagination?.minLimit +1);}} dangerouslySetInnerHTML={{__html : pagination?.minLimit+1}}></a></li>)}
                        {pagination?.minLimit +2 > pagination?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((pagination?.minLimit +2 == pagination?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); setCurrPage(pagination?.minLimit +2);}} dangerouslySetInnerHTML={{__html : pagination?.minLimit+2}}></a></li>)}
                        {pagination?.minLimit +3 > pagination?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((pagination?.minLimit +3 == pagination?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); setCurrPage(pagination?.minLimit +3);}} dangerouslySetInnerHTML={{__html : pagination?.minLimit+3}}></a></li>)}
                        {pagination?.minLimit +4 > pagination?.maxLimit ? ("") : (<li className="pagination_item"><a href="#" className={"pagination_anchor " + ((pagination?.minLimit +4 == pagination?.currPage) && "currently")} onClick={(e) => {e.preventDefault(); setCurrPage(pagination?.minLimit +4);}} dangerouslySetInnerHTML={{__html : pagination?.minLimit+4}}></a></li>)}
                        {pagination?.maxLimit == pagination?.totalPage ? ("") : (<li className="pagination_item"><a href="#" className="pagination_anchor next" onClick={(e) => {e.preventDefault(); setCurrPage(pagination?.maxLimit + 1);}} ></a></li>)}
                    </ul>
                )}
                </div>
                {/*<!-- pagination end -->*/}
            </div>
        </div>
        {confirmModal && <ConfirmModal agcNum={agcNum} setModal={setConfirmModal}/>}
        {questionModal && <QuestionModal type={qType} recNum={recNum} agcNum={agcNum} setModal={setQuestionModal}/>}
        </>
    )
};

export default QuestionManage;