import api from "../../api";

export async function getBannerList() {
    const response = await api.get(`/api/jobSearching/banner`);

    return response;
}

export async function getAnnounceList(num) {
    const response = await api.get(`/api/jobSearching/jobAnnounce/${num}`);

    return response;
}

export async function getJobSearchList() {
    const response = await api.get('/api/jobSearching/jobSearch');

    return response;
}

export async function conditionedSearch(data) {
    const response = await api.post('/api/jobSearching/conditionedSearch', data, {
        headers: {
            'Content-Type': 'application/json'
        },
        params : {
            searchText: data.searchText
        }
    });

    return response;
}

export async function announceRecruitSearch(data) {
    const response = await api.put('/api/jobSearching/jobSearch', data, {
        headers: {
            'Content-Type': 'application/json'
        },
    });

    return response;
}

export async function createInter(recruitSeq) {
    const response = await api.post('/api/jobSearching/createInter', null, {
        params : {
            recruitSeq: recruitSeq
        }
    })

    return response
}

export async function deleteInter(recruitSeq) {
    const response = await api.delete('/api/jobSearching/deleteInter', {
        params : {
            recruitSeq: recruitSeq
        }
    })

    return response
}

export async function getUserInter() {
    const response = await api.get('/api/jobSearching/getUserInter');

    return response;
}

export async function getRecruitListByInterest() {
    const response = await api.get('/api/jobSearching/getRecruitListByInterest');

    return response;
}

export async function jobRecom() {
    const response = await api.post('/api/job/jobRecom');

    return response;
}

export async function plusViewCount(recruitSeq) {
    const response = await api.put('/api/jobSearching/plusViewCount', null, {
        params: {
            recruitSeq: recruitSeq
        }
    })

    return response;
}

export async function getResumeDesire() {
    const response = await api.get('/api/resume_new/getResumeDesire');

    return response;
}

export async function applyAnnouncement(recruitSeq, resumeSeq, companySeq) {
    const response = await api.post('/api/jobSearching/applyAnnouncement', null, {
        params: {
            recruitSeq: recruitSeq,
            resumeSeq: resumeSeq,
            companySeq: companySeq
        }
    })

    return response;
}

export async function getAnnouncementCount(recruitSeq) {
    const response = await api.get('api/jobSearching/getAnnouncementCount', {
        params: {
            recruitSeq: recruitSeq
        }
    })

    return response;
}

export async function getRecruitByRecruitseq(recruitSeq) {
    const response = await api.get('api/jobSearching/getRecruit', {
        params: {
            recruitSeq: recruitSeq
        }
    })

    return response;
}