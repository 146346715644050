import api from "../../../api/api";
import React from "react";

const DeleteModal = (props) => {
    const {setDeleteModal, applicationList, setApplicationList, deleteSeq} = props;

    const deleteRecruit = (recruitSeq) => {
        // const userConfirmed = window.confirm("공고를 삭제하시겠습니까?");
        // if (userConfirmed) {
        try {
            api.post(`/api/comp/matching/deleteRecruit?recruitSeq=${recruitSeq}`)
                .then(response => response.data)
                .then((response) => {
                    if (response && response.resultCode === 1000 && response.resultData) {
                        if(applicationList) {
                            const updatedRecruitData = applicationList.filter(item => item.seq !== recruitSeq);
                            setApplicationList(updatedRecruitData);
                            setDeleteModal(() => false);
                        } else {
                            alert("삭제되었습니다.");
                            window.location.href="/comp/jobRequest/jobApplicationList";
                            setDeleteModal(() => false);
                        }
                        
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        } catch (e) {
            console.log(e);
        }
        // }
    };

    return (
        <React.Fragment>
            <div className="popup_mask" style={{display: "block"}}></div>
            <div className="popup_layer popup_small" id="popWarning">
                <div className="popup_inner">
                    <div className="popup_contents">
                        <p>해당 공고 삭제 시 내용 및 지원 인재 정보도 함께<br />삭제되며, 복구될 수 없습니다. 삭제하시겠습니까?</p>
                        <div className="popup_small_btn_wrap">
                            <a className="btn_submit filled_black" onClick={() => setDeleteModal(false)}>취소</a>
                            <a className="btn_submit filled_green" onClick={() => deleteRecruit(deleteSeq)} >삭제</a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DeleteModal;

