export default function NoticeTemplate({ title, list = [] }) {
	return (
		<div className='notice-template'>
			<h4>{title}</h4>

			<div className='notice-template-content'>
				{list.map(i => <li key={i}>{i}</li>)}
			</div>
		</div>
	)
}