import { useLocation, useHistory } from "react-router-dom";
import api from "../../../api/api";

const SocialLoginRedirect = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const provider = searchParams.get("provider");
  const history = useHistory();
  if (code) {
    api
      .get(`/api/v1/auth/${provider}/getToken?code=${code}`)
      .then((response) => {
        let access_token = response.data.access_token;
        api
          .post(
            `/api/v1/auth/${provider}/getProfile?access_token=${access_token}`
          )
          .then((response) => {
            let user;
            let redirect_uri = response.data.redirect_uri;
            if (provider === "kakao") {
              user = response.data.id;
            } else if (provider === "naver") {
              user = response.data.response.id;
            } else if (provider === "google") {
              user = response.data.id;
            } else if (provider === "facebook") {
              user = response.data.id;
            }
            if (redirect_uri.indexOf("login") > 0) {
              const formData = {
                id: user,
                password: "NO_PASS",
              };
              api.post("/api/v1/auth/login", formData).then((response) => {
                if (response.data.resultCode != 9000) {
                  const token = response.data.resultData;
                  localStorage.setItem("accessToken", token);

                  const headers = {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  };
                  const userPromise = api.get("/api/v1/users", headers);
                  const seqNoPromise = api.get("/api/v1/getSeqNo", headers);

                  Promise.all([userPromise, seqNoPromise])
                    .then(([userResponse, seqNoResponse]) => {
                      const user = userResponse.data.resultData;
                      const { username, providerType, userId, roleType } = user;
                      const userData = {
                        username,
                        providerType,
                        userId,
                        roleType,
                      };
                      localStorage.setItem("user", JSON.stringify(userData));
                      localStorage.setItem("userId", userData.userId);
                      const mem_seq = seqNoResponse.data.resultData;
                      localStorage.setItem("mem_seq", mem_seq);

                      window.location.href = "/";
                    })
                    .catch((error) => {
                      console.error("에러 발생:", error);
                    });
                } else {
                  alert("회원가입 계정이 아닙니다. 회원가입을 완료해주세요.");
                  window.location.href = "/";
                }
              });
            } else {
              if (redirect_uri.indexOf("interJoin") > 0) {
                localStorage.setItem("id", user);
                localStorage.setItem("providerType", provider);
              } else {
                try {
                  api
                    .get("/api/signup/countId", {
                      params: { id: user },
                    })
                    .then((response) => {
                      if (response.data.resultData !== 0) {
                        alert("해당 SNS의 계정이 존재합니다. 로그인 해주세요.");
                        window.location.href = "/";
                      } else {
                        history.push({
                          pathname: "/user/signup/snssignup",
                          state: { providerType: provider, id: user },
                        });
                      }
                    });
                } catch (error) {
                  console.log(error);
                  window.location.href = "/";
                }
              }
            }
          });
      })
      .catch((error) => {
        console.error("Error:", error);
        window.location.href = "/";
      });
  } else {
    alert("회원가입 계정이 아닙니다. 회원가입을 완료해주세요.");
    window.location.href = "/";
  }
  return (
    <div>
      <div className="wrapper">
        <main>
          <div className="page page_recommend page_layout_40">
            <div className="page_inner">
              <div className="applicant_list">
                <div className="wrap_accodian">
                  <div style={{ textAlign: "center" }}>
                    <h3>잠시만 기다려주세요.</h3>
                    <div>
                      <img
                        src="/img/icon/Spiner.gif"
                        alt="로딩중"
                        style={{ width: "200px", margin: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SocialLoginRedirect;
