import PictureQuizPreview from "./PictureQuiz/PictureQuizPreview";
import BlockQuizPreview from "./BlockQuiz/BlockQuizPreview";
import EmotionQuizPreview from "./EmotionQuiz/EmotionQuizPreview";
import CardQuizPreview from "./CardQuiz/CardQuizPreview";
import PictureQuiz from "./PictureQuiz/PictureQuiz";
import EmotionQuiz from "./EmotionQuiz/EmotionQuiz";
import CardQuiz from "./CardQuiz/CardQuiz";
import { getImageUrl } from "../../utils/common";
import BlockQuiz from "./BlockQuiz/BlockQuiz";

export const INITIAL_COUNTDOWN_SECONDS = 600;

export default function Games({
  cateType,
  cateIdx,
  nextPage,
  values,
  isModalOpen,
  openModal,
  setPrevPageButtonProps,
  setNextPageButtonProps,
  handleGamesValueChange,
}) {
  return (
    <>
      {cateType === "SituationPreview" && (
        <PictureQuizPreview cateIdx={cateIdx} nextPage={nextPage} />
      )}
      {cateType === "BlockPreview" && (
        <BlockQuizPreview cateIdx={cateIdx} nextPage={nextPage} />
      )}
      {cateType === "EmotionPreview" && (
        <EmotionQuizPreview cateIdx={cateIdx} nextPage={nextPage} />
      )}
      {cateType === "ThinkingPreview" && (
        <CardQuizPreview cateIdx={cateIdx} nextPage={nextPage} />
      )}
      {values &&
        cateType === "Situation" &&
        values.map((i) => {
          return (
            <PictureQuiz
              key={i.problemSituationId}
              defaultValue={i.arrayScore}
              image={getImageUrl(i.problemSituationImage)}
              list={[
                i.problemSituationText1,
                i.problemSituationText2,
                i.problemSituationText3,
                i.problemSituationText4,
              ]}
              onChange={handleGamesValueChange}
              isModalOpen={isModalOpen}
              openModal={openModal}
              setPrevPageButtonProps={setPrevPageButtonProps}
              setNextPageButtonProps={setNextPageButtonProps}
            />
          );
        })}
      {values &&
        cateType === "Emotion" &&
        values.map((i) => (
          <EmotionQuiz
            key={i.problemEmotionImage}
            defaultValue={i.arrayScore}
            image={getImageUrl(i.problemEmotionImage)}
            list={["기쁨", "놀람", "슬픔", "분노"]}
            onChange={handleGamesValueChange}
            isModalOpen={isModalOpen}
            openModal={openModal}
            setPrevPageButtonProps={setPrevPageButtonProps}
            setNextPageButtonProps={setNextPageButtonProps}
          />
        ))}
      {values &&
        cateType === "Thinking" &&
        values.map((i) => (
          <CardQuiz
            key={i.problems[0].problemThinkingImages[0]}
            problems={i.problems}
            defaultValue={i.arrayScore}
            onChange={handleGamesValueChange}
            isModalOpen={isModalOpen}
            openModal={openModal}
            setPrevPageButtonProps={setPrevPageButtonProps}
            setNextPageButtonProps={setNextPageButtonProps}
          />
        ))}
      {values &&
        cateType === "Block" &&
        values.map((i) => {
          return (
            <BlockQuiz
              key={i.problemBlockId}
              defaultValue={i.arrayScore}
              nodes={i.problemBlocks}
              onChange={handleGamesValueChange}
              isModalOpen={isModalOpen}
              openModal={openModal}
              setPrevPageButtonProps={setPrevPageButtonProps}
              setNextPageButtonProps={setNextPageButtonProps}
            />
          );
        })}
    </>
  );
}
