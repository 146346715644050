import React, { useState, useEffect } from 'react';
import api, { decodeToken } from '../../../api/api';
import Pagination from "../../../api/Pagination";
import jwtDecode from "jwt-decode";

const CurrentLectureList = ({lectures}) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(4);
    const [visiblePageCount, setVisiblePageCount] = useState(10);
    const offset = (page - 1) * limit;
    const lectureLink = (e,classId) =>{
        e.preventDefault();
        api.get('/api/lms/makeLinkParam').then(response => response.data).then((response) => {
            let url = "";
            url = response.resultData;
            url += '&class_id='+classId+'&page=class_intro';
            window.open(url,"didisam");
        })
    };
    return (       
        <>
        <div className="box_wrap_col">
            {
                lectures && lectures.slice(offset,offset+limit).map((value, j)=>{
                    return (
                        <a href="#" target="_self" className="content_box_col" onClick={(e) => lectureLink(e,value.class_id)}  key={j}>
                            <p className="con_title">
                                <span>{value.title}</span>
                                {
                                    value.teachers && (
                                        <>
                                        / <span>{value.teachers[0].name}</span>
                                        </>
                                    )
                                }
                            </p>
                            <div className="content_wrap">
                                <span className="thumb_img">
                                    <img src={value.thumbnail_image} alt={value.title} />
                                </span>
                                { value.attend && (
                                    <>
                                        <div className="text_wrap">
                                            <div className="date_count">
                                                <p>수강기간 {Math.ceil(Math.abs(new Date(value.attend.period_end_at).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))}일 남음</p>
                                                {/*<span>인천지식재산센터</span>*/}
                                            </div>
                                            <p className="contents_count"><span>{value.attend.complete_count}</span> / <span>{value.attend.lecture_count}</span></p>                                               
                                            <div className="wrap_progress">
                                                <p>수강완료 강의 수</p>                                                   
                                                <div className="box_progress">                                                       
                                                    <p className="progress_txt"><span>{value.attend.complete_percentage}</span>% 수강 중</p>
                                                    <progress value={value.attend.complete_percentage} max="100"></progress>
                                                </div>
                                            </div>        
                                        </div>
                                    </>
                                )}  
                            </div>                                                          
                        </a>
                    )
                })
            }
        </div>
        {
            lectures.length > limit && (
                <div className="wrap_pagination">
                    <Pagination
                        total={lectures.length}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                        visiblePageCount={visiblePageCount}
                    />
                </div>
            )
        } 
    
    </>
    )
};

const LectureList = ({lectures}) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(4);
    const [visiblePageCount, setVisiblePageCount] = useState(10);
    const offset = (page - 1) * limit;
    const lectureLink = (e,classId) =>{
        e.preventDefault();
        api.get('/api/lms/makeLinkParam').then(response => response.data).then((response) => {
            let url = "";
            url = response.resultData;
            url += '&class_id='+classId+'&page=class_intro';
            window.open(url,"didisam");
        })
    };
    return (    
        <>
            <div className="box_wrap_row">
                {
                    lectures && lectures.slice(offset,offset+limit).map((value, j)=>{
                        return (
                            <a href="#" target="_self" className="content_box_row" onClick={(e) => lectureLink(e,value.class_id)}  key={j}>
                                <span className="thumb_img">
                                    <img src={value.thumbnail_image} alt={value.title} />
                                </span>
                                <div className="text_wrap">
                                    <span>{value.title}</span>{value.teachers && (<p>{value.teachers[0].name}</p>)}
                                </div>                                                        
                            </a>
                        )
                    })
                }
            </div>            
            {
                lectures.length > limit && (
                    <div className="wrap_pagination">
                        <Pagination
                            total={lectures.length}
                            limit={limit}
                            page={page}
                            setPage={setPage}
                            visiblePageCount={visiblePageCount}
                        />
                    </div>
                )
            } 
        </>
    )
};

const LectureResult = () => {
    const [result, setResult] = useState(false);   
    const [current, setCurrent] = useState(false);   
    const [complete, setComplete] = useState(false);   
    const [late, setLate] = useState(false);    
    useEffect(() => {
        api.post('/api/lms/myClassList').then(response => response.data)
            .then((response) => {
                if(response && response.resultCode == 1000){
                    setResult(response.resultData);                    
                } else {
                    //alert("일치하는 정보가 없습니다.");
                }
            }
        );
        
    }, []);
    useEffect(() => {
        setCurrent(result.current);
        setComplete(result.complete);
        setLate(result.late);        
    }, [result]);
    return (
        <div className="page_inner basic_page_inner">
            {/* 현재 수강중인 CLASS start */}
            <div className="area_shade area_recommend">
                <div className="wrap_section_title">
                    <h3 className="">현재 수강중인 CLASS</h3>
                    {/*
                        current && current != undefined && current.length>0   && (<a href="#" target="_self" className="job_more_anchor">현재 수강중인 CLASS 더보기</a>)
                    */}
                </div>
                <div className="profile_tab_con profile_tab_con_4">   
                    <div className="popup_contents">  
                        {
                            current && current != undefined && current.length>0  ? <CurrentLectureList lectures={current}/>: (
                                <p className="contents_null_text">수강이력이 없습니다.</p>       
                            )
                        }
                    </div> 
                </div>
            </div>
            {/* 현재 수강중인 CLASS start */}
            <div className="area_shade area_recommend">
                <div className="wrap_section_title">
                    <h3 className="">지난 수강완료 CLASS</h3>
                    {/*
                        complete && complete != undefined && complete.length>0 && (<a href="#" target="_self" className="job_more_anchor">지난 수강완료 CLASS 더보기</a>)
                    */}
                </div>
                <div className="profile_tab_con profile_tab_con_4">   
                    <div className="popup_contents">  
                        {
                            complete && complete != undefined && complete.length>0 ? <LectureList lectures={complete}/>: (
                                <p className="contents_null_text">수강이력이 없습니다.</p>       
                            )
                        }
                    </div> 
                </div>
            </div>
            {/* 현재 수강중인 CLASS start */}
            <div className="area_shade area_recommend">
                <div className="wrap_section_title">
                    <h3 className="">수강기한 마감 CLASS</h3>
                    {/*
                        late && late != undefined && late.length>0 && (<a href="#" target="_self" className="job_more_anchor">수강기한 마감 CLASS 더보기</a>)
                    */}
                </div>
                <div className="profile_tab_con profile_tab_con_4">   
                    <div className="popup_contents">  
                        {
                            late && late != undefined && late.length>0? <LectureList lectures={late}/>: (
                                <p className="contents_null_text">수강이력이 없습니다.</p>       
                            )
                        }
                    </div> 
                </div>
            </div>
        </div>        
    )
};
const MyLecture = () => {
    return (
        <div>            
            <div className="wrapper">
                {/* 본문 */}
                <main>
                    <div className="page page_recommend page_layout_40">
                        <div className="page_back_title">
                            <h2 className="page_back_title border_bottom_line">
                                내 강의실
                            </h2>                  
                        </div>
                        <LectureResult />
                    </div>
                </main>
            </div>    
        </div>
    )
}

export default MyLecture;