import React from "react";

const SelfIntroduction = ({register,errors, readOnly}) => {
    return (
        <div className="wrap_signup_input wrap_signup_basic">
            <div className="box_sub_title">
                <h4 className="sub_title_signup">자기소개서</h4>
            </div>
            <div className="box_self_info">
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">제목</label>
                        <input readOnly={readOnly} maxLength={51} type="text" id="introduction.introduction_title" name="introduction.introduction_title" className=""
                               placeholder="자기소개서 제목을 입력해주세요." {...register('introduction.introduction_title', {
                            required: '필수 입력 항목입니다.',
                            maxLength:{value:50,message:"최대값을 50자 입니다."}

                        })}></input>
                    </div>
                    <p className="msg_err">{errors?.introduction?.introduction_title?.message}</p>
                </div>
                <div className="wrap_box_input">
                    <div className="box_input ">
                        <label htmlFor="" className="asterisk">내용</label>
                        <textarea readOnly={readOnly} maxLength={2001} name="introduction.introduction_content" id="introduction.introduction_content" placeholder="자기소개서 내용을 입력해주세요." {...register('introduction.introduction_content', {
                            required: '필수 입력 항목입니다.',
                            maxLength:{value:2000,message:"최대값을 2000자 입니다."}
                        })}></textarea>
                    </div>
                    <p className="msg_err">{errors?.introduction?.introduction_content?.message}</p>
                </div>
            </div>
        </div>

    )

}

export default SelfIntroduction;