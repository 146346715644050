import React from "react";

const BackModal = (props) => {
    const { setBackModal, link, ment } = props;

    return (
        <>
            <div className="popup_mask" onClick={() => setBackModal(false)} style={{display : "block"}}></div>
            <div className={"popup_layer pop_lg popLResultEvaluation"} style={{width:"510px", display : "block"}} id="popLResultEvaluation">
                <div className="popup_inner" style={{paddingTop : "10px", paddingBottom : "25px"}}>
                    <div className="pop_title line_under">
                        <button type="button" aria-label="레이어 팝업 닫기" className="popup_close" onClick={() => setBackModal(false)} style={{marginTop : 0}} ></button>
                    </div>
                    <div className="popup_contents" style={{paddingBottom : 0}} >
                        {ment}
                        <div className="wrap_btns" >
                            <div className="back_or_next">
                                <a href={link} className="btn_submit filled_green"  style={{width : "220px"}}>확인</a>
                                <button onClick={() => setBackModal(false)} className="btn_submit lined_green" style={{width : "220px"}}>취소</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BackModal;