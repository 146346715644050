import React from "react";

const ComputerSkill = ({register, readOnly}) => {
    return (<div className="wrap_signup_input wrap_signup_basic">
            <div className="box_sub_title">
                <h4 className="sub_title_signup">컴퓨터 활용 능력</h4>
            </div>
            <div className="box_computer_info">
                <div className="box_division_radio">
                    <p>워드</p>
                    <div className="wrap_box_radio">
                        <div className="input_radio">
                            <label htmlFor="temp11" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp11" readOnly={readOnly} disabled={readOnly}
                                       name="word" value="3" {...register('comskill.word_ability')}></input>
                                상</label>
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp12" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp12" readOnly={readOnly} disabled={readOnly}
                                       name="word" value="2"  {...register('comskill.word_ability')}></input>
                                중</label>
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp13" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp13" readOnly={readOnly} disabled={readOnly}
                                       name="word" value="1" {...register('comskill.word_ability')}></input>
                                하</label>
                        </div>
                    </div>
                </div>
                <div className="box_division_radio">
                    <p>엑셀</p>
                    <div className="wrap_box_radio">
                        <div className="input_radio">
                            <label htmlFor="temp14" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp14" readOnly={readOnly} disabled={readOnly}
                                       name="excel" value="3"{...register('comskill.excel_ability')}></input>
                                상</label>
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp15" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp15" readOnly={readOnly} disabled={readOnly}
                                       name="excel" value="2" {...register('comskill.excel_ability')}></input>
                                중</label>
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp16" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp16" readOnly={readOnly} disabled={readOnly}
                                       name="excel" value="1" {...register('comskill.excel_ability')}></input>
                                하</label>
                        </div>
                    </div>
                </div>
                <div className="box_division_radio">
                    <p>파워포인트</p>
                    <div className="wrap_box_radio">
                        <div className="input_radio">
                            <label htmlFor="temp17" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp17" readOnly={readOnly} disabled={readOnly}
                                       name="ppt" value="3" {...register('comskill.ppt_ability')}></input>
                                상</label>
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp18" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp18" readOnly={readOnly} disabled={readOnly}
                                       name="ppt" value="2" {...register('comskill.ppt_ability')}></input>
                                중</label>
                        </div>
                        <div className="input_radio">
                            <label htmlFor="temp19" className="label_txt">
                                <input type="radio" className="btn_type_radio" id="temp19" readOnly={readOnly} disabled={readOnly}
                                       name="ppt" value="1"  {...register('comskill.ppt_ability')}></input>
                                하</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>)

}
export default ComputerSkill;