import { useGameTimer } from "../../../hooks/useGameTimer";
import { getImageUrl } from "../../../utils/common";
import NoticeTemplate from "../common/NoticeTemplate";
import Tip from "../common/Tip";
import { INITIAL_COUNTDOWN_SECONDS } from "../games";

export default function EmotionQuizPreview({ cateIdx, nextPage }) {
  const { GameTimerComponent } = useGameTimer({
    initialDurationInSeconds: INITIAL_COUNTDOWN_SECONDS,
  });

  return (
    <div className="quiz-preview">
      <NoticeTemplate
        title="게임형 평가 시작전 안내사항"
        list={[
          "1. 문항형 평가와 달리 게임형 평가는 시간제한이 있으며 멈춤없이 진행됩니다.",
          "2. 문항형 평가와 달리 게임형 평가는 해당문제의 제한시간 종료후 [다음으로] 기능이 활성화되며, 답변수정 불가하니 신중한 답변을 바랍니다.",
        ]}
      />

      <GameTimerComponent />

      <div className="quiz flex">
        <div className="quiz-assets">
          <img
            src={getImageUrl("/game/emotion/A/A_preview.webp")}
            alt="/game/emotion/A/A_preview.webp"
          />
        </div>

        <div className="quiz-answer">
          <h3>
            Q. 주어진 그림을 확인하여 아래의 올바른 감정카드 버튼을 클릭하세요.
          </h3>

          <div className="emotion-buttons">
            {["기쁨", "놀람", "슬픔", "분노"].map((emotion) => (
              <button className={`btn_submit`} key={emotion}>
                {emotion}
              </button>
            ))}
          </div>
          <div className="wrap_btns">
            <button onClick={nextPage} className="btn_submit filled_black">
              게임시작
            </button>
          </div>
        </div>
      </div>

      <Tip top={165} left={70} arrowDirection="top-right">
        제한시간 1분씩 총 10문제가
        <br />
        진행되오니 주의하세요.
      </Tip>

      <Tip top={180} right={-150} arrowDirection="left-center">
        주어진 인물표정과 어울리는 감정카드 버튼을 마우스
        <br />
        클릭 시 선택 효과가 나타나며, 다른 감정카드 버튼으로
        <br />
        재클릭 시 해당버튼으로 선택 수정됩니다.
      </Tip>

      <Tip top={375} right={110} arrowDirection="left-top">
        게임 규칙을 이해 완료하였으면
        <br />
        버튼 클릭 후 게임을 시작하세요.
      </Tip>

      {Boolean(cateIdx) && (
        <Tip bottom={-65} right={200} arrowDirection="bottom-right">
          제한시간 이후 버튼 클릭 가능하며,
          <br />
          입력한 답변 확인은 가능하나 답변
          <br />
          수정은 불가합니다.
        </Tip>
      )}

      <Tip bottom={-65} right={-100} arrowDirection="bottom-left">
        버튼 클릭 전까지 입력값 수정
        <br />
        가능하며, 제한시간이 종료된
        <br />
        경우 정답 입력 및 수정이
        <br />
        불가능합니다.
      </Tip>
    </div>
  );
}
