import React from 'react';
import api from '../../../api/api';


const ConfirmModal = (props) => {
    const closeModal = () => {
        props.setModal(false);
    }

    const deleteItem = () => {
        api.post("/api/comp/interview/deleteItem",{"agency_seq" : props.agcNum})
        .then(response => response.data)
        .then((response) => {
            if(response && response.resultCode == 1000){
                alert("삭제 완료");
            } else {
                alert("삭제 에러");
            }
        })
        closeModal();
    }
    return (
        <>
        <div className="popup_mask" style={{"display" : "block"}}></div>
        <div className="popup_layer popup_small" id="popWarning">
            <div className="popup_inner">          
                <div className="popup_contents">
                    <p>해당 템플릿 삭제 시 복구될 수 없습니다.<br/>삭제하시겠습니까? </p>
                    <div className="popup_small_btn_wrap">
                        <a href="javascript:;" target="_self" className="btn_submit filled_black" onClick={(e) => {e.preventDefault(); closeModal();}}>취소</a>
                        <a href="javascript:;" target="_self" className="btn_submit filled_green" onClick={(e) => {e.preventDefault(); deleteItem();}}>삭제</a>
                    </div>
                </div>    
            </div>
        </div>
        </>
    )
};

export default ConfirmModal;