import { useEffect, useState } from "react";
import api, { decodeToken } from "../../../api/api";

const JobMain = () => {
  const dToken = decodeToken(localStorage.getItem("accessToken"));
  const [category, setCategory] = useState([
    {
      category: String,
      cateSeq: String,
    },
  ]);
  const [cateSeq, setCateSeq] = useState([]);
  const [result, setResult] = useState([]);
  let count = 0;
  useEffect(() => {
    api
      .post("/api/job/JobMatching", {
        member_id: dToken && dToken.sub,
      })
      .then((response) => response.data)
      .then((response) => {
        const cate = [];
        const seq = [];
        console.log(response);
        if (response.resultCode === 1000) {
          response.resultData.data.map((item, i) => {
            if (item.total_score > 0) {
              cate.push({
                category:
                  item.depth1 +
                  " > " +
                  item.depth2 +
                  " > " +
                  item.depth3 +
                  " > " +
                  item.depth4,
                cateSeq:
                  (item.depth1 && item.depth1.substring(0, 2)) +
                  (item.depth2 && item.depth2.substring(0, 2)) +
                  (item.depth3 && item.depth3.substring(0, 2)) +
                  (item.depth4 && item.depth4.substring(0, 2)),
              });
              seq.push({
                cate_seq:
                  (item.depth1 && item.depth1.substring(0, 2)) +
                  (item.depth2 && item.depth2.substring(0, 2)) +
                  (item.depth3 && item.depth3.substring(0, 2)) +
                  (item.depth4 && item.depth4.substring(0, 2)),
              });
            }
          });
          if (cate.length > 0) {
            setCategory(cate);
            setCateSeq(seq);
          } else {
            api
              .post("/api/job/getDesiredJob")
              .then((response) => response.data)
              .then((response) => {
                if (response.resultCode === 1000) {
                  response.resultData.map((item, i) => {
                    cate.push({
                      category:
                        item.depth1_name +
                        " > " +
                        item.depth2_name +
                        " > " +
                        item.depth3_name +
                        " > " +
                        item.depth4_name,
                      cateSeq:
                        item.depth1 + item.depth2 + item.depth3 + item.depth4,
                    });
                    seq.push({
                      cate_seq:
                        item.depth1 + item.depth2 + item.depth3 + item.depth4,
                    });
                  });
                  setCategory(cate);
                  setCateSeq(seq);
                } else {
                  alert(
                    "데이터를 가져오는 중에 오류가 발생하였습니다. 관리자에게 문의해주세요."
                  );
                }
              });
          }
        } else {
          api
            .post("/api/job/getDesiredJob")
            .then((response) => response.data)
            .then((response) => {
              if (response.resultCode === 1000) {
                response.resultData.map((item, i) => {
                  cate.push({
                    category:
                      item.depth1_name +
                      " > " +
                      item.depth2_name +
                      " > " +
                      item.depth3_name +
                      " > " +
                      item.depth4_name,
                    cateSeq:
                      item.depth1 + item.depth2 + item.depth3 + item.depth4,
                  });
                  seq.push({
                    cate_seq:
                      item.depth1 + item.depth2 + item.depth3 + item.depth4,
                  });
                });
                setCategory(cate);
                setCateSeq(seq);
              } else {
                alert(
                  "데이터를 가져오는 중에 오류가 발생하였습니다. 관리자에게 문의해주세요."
                );
              }
            });
        }
      });
  }, []);

  useEffect(() => {
    api
      .post("/api/resume/getACateList", cateSeq)
      .then((response) => response.data)
      .then((response) => {
        response && response.resultData && setResult(response.resultData);
      });
  }, [category, cateSeq]);
  return (
    <div>
      <div className="wrapper">
        <main>
          <div className="page recommend page_recommend page_layout_40">
            <h2 className="page_back_title page_inner_above">직무추천</h2>

            <div className="page_inner">
              <div className="area_shade area_recommend">
                {category && category.length > 0 ? (
                  <>
                    <div className="wrap_section_title">
                      <h3 className="">종합정보 기반 직무 추천</h3>
                      <a
                        href="/user/job/recruitInfo"
                        target="_self"
                        className="job_more_anchor"
                      >
                        채용정보 바로가기
                      </a>
                    </div>
                    <div className="wrap_recommend">
                      <ul className="recommend_list">
                        {category &&
                          category.map(
                            (item, i) =>
                              // rItem.result 값이 없는 경우 해당 항목 건너뛰기
                              result &&
                              result.some(
                                (rItem) =>
                                  rItem.cate_seq === item.cateSeq &&
                                  rItem.result.length > 0
                              ) &&
                              count < 5 && (
                                <li key={i} className="recommend_item">
                                  <div className="recommend_tit">
                                    <h4>
                                      <span>{++count}순위</span>
                                      {item.category}
                                    </h4>
                                  </div>
                                  <ul className="recommend_cnt_list">
                                    {result &&
                                      result.map(
                                        (rItem, j) =>
                                          rItem.cate_seq === item.cateSeq &&
                                          rItem.result &&
                                          rItem.result.map(
                                            (rrItem, k) =>
                                              k < 10 && (
                                                <li
                                                  key={k}
                                                  className="recommend_cnt_item"
                                                >
                                                  {rrItem.cate_nm}
                                                </li>
                                              )
                                          )
                                      )}
                                  </ul>
                                </li>
                              )
                          )}
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="wrap_section_title">
                      <h3 className="">종합정보 기반 직무 추천</h3>
                    </div>
                    <div className="wrap_recommend">
                      <ul className="recommend_list">
                        <li className="recommend_item">
                          <div className="recommend_tit">
                            <h4>직무 추천 결과가 없습니다.</h4>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default JobMain;
