import React from 'react';
import {useLocation } from "react-router-dom";

const LmsError = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const errorCode = params.get('code');
    const trace = params.get('trace');
    console.log(errorCode);
    return (
        <div>            
            <div className="wrapper">
                {/* 본문 */}
                <main>
                    <div className="page page_recommend page_layout_40">                        
                        {/*
                            errorCode == 20005 ? 
                                <div className="page_back_title">
                                    <h2 className="page_back_title border_bottom_line">
                                        errorCode 
                                    </h2>                  
                                    <div className="page_inner">
                                        <div className="applicant_list">     
                                            <p>접근 권한이 없어 강의를 불러올 수 없습니다.</p>   
                                            <br/>                         
                                            <p>관리자에게 문의해주세요. </p>   
                                        </div>                  
                                    </div>
                                </div>
                                :
                                errorCode == 20012 ?
                                    <div className="page_back_title">
                                        <h2 className="page_back_title border_bottom_line">
                                        디디쌤 자동가입에 실패했습니다
                                        </h2>                  
                                        <div className="page_inner">
                                            <div className="applicant_list">     
                                                <p>회원 가입 중 오류가 발생하였습니다.</p>  
                                                <br/>                         
                                                <p>잠시 후 다시 시도 부탁드립니다.</p>                           
                                            </div>                  
                                        </div>
                                    </div>
                                    :
                                    errorCode == 20000 ?
                                        <div className="page_back_title">
                                            <h2 className="page_back_title border_bottom_line">
                                                디디쌤과의 연동에 실패했습니다.
                                            </h2>                  
                                            <div className="page_inner">
                                                <div className="applicant_list">                                  
                                                    <p>잠시 후 다시 시도 부탁드립니다.</p>                           
                                                </div>                  
                                            </div>
                                        </div>
                                        :
                                        <div className="page_back_title">
                                            <h2 className="page_back_title border_bottom_line">
                                                시스템 에러가 발생했습니다.
                                            </h2>                  
                                            <div className="page_inner">
                                                <div className="applicant_list">          
                                                    <p>잠시 후 다시 시도 부탁드립니다.</p>                        
                                                </div>                  
                                            </div>
                                        </div>
    */}
                        <div className="page_back_title">
                            <h2 className="page_back_title border_bottom_line">
                                시스템 오류가 발생했습니다. 
                            </h2>                  
                            <div className="page_inner">
                                <div className="applicant_list">      
                                    <p>errorCode : {errorCode}</p>    
                                    <br/>
                                    <p>{trace}</p>                        
                                </div>                  
                            </div>
                        </div>
                    </div>
                </main>
            </div>    
        </div>        
    )
}

export default LmsError;