import React, {useState} from "react";
import PopupPolicy from './PopupPolicy';
import PopupTerms from './PopupTerms';
const Footer = () => {

    const [policyModal, setPolicyModal] = useState(false);
    const [termsModal, setTermsModal] = useState(false);
    return (
        <footer>
            <div className="wrap_footer">
                {/*
                <div className="area_left">
                    <div className="box_logo">
                        <a href="#" target="_self" className="">
                            <img src="/img/logo/vlogow.png" alt=""/>
                        </a>
                    </div>
                    <div className="box_text">
                        <p>대표번호</p>
                        <h2>1644-8619</h2>
                    </div>
                </div>
                */}
                <div className="area_right">
                    <div className="wrap_terms">
                        <div className="box_element">
                            <ul className="terms_list">
                                <li className="terms_item">
                                    <a href="#" target="_self" className="terms_anchor" onClick={(e) => {e.preventDefault(); setPolicyModal(true);}}>개인정보 처리방침</a>
                                </li>
                                <li className="terms_item">
                                    <a href="/terms" target="_self" className="terms_anchor" onClick={(e) => {e.preventDefault(); setTermsModal(true);}}>이용약관</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <p style={{color: 'white'}}>version</p>
                    {/*
                    <div className="wrap_text">
                        <div className="area_footer_text">
                            <ul className="footer_text_list">
                                <li className="footer_text_item">
                                    <p>(주)브이드림</p>
                                </li>
                                <li className="footer_text_item">
                                    <p>대표이사 : 김민지</p>
                                    <p className="line_left">사업자등록번호 : 186-86-00972</p>
                                </li>
                                <li className="footer_text_item">
                                    <p>FAX : 051-711-8573</p>
                                    <p className="line_left">victory@vdream.co.kr</p>
                                </li>
                                <li className="footer_text_item">
                                    <p>대표번호 1644-8619</p>
                                </li>
                            </ul>
                            <ul className="footer_text_list">
                                <li className="footer_text_item">
                                    <p>본사</p>
                                    <p className="line_left">부산 동구 중앙대로 216, 교원 부산빌딩 13층</p>
                                </li>
                                <li className="footer_text_item">
                                    <p>서초 드림센터</p>
                                    <p className="line_left">서울특별시 서초구 반포대로 18길 36, 서초센트럴 IPARK 오피스동 7층</p>
                                </li>
                                <li className="footer_text_item">
                                    <p>© 2021. V.Dream. All rights reserved.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    */}
                </div>
            </div>
            {
                policyModal && <PopupPolicy setModal={setPolicyModal}/>
            }
            {
                termsModal && <PopupTerms setModal={setTermsModal}/>
            }
        </footer>
    )

}

export default Footer;