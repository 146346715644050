import React, {Component, useEffect, useState} from 'react';
import EnterpriseInfo from "./EnterpriseInfo";
import IncruitInfo from "./IncruitInfo";
import WorkCondition from "./WorkCondition";
import Disability from "./Disability";
import {useForm} from "react-hook-form";
import api from "../../../api/api";
import {useHistory} from "react-router-dom";
import {getCompInfo, uploadImage} from "../../../api/Comp/CompSignup/CompSignup";
import {CompanyRequestInit} from "./Init/CompanyInit";


const JobRequestForm = () => {
    const methods = useForm({
        mode: 'onChange',
        defaultValues: CompanyRequestInit
    });
    const {
        register,
        setValue,
        watch,
        handleSubmit,
        control,
        reset,
        resetField,
        trigger,
        setError,
        remove,
        setFocus,
        formState: {errors}
    } = methods
    const watchField = watch()
    const jobRequestData = new FormData();
    const history = useHistory();

    const [address, setAddress] = useState(null);
    const [compImage, setCompImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [postFile, setPostFile] = useState(null);
    

    useEffect(() => {
        try {
            getCompInfo().then((response) => {
                if (response.data.resultCode === 1000 && response.data.resultData) {
                    const info = response.data.resultData;
                    const compAddress = response.data.resultData.comp_member_info.address;
                    const addressArray = compAddress.split('|');
                    setAddress(addressArray);
                    
                    setValue("enterprise.enterprise_nm",info.comp_member_info.comp_nm);
                    setValue("enterprise.manager_nm",info.comp_member_info.manager_nm);
                    setValue("enterprise.tel_no", info.comp_member_info.manager_tel);
                    setValue("enterprise.enterprise_email",info.comp_member_info.email);
                    setValue("comInfo.file_path", info.comp_member_info.rep_file_path)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }, []);

    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        if (!isSubmit) return
        AlertError()
        setIsSubmit(false)
    }, [errors, isSubmit])

    const AlertError = () => {
        if(errors?.enterprise?.enterprise_nm){
            alert("기업이름을 확인 하세요");
        }else if(errors?.enterprise?.manager_nm){
            alert("관리자이름을 확인 하세요");
        }else if(errors?.enterprise?.tel_no){
            alert("대표번호을 확인 하세요");
        }else if(errors?.enterprise?.enterprise_email){
            alert("기업 이메일을 확인 하세요");
        }else if(errors?.enterprise?.homepage_url){
            alert("기업 홈페이지 주소를 확인 하세요");
        }else if(errors?.enterprise?.enterprise_info){
            alert("기업정보를 확인 하세요");
        }else if(errors?.incruit?.recruit_name){
            alert("공고명을 확인 하세요");
        }else if(errors?.incruit?.recruit_start_date){
            alert("공고 시작일을 확인 하세요");
            document.getElementsByName("incruit.recruit_start_date")[0].focus();
        }else if(errors?.incruit?.recruit_end_date){
            alert("공고 종료일을 확인 하세요");
            document.getElementsByName("incruit.recruit_end_date")[0].focus();
        }else if(errors?.incruit?.position_num){
            alert("모집 인원수를 확인 하세요");
        }else if(errors?.incruit?.salary_type){
            alert("임금 지급 단위시간을 확인 하세요");
        }else if(errors?.incruit?.salary){
            alert("임금 정보를 확인 하세요");
        }else if(errors?.condition?.duty_form){
            alert("직무 조건을 확인 하세요");
        }else if(errors?.condition?.duty_hour){
            alert("직무 조건을 확인 하세요");
        }else if(errors?.disableNameData){
            alert("장애 조건을 확인 하세요");
        }else if(errors?.comInfo?.main_duty){
            alert("주요직무를 확인 하세요");
        }else if(errors?.comInfo?.qualification){
            alert("자격요건을 확인 하세요");
        }else if(errors?.comInfo?.benefits){
            alert("복리후생을 확인 하세요");
        }else if(errors?.comInfo?.other_info){
            alert("기타사항을 확인 하세요");
        }
    }

    const onSubmitHandler = async (data) => {
        try {

            const {condition: {work_location, detail_work_location, employed_years}} = data;
            const address = detail_work_location ? `${work_location}|${detail_work_location}` : work_location;

            if (data.condition.employment_condition === '경력') {
                data.condition.employment_condition += `|${employed_years}`;
            }

            jobRequestData.enterprise = data.enterprise;
            jobRequestData.comInfo = data.comInfo;

            jobRequestData.incruit = data.incruit;
            jobRequestData.disability = data.disability;
            jobRequestData.disability.handicapped_type[0].add_disabled = data.disableNameData.map((disabled_name) => ({
                disabled_name
            }));
            jobRequestData.condition = data.condition;
            jobRequestData.condition.work_location = address;
            jobRequestData.desire = JSON.parse(localStorage.getItem('compDesiredJobCode'));


            delete jobRequestData.condition.detail_work_location;
            window.localStorage.removeItem('compDesiredJobCode');

            const response = await api.post('/api/comp/matching/createRecruit', JSON.stringify(jobRequestData), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.resultCode === 1000) {
                alert("직무요청서가 성공적으로 등록 되었습니다.")
                window.location.href = '/comp/jobRequest/jobApplicationList';

                //window.location.href="JobApplicationList";
            } else alert("오류가 발생하였습니다. 다시 작성부탁드립니다.");

            console.log(response);
        } catch (e) {
            console.log(e)
        }
        setIsSubmit(true)
    }

    const onError = () => {
        setIsSubmit(true)
    }

    return (
        <div className="wrapper">
            <main>
                <div className="page join page_join page_mypage enterprise page_job_request">
                    <div className="page_back_title with_btn">
                        <a href="/comp/jobRequest/jobApplicationList" target="_self" className="page_back">직무요청서 등록</a>
                        <button type="submit" className="btn_submit filled_green">저장하기</button>
                    </div>
                    <div className="page_inner_above"></div>

                    <div className="wrap_progressbar">
                        <ul className="progressbar_list progress_step02">
                            <li className="progressbar_item progress_item01"><p>요청직무 선택</p></li>
                            <li className="progressbar_item progress_item02"><p>직무요청서 작성</p></li>
                        </ul>
                    </div>

                    <h3 className="page_title">직무요청서를 작성해보세요!</h3>
                    <form onSubmit={handleSubmit(onSubmitHandler, onError)} onKeyDown={(e)=>{if (e.key === 'Enter' && e.target.nodeName === "INPUT"){e.preventDefault();}}}>
                        <div className="page_inner">
                            <div className="area_shade">
                                <EnterpriseInfo methods={methods} entImage = {watchField.enterprise.enterprise_image} compImage={compImage} setCompImage={setCompImage}
                                                imagePreview={imagePreview} setImagePreview={setImagePreview} errors={errors}/>
                                <IncruitInfo methods={methods} errors={errors}/>
                                <WorkCondition methods={methods} address={address} errors={errors}/>
                                <Disability methods={methods} postFile={postFile} setPostFile={setPostFile} errors={errors}/>
                            </div>
                            <div className="wrap_btns">
                                <a href="/comp/jobRequest/chooseJobRequest" target="_self"
                                   className="btn_submit filled_black">이전으로</a>
                                <button type="submit"
                                        className="btn_submit filled_green">저장하기
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </main>

        </div>
    )
}
export default JobRequestForm;





