import { useEffect, useMemo, useState } from "react";
import Loading from "../common/Loading";
import { useGameTimer } from "../../../hooks/useGameTimer";
import { INITIAL_COUNTDOWN_SECONDS } from "../games";

export default function EmotionQuiz({
  image,
  defaultValue,
  list = [],
  onChange,
  isModalOpen,
  openModal,
  setPrevPageButtonProps,
  setNextPageButtonProps,
}) {
  const {
    pauseTimer,
    resumeTimer,
    isTimerFinished,
    startTimer,
    GameTimerComponent,
  } = useGameTimer({
    initialDurationInSeconds: INITIAL_COUNTDOWN_SECONDS,
    onTimerComplete: () => {
      setPrevPageButtonProps(() => {
        return { disabled: false, onClick: () => {} };
      });
      setNextPageButtonProps(() => {
        return {
          disabled: false,
          onClick: (nextPage) => nextPage(),
        };
      });
    },
  });

  const [selectedEmotion, setSelectedEmotion] = useState(
    defaultValue?.[0] || undefined
  );
  const [isLoading, setIsLoading] = useState(true);
  const isAnswered = useMemo(
    () => ![undefined].includes(defaultValue?.[0]),
    []
  );

  useEffect(() => {
    if (isAnswered) {
      setPrevPageButtonProps(() => ({
        disabled: false,
        onClick: () => {},
      }));
      setNextPageButtonProps(() => ({
        disabled: false,
        onClick: (nextPage) => nextPage(),
      }));

      return;
    }

    startTimer();
    setPrevPageButtonProps(() => {
      return {
        disabled: true,
        onClick: () => {},
      };
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isTimerFinished) {
      onChange({ arrayScore: [selectedEmotion ?? "-"] });
    }
  }, [isTimerFinished]);

  useEffect(() => {
    if (selectedEmotion) {
      setNextPageButtonProps(() => {
        return {
          disabled: false,
          onClick: () =>
            onChange({ arrayScore: [selectedEmotion ?? "-"] }, true),
        };
      });
    } else {
      setNextPageButtonProps(() => {
        return {
          disabled: false,
          onClick: () => {
            openModal({
              title: "알람",
              content: (
                <div>
                  <p>미선택한 감정카드가 있습니다.</p>
                  <p>다음문제로 넘어가시겠습니까?</p>
                </div>
              ),
              buttons: [
                {
                  label: "네",
                  onClick: () => {
                    onChange({ arrayScore: [selectedEmotion ?? "-"] }, true);
                  },
                  className: "confirm-button",
                },
                {
                  label: "아니오",
                  className: "cancel-button",
                },
              ],
            });

            return false;
          },
        };
      });
    }
  }, [selectedEmotion]);

  useEffect(() => {
    isModalOpen ? pauseTimer() : resumeTimer();
  }, [isModalOpen]);

  const handleClick = (emotion) => {
    if (isAnswered || isTimerFinished) return;

    setSelectedEmotion((prevEmotion) =>
      prevEmotion === emotion ? undefined : emotion
    );
  };

  return (
    <div className="quiz flex">
      <Loading loading={isLoading} />
      <div className="quiz-wrapper">
        <GameTimerComponent isFinished={isTimerFinished || !!isAnswered} />
        <div className="quiz flex h-full">
          <div className="quiz-assets">
            <img src={image} alt="quiz" />
          </div>

          <div className="quiz-answer">
            <h3>
              Q. 주어진 그림을 확인하여 아래의 올바른 감정카드 버튼을
              클릭하세요.
            </h3>
            <div className="emotion-buttons">
              {list.map((emotion) => (
                <button
                  className={`btn_submit ${
                    emotion === selectedEmotion ? "filled_green" : ""
                  }`}
                  key={emotion}
                  onClick={() => handleClick(emotion)}
                >
                  {emotion}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
