import { useHistory,Link } from "react-router-dom/cjs/react-router-dom.min";
import { plusViewCount } from "../../api/User/Jop/Job";
import React, { useState,useEffect } from "react";
import jwt_decode from "jwt-decode";
import ContractModal from "../user/login/ContractModal";

const AnnouncementItem = ({announce,name,userId}) => {
    const [modal, setModal] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const history = useHistory();

    const toDetailPage = (name,index) => {
        try {
            plusViewCount(announce.id);
            history.push(`/user/job/detailPage/${announce.id}`);
            window.scrollTo(0, 0);
        } catch (e) {
            console.log(e)
        }
    }

    return (
         <li className="job_popular_item">
            <a target="_self" className="job_popular_anchor"
               onClick={
                   user && user.roleType === "COMPANY" && localStorage.getItem("customer_status") !== "true" ? (
                       ()=>setModal(true)
                   ) : (
                       ()=>toDetailPage()
                   )
               }>
                <div className="box_top">
                    <h4 className="company_name">{announce["enterprise_name"]}</h4>
                    <p>{isNaN(announce["remain_day"])?"" : "D-"}<span>{announce["remain_day"]}</span></p>
                </div>
                <h3 className="job_popular_title">{announce["recruit_name"]}</h3>
                <div className="box_detail">
                    <span>{announce["job"]}</span>
                </div>
                <div className="box_badge">
                    <p className="badge badge_task">{announce["duty_type"]}</p>
                    <p className="badge badge_term">{announce["education_constrain"]}</p>
                    {
                        announce["disability_constrain"] && (<p className="badge badge_term">{announce["disability_constrain"].slice(0,2)}</p>)
                    }
                </div>
            </a>
             {
                 modal && <ContractModal setModal={setModal} />
             }
        </li>
    );
}



const Announcement = ({sortSubject,announceList,title,subTitle,href,name}) => {

    const [userId,setUserId] = useState(null);

    const loginCheck = () => {
        try{
            const decodedAccessToken = jwt_decode(localStorage.getItem("accessToken"))
            setUserId(decodedAccessToken.sub)
        }catch(e){
            console.log("is not logined");
        }
    }

    useEffect(loginCheck,[]);

    return (
         <section className={`section_job_popular ${sortSubject}`}>
            <h4 className="sr_only">{title}</h4>
            <div className="wrap_section_title">
                <h3 className="before_icon">{subTitle}</h3>
                <Link to={href} target="_self" className="job_more_anchor">채용정보 더보기</Link>
            </div>
            <ul className="job_popular_list">
                {
                    announceList.map((v,i) => <AnnouncementItem key={i} announce={v} name={name} userId={userId}/>)
                }
            </ul>
        </section>
    );
}

export default Announcement