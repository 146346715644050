const HealthChk = () => {
    return(
    <div>
        <div className="wrapper">
            <main>
                <div className="page page_recommend page_layout_40">
                    <h2 className="page_back_title border_bottom_line">                     
                        health check
                    </h2>
                    <div className="page_inner">
                        <div className="applicant_list">     
                            <p>health check</p>                           
                        </div>                  
                    </div>
                </div>
            </main>
        </div>        
    </div>
    )
};

export default HealthChk;