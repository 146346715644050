import React, { useState, useEffect, useRef } from "react";
import "./STTAndTTS.css";
import { ReactComponent as IconMic } from "../../assets/icon/icon_mic.svg";
import { ReactComponent as IconMicOff } from "../../assets/icon/icon_mic_off.svg";
import { ReactComponent as IconSpeaker } from "../../assets/icon/icon_speaker.svg";
import { ReactComponent as IconSpeakerOff } from "../../assets/icon/icon_speaker_off.svg";
import api from "../../api/api";

const getSupportedMimeType = () => {
  if (MediaRecorder.isTypeSupported("audio/webm")) {
    return "audio/webm"; // Use WebM for Chrome, Firefox, etc.
  } else if (MediaRecorder.isTypeSupported("audio/mp4")) {
    return "audio/mp4"; // Use MP4 for Safari
  } else if (MediaRecorder.isTypeSupported("audio/mpeg")) {
    return "audio/mpeg"; // Alternative for older browsers
  } else {
    throw new Error("No supported audio format found for recording.");
  }
};

const STTAndTTS = ({
  messages,
  handleSendMessage,
  isListening,
  setIsListening,
  isSpeaking,
  setIsSpeaking,
}) => {
  const [notification, setNotification] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const analyserRef = useRef(null);
  const silenceTimeoutRef = useRef(null);
  const audioContextRef = useRef(null);

  // 무음 감지를 위한 설정
  const SILENCE_THRESHOLD = -50; // dB
  const SILENCE_DURATION = 1500; // 1.5초 동안 무음이 지속되면 녹음 중지

  useEffect(() => {
    const requestMicrophonePermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            sampleRate: 48000,
            noiseSuppression: true,
            channelCount: 1,
          },
        });
        console.log("Microphone permission granted.");

        // AudioContext 설정
        audioContextRef.current = new (window.AudioContext ||
          window.webkitAudioContext)();
        const source = audioContextRef.current.createMediaStreamSource(stream);
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 2048;
        source.connect(analyserRef.current);

        const options = {
          mimeType: getSupportedMimeType(),
          audioBitsPerSecond: 256000,
        };

        const recorder = new MediaRecorder(stream, options);
        console.log("MediaRecorder initialized with options:", options);

        setMediaRecorder(recorder);

        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            setAudioChunks((prev) => [...prev, event.data]);
          }
        };
      } catch (error) {
        console.error("Microphone permission or initialization error:", error);
        setNotification("마이크 권한이 없습니다.");
      }
    };

    requestMicrophonePermission();

    return () => {
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => setNotification(null), 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.onstop = async () => {
        if (audioChunks.length > 0) {
          const audioBlob = new Blob(audioChunks, {
            type: getSupportedMimeType(),
          });
          console.log("Created audio blob of size:", audioBlob.size);
          await processAudioData(audioBlob);
        }
      };
    }
  }, [mediaRecorder, audioChunks]);

  useEffect(() => {
    if (isListening) {
      monitorAudioLevel();
    } else {
      if (silenceTimeoutRef.current) {
        clearTimeout(silenceTimeoutRef.current);
        silenceTimeoutRef.current = null;
      }
    }
  }, [isListening]);

  const monitorAudioLevel = () => {
    if (!analyserRef.current || !isListening) return;

    const dataArray = new Float32Array(analyserRef.current.fftSize);
    analyserRef.current.getFloatTimeDomainData(dataArray);

    // RMS 값 계산
    let sum = 0;
    for (let i = 0; i < dataArray.length; i++) {
      sum += dataArray[i] * dataArray[i];
    }
    const rms = Math.sqrt(sum / dataArray.length);
    const db = 20 * Math.log10(rms);

    // 무음 감지
    if (db < SILENCE_THRESHOLD) {
      if (!silenceTimeoutRef.current) {
        silenceTimeoutRef.current = setTimeout(() => {
          if (isListening) {
            console.log("Silence detected, stopping recording");
            toggleListening();
          }
        }, SILENCE_DURATION);
      }
    } else {
      // 소리가 감지되면 타이머 초기화
      if (silenceTimeoutRef.current) {
        clearTimeout(silenceTimeoutRef.current);
        silenceTimeoutRef.current = null;
      }
    }

    // 계속 모니터링
    requestAnimationFrame(monitorAudioLevel);
  };

  const processAudioData = async (audioBlob) => {
    try {
      // setNotification("음성 변환 중...");

      if (audioBlob.size === 0) {
        throw new Error("녹음된 오디오가 없습니다.");
      }

      const formData = new FormData();
      formData.append("file", audioBlob, "recording.webm");

      const response = await api.post("/api/stt/convert", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data) {
        const text =
          typeof response.data === "string"
            ? response.data
            : response.data.text;
        if (text) {
          handleSendMessage(text);
          setNotification("음성 변환 완료");
        } else {
          throw new Error("No text in response");
        }
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error in processAudioData:", error);
      // setNotification("음성 변환 중 오류가 발생했습니다");
    } finally {
      setAudioChunks([]); // Clear chunks after processing
    }
  };

  const toggleSpeaking = () => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      setNotification("음성안내를 중지합니다");
      console.log("Speech synthesis stopped.");
    } else {
      speakMessage();
    }
  };

  const speakMessage = () => {
    if (!messages.length) return;

    const lastBotMessage = messages.findLast(
      (message) => message.sender === "bot"
    );

    if (lastBotMessage && lastBotMessage.ttsText) {
      const speech = new SpeechSynthesisUtterance(lastBotMessage.ttsText);

      speech.onend = () => {
        setIsSpeaking(false);
      };

      speech.onerror = (event) => {
        setIsSpeaking(false);
        console.error("Speech synthesis error:", event.error);
        setNotification("음성안내를 중지합니다");
      };

      setIsSpeaking(true);
      setNotification("음성안내를 시작합니다");
      window.speechSynthesis.speak(speech);
    }
  };

  const toggleListening = () => {
    if (!mediaRecorder) {
      setNotification("마이크 권한이 없습니다.");
      alert(
        "마이크 권한이 브라우저에서 차단되었습니다. 브라우저 설정에서 마이크 권한을 허용해 주세요."
      );
      return;
    }

    if (isListening) {
      try {
        mediaRecorder.stop();
        setIsListening(false);
        setNotification("음성답변을 중지합니다");
      } catch (error) {
        console.error("Error stopping recorder:", error);
        // setNotification("녹음 중지 중 오류가 발생했습니다");
      }
    } else {
      try {
        setAudioChunks([]);
        mediaRecorder.start(100);
        setIsListening(true);
        setNotification("음성인식중입니다");
      } catch (error) {
        console.error("Error starting recorder:", error);
        // setNotification("녹음 시작 중 오류가 발생했습니다");
      }
    }
  };

  return (
    <div className="stt-and-tts-wrapper">
      <div
        className={`stt-and-tts-button ${isSpeaking ? "active" : ""}`}
        onClick={toggleSpeaking}
      >
        {isSpeaking ? <SpeakerIcon /> : <SpeakerOffIcon />}
      </div>
      <div
        className={`stt-and-tts-button ${isListening ? "active" : ""}`}
        onClick={toggleListening}
      >
        {isListening ? <MicIcon /> : <MicOffIcon />}
      </div>
      {notification && <div className="noti-message">{notification}</div>}
    </div>
  );
};

export default STTAndTTS;

function MicIcon() {
  return <IconMic className="mic-icon" />;
}
function MicOffIcon() {
  return <IconMicOff className="mic-off-icon" />;
}
function SpeakerIcon() {
  return <IconSpeaker className="speaker-icon" />;
}
function SpeakerOffIcon() {
  return <IconSpeakerOff className="speaker-off-icon" />;
}
