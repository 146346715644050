export const useImageFile = (ref, setImage, methods, name, callback, type) => {
    const {setError, clearErrors, setValue} = methods;

    const maxSize = 10*1024*1024;

    const getImage = (e) => {
        const image = e.target.files[0];

        if (image && image.size < maxSize) {
            if (type === undefined) {
                return image;
            } else {
                if (type.includes(image.type.split('/')[1])) {
                    return image;
                } else if (type.includes('jpg') || type.includes('jpeg') || type.includes('png')) {
                    alert("※ JPG, PNG 형식의 파일만 업로드 가능합니다.")
                } else if (type.includes('pdf')) {
                    alert("※ PDF 형식의 파일만 업로드 가능합니다.")
                }
            }
        } else {
            alert("파일 크기가 10MB를 초과합니다. 다른 파일을 선택해주세요.");
            setImage(null);
            ref.current.value = null;
        }

    };

    const uploadImage = (ref) => {
        const image = getImage(ref);
        if (image !== undefined) {
            setImage(image);
            if (name !== undefined && name !== null) {
                setValue(name, image, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
                clearErrors(name);
            }
            if (callback !== undefined && callback !== null) {
                callback(image);
            }
        }
    }

    const deleteImage = () => {
        setImage(null);
        if (name !== undefined && name !== null) {
            setError(name, {
                message: "필수 입력 항목입니다."
            }, {shouldFocus: true});
        }
    };

    const image2Base64 = (name,image) => {

        let reader = new FileReader()

        if(image){
            reader.readAsDataURL(image)
            reader.onload = () => {
                setValue(name, reader.result, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
                clearErrors(name);
            };
        }
    };

    const uploadBase64 = (ref) => {

        const image = getImage(ref);
        if(image !== undefined){
            setImage(image);
            if (name !== undefined && name !== null) {
                image2Base64(name,image);
            }
            if (callback !== undefined && callback !== null) {
                callback(image);
            }
        }

    }

    return {
        uploadImage,
        deleteImage,
        uploadBase64
    }
}