const ApplicantEdu = (props) => {
    console.log(props);
    return (
        <div className="profile_tab_con profile_tab_con_4" id="btn4">
            <div className="pop_title line_under">
                <h3><span dangerouslySetInnerHTML={{__html : props?.userNm}}></span>님의 수강정보</h3>
            </div>
            <div className="popup_contents">
                <h4>현재 수강중인 CLASS</h4>
                {(props?.userClass?.current == null || Object.keys(props?.userClass?.current).length == 0) ? (
                <p className="contents_null_text">
                    수강이력이 없습니다.
                </p>
                    ) : (
                <div className="box_wrap_col">
                    <div className="box_wrap_col">
                        {props?.userClass?.current?.slice(0,4).map((item, i) => (
                            <a href="#" target="_self" className="content_box_col">
                                <p className="con_title"><span dangerouslySetInnerHTML={{__html : item.title}}></span> / <span>
                                    {item?.teachera?.map((tItem, j) => (
                                        tItem.name + (item.teachera.length !== j && ", ")
                                    ))}
                                    </span></p>
                                <div className="content_wrap">
                                    <span className="thumb_img">
                                        <img src={item.thumbnail_image} alt={"썸네일 " + item.title}/>
                                    </span>
                                    <div className="text_wrap">
                                        <div className="date_count">
                                            <p>수강기간 <span dangerouslySetInnerHTML={{__html : item?.attend && (Math.ceil(Math.abs(new Date(item.attend.period_end_at).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)))}}></span>일 남음 · </p>
                                            <span dangerouslySetInnerHTML={{__html : item?.class_tag?.tag_name}}></span>
                                        </div>
                                        <p className="contents_count"><span dangerouslySetInnerHTML={{__html : item?.attend?.complete_count}}></span> / <span dangerouslySetInnerHTML={{__html : item?.attend?.lecture_count}}></span></p>                                               
                                        <div className="wrap_progress">
                                            <p>수강완료 강의 수</p>                                                   
                                            <div className="box_progress">                                                       
                                                <p className="progress_txt"><span dangerouslySetInnerHTML={{__html : item?.attend?.complete_percentage}}></span>% 수강 중</p>
                                                <progress value={item?.attend?.complete_percentage} max="100"></progress>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>         
                        ))}
                    </div>
                </div>
                )} 
            </div>        



            <div className="popup_contents">
                <h4>지난 수강완료 CLASS</h4>
                {(props?.userClass?.complete == null || Object.keys(props?.userClass?.complete).length == 0) ? (
                    <p className="contents_null_text">
                        수강이력이 없습니다.
                    </p>
                ) : (
                    <div className="box_wrap_row">
                        {props?.userClass?.complete?.slice(0,4).map((item, i) => (
                            <a href="#" target="_blank" className="content_box_row">
                                <span className="thumb_img">
                                    <img src={item.thumbnail_image} alt={"썸네일 " + item.title}/>
                                </span>
                                <div className="text_wrap">
                                    <span dangerouslySetInnerHTML={{__html : item.title}}></span>
                                    <p>
                                    {item?.teachera?.map((tItem, j) => (
                                        tItem.name + (item.teachera.length !== j && ", ")
                                    ))}
                                    </p>
                                </div>
                            </a>
                        ))}
                    </div>
                )}
            </div>

            <div className="popup_contents">
                <h4>수강기한 마감 CLASS</h4>
                {(props?.userClass?.late == null || Object.keys(props?.userClass?.late).length == 0) ? (
                    <p className="contents_null_text">
                        수강이력이 없습니다.
                    </p>
                ) : (
                    <div className="box_wrap_row">
                        {props?.userClass?.late?.slice(0,4).map((item, i) => (
                            <a href="#" target="_blank" className="content_box_row">
                                <span className="thumb_img">
                                    <img src={item.thumbnail_image} alt={"썸네일 " + item.title}/>
                                </span>
                                <div className="text_wrap">
                                    <span dangerouslySetInnerHTML={{__html : item.title}}></span>
                                    <p>
                                    {item?.teachera?.map((tItem, j) => (
                                        tItem.name + (item.teachera.length !== j && ", ")
                                    ))}
                                    </p>
                                </div>
                            </a>
                        ))}
                    </div>
                )}
            </div>

            
        </div>
    )
}

export default ApplicantEdu;