import React, { useState, useEffect, useMemo, useRef, forwardRef } from "react";
import { useFieldArray } from "react-hook-form";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import dayjs from "dayjs";
import "../autoComplete.css";

export const AutoComplete = ({
  name,
  id,
  inputValue,
  placeholder,
  register,
  autoCompleteInfo,
  group,
  setv,
  setFocus,
  readOnly,
}) => {
  const autoRef = useRef(null);

  const keyDown = (e) => {
    const event = e || window.event;

    if (event.code == "ArrowDown") {
      autoRef?.current?.focus();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      event.preventDefault();
    }
  };

  const change = (e) => {
    setv(name, e.target.value);
  };

  return (
    <div className="box_input dropdown">
      <label htmlFor="" className="asterisk">
        자격증명
      </label>
      <input
        type="text"
        className="placeholder_test"
        name={name}
        id={id}
        placeholder={placeholder}
        {...register}
        onChange={change}
        onKeyDown={keyDown}
        autoComplete="off"
        tabIndex={0}
        readOnly={readOnly}
        disabled={readOnly}
      ></input>
      <Option
        inputName={name}
        inputValue={inputValue}
        majorInfo={autoCompleteInfo}
        schoolGroup={group}
        setValue={setv}
        ref={autoRef}
        inputRef={setFocus}
      />
    </div>
  );
};

export const Option = forwardRef(
  (
    { inputName, inputValue, majorInfo, schoolGroup, setValue, inputRef },
    ifocus
  ) => {
    const [count, setCount] = useState(0);
    const [enter, setEnter] = useState(false);

    const keyDown = (e) => {
      const event = e || window.event;

      if (event.code === "ArrowDown") {
        setCount(Math.min(count + 1, contents.length - 1));
        setEnter(false);
      } else if (event.code === "ArrowUp") {
        setCount(Math.max(count - 1, 0));
        setEnter(false);
      } else if (event.code === "Enter") {
        setValue(inputName, contents[count].license_name);
        setCount(0);
        setEnter(true);
        inputRef(inputName);
      }

      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      event.preventDefault();
    };

    const click = (e) => {
      setCount(0);
      setEnter(true);
      setValue(inputName, e.target.innerText);
    };

    const contents = useMemo(() => {
      setCount(0);
      setEnter(true);
      let result =
        majorInfo?.filter(
          (data) =>
            data.grade === (schoolGroup || "") &&
            data.license_name.includes(inputValue)
        ) || [];
      return result;
    }, [inputValue, schoolGroup, majorInfo]);

    useEffect(() => {
      if (!enter) {
        ifocus?.current?.focus();
      } else {
        setEnter(false);
      }
    }, [count]);

    /*
    if(contents.length === 0 || contents == null){
        return (<div className="dropdown-options"><span href="#">{"일치하는 목록 없음"}</span></div>);
    }
    */

    return inputValue !== "" &&
      ((contents.length === 1 &&
        contents[0].license_name.length > inputValue.length &&
        contents[0].license_name.includes(inputValue)) ||
        contents.length > 1) ? (
      <div className="dropdown-options">
        {contents.map((e, i) => {
          return (
            <span
              key={i}
              onKeyDown={keyDown}
              onClick={click}
              ref={count === i ? ifocus : null}
              tabIndex={-1}
            >
              {e.license_name}
            </span>
          );
        })}
      </div>
    ) : (
      <></>
    );
  }
);

export const AutoCompleteOption = ({
  inputName,
  inputValue,
  certificateType,
  certificationInfo,
  setValue,
}) => {
  const [click, setClick] = useState(false);
  const changeInput = (value) => {
    setValue(inputName, value);
    setClick(false);
  };

  const contents = useMemo(() => {
    let result = certificationInfo.filter(
      (data) =>
        data.grade == (certificateType || "") &&
        data.license_name.indexOf(inputValue) !== -1
    );
    return result;
  }, [inputValue, certificateType, certificationInfo]);
  /*
    if(contents.length === 0 || contents == null){
        return (<div className="dropdown-options"><span href="#">{"일치하는 목록 없음"}</span></div>);
    }*/

  useEffect(() => {
    if (contents.length > 1 && inputValue !== "") {
      setClick(true);
    }
  }, [contents]);
  return contents.length > 1 && inputValue !== "" && click ? (
    <div className="chat-dropdown-options">
      {contents.map((e, i) => {
        return (
          <span
            key={i}
            onClick={(event) => {
              event.stopPropagation();
              console.log(event);
              changeInput(e.license_name);
            }}
          >
            {e.license_name}
          </span>
        );
      })}
    </div>
  ) : (
    <></>
  );
};

const ResumeCertificate = ({
  register,
  control,
  errors,
  setValue,
  watchField,
  autoComplete,
  certificateHistory,
  setFocus,
  readOnly,
  certIndex,
}) => {
  const [certificationAutoComplete, setCertificationAutoComplete] = useState(
    []
  );

  useEffect(() => {
    setCertificationAutoComplete(autoComplete);
  }, [autoComplete]);

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "certificate", // Use the same name used in the form registration.
  });

  // useEffect(() => {
  //   if (certificateHistory?.length > 0) {
  //     certificateHistory.map((data, i) => {
  //       append();
  //       setValue(`certificate[${i}].certificate_name`, data.title);
  //       setValue(
  //         `certificate[${i}].certificate_authority`,
  //         data.issuing_agency
  //       );
  //       setValue(
  //         `certificate[${i}].certificate_acquisition_date`,
  //         data.issuing_date.replace(" ", "").replace(".", "-")
  //       );
  //     });
  //   }
  // }, [certificateHistory]);
  useEffect(() => {
    if (certificateHistory?.length > 0) {
      replace(
        certificateHistory.map((data) => ({
          certificate_name: data.certificate_name,
          certificate_authority: data.certificate_authority,
          certificate_gubun: data.certificate_gubun,
          certificate_acquisition_date: data.certificate_acquisition_date,
        }))
      );
    }
  }, [certificateHistory.length]);

  return (
    <div className="wrap_signup_input wrap_signup_basic">
      <div className="box_sub_title">
        <h4 className="sub_title_signup">자격증</h4>
      </div>
      {fields.map((item, index) => (
        <div key={item.id} className="box_certificate_info history_certificate">
          <div className="box_division">
            {!readOnly && (
              <button
                className="btn_square_black"
                type="button"
                onClick={() => remove(index)}
              ></button>
            )}

            <div className="wrap_box_input">
              <div className="box_input">
                <label htmlFor="" className="asterisk">
                  자격증 구분
                </label>
                <select
                  name={`certificate[${index}].certificate_gubun`}
                  id={`certificate[${index}].certificate_gubun`}
                  className="select"
                  {...register(`certificate[${index}].certificate_gubun`, {
                    required: "필수 입력 항목입니다.",
                  })}
                  readOnly={readOnly}
                  disabled={readOnly}
                >
                  <option value="" hidden={true}>
                    자격증을 선택해주세요.
                  </option>
                  <option value="국가기술자격증-기술사">
                    국가기술자격증-기술사
                  </option>
                  <option value="국가기술자격증-기능장">
                    국가기술자격증-기능장
                  </option>
                  <option value="국가기술자격증-기사">
                    국가기술자격증-기사
                  </option>
                  <option value="국가기술자격증-산업기사">
                    국가기술자격증-산업기사
                  </option>
                  <option value="국가기술자격증-기능사">
                    국가기술자격증-기능사
                  </option>
                  <option value="국가전문자격증">국가전문자격증</option>
                  <option value="국가공인민간자격증">국가공인민간자격증</option>
                  <option value="국가기술자격증-단일등급">
                    국가기술자격증-단일등급
                  </option>
                  <option value="국가기술자격증-1급">국가기술자격증-1급</option>
                  <option value="국가기술자격증-2급">국가기술자격증-2급</option>
                  <option value="국가기술자격증-3급">국가기술자격증-3급</option>
                </select>
              </div>
              <p className="msg_err">
                {errors?.certificate?.[index]?.certificate_gubun?.message}
              </p>
            </div>
            <div className="wrap_box_input">
              <div className="box_input">
                <label htmlFor="">급수</label>
                <input
                  type="text"
                  id=""
                  name={`certificate[${index}].certificate_level`}
                  {...register(`certificate[${index}].certificate_level`)}
                  placeholder="자격증 급수를 입력해주세요."
                  readOnly={readOnly}
                  disabled={readOnly}
                ></input>
              </div>
              <p className="msg_err">
                {errors?.certificate?.[index]?.certificate_level?.message}
              </p>
            </div>
          </div>
          <div className="box_division">
            {!readOnly && (
              <button
                className="btn_square_black"
                type="button"
                onClick={() => remove(index)}
              ></button>
            )}

            <div className="wrap_box_input">
              {/*
                            <div className="box_input dropdown">
                                <label htmlFor="" className="asterisk">자격증명</label>
                                <input
                                    type="text"
                                    id=""
                                    name={`certificate[${index}].certificate_name`}
                                    {...register(`certificate[${index}].certificate_name`, {
                                        required: '필수 입력 항목입니다.'
                                    })}
                                    placeholder="자격증명을 입력해주세요."
                                ></input>

                                <AutoCompleteOption inputName={`certificate[${index}].certificate_name`}
                                inputValue={watchField.certificate[index].certificate_name}
                                certificateType={watchField.certificate[index].certificate_gubun}
                                certificationInfo={certificationAutoComplete} setValue={setValue}/>
                                </div>*/}

              <AutoComplete
                name={`certificate[${index}].certificate_name`}
                id={`certificate[${index}].certificate_name`}
                inputValue={
                  watchField.certificate[index]?.certificate_name || ""
                }
                placeholder={"자격증명을 입력해주세요."}
                register={register(`certificate[${index}].certificate_name`, {
                  required: "필수 입력 항목입니다.",
                })}
                autoCompleteInfo={certificationAutoComplete}
                group={watchField.certificate[index].certificate_gubun}
                setv={setValue}
                setFocus={setFocus}
                readOnly={readOnly}
              />

              <p className="msg_err">
                {errors?.certificate?.[index]?.certificate_name?.message}
              </p>
            </div>
            <div className="wrap_box_input">
              <div className="box_input">
                <label htmlFor="">발행처</label>
                <input
                  type="text"
                  id=""
                  name={`certificate[${index}].certificate_authority`}
                  {...register(`certificate[${index}].certificate_authority`)}
                  placeholder="발행처를 입력해주세요."
                  readOnly={readOnly}
                  disabled={readOnly}
                ></input>
              </div>
              <p className="msg_err">
                {errors?.certificate?.[index]?.certificate_authority?.message}
              </p>
            </div>
            <div className="wrap_box_input">
              <div className="box_input wrap_datepicker">
                <label htmlFor="">취득일</label>
                <input
                  type="text"
                  id="dp1690885806929"
                  className="input_datepicker hasDatepicker"
                  placeholder="년도를 입력해주세요."
                  name={`certificate[${index}].certificate_acquisition_date`}
                  {...register(
                    `certificate[${index}].certificate_acquisition_date`
                  )}
                  style={{ display: "none" }}
                ></input>
                <DatePicker
                  readOnly={readOnly}
                  disabled={readOnly}
                  onKeyDown={(e) => {
                    if (
                      (e.keyCode < 48 || e.keyCode > 57) &&
                      e.keyCode !== 8 &&
                      e.keyCode !== 189
                    ) {
                      e.preventDefault();
                    }
                  }}
                  selected={
                    watchField?.certificate?.[index]
                      ?.certificate_acquisition_date
                      ? new Date(
                          watchField.certificate[
                            index
                          ].certificate_acquisition_date
                        )
                      : null
                  }
                  onChange={(date) => {
                    date &&
                      setValue(
                        "certificate[" +
                          index +
                          "].certificate_acquisition_date",
                        dayjs(date).format("YYYY-MM"),
                        { shouldValidate: true }
                      );
                  }}
                  showMonthYearPicker
                  dateFormat="yyyy-MM"
                  locale={ko}
                  maxDate={new Date()}
                  placeholderText="년도를 입력해주세요."
                />
              </div>
              <p className="msg_err">
                {
                  errors?.certificate?.[index]?.certificate_acquisition_date
                    ?.message
                }
              </p>
            </div>
          </div>
        </div>
      ))}

      <button
        type="button"
        readOnly={readOnly}
        disabled={readOnly}
        onClick={() => {
          append();
        }}
        className="btn_submit btn_add filled_green"
      >
        <span className="btn_text">자격증 추가</span>
      </button>
    </div>
  );
};
export default ResumeCertificate;
