const ErrorMessages ={
    required: "필수 입력 항목입니다.",
    duplicateValidation: "중복여부를 확인해주세요.",
    inValidUserId: "4~19자의 영문 or 영문+숫자 조합만 사용 가능합니다.",
    // inValidId: "3~19자의 영문, 숫자 조합만 사용 가능합니다.",
    inValidPw: "8~32자의 영문, 숫자, 특수문자 조합만 사용 가능합니다.",
    unMatchPw: "입력하신 비밀번호와 일치하지 않습니다.",
    currentPw: "현재와 다른 비밀번호를 사용해주세요",
    duplicateId: "이미 사용중인 아이디입니다.",
    duplicateCrtfNum: "이미 사용중인 등록번호입니다.",
    duplicateBizSeq: "이미 등록된 사업자등록번호입니다.",
    validPhone: "휴대폰 인증을 진행해주세요.",
    duplicatePhoneNo: "이미 등록된 휴대폰 번호입니다.",
    inValidPhone: "인증번호가 일치하지 않습니다.",
    reValidPhone: "휴대폰 인증을 다시 진행해주세요.",
    inValidEmail: "잘못된 E-mail 형식입니다.",
    inValidBirthday: "올바른 날짜 형식(YYYY-MM-DD)으로 입력해 주세요."
};

export { ErrorMessages };