import React, {useEffect, useState,useRef} from 'react';
import dayjs from "dayjs";
import {ko} from "date-fns/esm/locale";
import {ErrorMessages} from "../../../utils/Message";
import './datePickerCustom.css';
import { StartDate,EndDate } from './DatePicker';
import { clear } from '@testing-library/user-event/dist/clear';

const IncruitInfo = (props) => {
    const { methods, disabled: propsDisabled,errors} = props;
    const {watch, register, setValue,control,clearErrors} = methods;
    const watchField = watch();
    const disabled = propsDisabled !== undefined ? propsDisabled : false;
    const [isPositionNumRadioChecked, setIsPositionNumRadioChecked] = useState(false);
    const [isEmploymentTypeTemp4RadioChecked, setIsEmploymentTypeTemp4RadioChecked] = useState(false);
    const [isEmploymentTypeTemp5RadioChecked, setIsEmploymentTypeTemp5RadioChecked] = useState(false);

    const start_focus = useRef(null);
    
    const comma_seperate = (e) => {
        let input = e.target.value.replaceAll(',',"");
        if(input != null && input != "" && !isNaN(input)){
            let l = input.length;

            let remain = l%3 == 0 ? 3 : l%3;
            let result = input.slice(0,remain);
            for(let i=remain; i< l; i+=3){
                result += "," + input.slice(i,i+3);
            }
            setValue(`incruit.salary`,result);
        }
        else{
            setValue(`incruit.salary`,null);
        }
    }

    const convert_comma_seperate2Number = (input) => {
        return input === null ? null : input.toString().replaceAll(',',"");
    }

    useEffect(() => {

        if(isEmploymentTypeTemp4RadioChecked === false && isEmploymentTypeTemp5RadioChecked === false){
            //setValue("incruit.recruit_start_date",null);
            setValue("incruit.recruit_end_date",null);
            setValue("incruit.employment_type","");
        }else if(isEmploymentTypeTemp4RadioChecked === false && isEmploymentTypeTemp5RadioChecked === true){
            setValue("incruit.recruit_start_date",dayjs(new Date()).format("YYYY-MM-DD HH:mm"));
            clearErrors("incruit.recruit_start_date"); clearErrors("incruit.recruit_end_date");
        }else{
            clearErrors("incruit.recruit_start_date"); clearErrors("incruit.recruit_end_date");
        }
        
    }, [isEmploymentTypeTemp4RadioChecked,isEmploymentTypeTemp5RadioChecked]);

    useEffect(() => {
        if (watchField.incruit.position_num === '미정') {
            setIsPositionNumRadioChecked(true);
        }
    }, [watchField.incruit.position_num]);


    const handlePositionNumRadioClick = () => {
        setIsPositionNumRadioChecked(!isPositionNumRadioChecked);
        setValue('incruit.position_num', '미정')
    };
    
    const handleEmploymentTypeRadioClick = (value) => {
        setValue("incruit.employment_type", value)
        setValue("incruit.recruit_end_date",null);
    };

    return (
        <>
            <div className="wrap_signup_input">
                <div className="box_sub_title">
                    <h4 className="sub_title_signup">직무요청 내용</h4>
                </div>

                <div className="wrap_box_input focus_in">
                    <div className="box_input">
                        <label htmlFor="" className="">공고상태</label>
                        <div className="wrap_box_radio box_education">
                            <div className="input_radio">
                                <label htmlFor="temp1" className="label_txt">
                                    <input type="radio" className="btn_type_radio" id="temp1" name="recruit_status"
                                           value="공고중" {...register('incruit.recruit_status')}
                                           disabled={disabled}
                                    ></input>
                                    공고중</label>
                            </div>
                            <div className="input_radio">
                                <label htmlFor="temp2" className="label_txt">
                                    <input type="radio" className="btn_type_radio" id="temp2" name="recruit_status"
                                           value="공고종료" {...register('incruit.recruit_status')}
                                           disabled={disabled}
                                    ></input>
                                    공고종료</label>
                            </div>
                            <div className="input_radio">
                                <label htmlFor="temp3" className="label_txt">
                                    <input type="radio" className="btn_type_radio" id="temp3" name="recruit_status"
                                           value="채용마감" {...register('incruit.recruit_status')}
                                           disabled={disabled}
                                    ></input>
                                    채용마감</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap_signup_input wrap_signup_basic">
                <div className="wrap_box_input focus_in">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk" ref={start_focus}>공고명</label>
                        <input type="text" id="" name={`incruit.recruit_name`}
                               {...register(`incruit.recruit_name`, {required: ErrorMessages.required})}
                               placeholder="공고명을 입력해 주세요."
                               disabled={disabled}
                        ></input>
                    </div>
                    <p className="msg_err">{errors?.incruit?.recruit_name?.message}</p>
                </div>
            </div>
            <div className="wrap_signup_input">
                <div className="wrap_box_input focus_in">
                    <div className="box_input wrap_datepicker">
                        <label htmlFor="" className="asterisk" >공고시작일</label>
                        <StartDate control={control} ko={ko} watchField={watchField} isEmploymentTypeTemp4RadioChecked={isEmploymentTypeTemp4RadioChecked}
                        isEmploymentTypeTemp5RadioChecked={isEmploymentTypeTemp5RadioChecked} disabled={disabled} clearErrors={clearErrors} setValue={setValue}/>
                    </div>
                    <p className="msg_err">{errors?.incruit?.recruit_start_date?.message}</p>
                </div>

                <div className="box_division">
                    <div className="wrap_box_input focus_in">
                        <div className="box_input wrap_datepicker">
                            <label htmlFor="" className="asterisk">공고마감일</label>
                            <EndDate control={control} ko={ko} watchField={watchField} isEmploymentTypeTemp4RadioChecked={isEmploymentTypeTemp4RadioChecked}
                            isEmploymentTypeTemp5RadioChecked={isEmploymentTypeTemp5RadioChecked} disabled={disabled} clearErrors={clearErrors} setValue={setValue}/>
                        </div>
                        <p className="msg_err">{errors?.incruit?.recruit_end_date?.message}</p>
                    </div>
                    <div className="wrap_box_input focus_in box_margin_none">
                        <div className="box_input">
                            <div className="wrap_box_radio">
                                <div className="input_radio">
                                    <label htmlFor="temp4" className="label_txt">
                                        <input type="radio" className="btn_type_radio" id="temp4" name="employment_type"
                                               value="채용 시 마감"
                                               {...register('incruit.employment_type')}
                                               // checked={isEmploymentTypeTemp4RadioChecked}
                                               checked={watchField.incruit.employment_type === "채용 시 마감" ? true: false}
                                               onClick={() => handleEmploymentTypeRadioClick("채용 시 마감")}
                                               disabled={disabled}
                                        ></input>
                                        채용 시 마감</label>
                                </div>
                                <div className="input_radio">
                                    <label htmlFor="temp5" className="label_txt">
                                        <input type="radio" className="btn_type_radio" id="temp5" name="employment_type"
                                               value="상시 채용"
                                               {...register('incruit.employment_type')}
                                               // checked={isEmploymentTypeTemp5RadioChecked}
                                               checked={watchField.incruit.employment_type === "상시 채용" ? true: false}
                                               onClick={() => handleEmploymentTypeRadioClick("상시 채용")}
                                               disabled={disabled}
                                        ></input>
                                        상시 채용</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box_division">
                    <div className="wrap_box_input focus_in">
                        <div className="box_input">
                            <label htmlFor="" className="asterisk">요청인원 (단위: 명)</label>
                            <input type="number" className="input_number_select" id=""
                                   name={`incruit.position_num`}
                                   {...register(`incruit.position_num`, {
                                       required: isPositionNumRadioChecked ? false : ErrorMessages.required,
                                   })}
                                   min="0" max="99" placeholder="요청인원을 입력해 주세요."
                                   readOnly={isPositionNumRadioChecked}
                                   disabled={disabled}
                            ></input>
                            <div className="wrap_box_radio with_radio">
                                <div className="input_radio">
                                    <label htmlFor="temp6" className="label_txt">
                                        <input type="radio" className="btn_type_radio" id="temp6"
                                               name="position_num" defaultValue="미정"
                                               checked={isPositionNumRadioChecked}
                                               onClick={handlePositionNumRadioClick}
                                               disabled={disabled}
                                        ></input>
                                        미정</label>
                                </div>
                            </div>

                        </div>
                        <p className="msg_err">{errors?.incruit?.position_num?.message}</p>
                    </div>

                </div>

                <div className="box_division">
                    <div className="wrap_box_input focus_in">
                        <div className="box_input">
                            <label htmlFor="" className="asterisk">급여</label>
                            <select name="incruit.salary_type" id="incruit.salary_type"
                                className="select" {...register('incruit.salary_type', {
                                required: ErrorMessages.required,
                                onChange: (e) => {
                                    if(e.target.value === "면접 후 합의"){
                                        setValue(`incruit.salary`,0);
                                    }
                                }
                            })}
                                    disabled={disabled}

                            >
                                <option value="" hidden={true}>급여를 선택해주세요.</option>
                                <option value="시급">시급</option>
                                <option value="일급">일급</option>
                                <option value="주급">주급</option>
                                <option value="월급">월급</option>
                                <option value="연봉">연봉</option>
                                <option value="면접 후 합의">면접 후 합의</option>
                            </select>
                        </div>
                        <p className="msg_err">{errors?.incruit?.salary_type?.message}</p>
                    </div>
                    <div className="wrap_box_input focus_in">
                        <div className="wrap_box_input_minor">
                            <div className="box_input exception_wage">
                                <label htmlFor="" className=""></label>
                                <div className="exception_wage">
                                    <input id=""
                                           name={`incruit.salary`}
                                           {...register(`incruit.salary`, {required: ErrorMessages.required, onChange: comma_seperate, setValueAs: convert_comma_seperate2Number})}
                                           placeholder="급여를 숫자로 입력해주세요."
                                           disabled={watchField.incruit.salary_type === "면접 후 합의" ? true : disabled}
                                    ></input>
                                    <span>원</span>
                                </div>
                            </div>
                            <p className="msg_err">{errors?.incruit?.salary?.message}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default IncruitInfo;





