import "./GameTimer.css";

export function GameTimer({ isFinished, remainingTime }) {
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes > 0 ? `${minutes}분` : ""}${minutes > 0 && secs > 0 ? " " : ""}${secs > 0 ? `${secs}초` : ""}`;
  };

  return (
    <div className="game-timer">
      <div className="timer-display">
        <img
          src="/img/icon/icon_stopwatch.png"
          alt="Timer Icon"
          className="timer-icon"
        />
        <p className="timer-text">
          {`제한시간 ${isFinished ? "종료" : formatTime(remainingTime)}`}
        </p>
      </div>
    </div>
  );
}
