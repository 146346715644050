import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../../../api/api";
import ApplicantInfoModal from "./applicantInfoModal";
import ConfirmModal from "./confirmModal";
import InsertInterviewModal from "../interview/InsertInterviewModal";
import MainTabA from "./mainTabA";
import MainTabB from "./mainTabB";
import MainTabC from "./mainTabC";
import MainTabD from "./mainTabD";
import MainTabE from "./mainTabE";
import ApplicantModal from "../JobRequest/ApplicantModal";
import DeleteModal from "../JobRequest/DeleteModal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

let initParam = {
  recruit_seq: 497,
  comp_evaluation: [1, 2, 3, 4, 5, 6, 7],
  sort_field: null,
  sort_method: null,
  current_page: 1,
};

const ApplicantMain = () => {
  const [loading, setLoading] = useState(false);
  const [currTab, setCurrTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
    tab4: false,
    tab5: false,
  });
  const location = useLocation();
  const [deleteModal, setDeleteModal] = useState(false);
  const [jobParam, setJobParam] = useState({});

  const [applicantList, setApplicantList] = useState({});

  const [applicantModal, setApplicantModal] = useState(false);
  const [applicantInfoModal, setApplicantInfoModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState(null);
  const [result, setResult] = useState({});

  const [paginationInfo, setPaginationInfo] = useState({});
  const [totalPage, setTotalPage] = useState(1);
  const [interviewModal, setInterviewModal] = useState(false);
  const [recruitSeq, setRecruitSeq] = useState(false);
  const [resumeSeq, setResumeSeq] = useState(false);

  const [updateParam, setUpdateParam] = useState({
    comp_evaluation: null,
    recruit_seq: null,
    announcement_seq: null,
  });
  const [updateChk, setUpdateChk] = useState(false);

  const parmas = useParams();

  //창 올때 파라미터 값 저장
  useEffect(() => {
    let paramTmp = { ...initParam };
    paramTmp.recruit_seq = parmas.recruit_seq;
    location?.state?.comp_evaluation != undefined &&
      (paramTmp.comp_evaluation = location?.state?.comp_evaluation);
    let tabState = {
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
    };
    location?.state?.tab != undefined
      ? (tabState["tab" + location.state.tab] = true)
      : (tabState["tab1"] = true);
    setCurrTab(tabState);
    setJobParam(paramTmp);
  }, []);

  //파라미터 변경(창넘어올때나, 탭변경) 시에 값 렌더링
  useEffect(() => {
    setLoading(true);
    try {
      Object.keys(jobParam).length &&
        api
          .post("/api/comp/applicant/getApplicantListInfo", jobParam)
          .then((response) => response.data)
          .then((response) => {
            response &&
              response.resultData &&
              setApplicantList(response.resultData);
          });
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, [jobParam, currTab]);

  useEffect(() => {
    if (Object.keys(applicantList).length > 0) {
      let totalPage = Math.ceil(applicantList.totalCnt / 10);
      let minLimit = 5 * (Math.ceil(jobParam.current_page / 5) - 1) + 1;
      let maxLimit = 5 * (Math.ceil(jobParam.current_page / 5) - 1) + 5;
      maxLimit = maxLimit > totalPage ? totalPage : maxLimit;
      let pageTmp = {
        currPage: jobParam.current_page,
        totalPage: totalPage,
        minLimit: minLimit,
        maxLimit: maxLimit,
      };
      setPaginationInfo(pageTmp);
    }
  }, [applicantList]);

  useEffect(() => {
    try {
      updateChk &&
        api
          .post("/api/comp/applicant/getApplicantListInfo", jobParam)
          .then((response) => response.data)
          .then((response) => {
            response &&
              response.resultData &&
              setApplicantList(response.resultData);
            setUpdateChk(false);
          });
    } catch (e) {}
  }, [updateChk]);
  //지원자 리스트는 지원자에대한 announcement_seq, recruit_seq, user_evaluation || comp_evaluation 로 COMM_ANNOUNCEMENT_TB 조회
  const applicantDetailModal = async (annNum, recNum) => {
    let detailParam = {
      announcement_seq: 39,
      recruit_seq: 497,
    };
    annNum && (detailParam.announcement_seq = annNum);
    recNum && (detailParam.recruit_seq = recNum);
    setLoading(true);
    try {
      await api
        .post("/api/comp/applicant/getApplicantInfo", detailParam)
        .then((response) => response.data)
        .then((response) => {
          if (response?.resultData == null) {
            setResult(null);
          }
          response && response.resultData && setResult(response.resultData);
        });
    } catch (e) {
    } finally {
      setLoading(false);
      setApplicantInfoModal(true);
    }
  };

  const sorting = (field, method) => {
    //console.log(field + " + " + method)
    let tmpParam = { ...jobParam };
    tmpParam.sort_field = field;
    tmpParam.sort_method = method;
    setJobParam(tmpParam);
  };

  const changeTab = (tab) => {
    let tabState = {
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
    };
    tabState[tab] = true;
    setCurrTab(tabState);
    let tmp = { ...jobParam };
    switch (tab) {
      case "tab1":
        tmp.comp_evaluation = [1, 2, 3, 4, 5, 6, 7];
        break;
      case "tab2":
        tmp.comp_evaluation = [1];
        break;
      case "tab3":
        tmp.comp_evaluation = [3];
        break;
      case "tab4":
        tmp.comp_evaluation = [5, 6];
        break;
      case "tab5":
        tmp.comp_evaluation = [2, 4];
        break;
    }
    setJobParam(tmp);
  };

  const setCurrPage = (pageNum) => {
    let tmpParam = { ...jobParam };
    tmpParam.current_page = pageNum;
    setJobParam(tmpParam);
  };
  const openModal = (recruitSeq) => {
    setRecruitSeq(recruitSeq);
    setApplicantModal(true);
  };

  /*const deleteRecruit = (recruitSeq) => {
        const userConfirmed = window.confirm("공고를 삭제하시겠습니까?");
        if (userConfirmed) {
            try {
                api.post(`/api/comp/matching/deleteRecruit?recruitSeq=${recruitSeq}`)
                    .then(response => response.data)
                    .then((response) => {
                        if (response && response.resultCode === 1000 && response.resultData) {
                            alert("삭제되었습니다.");
                            window.location.href="/comp/jobRequest/jobApplicationList";
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } catch (e) {
                console.log(e);
            }
        }
    };*/

  const setDefaultImg = (e) => {
    if (
      e.target.src != null &&
      e.target.src.indexOf("/img/icon/icon_profile.png") == -1
    ) {
      e.target.src = "/img/icon/icon_profile.png";
    }
  };

  return (
    <>
      <div className="wrapper">
        <div className="page page_applicant_list page_layout_40">
          <div className="page_back_title">
            <h2 className="page_back_title border_bottom_line show_detail_wrap">
              <a
                href="/comp/jobRequest/jobApplicationList"
                target="_self"
                className="page_back"
                dangerouslySetInnerHTML={{
                  __html: applicantList?.recruit_name,
                }}
              ></a>
            </h2>
            <button
              type="button"
              style={{ right: "190px" }}
              className="btn_submit filled_green"
              onClick={(e) => {
                e.preventDefault();
                openModal(applicantList?.recruit_seq);
              }}
            >
              채용공고 상세보기
            </button>
            <button
              type="button"
              className="btn_submit filled_green"
              onClick={(e) => {
                e.preventDefault();
                setDeleteModal(() => true);
              }}
            >
              공고 삭제
            </button>
          </div>
          <ul className="tab_menu">
            <li className="tab_menu_list">
              <a
                href="#"
                target="_self"
                className={"tab_page_01 " + (currTab.tab1 ? "active" : "")}
                onClick={(e) => {
                  changeTab("tab1");
                }}
              >
                전체
              </a>
            </li>
            <li className="tab_menu_list">
              <a
                href="#"
                target="_self"
                className={"tab_page_02 " + (currTab.tab2 ? "active" : "")}
                onClick={(e) => {
                  changeTab("tab2");
                }}
              >
                미열람
              </a>
            </li>
            <li className="tab_menu_list">
              <a
                href="#"
                target="_self"
                className={"tab_page_03 " + (currTab.tab3 ? "active" : "")}
                onClick={(e) => {
                  changeTab("tab3");
                }}
              >
                면접진행
              </a>
            </li>
            <li className="tab_menu_list">
              <a
                href="#"
                target="_self"
                className={"tab_page_04 " + (currTab.tab4 ? "active" : "")}
                onClick={(e) => {
                  changeTab("tab4");
                }}
              >
                최종합격
              </a>
            </li>
            <li className="tab_menu_list">
              <a
                href="#"
                target="_self"
                className={"tab_page_05 " + (currTab.tab5 ? "active" : "")}
                onClick={(e) => {
                  changeTab("tab5");
                }}
              >
                불합격
              </a>
            </li>
          </ul>
          <div className="page_inner wrap_list_table applicant_list">
            {currTab.tab1 && (
              <MainTabA
                result={applicantList}
                paginationInfo={paginationInfo}
                setCurrPage={setCurrPage}
                modal={applicantDetailModal}
                sorting={sorting}
                setConfirmModal={setConfirmModal}
                setConfirmModalType={setConfirmModalType}
                setUpdateParam={setUpdateParam}
                setRecruitSeq={setRecruitSeq}
                setResumeSeq={setResumeSeq}
                setInterviewModal={setInterviewModal}
                setDefaultImg={setDefaultImg}
              />
            )}
            {currTab.tab2 && (
              <MainTabB
                result={applicantList}
                paginationInfo={paginationInfo}
                setCurrPage={setCurrPage}
                modal={applicantDetailModal}
                sorting={sorting}
                setConfirmModal={setConfirmModal}
                setConfirmModalType={setConfirmModalType}
                setUpdateParam={setUpdateParam}
                setRecruitSeq={setRecruitSeq}
                setResumeSeq={setResumeSeq}
                setInterviewModal={setInterviewModal}
                setDefaultImg={setDefaultImg}
              />
            )}
            {currTab.tab3 && (
              <MainTabC
                result={applicantList}
                paginationInfo={paginationInfo}
                setCurrPage={setCurrPage}
                modal={applicantDetailModal}
                sorting={sorting}
                setConfirmModal={setConfirmModal}
                setConfirmModalType={setConfirmModalType}
                setUpdateParam={setUpdateParam}
                setRecruitSeq={setRecruitSeq}
                setResumeSeq={setResumeSeq}
                setInterviewModal={setInterviewModal}
                setDefaultImg={setDefaultImg}
              />
            )}
            {currTab.tab4 && (
              <MainTabD
                result={applicantList}
                paginationInfo={paginationInfo}
                setCurrPage={setCurrPage}
                modal={applicantDetailModal}
                sorting={sorting}
                setConfirmModal={setConfirmModal}
                setConfirmModalType={setConfirmModalType}
                setUpdateParam={setUpdateParam}
                setRecruitSeq={setRecruitSeq}
                setResumeSeq={setResumeSeq}
                setInterviewModal={setInterviewModal}
                setDefaultImg={setDefaultImg}
              />
            )}
            {currTab.tab5 && (
              <MainTabE
                result={applicantList}
                paginationInfo={paginationInfo}
                setCurrPage={setCurrPage}
                modal={applicantDetailModal}
                sorting={sorting}
                setConfirmModal={setConfirmModal}
                setConfirmModalType={setConfirmModalType}
                setUpdateParam={setUpdateParam}
                setRecruitSeq={setRecruitSeq}
                setResumeSeq={setResumeSeq}
                setInterviewModal={setInterviewModal}
                setDefaultImg={setDefaultImg}
              />
            )}
          </div>
        </div>
      </div>

      {applicantModal && (
        <ApplicantModal
          setModal={setApplicantModal}
          setApplicantInfoModal={setApplicantInfoModal}
          setResult={setResult}
          updateChk={updateChk}
          setUpdateChk={setUpdateChk}
          recruitSeq={recruitSeq}
        />
      )}
      {applicantInfoModal && (
        <ApplicantInfoModal
          result={result}
          setModal={setApplicantInfoModal}
          setConfirmModal={setConfirmModal}
          setConfirmModalType={setConfirmModalType}
          updateChk={updateChk}
          setUpdateParam={setUpdateParam}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          setModal={setConfirmModal}
          type={confirmModalType}
          setUpdateChk={setUpdateChk}
          updateParam={updateParam}
        />
      )}
      {interviewModal && (
        <InsertInterviewModal
          setInterviewModal={setInterviewModal}
          recruitSeq={recruitSeq}
          resumeSeq={resumeSeq}
          updateParam={updateParam}
        />
      )}
      {deleteModal && (
        <DeleteModal
          setDeleteModal={setDeleteModal}
          deleteSeq={applicantList?.recruit_seq}
        />
      )}
    </>
  );
};

export default ApplicantMain;
