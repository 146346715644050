import axios from 'axios';
import {useLocation, Redirect, useHistory} from 'react-router-dom';
import {useState, useEffect} from 'react';
import api from '../../../api/api'

const SocialLoginRedirect = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('code');
    if (token) {
        axios.post('/api/v1/auth/login', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((response) => {
                const user = response.data.body.user;
                const { username, providerType, userId } = user;
                const userData = { username, providerType, userId };
                localStorage.setItem('id', userData.userId)
                localStorage.setItem('providerType', userData.providerType)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
};

export default SocialLoginRedirect;