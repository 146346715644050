
import {useForm} from "react-hook-form";
import React, {Component, useEffect, useState,useCallback} from 'react';
import DatePicker from "react-datepicker";
import {ko} from 'date-fns/esm/locale';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import api, { decodeToken } from '../../../api/api';
const InsertInterviewModal = (props) => {  
    const formData = new FormData();
    const init = { 
        announcement_seq : "",      
        recruit_seq: "",
        resume_seq: "",
        agency_type: "",
        interview_dt1: "",
        interview_dt2: "",
        interview_dt3: "",
        direct_yn: "N",        
        comp_evaluation : 3
    }    
    
    const methods = useForm({
        mode: 'onChange',
        defaultValues : init
    });
    const { handleSubmit, formState: { errors, isValid }} = methods;
    const [recruitNm, setRecruitNm] = useState([]);   
    const [resumeInfo, setResumeInfo] = useState([]);  
    const [dateMenu, setDateMenu] = useState(true);  
    const [isSubmit, setIsSubmit] = useState(false);   
    const [selectedDate, setSelectedDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
    const [selectedHour, setSelectedHour] = useState('09');
    const [selectedMinute, setSelectedMinute] = useState('00');
    const [selectedDate1, setSelectedDate1] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
    const [selectedHour1, setSelectedHour1] = useState('09');
    const [selectedMinute1, setSelectedMinute1] = useState('00');
    const [selectedDate2, setSelectedDate2] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
    const [selectedHour2, setSelectedHour2] = useState('09');
    const [selectedMinute2, setSelectedMinute2] = useState('00');
    const [selectedDate3, setSelectedDate3] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
    const [selectedHour3, setSelectedHour3] = useState('09');
    const [selectedMinute3, setSelectedMinute3] = useState('00');
    const [interviewPopup, setInterviewPopup ] = useState({display: 'block', top:'10%'})
    const [popup, setPopup ] = useState({display: 'block'}) 
    const [selectDisabled, setSelectDisabled] = useState(false);

    const [interviewInfo, setInterviewInfo] = useState({      
            announcement_seq : "",      
            recruit_seq: "",
            resume_seq: "",
            agency_type: "Y",
            interview_dt1: "",
            interview_dt2: "",
            interview_dt3: "",
            direct_yn: "N",        
            comp_evaluation : 3
    });

    const changeDateMenu = (value) => {
        setDateMenu(value);
        if(value){
            setInterviewPopup(prevState => ({
                ...prevState, 
                top: '10%',
            }));
        }
        else{
            setInterviewPopup(prevState => ({
                ...prevState, 
                top: '0%',
            }));
        }
    } 
    useEffect(() => {
        getRecruitNm()
        setupInterviewInfo("interview_dt1",selectedDate+' '+selectedHour+':'+selectedMinute)
        setupInterviewInfo("announcement_seq",props.updateParam?.announcement_seq)  
    }, []);

    const getRecruitNm = () =>{
        if(props.recruitSeq && props.resumeSeq) {
            setSelectDisabled(true);
            setupInterviewInfo("recruit_seq",props.recruitSeq)  
            setupInterviewInfo("resume_seq",props.resumeSeq)  
            api.post('/api/comp/interview/getResumeInfoList', {
                recruitSeq : props.recruitSeq,
                companySeq : 0
                }).then(response => response.data).then((response) => {
                    setResumeInfo(response.resultData);
                })    
        }
        api.get('/api/comp/interview/getRecruitNmList').then(response => response.data).then((response) => {
            if(response && response.resultCode == 1000){
                setRecruitNm(response.resultData);
            } else {
                //alert("일치하는 정보가 없습니다.");
            }
        })
    };

    const getResumeInfo = (e) => {
        setupInterviewInfo("recruit_seq",e.target.value)
        setupInterviewInfo("resume_seq","")
        api.post('/api/comp/interview/getResumeInfoList', {
            recruitSeq : e.target.value,
            companySeq : 0
            }).then(response => response.data).then((response) => {
                setResumeInfo(response.resultData);
            })        
    };
    
    const getResumeSeqInfo = (e) =>{
        setupInterviewInfo("resume_seq",e.target.value)
    };
    const setupInterviewInfo = async (target,value) =>{
        setInterviewInfo(prevState => ({
            ...prevState, 
            [target]: value,
        }));
    }
    const deleteInterviewInfo = () => {        
        try {
            api.post('/api/comp/interview/deleteInterview', interviewInfo).then(response => response.data)
            .then((response) => {
            }
            )                
        } catch (error) {
            alert("오류가 발생했습니다. 관리자에게 문의해주세요.");
            console.log('Error:', error);
        }   
    }
    const onSubmithandler = async () => {         
        if (!interviewInfo.resume_seq) {
            alert("면접 대상자를 선택해 주세요")
        }else if (!interviewInfo.agency_type) {
            alert("면접 방법을 선택해 주세요")
        } else if (!interviewInfo.interview_dt1) {
            alert("면접 일정을 입력해 주세요")
        } else {
            deleteInterviewInfo();
            try {
                await api.post('/api/comp/interview/setInterviewInfo', interviewInfo).then(response => response.data)
                    .then((response) => {
                        if(response && response.resultCode == 1000){
                            alert(response.resultData);
                            props.setInterviewModal(false)
                        } 
                        else{
                            alert("오류가 발생했습니다. 관리자에게 문의해주세요.");
                            props.setInterviewModal(false)
                        }
                    }
                )
            } catch (error) {
                alert("오류가 발생했습니다. 관리자에게 문의해주세요.");
                console.log('Error:', error);
                props.setInterviewModal(false)
            }
        }           
    };
    const onError = () => {
        setIsSubmit(true)
    }
    useEffect(() => {
        if(!isSubmit) return
        AlertError()
        setIsSubmit(false)
    }, [errors, isSubmit])
    const AlertError = () => {
    }
    return (
        <>
            <div className="popup_mask" style={popup}></div>
            <div className="popup_layer popup_middle" id="popInterviewApplySugg" style={interviewPopup}>
                <div className="popup_inner" style={{maxHeight:'1500px'}}>
                    <div className="pop_title">
                        <h3>면접 등록하기</h3>
                        <button type="button" aria-label="레이어 팝업 닫기" className="popup_close" onClick={(e) => {e.preventDefault(); props.setInterviewModal(false);}}></button>
                    </div>
                    <div className="popup_contents">
                        <div className="selection_wrap">
                            <h4 className="selection_title">공고를 선택해 주세요</h4>
                            <div className="rd_select_wrap">
                                <select onChange={getResumeInfo} defaultValue={props.recruitSeq ? props.recruitSeq : ''} disabled={selectDisabled}>
                                    {!props.recruitSeq && <option value=''>공고를 선택해주세요</option>}
                                    {recruitNm.map(item => ( props.recruitSeq ? item.recruit_seq == props.recruitSeq &&
                                    <option key={item.recruit_seq} value={item.recruit_seq}>{item.recruit_name}</option>
                                    : <option key={item.recruit_seq} value={item.recruit_seq}>{item.recruit_name}</option>
                                    ))}                                    
                                </select>
                            </div>
                        </div>
                        <div className="selection_wrap" style={{marginBottom:"40px"}}>
                            <h4 className="selection_title">면접 대상자를 선택해 주세요</h4>
                            <div className="rd_select_wrap">
                                <select onChange={getResumeSeqInfo} defaultValue={props.resumeSeq ? props.resumeSeq : ''} disabled={selectDisabled}>
                                {!props.resumeSeq && <option value=''>면접 대상자를 선택해 주세요</option>}
                                    {resumeInfo.map(item => ( props.resumeSeq ? item.resume_seq == props.resumeSeq &&
                                    <option key={item.resume_seq} value={item.resume_seq}>{item.kor_nm} / {item.sex_gb} / {item.age}세</option>
                                    : <option key={item.resume_seq} value={item.resume_seq}>{item.kor_nm} / {item.sex_gb} / {item.age}세</option>
                                    ))}                                    
                                </select>
                            </div>
                        </div>
                        <div className="selection_wrap" style={{marginBottom:"40px"}}>
                            <h4 className="selection_title">면접 방법을 선택해 주세요</h4>
                            <div className="rd_select_wrap">
                                <div className="rd_btn_wrap">                           
                                    <input type="radio" name="modal_rd01" id="modal01" onClick={()=>setupInterviewInfo("agency_type","Y")} defaultChecked/>
                                    <label htmlFor="modal01">직접면접</label>
                                </div>
                                <div className="rd_btn_wrap">                           
                                    <input type="radio" name="modal_rd01" id="modal02" onClick={()=>setupInterviewInfo("agency_type","N")}/>
                                    <label htmlFor="modal02">대행면접</label>
                                </div>
                            </div>
                        </div>
                        <div className="selection_wrap" style={{marginBottom:"40px"}}>
                            <h4 className="selection_title">면접 일정을 입력해 주세요</h4>

                            <ul className="rd_select_wrap">
                                <li className="rd_btn_wrap">                      
                                    <input type="radio" name="modal_rd02" id="modal03" onClick={()=>changeDateMenu(true)} defaultChecked/>
                                    <label htmlFor="modal03">면접일 지정하기</label>
                                </li>
                                <li className="rd_btn_wrap">                      
                                    <input type="radio" name="modal_rd02" id="modal04" onClick={()=>changeDateMenu(false)} />
                                    <label htmlFor="modal04">면접일 제안하기</label>
                                </li>
                            </ul>
                        </div>
                        <div className="interview_tab_contents">
                            <div id="btn10" className={"tab_content_select" + (dateMenu ? " current" : "")}>
                                <div className="selection_wrap">                           
                                    <div className="line_date_select">  
                                        <input type="text" id="" className="input_datepicker"
                                            name={`selectedDate`} style={{display: "none"}} />
                                        <DatePicker
                                            locale={ko} selected={selectedDate ? new Date(selectedDate) : null}
                                            onChange={(date) => {setSelectedDate(dayjs(date).format('YYYY-MM-DD')); setupInterviewInfo("interview_dt1",dayjs(date).format('YYYY-MM-DD')+' '+selectedHour+':'+selectedMinute)}}
                                            dateFormat="yyyy-MM-dd" minDate={new Date()}
                                        />                                        
                                        <select defaultValue='09' value={selectedHour} 
                                        onChange={(e) => {setSelectedHour(e.target.value); setupInterviewInfo("interview_dt1",selectedDate+' '+e.target.value+':'+selectedMinute)}}>
                                            <option key='09' value="09">09시</option>
                                            <option key='10' value="10">10시</option>
                                            <option key='11' value="11">11시</option>
                                            <option key='12' value="12">12시</option>
                                            <option key='13' value="13">13시</option>
                                            <option key='14' value="14">14시</option>
                                            <option key='15' value="15">15시</option>
                                            <option key='16' value="16">16시</option>
                                            <option key='17' value="17">17시</option>
                                            <option key='18' value="18">18시</option>
                                        </select>
                                        <select defaultValue='00' value={selectedMinute}                                         
                                        onChange={(e) => {setSelectedMinute(e.target.value); setupInterviewInfo("interview_dt1",selectedDate+' '+selectedHour+':'+e.target.value)}}>
                                            <option key='00' value="00">00분</option>
                                            <option key='10' value="10">10분</option>
                                            <option key='20' value="20">20분</option>
                                            <option key='30' value="30">30분</option>
                                            <option key='40' value="40">40분</option>
                                            <option key='50' value="50">50분</option>         
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div id="btn11" className={"tab_content_select" + (dateMenu ? "" : " current")}>
                                <div className="selection_wrap">
                                    <p>면접일 1</p>
                                    <div className="line_date_select">                     
                                    <input type="text" id="" className="input_datepicker"
                                            name={`selectedDate1`} style={{display: "none"}} />
                                        <DatePicker
                                            locale={ko} selected={selectedDate1 ? new Date(selectedDate1) : null}
                                            onChange={(date) => {setSelectedDate1(dayjs(date).format('YYYY-MM-DD')); setupInterviewInfo("interview_dt1",dayjs(date).format('YYYY-MM-DD')+' '+selectedHour1+':'+selectedMinute1)}}
                                            dateFormat="yyyy-MM-dd" minDate={new Date()}
                                        /> 
                                        <select defaultValue='09' value={selectedHour1} 
                                        onChange={(e) => {setSelectedHour1(e.target.value); setupInterviewInfo("interview_dt1",selectedDate1+' '+e.target.value+':'+selectedMinute1)}}>
                                            <option key='09' value="09">09시</option>
                                            <option key='10' value="10">10시</option>
                                            <option key='11' value="11">11시</option>
                                            <option key='12' value="12">12시</option>
                                            <option key='13' value="13">13시</option>
                                            <option key='14' value="14">14시</option>
                                            <option key='15' value="15">15시</option>
                                            <option key='16' value="16">16시</option>
                                            <option key='17' value="17">17시</option>
                                            <option key='18' value="18">18시</option>
                                        </select>
                                        <select defaultValue='00' value={selectedMinute1} 
                                        onChange={(e) => {setSelectedMinute1(e.target.value); setupInterviewInfo("interview_dt1",selectedDate1+' '+selectedHour1+':'+e.target.value)}}>
                                            <option key='00' value="00">00분</option>
                                            <option key='10' value="10">10분</option>
                                            <option key='20' value="20">20분</option>
                                            <option key='30' value="30">30분</option>
                                            <option key='40' value="40">40분</option>
                                            <option key='50' value="50">50분</option>         
                                        </select>
                                    </div>
                                </div>
                                <div className="selection_wrap">
                                    <p>면접일 2</p>
                                    <div className="line_date_select">                    
                                    <input type="text" id="" className="input_datepicker"
                                            name={`selectedDate2`} style={{display: "none"}} />
                                        <DatePicker
                                            locale={ko} selected={selectedDate2 ? new Date(selectedDate2) : null}
                                            onChange={(date) => {setSelectedDate2(dayjs(date).format('YYYY-MM-DD')); setupInterviewInfo("interview_dt2",dayjs(date).format('YYYY-MM-DD')+' '+selectedHour2+':'+selectedMinute2)}}
                                            dateFormat="yyyy-MM-dd" minDate={new Date()}
                                        /> 
                                        <select defaultValue='09' value={selectedHour2} 
                                        onChange={(e) => {setSelectedHour2(e.target.value); setupInterviewInfo("interview_dt2",selectedDate2+' '+e.target.value+':'+selectedMinute2)}}>
                                            <option key='09' value="09">09시</option>
                                            <option key='10' value="10">10시</option>
                                            <option key='11' value="11">11시</option>
                                            <option key='12' value="12">12시</option>
                                            <option key='13' value="13">13시</option>
                                            <option key='14' value="14">14시</option>
                                            <option key='15' value="15">15시</option>
                                            <option key='16' value="16">16시</option>
                                            <option key='17' value="17">17시</option>
                                            <option key='18' value="18">18시</option>
                                        </select>
                                        <select defaultValue='00' value={selectedMinute2} 
                                        onChange={(e) => {setSelectedMinute2(e.target.value); setupInterviewInfo("interview_dt2",selectedDate2+' '+selectedHour2+':'+e.target.value)}}>
                                            <option key='00' value="00">00분</option>
                                            <option key='10' value="10">10분</option>
                                            <option key='20' value="20">20분</option>
                                            <option key='30' value="30">30분</option>
                                            <option key='40' value="40">40분</option>
                                            <option key='50' value="50">50분</option>         
                                        </select>
                                    </div>
                                </div>
                                <div className="selection_wrap">
                                    <p>면접일 3</p>
                                    <div className="line_date_select">                 
                                    <input type="text" id="" className="input_datepicker"
                                            name={`selectedDate3`} style={{display: "none"}} />
                                        <DatePicker
                                            locale={ko} selected={selectedDate3 ? new Date(selectedDate3) : null}
                                            onChange={(date) => {setSelectedDate3(dayjs(date).format('YYYY-MM-DD')); setupInterviewInfo("interview_dt3",dayjs(date).format('YYYY-MM-DD')+' '+selectedHour3+':'+selectedMinute3)}}
                                            dateFormat="yyyy-MM-dd" minDate={new Date()}
                                        /> 
                                        <select defaultValue='09' value={selectedHour3}
                                        onChange={(e) => {setSelectedHour3(e.target.value); setupInterviewInfo("interview_dt3",selectedDate3+' '+e.target.value+':'+selectedMinute3)}}>
                                            <option key='09' value="09">09시</option>
                                            <option key='10' value="10">10시</option>
                                            <option key='11' value="11">11시</option>
                                            <option key='12' value="12">12시</option>
                                            <option key='13' value="13">13시</option>
                                            <option key='14' value="14">14시</option>
                                            <option key='15' value="15">15시</option>
                                            <option key='16' value="16">16시</option>
                                            <option key='17' value="17">17시</option>
                                            <option key='18' value="18">18시</option>
                                        </select>
                                        <select defaultValue='00' value={selectedMinute3} 
                                        onChange={(e) => {setSelectedMinute3(e.target.value); setupInterviewInfo("interview_dt3",selectedDate3+' '+selectedHour3+':'+e.target.value)}}>
                                            <option key='00' value="00">00분</option>
                                            <option key='10' value="10">10분</option>
                                            <option key='20' value="20">20분</option>
                                            <option key='30' value="30">30분</option>
                                            <option key='40' value="40">40분</option>
                                            <option key='50' value="50">50분</option>         
                                        </select>
                                    </div>
                                </div>
                            </div>                  
                        </div>              
                        <div className="popup_middle_btn_wrap">
                            <a href="#" target="_self" className="btn_submit filled_black"  onClick={(e) => {e.preventDefault(); props.setInterviewModal(false);}}>취소</a>
                            <a href="#" target="_self" className="btn_submit filled_green" onClick={handleSubmit(onSubmithandler, onError)}>확인</a>
                        </div>              
                    </div>
                </div>
            </div>
        </>
    )
};

export default InsertInterviewModal;