import { useFieldArray, useForm, UseFormRegisterReturn } from "react-hook-form";
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  forwardRef,
} from "react";
import ChooseJobModal from "./ChooseJobModal";
import DatePicker from "react-datepicker";
import renderInputs from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import dayjs from "dayjs";

const Career = ({
  register,
  control,
  errors,
  setValue,
  watchField,
  modal,
  setModal,
  careerHistory,
  readOnly,
  careerIndexAtChat,
}) => {
  const [checked, setChecked] = useState(false);
  const [careerIndex, setCareerIndex] = useState(-1);
  const isNewComer = localStorage.getItem("newcomer");
  const openDutyModal = JSON.parse(localStorage.getItem("openDutyModal"));
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "career",
  });

  useEffect(() => {
    if (isNewComer) setChecked(isNewComer);
  }, [isNewComer]);

  useEffect(() => {
    if (openDutyModal === true) handleOpenModal(openDutyModal);
  }, [openDutyModal]);

  useEffect(() => {
    if (careerHistory?.length > 0) {
      replace(
        careerHistory.map((data) => ({
          company_name: data.company_name,
          department: data.department,
          employment_status: data.employment_status,
          hired_date: data.hired_date,
          duty: data.duty,
          duty_detail: data.duty_detail,
          resignation_date: data.resignation_date,
          salary: data.salary,
          is_removed: false,
          is_saved: false,
        }))
      );
    }
  }, [careerHistory?.length]);

  useEffect(() => {
    if (isNewComer) return;
    watchField?.career?.filter((data, _) => data.is_removed === false)
      .length === 0
      ? setChecked(false)
      : setChecked(false);
  }, [watchField.career]);

  const handleCloseModal = () => {
    setModal(false);
    localStorage.setItem("openDutyModal", JSON.stringify("complete"));
  };

  const changeDuty = useCallback(
    (checkboxValue) => {
      const _index =
        careerIndexAtChat !== null && careerIndexAtChat !== undefined
          ? careerIndexAtChat
          : careerIndex;

      if (checkboxValue?.d_cate_cdnm !== undefined) {
        setValue(
          `career[${_index}].duty`,
          checkboxValue.l_cate_cdnm +
            ">" +
            checkboxValue.m_cate_cdnm +
            ">" +
            checkboxValue.s_cate_cdnm +
            ">" +
            checkboxValue.d_cate_cdnm
        );

        setValue(`career[${_index}].duty_detail`, checkboxValue.d_cate_cdnm);
      }
    },
    [careerIndex, careerIndexAtChat]
  );

  const handleOpenModal = (index) => {
    setModal(true);
    setCareerIndex(index);
  };

  const handleRemove = (index) => {
    if (watchField.career[index].is_saved) {
      setValue(`career[${index}].is_removed`, true);
    } else {
      remove(index);
    }
  };

  const handleNewCheckbox = (event) => {
    setChecked(event.target.checked);
    var count = 0;
    if (event.target.checked) {
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].is_saved === true) {
          setValue(`career[${i}].is_removed`, true);
        } else {
          remove(i - count);
          count += 1;
        }
      }
    }
  };

  return (
    <div className="wrap_signup_input wrap_signup_basic">
      <div className="box_sub_title chk_required_career">
        <h4 className="sub_title_signup">
          경력사항<span>최신경력 순으로 작성해 주세요.</span>
        </h4>
        <div className="box_input_chk">
          <label htmlFor="temp06">
            신입
            <input
              type="checkbox"
              checked={checked}
              readOnly={readOnly}
              disabled={readOnly}
              onChange={handleNewCheckbox}
              className="input_chk"
              id="temp06"
              name="group6"
            ></input>
          </label>
        </div>
      </div>
      {!isNewComer &&
        fields
          .map((item, index) => (
            <div key={item.id} className="box_career_info history_company">
              <div className="box_division">
                {!readOnly && (
                  <button
                    className="btn_square_black"
                    type="button"
                    onClick={() => handleRemove(index)}
                  ></button>
                )}

                <div className="wrap_box_input">
                  <div className="box_input">
                    <label htmlFor="" className="asterisk">
                      회사명
                    </label>
                    <input
                      readOnly={readOnly}
                      disabled={readOnly}
                      type="text"
                      id=""
                      name={`career[${index}].company_name`}
                      {...register(`career[${index}].company_name`, {
                        required: watchField.career[index].is_removed
                          ? false
                          : "필수 입력 항목입니다.",
                      })}
                      placeholder="회사명"
                    ></input>
                  </div>
                  <p className="msg_err">
                    {errors?.career?.[index]?.company_name?.message}
                  </p>
                </div>
                <div className="wrap_box_input">
                  <div className="box_input">
                    <label htmlFor="" className="asterisk">
                      부서/직급/직책{" "}
                    </label>
                    <input
                      type="text"
                      id=""
                      readOnly={readOnly}
                      disabled={readOnly}
                      name={`career[${index}].department`}
                      {...register(`career[${index}].department`, {
                        required: watchField.career[index].is_removed
                          ? false
                          : "필수 입력 항목입니다.",
                        pattern: {
                          value: /^[a-zA-Zㄱ-ㅎ가-힣\\/]*$/,
                          message: "문자만 입력 가능합니다.",
                        },
                        onChange: (e) => {
                          if (
                            !e.target.value.match(/^[a-zA-Zㄱ-ㅎ가-힣\\/]*$/)
                          ) {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Zㄱ-ㅎ가\\/]/gi,
                              ""
                            );
                          }
                        },
                      })}
                      placeholder="부서"
                    ></input>
                  </div>
                  <p className="msg_err">
                    {errors?.career?.[index]?.department?.message}
                  </p>
                </div>
                <div className="wrap_box_input">
                  <div className="box_input">
                    <label htmlFor="" className="asterisk">
                      직무
                    </label>
                    <input
                      disabled={readOnly}
                      type="text"
                      id=""
                      style={{ backgroundColor: "#FFF" }}
                      {...register(`career[${index}].duty_detail`, {
                        required: watchField.career[index].is_removed
                          ? false
                          : "필수 입력 항목입니다.",
                      })}
                      placeholder="직무"
                      readOnly
                      onClick={(e) => {
                        handleOpenModal(index);
                      }}
                    ></input>
                  </div>
                  <p className="msg_err">
                    {errors?.career?.[index]?.duty?.message}
                  </p>
                </div>
              </div>
              <div className="box_division">
                <div className="wrap_box_input">
                  <div className="box_input wrap_datepicker">
                    <label htmlFor="" className="asterisk">
                      입사년월
                    </label>
                    <input
                      type="text"
                      id="dp1690885806925"
                      readOnly={readOnly}
                      disabled={readOnly}
                      className="input_datepicker hasDatepicker"
                      placeholder="년도를 입력해주세요."
                      name={`career[${index}].hired_date`}
                      {...register(`career[${index}].hired_date`, {
                        required: watchField.career[index].is_removed
                          ? false
                          : "필수 입력 항목입니다.",
                      })}
                      style={{ display: "none" }}
                    ></input>
                    <DatePicker
                      readOnly={readOnly}
                      disabled={readOnly}
                      onKeyDown={(e) => {
                        if (
                          (e.keyCode < 48 || e.keyCode > 57) &&
                          e.keyCode !== 8 &&
                          e.keyCode !== 189
                        ) {
                          e.preventDefault();
                        }
                      }}
                      selected={
                        watchField?.career?.[index]?.hired_date
                          ? new Date(watchField.career[index].hired_date)
                          : null
                      }
                      onChange={(date) => {
                        date &&
                          setValue(
                            "career[" + index + "].hired_date",
                            dayjs(date).format("YYYY-MM"),
                            { shouldValidate: true }
                          );
                      }}
                      showMonthYearPicker
                      dateFormat="yyyy-MM"
                      locale={ko}
                      maxDate={
                        watchField?.career?.[index]?.resignation_date <
                        new Date()
                          ? watchField?.career?.[index]?.resignation_date
                          : new Date()
                      }
                      placeholderText="년도를 입력해주세요."
                    />
                  </div>
                  <p className="msg_err">
                    {errors?.career?.[index]?.hired_date?.message}
                  </p>
                </div>
                <div className="wrap_box_input">
                  <div className="box_input">
                    <label htmlFor="" className="asterisk">
                      재직여부
                    </label>
                    <select
                      name={`career[${index}].employment_status`}
                      id={`career[${index}].employment_status`}
                      readOnly={readOnly}
                      disabled={readOnly}
                      className="select"
                      {...register(`career[${index}].employment_status`, {
                        required: watchField.career[index].is_removed
                          ? false
                          : "필수 입력 항목입니다.",
                      })}
                    >
                      <option value="" hidden={true}>
                        재직여부를 선택해주세요.
                      </option>
                      <option value="재직중">재직중</option>
                      <option value="퇴사">퇴사</option>
                    </select>
                  </div>
                  <p className="msg_err">
                    {errors?.career?.[index]?.employment_status?.message}
                  </p>
                </div>
                {(watchField.career[index].employment_status === "" ||
                  watchField.career[index].employment_status === "퇴사") && (
                  <>
                    <div className="wrap_box_input">
                      <div className="box_input wrap_datepicker">
                        <label htmlFor="" className="asterisk">
                          퇴사년월
                        </label>
                        <input
                          type="text"
                          id="dp1690885806926"
                          readOnly={readOnly}
                          disabled={readOnly}
                          className="input_datepicker hasDatepicker"
                          placeholder="년도를 입력해주세요."
                          name={`career[${index}].resignation_date`}
                          {...register(`career[${index}].resignation_date`, {
                            required: watchField.career[index].is_removed
                              ? false
                              : "필수 입력 항목입니다.",
                          })}
                          style={{ display: "none" }}
                        ></input>
                        <DatePicker
                          readOnly={readOnly}
                          disabled={readOnly}
                          onKeyDown={(e) => {
                            console.log(e);
                            if (
                              (e.keyCode < 48 || e.keyCode > 57) &&
                              e.keyCode !== 8 &&
                              e.keyCode !== 189
                            ) {
                              e.preventDefault();
                            }
                          }}
                          selected={
                            watchField?.career?.[index]?.resignation_date
                              ? new Date(
                                  watchField.career[index].resignation_date
                                )
                              : null
                          }
                          onChange={(date) => {
                            date &&
                              setValue(
                                "career[" + index + "].resignation_date",
                                dayjs(date).format("YYYY-MM"),
                                { shouldValidate: true }
                              );
                          }}
                          showMonthYearPicker
                          dateFormat="yyyy-MM"
                          locale={ko}
                          maxDate={new Date()}
                          minDate={
                            new Date(watchField.career[index].hired_date)
                          }
                          placeholderText="년도를 입력해주세요."
                        />
                      </div>
                      <p className="msg_err">
                        {errors?.career?.[index]?.resignation_date?.message}
                      </p>
                    </div>
                  </>
                )}
                <div className="wrap_box_input">
                  <div className="wrap_box_input_minor">
                    <div className="box_input  exception_wage">
                      <label htmlFor="">연봉</label>
                      <div className="exception_wage">
                        <input
                          type="test"
                          id=""
                          placeholder="숫자로 입력해주세요."
                          name={`career[${index}].salary`}
                          readOnly={readOnly}
                          disabled={readOnly}
                          {...register(`career[${index}].salary`, {
                            min: 0,
                            max: 1000000000000,
                            onChange: (e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9,]/g,
                                ""
                              );
                            },
                          })}
                        ></input>
                        <span>만원</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
          .filter((_, index) => watchField.career[index].is_removed === false)}
      {!checked ? (
        <button
          type="button"
          disabled={readOnly}
          onClick={() => {
            console.log(fields);
            append({
              company_name: null,
              department: null,
              hired_date: null,
              resignation_date: null,
              duty: null,
              duty_detail: null,
              is_removed: false,
              is_saved: false,
            });
          }}
          className="btn_submit btn_add filled_green"
        >
          <span className="btn_text">경력사항 추가</span>
        </button>
      ) : null}

      {modal ? (
        <div onClick={() => {}}>
          <ChooseJobModal
            onClose={handleCloseModal}
            onCheckboxChange={changeDuty}
            setModal={setModal}
            modal={modal}
          />
        </div>
      ) : null}
    </div>
  );
};
export default Career;
