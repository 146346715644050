import React from 'react';
import api from '../../../api/api';
const ApplicantCancleModal = (props) => {  
    const cancelApplicant = (e) => {
        e.preventDefault();
        api.post('/api/user/interview/updateApplicantEvaluation',props.applicant).then(response => response.data)
            .then((response) => {
                if(response && response.resultCode == 1000){
                } else {
                    //alert("일치하는 정보가 없습니다.");
                }
            }
        );

        window.location.href='/user/interview/InterviewMain';
    }
    const close_popup = (e) =>{
        e.preventDefault();
        props.setApplicantCancelModal(false)
    }
    return (
        <>
            <div className="popup_layer popup_small" id="popWarning01">
                <div className="popup_inner">          
                    <div className="popup_contents">
                        <p>해당 공고 지원취소를 확정하시겠습니까?</p>
                        <div className="popup_small_btn_wrap">
                            <a href="javascript:;" target="_self" className="btn_submit filled_black" onClick={(e)=>close_popup(e)}>취소</a>
                            <a href="javascript:;" target="_self" className="btn_submit filled_green" onClick={(e)=>{cancelApplicant(e)}}>확인</a>
                        </div>
                    </div>    
                </div>
            </div>
        </>
    )
};

export default ApplicantCancleModal;