import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {Document, Page, pdfjs} from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import {scenario, scenarioType} from "../../../utils/Scenario";
import uuid from 'react-uuid';
import InterCrtfCertification from "./InterCrtfCertification";
import InterAddInfo from "./InterAddInfo";
import axios from "axios";
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import {
    countId,
    countPhoneNo,
    createUser,
    readOcr,
    sendPhone,
    synonym,
    uploadImage
} from "../../../api/User/Signup/Signup";
import {ErrorMessages} from "../../../utils/Message";
import {useImageFile} from "../../../hooks/useImageFile";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

dayjs.locale('ko')

const init =  {
    member_com : {
        member_id: "",
        member_pw: "NO_PASS",
        email: "",
        member_nm: "",
        gubun: "USER",
        provider_type: "LOCAL"
    },
    disabili_certifica : {
        dis_reg_cer_seq: "",
        birthday: "",
        sex_gb: "",
        address: "",
        extraAddr: "",
        major_disability_nm: "",
        minor_disability_nm: "",
        total_disability_nm: "",
        issuance_dt: "",
        issuance_agency: "",
        confirm_no: "",
        certifica_no: "",
        crtf_file_path: "",
        crtf: ""
    },
    member_info : {
        image_file_path: "",
        image: "",
        phone_no: "",
        protector_nm: "",
        protector_relation: "",
        equipment_st: "",
        inflow: "",
    }
}

const InterSignup = () => {
     const methods = useForm({
        mode: 'onChange',
        defaultValues:init
    });
    const { register, setValue, watch, trigger, reset, setError, clearErrors, formState: { errors }} = methods;

    const watchField = watch();
    const [time ,setTime] = useState(1799);
    const [phone, setPhone] = useState({
        // send : false,
        num : undefined,
        valid : false,
        pass : false
    });
    const [crtf, setCrtf] = useState(null);
    const [image, setImage] = useState(null);
    const [messages, setMessages] = useState([]);
    const [scenarioId, setScenarioId] = useState(scenarioType.GREETING);
    const [inputValue, setInputValue] = useState("");
    const [crtfStatus, setCrtfStatus] = useState(false);
    const crtfInput= useRef(null);
    const imageInput = useRef(null);
    const scrollRef = useRef(null);
    const _scenarioId = scenarioId.split("||")
    const history = useHistory();
    const formData = new FormData();

    useEffect(() => {
        const currentScenario = scenario[_scenarioId[0]]
        if (!currentScenario) return
        deleteType(["button", "file", "valid"])
        appendMessage(currentScenario)
    }, [scenarioId])

    useEffect(() => {
        setTimeout(() => {
            // scrollRef.current.scrollIntoView({ behavior: "smooth"});
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }, 50)
    }, [messages])

    useEffect(() => {
        let Count

        if (time > 0) {
            Count = setInterval(() => {
                setTime((prev) => prev - 1);
            }, 1000);
        }
        else {
            clearInterval(Count);
            if (phone.pass !== true)
                setPhone({...phone, num: undefined, valid: false})
        }
        return () => clearInterval(Count)
    }, [time])

    const timeFormat = (time) => {
        const m = Math.floor(time / 60).toString()
        let s = (time % 60).toString()
        if (s.length === 1) s = `0${s}`
        return `${m}:${s}`
    }

    const socialReset = () => {
        reset({
            ...watchField,
            member_com : {
                ...watchField.member_com,
                member_id: localStorage.getItem('id'),
                provider_type: localStorage.getItem('providerType')
            }
        })
    }

    const createWindow = (url, name, width, height) => {
        let left = 0;
        let top = 0;

        if (!name) {
            name = 'Window';
        }
        if (!width) {
            width = 500;
        }
        if (!height) {
            height = 600;
        }

        if (url == null) {
            return null;
        }

        var options = `width=${width},height=${height},left=${left},top=${top}`;

        return window.open(url, name, options);
    };

    const openWindow = (authURL, message) => {
        let popup, oauthInterval;
        popup = createWindow(authURL, 'OAuth');
        oauthInterval = setInterval( async () => {
            try {
                if (popup.location.href.startsWith('https://matching.vdream.co.kr')) {
                    clearInterval(oauthInterval);
                    popup.close();
                    const DuplicateCheck = await idDuplicateCheck(localStorage.getItem('id'), true);
                    if (DuplicateCheck) {
                        socialReset();
                        handleButton(scenarioType.CREATE_EMAIL, message);
                    } else {
                        alert("이미 가입되어있는 소셜계정입니다.");
                        localStorage.clear();
                        history.push('/')
                    }
                }
            } catch (e) {
            }
        }, 1000);
    }

    const idDuplicateCheck = async (value, isSocial) => {
        const id = value;

        if (!(/^(?=.*[A-Za-z])[A-Za-z0-9]{4,19}$/.test(id)) && isSocial === false) {
            return false;
        } else {
            try {
                const response = await countId(id);

                if (response.data.resultData !== 0) {
                    return false
                } else {
                    return true
                }

            } catch (error)  {
                console.log(error)
                return false
            }
        }
    }

    const sendMessage = async () => {
        const num = watchField.member_info.phone_no;

        if (num === "" || num === undefined)
            setError("member_info.phone_no", {
                message: ErrorMessages.required
            }, {shouldFocus: true})
        else {
            try {
                clearErrors("member_info.phone_no")
                // test시에만 휴대폰중복체크 해제
                // const response = await countPhoneNo(num);
                //
                // if (response.data.resultData === 0) {
                    const valid = await sendPhone(num);

                    setPhone({...phone, num : valid})
                    setTime(1799)
                // } else {
                //     setError("member_info.phone_no", {
                //         message: ErrorMessages.duplicatePhoneNo
                //     }, {shouldFocus: true})
                // }

            } catch (error) {
                console.log(error)
            }
        }

    }

    const validPhoneCheck = () => {
        if (phone.num !== undefined) {
            if (watchField.member_info.validNum === phone.num.data.resultData.toString()) {
                setPhone({...phone, valid: true, pass: true})
                clearErrors("member_info.validNum")
                setScenarioId(scenarioType.UPLOAD_CRTF + '||' + uuid())
            }
            else {
                setError("member_info.validNum", {
                    message : "인증번호가 일치하지 않습니다."
                }, {shouldFocus: true})
                setPhone({...phone, valid : false})
                setValue("member_info.validNum", "")
            }
        }
        else {
            setError("member_info.validNum", {
                message : "휴대폰 인증을 다시 진행해주세요."
            }, {shouldFocus: true})
            setPhone({...phone, valid : false})
            setValue("member_info.validNum", "")
        }
    }

    const uploadCrtf = async (image) => {
        const formData = new FormData();
        // console.log(image)
        let sex_gb = '';
        let social_number = '';
        let extraAddr = '';
        const userChat = createUserChat(image.name, image, "crtf")
        appendMessage(userChat)
        setScenarioId(scenarioType.CREATE_CRTF + '||' + uuid())

        try {
            formData.set('crtf', image);

            readOcr(formData).then((response) => {
                if (response.data && response.data.resultCode === 1000 && response.data.resultData) {
                    const {data} = response.data.resultData;

                    data.social_security_number = data.social_security_number.replace(" ", "")
                    if (data.social_security_number.slice(7, 8) % 2 === 1) {
                        sex_gb = "남성";
                    } else {
                        sex_gb = "여성";
                    }

                    if (data.social_security_number.slice(7, 8) < 3) {
                        social_number = "19" + data.social_security_number.slice(0, 2) + "-" + data.social_security_number.slice(2, 4) + "-" + data.social_security_number.slice(4, 6);
                    } else {
                        social_number = "20" + data.social_security_number.slice(0, 2) + "-" + data.social_security_number.slice(2, 4) + "-" + data.social_security_number.slice(4, 6);
                    }

                    var tmp = data.address.split(",");
                    if ((tmp[1] === undefined) || (tmp[1] === "")) {
                        tmp = "";
                    } else {
                        for (var i = 1; i < tmp.length; i++) {
                            extraAddr += tmp[i];
                            if (i != tmp.length - 1)
                                extraAddr += ","
                        }
                    }

                    setValue('member_com.member_nm', data.name, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                    setValue('disabili_certifica.crtf', image, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                    setValue('disabili_certifica.birthday', social_number, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                    setValue('disabili_certifica.sex_gb', sex_gb, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                    setValue('disabili_certifica.address', data.address.split(",")[0], { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                    setValue('disabili_certifica.extraAddr', extraAddr, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                    setValue('disabili_certifica.major_disability_nm', data.major_disability, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                    setValue('disabili_certifica.minor_disability_nm', data.minor_disability, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                    setValue('disabili_certifica.total_disability_nm', data.comprehensive_degree_disability, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                    setValue('disabili_certifica.issuance_agency', data.issuing_agency, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                    setValue('disabili_certifica.issuance_dt', data.issuing_date, { shouldValidate: true, shouldDirty: true, shouldTouch: true});
                    setValue('disabili_certifica.dis_reg_cer_seq', data.registration_number, { shouldValidate: true, shouldDirty: true, shouldTouch: true});

                }
            })
            setCrtfStatus(false)
        } catch (error) {
            console.log(error)
        }
    }

    const {uploadImage: uploadOcr, deleteImage} = useImageFile(crtfInput, setCrtf, methods, undefined, uploadCrtf, ['pdf']);

    const crtfDelete = () => {
        if (crtfInput.current !== null)
            crtfInput.current.value = null
        setScenarioId(scenarioType.UPLOAD_CRTF + '||' + uuid())
        setCrtfStatus(true);
    }

    const createUserChat = (text, file, type) => {
        if (type === "text")
            return {
                "role": "user",
                "content": [
                    {
                        "type": "text",
                        "message": text
                    }
                ]
            }
        else if (type === "crtf")
            return {
                "role": "user",
                "content": [
                    {
                        "type": "text",
                        "message": text
                    },
                    {
                        "type": "crtf_input",
                        "message": text,
                        "file": file
                    }
                ]
            }
        else if (type === "image")
            return {
                "role": "user",
                "content": [
                    {
                        "type": "text",
                        "message": text
                    },
                    {
                        "type": "image_input",
                        "message": text,
                        "file": file,
                    },
                    {
                        "type": "text",
                        "message": "작성을 완료했어요."
                    }
                ]
            }
    }

    const appendMessage = (message) => {
        setMessages(prev => {
            const nextMessages = [...prev, message]
            return nextMessages
        })
    }

    const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
            handleSendBtn()
        }
    };

    const validButton = async (nextScenario, message) => {

        if (nextScenario === "google" || nextScenario === "kakao" || nextScenario === "naver" || nextScenario === "facebook") {
            openWindow('/api/oauth2/authorization/join/' + nextScenario + '?redirect_uri=https://matching.vdream.co.kr/auth/redirect/interJoin', message);
        }
        else if (nextScenario === "valid_num") {
            sendMessage()

            return true
        }
        else if (nextScenario === "upload_crtf") {
            resetCertification();

            return true
        }
        else if (nextScenario === "create_addinfo") {
            const isValid = await trigger(["member_com.member_nm", "disabili_certifica.sex_gb", "disabili_certifica.birthday", "disabili_certifica.major_disability_nm", "disabili_certifica.issuance_dt", "disabili_certifica.issuance_agency", "disabili_certifica.dis_reg_cer_seq", "disabili_certifica.address", "disabili_certifica.extraAddr"]);

            if (isValid === false) {
                handleButton(scenarioType.VALID_CRTF, message)
                return false
            }

            return true
        }
        else if (nextScenario === "create_account") {

            const isValid = await trigger(["member_info.protector_nm", "member_info.protector_relation", "member_info.equipment_st", "member_info.inflow"]);

            if (watchField.member_info.image_file_path.length === 0) {

                setError("member_info.image_file_path", {
                    message: "필수 입력 항목입니다."
                }, {shouldFocus: true});

                handleButton(scenarioType.VALID_ADDINFO, message)

                return false
            }

            if ( isValid === false ) {
                handleButton(scenarioType.VALID_ADDINFO, message)
            }

            return isValid
        }
        else if (nextScenario === "insert_account") {
            onSubmit(watchField)
        }
        else {

            return true
        }
    }

    const vaildateUserInput = async (value) => {
        const _uuid = "||" + uuid()

        const validationId = /^(?=.*[A-Za-z])[A-Za-z0-9]{4,19}$/;
        const validationPw = /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,32}$/;
        const validationEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const validationPhone = /^[0-9]+(-[0-9]+)?$/;

        try {
            if ((_scenarioId[0] === scenarioType.GREETING) || (_scenarioId[0] === scenarioType.TERMSDISAGREE)) {
                let syn = await synonymCheck(value);

                if (syn === "" || syn !== '네, 동의합니다') {
                    setScenarioId(scenarioType.TERMSDISAGREE + _uuid)
                } else if (syn === '네, 동의합니다') {
                    setScenarioId(scenarioType.SELECT_TYPE + _uuid)
                }

            } else if (_scenarioId[0] === scenarioType.SELECT_TYPE) {
                let syn = await synonymCheck(value);

                if (syn === '계정을 만들고 싶어요') {
                    setScenarioId(scenarioType.CREATE_ID + _uuid)
                } else if (syn === 'SNS 계정으로 시작하고 싶어요') {
                    setScenarioId(scenarioType.SELECT_SOCIAL + _uuid)
                } else {
                    setScenarioId(scenarioType.SELECT_TYPE + _uuid)
                }

            } else if (_scenarioId[0] === scenarioType.SELECT_SOCIAL) {
                let syn = await synonymCheck(value)

                if (syn === '구글') {
                    handleButton("google", inputValue)
                } else if (syn === '카카오') {
                    handleButton("kakao", inputValue)
                } else if (syn === '네이버') {
                    handleButton("naver", inputValue)
                } else if (syn === '페이스북') {
                    handleButton("facebook", inputValue)
                } else {
                    setScenarioId(scenarioType.SELECT_SOCIAL + _uuid)
                }

            } else if ((_scenarioId[0] === scenarioType.CREATE_ID) || (_scenarioId[0] === scenarioType.DUPLICATE_ID) || (_scenarioId[0] === scenarioType.VALID_ID)) {
                if (!validationId.test(value)) {
                    setScenarioId(scenarioType.VALID_ID + _uuid)
                } else {
                    const DuplicateCheck = await idDuplicateCheck(value, false);

                    if (DuplicateCheck === false) {
                        setScenarioId(scenarioType.DUPLICATE_ID + _uuid)
                    } else {
                        setValue("member_com.member_id", value)
                        setScenarioId(scenarioType.INSERT_ID + _uuid)
                    }
                }

            } else if (_scenarioId[0] === scenarioType.INSERT_ID) {
                let syn = await synonymCheck(value);

                if (syn === "" || syn !== '네, 동의합니다') {
                    setScenarioId(scenarioType.CREATE_ID + _uuid)
                } else if (syn === '네, 동의합니다') {
                    setScenarioId(scenarioType.CREATE_PW + _uuid)
                }

            } else if ((_scenarioId[0] === scenarioType.CREATE_PW) || (_scenarioId[0] === scenarioType.VALID_PW)) {
                if (!validationPw.test(value)) {
                    setScenarioId(scenarioType.VALID_PW + _uuid)
                } else {
                    setValue("member_com.member_pw", value)
                    setScenarioId(scenarioType.CREATE_PW_CHECK + _uuid)
                }

            } else if ((_scenarioId[0] === scenarioType.CREATE_PW_CHECK) || (_scenarioId[0] === scenarioType.CHECK_PW)) {
                if (value !== watchField.member_com.member_pw) {
                    setScenarioId(scenarioType.CHECK_PW + _uuid)
                } else {
                    setScenarioId(scenarioType.INSERT_PW + _uuid)
                }

            } else if (_scenarioId[0] === scenarioType.INSERT_PW) {
                let syn = await synonymCheck(value);

                if (syn === "" || syn !== '네, 동의합니다') {
                    setScenarioId(scenarioType.CREATE_PW + _uuid)
                } else if (syn === '네, 동의합니다') {
                    setScenarioId(scenarioType.CREATE_EMAIL + _uuid)
                }

            } else if ((_scenarioId[0] === scenarioType.CREATE_EMAIL) || (_scenarioId[0] === scenarioType.VALID_EMAIL)) {
                if (!validationEmail.test(value)) {
                    setScenarioId(scenarioType.VALID_EMAIL + _uuid)
                } else {
                    setValue("member_com.email", value)
                    setScenarioId(scenarioType.CREATE_PHONE + _uuid)
                }

            } else if ((_scenarioId[0] === scenarioType.CREATE_PHONE) || (_scenarioId[0] === scenarioType.ERROR_PHONE)) {
                if (!validationPhone.test(value)) {
                    setScenarioId(scenarioType.ERROR_PHONE + _uuid)
                } else {
                    setValue("member_info.phone_no", value)
                    setScenarioId(scenarioType.VALID_PHONE + _uuid)
                }

            } else if (_scenarioId[0] === scenarioType.VALID_PHONE) {
                let syn = await synonymCheck(value)

                if (syn === "" || syn !== '휴대폰 인증하기') {
                    setScenarioId(scenarioType.VALID_PHONE + _uuid)
                } else if (syn === '휴대폰 인증하기') {
                    sendMessage()
                    setScenarioId(scenarioType.VALID_NUM + _uuid)
                }
            } else if (_scenarioId[0] === scenarioType.VALID_NUM || (phone.valid === false && phone.pass === false)) {

                setScenarioId(scenarioType.VALID_NUM + _uuid)
            } else if (_scenarioId[0] === scenarioType.UPLOAD_CRTF) {
                let syn = await synonymCheck(value)
                resetCertification();
                if (syn === "" || syn !== '네, 동의합니다') {
                    handleButton(scenarioType.UPLOAD_CRTF, inputValue)
                } else if (syn === '네, 동의합니다') {
                    handleButton(scenarioType.CREATE_ADDINFO, inputValue)
                }
            } else if ((_scenarioId[0] === scenarioType.CREATE_CRTF) || (_scenarioId[0] === scenarioType.VALID_CRTF)) {
                let syn = await synonymCheck(value)

                if (syn === "" || syn !== '네, 동의합니다') {
                    handleButton(scenarioType.UPLOAD_CRTF, inputValue)
                } else if (syn === '네, 동의합니다') {
                    handleButton(scenarioType.CREATE_ADDINFO, inputValue)
                }
            } else if ((_scenarioId[0] === scenarioType.CREATE_ADDINFO) || (_scenarioId[0] === scenarioType.VALID_ADDINFO) || (_scenarioId[0] === scenarioType.PRESSBUTTON_ADDINFO)) {
                let syn = await synonymCheck(value)

                if (syn === "" || syn !== '작성을 완료했어요') {
                    handleButton(scenarioType.PRESSBUTTON_ADDINFO, inputValue)
                } else if (syn === '작성을 완료했어요') {
                    handleButton(scenarioType.CREATE_ACCOUNT, inputValue)
                }

            } else if (_scenarioId[0] === scenarioType.CREATE_ACCOUNT || _scenarioId[0] === scenarioType.PRESSBUTTON_ACCOUNT) {
                let syn = await synonymCheck(value)

                if (syn === "" || syn !== '가입하기') {
                    handleButton(scenarioType.PRESSBUTTON_ACCOUNT, inputValue)
                } else if (syn === '가입하기') {
                    handleButton(scenarioType.INSERT_ACCOUNT, inputValue)
                }
            }
        } catch (e) {
            console.log(errors)
        }
    }

    async function synonymCheck (input) {
        try {
            if (input !== "") {
                const response = await synonym(input);

                if (response.data.resultCode === 1000 && response.data.resultData) {
                    return response.data.resultData;
                } else {
                    return "";
                }
            } else {
                return "";
            }
        } catch (e) {
            console.log(e);
            return "";
        }
    }

    const handleChangeInput = (e) => {
        setInputValue(e.target.value)
    }

    const handleSendBtn = () => {
        if (_scenarioId[0] === 'create_pw' || _scenarioId[0] === 'valid_pw' || _scenarioId[0] === 'create_pw_check' || _scenarioId[0] === 'check_pw') {
            const maskedPassword  = '*'.repeat(inputValue.length)
            const userChat = createUserChat(maskedPassword, "", "text")
            appendMessage(userChat)
            vaildateUserInput(inputValue)
            resetInputValue()
        }
        else if (['create_crtf', 'valid_crtf', 'create_addinfo', 'valid_addinfo', 'pressbutton_addinfo', 'create_account', 'pressbutton_account'].includes(_scenarioId[0])) {
            vaildateUserInput(inputValue)
            resetInputValue()
        }
        else {
            const userChat = createUserChat(inputValue, "", "text")
            appendMessage(userChat)
            vaildateUserInput(inputValue)
            resetInputValue()
        }

    }

    const resetInputValue = () => {
        setInputValue("")
    }

    const handleButton = async (nextScenario, message) => {
        const valid = await validButton(nextScenario, message)

        if (valid === true) {
            if (nextScenario === "create_account") {

                if (watchField.member_info.image_file_path) {
                    const userChat = createUserChat(watchField.member_info.image_file_path_nm, watchField.member_info.image_file_path, "image")
                    appendMessage(userChat)
                    const scenario = nextScenario + "||" + uuid()
                    setScenarioId(scenario)
                }
            }
            else {

                const userChat = createUserChat(message, "", "text")
                appendMessage(userChat)
                const scenario = nextScenario + "||" + uuid()
                setScenarioId(scenario)
            }
        }

    }

    const resetCertification = () => {
        setCrtfStatus(true);
        setValue('member_com.member_nm', "");
        setValue('disabili_certifica.crtf', "");
        setValue('disabili_certifica.birthday', "");
        setValue('disabili_certifica.sex_gb', "");
        setValue('disabili_certifica.address', "");
        setValue('disabili_certifica.extraAddr', "");
        setValue('disabili_certifica.major_disability_nm', "");
        setValue('disabili_certifica.minor_disability_nm', "");
        setValue('disabili_certifica.total_disability_nm', "");
        setValue('disabili_certifica.issuance_agency', "");
        setValue('disabili_certifica.issuance_dt', "", );
        setValue('disabili_certifica.dis_reg_cer_seq', "");
        setValue('disabili_certifica.confirm_no', "", {});
        clearErrors(['member_com.member_nm','disabili_certifica.crtf','disabili_certifica.birthday','disabili_certifica.sex_gb','disabili_certifica.address','disabili_certifica.extraAddr',
            'disabili_certifica.major_disability_nm','disabili_certifica.minor_disability_nm','disabili_certifica.total_disability_nm','disabili_certifica.issuance_agency',
            'disabili_certifica.issuance_dt','disabili_certifica.dis_reg_cer_seq'])
    }

    const deleteType = (type) => {
        let del = false

        if (_scenarioId[0]==="upload_crtf" || _scenarioId[0]==="valid_crtf") {
            type.push("crtf", "addinfo")
            if (_scenarioId[0]==="upload_crtf")
                del = true;
        } else if (_scenarioId[0] ==="valid_addinfo" || _scenarioId[0] ==="pressbutton_addinfo") {
            type.push("addinfo")
        }

        const updatedData = messages.reduce((acc, item) => {
            if (item.role === "user" && del === true) {
                item.content.map(chat => {
                    if (chat.type === "crtf_input" || chat.type === "image_input") {
                        chat.type = "deleted"
                    }
                })
            }
            // const filteredContent = item.content.filter(contentItem => contentItem.type !== "button");
            const filteredContent = item.content.filter(contentItem => !type.includes(contentItem.type));
            acc.push({ ...item, content: filteredContent });
            return acc;
        }, []);
        setMessages(updatedData)

    }

    const toFirst = () => {
        if(window.confirm("다시 작성하시겠습니까? 지금까지 작성된 내용은 저장되지 않습니다.")){
            window.location.href='/user/signup/intersignup';
        }
    }

    const toQuit =() => {
        if(window.confirm("작성을 종료하시겠습니까? 지금까지 작성된 내용은 저장되지 않습니다.")){
            window.location.href='/';
        }
    }

    const onSubmit = async (data) => {

        try {
            data.disabili_certifica.address =  data.disabili_certifica.address + "|" + data.disabili_certifica.extraAddr;
            data.member_info.phone_no = data.member_info.phone_no.replaceAll("-", "");

            await createUser(data).then((response) => {
                if (response.data.resultCode === 1000) {

                    if ( crtf !== null) {
                        formData.append('image', crtf);
                        formData.append('imageNm', data.disabili_certifica.dis_reg_cer_seq + "." + crtf.type.split('/')[1]);
                        formData.append('originFileNm', crtf.name);
                        formData.append('memSeq', response.data.resultData)

                        uploadImage(formData);
                    }

                    window.location.href="/user/signup/signupsuccess";
                }
            });
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <div>
            <div className="wrapper">
                <main>
                    <div className="join page_join page_join_chat" >
                        <h2 className="page_title">회원 가입하기</h2>
                        <div className="wrap_progressbar">
                            <ul className="progressbar_list progress_step03">
                                <li className="progressbar_item progress_item01"><p>이용약관 동의</p></li>
                                <li className="progressbar_item progress_item02"><p>계정유형 선택</p></li>
                                <li className="progressbar_item progress_item03"><p>회원정보 등록</p></li>
                            </ul>
                        </div>
                        <div className="page_inner" >
                            <div className="area_shade" style={{padding: 0}}>
                                <div className="message_container">
                                    <div className="area_chat" style={{height : "900px", overflowY: "auto"}} ref={scrollRef} >
                                        {
                                            messages.map((message, i) => {
                                                if (message.role === "user") return (
                                                    <div className="send_message_area">
                                                        <div className="send_message_wrap">
                                                            {
                                                                message.content.map(item => {
                                                                    if (item.type === 'text') return (
                                                                        <div className="send_message_box" >
                                                                            <div className="send_message_text">
                                                                                {item.message}
                                                                            </div>
                                                                            <div className="send_message_date">{dayjs(new Date()).format('A hh:mm')}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                    if (item.type === "crtf_input") return (
                                                                        <div className="send_message_box">
                                                                            <div className="box_chat_bg">
                                                                                <div className="wrap_attached_file">
                                                                                    <div className="box_thumbnail">
                                                                                        <Document file={item.file}>
                                                                                            <Page pageNumber={1} width={318} height={422}/>
                                                                                        </Document>
                                                                                    </div>
                                                                                    <div className="box_attached_info">
                                                                                        <p className="attached_file_name">{item.message}</p>
                                                                                        <button
                                                                                            className="btn_attached_delete photo" type="button"
                                                                                            title="첨부파일 삭제" onClick={crtfDelete}></button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                    if (item.type === "image_input") return (
                                                                        <div className="send_message_box" >
                                                                            <div className="box_chat_bg">
                                                                                <div className="wrap_attached_file">
                                                                                    <div className="box_thumbnail">
                                                                                        <img src={item.file} alt=""/>
                                                                                    </div>
                                                                                    <div className="box_attached_info">
                                                                                        <p className="attached_file_name">{item.message}</p>
                                                                                        {/*<button className="btn_attached_delete photo" type="button"*/}
                                                                                        {/*    title="첨부파일 삭제" onClick={imageDelete}></button>*/}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                    if (item.type === "deleted") return (
                                                                        <div className="send_message_box">
                                                                            <div className="box_chat_bg">
                                                                                <p className="attached_note"> 파일이 삭제되었습니다.</p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                                else return <div className="chat_message_area">
                                                    <div className="chat_message_wrap">
                                                        <div className="chat_message_profile">
                                                            <div className="chatbot_photo"></div>
                                                            <div className="chatbot_name">드리미 챗봇</div>
                                                        </div>
                                                        {
                                                            message.content.map((item, i) => {
                                                                if (item.type === "text") return (
                                                                    <div className="chat_message_box" >
                                                                        <div className="chat_message_text">
                                                                            {item.message}
                                                                        </div>
                                                                        <div className="chat_message_date">{dayjs(new Date()).format('A hh:mm')}
                                                                        </div>
                                                                    </div>
                                                                )
                                                                if (item.type === "privacy") return (
                                                                    <div className="chat_message_box">
                                                                        <div className="box_chat_bg wrap_terms">
                                                                            <div className="box_terms">
                                                                                {
                                                                                    item.message
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                if (item.type === "button") return (
                                                                    <div className="chat_message_box">
                                                                        <div className="wrap_btn">
                                                                            {
                                                                                item.buttons.map((button,i) => <button className="btn_inline" type="button" key={i+uuid()}
                                                                                    onClick={() => handleButton(button.action, button.message)}>{button.message}</button>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                                if (item.type === "file") return (
                                                                    <>
                                                                    <div className="chat_message_box">
                                                                        <div className="box_chat_bg">
                                                                            <div className="wrap_attached_file">
                                                                                <div className="box_attached_file">
                                                                                    <input id="attachedFileCertificate" type="file" className="attached_file btn_submit filled_green"
                                                                                            ref={crtfInput} onChange={uploadOcr} accept='.pdf' />
                                                                                    <p className="attached_note">PDF 형식의 파일만 업로드 가능합니다.<br/>※ 10MB 이하만 업로드 가능합니다.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <InterCrtfCertification methods={methods} scenarioId={_scenarioId[0]} crtfStatus={crtfStatus} />
                                                                    </>
                                                                )
                                                                if (item.type === "valid") return (
                                                                    <div className="chat_message_box" >
                                                                        <div className="box_chat_bg">
                                                                            <div className="wrap_certificate">
                                                                                <div className="wrap_box_input">
                                                                                    <div className="box_confirm">
                                                                                        <div className="box_input">
                                                                                            <label htmlFor="member_info.phone_no" className="asterisk">휴대폰 인증</label>
                                                                                            <input type="tel" id="member_info.phone_no" name="member_info.phone_no" placeholder="휴대폰 번호를 입력해 주세요."
                                                                                                   {...register("member_info.phone_no",{
                                                                                                       onChange : (e) => { e.target.value = e.target.value.replace(/[^0-9]/g,'')}
                                                                                                   })}/>
                                                                                            <p className="msg_time">{timeFormat(time)}</p>
                                                                                        </div>
                                                                                        <button type="button" className="btn_submit filled_green" onClick={sendMessage}>재전송</button>
                                                                                    </div>
                                                                                    <div className="box_confirm">
                                                                                        <div className="box_input">
                                                                                            <input type="tel" id="member_info.validNum" name="member_info.validNum" placeholder="인증번호를 입력해 주세요." {...register("member_info.validNum",{
                                                                                                required: '필수 입력 항목입니다.',
                                                                                            })}/>
                                                                                        </div>
                                                                                        <button type="button" className="btn_submit filled_green" onClick={validPhoneCheck}>인증번호 확인</button>
                                                                                    </div>
                                                                                    <p className="msg_err">{errors.member_info?.validNum?.message || errors.member_info?.phone_no?.message}</p>
                                                                                    <p className="msg_phone_valid">{ (!errors.member_info?.validNum && (phone.valid===true || phone.pass===true)) && '인증번호가 확인 되었습니다.' }</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                if (item.type === "crtf") return (
                                                                    <InterCrtfCertification methods={methods} scenarioId={_scenarioId[0]} crtfStatus={crtfStatus}/>
                                                                )
                                                                if (item.type === "addinfo") return (
                                                                    <InterAddInfo methods={methods} imageInput={imageInput} scenarioId={_scenarioId[0]} setImage={setImage}/>
                                                                )
                                                                return <></>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                        {/*<div ref={scrollRef}></div>*/}
                                    </div>

                                    <div className="area_send">
                                        <div className="wrap_send_message">
                                            <input type="text" id="" className="" placeholder="도움이 필요하신가요?"
                                                   value={inputValue} onChange={handleChangeInput} onKeyPress={handleOnKeyPress} />
                                            <button type="button" className="btn_submit filled_green"
                                                    onClick={handleSendBtn}>전송
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wrap_btns">
                                <button className="btn_submit filled_black" onClick={toFirst}>처음부터</button>
                                <button className="btn_submit filled_green" onClick={toQuit}>대화종료</button>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default InterSignup;