import React, {useState} from "react";
import {useFormContext} from "react-hook-form";
import {checkId, countId} from "../../../api/Comp/CompSignup/CompSignup";
import {ErrorMessages} from "../../../utils/Message";

const MemberCom = (props) => {
    const {idCheck, setIdCheck} = props
    const { register, setValue, setError, clearErrors, watch, formState: { errors } } = useFormContext();
    const watchField = watch();

    const duplicateCheckId = async () => {
        const id = watchField.member_com.member_id;

        if (id === "" || id === undefined) {
            setError("member_com.member_id",{
                message: ErrorMessages.required
            }, {shouldFocus: true});
        } else if (!(/^(?=.*[A-Za-z])[A-Za-z0-9]{4,19}$/.test(id))) {
            setError("member_com.member_id",{
                message: ErrorMessages.inValidUserId
            }, {shouldFocus: true});
        } else {
            try {
                const response = await countId(id);

                if (response.data.resultData !== 0) {
                    setError("member_com.member_id", {
                        message: ErrorMessages.duplicateId
                    }, {shouldFocus: true});
                    setValue("member_com.member_id", "", {shouldDirty: true, shouldTouch: true});
                    setIdCheck(false);
                } else {
                    clearErrors('member_com.member_id')
                    setIdCheck(true);
                }

            } catch (error)  {
                console.log(error)
            }
        }
    }

    return (
        <div className="wrap_signup_input wrap_signup_basic">
            <div className="box_sub_title">
                <h4 className="sub_title_signup">계정정보</h4>
            </div>
            <div className="wrap_box_input focus_in">
                <div className="box_confirm">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">아이디</label>
                        <input type="text" id="member_com.member_id" name="member_com.member_id" {...register('member_com.member_id', {
                            required: ErrorMessages.required,
                            pattern: {
                                value: /^(?=.*[A-Za-z])[A-Za-z0-9]{4,19}$/,
                                message: ErrorMessages.inValidUserId
                            },
                            validate: {
                                duplicate: () => idCheck === true || ErrorMessages.duplicateValidation
                            }
                        })}/>
                        <button type="button" className="btn_submit filled_green" onClick={duplicateCheckId} >중복확인</button>
                    </div>
                </div>
                <p className="msg_err">{ errors.member_com?.member_id?.message }</p>
                <p className="msg_id_dup">{ (!errors.member_com?.member_id && idCheck===true) && '사용 가능한 아이디입니다.' }</p>
            </div>
            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="password" className="asterisk">비밀번호</label>
                    <input type="password" id="member_com.member_pw" name="member_com.member_pw" placeholder="비밀번호를 입력해 주세요." autoComplete="new-password" {...register('member_com.member_pw', {
                        required: ErrorMessages.required,
                        pattern: {
                            value: /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,32}$/,
                            message: ErrorMessages.inValidPw
                        }
                    })}/>
                </div>
                <p className="msg_err">{ errors.member_com?.member_pw?.message }</p>
            </div>
            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="password" className="asterisk">비밀번호 확인</label>
                    <input type="password" id="member_com.validPw" name="member_com.validPw" placeholder="위와 동일한 비밀번호를 다시 입력해 주세요." autoComplete="new-password" {...register('member_com.validPw', {
                        required: ErrorMessages.required,
                        validate: {
                            valid: (value) => value === watchField.member_com.member_pw || ErrorMessages.unMatchPw
                        }
                    })}/>
                </div>
                <p className="msg_err">{ errors.member_com?.validPw?.message }</p>

            </div>
        </div>
    )
}

export default MemberCom;