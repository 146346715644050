import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

export const StartDate = ({control,ko,watchField,isEmploymentTypeTemp4RadioChecked,isEmploymentTypeTemp5RadioChecked,disabled,clearErrors, setValue}) => {

return(
    <Controller
        rules={{required: "필수입력 항목입니다."}}
        name={'incruit.recruit_start_date'}
        control={control}
        render={({field: {onChange}}) => (
                <DatePicker
                    name={'incruit.recruit_start_date'}
                    locale={ko}
                    selected={watchField?.incruit?.recruit_start_date ? new Date(watchField.incruit.recruit_start_date) : null}
                    maxDate={watchField.incruit.recruit_end_date == null || watchField.incruit.recruit_end_date == "" ? null : new Date(watchField.incruit.recruit_end_date)}
                    minDate={new Date()} placeholderText="공고시작일을 선택해주세요."
                    onChange={(date) => {
                        try{
                            onChange(dayjs(date).format("YYYY-MM-DD HH:mm"));
                        }catch(e){
                            onChange(null);
                        }
                    }}
                    timeCaption='시간'
                    timeFormat="HH:mm"
                    showTimeSelect
                    timeIntervals={60}
                    dateFormat="yyyy년 MMMM d일, HH:mm"
                    // readOnly={ watchField.incruit.employment_type === "상시 채용" || watchField.incruit.employment_type === "채용 시 마감" ? true : false}
                    className={disabled ? 'gray-datepicker' : ''}
                    disabled={disabled === true ? true : false}
                    onKeyDown={(e) => {
                        const event = e || window.event;
                        if(event.code !="Tab" && event.code != "Enter"){
                            e.preventDefault();
                        }
                    }}
                />

            )
        }
    />
)


}


export const EndDate = ({control,ko,watchField,isEmploymentTypeTemp4RadioChecked,isEmploymentTypeTemp5RadioChecked,disabled,clearErrors, setValue}) => {

    return (
        <Controller
            rules={{
                validate: {
                    requried: (value) => (value !== null || watchField.incruit.employment_type === "상시 채용" || watchField.incruit.employment_type === "채용 시 마감") || "필수 입력 항목입니다.",
                    previousDate: (value) => (value === null || value > watchField.incruit.recruit_start_date) || "공고일을 확인해주세요."
                }
            }}
            name={"incruit.recruit_end_date"}
            control={control}
            render={
                ({field:{onChange}})=>(
                    <DatePicker
                        name={"incruit.recruit_end_date"}
                        locale={ko}
                        selected={watchField?.incruit?.recruit_end_date ? new Date(watchField.incruit.recruit_end_date) : null}
                        minDate={watchField.incruit.recruit_start_date == null || watchField.incruit.recruit_start_date == "" ? new Date() : new Date(watchField.incruit.recruit_start_date)} 
                        placeholderText="공고마감일을 선택해주세요."
                        onChange={(date) => {
                            try{
                                onChange(dayjs(date).format("YYYY-MM-DD HH:mm"));
                                setValue("incruit.employment_type", "");
                            }catch(e){
                                onChange(null);
                            }
                        }}
                        timeCaption='시간'
                        timeFormat="HH:mm"
                        showTimeSelect
                        timeIntervals={60}
                        dateFormat="yyyy년 MMMM d일, HH:mm"
                        // readOnly={ watchField.incruit.recruit_end_date !== null || watchField.incruit.employment_type === "상시 채용" || watchField.incruit.employment_type === "채용 시 마감" ? true : false}
                        className={disabled || watchField.incruit.employment_type === "상시 채용" || watchField.incruit.employment_type === "채용 시 마감" ? 'gray-datepicker' : ''}
                        // disabled={watchField.incruit.recruit_end_date !== null || watchField.incruit.employment_type === "상시 채용" || watchField.incruit.employment_type === "채용 시 마감" ? true : false}
                        onKeyDown={(e) => {
                            const event = e || window.event;
                            if(event.code !="Tab" && event.code != "Enter"){
                                e.preventDefault();
                            }
                        }}
                    />
                )

            }
        />
    )
}

                            

