import React from 'react';
const Loading = () => {
    return (
        <div style={{textAlign:'center'}}>
            <h3>잠시만 기다려주세요.</h3>
            <div>
                <img src="/img/icon/Spiner.gif" alt="로딩중" style={{width:'200px', margin:'auto'}}/>
            </div>
        </div>    
    )
};
export default Loading;