import RecJob from "./RecJob";
import DesiredJob from "./DesiredJob";
import JobSearch from "./JobSearch";
import { useEffect, useLayoutEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  createInter,
  deleteInter,
  getJobSearchList,
  getRecruitListByInterest,
  getUserInter,
  jobRecom,
  plusViewCount,
} from "../../../api/User/Jop/Job";

const JobInfo = () => {
  const [user, setUser] = useState(null);
  const [clickInter, setClickInter] = useState(false);
  const [interList, setInterList] = useState([]);
  const [recJobDepth1th, setRecJobDepth1th] = useState([]);
  const [allJobList, setAllJobList] = useState([]);
  const [recJobList, setRecJobList] = useState([]);
  const [allInterJobList, setAllInterJobList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [activeTab, setActiveTab] = useState("JobSearch");
  const history = useHistory();
  const location = useLocation();

  useLayoutEffect(() => {
    if (
      localStorage.getItem("checkResume") === "true" &&
      localStorage.getItem("checkAbility") === "true"
    ) {
      setActiveTab("RecJob");
    }
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  useEffect(() => {
    const hash = location.hash.slice(1); // '#' 제거
    if (hash === "recommended") {
      setActiveTab("RecJob");
    } else if (hash === "desired") {
      setActiveTab("DesiredJob");
    } else if (hash === "search") {
      setActiveTab("JobSearch");
    }
  }, [location]);

  // 추천채용정보 조회
  useLayoutEffect(() => {
    try {
      if (
        localStorage.getItem("accessToken") !== null &&
        localStorage.getItem("user") !== null
      ) {
        jobRecom().then((response) => {
          if (response.data.resultCode === 1000 && response.data.resultData) {
            const temp = [...response.data.resultData];
            temp.map((data) => {
              data.recomJobList.sort((a, b) => {
                if (
                  a.incruit.employment_type === "" &&
                  b.incruit.employment_type !== ""
                )
                  return -1;
                if (
                  a.incruit.employment_type === "" &&
                  b.incruit.employment_type === ""
                ) {
                  return (
                    calculatedDays(a.incruit.recruit_end_date) -
                    calculatedDays(b.incruit.recruit_end_date)
                  );
                }
                if (
                  a.incruit.employment_type === "채용 시 마감" &&
                  b.incruit.employment_type === "상시 채용"
                )
                  return -1;
                if (
                  a.incruit.employment_type === "상시 채용" &&
                  b.incruit.employment_type !== "상시 채용"
                )
                  return 1;
                return 0;
              });
            });
            temp.forEach((data) => {
              data.recomJobList.forEach((post) => {
                setRecJobDepth1th((prev) => {
                  const recJob = [...prev, post];
                  recJob.sort((a, b) => {
                    if (
                      a.incruit.employment_type === "" &&
                      b.incruit.employment_type !== ""
                    )
                      return -1;
                    if (
                      a.incruit.employment_type === "" &&
                      b.incruit.employment_type === ""
                    ) {
                      return (
                        calculatedDays(a.incruit.recruit_end_date) -
                        calculatedDays(b.incruit.recruit_end_date)
                      );
                    }
                    if (
                      a.incruit.employment_type === "채용 시 마감" &&
                      b.incruit.employment_type === "상시 채용"
                    )
                      return -1;
                    if (
                      a.incruit.employment_type === "상시 채용" &&
                      b.incruit.employment_type !== "상시 채용"
                    )
                      return 1;
                  });
                  return recJob;
                });
              });
            });
            setRecJobList(temp);
          }
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      // 전체채용정보 조회
      getJobSearchList().then((response) => {
        if (response.data.resultCode === 1000 && response.data.resultData) {
          const temp = [...response.data.resultData];
          if (
            localStorage.getItem("checkResume") === "true" &&
            localStorage.getItem("checkAbility") === "true"
          ) {
            temp.sort((a, b) => {
              if (
                checkedDepth1th(a.enterprise.seq) === true &&
                checkedDepth1th(b.enterprise.seq) !== true
              )
                return -1;
              if (
                checkedDepth1th(a.enterprise.seq) !== true &&
                checkedDepth1th(b.enterprise.seq) === true
              )
                return 1;

              if (
                a.incruit.employment_type === "" &&
                b.incruit.employment_type !== ""
              )
                return -1;
              if (
                a.incruit.employment_type === "" &&
                b.incruit.employment_type === ""
              ) {
                return (
                  calculatedDays(a.incruit.recruit_end_date) -
                  calculatedDays(b.incruit.recruit_end_date)
                );
              }
              if (
                a.incruit.employment_type === "채용 시 마감" &&
                b.incruit.employment_type === "상시 채용"
              )
                return -1;
              if (
                a.incruit.employment_type === "상시 채용" &&
                b.incruit.employment_type !== "상시 채용"
              )
                return 1;
            });
            setJobList(temp);
          } else {
            temp.sort((a, b) => {
              if (
                a.incruit.employment_type === "" &&
                b.incruit.employment_type !== ""
              )
                return -1;
              if (
                a.incruit.employment_type === "" &&
                b.incruit.employment_type === ""
              ) {
                return (
                  calculatedDays(a.incruit.recruit_end_date) -
                  calculatedDays(b.incruit.recruit_end_date)
                );
              }
              if (
                a.incruit.employment_type === "채용 시 마감" &&
                b.incruit.employment_type === "상시 채용"
              )
                return -1;
              if (
                a.incruit.employment_type === "상시 채용" &&
                b.incruit.employment_type !== "상시 채용"
              )
                return 1;
            });
            setJobList(temp);
          }
        }
      });
    }
  }, []);

  // 관심채용정보 조회
  useLayoutEffect(() => {
    if (
      localStorage.getItem("accessToken") !== null &&
      localStorage.getItem("user") !== null &&
      clickInter === false
    ) {
      try {
        getRecruitListByInterest()
          .then((response) => {
            if (response.data.resultCode === 1000 && response.data.resultData) {
              const temp = [...response.data.resultData];
              temp.sort((a, b) => {
                if (
                  (calculatedDays(a.incruit.recruit_end_date) >= 0 ||
                    a.incruit.employment_type !== "") &&
                  a.incruit.recruit_status === "공고중" &&
                  !(
                    (calculatedDays(b.incruit.recruit_end_date) >= 0 ||
                      b.incruit.employment_type !== "") &&
                    b.incruit.recruit_status === "공고중"
                  )
                )
                  return -1;
                if (
                  !(
                    (calculatedDays(a.incruit.recruit_end_date) >= 0 ||
                      a.incruit.employment_type !== "") &&
                    a.incruit.recruit_status === "공고중"
                  ) &&
                  (calculatedDays(b.incruit.recruit_end_date) >= 0 ||
                    b.incruit.employment_type !== "") &&
                  b.incruit.recruit_status === "공고중"
                )
                  return 1;

                if (
                  a.incruit.employment_type === "" &&
                  b.incruit.employment_type !== ""
                )
                  return -1;
                if (
                  a.incruit.employment_type === "" &&
                  b.incruit.employment_type === ""
                ) {
                  return (
                    calculatedDays(a.incruit.recruit_end_date) -
                    calculatedDays(b.incruit.recruit_end_date)
                  );
                }
                if (
                  a.incruit.employment_type === "채용 시 마감" &&
                  b.incruit.employment_type === "상시 채용"
                )
                  return -1;
                if (
                  a.incruit.employment_type === "상시 채용" &&
                  b.incruit.employment_type !== "상시 채용"
                )
                  return 1;
                return 0;
              });
              setAllInterJobList(temp);
            } else {
              setAllInterJobList([]);
            }
          })
          .finally(() => setClickInter(false));
      } catch (e) {
        console.log(e);
      }
    }
  }, [clickInter]);

  useEffect(() => {
    if (
      localStorage.getItem("accessToken") !== null &&
      localStorage.getItem("user") !== null
    ) {
      try {
        getUserInter()
          .then((response) => {
            if (response.data.resultCode === 1000 && response.data.resultData) {
              setInterList(() => response.data.resultData);
            }
          })
          .finally(() => setClickInter(false));
      } catch (e) {
        console.log(e);
      }
    }
  }, [clickInter]);

  const handleInterest = (e) => {
    const recruitSeq = parseInt(e.target.defaultValue);
    if (e.target.checked === true && recruitSeq !== null) {
      try {
        createInter(recruitSeq);
      } catch (e) {
        console.log(e);
      }
    } else if (e.target.checked === false && recruitSeq !== null) {
      try {
        deleteInter(recruitSeq);
      } catch (e) {
        console.log(e);
      }
    }
    setClickInter(true);
  };

  function checkedDepth1th(recruitSeq) {
    let check = false;
    recJobDepth1th.slice(0, 3).map((data) => {
      if (data.enterprise.seq === recruitSeq) {
        check = true;
      }
    });
    return check;
  }

  function checkedInter(recruitSeq) {
    let check = false;
    interList.map((data) => {
      if (data.recruit_seq === recruitSeq) {
        check = true;
      }
    });
    return check;
  }

  function calculatedDays(dateString) {
    const dateObj = new Date(dateString);
    const timeDiff = dateObj.getTime() - Date.now();
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysLeft;
  }

  const toDetailPage = (data) => {
    history.push(`/user/job/detailPage/${data.enterprise.seq}`);
    window.scrollTo(0, 0);
    try {
      plusViewCount(data.enterprise.seq);
    } catch (e) {
      console.log(e);
    }
  };

  const handleTabClick = (tabClass) => {
    setActiveTab(tabClass);
    let newPath = "/user/job/recruitInfo";
    if (tabClass === "RecJob") {
      newPath += "#recommended";
    } else if (tabClass === "DesiredJob") {
      newPath += "#desired";
    } else if (tabClass === "JobSearch") {
      newPath += "#search";
    }
    history.push(newPath);
  };

  return (
    <div className="wrapper">
      <main>
        <div className="page page_applicant_list page_layout_40">
          <h2 className="page_back_title border_bottom_line">채용정보</h2>
          <div className="page_description">
            <p>
              다양한 기업들의 최신 채용정보를 제공하며 나에게 맞는 일자리를
              찾아드립니다.
            </p>
            <p>개인의 경력과 원하는 조건에 맞는 기업을 찾아보세요.</p>
          </div>
          <ul className="tab_menu">
            {user && user.roleType === "COMPANY" ? null : (
              <li className="tab_menu_list">
                <a
                  href="#recommended"
                  target="_self"
                  className={`tab_page_01 ${activeTab === "RecJob" ? "active" : ""}`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      localStorage.getItem("accessToken") !== null &&
                      localStorage.getItem("checkResume") === "true" &&
                      localStorage.getItem("checkAbility") === "true"
                    ) {
                      handleTabClick("RecJob");
                    } else {
                      alert(
                        "이력서 및 역량평가 완료 후 회원님과 어울리는 직무 추천정보와 다양한 채용정보를 확인할 수 있습니다."
                      );
                    }
                  }}
                >
                  추천 채용정보
                </a>
              </li>
            )}
            {user && user.roleType === "COMPANY" ? null : (
              <li className="tab_menu_list">
                <a
                  href="#desired"
                  target="_self"
                  className={`tab_page_02 ${activeTab === "DesiredJob" ? "active" : ""}`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      localStorage.getItem("accessToken") !== null &&
                      localStorage.getItem("checkResume") === "true" &&
                      localStorage.getItem("checkAbility") === "true"
                    ) {
                      handleTabClick("DesiredJob");
                    } else {
                      alert(
                        "이력서 및 역량평가 완료 후 회원님과 어울리는 직무 추천정보와 다양한 채용정보를 확인할 수 있습니다."
                      );
                    }
                  }}
                >
                  관심 채용정보
                </a>
              </li>
            )}
            <li className="tab_menu_list">
              <a
                href="#search"
                target="_self"
                className={`tab_page_03 ${activeTab === "JobSearch" ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("JobSearch");
                }}
              >
                채용정보 검색
              </a>
            </li>
          </ul>
          <div className="page_inner tab_content_wrap">
            {activeTab === "RecJob" ? (
              <RecJob
                user={user}
                recJobList={recJobList}
                setRecJobList={setRecJobList}
                checkedInter={checkedInter}
                handleInterest={handleInterest}
                toDetailPage={toDetailPage}
              />
            ) : activeTab === "DesiredJob" ? (
              <DesiredJob
                allInterJobList={allInterJobList}
                setAllInterJobList={setAllInterJobList}
                user={user}
                checkedInter={checkedInter}
                handleInterest={handleInterest}
                calculatedDays={calculatedDays}
                toDetailPage={toDetailPage}
              />
            ) : (
              <JobSearch
                jobList={jobList}
                setJobList={setJobList}
                checkedInter={checkedInter}
                handleInterest={handleInterest}
                calculatedDays={calculatedDays}
                toDetailPage={toDetailPage}
                checkedDepth1th={checkedDepth1th}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default JobInfo;
