import { useState, useEffect, useRef } from "react";
import Pagination from "./Pagination";
import api from "../../../api/api";

const ChooseJobModal = (props) => {
  const [selectLCate, setSelectLCate] = useState([]);
  const [selectMCate, setSelectMCate] = useState([]);
  const [selectSCate, setSelectSCate] = useState([]);
  const [selectDCate, setSelectDCate] = useState([]);
  const [inputText, setInputText] = useState("");
  const [inputCateCode, setinputCateCode] = useState("");
  const [checkboxValue, setCheckboxValue] = useState(props.selectedCheckboxes);
  const [FirstDesired, setFirstDesired] = useState(props.FirstDesired);
  const [SecondDesired, setSecondDesired] = useState(props.SecondDesired);
  const [ThirdDesired, setThirdDesired] = useState(props.ThirdDesired);
  const mCateRef = useRef(null);
  const sCateRef = useRef(null);
  const [limit, setLimit] = useState(5);
  const [visiblePageCount, setVisiblePageCount] = useState(10);
  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;

  const handleCheckboxToggle = (e, value) => {
    setCheckboxValue(value);
    const checkboxes = document.getElementsByName("group");
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i] !== e.target) {
        checkboxes[i].checked = false;
      }
    }

    props.onCheckboxChange(value);
  };

  useEffect(() => {
    props.onCheckboxChange(checkboxValue);
  }, [checkboxValue, props.onCheckboxChange]);

  const handleOutSideClick = (e) => {
    if (props.modal) {
      props.onClose();
    }
  };
  const handleSave = (e) => {
    if (e) e.preventDefault();

    props.onCheckboxChange(checkboxValue);
    props.onClose();
  };

  const disableModal = () => {
    props.setModal(false);
  };
  useEffect(() => {
    handleCATE();
  }, []);
  const handleCATE = (e) => {
    api
      .post("/api/resume/getLCateList", {})
      .then((response) => response.data)
      .then((response) => {
        setSelectLCate(response.resultData);
      });
  };
  const handleLCATE = (e) => {
    api
      .post("/api/resume/getMCateList", {
        cate_seq: e.target.value,
      })
      .then((response) => response.data)
      .then((response) => {
        setSelectMCate(response.resultData);
        if (mCateRef.current) {
          mCateRef.current.value = ""; // Reset the value
          mCateRef.current.selectedIndex = 0; // Set the first option as selected
        }
        setSelectSCate([]);
        setinputCateCode(e.target.value);
        api
          .post("/api/resume/getLMSDCateList", {
            cate_nm: inputText,
            cate_seq: e.target.value,
          })
          .then((response) => response.data)
          .then((response) => {
            setSelectDCate(response.resultData);
          });
      });
  };
  const handleMCATE = (e) => {
    api
      .post("/api/resume/getSCateList", {
        cate_seq: e.target.value,
      })
      .then((response) => response.data)
      .then((response) => {
        setSelectSCate(response.resultData);
        if (sCateRef.current) {
          sCateRef.current.value = ""; // Reset the value
          sCateRef.current.selectedIndex = 0; // Set the first option as selected
        }
        setinputCateCode(e.target.value);
        api
          .post("/api/resume/getLMSDCateList", {
            cate_nm: inputText,
            cate_seq: e.target.value,
          })
          .then((response) => response.data)
          .then((response) => {
            setSelectDCate(response.resultData);
          });
      });
  };

  const handleSCATE = (e) => {
    api
      .post("/api/resume/getDCateList", {
        cate_seq: e.target.value,
      })
      .then((response) => response.data)
      .then((response) => {
        setSelectDCate(response.resultData);
        setinputCateCode(e.target.value);
        api
          .post("/api/resume/getLMSDCateList", {
            cate_nm: inputText,
            cate_seq: e.target.value,
          })
          .then((response) => response.data)
          .then((response) => {
            setSelectDCate(response.resultData);
          });
      });
  };

  const searchButton = (e) => {
    if (e) e.preventDefault();

    api
      .post("/api/resume/getLMSDCateList", {
        cate_nm: inputText,
        cate_seq: inputCateCode,
      })
      .then((response) => response.data)
      .then((response) => {
        setSelectDCate(response.resultData);
      });
  };
  const activeEnter = (e) => {
    if (e.key === "Enter") {
      searchButton();
    }
  };
  const colWidth64 = {
    width: "64px",
  };
  const colWidthAuto = {
    width: "auto",
  };
  const displayOn = {
    display: "block",
  };
  const displayOff = {
    display: "none",
  };

  return (
    <div>
      <div
        className="popup_mask"
        style={displayOn}
        onClick={handleOutSideClick}
      ></div>
      <div className="popup_layer pop_lg" id="popLSelectJob" style={displayOn}>
        <div className="popup_inner">
          <div className="pop_title">
            {props.category === "desired" ? (
              <h3>희망직무 선택하기</h3>
            ) : (
              <h3>경력직무 검색하기</h3>
            )}
            <button
              type="button"
              aria-label="레이어 팝업 닫기"
              className="popup_close"
              onClick={disableModal}
            ></button>
          </div>
          <div className="popup_contents">
            <div className="wrap_box_search">
              <div className="box_division">
                <div className="wrap_box_input">
                  <div className="box_input">
                    <label htmlFor="" className="">
                      대분류{" "}
                    </label>
                    <select onChange={handleLCATE} defaultValue="">
                      <option value="">대분류를 선택해주세요.</option>
                      {selectLCate.map((item) => (
                        <option key={item.cate_cd} value={item.cate_seq}>
                          {item.cate_cdnm}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="wrap_box_input">
                  <div className="box_input">
                    <label htmlFor="" className="">
                      중분류
                    </label>
                    <select
                      ref={mCateRef}
                      onChange={handleMCATE}
                      defaultValue=""
                    >
                      <option value="">중분류를 선택해주세요.</option>
                      {selectMCate.map((item) => (
                        <option key={item.cate_cd} value={item.cate_seq}>
                          {item.cate_cdnm}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="wrap_box_input">
                  <div className="box_input">
                    <label htmlFor="" className="">
                      소분류
                    </label>
                    <select
                      ref={sCateRef}
                      onChange={handleSCATE}
                      defaultValue=""
                    >
                      <option value="">소분류를 선택해주세요.</option>
                      {selectSCate.map((item) => (
                        <option key={item.cate_cd} value={item.cate_seq}>
                          {item.cate_cdnm}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="wrap_box_input wrap_box_input_text">
                  <div className="box_input">
                    <label htmlFor="" className="">
                      세분류
                    </label>
                    <input
                      type="text"
                      id="dCateNm"
                      className=""
                      name="dCateNm"
                      onChange={(e) => setInputText(e.target.value)}
                      onKeyDown={(e) => activeEnter(e)}
                      placeholder="검색어 예시: 사무, 디자인, 마케팅"
                    />
                  </div>
                </div>
              </div>
              <a
                href="#"
                target="_self"
                className="btn_submit filled_green"
                onClick={searchButton}
              >
                {" "}
                검색하기
              </a>
            </div>
            <div className="wrap_box_search_result">
              <div className="box_search_result">
                <table className="table_detail">
                  <colgroup>
                    <col style={colWidth64} />
                    <col style={colWidth64} />
                    <col style={colWidthAuto} />
                    <col style={colWidthAuto} />
                    <col style={colWidthAuto} />
                    <col style={colWidthAuto} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">번호</th>
                      <th scope="col">대분류</th>
                      <th scope="col">중분류</th>
                      <th scope="col">소분류</th>
                      <th scope="col">세분류</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectDCate
                      .slice(offset, offset + limit)
                      .map((item, i) => {
                        const isDesiredCategory =
                          item.d_cate_cdnm === FirstDesired?.d_cate_cdnm ||
                          item.d_cate_cdnm === SecondDesired?.d_cate_cdnm ||
                          item.d_cate_cdnm === ThirdDesired?.d_cate_cdnm;
                        return (
                          <tr key={i}>
                            <td>
                              <div className="box_input_chk">
                                <label
                                  htmlFor={"group" + i}
                                  className="input_chk_label"
                                >
                                  <input
                                    type="checkbox"
                                    className="input_chk"
                                    id={"group" + i}
                                    name="group"
                                    onChange={(e) =>
                                      handleCheckboxToggle(e, item)
                                    }
                                    disabled={isDesiredCategory}
                                  />
                                </label>
                              </div>
                            </td>
                            <td>{(page - 1) * limit + i + 1}</td>
                            <td>{item.l_cate_cdnm}</td>
                            <td>{item.m_cate_cdnm}</td>
                            <td>{item.s_cate_cdnm}</td>
                            <td>{item.d_cate_cdnm}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="wrap_pagination">
              <Pagination
                total={selectDCate.length}
                limit={limit}
                page={page}
                setPage={setPage}
                visiblePageCount={visiblePageCount}
              />
            </div>
          </div>
          <div className="wrap_btns flex_ed">
            <a
              href="#"
              target="_self"
              className="btn_submit filled_green"
              onClick={handleSave}
            >
              다음으로
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChooseJobModal;
