import { useEffect, useState } from "react";
import api, { decodeToken } from "../../../api/api";
import { useHistory } from "react-router-dom";
import ResultModal from "./resultModal";
import Aside from "../../../components/lnb/Aside";

const AbilityMain = () => {
  const [result, setResult] = useState({});
  const [modal, setModal] = useState(false);
  const dToken = decodeToken(localStorage.getItem("accessToken"));
  const userId = dToken.sub;

  useEffect(() => {
    api
      .post("/api/ability/abilityChk")
      .then((response) => response.data)
      .then((response) => {
        setResult(response);
      });
    localStorage.removeItem("abilityResult_" + userId);
    localStorage.removeItem("category_" + userId);
  }, []);
  //체크 (value : -1(역량평가진행X) / 0+ : 역량평가진행 경과일)
  const abilityChk =
    result && result.resultData && result.resultData.ability_chk;
  const useHis = useHistory();
  const goToprocced = (e) => {
    e.preventDefault();
    useHis.push("/user/ability/procced", { checked: true });
  };

  return (
    <>
      <Aside />
      <div className="wrapper">
        <main>
          <div className="page ability page_layout_40">
            <h2 className="page_back_title page_inner_above">역량평가</h2>
            <div className="page_inner">
              <div className="area_shade">
                <div className="wrap_txt">
                  <p>
                    ·개인의 잠재력과 성장 가능성을 평가하기 위한 역량평가를
                    실시합니다. <br></br>
                    ·역량평가 결과를 기반으로 보다 나은 취업 기회와 더욱 향상된
                    직무 성과를 이룰 수 있습니다. <br></br>
                    ·역량평가는 30일 이후에 재시도 할 수 있으니 질문에 신중히
                    답하여 주시기 바랍니다.
                  </p>
                </div>
                <div className="wrap_btns">
                  {abilityChk == null ? (
                    <a
                      href="#"
                      target="_self"
                      className="btn_submit filled_green"
                      onClick={goToprocced}
                      dangerouslySetInnerHTML={{ __html: "역량평가하기" }}
                    ></a>
                  ) : (
                    <>
                      <a
                        href="#"
                        target="_self"
                        className="btn_submit filled_green"
                        onClick={goToprocced}
                        dangerouslySetInnerHTML={{ __html: "역량평가하기" }}
                      ></a>
                      <a
                        href="#"
                        target="_self"
                        className="btn_submit lined_green"
                        onClick={(e) => {
                          e.preventDefault();
                          setModal(true);
                        }}
                        dangerouslySetInnerHTML={{ __html: "최근 결과보기" }}
                      ></a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {modal ? (
        <ResultModal
          setModal={setModal}
          abilityResult={result && result.resultData}
        />
      ) : null}
    </>
    /*
        <h1>역량평가</h1>
        {
            abilityChk && abilityChk == -1 ? (
                <div>
                    <a href='#' onClick={goToprocced} className='' style={{width : '200px', textAlign : 'center', backgroundColor : 'red', verticalAlign : 'center', padding : '10px 0', display : 'inline-block'}}>역량평가하기</a>
                </div>
            ) : (
                <div>
                    {abilityChk && (30 - abilityChk) >= 0 ?
                        <>
                            <span>재응시 {30 - abilityChk}일 남음</span>
                            <a  href='#' onClick={goToprocced}  className='' style={{opacity: '0.7' ,width : '200px', textAlign : 'center', backgroundColor : 'red', verticalAlign : 'center', padding : '10px 0', display : 'inline-block'}}>역량평가하기</a>
                        </>
                        :
                        <a href='#' className='' style={{width : '200px', textAlign : 'center', backgroundColor : 'red', verticalAlign : 'center', padding : '10px 0', display : 'inline-block'}}>역량평가하기</a>
                    }
                    <br></br>
                    <a href='#' className='' style={{width : '200px', textAlign : 'center', backgroundColor : 'yellow', verticalAlign : 'center', padding : '10px 0', display : 'inline-block'}}>최근 결과보기</a>

                </div>
            )
        }
        <br></br>
        <br></br><br></br>
        <a href='#' className='' style={{width : '200px', textAlign : 'center', backgroundColor : 'red', verticalAlign : 'center', padding : '10px 0', display : 'inline-block'}}>역량평가하기</a>
        <br></br>
        <a href='#' className='' style={{width : '200px', textAlign : 'center', backgroundColor : 'yellow', verticalAlign : 'center', padding : '10px 0', display : 'inline-block'}}>최근 결과보기</a>

        <div>{result.resultCode}</div>
        <div>{result.resultMsg}</div>
        <div>{result && result.resultData && result.resultData.ability_chk}</div>
        <div>{result && result.resultData && result.resultData.userPhysicalVO && result.resultData.userPhysicalVO.create_dt}</div>*/
  );
};

export default AbilityMain;
