import React, { useEffect, useState } from "react";
import api from "../../../api/api";
import Echarts from "echarts-for-react";

const initVal1 = {
  radar: {
    indicator: [],
    shape: "line",
    splitNumber: 5,
    axisName: {
      color: "black",
    },
    splitLine: {
      lineStyle: {
        color: ["#cfcfcf"],
      },
    },
    splitArea: {
      areaStyle: {
        color: ["#eeeeee", "#ffffff", "#eeeeee", "#ffffff", "#eeeeee"],
      },
    },
  },
  series: [
    {
      type: "radar",
      data: [
        {
          value: [],
        },
      ],
      symbol: "circle",
      symbolSize: 10,
      lineStyle: {
        color: "#f49c9c",
      },
      itemStyle: {
        color: "rgb(244, 156, 156, 0.3)",
        borderColor: "#f49c9c",
        borderWidth: 1,
        borderType: "solid",
      },
      areaStyle: {
        color: "rgb(244, 156, 156, 0.3)",
      },
    },
  ],
};

const initVal2 = {
  radar: {
    indicator: [],
    shape: "line",
    splitNumber: 5,
    axisName: {
      color: "black",
    },
    splitLine: {
      lineStyle: {
        color: ["#cfcfcf"],
      },
    },
    splitArea: {
      areaStyle: {
        color: ["#eeeeee", "#ffffff", "#eeeeee", "#ffffff", "#eeeeee"],
      },
    },
  },
  series: [
    {
      type: "radar",
      data: [
        {
          value: [],
        },
      ],
      symbol: "circle",
      symbolSize: 10,
      lineStyle: {
        color: "#f49c9c",
      },
      itemStyle: {
        color: "rgb(244, 156, 156, 0.3)",
        borderColor: "#f49c9c",
        borderWidth: 1,
        borderType: "solid",
      },
      areaStyle: {
        color: "rgb(244, 156, 156, 0.3)",
      },
    },
  ],
};

const initVal3 = {
  radar: {
    indicator: [],
    shape: "line",
    splitNumber: 5,
    axisName: {
      color: "black",
    },
    splitLine: {
      lineStyle: {
        color: ["#cfcfcf"],
      },
    },
    splitArea: {
      areaStyle: {
        color: ["#eeeeee", "#ffffff", "#eeeeee", "#ffffff", "#eeeeee"],
      },
    },
  },
  series: [
    {
      type: "radar",
      data: [
        {
          value: [],
        },
      ],
      symbol: "circle",
      symbolSize: 10,
      lineStyle: {
        color: "#f49c9c",
      },
      itemStyle: {
        color: "rgb(244, 156, 156, 0.3)",
        borderColor: "#f49c9c",
        borderWidth: 1,
        borderType: "solid",
      },
      areaStyle: {
        color: "rgb(244, 156, 156, 0.3)",
      },
    },
  ],
};

const ConfirmModal = (props) => {
  const [fadeInOut, setFadeInOut] = useState("fade-in");
  const [options1, setOptions1] = useState({});
  const [options2, setOptions2] = useState({});
  const [options3, setOptions3] = useState({});
  const [abilityTestResult, setAbilityTestResult] = useState({});

  const userString = localStorage.getItem("user");
  const userObject = JSON.parse(userString);
  const userName = userObject.username;

  useEffect(() => {
    initVal1.radar.indicator = [];
    initVal1.series[0].data[0].value = [];
    initVal2.radar.indicator = [];
    initVal2.series[0].data[0].value = [];
    initVal3.radar.indicator = [];
    initVal3.series[0].data[0].value = [];
    let tmp1 = { ...initVal1 };
    let tmp2 = { ...initVal2 };
    let tmp3 = { ...initVal3 };

    let sum = 0;
    let cnt = 0;

    props &&
      props.abilityResult &&
      props.abilityResult.userPhysicalVO &&
      props.abilityResult.userPhysicalVO &&
      Object.keys(props.abilityResult.userPhysicalVO).map((data, i) => {
        if (switchVOData(data) !== "") {
          tmp1.radar.indicator.push({
            name: switchVOData(data),
            max: 5,
          });
          tmp1.series[0].data[0].value.push(
            props.abilityResult.userPhysicalVO[data]
          );
          sum += Number(props.abilityResult.userPhysicalVO[data]);
          cnt++;
        }
      });
    tmp1.radar.indicator = [
      {
        name: "전체평균",
        max: 5,
      },
      ...tmp1.radar.indicator,
    ];
    tmp1.series[0].data[0].value = [
      (sum / cnt).toFixed(2),
      ...tmp1.series[0].data[0].value,
    ];

    setOptions1(tmp1);

    sum = 0;
    cnt = 0;

    props &&
      props.abilityResult &&
      props.abilityResult.userSkillVO &&
      props.abilityResult.userSkillVO &&
      Object.keys(props.abilityResult.userSkillVO).map((data, i) => {
        if (switchVOData(data) !== "") {
          tmp2.radar.indicator.push({
            name: switchVOData(data),
            max: 5,
          });
          tmp2.series[0].data[0].value.push(
            props.abilityResult.userSkillVO[data]
          );
          sum += Number(props.abilityResult.userSkillVO[data]);
          cnt++;
        }
      });

    tmp2.radar.indicator = [
      {
        name: "전체평균",
        max: 5,
      },
      ...tmp2.radar.indicator,
    ];
    tmp2.series[0].data[0].value = [
      (sum / cnt).toFixed(2),
      ...tmp2.series[0].data[0].value,
    ];
    setOptions2(tmp2);

    sum = 0;
    cnt = 0;

    props &&
      props.abilityResult &&
      props.abilityResult.userPsychometricVO &&
      props.abilityResult.userPsychometricVO &&
      Object.keys(props.abilityResult.userPsychometricVO).map((data, i) => {
        if (switchVOData(data) !== "") {
          tmp3.radar.indicator.push({
            name: switchVOData(data),
            max: 5,
          });
          tmp3.series[0].data[0].value.push(
            props.abilityResult.userPsychometricVO[data]
          );
          sum += Number(props.abilityResult.userPsychometricVO[data]);
          cnt++;
        }
      });

    tmp3.radar.indicator = [
      {
        name: "전체평균",
        max: 5,
      },
      ...tmp3.radar.indicator,
    ];
    tmp3.series[0].data[0].value = [
      (sum / cnt).toFixed(2),
      ...tmp3.series[0].data[0].value,
    ];
    setOptions3(tmp3);

    api
      .post("/api/ability/getAbilityTestResult")
      .then((response) => response.data)
      .then((response) => {
        setAbilityTestResult(response && response.resultData);
      });
  }, []);

  //모달 닫기 이벤트
  const disableModal = () => {
    setTimeout(() => {
      props.setModal(false);
    }, 1000);
    setFadeInOut("fade-out");
  };

  //데이터 값 한글로 변경
  const switchVOData = (param) => {
    switch (param) {
      case "sight":
        return "시각기능";
      case "listen":
        return "청각기능";
      case "posture_change":
        return "기본적인\n자세변화";
      case "posture_keep":
        return "자세유지";
      case "lift":
        return "물건들어올려나르기";
      case "hand_move":
        return "미세한 손동작";
      case "use_arms":
        return "손과 팔 이용";
      case "walking":
        return "보행";
      case "movement":
        return "이동";
      case "communication":
        return "의사소통능력";
      case "numeracy":
        return "수리능력";
      case "solving":
        return "문제해결능력";
      case "self_development":
        return "자기개발능력";
      case "resource_manage":
        return "자원관리능력";
      case "relationship":
        return "대인관계능력";
      case "information":
        return "정보능력";
      case "technical":
        return "기술능력";
      case "organizational":
        return "조직이해능력";
      case "work_ethic":
        return "직업윤리능력";
      case "attention":
        return "주의력기능";
      case "memory":
        return "기억기능";
      case "emotion":
        return "감정기능";
      case "thinking":
        return "사고력기능";
      case "energy":
        return "에너지와 욕동기능";
      case "overall":
        return "전반적 발달장애";
      default:
        return "";
    }
  };

  return (
    <>
      <div
        className="popup_mask"
        onClick={disableModal}
        style={{ display: "block" }}
      ></div>
      <div
        className={"popup_layer pop_lg popLResultEvaluation " + fadeInOut}
        id="popLResultEvaluation"
        style={{
          display: "block",
          transform: "translateX(-50%) translateY(0%)",
          WebkitTransform: "translateX(-50%) translateY(0%)",
          width: 1700,
          maxWidth: "inherit",
        }}
      >
        <div className="popup_inner">
          <div className="pop_title line_under">
            <h3>
              <span>{userName}</span>님의 역량평가 종합결과
            </h3>
            <button
              type="button"
              aria-label="레이어 팝업 닫기"
              onClick={disableModal}
              className="popup_close"
            ></button>
          </div>
          <div className="popup_contents" style={{ padding: 0 }}>
            <div
              className="wrap_graph_radial"
              style={{ justifyContent: "center" }}
            >
              <div className="box_graph">
                <h3 style={{ textAlign: "center", marginBottom: 15 }}>
                  정신장애정도
                </h3>
                <Echarts
                  option={options3}
                  opts={{ renderer: "canvas", width: 520, height: "auto" }}
                />
              </div>

              <div className="box_graph">
                <h3 style={{ textAlign: "center", marginBottom: 15 }}>
                  신체장애정도
                </h3>
                <Echarts
                  option={options1}
                  opts={{ renderer: "canvas", width: 520, height: "auto" }}
                />
              </div>

              <div className="box_graph">
                <h3 style={{ textAlign: "center", marginBottom: 15 }}>
                  직업기초능력
                </h3>
                <Echarts
                  option={options2}
                  opts={{ renderer: "canvas", width: 520, height: "auto" }}
                />
              </div>
            </div>
          </div>
          <div className="pop_title line_under">
            <h3>
              <span>{userName}</span>님의 직업기초 능력평가 결과
            </h3>
          </div>
          <div className="popup_contents">
            <div className="wrap_table">
              <table className="table table_row">
                <caption>직업기초 능력평가 결과</caption>
                {/*<colgroup>
                                <col style="width:140px;">
                                <col style="width:auto;">
                             </colgroup>*/}
                <tbody>
                  <tr>
                    <th scope="row">의사소통</th>
                    <td>
                      {abilityTestResult && abilityTestResult.communication}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">수리능력</th>
                    <td>{abilityTestResult && abilityTestResult.numeracy}</td>
                  </tr>
                  <tr>
                    <th scope="row">문제해결</th>
                    <td>{abilityTestResult && abilityTestResult.solving}</td>
                  </tr>
                  <tr>
                    <th scope="row">자기개발</th>
                    <td>
                      {abilityTestResult && abilityTestResult.self_development}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">자원관리</th>
                    <td>
                      {abilityTestResult && abilityTestResult.resource_manage}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">대인관계</th>
                    <td>
                      {abilityTestResult && abilityTestResult.relationship}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">정보</th>
                    <td>
                      {abilityTestResult && abilityTestResult.information}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">기술</th>
                    <td>{abilityTestResult && abilityTestResult.technical}</td>
                  </tr>
                  <tr>
                    <th scope="row">조직이해</th>
                    <td>
                      {abilityTestResult && abilityTestResult.organizational}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">직업윤리</th>
                    <td>{abilityTestResult && abilityTestResult.work_ethic}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="pop_title line_under">
            <h3>
              <span>{userName}</span>님의 성향분석 결과
            </h3>
          </div>
          <div className="popup_contents">
            <div className="wrap_tendency">
              <div className="box_tendency">
                <div className="tendency_tit">
                  <h4>
                    <span>{userName}</span>님의 강점
                  </h4>
                </div>
                <div className="tendency_cnt">
                  <p>
                    {userName +
                      (abilityTestResult && abilityTestResult.strength)}
                  </p>
                </div>
              </div>
              <div className="box_tendency">
                <div className="tendency_tit">
                  <h4>
                    <span>{userName}</span>님의 약점
                  </h4>
                </div>
                <div className="tendency_cnt">
                  <p>
                    {userName +
                      (abilityTestResult && abilityTestResult.weakness)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmModal;
