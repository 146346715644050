import {useEffect, useState} from "react";

const SearchCondition = (props) => {
    const { register, watchFeild, setValue } = props;
    const [isIrrelevantRadioChecked, setIsIrrelevantRadioChecked] = useState(false);

    useEffect(() => {
        const checkboxes = document.querySelectorAll('.wrap_chk_icon input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.disabled = isIrrelevantRadioChecked;
            if(isIrrelevantRadioChecked) checkbox.checked = false;
        });
    }, [isIrrelevantRadioChecked]);

    const handleIrrelevantTypeRadioClick = () =>{
        setIsIrrelevantRadioChecked(!isIrrelevantRadioChecked);
        if(!isIrrelevantRadioChecked) {
            setValue('disability.handicapped_type[0].disability_status','Y')
            setValue('disableNameData',[])
        }
        else setValue('disability.handicapped_type[0].disability_status','N')
    }

    return (
        <div className="area_shade">
            <div className="section_title">
                <h3>채용정보 검색하기</h3>
            </div>
            <div className="wrap_search_detail">
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="" className="">재택근무 여부</label>
                        <div className="wrap_box_radio box_education">
                            <div className="input_radio">
                                <label htmlFor="temp11" className="label_txt">
                                    <input type="radio" className="btn_type_radio" id="temp11" name="radio10" value="재택근무"
                                           {...register("condition.remote_work")}/>
                                    재택근무 희망
                                </label>
                            </div>
                            <div className="input_radio">
                                <label htmlFor="temp12" className="label_txt">
                                    <input type="radio" className="btn_type_radio" id="temp12" name="radio10" value="출퇴근"
                                           {...register("condition.remote_work")}/>
                                    출퇴근 희망
                                </label>
                            </div>
                            <div className="input_radio">
                                <label htmlFor="temp13" className="label_txt">
                                    <input type="radio" className="btn_type_radio" id="temp13" name="radio10" value=""
                                           {...register("condition.remote_work")}/>
                                    무관
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="" className="">고용형태</label>
                        <div className="wrap_box_radio box_education">
                            <div className="input_radio">
                                <label htmlFor="temp14" className="label_txt">
                                    <input type="radio" className="btn_type_radio" id="temp14" name="radio11" value="정규직"
                                           {...register("condition.duty_type")}/>
                                    정규직</label>
                            </div>
                            <div className="input_radio">
                                <label htmlFor="temp15" className="label_txt">
                                    <input type="radio" className="btn_type_radio" id="temp15" name="radio11" value="계약직"
                                           {...register("condition.duty_type")}/>
                                    계약직</label>
                            </div>
                            <div className="input_radio">
                                <label htmlFor="temp16" className="label_txt">
                                    <input type="radio" className="btn_type_radio" id="temp16" name="radio11" value=""
                                           {...register("condition.duty_type")}/>
                                    무관</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap_box_input">
                    <div className="box_input">
                        <div className="label_with_input">
                            <label htmlFor="" className="">장애유형 선택</label>
                            <div className="box_input_chk_round">
                                <label htmlFor="temp21">
                                    <input type="checkbox" className="input_chk" id="temp21"
                                           name=""
                                           checked={isIrrelevantRadioChecked}
                                           onChange={handleIrrelevantTypeRadioClick} />
                                    무관
                                </label>
                            </div>
                        </div>
                        <div className="wrap_chk_icon">
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp01"
                                       className="btn_chk_icon btn_icon_01" name="disableNameData" value="1"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp01">지체장애</label>
                            </div>
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp02"
                                       className="btn_chk_icon btn_icon_02" name="disableNameData" value="2"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp02">호흡기장애</label>
                            </div>
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp03"
                                       className="btn_chk_icon btn_icon_03" name="disableNameData" value="3"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp03">시각장애</label>
                            </div>
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp04"
                                       className="btn_chk_icon btn_icon_04" name="disableNameData" value="4"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp04">청각장애</label>
                            </div>
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp05"
                                       className="btn_chk_icon btn_icon_05" name="disableNameData" value="5"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp05">언어장애</label>
                            </div>
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp06"
                                       className="btn_chk_icon btn_icon_06" name="disableNameData" value="6"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp06">안면장애</label>
                            </div>
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp07"
                                       className="btn_chk_icon btn_icon_14" name="disableNameData" value="7"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp07">자폐성장애</label>
                            </div>
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp08"
                                       className="btn_chk_icon btn_icon_08" name="disableNameData" value="8"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp08">정신장애</label>
                            </div>
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp09"
                                       className="btn_chk_icon btn_icon_09" name="disableNameData" value="9"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp09">뇌병변장애</label>
                            </div>
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp10"
                                       className="btn_chk_icon btn_icon_10" name="disableNameData" value="10"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp10">신장장애</label>
                            </div>
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp11"
                                       className="btn_chk_icon btn_icon_11" name="disableNameData" value="11"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp12">심장장애</label>
                            </div>
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp12"
                                       className="btn_chk_icon btn_icon_12" name="disableNameData" value="12"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp12">간장애</label>
                            </div>
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp13"
                                       className="btn_chk_icon btn_icon_13" name="disableNameData" value="13"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp13">장루/요루장애</label>
                            </div>
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp14"
                                       className="btn_chk_icon btn_icon_14" name="disableNameData" value="14"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp14">뇌전증장애</label>
                            </div>
                            <div className="box_chk_icon">
                                <input type="checkbox" id="icon_temp15"
                                       className="btn_chk_icon btn_icon_15" name="disableNameData" value="15"
                                       {...register(`disableNameData`)}/>
                                <label className="" htmlFor="icon_temp15">지적장애</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap_box_input">
                    <div className="box_input">
                        <label htmlFor="" className="">채용공고 검색</label>
                        <input type="text" id="" name="" placeholder="기업명, 공고명을 입력해주세요." {...register("searchText")}/>
                    </div>
                </div>
                <div className="wrap_btn">
                    <button type="submit" className="btn_submit filled_green">채용정보 검색하기</button>
                </div>
            </div>
        </div>
    )
}

export default SearchCondition;