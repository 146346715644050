import React, {useEffect, useLayoutEffect, useState} from 'react';
import EnterpriseInfo from "./EnterpriseInfo";
import {useForm} from "react-hook-form";
import IncruitInfo from "./IncruitInfo";
import WorkCondition from "./WorkCondition";
import Disability from "./Disability";
import {CompanyRequestInit} from "./Init/CompanyInit";
import {getRecruit, updateRecruit} from "../../../api/Comp/JobMatching/JobMatching";
import {uploadImage} from "../../../api/Comp/CompSignup/CompSignup";
import api from '../../../api/api';

const ApplicantModal = (props) => {
    const { setModal, recruitSeq, applyCnt = 0 } = props
    const methods = useForm({
        mode: 'onChange',
        defaultValues: CompanyRequestInit
    });
    const { setValue, reset, watch, handleSubmit, formState: { errors } } = methods;
    const [isDetail, setIsDetail] = useState(true);
    const [compImage, setCompImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [postFile, setPostFile] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [jobParam, setJobParam] = useState({});
    const [applicantListAll, setApplicantListAll] = useState({});
    const [applicantListPass, setApplicantListPass] = useState({});
    const [applicantListInterview, setApplicantListInterview] = useState({});
    const [isActive, setIsActive] = useState({});
    const [compAddress, setCompAddress] = useState(null);
    const watchField = watch()

    useLayoutEffect(() => {
        let paramTmp = {
            "recruit_seq" : recruitSeq,
            "comp_evaluation" : [1, 2, 3, 4, 5, 6, 7],
            "sort_field" : null,
            "method" : null
        }
        setJobParam(paramTmp);

        let passParam = {
            "recruit_seq" : recruitSeq,
            "comp_evaluation" : [5, 6], //[5,6]
            "sort_field" : null,
            "method" : null
        }
        
        try{
            !Object.keys(applicantListPass).length && api.post("/api/comp/applicant/getApplicantListInfo", passParam).then(response =>
                response.data
                ).then((response) => { 
                    response && response.resultData && setApplicantListPass(response.resultData);
                })
        } catch(e){
            console.log(e);
        }

        let interviewParam = {
            "recruit_seq" : recruitSeq,
            "comp_evaluation" : [3],    //[3]
            "sort_field" : null,
            "method" : null
        }

        try{
            !Object.keys(applicantListInterview).length && api.post("/api/comp/applicant/getApplicantListInfo", interviewParam).then(response =>
                response.data
                ).then((response) => { 
                    response && response.resultData && setApplicantListInterview(response.resultData);
                })
        } catch(e){
            console.log(e);
        }

        try {
            getRecruit(recruitSeq).then((response) => {
                if (response.data.resultCode === 1000 && response.data.resultData) {
                    reset({
                        ...response.data.resultData,
                        condition: {
                            ...response.data.resultData.condition,
                            work_location: response.data.resultData.condition.work_location.split("|")[0],
                            detail_work_location: response.data.resultData.condition.work_location.split("|")[1]
                        }
                    })
                    if (response.data.resultData.disability.handicapped_type[0].disability_status === 'Y') {
                        setValue("disableNameData", response.data.resultData.disability.handicapped_type[0].add_disabled.map((data) => data.disabled_name))
                    }
                    if (response.data.resultData.condition.employment_condition.split("|")[0] === "경력") {
                        setValue("condition.employment_condition", response.data.resultData.condition.employment_condition.split("|")[0])
                        setValue("condition.employed_years", response.data.resultData.condition.employment_condition.split("|")[1])
                    }
                    if (response.data.resultData.incruit.recruit_status === "공고중" &&
                        response.data.resultData.incruit.recruit_end_date !== null  &&
                        (new Date(response.data.resultData.incruit.recruit_end_date) < new Date())) {
                        setValue("incruit.recruit_status", "공고종료")
                    }
                    setCompAddress(response.data.resultData.incruit.address.split('|'));
                }
            })
        } catch(e) {
            console.log(e);
        }

    }, [])

    useEffect(() => {
        let paramTmp = {
            "recruit_seq" : recruitSeq,
            "comp_evaluation" : [1, 2, 3, 4, 5, 6, 7],
            "sort_field" : null,
            "method" : null
        }
        props.updateChk && setJobParam(paramTmp);

        let passParam = {
            "recruit_seq" : recruitSeq,
            "comp_evaluation" : [5, 6], //[5,6]
            "sort_field" : null,
            "method" : null
        }
        
        try{
            props.updateChk && !Object.keys(applicantListPass).length && api.post("/api/comp/applicant/getApplicantListInfo", passParam).then(response =>
                response.data
                ).then((response) => { 
                    response && response.resultData && setApplicantListPass(response.resultData);
                })
        } catch(e){
            console.log(e);
        }

        let interviewParam = {
            "recruit_seq" : recruitSeq,
            "comp_evaluation" : [3],    //[3]
            "sort_field" : null,
            "method" : null
        }
        
        try{
            props.updateChk && !Object.keys(applicantListInterview).length && api.post("/api/comp/applicant/getApplicantListInfo", interviewParam).then(response =>
                response.data
                ).then((response) => { 
                    response && response.resultData && setApplicantListInterview(response.resultData);
                })
        } catch(e){
            console.log(e);
        }
        props.setUpdateChk(false);
    }, [props.updateChk])


    useEffect(() => {
        try{
            Object.keys(jobParam).length && api.post("/api/comp/applicant/getApplicantListInfo", jobParam).then(response =>
                response.data
                ).then((response) => { 
                    response && response.resultData && setApplicantListAll(response.resultData);
                })
        } catch(e){
            console.log(e);
        }
    }, [jobParam]);

    const disableModal = () =>{
        setTimeout(() => {
            setModal(false);
        }, 300);
    }

    const onSubmit = (data) => {
        try {
            const {condition: {work_location, detail_work_location, employed_years}} = data;
            const address = detail_work_location ? `${work_location}|${detail_work_location}` : work_location;
            data.condition.work_location = address;

            if (data.condition.employment_condition === '경력') {
                data.condition.employment_condition += `|${employed_years}`;
            }
            if (data.disability.handicapped_type[0].disability_status === 'Y') {
                data.disability.handicapped_type[0].add_disabled = data.disableNameData.map((disabled_name) => ({
                    disabled_name
                }));
            }

            updateRecruit(data, data.enterprise.seq).then((response) => {
                if (response.data.resultCode === 1000 && response.data.resultData) {
                    alert("직무요청서가 성공적으로 수정되었습니다.");
                    setDisabled(true);
                    setModal(false);

                    window.location.href = "/comp/jobRequest/jobApplicationList";
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    const calcAge = (birthday) => {
        let date = new Date();
        let year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        let monthDay = month + day;
        
        let birth = birthday.replace('-', '').replace('-', '');
        let birthY = birth.substr(0,4);
        let birthMd = birth.substr(4,4);

        let age = monthDay < birthMd ? year - birthY -1 : year - birthY;
        return age;
    }

    const calcCareer = (career) => {
        let ret_month = 0; 
        let date = new Date();
        let year = date.getFullYear();
        var month = date.getMonth() + 1;
        
        career && career.map((item, i) => {
            let hired_date = item.hired_date;
            hired_date  = hired_date.replace('-', '').replace('-', '').replace('/', '').replace('/', '').replace('.', '').replace('.', '');
            let resignation_date = item.resignation_date;
            if(resignation_date != null) resignation_date = resignation_date.replace('-', '').replace('-', '').replace('/', '').replace('/', '').replace('.', '').replace('.', '');
            
            ret_month += item.employment_status == "재직중" ? (year - Number(hired_date.substr(0,4)))*12 + (month - Number(hired_date.substr(4,2)))  : item.employment_status == "퇴사" ? (Number(resignation_date.substr(0,4)) - Number(hired_date.substr(0,4))) * 12 + (Number(resignation_date.substr(4,2)) - Number(hired_date.substr(4,2))) : 0;
            
        });
        ret_month > 0 && ret_month++;

        return ret_month > 0 ? (Math.floor(ret_month/12))+ "년 " + (ret_month%12) + "개월" : "";
    }

    const toggleSortBtn = (e) => {
        let tmp = {...isActive};
        (tmp[e.target.id] === undefined || tmp[e.target.id] === "ASC") ? (tmp[e.target.id] = "DESC") : (tmp[e.target.id] = "ASC");
        sorting((e.target.id === "comp_evaluation2" ? "comp_evaluation" : e.target.id) , tmp[e.target.id]);
        setIsActive(tmp);
    }

    const sorting = (field, method) => {
        let tmpParam = {...jobParam};
        tmpParam.sort_field = field;
        tmpParam.sort_method = method;
        setJobParam(tmpParam);
    }

    const compareDate = (e) => {
        const nowDate = new Date();
        const tmp = e.replace('-', '').replace('-', '').replace('/', '').replace('/', '').replace('.', '').replace('.', '');
        return (nowDate.getFullYear() === Number(e.substring(0,4))) && ((nowDate.getMonth() + 1) === Number(e.substring(4,2))) && (nowDate.getDate() === Number(e.substring(6,2)));
    }

    const moveToInterviewRoom = (e,link) =>{
        e.preventDefault()
        window.open(link,'interviewRoom');
    }

    const applicantDetailModal = async(annNum, recNum) => {
        let detailParam = {
            "announcement_seq" : null,
            "recruit_seq" : null
        }
        annNum && (detailParam.announcement_seq = annNum);
        recNum && (detailParam.recruit_seq = recNum);
        try{
            await api.post("/api/comp/applicant/getApplicantInfo", detailParam).then(response =>
                response.data
                ).then((response) => {
                    response && response.resultData && props.setResult(response.resultData);
                })
        } catch(e){

        } finally{
            props.setApplicantInfoModal(true);
        }
    }
    
    const setDefaultImg = (e) => {
        if(e.target.src != null && e.target.src.indexOf("/img/icon/icon_profile.png") == -1){
            e.target.src = "/img/icon/icon_profile.png";
        }
    }

    return (
        <React.Fragment>
                <div className="popup_mask" style={{display: "block"}} onClick={() => disableModal()} ></div>
                <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e)=>{if (e.key === 'Enter' && e.target.nodeName === "INPUT"){e.preventDefault();}}}>
                    <div className="popup_layer enterprise pop_lg popRequstDetail" id="popRequestDetail" style={{display: "block"}}>
                        <div className="popup_inner">
                            <div className="pop_title with_btn">
                                <h3>직무요청서 상세보기</h3>
                                <button type="button" aria-label="레이어 팝업 닫기" className="popup_close" onClick={() => setModal(false)}/>
                            </div>
                            <div className="popup_contents profile_detail">
                                <div className="popup_section_title line_under popup_btn_init">
                                    <h3 className="page_back_title border_bottom_line" />
                                    <div className="btn_wrap">
                                        {
                                            disabled === true ? (
                                                <button className="btn_submit filled_green" type="button" onClick={(e) => {
                                                    e.preventDefault();setDisabled(false);}}>편집하기</button>
                                            ) : (
                                                <button type="submit" className="btn_submit filled_green"> 저장하기</button>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="page_inner recruit_status_page">
                                    <div className="area_shade recruit_status">
                                        <h3>채용현황 보기</h3>
                                        <div className="wrap_progressbar">
                                            <ul className={`progressbar_list 
                                            ${applicantListPass?.applicantInfos?.length > 0 ? (
                                                "progress_step04"
                                            ) : ( 
                                                applicantListInterview?.applicantInfos?.length > 0
                                            ) ? (
                                                "progress_step03"
                                            ) : (
                                                "progress_step02"
                                            )}
                                            `}>
                                                <li className="progressbar_item progress_item01">
                                                    <p>직무요청서 등록</p>
                                                </li>
                                                <li className="progressbar_item progress_item02">
                                                    {
                                                        (applicantListInterview?.applicantInfos?.length === 0 && applicantListPass?.applicantInfos?.length === 0) && (
                                                            <p className="current_step">
                                                                <span
                                                                    dangerouslySetInnerHTML={{__html: applicantListAll?.applicantInfos?.length}}></span>명
                                                                지원
                                                            </p>
                                                        )
                                                    }
                                                    <p>지원자 접수</p>
                                                </li>
                                                <li className="progressbar_item progress_item03">
                                                    {
                                                        (applicantListInterview?.applicantInfos?.length > 0 && applicantListPass?.applicantInfos?.length === 0) && (
                                                            <p className="current_step">
                                                                <span
                                                                    dangerouslySetInnerHTML={{__html: applicantListInterview?.applicantInfos?.length}}></span>명
                                                                면접
                                                            </p>
                                                        )
                                                    }
                                                    <p>면접 진행</p>
                                                </li>
                                                <li className="progressbar_item progress_item04">
                                                    {
                                                        applicantListPass?.applicantInfos?.length > 0 && (
                                                            <p className="current_step">
                                                                <span
                                                                    dangerouslySetInnerHTML={{__html: applicantListPass?.applicantInfos?.length}}></span>명
                                                                합격
                                                            </p>
                                                        )
                                                    }
                                                    <p>최종 합격자 선정</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="wrap_accodian wrap_recruit_status">
                                            <div className="wrap_accodian_item table_init" style={isDetail === true ? { display: "none" } : null}>
                                                <div className="accodian_cnt">
                                                    <div className="wrap_applicant_list">
                                                        <h4>최종합격자</h4>
                                                        <table className="table_detail">
                                                            <colgroup>
                                                                <col style={{ width: 95 }} />
                                                                <col style={{ width: "auto" }} />
                                                                <col style={{ width: 135 }} />
                                                                <col style={{ width: 122 }} />
                                                                <col style={{ width: 185 }} />
                                                                <col style={{ width: 126 }} />
                                                            </colgroup>
                                                            <thead>
                                                            <tr>
                                                                <th scope="col">번호</th>
                                                                <th scope="col">지원자</th>
                                                                <th scope="col">지원일</th>
                                                                <th scope="col">면접방법</th>
                                                                <th scope="col">면접일시</th>
                                                                <th scope="col">평가상태</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {applicantListPass?.applicantInfos?.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td dangerouslySetInnerHTML={{__html : item.rownum}}></td>
                                                                    <td className="td_pic_init">                                            
                                                                        <a href="#" onClick={(e) => {e.preventDefault(); applicantDetailModal(item.announcement_seq, item.recruit_seq)}}  className="resume_popup" >
                                                                            <div className="prof_pic_wrap">
                                                                                <span className="profile_pic">
                                                                                    <img src={item.image_file_path} onError={setDefaultImg} alt="프로필 이미지" />
                                                                                </span>
                                                                            </div>
                                                                            <div className="prof_text_wrap">
                                                                                <div>
                                                                                    <span className="name" dangerouslySetInnerHTML={{__html : item.member_nm}}></span>|
                                                                                    <span className="gender" dangerouslySetInnerHTML={{__html : item.sex_gb}}></span>
                                                                                    <span className="age" dangerouslySetInnerHTML={{__html : calcAge(item.birthday)}}></span>세| 
                                                                                    <span className="p_history" dangerouslySetInnerHTML={{__html : calcCareer(item.career)}}></span>
                                                                                </div>
                                                                                <div>
                                                                                    <span className="body_status" dangerouslySetInnerHTML={{__html : item.major_disability_nm}}></span>
                                                                                </div>                                           
                                                                            </div>                                                                                           
                                                                        </a>                                          
                                                                    </td>
                                                                    <td dangerouslySetInnerHTML={{__html : item.apply_dt}}></td>
                                                                    <td class="no_ellipsis">{ item.comp_evaluation == 3 || item.comp_evaluation == 4  || item.comp_evaluation == 6 ? (item.agency_type == 'Y' ? '직접면접' : item.agency_type == 'N' ? '대행면접' : "-") : "-"}</td>
                                                                    <td>
                                                                            {item.comp_evaluation == 3 || item.comp_evaluation == 4  || item.comp_evaluation == 6 ? (
                                                                                <button 
                                                                                type="button" className="btn_table_text" 
                                                                                dangerouslySetInnerHTML={
                                                                                    {
                                                                                        __html : item.interview_dt != null ? 
                                                                                            item.interview_dt.substring(0, 10)+"<br/>"+item.interview_dt.substring(10, 16)
                                                                                            : item.select_confirm != null ?
                                                                                            (() => {
                                                                                                switch (item.select_confirm) {
                                                                                                case '1':
                                                                                                    return item.interview_dt1.substring(0, 10)+"<br/>"+item.interview_dt1.substring(10, 16);
                                                                                                case '2':
                                                                                                    return item.interview_dt2.substring(0, 10)+"<br/>"+item.interview_dt2.substring(10, 16);
                                                                                                case '3':
                                                                                                    return item.interview_dt3.substring(0, 10)+"<br/>"+item.interview_dt3.substring(10, 16);
                                                                                                default:
                                                                                                    return "가능한 일정이<br/>없습니다";
                                                                                                }
                                                                                            })(): "구직자<br/>응답대기"
                                                                                    }                                        
                                                                                }
                                                                                >
                                                                            </button>                             
                                                                            ) : (
                                                                                "-"
                                                                            )
                                                                        }
                                                                    </td>
                                                                    <td dangerouslySetInnerHTML={{__html : item.comp_evaluation_name && item.comp_evaluation != "5" ? item.comp_evaluation_name : "최종합격"}}></td> 
                                                                </tr>
                                                            ))
                                                            }
                                                            {applicantListPass?.applicantInfos?.length == 0 && (
                                                                <tr>
                                                                    <td colSpan={6}>최종 합격자가 없습니다.</td>
                                                                </tr>
                                                            )
                                                            }
                                                            
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="wrap_applicant_list">
                                                        <h4>면접자 현황</h4>
                                                        <table className="table_detail">
                                                            <colgroup>
                                                                <col style={{ width: 75 }} />
                                                                <col style={{ width: "auto" }} />
                                                                <col style={{ width: 105 }} />
                                                                <col style={{ width: 85 }} />
                                                                <col style={{ width: 150 }} />
                                                                <col style={{ width: 135 }} />
                                                                <col style={{ width: 75 }} />
                                                                <col style={{ width: 135 }} />
                                                            </colgroup>
                                                            <thead>
                                                            <tr>
                                                                <th scope="col">번호</th>
                                                                <th scope="col">지원자</th>
                                                                <th scope="col">지원일</th>
                                                                <th scope="col">면접방법</th>
                                                                <th scope="col">면접일시</th>
                                                                <th scope="col">면접실</th>
                                                                <th scope="col">면접점수</th>
                                                                <th scope="col">평가상태</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {applicantListInterview?.applicantInfos?.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td dangerouslySetInnerHTML={{__html : item.rownum}}></td>
                                                                    <td className="td_pic_init">                                            
                                                                        <a href="#" onClick={(e) => {e.preventDefault(); applicantDetailModal(item.announcement_seq, item.recruit_seq)}}  className="resume_popup" >
                                                                            <div className="prof_pic_wrap">
                                                                                <span className="profile_pic">
                                                                                    <img src={item.image_file_path} onError={setDefaultImg} alt="프로필 이미지" />
                                                                                </span>
                                                                            </div>
                                                                            <div className="prof_text_wrap">
                                                                                <div>
                                                                                    <span className="name" dangerouslySetInnerHTML={{__html : item.member_nm}}></span>|
                                                                                    <span className="gender" dangerouslySetInnerHTML={{__html : item.sex_gb}}></span>
                                                                                    <span className="age" dangerouslySetInnerHTML={{__html : calcAge(item.birthday)}}></span>세| 
                                                                                    <span className="p_history" dangerouslySetInnerHTML={{__html : calcCareer(item.career)}}></span>
                                                                                </div>
                                                                                <div>
                                                                                    <span className="body_status" dangerouslySetInnerHTML={{__html : item.major_disability_nm}}></span>
                                                                                </div>                                           
                                                                            </div>                                                                                           
                                                                        </a>                                          
                                                                    </td>
                                                                    <td dangerouslySetInnerHTML={{__html : item.apply_dt}}></td>
                                                                    <td class="no_ellipsis">{ item.comp_evaluation == 3 || item.comp_evaluation == 4  || item.comp_evaluation == 6 ? (item.agency_type == 'Y' ? '직접면접' : item.agency_type == 'N' ? '대행면접' : "-") : "-"}</td>
                                                                    <td>
                                                                    {item.comp_evaluation == 3 || item.comp_evaluation == 4  || item.comp_evaluation == 6 ? (
                                                                        <button 
                                                                        type="button" className="btn_table_text" 
                                                                        dangerouslySetInnerHTML={
                                                                            {
                                                                                __html : item.interview_dt != null ? 
                                                                                    item.interview_dt.substring(0, 10)+"<br/>"+item.interview_dt.substring(10, 16)
                                                                                    : item.select_confirm != null ?
                                                                                    (() => {
                                                                                        switch (item.select_confirm) {
                                                                                        case '1':
                                                                                            return item.interview_dt1.substring(0, 10)+"<br/>"+item.interview_dt1.substring(10, 16);
                                                                                        case '2':
                                                                                            return item.interview_dt2.substring(0, 10)+"<br/>"+item.interview_dt2.substring(10, 16);
                                                                                        case '3':
                                                                                            return item.interview_dt3.substring(0, 10)+"<br/>"+item.interview_dt3.substring(10, 16);
                                                                                        default:
                                                                                            return "가능한 일정이<br/>없습니다";
                                                                                        }
                                                                                    })(): "구직자<br/>응답대기"
                                                                            }                                        
                                                                        }
                                                                        >
                                                                    </button>                             
                                                                    ) : (
                                                                        "-"
                                                                    )
                                                                }                                       
                                                                    </td> 
                                                                    <td>
                                                                    {
                                                                        item.interview_dt != null ? (
                                                                            new Date(item.interview_dt).toDateString() === new Date().toDateString() ? (
                                                                                item.zoom_link !== null ? (
                                                                                    <button type="button" class="btn_table_init" onClick={(e)=>(moveToInterviewRoom(e,item.zoom_link))}>면접실 입장</button>
                                                                                ) : (
                                                                                    <button type="button" class="btn_table_init" disabled>면접실 입장</button>
                                                                                )
                                                                            ) : (
                                                                                <button type="button" class="btn_table_init" disabled>면접실 입장</button>
                                                                            )
                                                                        ) : null                                                                
                                                                    }
                                                                    </td>   
                                                                    <td dangerouslySetInnerHTML={{__html : item.interview_score != null ? item.interview_score : "-"}}></td>
                                                                    <td dangerouslySetInnerHTML={{__html : item.comp_evaluation_name && item.comp_evaluation != "5" ? item.comp_evaluation_name : "최종합격"}}></td> 
                                                                </tr>
                                                            ))
                                                            }
                                                            {applicantListInterview?.applicantInfos?.length == 0 && (
                                                                <tr>
                                                                    <td colSpan={8}>면접 예정자가 없습니다..</td>
                                                                </tr>
                                                            )
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="wrap_applicant_list">
                                                        <h4>지원자 리스트</h4>
                                                        <table className="table_detail sort_column">
                                                            <colgroup>
                                                                <col style={{ width: 95 }} />
                                                                <col style={{ width: "auto" }} />
                                                                <col style={{ width: 130 }} />
                                                                <col style={{ width: 185 }} />
                                                                <col style={{ width: 130 }} />
                                                            </colgroup>
                                                            <thead>
                                                            <tr>
                                                                <th scope="col" className={"header_sort01 " + (isActive.rownum == "DESC" ? "sort_up" : "sort_down")} id="rownum" onClick={toggleSortBtn}>
                                                                    번호
                                                                </th>
                                                                <th scope="col" className="no_sort">
                                                                    지원자
                                                                </th>
                                                                <th scope="col" className={"header_sort02 " + (isActive.apply_dt == "ASC" ? "sort_up" : "sort_down")} id="apply_dt" onClick={toggleSortBtn}>
                                                                    지원일
                                                                </th>
                                                                <th scope="col" className={"header_sort03 " + (isActive.interview_dt == "ASC" ? "sort_up" : "sort_down")} id="interview_dt" onClick={toggleSortBtn}>
                                                                    면접일시
                                                                </th>
                                                                <th scope="col" className={"header_sort01 " + (isActive.comp_evaluation == "ASC" ? "sort_up" : "sort_down")} id="comp_evaluation" onClick={toggleSortBtn}>
                                                                    평가상태
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {applicantListAll?.applicantInfos?.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td dangerouslySetInnerHTML={{__html : item.rownum}}></td>
                                                                    <td className="td_pic_init">                                            
                                                                        <a href="#" onClick={(e) => {e.preventDefault(); applicantDetailModal(item.announcement_seq, item.recruit_seq)}} className="resume_popup" >
                                                                            <div className="prof_pic_wrap">
                                                                                <span className="profile_pic">
                                                                                    <img src={item.image_file_path} onError={setDefaultImg} alt="프로필 이미지" />
                                                                                </span>
                                                                            </div>
                                                                            <div className="prof_text_wrap">
                                                                                <div>
                                                                                    <span className="name" dangerouslySetInnerHTML={{__html : item.member_nm}}></span>|
                                                                                    <span className="gender" dangerouslySetInnerHTML={{__html : item.sex_gb}}></span>
                                                                                    <span className="age" dangerouslySetInnerHTML={{__html : calcAge(item.birthday)}}></span>세| 
                                                                                    <span className="p_history" dangerouslySetInnerHTML={{__html : calcCareer(item.career)}}></span>
                                                                                </div>
                                                                                <div>
                                                                                    <span className="body_status" dangerouslySetInnerHTML={{__html : item.major_disability_nm}}></span>
                                                                                </div>                                           
                                                                            </div>                                                                                           
                                                                        </a>                                          
                                                                    </td>
                                                                    <td dangerouslySetInnerHTML={{__html : item.apply_dt}}></td>
                                                                    <td>
                                                                    {item.comp_evaluation == 3 || item.comp_evaluation == 4  || item.comp_evaluation == 6 ? (
                                                                        <button 
                                                                        type="button" className="btn_table_text" 
                                                                        dangerouslySetInnerHTML={
                                                                            {
                                                                                __html : item.interview_dt != null ? 
                                                                                    item.interview_dt.substring(0, 10)+"<br/>"+item.interview_dt.substring(10, 16)
                                                                                    : item.select_confirm != null ?
                                                                                    (() => {
                                                                                        switch (item.select_confirm) {
                                                                                        case '1':
                                                                                            return item.interview_dt1.substring(0, 10)+"<br/>"+item.interview_dt1.substring(10, 16);
                                                                                        case '2':
                                                                                            return item.interview_dt2.substring(0, 10)+"<br/>"+item.interview_dt2.substring(10, 16);
                                                                                        case '3':
                                                                                            return item.interview_dt3.substring(0, 10)+"<br/>"+item.interview_dt3.substring(10, 16);
                                                                                        default:
                                                                                            return "가능한 일정이<br/>없습니다";
                                                                                        }
                                                                                    })(): "구직자<br/>응답대기"
                                                                            }                                        
                                                                        }
                                                                        >
                                                                    </button>                             
                                                                    ) : (
                                                                        "-"
                                                                    )
                                                                }
                                                                    </td>   
                                                                    <td dangerouslySetInnerHTML={{__html : item.comp_evaluation_name && item.comp_evaluation != "5" ? item.comp_evaluation_name : "최종합격"}}></td>
                                                                </tr>
                                                            ))

                                                            }
                                                            {applicantListAll?.applicantInfos?.length == 0 && (
                                                                <tr>
                                                                    <td colSpan={5}>지원자가 없습니다.</td>
                                                                </tr>
                                                            )
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className={`btn_submit filled_green detail_on_off${isDetail === false ? " on" : ""}`} type="button" onClick={() => setIsDetail(!isDetail)}>
                                                {/* <span class="btn_text">상세내용 닫기</span>    */}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="area_shade request_write">

                                        <EnterpriseInfo methods={methods} disabled={disabled} imagePreview={imagePreview} setImagePreview={setImagePreview}
                                                        compImage={compImage} setCompImage={setCompImage} />
                                        <IncruitInfo methods={methods} disabled={disabled}/>
                                        <WorkCondition methods={methods} address={compAddress} disabled={disabled}/>
                                        <Disability methods={methods} disabled={disabled} postFile={postFile} setPostFile={setPostFile}/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
        </React.Fragment>
    )
}

export default ApplicantModal;