import React, {useEffect, useState} from 'react';
import MemberCom from "./MemberCom";
import CompInfo from "./CompInfo";
import ManagerInfo from "./ManagerInfo";
import {FormProvider, useForm} from "react-hook-form";
import {useHistory} from 'react-router-dom';
import {createCompUser, uploadImage} from "../../../api/Comp/CompSignup/CompSignup";
import {ErrorMessages} from "../../../utils/Message";
import HolidayReg from "./HolidayReg";

const init = {
    member_com: {
        gubun: "COMPANY",
        provider_type: "LOCAL"
    }
}

const CompSignup = () => {
    const [isSubmit, setIsSubmit] = useState(false)
    const [idCheck, setIdCheck] = useState(false)
    const [compImage, setCompImage] = useState(null);
    const [bizImage, setBizImage] = useState(null);
    const [bizSeqCheck, setBizSeqCheck] = useState(null);
    const history = useHistory();
    const [phone, setPhone] = useState({
        send : false,
        num : undefined,
        valid : false,
        pass : false
    })
    const methods = useForm({
        mode: 'onChange',
        defaultValues: init
    })
    const { setValue, setError, handleSubmit, formState: { errors, isValid } } = methods;
    const watchField = methods.watch();

    useEffect(() => {
        if(!isSubmit) return
        Object.entries(errors).forEach(([key, value]) => {
            Object.keys(value).forEach((name) => {
                setValue(key + "." + name, "", {shouldValidate: false, shouldDirty: true})
            })
        })
        AlertError()
        setIsSubmit(false)
    }, [errors, isSubmit] )

    const extraValid = () => {
        if (compImage === null) {
            setError("comp_member_info.rep_file_path", {
                message: ErrorMessages.required
            }, {shouldFocus: true});
        }
        if (bizImage === null) {
            setError("comp_member_info.biz_image", {
                message: ErrorMessages.required
            }, {shouldFocus: true});
        }
    }

    const AlertError = () => {

        if (errors.member_com?.member_id) {
            alert("아이디 입력정보를 확인해주세요.")
        } else if (errors.member_com?.member_pw || errors.member_com?.validPw) {
            alert("비밀번호 입력정보를 확인해주세요.")
        } else if (compImage === null || compImage === undefined) {
            alert("대표 이미지를 첨부해주세요.")
        } else if (bizImage === null || bizImage === undefined) {
            alert("사업자등록증 또는 고유번호증을 첨부해주세요.")
        } else if (errors.comp_member_info?.comp_nm) {
            alert("회사명 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.rep_nm) {
            alert("대표자명 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.biz_seq) {
            alert("사업자등록번호 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.address || errors.comp_member_info?.extraAddress) {
            alert("주소 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.biz_sector) {
            alert("기업업종 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.sales) {
            alert("매출 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.employee_cnt) {
            alert("종업원 수 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.manager_nm) {
            alert("담당자 이름 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.manager_pos) {
            alert("담당자 이름 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.manager_tel || errors.comp_member_info?.validNum) {
            alert("담당자 연락처 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.comp_tel) {
            alert("회사 연락처 입력정보를 확인해주세요.")
        } else if (errors.member_com?.email) {
            alert("이메일 입력정보를 확인해주세요.")
        } else if (errors.comp_member_info?.manager_address || errors.comp_member_info?.manager_extraAddress) {
            alert("담당자 근무지 입력정보를 확인해주세요.")
        } else if (errors.member_com?.al_guidelines) {
            alert("연차사용기준 입력정보를 확인해주세요.")
        } else if (errors.member_com?.rh_policy) {
            alert("대체공휴일 휴무규정 입력정보를 확인해주세요.")
        }
    }

    const onSubmit = async (data) => {
        extraValid()

        if (compImage !== null && bizImage !== null) {
            const formData = new FormData();

            data.comp_member_info.address = data.comp_member_info.address + "|" + data.comp_member_info.extraAddress;
            data.comp_member_info.manager_address = data.comp_member_info.manager_address + "|" + data.comp_member_info.manager_extraAddress;

            try {
                await createCompUser(data).then((response) => {
                    if (response.data.resultCode === 1000) {

                        formData.append('image', bizImage);
                        formData.append('imageNm', data.comp_member_info.biz_seq + "." + bizImage.type.split('/')[1]);
                        formData.append('originFileNm', bizImage.name);
                        formData.append('compSeq', response.data.resultData)

                        uploadImage(formData)

                        window.location.href="/comp/signup/compSignupsuccess";
                    }
                })

            } catch (error) {
                console.log(error)
            }
        }

        setIsSubmit(true)
    }

    const onError = () => {
        extraValid()

        setIsSubmit(true)
    }

    return (
        <div className="wrapper">
            <main>
                <div className="page join page_join enterprise">
                    <h2 className="page_title">기업회원 가입하기</h2>

                    <div className="wrap_progressbar">
                        <ul className="progressbar_list progress_step02">
                            <li className="progressbar_item progress_item01"><p>이용약관 동의</p></li>
                            <li className="progressbar_item progress_item02"><p>회원정보 등록</p></li>
                        </ul>
                    </div>
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit,onError)} onKeyDown={(e)=>{if (e.key === 'Enter' && e.target.nodeName === "INPUT"){e.preventDefault();}}}>
                            <div className="page_inner">
                                <div className="area_shade">
                                    <div className="wrap_title_step">
                                        <span className="badge badge_step">STEP 2</span>
                                        <h3 className="title_step">회원정보를 등록해 주세요.</h3>
                                    </div>

                                    <MemberCom idCheck={idCheck} setIdCheck={setIdCheck}/>
                                    <CompInfo compImage={compImage} setCompImage={setCompImage} bizImage={bizImage} setBizImage={setBizImage} bizSeqCheck={bizSeqCheck} setBizSeqCheck={setBizSeqCheck} isCompInfo={false}/>
                                    <ManagerInfo phone={phone} setPhone={setPhone} phoneChange={true}/>
                                    <HolidayReg />

                                </div>
                                <div className="wrap_btns">
                                    <a href="/comp/signup/termsconditions" className="btn_submit filled_black">이전으로</a>
                                    <button type="submit"  className="btn_submit filled_green">가입하기</button>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </main>
        </div>
    )
}

export default CompSignup;