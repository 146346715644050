const ApplicantProfile = (props) => {
  if (props.profile === undefined) return;

  const calcCareer = (career) => {
    let ret_month = 0;
    let date = new Date();
    let year = date.getFullYear();
    var month = date.getMonth() + 1;

    career &&
      career.map((item, i) => {
        let hired_date = item.hired_date;
        hired_date = hired_date
          .replace("-", "")
          .replace("-", "")
          .replace("/", "")
          .replace("/", "")
          .replace(".", "")
          .replace(".", "");
        let resignation_date = item.resignation_date;
        if (resignation_date != null)
          resignation_date = resignation_date
            .replace("-", "")
            .replace("-", "")
            .replace("/", "")
            .replace("/", "")
            .replace(".", "")
            .replace(".", "");

        ret_month +=
          item.employment_status == "재직중"
            ? (year - Number(hired_date.substr(0, 4))) * 12 +
              (month - Number(hired_date.substr(4, 2)))
            : item.employment_status == "퇴사"
              ? (Number(resignation_date.substr(0, 4)) -
                  Number(hired_date.substr(0, 4))) *
                  12 +
                (Number(resignation_date.substr(4, 2)) -
                  Number(hired_date.substr(4, 2)))
              : 0;
      });
    ret_month > 0 && ret_month++;

    return ret_month > 0
      ? Math.floor(ret_month / 12) + "년 " + (ret_month % 12) + "개월"
      : "";
  };
  return (
    <div className="profile_tab_con current profile_tab_con_1" id="btn1">
      <div className="profile_cont">
        <h4>학력사항</h4>
        <table>
          {props.profile &&
            props.profile.education &&
            props.profile.education.high_school && (
              <tr>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        props.profile &&
                        props.profile.education &&
                        props.profile.education.high_school &&
                        props.profile.education.high_school.admission_date,
                    }}
                  ></span>
                  <span className="tilde">~</span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        props.profile &&
                        props.profile.education &&
                        props.profile.education.high_school &&
                        props.profile.education.high_school.graduate_year,
                    }}
                  ></span>
                </th>
                <td>
                  {props.profile &&
                    props.profile.education &&
                    props.profile.education.high_school &&
                    props.profile.education.high_school.school_name}{" "}
                  {props.profile &&
                    props.profile.education &&
                    props.profile.education.high_school &&
                    props.profile.education.high_school.graduate_status}
                </td>
              </tr>
            )}
          {props.profile &&
            props.profile.education &&
            props.profile.education.university &&
            props.profile.education.university.map((item, i) => {
              <>
                <tr className="mg_bt_16">
                  <th>
                    <span
                      dangerouslySetInnerHTML={{ __html: item.admission_date }}
                    ></span>
                    <span className="tilde">~</span>
                    <span
                      dangerouslySetInnerHTML={{ __html: item.graduate_year }}
                    ></span>
                  </th>
                  <td>
                    {item.school_name} {item.major} {item.graduate_status}
                  </td>
                </tr>
                {item.add_major &&
                  item.add_major.map((aItem, j) => {
                    <tr key={j} className="mg_bt_16">
                      <th></th>
                      <td>
                        <p
                          className="profile_txt_br"
                          dangerouslySetInnerHTML={{
                            __html: aItem.major_state,
                          }}
                        ></p>
                        <p>
                          {aItem.major_affiliation} {aItem.major_name}
                        </p>
                      </td>
                    </tr>;
                  })}
                <tr>
                  <th></th>
                  <td>
                    <p className="profile_txt_br">학점</p>
                    <p>
                      {item.grade} / {item.total_grade}
                    </p>
                  </td>
                </tr>
              </>;
            })}
          {props.profile &&
            props.profile.education &&
            props.profile.education.graduate &&
            props.profile.education.graduate.map((item, i) => {
              <>
                <tr className="mg_bt_16">
                  <th>
                    <span
                      dangerouslySetInnerHTML={{ __html: item.admission_date }}
                    ></span>
                    <span className="tilde">~</span>
                    <span
                      dangerouslySetInnerHTML={{ __html: item.graduate_year }}
                    ></span>
                  </th>
                  <td>
                    {item.school_name} {item.major_affiliation} {item.major}{" "}
                    {item.degree}
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td>
                    <p className="profile_txt_br">학점</p>
                    <p>
                      {item.grade} / {item.total_grade}
                    </p>
                  </td>
                </tr>
              </>;
            })}
        </table>
      </div>
      <div className="profile_cont">
        <h4>
          경력사항
          {props.profile &&
            props.profile.career &&
            props.profile.career.length > 0 && (
              <>
                <span>총</span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: calcCareer(props.profile.career),
                  }}
                ></span>
              </>
            )}
        </h4>
        <table>
          {props.profile &&
            props.profile.career &&
            props.profile.career.map((item, i) => (
              <tr key={i}>
                <th>
                  <span>{item.hired_date}</span>
                  <span className="tilde">~</span>
                  <span>
                    {item.employment_status == "퇴사"
                      ? item.resignation_date
                      : ""}
                  </span>
                </th>
                <td>
                  {item.company_name} {item.department}
                </td>
              </tr>
            ))}
        </table>
      </div>
      <div className="profile_cont">
        <h4>컴퓨터 활용 능력</h4>
        <div className="profile_cont_box">
          <p className="profile_cont_tit">컴퓨터 활용 능력</p>
          <ul>
            {props.profile &&
            props.profile?.comskill &&
            props.profile?.comskill.word_ability &&
            props.profile?.comskill.word_ability == "1" ? (
              <li>
                <span>워드</span>˙<span>하</span>
              </li>
            ) : props.profile?.comskill.word_ability == "2" ? (
              <li>
                <span>워드</span>˙<span>중</span>
              </li>
            ) : props.profile?.comskill.word_ability == "3" ? (
              <li>
                <span>워드</span>˙<span>상</span>
              </li>
            ) : (
              ""
            )}
            {props.profile &&
            props.profile?.comskill &&
            props.profile?.comskill.excel_ability &&
            props.profile?.comskill.excel_ability == "1" ? (
              <li>
                <span>엑셀</span>˙<span>하</span>
              </li>
            ) : props.profile?.comskill.excel_ability == "2" ? (
              <li>
                <span>엑셀</span>˙<span>중</span>
              </li>
            ) : props.profile?.comskill.excel_ability == "3" ? (
              <li>
                <span>엑셀</span>˙<span>상</span>
              </li>
            ) : (
              ""
            )}
            {props.profile &&
            props.profile?.comskill &&
            props.profile?.comskill.ppt_ability &&
            props.profile?.comskill.ppt_ability == "1" ? (
              <li>
                <span>ppt</span>˙<span>하</span>
              </li>
            ) : props.profile?.comskill.ppt_ability == "2" ? (
              <li>
                <span>ppt</span>˙<span>중</span>
              </li>
            ) : props.profile?.comskill.ppt_ability == "3" ? (
              <li>
                <span>ppt</span>˙<span>상</span>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
      <div className="profile_cont">
        <h4>자격증</h4>
        <table>
          {props.profile &&
            props.profile.certificate &&
            props.profile.certificate.map((item, i) => (
              <tr>
                <th>{item.certificate_acquisition_date}</th>
                <td>
                  {item.certificate_name} {item.certificate_level}{" "}
                  {item.certificate_gubun && "(" + item.certificate_gubun + ")"}
                </td>
                <td className="td_mini_txt">{item.certificate_authority}</td>
              </tr>
            ))}
        </table>
      </div>
      <div className="profile_cont">
        <h4>수상내역</h4>
        <table>
          {props.profile &&
            props.profile.prize &&
            props.profile.prize.map((item, i) => (
              <tr>
                <th>{item.award_date}</th>
                <td>{item.award_name}</td>
                <td className="td_mini_txt">{item.award_authority}</td>
              </tr>
            ))}
        </table>
      </div>
      <div className="profile_cont">
        <h4>어학</h4>
        <table>
          {props.profile &&
            props.profile.language &&
            props.profile.language.map((item, i) => (
              <tr>
                <th>{item.language_name}</th>
                <td>
                  {item.language_test_name} {item.language_score}
                </td>
                <td
                  className="td_mini_txt"
                  dangerouslySetInnerHTML={{
                    __html:
                      item.language_acquisition_date &&
                      "(취득일 - <span>" +
                        item.language_acquisition_date +
                        "</span>)",
                  }}
                ></td>
              </tr>
            ))}
        </table>
      </div>
      <div className="profile_cont">
        <h4>병역사항</h4>
        <table>
          {props.profile && props.profile.military && (
            <tr>
              {props.profile.military.military_target != "대상아님" &&
              props.profile.military.military_target != "미필" &&
              props.profile.military.military_target != "면제" ? (
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        props.profile.military.military_target != "대상아님" &&
                        props.profile.military.military_target != "미필" &&
                        props.profile.military.military_target != "면제"
                          ? props.profile.military.military_start_date
                          : "",
                    }}
                  ></span>
                  <span
                    className="tilde"
                    dangerouslySetInnerHTML={{
                      __html:
                        props.profile.military.military_target != "대상아님" &&
                        props.profile.military.military_target != "미필" &&
                        props.profile.military.military_target != "면제"
                          ? "~"
                          : "",
                    }}
                  ></span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        props.profile.military.military_target != "대상아님" &&
                        props.profile.military.military_target != "미필" &&
                        props.profile.military.military_target != "면제" &&
                        props.profile.military.military_target != "복무중"
                          ? props.profile.military.military_end_date
                          : "",
                    }}
                  ></span>
                </th>
              ) : (
                ""
              )}
              <td
                dangerouslySetInnerHTML={{
                  __html:
                    (props.profile.military.military_category &&
                      props.profile.military.military_category + " ") +
                    (props.profile.military.military_discharged_rank &&
                      props.profile.military.military_discharged_rank + " ") +
                    (props.profile.military.military_target != "대상아님" &&
                    props.profile.military.military_target != "미필" &&
                    props.profile.military.military_target != "면제"
                      ? props.profile.military.discharge_reason
                      : props.profile.military.military_target),
                }}
              ></td>
            </tr>
          )}
        </table>
      </div>
      <div className="profile_cont">
        <h4>보훈사항</h4>
        <table>
          {props.profile && props.profile.veteran && (
            <tr>
              <td
                dangerouslySetInnerHTML={{
                  __html:
                    props.profile.veteran.has_veterans_affair +
                    (props.profile.veteran.has_veterans_affair == "대상"
                      ? "(" + props.profile.veteran.veterans_affair_reason + ")"
                      : ""),
                }}
              ></td>
            </tr>
          )}
        </table>
      </div>
      <div className="profile_cont">
        <h4>자기소개서</h4>
        <div
          className="cont_9_box"
          dangerouslySetInnerHTML={{
            __html:
              (props?.profile?.introduction?.introduction_title
                ? "<h3>제목: " +
                  props?.profile?.introduction?.introduction_title +
                  "</h3>\n"
                : "") +
              (props.profile &&
                props.profile.introduction &&
                props.profile.introduction.introduction_content),
          }}
        ></div>
      </div>
      <div className="profile_cont profile_cont_last">
        <h4>장애유형 및 정도</h4>
        <div className="profile_cont_box">
          <div>
            <p className="profile_cont_tit">주장애 및 장애정도</p>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  props.disabili_certifica &&
                  props.disabili_certifica.major_disability_nm,
              }}
            ></p>
          </div>
          <div>
            <p className="profile_cont_tit">부장애 및 장애정도</p>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  props.disabili_certifica &&
                  props.disabili_certifica.minor_disability_nm,
              }}
            ></p>
          </div>
          <div>
            <p className="profile_cont_tit">종합 장애정도</p>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  props.disabili_certifica &&
                  props.disabili_certifica.total_disability_nm,
              }}
            ></p>
          </div>
          <div>
            <p className="profile_cont_tit">보호장구</p>
            <p dangerouslySetInnerHTML={{ __html: props.equipment_st }}></p>
          </div>
          <div>
            <p className="profile_cont_tit">발급일</p>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  props.disabili_certifica &&
                  props.disabili_certifica.issuance_dt,
              }}
            ></p>
          </div>
          <div>
            <p className="profile_cont_tit">발급기관</p>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  props.disabili_certifica &&
                  props.disabili_certifica.issuance_agency,
              }}
            ></p>
          </div>
          <div>
            <p className="profile_cont_tit">등록번호</p>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  props.disabili_certifica &&
                  props.disabili_certifica.dis_reg_cer_seq,
              }}
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantProfile;
