import React, {Component, useEffect, useState,useCallback} from 'react';
import api, { decodeToken } from '../../../api/api';
import Pagination from "../../../api/Pagination";
import DatePicker from "react-datepicker";
import InsertInterviewModal from './InsertInterviewModal';
import UpdateInterviewModal from './UpdateInterviewModal';
import {ko} from 'date-fns/esm/locale';
import 'dayjs/locale/ko';
import dayjs from 'dayjs';

const InterviewList = ({detailModal}) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [visiblePageCount, setVisiblePageCount] = useState(10);
    const [result, setResult] = useState(false);  
    const [search, setSearch] = useState(false);  
    const [searchTerm, setSearchTerm] = useState('');  
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [startDt, setStartDt] = useState('');  
    const [endDt, setEndDt] = useState('');  
    const [agencyType, setAgencyType] = useState('');  
    const [applicantModal, setApplicantModal] = useState(false);
    const [insertInterviewModal, setInsertInterviewModal] = useState(false);
    const [updateInterviewModal, setUpdateInterviewModal] = useState(false);
    const [updateInterviewData, setUpdateInterviewData] = useState(false);
    const [sortColumn, setSortColmn] = useState('interview_dt');
    const [orderType, setOrderType] = useState('DESC');
    const [orderTypeNum, setOrderTypeNum] = useState('DESC');
    const [orderTypeNm, setOrderTypeNm] = useState('DESC');
    const [orderTypeApplyDt, setOrderTypeNumApplyDt] = useState('DESC');
    const [orderTypeAgencyType, setOrderTypeAgencyType] = useState('DESC');
    const [orderTypeInterviewDt, setOrderTypeInterviewDt] = useState('DESC');
    const offset = (page - 1) * limit;
    const searchList = (e) => {
        e.preventDefault()
        let data ={            
            start_dt : startDt,
            end_dt : endDt,
            agency_type : agencyType,
            searchTerm : searchTerm,
            sort_column : sortColumn,
            order_type : orderType
        }
        setSearch(true);
        api.post('/api/comp/interview/searchInterviewList',data).then(response => response.data)
            .then((response) => {
                if(response && response.resultCode == 1000){
                    setResult(response.resultData);
                } else {
                    //alert("일치하는 정보가 없습니다.");
                }
            }
        );
    }        
    const changeOrder = (e,column, type) =>{
        setSortColmn(column);
        setOrderTypeNum('DESC');
        setOrderTypeNm('DESC');
        setOrderTypeNumApplyDt('DESC');
        setOrderTypeAgencyType('DESC');
        setOrderTypeInterviewDt('DESC');
        if(column === 'interview_seq'){
            setOrderTypeNum(type);
            setOrderType(orderTypeNum);    
        }
        else if(column === 'recruit_name'){
            setOrderTypeNm(type);
            setOrderType(orderTypeNm);    
        }
        else if(column ==='apply_dt'){
            setOrderTypeNumApplyDt(type);
            setOrderType(orderTypeApplyDt);    
        }
        else if(column === 'agency_type'){
            setOrderTypeAgencyType(type);
            setOrderType(orderTypeAgencyType);    
        }
        else if(column === 'interview_dt'){
            setOrderTypeInterviewDt(type);
            setOrderType(orderTypeInterviewDt);    
        }
        searchList(e);
    }
    useEffect(() => {
        api.post('/api/comp/interview/getInterviewList').then(response => response.data)
            .then((response) => {
                if(response && response.resultCode == 1000){
                    setResult(response.resultData);
                } else {
                    //alert("일치하는 정보가 없습니다.");
                }
            }
        );
    }, []);
    const handlePeriodChange = (period) => {
        // 선택된 기간에 따라 시작 날짜와 종료 날짜를 계산하여 설정
        const currentDate = new Date();
        let newStartDate = new Date();
    
        switch (period) {
          case '1주일':
            newStartDate.setDate(currentDate.getDate() + 7);
            break;
          case '1개월':
            newStartDate.setMonth(currentDate.getMonth() + 1);
            break;
          case '3개월':
            newStartDate.setMonth(currentDate.getMonth() + 3);
            break;
          case '6개월':
            newStartDate.setMonth(currentDate.getMonth() + 6);
            break;
          default:
            // 기본값 설정
            break;
        }
    
        setSelectedPeriod(period);
        setStartDt(currentDate.toISOString().split('T')[0]);
        setEndDt(newStartDate.toISOString().split('T')[0]);
    };
    
    const moveToInterviewRoom = (e,link) =>{
        e.preventDefault()
        window.open(link,'interviewRoom');
    }
    const updateInterviewDate = (e,item) =>{
        e.preventDefault()
        if(item.interview_dt != null){
            const currentDate = new Date();
            const interviewDate = new Date(item.interview_dt.substring(0,10));
            if (interviewDate > currentDate ) {
                setUpdateInterviewData(item);
                setUpdateInterviewModal(true);
            }
        }
        else{
            setUpdateInterviewData(item);
            setUpdateInterviewModal(true);
        }
        
    }
    return (
        <div className="table_init content_01">
            <div className="search_form_area">    
                <div className="wrap_search_area">
                    <div className="search_from_wrap">                               
                        <form className="wrap_input">
                            <span className="search_label">조회기간</span>
                            <div className="wrap_btn_small select_btn_wrap">
                                <div className="input_wrap">                                 
                                    <input type="radio" id="resume11" name="select_period"
                                        onChange={() => handlePeriodChange('1주일')} checked={selectedPeriod === '1주일'} />
                                    <label htmlFor="resume11">1주일</label>
                                </div>
                                <div className="input_wrap">                                                
                                    <input type="radio" id="resume12" name="select_period"
                                        onChange={() => handlePeriodChange('1개월')} checked={selectedPeriod === '1개월'} />
                                    <label htmlFor="resume12">1개월</label>
                                </div>
                                <div className="input_wrap">                                                
                                    <input type="radio" id="resume13" name="select_period"
                                        onChange={() => handlePeriodChange('3개월')} checked={selectedPeriod === '3개월'} />
                                    <label htmlFor="resume13">3개월</label>
                                </div>
                                <div className="input_wrap">                                                
                                    <input type="radio" id="resume14" name="select_period"
                                        onChange={() => handlePeriodChange('6개월')} checked={selectedPeriod === '6개월'} />
                                    <label htmlFor="resume14">6개월</label>
                                </div>                       
                            </div>
                        </form>
                        <form className="wrap_input">
                            <span className="search_label">날짜선택</span>
                            <div className="wrap_date_input">
                                <input type="text" id="" className="input_datepicker input_mid"
                                            name={`startDt`} style={{display: "none"}} />
                                        <DatePicker
                                            locale={ko} selected={startDt ? new Date(startDt) : null}
                                            onChange={(date) => {setStartDt(dayjs(date).format('YYYY-MM-DD'))}}
                                            dateFormat="yyyy-MM-dd"
                                        />   
                                <span className="tilde">~</span>
                                <input type="text" id="" className="input_datepicker input_mid"
                                            name={`endDt`} style={{display: "none"}} />
                                        <DatePicker
                                            locale={ko} selected={endDt ? new Date(endDt) : null}
                                            onChange={(date) => {setEndDt(dayjs(date).format('YYYY-MM-DD'))}}
                                            dateFormat="yyyy-MM-dd"
                                        /> 
                            </div>
                        </form>
                    </div>    
                    <div className="search_from_wrap">     
                        <form className="wrap_input">
                            <select onChange={(e)=>setAgencyType(e.target.value)} name="category" title="검색조건" className="select" defaultValue=''>
                                    <option key='A' value=''>전체</option>
                                    <option key='N' value="N">대행면접</option>
                                    <option key='Y' value="Y">직접면접</option>        
                                </select>
                            <input type="search" placeholder="검색어를 입력해 주세요." value={searchTerm} onChange={(e)=>setSearchTerm(e.target.value)}/>
                            <button type="submit" className="filled_green btn_with_search" title="검색" onClick={(e)=>searchList(e)}>검색</button>                                    
                        </form>
                        <button type="button" className="btn_submit filled_green" style={{width:'20%'}} onClick={(e) => {e.preventDefault(); setInsertInterviewModal(true);}}>면접일정 추가</button>
                    </div>    
                                           
                </div> 
            </div>
            <div>
                <p className="case_count">총<span>{result.length}</span>건</p>                          
            </div>
            {
                <>
                    <div className="wrap_applicant_list">
                        <table className="table_detail sort_column">
                            <colgroup>
                                <col style={{width:"85px"}}/>                                  
                                <col style={{width:"170px"}}/>                              
                                <col style={{width:"auto"}}/>
                                <col style={{width:"120px"}}/>
                                <col style={{width:"120px"}}/>
                                <col style={{width:"120px"}}/>
                                <col style={{width:"130px"}}/>                           
                            </colgroup>
                            <thead>
                                <tr>
                                    {
                                        (orderTypeNum !== 'ASC' && orderTypeNum != null) ?
                                            <th scope="col" className="header_sort01 sort_down" onClick={(e)=>{changeOrder(e,'interview_seq','ASC')}}>번호</th>  
                                            : <th scope="col" className="header_sort01 sort_down rotate" onClick={(e)=>{changeOrder(e,'interview_seq','DESC')}}>번호</th>  
                                    }
                                    {
                                        (orderTypeNm !== 'ASC' && orderTypeNm != null) ?
                                            <th scope="col" className="header_sort02 sort_down" onClick={(e)=>{changeOrder(e,'recruit_name','ASC')}}>공고명</th>    
                                            : <th scope="col" className="header_sort02 sort_down rotate" onClick={(e)=>{changeOrder(e,'recruit_name','DESC')}}>공고명</th>    
                                    }
                                    <th scope="col" className="no_sort">지원자</th>
                                    {
                                        (orderTypeApplyDt !== 'ASC' && orderTypeApplyDt != null) ?
                                            <th scope="col" className="header_sort04 sort_down" onClick={(e)=>{changeOrder(e,'apply_dt','ASC')}}>지원일</th> 
                                            : <th scope="col" className="header_sort04 sort_down rotate" onClick={(e)=>{changeOrder(e,'apply_dt','DESC')}}>지원일</th> 
                                    }
                                    {
                                        (orderTypeAgencyType !== 'ASC' && orderTypeAgencyType != null) ?
                                            <th scope="col" className="header_sort05 sort_down" onClick={(e)=>{changeOrder(e,'agency_type','ASC')}}>면접방법</th>
                                            : <th scope="col" className="header_sort05 sort_down rotate" onClick={(e)=>{changeOrder(e,'agency_type','DESC')}}>면접방법</th>
                                    }
                                    {
                                        (orderTypeInterviewDt !== 'ASC' && orderTypeInterviewDt != null) ?
                                            <th scope="col" className="header_sort06 sort_down" onClick={(e)=>{changeOrder(e,'interview_dt','ASC')}}>면접일시</th>
                                            : <th scope="col" className="header_sort06 sort_down rotate" onClick={(e)=>{changeOrder(e,'interview_dt','DESC')}}>면접일시</th>
                                    }
                                    <th scope="col" className="no_sort">면접실</th>                               
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    result ? (result.slice(offset,offset+limit).map((value, i)=>{
                                        return (
                                            <tr key={i}>
                                                <td>{i+1}</td>
                                                <td title={value.recruit_name}>{value.recruit_name}</td>
                                                <td className="td_pic_init"> 
                                                    <a href="#" className="resume_popup" onClick={(e) => {detailModal(value.announcement_seq, value.recruit_seq)} } >
                                                        <div className="prof_pic_wrap">
                                                                <span className="profile_pic">
                                                                    <img src={value.image_file_path} alt="프로필이미지"/>
                                                                </span>
                                                            </div>
                                                        <div className="prof_text_wrap">
                                                            <div>
                                                                <span className="name">{value.kor_nm}</span> | 
                                                                <span className="gender">{value.sex_gb}</span> <span className="age">{value.age}</span>세 |  
                                                                <span className="p_history">경력 {value.total_duration}</span>
                                                            </div>
                                                            <div>
                                                                <span className="body_status">{value.major_disability_nm}</span>
                                                            </div>                                           
                                                        </div>
                                                    </a>
                                                </td>
                                                <td>{value.apply_dt.substring(0, 10)}<br/>{value.apply_dt.substring(10, 16)}</td>
                                                <td>{value.agency_type && value.agency_type ==='Y' ? (<>직접면접</>) : (<>대행면접</>)}</td>
                                                <td>
                                                <button 
                                                        type="button" className="btn_table_text" 
                                                        dangerouslySetInnerHTML={
                                                            {
                                                                __html : value.interview_dt != null ? 
                                                                value.interview_dt.substring(0, 10)+"<br/>"+value.interview_dt.substring(10, 16)
                                                                    : value.select_confirm != null ?
                                                                    (() => {
                                                                        switch (value.select_confirm) {
                                                                        case '1':
                                                                            return value.interview_dt1.substring(0, 10)+"<br/>"+value.interview_dt1.substring(10, 16);
                                                                        case '2':
                                                                            return value.interview_dt2.substring(0, 10)+"<br/>"+value.interview_dt2.substring(10, 16);
                                                                        case '3':
                                                                            return value.interview_dt3.substring(0, 10)+"<br/>"+value.interview_dt3.substring(10, 16);
                                                                        default:
                                                                            return "가능한 일정이<br/>없습니다";
                                                                        }
                                                                    })(): "구직자<br/>응답대기"
                                                            }                                        
                                                        }
                                                        onClick={(e) => {updateInterviewDate(e,value)}}
                                                        >
                                                    </button>  
                                                    
                                                </td>
                                                <td>
                                                    {
                                                        value.interview_dt != null ? (
                                                            new Date(value.interview_dt).toDateString() === new Date().toDateString() ? (
                                                                value.zoom_link !== null ? (
                                                                    <button type="button" className="btn_table_init" onClick={(e)=>(moveToInterviewRoom(e,value.zoom_link))}>면접실 입장</button>
                                                                ) : (
                                                                    <button type="button" className="btn_table_init" disabled>면접실 입장</button>
                                                                )
                                                            ) : (
                                                                <button type="button" className="btn_table_init" disabled>면접실 입장</button>
                                                            )
                                                        ) : null                                                                
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })) : (
                                        <tr>
                                            <td colSpan="6">지원자가 없습니다.</td>
                                        </tr>
                                    )
                                }
                                
                            </tbody>
                        </table>
                    </div>
                    {
                        result.size > limit && (
                            <div className="wrap_pagination">
                                <Pagination
                                    total={result.size}
                                    limit={limit}
                                    page={page}
                                    setPage={setPage}
                                    visiblePageCount={visiblePageCount}
                                />
                            </div>
                        )
                    }
                </>
            }
            
            {
                insertInterviewModal && <InsertInterviewModal setInterviewModal={setInsertInterviewModal} recruitSeq={false}  resumeSeq={false}/>
            }
            {
                updateInterviewModal && <UpdateInterviewModal setInterviewModal={setUpdateInterviewModal} interviewData={updateInterviewData}/>
            }
        </div>
    )
};

export default InterviewList;