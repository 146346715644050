import {useState} from "react";
import {deleteId, updateWithdrawal} from "../../../api/User/Signup/Signup";

const WithdrawalPage = () => {
    const [check, setCheck] = useState(false)
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
    const [checkName, setCheckName] = useState("");

    const withdraw = () => {
        if (checkName === ""){
            alert("이름을 입력해주세요.")
        } else if (checkName !== user.username) {
            alert('이름을 확인해주세요.')
        } else {
            try {
                if (user.providerType === "LOCAL") {
                    updateWithdrawal(localStorage.getItem("disCerRegSeq")).then((response) => {
                        if (response.data.resultCode === 1000) {
                            alert("회원 탈퇴가 완료되었습니다.")
                            localStorage.clear();
                            window.location.href="/";
                        }
                    });
                } else {
                    deleteId(user.userId, localStorage.getItem("disCerRegSeq")).then((response) => {
                        if (response.data.resultCode === 1000) {
                            alert("회원 탈퇴가 완료되었습니다.")
                            localStorage.clear();
                            window.location.href="/";
                        }
                    });
                }

            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
        <div className="wrapper">
            <main>
                <div className="page page_mypage page_join_complete page_quit">
                    <h2 className="page_back_title border_bottom_line">브이드림 회원탈퇴</h2>
                    <div className="page_inner">
                        <div className="shadow_box_wrap">
                            <div className="area_shade">
                                <div className="wrap_txt">
                                    <h3>지금까지 이용해 주셔서 감사드립니다.<br/>
                                        탈퇴하기 전 아래 유의사항을 확인해 주세요.
                                    </h3>
                                    <div>
                                        <p>탈퇴하신 아이디는 <span>복구가 불가능</span>하며, 추후 동일한 아이디로 재가입이 되지 않습니다.</p>
                                        <p>이력서 정보, 역량평가 내역, 지원 및 면접현황이 모두 삭제되며, <span>삭제된 데이터는 복구되지 않습니다.</span></p>
                                        <p>소셜 로그인 회원의 경우, 모든 정보가 삭제되며 같은 소셜 아이디로 재가입 시 신규 회원으로 가입됩니다. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="area_shade">
                                <div className="box_input_quit">
                                    <div className="wrap_input_quit">
                                        <div className="line_btn_download">
                                            <h5>아이디</h5>
                                            <span className="label_line"></span>
                                            <p>
                                                <span>{user.userId}</span>
                                            </p>
                                        </div>
                                        <div className="line_btn_download">
                                            <h5 className="asterisk_vers">탈퇴신청자</h5>
                                            <span className="label_line"></span>
                                            <input type="text" placeholder="탈퇴신청자 이름을 적어주세요." onChange={(e) => setCheckName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="wrap_box_input">
                                    <div className="box_input_chk_round">
                                        <label htmlFor="agree10">
                                            <input type="checkbox" className="input_chk" id="agree10" name="agree01"
                                                   checked={check === true ? true : false} onChange={() => setCheck(!check)}/>
                                            유의사항을 모두 확인했으며, 이에 동의합니다.
                                        </label>
                                    </div>
                                </div>
                                <div className="wrap_btns">
                                    <button className="btn_submit filled_gree dn" onClick={withdraw} disabled={check === true ? false : true}>탈퇴하기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default WithdrawalPage;