import React, { useState } from "react";
import "./messageInput.css";
import { ReactComponent as IconWaveform } from "../../assets/icon/icon_waveform.svg";

const MessageInput = ({ handleSendMessage, isListening }) => {
  const [inputValue, setInputValue] = useState("");

  const onSendButtonClick = () => {
    if (isListening) return;
    handleSendMessage(inputValue);
    setInputValue("");
  };

  const handleKeyPress = (e) => {
    if (isListening) return;
    if (e.key !== "Enter") return;
    if (e.nativeEvent.isComposing) return;
    onSendButtonClick();
  };

  return (
    <div style={styles.inputContainer}>
      {isListening ? (
        <div style={styles.speakingContainer}>
          <WaveformIcon />
          <span style={styles.speakingText}>음성인식중입니다</span>
        </div>
      ) : (
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="버튼을 선택하거나 메세지를 입력해주세요."
          onKeyDown={handleKeyPress}
          style={styles.input}
        />
      )}
      <img
        alt="send"
        src="/img/chatbot/UI_btn_textSend.png"
        style={styles.sendButton}
        onClick={onSendButtonClick}
      />
    </div>
  );
};

const styles = {
  inputContainer: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    height: "55px",
    backgroundColor: "#00bf81",
  },
  speakingContainer: {
    boxShadow:
      "2px 1px 1px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.19)",
    border: "1px solid #d6d6d6",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffffff",
    padding: "10px 10px 10px 30px",
    height: "35px",
    flex: 1,
    gap: "5px",
  },
  speakingIcon: {
    width: "24px",
    height: "24px",
    marginRight: "8px",
  },
  speakingText: {
    fontSize: "16px",
    color: "#333",
    fontWeight: "bold",
  },
  input: {
    boxShadow:
      "2px 1px 1px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.19)",
    border: "1px solid #d6d6d6",
    borderRadius: "50px",
    width: "calc(100% - 45px)",
    height: "35px",
    padding: "10px",
    fontSize: "12px",
  },
  sendButton: {
    width: "36px",
    height: "33px",
    marginLeft: "10px",
    cursor: "pointer",
  },
};

export default MessageInput;

function WaveformIcon() {
  return <IconWaveform className="waveform-icon" />;
}
