import React, { useState } from "react";

const ConfirmModal = (props) =>{
    const [fadeInOut, setFadeInOut] = useState("fade-in");
    const disableModal = () =>{
        setTimeout(() => {
            props.setModal(false);
        }, 300);
        setFadeInOut("fade-out");
    }

    return (
        <>
        <div className="popup_mask" onClick={disableModal} style={{display : "block"}}></div>
        <div className={"popup_layer pop_lg popLResultEvaluation " + fadeInOut} style={{width:props.width, display : "block"}} id="popLResultEvaluation">
            <div className="popup_inner" style={{paddingTop : "10px", paddingBottom : "25px"}}>
                <div className="pop_title line_under">
                    <button type="button" aria-label="레이어 팝업 닫기" className="popup_close" onClick={disableModal} style={{marginTop : 0}} ></button>
                </div>
                <div className="popup_contents" style={{paddingBottom : 0}} >
                    {props.ment}
                    <div className="wrap_btns" >
                        <div className="back_or_next">
                        <button type="submit" className="btn_submit filled_green" onClick={(e) => {disableModal()}} style={{width : "195px"}}>확인</button>
                        <button onClick={(e) => {e.preventDefault(); disableModal()}} target="_self" className="btn_submit lined_green" style={{width : "195px"}}>취소</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default ConfirmModal;