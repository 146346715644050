import React  from 'react';
import InterviewList from './InterviewList';


const InterviewMain = () => {
    return (
        <div className="wrapper">
            <main>
                <div className="page page_applicant_list page_layout_40">
                    <h2 className="page_back_title border_bottom_line">                     
                        면접/지원관리 페이지
                    </h2>
                    <div className="page_inner wrap_list_table applicant_list">  
                        <InterviewList />
                    </div>
                </div>
            </main>
        </div>
    )
};

export default InterviewMain;