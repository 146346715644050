import api from "../../../api/api";
import React, {useEffect, useState} from 'react';
const ConfirmModal = (props) => {

    const updateSubmit = () => {
        try {
            api.post("/api/comp/applicant/updateApplicantEvaluation", props.updateParam).then(response =>
            response.data
            ).then((response) => { 
                deleteInterviewInfo()
            })
        } catch(e){
            alert("오류 발생")
        } finally {
            props.setModal(false); 
            props.setUpdateChk(true);
        }
    }
    const deleteInterviewInfo = () => {        
        let interviewInfo = {      
            announcement_seq : props.updateParam.announcement_seq,
            recruit_seq: props.updateParam.recruit_seq,
            comp_evaluation : props.updateParam.comp_evaluation
        }
        try {
            api.post('/api/comp/interview/deleteInterview', interviewInfo).then(response => response.data)
            .then((response) => {
            }
            )                
        } catch (error) {
            alert("오류가 발생했습니다. 관리자에게 문의해주세요.");
            console.log('Error:', error);
        }   
    }
    return (
        <>
        <div className="popup_mask" style={{"display" : "block"}} onClick={(e) => {e.preventDefault(); props.setModal(false);}}></div>
        <div className="popup_layer popup_small" >
            <div className="popup_inner">          
                <div className="popup_contents">
                    {props?.type && props.type ==="confirm" ? (
                        <p><span class="selected_value">평가선택값</span> 처리하시겠습니까? <br/> 확인 클릭 시 바로 반영됩니다. </p>
                    ) : (
                        <p>해당 공고 삭제 시 내용 및 지원 인재 정보도 함께<br/>삭제되며, 복구될 수 없습니다. 삭제하시겠습니까?</p>
                    )}
                    <div className="popup_small_btn_wrap">
                        <a href="#" className="btn_submit filled_black" onClick={(e) => {e.preventDefault(); props.setModal(false);}}>취소</a>
                        {props?.type && props.type ==="confirm" ? (
                            <a href="#" className="btn_submit filled_green" onClick={(e) => {e.preventDefault();  updateSubmit();}}>확인</a>
                        ) : (
                            <a href="#" className="btn_submit filled_green" onClick={(e) => {e.preventDefault(); props.setModal(false);}}>삭제</a>
                        )}
                    </div>
                </div>    
            </div>
        </div>
        </>
    )
}

export default ConfirmModal;