import React, { useState, useEffect } from 'react';
import api, { decodeToken } from '../../../api/api';
import PaginationIndividual from "../../../api/PaginationIndividual";
import Loading from "../../../components/Loading/Loading";
import jwtDecode from "jwt-decode";


const LectureResult = (props) => {
    const [page, setPage] = useState([1,1,1,1,1,1,1,1,1,1,1]);
    const [result, setResult] = useState(false);  
    const lectureLink = (e,classId) =>{
        e.preventDefault();
        if(props.status === 1 ){
            api.get('/api/lms/makeLinkParam').then(response => response.data).then((response) => {
                let url = "";
                url = response.resultData;
                url += '&class_id='+classId+'&page=class_intro';
                window.open(url,"didisam");
            })
        }
        else{
            props.popup_layer(props.layerTarget);
        }
    };  
    useEffect(() => {
        api.post('/api/lms/samClassList').then(response => response.data)
            .then((response) => {
                if(response && response.resultCode == 1000){
                    props.setStatus(1);
                    setResult(response.resultData);
                } else {                    
                    api.post('/api/lms/sampleClassList').then(response => response.data)
                        .then((response) => {
                            if(response && response.resultCode == 1000){                                
                                props.setStatus(-1);
                                setResult(response.resultData);
                            } 
                        }
                    );
                }
            }
        );
        
    }, []);
    const toggleOnClass = (event) => {
        const element = event.currentTarget;
        if (element.classList.contains('on')) {
            element.classList.remove('on');
            element.nextElementSibling.style.display = 'none';
        } else {
            element.classList.add('on');
            element.nextElementSibling.style.display = 'block';
        }
    };
    
    return (
        <div className="wrap_accodian">
            {
                props.status === 1  || props.status === -1 ? (
                    <ul className="accodian_list">
                        {
                            result.map((item, i)=>{
                                const jobNm = item.jobNm;
                                const lectureResult = item.classList;
                                const lecture = lectureResult.contents;
                                let limit = 4;
                                let visiblePageCount = 10;
                                let offset = (page[i] - 1) * limit;
                                return(                                    
                                    (
                                        <li className="wrap_accodian_item card_init area_shade" key={i}>    
                                            <div className={`accodian_tit_box ${i < 3 ? 'on' : ''}`}  onClick={(e)=>toggleOnClass(e)}>
                                                <h3 className="">{jobNm}</h3>
                                            </div>                                              
                                            <div className="accodian_cnt"  style={{ display: i < 3 ? 'block' : 'none' }}>
                                            {
                                                lecture ? (
                                                    <>
                                                    <div className="section_lecture_list">
                                                        <ul className="lecture_list">
                                                            {
                                                                lecture && lecture.slice(offset,offset+limit).map((value, j)=>{
                                                                    return (
                                                                        <li className="lecture_list_item" key={j}>
                                                                            <a href="#" target="_self" className="lecture_item_box" onClick={(e) => lectureLink(e,value.class_id)} >
                                                                                <div className="wrap_lecture_thumb">
                                                                                    <img src={value.thumbnail_image} alt={value.title}/>
                                                                                </div>
                                                                                <p className="category_name">카테고리: <span>{value.tag_name}</span></p>
                                                                                <h4 className="lecture_list_title">{value.title}</h4>
                                                                                <span className="horizontal_line"></span>
                                                                                <div className="box_detail">
                                                                                    {value.teachers && (
                                                                                        <p>강사명:  <span>{value.teachers[0].name}</span></p>
                                                                                    )}
                                                                                    {value.period && (
                                                                                        <p>수강기간 정보:  수강 후 <span>{value.period.days}{value.period.type}</span></p>
                                                                                    )}
                                                                                </div>  
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    {
                                                        lecture.length > limit && (
                                                            <div className="wrap_pagination">
                                                                <PaginationIndividual
                                                                    total={lecture.length}
                                                                    limit={limit}
                                                                    page={page}
                                                                    setPage={(newPage) => setPage(newPage)} // 각 게시판에 대한 페이지 업데이트 함수 전달
                                                                    visiblePageCount={visiblePageCount}
                                                                    i={i}
                                                                />
                                                            </div>
                                                        )
                                                    } 
                                                    </>
                                                ) : (
                                                    <div class="section_lecture_list">
                                                        <p class="no_content_notice">추천 교육 준비중 입니다. </p>
                                                    </div>   
                                                )
                                            }                                                
                                            </div>
                                        </li>
                                    )
                                )
                            })
                        }                                        
                    </ul>
                ) : (
                    <Loading />
                )
            }
        </div>       
    )
};
const Recommend = () => { 
    const [role, setRole] = useState();
    const [checkResume, setCheckResume] = useState(false);
    const [checkAbility, setCheckAbility] = useState(0);
    const [popup, setPopup ] = useState({display: 'none'})    
    const [status, setStatus] = useState(0);
    const [layerTarget, setLayerTarget] = useState(false);
    const [popWarning01, setPopWarning01 ] = useState({display: 'none'})
    const [popWarning02, setPopWarning02 ] = useState({display: 'none'})
    const [popWarning03, setPopWarning03 ] = useState({display: 'none'})
    const [popWarning04, setPopWarning04 ] = useState({display: 'none'})

    const popup_layer = (target) => {
        if(target) setPopup({display:'block'})
        if(target == 1)        setPopWarning01({display:'block'})
        else if(target == 2)    setPopWarning02({display:'block'})
        else if(target == 3)    setPopWarning03({display:'block'})
        else if(target == 4)    setPopWarning04({display:'block'})
    }

    const close_popup_layer = () => {
        setPopup({display:'none'})
        setPopWarning01({display:'none'})
        setPopWarning02({display:'none'})
        setPopWarning03({display:'none'})
        setPopWarning04({display:'none'})
    }
    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            const decodedToken = jwtDecode(accessToken);
            setRole(decodedToken.role);
            const dToken = decodeToken(localStorage.getItem("accessToken"));
            try {
                api.post('/api/resume_new/getProfileList',{}).then(response => 
                    response.data
                ).then((response) => {
                    if(response.resultData.length>0)
                        setCheckResume(true)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            } catch (e) {
                console.log(e);
            }

            api.post("/api/ability/abilityChk").then(response => 
                response.data
            ).then((response) => {
                if(response.resultCode===1000)
                    setCheckAbility(1);
                else if(localStorage.getItem('abilityResult_'+dToken.sub)!=undefined)
                    setCheckAbility(-1);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }, []);

    useEffect(() => {
        if(status!==0){
            if(role != undefined){
                    if(!checkResume && checkAbility != 1)   setLayerTarget('4');
                    else if(!checkResume)    setLayerTarget('2');
                    else if(checkAbility != 1)  setLayerTarget('3');
            }
            else{
                setLayerTarget('1');
            }
        }
        popup_layer(layerTarget);
    }, [role, checkResume,checkAbility,status ,layerTarget]);
    return (
        <div>
            <div className="wrapper">
                {/* 본문 */}
                <main>
                    <div className="page page_recommend page_layout_40">
                        <h2 className="page_back_title border_bottom_line">                     
                            추천교육
                        </h2>
                        <div className="page_inner">
                            {/* 지원자 리스트 */}
                            <div className="applicant_list">                                         
                                <LectureResult status={status} setStatus={setStatus} popup_layer={popup_layer} layerTarget={layerTarget}/>
                            </div>                  
                        </div>
                    </div>
                </main>
            </div>
            
            {/* 팝업 - 회원가입 미완료자 강의카드 클릭 시  */}
            
            <div className="popup_mask" style={popup}></div>
            <div className="popup_layer popup_small" style={popWarning01}>
                <div className="popup_inner">          
                    <div className="popup_contents">
                        <p>회원가입 후 이력서 작성 및 역량평가 완료 시<br/>추천직무별 추천교육 정보를 제공해드립니다.</p>
                        <div className="popup_small_btn_wrap">
                            <a href="#" target="_self" className="btn_submit filled_black" onClick={close_popup_layer}>닫기</a>
                            <a href="/user/signup/selectsignup" target="_self" className="btn_submit filled_green">회원가입 하기</a>
                        </div>
                    </div>    
                </div>
             </div>

            {/* 팝업 - 이력서작성 미완료자 강의카드 클릭 시   */}
            <div className="popup_layer popup_small" style={popWarning02}>
                <div className="popup_inner">          
                    <div className="popup_contents">
                        <p>이력서 작성 완료 시 추천직무별<br/>추천교육 정보를 제공해드립니다.</p>
                        <div className="popup_small_btn_wrap">
                            <a href="#" target="_self" className="btn_submit filled_black" onClick={close_popup_layer}>닫기</a>
                            <a href="/user/resume/beforeResume" target="_self" className="btn_submit filled_green">이력서작성 하기</a>
                        </div>
                    </div>    
                </div>
            </div>

            {/* 팝업 - 역량평가 미완료자 강의카드 클릭 시    */}
            <div className="popup_layer popup_small" style={popWarning03}>
                <div className="popup_inner">          
                    <div className="popup_contents">
                        <p>역량평가 완료 시 추천직무별<br/>추천교육 정보를 제공해드립니다.</p>
                        <div className="popup_small_btn_wrap">
                            <a href="#" target="_self" className="btn_submit filled_black" onClick={close_popup_layer}>닫기</a>
                            <a href="/user/ability/main" target="_self" className="btn_submit filled_green">역량평가 하기</a>
                        </div>
                    </div>    
                </div>
            </div>

            {/* 팝업 - 이력서작성/역량평가 미완료자 강의카드 클릭 시    */}
            <div className="popup_layer popup_small" style={popWarning04}>
                <div className="popup_inner">          
                    <div className="popup_contents">
                        <p>이력서 작성 및 역량평가 완료 시<br/>추천직무별 추천교육 정보를 제공해드립니다.</p>
                        <div className="popup_small_btn_wrap">
                            <a href="#" target="_self" className="btn_submit filled_black" onClick={close_popup_layer}>닫기</a>
                            <a href="/user/resume/beforeResume" target="_self" className="btn_submit filled_green">이력서작성 하기</a>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    )
}
export default Recommend;