import {React, useRef, useState} from "react";
import {useImageFile} from "../../../hooks/useImageFile";
import {usePopupPost} from "../../../hooks/usePopupPost";
import {useFormContext} from "react-hook-form";
import {checkBizSeq, checkId, countBizSeq} from "../../../api/Comp/CompSignup/CompSignup";
import {ErrorMessages} from "../../../utils/Message";

const CompInfo = (props) => {
    const { compImage, setCompImage, bizImage, setBizImage, bizSeqCheck, setBizSeqCheck, isCompInfo } = props;
    const methods = useFormContext();
    const { register, setValue, setError, clearErrors, reset, watch, formState: { errors } } = methods;
    const compImageRef = useRef(null);
    const bizImageRef = useRef(null);
    const watchField = watch();

    const comma_seperate = (e) => {
        let input = e.target.value.replaceAll(',',"");
        if(input != null && input != "" && !isNaN(input)){
            let l = input.length;

            let remain = l%3 == 0 ? 3 : l%3;
            let result = input.slice(0,remain);
            for(let i=remain; i< l; i+=3){
                result += "," + input.slice(i,i+3);
            }
            e.target.value = result;
        }
        else{
            e.target.value = null;
        }
    }

    const convert_comma_seperate2Number = (input) => {
        return input === null ? null : input.toString().replaceAll(',',"");
    }

    const duplicateCheckBizSeq = async () => {
        const biz_seq = watchField.comp_member_info.biz_seq;

        if (biz_seq === "" || biz_seq === undefined) {
            setError("comp_member_info.biz_seq",{
                message: "필수 입력 항목입니다."
            }, {shouldFocus: true});
        }
        else {
            try {
                const response = await countBizSeq(biz_seq);

                if (response.data.resultData !== 0) {
                    setError("comp_member_info.biz_seq", {
                        message: "이미 등록된 사업자등록번호입니다."
                    }, {shouldFocus: true});
                    setValue("comp_member_info.biz_seq", "", {shouldDirty: true, shouldTouch: true});
                    setBizSeqCheck(false);
                } else {
                    clearErrors("comp_member_info.biz_seq")
                    setBizSeqCheck(true);
                }

            } catch (error)  {
                console.log(error)
            }
        }
    }

    const setImageFile = (image) => {
        clearErrors(["comp_member_info.rep_file_path", "comp_member_info.rep_file_nm"])
        setValue("comp_member_info.rep_file_nm", image.name, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
    }

    const deleteCompImage = () => {
        setValue("comp_member_info.rep_file_nm", "", {shouldValidate: true, shouldDirty: true, shouldTouch: true});
        setValue("comp_member_info.rep_file_path", "", {shouldValidate: true, shouldDirty: true, shouldTouch: true});
        setError("comp_member_info.rep_file_path",{
            message: ErrorMessages.required
        }, {shouldFocus: true})
        setCompImage(null);
    }

    const { uploadBase64: uploadCompImage} = useImageFile(compImageRef, setCompImage, methods, "comp_member_info.rep_file_path", setImageFile, ['jpg', 'jpeg', 'png']);
    const { uploadImage: uploadBizImage, deleteImage: deleteBizImage } = useImageFile(bizImageRef, setBizImage, methods, "comp_member_info.biz_image", null, ['pdf']);

    function getPostCode(address, extraAddress) {
        setValue('comp_member_info.address', address, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
        setValue('comp_member_info.extraAddress', extraAddress, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
    }

    const {postCode} = usePopupPost(getPostCode);

    const autoHyphen = (e) => {
        let num = e.target.value.replace(/[^0-9]/g,'');
        if(num.length < 4) {
            e.target.value = num.slice(0,3)
        } else if (num.length < 6) {
            e.target.value =  num.slice(0,3) + "-" + num.slice(3);
        } else {
            e.target.value =  num.slice(0,3) + "-" + num.slice(3,5) + "-" + num.slice(5);
        }
    }

    const onErrorImg = (e) => {
        e.target.src = "/img/logo/vlogo.png"
        e.target.style.height = "80%"
    }

    return (
        <>
        <div className="wrap_signup_input wrap_signup_basic">
            <div className="box_sub_title">
                <h4 className="sub_title_signup">기업정보</h4>
            </div>
                { (compImage === null && isCompInfo === false) &&
                <div className="wrap_box_input focus_in">
                    <div className="box_attached_file box_input">
                        <label htmlFor="" className="asterisk">대표 이미지</label>
                        <input type="file" id="comp_member_info.rep_image" name="comp_member_info.rep_image" className="attached_file btn_submit filled_green"
                               style={{display: "none"}} ref={compImageRef} onChange={uploadCompImage} accept='.jpg, .png'/>
                        <button type="button" className="attached_file btn_submit filled_green"
                                onClick={(e) => {compImageRef.current.click()}}> 파일첨부
                        </button>
                        <p className="attached_note">※ JPG, PNG 형식의 파일만 업로드 가능합니다.</p>
                    </div>
                    <p className="msg_err">{errors.comp_member_info?.rep_file_path?.message}</p>
                </div>
                }
                { (compImage !== null && isCompInfo === false) &&
                <div className="wrap_signup_input wrap_box_address wrap_box_address_first">
                    <div className="wrap_box_input">
                        <div className="box_confirm">
                            <div className="box_input box_input_100">
                                <div className="wrap_attached_file">
                                    <label htmlFor="" className="asterisk">대표 이미지</label>
                                    <div className="attached_file_viewer">
                                        <ul className="attached_file_list">
                                            <li className="attached_file_item">
                                                <a href="#" target="_self" className="attached_anchor"><span>{compImage && compImage.name}</span></a>
                                                <button className="btn_attached_delete" title="첨부파일 삭제" onClick={deleteCompImage}></button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            { isCompInfo === true &&
            <div className="wrap_signup_input wrap_box_address wrap_box_address_first">
                <div className="wrap_box_input">
                    <div className="box_confirm">
                        <div className="box_input box_input_100">
                            <label htmlFor="" className="asterisk">대표 이미지</label>
                            <div className="wrap_box_input wrap_basic_photo_input">
                                <div className="wrap_basic_photo">
                                    <div id="image_container" className="wrap_thumbnail">
                                        <div className="box_thumbnail">
                                            <img src={watchField.comp_member_info.rep_file_path} alt="" onError={onErrorImg}/>
                                        </div>
                                    </div>
                                    <input type="file" className="attachedFileImage" id="attachedFileImage" onChange={uploadCompImage} accept=".jpg, .png"/>
                                </div>
                                <p>※ JPG, PNG 형식의 파일만 업로드 가능합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {
                bizImage === null ? (
                    <div className="wrap_box_input focus_in">
                        <div className="box_attached_file box_input">
                            <label htmlFor="" className="asterisk">사업자등록증 또는 고유번호증</label>
                            <input type="file" id="comp_member_info.biz_image" className="attached_file btn_submit filled_green"
                                   style={{display: "none"}} ref={bizImageRef} onChange={uploadBizImage} accept='.pdf'/>
                            <button type="button" className="attached_file btn_submit filled_green"
                                    onClick={(e) => {bizImageRef.current.click()}}> 파일첨부
                            </button>
                            <p className="attached_note">※ PDF 형식의 파일만 업로드 가능합니다.</p>
                        </div>
                        <p className="msg_err">{errors.comp_member_info?.biz_image?.message}</p>
                    </div>
                ) : (
                    <div className="wrap_signup_input wrap_box_address wrap_box_address_first">
                        <div className="wrap_box_input">
                            <div className="box_confirm">
                                <div className="box_input box_input_100">
                                    <div className="wrap_attached_file">
                                        <label htmlFor="" className="asterisk">사업자등록증 또는 고유번호증</label>
                                        <div className="attached_file_viewer">
                                            <ul className="attached_file_list">
                                                <li className="attached_file_item">
                                                    <a href="#" target="_self" className="attached_anchor">
                                                        <span>{bizImage ? bizImage.name : watchField.comp_member_info.biz_origin_file_nm}</span>
                                                    </a>
                                                    <button className="btn_attached_delete" title="첨부파일 삭제" onClick={deleteBizImage}></button>
                                                </li>
                                            </ul>
                                            <p>※PDF 형식의 파일만 업로드 가능합니다.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">회사명</label>
                    <input type="text" id="comp_member_info.comp_nm" name="comp_member_info.comp_nm" placeholder="회사명을 입력해 주세요." {...register('comp_member_info.comp_nm', {
                        required: ErrorMessages.required
                    })}/>
                </div>
                <p className="msg_err">{errors.comp_member_info?.comp_nm?.message}</p>
            </div>
            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">대표자명</label>
                    <input type="text" id="comp_member_info.rep_nm" name="comp_member_info.rep_nm" placeholder="대표자명을 입력해 주세요." {...register('comp_member_info.rep_nm',{
                        required: ErrorMessages.required
                    })}/>
                </div>
                <p className="msg_err">{errors.comp_member_info?.rep_nm?.message}</p>
            </div>
            {isCompInfo === false &&
            <div className="wrap_box_input focus_in">
                <div className="box_confirm">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">사업자등록번호</label>
                        <input type="text" id="comp_member_info.biz_seq" name="comp_member_info.biz_seq"
                               placeholder="사업자등록번호를 입력해 주세요." {...register('comp_member_info.biz_seq', {
                            required: ErrorMessages.required,
                            validate: {
                                duplicate: () => bizSeqCheck === true || ErrorMessages.duplicateValidation
                            },
                            onChange: (e) => {
                                autoHyphen(e)
                            }
                        })}/>
                    </div>
                    <button type="button" className="btn_submit filled_green" onClick={duplicateCheckBizSeq}> 중복확인
                    </button>
                </div>
                <p className="msg_err">{errors.comp_member_info?.biz_seq?.message}</p>
                <p className="msg_biz_dup">{(!errors.comp_member_info?.biz_seq && bizSeqCheck === true) && '사용 가능한 사업자등록번호입니다.'}</p>
            </div>
            }
            {isCompInfo === true &&
            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">사업자등록번호</label>
                        <input type="text" id="comp_member_info.biz_seq" name="comp_member_info.biz_seq"
                               placeholder="사업자등록번호를 입력해 주세요." {...register('comp_member_info.biz_seq')} disabled={true}/>
                </div>
            </div>
            }
        </div>
        <div className="wrap_signup_input wrap_box_address">
            <div className="wrap_box_input focus_in">
                <div className="box_confirm">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">주소</label>
                        <input type="text" id="comp_member_info.address" name="comp_member_info.address" placeholder="주소를 입력해 주세요." {...register('comp_member_info.address', {
                                required: true
                            })} readOnly/>
                    </div>
                    <button type="button" className="btn_submit filled_green" onClick={postCode} > 우편번호 찾기 </button>
                </div>
                <div className="box_input">
                    <input type="text" id="comp_member_info.extraAddress" name="comp_member_info.extraAddress" placeholder="상세주소를 입력해 주세요."  {...register('comp_member_info.extraAddress', {
                        required: true
                    })}/>
                </div>
                <p className="msg_err">{ (errors.comp_member_info?.address || errors.comp_member_info?.extraAddress) && ErrorMessages.required }</p>
            </div>
        </div>
        <div className="wrap_signup_input wrap_signup_basic">
            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">기업 업종</label>
                    <input type="text" id="comp_member_info.biz_sector" name="comp_member_info.biz_sector" placeholder="기업업종을 입력해 주세요." {...register('comp_member_info.biz_sector',{
                        required: ErrorMessages.required
                    })}/>
                </div>
                <p className="msg_err">{errors.comp_member_info?.biz_sector?.message}</p>
            </div>
        </div>
            <div className="wrap_signup_input wrap_signup_basic">
                <div className="wrap_box_input focus_in">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">매출 (단위: 백만원)</label>
                        <input type="text" id="comp_member_info.sales" name="comp_member_info.sales" placeholder="매출을 입력해 주세요." {...register('comp_member_info.sales',{
                                required: ErrorMessages.required,
                                setValueAs: convert_comma_seperate2Number,
                                onChange : (e) => { e.target.value = e.target.value.replace(/[^0-9]/g,''); comma_seperate(e);}
                            })}/>
                    </div>
                    <p className="msg_err">{errors.comp_member_info?.sales?.message}</p>
                </div>
            </div>
            <div className="wrap_signup_input wrap_signup_basic">
                <div className="wrap_box_input focus_in">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">종업원 수 (단위: 명)</label>
                        <input type="text" id="comp_member_info.employee_cnt" name="comp_member_info.employee_cnt" placeholder="종업원수를 입력해 주세요." {...register('comp_member_info.employee_cnt',{
                                required: ErrorMessages.required,
                                onChange : (e) => { e.target.value = e.target.value.replace(/[^0-9]/g,''); comma_seperate(e);},
                                setValueAs: convert_comma_seperate2Number
                            })}/>
                    </div>
                    <p className="msg_err">{errors.comp_member_info?.employee_cnt?.message}</p>
                </div>
            </div>
        </>
    )
}

export default CompInfo;