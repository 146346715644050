import {ErrorMessages} from "../../../utils/Message";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useFormContext} from "react-hook-form";
import {checkCrtfNum, checkId, countId, countPhoneNo, getPhoneNo, sendPhone} from "../../../api/User/Signup/Signup";

const MemberCom = (props) => {
    const {idCheck, setIdCheck, phone, setPhone} = props;
    const methods = useFormContext();
    const {register, setValue, watch, handleSubmit, clearErrors, reset, setError, formState: {errors}} = methods;
    const [time, setTime] = useState(1799);
    const watchField = watch();

    useEffect(() => {
        let Count

        if (time > 0) {
            Count = setInterval(() => {
                setTime((prev) => prev - 1);
            }, 1000);
        } else {
            clearInterval(Count);
            if (phone.pass !== true)
                setPhone({...phone, num: undefined, valid: false})
        }
        return () => clearInterval(Count)
    }, [time])

    const timeFormat = (time) => {
        const m = Math.floor(time / 60).toString()
        let s = (time % 60).toString()
        if (s.length === 1) s = `0${s}`
        return `${m}:${s}`
    }

    const duplicateCheckId = async () => {
        const id = watchField.member_com.member_id;

        if (id === "") {
            setError("member_com.member_id", {
                message: ErrorMessages.required
            }, {shouldFocus: true});
        } else if (!(/^(?=.*[A-Za-z])[A-Za-z0-9]{4,19}$/.test(id))) {
            setError("member_com.member_id", {
                message: ErrorMessages.inValidUserId
            }, {shouldFocus: true});
        } else {
            try {
                const response = await countId(id);

                if (response.data.resultData !== 0) {
                    setError("member_com.member_id", {
                        message: ErrorMessages.duplicateId
                    }, {shouldFocus: true});
                    setValue("member_com.member_id", "", {shouldDirty: true, shouldTouch: true});
                    setIdCheck(false);
                } else {
                    clearErrors('member_com.member_id')
                    setIdCheck(true);
                }

            } catch (error) {
                console.log(error)
            }
        }
    }

    const sendMessage = async () => {
        const num = watchField.member_info.phone_no;

        if (num === "" || num === undefined)
            setError("member_info.phone_no", {
                message: ErrorMessages.required
            }, {shouldFocus: true})
        else {
            try {
                clearErrors("member_info.phone_no")
                // test시에만 휴대폰중복체크 해제
                // const response = await countPhoneNo(num);
                //
                // if (response.data.resultData === 0) {
                const valid = await sendPhone(num);

                setPhone({...phone, send: true, num: valid})
                setTime(1799)
                // } else {
                //     setError("member_info.phone_no", {
                //         message: ErrorMessages.duplicatePhoneNo
                //     }, {shouldFocus: true})
                // }

            } catch (error) {
                console.log(error)
            }
        }
    }

    const validPhoneCheck = () => {
        if (phone.num !== undefined) {
            if (watchField.member_info.validNum === phone.num.data.resultData.toString()) {
                setPhone({...phone, valid: true, pass: true})
                clearErrors("member_info.phone_no")
                clearErrors("member_info.validNum")
            } else {
                setError("member_info.validNum", {
                    message: ErrorMessages.inValidPhone
                }, {shouldFocus: true})
                setPhone({...phone, valid: false})
            }
        } else {
            setError("member_info.validNum", {
                message: ErrorMessages.reValidPhone
            }, {shouldFocus: true})
            setPhone({...phone, valid: false})
        }
    }

    return (
        <div className="wrap_signup_input wrap_signup_basic">
            <div className="box_sub_title">
                <h4 className="sub_title_signup">기본정보</h4>
            </div>
            <div className="wrap_box_input focus_in">
                <div className="box_confirm">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">아이디</label>
                        <input type="id" id="member_com.member_id" name="member_com.member_id"
                               placeholder="아이디를 입력해 주세요." {...register("member_com.member_id", {
                            required: ErrorMessages.required,
                            pattern: {
                                value: /^(?=.*[A-Za-z])[A-Za-z0-9]{4,19}$/,
                                message: ErrorMessages.inValidUserId
                            },
                            validate: {
                                duplicate: () => idCheck === true || ErrorMessages.duplicateValidation
                            },
                            onChange: () => setIdCheck(false)
                        })}/>
                        <button type="button" className="btn_submit filled_green" onClick={duplicateCheckId}>중복확인
                        </button>
                    </div>
                </div>
                <p className="msg_err">{errors.member_com?.member_id?.message}</p>
                <p className="msg_id_dup">{(!errors.member_com?.member_id && idCheck === true) && '사용 가능한 아이디입니다.'}</p>
            </div>
            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">비밀번호</label>
                    <input type="password" id="member_com.member_pw" name="member_com.member_pw"
                           placeholder="비밀번호를 입력해 주세요." {...register("member_com.member_pw", {
                        required: ErrorMessages.required,
                        pattern: {
                            value: /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,32}$/,
                            message: ErrorMessages.inValidPw
                        },
                    })}/>
                </div>
                <p className="msg_err">{errors.member_com?.member_pw?.message}</p>
            </div>

            <div className="wrap_box_input focus_in">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">비밀번호 확인</label>
                    <input type="password" id="member_com.pwcheck" name="member_com.pwcheck"
                           placeholder="위와 동일한 비밀번호를 다시 입력해 주세요." {...register("member_com.pwcheck", {
                        required: true,
                        validate: (value) => value === watchField.member_com.member_pw,
                    })}/>
                </div>
                <p className="msg_err">{errors.member_com?.pwcheck?.type === 'required' && ErrorMessages.required}
                    {errors.member_com?.pwcheck?.type === 'validate' && ErrorMessages.unMatchPw}</p>
            </div>
            {phone.send !== true &&
            <div className="wrap_box_input focus_in">
                <div className="box_confirm">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">휴대폰 인증</label>
                        <input type="tel" id="member_info.phone_no" name="member_info.phone_no"
                               placeholder="휴대폰 번호를 입력해 주세요." {...register("member_info.phone_no", {
                            required: ErrorMessages.required,
                            validate: {
                                validPhone: () => phone.send === true || ErrorMessages.validPhone
                            },
                            onChange: (e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '')
                            }
                        })} />
                    </div>
                    <button type="button" className="btn_submit filled_green" onClick={sendMessage}>인증번호 요청</button>
                </div>
                <p className="msg_err">{errors.member_info?.phone_no?.message}</p>
            </div>
            }
            {phone.send === true &&
            <div className="wrap_box_input">
                <div className="box_confirm">
                    <div className="box_input">
                        <label htmlFor="" className="asterisk">휴대폰 인증</label>
                        <input type="tel" id="member_info.phone_no" name="member_info.phone_no"
                               placeholder="휴대폰 번호를 입력해 주세요." {...register("member_info.phone_no", {
                            required: ErrorMessages.required,
                            validate: {
                                validPhone: () => phone.send === true || ErrorMessages.validPhone
                            },
                            onChange: (e) => {
                                setPhone({...phone, valid: false, pass: false, num: undefined});
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }
                        })} disabled={phone.pass === true ? true : false}/>
                        <p className="msg_time">{phone.valid !== true && timeFormat(time)}</p>
                    </div>
                    {phone.pass !== true &&
                    <button type="button" className="btn_submit filled_green" onClick={sendMessage}>재전송</button>
                    }
                    {phone.pass === true &&
                    <button type="button" className="btn_submit filled_green" disabled>인증완료</button>
                    }
                </div>
                {phone.valid !== true &&
                <div className="box_confirm">
                    <div className="box_input">
                        <input type="tel" id="member_info.validNum" name="member_info.validNum"
                               placeholder="인증번호를 입력해 주세요." {...register("member_info.validNum", {
                            required: ErrorMessages.required,
                            validate: {
                                validNum: () => (phone.valid === true && phone.pass === true) || ErrorMessages.validPhone
                            },
                            onChange: () => setPhone({...phone, valid: false})
                        })}/>
                    </div>
                    <button type="button" className="btn_submit filled_green" onClick={validPhoneCheck}>인증번호 확인</button>
                </div>
                }
                <p className="msg_err">{errors.member_info?.validNum?.message || errors.member_info?.phone_no?.message}</p>
            </div>
            }
            <div className="wrap_box_input">
                <div className="box_input">
                    <label htmlFor="" className="asterisk">E-mail</label>
                    <input type="email" id="member_com.email" name="member_com.email"
                           placeholder="E-mail 주소를 입력해 주세요." {...register("member_com.email", {
                        required: ErrorMessages.required,
                        pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: ErrorMessages.inValidEmail
                        }
                    })}/>
                </div>
                <p className="msg_err">{errors.member_com?.email?.message}</p>
            </div>
        </div>
    )
}

export default MemberCom;