import React, {useEffect, useState, useRef} from 'react';
import api from "../../../api/api";
import Pagination from "../../user/resume/Pagination";
import { useHistory, useParams } from 'react-router-dom';
import {getRecruit} from "../../../api/Comp/JobMatching/JobMatching";

const ChooseJobRequest = () => {
    const {recruitSeq} = useParams();
    const [selectLCate, setSelectLCate] = useState([]);
    const [selectMCate, setSelectMCate] = useState([]);
    const [selectSCate, setSelectSCate] = useState([]);
    const [selectDCate, setSelectDCate] = useState([]);
    const [selectedCheckbox, setSelectedCheckbox] = useState('');
    const [visiblePageCount, setVisiblePageCount] = useState(10);
    const [inputText, setInputText] = useState("");
    const [inputCateCode, setinputCateCode] = useState("");
    const [checkboxValue, setCheckboxValue] = useState(selectedCheckbox);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const offset = (page - 1) * limit;
    const [Desired, setDesired] = useState({
        l_cate_cdnm: '',
        m_cate_cdnm: '',
        s_cate_cdnm: '',
        d_cate_cdnm: ''
    });

    const history = useHistory();
    const mCateRef = useRef(null);
    const sCateRef = useRef(null);

    const activeEnter = (e) => {
        if (e.key === "Enter") {
            searchButton();
        }
    }
    const onCheckboxChange = (checkboxValue) => {
        //setSelectedCheckbox(checkboxValue);
        setDesired(checkboxValue);
    };
    const handleCheckboxToggle = (e, value) => {
        setCheckboxValue(value);
        const checkboxes = document.getElementsByName('group')
        let target_value=false;
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i] !== e.target) {
                checkboxes[i].checked = false
            }else{
                target_value = checkboxes[i].checked;
            }
        }

        if(target_value){
            onCheckboxChange(value);
        }else{
            onCheckboxChange({
                l_cate_cdnm: '',
                m_cate_cdnm: '',
                s_cate_cdnm: '',
                d_cate_cdnm: ''
            });
        }
        
    };
    useEffect(() => {
        handleCATE();
        if(localStorage.getItem('compDesiredJobCode')){
            onCheckboxChange({
                l_cate_cdnm: JSON.parse(localStorage.getItem('compDesiredJobCode')).depth1_name,
                m_cate_cdnm: JSON.parse(localStorage.getItem('compDesiredJobCode')).depth2_name,
                s_cate_cdnm: JSON.parse(localStorage.getItem('compDesiredJobCode')).depth3_name,
                d_cate_cdnm: JSON.parse(localStorage.getItem('compDesiredJobCode')).depth4_name
            });
        } 
        else if(recruitSeq) {
            getRecruit(recruitSeq)
                .then((response) => {
                    if (response.data.resultCode === 1000 && response.data.resultData) {
                        localStorage.setItem('compDesiredJobCode', JSON.stringify(response.data.resultData.desire)); 
                        onCheckboxChange({
                            l_cate_cdnm: response.data.resultData.desire.depth1_name,
                            m_cate_cdnm: response.data.resultData.desire.depth2_name,
                            s_cate_cdnm: response.data.resultData.desire.depth3_name,
                            d_cate_cdnm: response.data.resultData.desire.depth4_name
                        });
                    }
                })
        }
        console.log(Desired);
    }, []);
    const handleCATE = (e) => {
        api.post("/api/resume/getLCateList", {})
            .then(response => response.data)
            .then((response) => {
                setSelectLCate(response.resultData);
            })
    };

    const handleLCATE = (e) => {
        api.post("/api/resume/getMCateList", {
            cate_seq: e.target.value
        }).then(response =>
            response.data
        ).then((response) => {
            setSelectMCate(response.resultData);
            if (mCateRef.current) {
                mCateRef.current.value = ''; // Reset the value
                mCateRef.current.selectedIndex = 0; // Set the first option as selected
            }
            setSelectSCate([]);
            setinputCateCode(e.target.value);
            api.post("/api/resume/getLMSDCateList", {
                cate_nm: inputText,
                cate_seq: e.target.value
            })
                .then(response => response.data)
                .then((response) => {
                    setSelectDCate(response.resultData);
                })
        })
    };
    const handleMCATE = (e) => {

        const code_value = e.target.value == ""? inputCateCode == "" ? "" : inputCateCode.slice(0,2) : e.target.value;

        api.post("/api/resume/getSCateList", {
            cate_seq: e.target.value
        }).then(response =>
            response.data
        ).then((response) => {
            setSelectSCate(response.resultData);
            if (sCateRef.current) {
                sCateRef.current.value = ''; // Reset the value
                sCateRef.current.selectedIndex = 0; // Set the first option as selected
            }
            
            setinputCateCode(code_value);
            api.post("/api/resume/getLMSDCateList", {
                cate_nm: inputText,
                cate_seq: code_value
            })
                .then(response => response.data)
                .then((response) => {
                    console.log(response.resultData);
                    setSelectDCate(response.resultData);
                })
        })
    };

    const handleSCATE = (e) => {

        const code_value = e.target.value == ""? inputCateCode == "" ? "" : inputCateCode.slice(0,4) : e.target.value;

        api.post("/api/resume/getDCateList", {
            cate_seq: e.target.value
        }).then(response =>
            response.data
        ).then((response) => {
            setSelectDCate(response.resultData);
            setinputCateCode(code_value);
            api.post("/api/resume/getLMSDCateList", {
                cate_nm: inputText,
                cate_seq: code_value
            })
                .then(response => response.data)
                .then((response) => {
                    setSelectDCate(response.resultData);
                })
        })
    };

    const searchButton = (e) => {
        if (e)
            e.preventDefault();
        setPage(1);
        api.post("/api/resume/getLMSDCateList", {
            cate_nm: inputText,
            cate_seq: inputCateCode
        }).then(response =>
            response.data
        ).then((response) => {
            setSelectDCate(response.resultData);
        })
    };

    async function alertMessage() {
        if (Desired.d_cate_cdnm != '') {
            window.localStorage.removeItem('compDesiredJobCode');
            let DesiredArr =
                {
                    depth1: Desired.l_cate_cdnm.split(".")[0],
                    depth2: Desired.m_cate_cdnm.split(".")[0],
                    depth3: Desired.s_cate_cdnm.split(".")[0],
                    depth4: Desired.d_cate_cdnm.split(".")[0],
                    depth1_name: Desired.l_cate_cdnm,
                    depth2_name: Desired.m_cate_cdnm,
                    depth3_name: Desired.s_cate_cdnm,
                    depth4_name: Desired.d_cate_cdnm
                };
                
            window.localStorage.setItem('compDesiredJobCode', JSON.stringify(DesiredArr));
            if(recruitSeq){
                window.location.href = `/comp/jobRequest/jobRequestUpdate/${recruitSeq}`;
            } else {
                window.location.href = "/comp/jobRequest/jobRequestForm";
            }
        } else {
            alert("요청 직무 선택은 필수 입니다.");
        }
    }


    return (
        <div className="wrapper">
            <main>
                <div className="page_job_request page_layout_40">
                    <div className="page_back_title">
                        <span onClick={() => window.location.href="/comp/jobRequest/jobApplicationList"} style={{cursor:"pointer"}} target="_self" className="page_back">요청 채용직무 선택</span>
                    </div>
                    <div className="wrap_progressbar">
                        <ul className="progressbar_list progress_step01">
                            <li className="progressbar_item progress_item01"><p>요청직무 선택</p></li>
                            <li className="progressbar_item progress_item02"><p>직무요청서 작성</p></li>
                        </ul>
                    </div>
                    <h3 className="page_title">요청직무를 선택해 주세요.</h3>
                    <div className="page_inner">
                        <div className="area_shade">
                            <div className="wrap_box_search">
                                <div className="box_division">
                                    <div className="wrap_box_input">
                                        <div className="box_input">
                                            <label htmlFor="" className="">대분류 </label>
                                            <select onChange={handleLCATE} defaultValue=''>
                                                <option value=''>대분류를 선택해주세요.</option>
                                                {selectLCate.map((item,i) => (
                                                    <option key={i}
                                                            value={item.cate_seq}>{item.cate_cdnm}</option>
                                                ))}

                                            </select>
                                        </div>
                                    </div>
                                    <div className="wrap_box_input">
                                        <div className="box_input">
                                            <label htmlFor="" className="">중분류</label>
                                            <select ref={mCateRef} onChange={handleMCATE} defaultValue=''>
                                                <option value=''>중분류를 선택해주세요.</option>
                                                {selectMCate.map((item,i) => (
                                                    <option key={i}
                                                            value={item.cate_seq}>{item.cate_cdnm}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="wrap_box_input">
                                        <div className="box_input">
                                            <label htmlFor="" className="">소분류</label>
                                            <select ref={sCateRef} onChange={handleSCATE} defaultValue=''>
                                                <option value=''>소분류를 선택해주세요.</option>
                                                {selectSCate.map((item,i) => (
                                                    <option key={i}
                                                            value={item.cate_seq}>{item.cate_cdnm}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="wrap_box_input wrap_box_input_text">
                                        <div className="box_input">
                                            <label htmlFor="" className=""></label>
                                            <input type="text" id="dCateNm" className="" name="dCateNm"
                                                   onChange={(e) => setInputText(e.target.value)}
                                                   onKeyDown={(e) => activeEnter(e)}
                                                   placeholder="검색어 예시: 사무, 디자인, 마케팅"/>
                                        </div>
                                    </div>
                                </div>
                                <a href="#" target="_self" className="btn_submit filled_green"
                                   onClick={searchButton}> 검색하기</a>
                            </div>

                            <div className="guide_exam">
                                <p>※장애예술인 채용을 희망하신다면 반드시 아래 예시를 포함해 선택해 주시길 바랍니다.</p>
                                <p>(예시:
                                    음악 > 08.문화·예술·방송·디자인 > 01.문화·예술 > 03.공연예술<span>/</span>미술 > 08.문화·예술·방송·디자인 > 02.디자인
                                    > 01.디자인)
                                </p>
                                <br></br>
                                <br></br>
                            </div>

                            <div className="wrap_box_search_result">
                                <div className="box_search_result">
                                    <table className="table_detail">
                                        <colgroup>
                                            <col style={{width: '64px'}}/>
                                            <col style={{width: '64px'}}/>
                                            <col style={{width: 'auto'}}/>
                                            <col style={{width: 'auto'}}/>
                                            <col style={{width: 'auto'}}/>
                                            {/*<col style={{width: 'auto'}}/>*/}
                                        </colgroup>
                                        <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">번호</th>
                                            <th scope="col">대분류</th>
                                            <th scope="col">중분류</th>
                                            <th scope="col">소분류</th>
                                            {/*<th scope="col">세분류</th>*/}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            selectDCate.slice(offset, offset + limit).map((item, i) => {
                                                let isChecked = 
                                                item.s_cate_cdnm === Desired.s_cate_cdnm &&
                                                item.m_cate_cdnm === Desired.m_cate_cdnm &&
                                                item.l_cate_cdnm === Desired.l_cate_cdnm &&
                                                item.d_cate_cdnm === Desired.d_cate_cdnm 
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <div className="box_input_chk">
                                                                <label htmlFor={"group" + i}
                                                                       className="input_chk_label">
                                                                    <input type="checkbox" className="input_chk"
                                                                           id={"group" + i} name="group"
                                                                           onChange={(e) => handleCheckboxToggle(e, item)}
                                                                           checked={isChecked}
                                                                    />
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>{(page - 1) * limit + i + 1}</td>
                                                        <td>{item.l_cate_cdnm}</td>
                                                        <td>{item.m_cate_cdnm}</td>
                                                        <td>{item.s_cate_cdnm}</td>
                                                        {/*<td>{item.d_cate_cdnm}</td>*/}
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>

                                    </table>
                                </div>
                            </div>

                            <div className="wrap_pagination">
                                <Pagination
                                    total={selectDCate.length}
                                    limit={limit}
                                    page={page}
                                    setPage={setPage}
                                    visiblePageCount={visiblePageCount}
                                />
                            </div>

                        </div>

                        <div className="wrap_btns flex_ed">
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                alertMessage();
                            }} target="_self" className="btn_submit filled_green">다음으로</a>

                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
export default ChooseJobRequest;





