import { useEffect, useMemo, useState } from "react";
import Loading from "../common/Loading";
import { useGameTimer } from "../../../hooks/useGameTimer";
import {
  adjustArraySize,
  getImageUrl,
  shuffleArray,
} from "../../../utils/common";
import { INITIAL_COUNTDOWN_SECONDS } from "../games";

export default function CardQuiz({
  defaultValue,
  problems,
  onChange,
  isModalOpen,
  openModal,
  setPrevPageButtonProps,
  setNextPageButtonProps,
}) {
  const {
    pauseTimer,
    resumeTimer,
    isTimerFinished,
    startTimer,
    GameTimerComponent,
  } = useGameTimer({
    initialDurationInSeconds: INITIAL_COUNTDOWN_SECONDS,
    onTimerComplete: () => {
      setPrevPageButtonProps(() => {
        return { disabled: false, onClick: () => {} };
      });
      setNextPageButtonProps(() => {
        return {
          disabled: false,
          onClick: (nextPage) => nextPage(),
        };
      });
    },
  });

  const { shuffledImages, categories } = useMemo(() => {
    const combinedData = problems.map((problem) => {
      const imagePairs = problem.problemThinkingIds.map((id, index) => ({
        id,
        imgUrl: getImageUrl(
          problem.problemThinkingImages[index].replace("/images", "/game")
        ),
      }));

      return {
        images: imagePairs,
        category: {
          name: problem.secondCategory,
          problemBlockId: problem.problemThinkingIds,
        },
      };
    });

    const images = combinedData.flatMap((data) => data.images);
    const shuffledImages = shuffleArray(images);

    return {
      shuffledImages,
      categories: combinedData.map((data) => data.category),
    };
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [categorySelections, setCategorySelections] = useState(() => {
    if (defaultValue === null || defaultValue === undefined)
      return [[], [], []];

    const convertToCategorySelection = defaultValue.map((value) => {
      const { id, imgUrl } =
        shuffledImages.find((image) => image.id === value) || [];
      return value !== null && value !== "-" && value !== undefined
        ? {
            imgUrl,
            problemBlockId: id,
          }
        : null;
    });

    return chunkArray(convertToCategorySelection, 3);
  });
  const isAnswered = useMemo(
    () =>
      Boolean(
        defaultValue?.some((value) => ![null, undefined].includes(value))
      ),
    []
  );

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isAnswered) {
      setPrevPageButtonProps(() => ({
        disabled: false,
        onClick: () => {},
      }));
      setNextPageButtonProps(() => ({
        disabled: false,
        onClick: (nextPage) => nextPage(),
      }));

      return;
    }

    startTimer();
    setPrevPageButtonProps(() => {
      return {
        disabled: true,
        onClick: () => {},
      };
    });
  }, []);

  useEffect(() => {
    isModalOpen ? pauseTimer() : resumeTimer();
  }, [isModalOpen]);

  useEffect(() => {
    if (isTimerFinished) {
      onChange({
        arrayScore: adjustArraySize(normalizeAndExtractIds(categorySelections)),
      });
    }
  }, [isTimerFinished]);

  useEffect(() => {
    const isAllSelected = categorySelections.every(
      (selection) => selection.filter(Boolean).length === 3
    );
    if (isAllSelected) {
      setNextPageButtonProps(() => {
        return {
          disabled: false,
          onClick: () =>
            onChange(
              {
                arrayScore: adjustArraySize(
                  normalizeAndExtractIds(categorySelections)
                ),
                arrayIds: [],
              },
              true
            ),
        };
      });
    } else {
      setNextPageButtonProps(() => {
        return {
          disabled: false,
          onClick: () => {
            openModal({
              title: "알람",
              content: (
                <div>
                  <p>그림카드를 모두 배치하지 않았습니다.</p>
                  <p>다음문제로 넘어가시겠습니까?</p>
                </div>
              ),
              buttons: [
                {
                  label: "네",
                  onClick: () => {
                    onChange(
                      {
                        arrayScore: adjustArraySize(
                          normalizeAndExtractIds(categorySelections)
                        ),
                        arrayIds: shuffledImages.map((image) => image.id),
                      },
                      true
                    );
                  },
                  className: "confirm-button",
                },
                {
                  label: "아니오",
                  className: "cancel-button",
                },
              ],
            });

            return false;
          },
        };
      });
    }
  }, [categorySelections]);

  const handleImageSelect = (index) => {
    setActiveImageIndex(index);
  };

  const handleCategorySelection = (categoryIndex, itemIndex) => {
    if (isTimerFinished || isAnswered) return;

    if (
      activeImageIndex &&
      categorySelections[categoryIndex].filter(Boolean).length === 3
    ) {
      handleImageSelect(null);
      openModal({
        title: "알람",
        content: (
          <div>
            <p>그림카드 배치는 최대 3개만 가능합</p>
            <p>니다. 수정을 원할 경우, 기존 그림카</p>
            <p>드를 재클릭하여 분류해제 후 새로운</p>
            <p>그림카드를 클릭하세요.</p>
          </div>
        ),
        buttons: [
          {
            label: "확인",
            className: "confirm-button",
          },
        ],
      });
      return;
    }

    setCategorySelections((prevSelections) => {
      const newSelections = prevSelections.map((selection, i) => {
        if (i === categoryIndex) {
          const updatedSelection = [...selection];
          if (
            !updatedSelection[itemIndex] &&
            !selection[itemIndex] &&
            activeImageIndex == null
          )
            return selection;

          updatedSelection[itemIndex] = selection[itemIndex]
            ? undefined
            : {
                imgUrl: shuffledImages[activeImageIndex]?.imgUrl,
                problemBlockId: shuffledImages[activeImageIndex]?.id,
              };
          return updatedSelection;
        }
        return selection;
      });
      return newSelections;
    });
    setActiveImageIndex(null);
  };

  const selectedImages = useMemo(
    () =>
      categorySelections
        .flat()
        .filter((item) => item)
        .map((item) => item?.imgUrl),
    [categorySelections]
  );

  return (
    <div className="quiz">
      <Loading loading={isLoading} />
      <div className="quiz-wrapper">
        <GameTimerComponent isFinished={isTimerFinished || !!isAnswered} />
        <div className="quiz">
          <div className="quiz-assets card-quiz-grid">
            {shuffledImages.map(({ imgUrl: image }, index) => (
              <button
                key={image}
                className={`card-quiz-grid-item ${
                  activeImageIndex === index ? "selected" : ""
                }`}
                onClick={() => {
                  !selectedImages.includes(image) && handleImageSelect(index);
                }}
              >
                {image && !selectedImages.includes(image) ? (
                  <img src={image} alt={image} />
                ) : null}
              </button>
            ))}
          </div>

          <div className="quiz-answer">
            <h3>
              Q. 주어진 3x3 그림카드들을 주제에 맞게 분류하여, 선택한 그림카드를
              각각 아래 빈칸영역에 마우스 클릭으로 배치하세요.
            </h3>

            <div className="card-quiz-box">
              {categories.map((category, i) => (
                <div className="card-quiz-row" key={category.name}>
                  <p>{category.name}</p>
                  <div>
                    {Array(3)
                      .fill()
                      .map((_, j) => (
                        <button
                          className="card-quiz-button"
                          key={`${category.name}-${j}`}
                          onClick={() => handleCategorySelection(i, j)}
                        >
                          {categorySelections[i][j]?.imgUrl && (
                            <img
                              src={categorySelections[i][j].imgUrl}
                              alt={`selected-${i}-${j}`}
                            />
                          )}
                        </button>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function normalizeAndExtractIds(array, length = 3) {
  return array.flatMap((subArray) => {
    return Array.from({ length }, (_, index) => {
      const element = subArray[index];
      return element && element.problemBlockId ? element.problemBlockId : null;
    });
  });
}

function chunkArray(array, size) {
  if (!array) return;

  const chunkedArray = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size));
  }

  return chunkedArray;
}
